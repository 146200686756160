// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentType, type ComponentProps } from 'react';

export type PropsWithInnerRef<P> = Omit<P, 'innerRef' | 'ref'> & {
	// React.ref and styled-component innerRef don't match
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	innerRef?: any;
};

// Ban usage of innerRef as a prop type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type BanInnerRef<T> = T extends { innerRef?: any } ? never : T;

export const styledComponentWithCondition =
	<P1 extends ComponentProps<any>, P2 extends ComponentProps<any>>( // eslint-disable-line @typescript-eslint/no-explicit-any
		useCompiled: () => boolean,
		CompiledComponent: ComponentType<BanInnerRef<P1>>,
		StyledComponent: ComponentType<P2>,
	): ComponentType<PropsWithInnerRef<P1 & P2>> =>
	// Couldn't destructure like {innerRef, ...rest} as
	// typescript is suspcious on destructing generics
	({ innerRef, ...rest }) =>
		useCompiled() ? (
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
			<CompiledComponent {...(rest as any)} ref={innerRef} />
		) : (
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
			<StyledComponent {...(rest as any)} innerRef={innerRef} />
		);

// The `any` type has non-logical behaviour, so we can test for it
type IfAny<T, Y, N> = 0 extends 1 & T ? Y : N;

/**
 * Feature flag a Compiled CSS-in-JS migration with the Styled API
 *
 * Additionally, ban usage of <any> props and inheritance (`styled(MyComponent)`)
 *
 * @param useCompiled condition (usually a feature flag) to define which component to return
 * @param CompiledComponent Component from the `styled` API in `@compiled/react`
 * @param StyledComponent Component from the `styled` API in `styled-components`
 * @returns Returns CompiledComponent when `useCompiled` is true, and `StyledComponent` when false
 */
export const styledComponentWithoutInheritanceWithCondition =
	<P1 extends ComponentProps<any>, P2 extends ComponentProps<any>>( // eslint-disable-line @typescript-eslint/no-explicit-any
		useCompiled: () => boolean,
		CompiledComponent: ComponentType<IfAny<P1, never, BanInnerRef<P1>>>,
		StyledComponent: ComponentType<IfAny<P2, never, P2>>,
	): ComponentType<
		PropsWithInnerRef<
			P1 &
				P2 & {
					__UNSAFE__NO_INHERITANCE_TYPE_MARKER?: true;
				}
		>
	> =>
	({ innerRef, ...rest }) =>
		useCompiled() ? (
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
			<CompiledComponent {...(rest as any)} ref={innerRef} />
		) : (
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
			<StyledComponent {...(rest as any)} innerRef={innerRef} />
		);
