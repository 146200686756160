import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	adminUserTitle: {
		id: 'software.main.flags.custom-filter-failed.admin-user-title',
		defaultMessage: "We couldn't load your filters",
		description: 'Warning Flag title shown to admin user',
	},
	adminUserDescription: {
		id: 'software.main.flags.custom-filter-failed.admin-user-description',
		defaultMessage:
			"One or more of your filters can't be used right now. You can check your filter queries and try again.",
		description: 'Warning Flag body shown to Admin user',
	},
	adminUserActionLink: {
		id: 'software.main.flags.custom-filter-failed.admin-user-action-link',
		defaultMessage: 'Manage custom filters',
		description: 'Warning flag action link shown to Admin user',
	},
	endUserTitle: {
		id: 'software.main.flags.custom-filter-failed.end-user-title',
		defaultMessage: "We couldn't load your filters",
		description: 'Warning Flag title shown to end user',
	},
	endUserDescription: {
		id: 'software.main.flags.custom-filter-failed.end-user-description',
		defaultMessage:
			"One or more of your filters can't be used right now. Contact your Jira admin to check the filter queries, then try again.",
		description: 'Warning Flag body shown to end user',
	},
	endUserActionLink: {
		id: 'software.main.flags.custom-filter-failed.end-user-action-link',
		defaultMessage: 'OK',
		description: 'Warning flag action link shown to end user',
	},
});
