import { useMutation, type MutationTuple } from '@apollo/react-hooks';
import gqlTagSwag from 'graphql-tag';
import type { AssignIssue, AssignIssueVariables } from './__generated_apollo__/AssignIssue';

export const MUTATION = gqlTagSwag`
    mutation AssignIssue($issueId: ID!, $accountId: ID) {
        assignIssue(input: { issueId: $issueId, accountId: $accountId }) {
            statusCode
            message
        }
    }
`;

export const useAssignToMeMutation = (): MutationTuple<AssignIssue, AssignIssueVariables> =>
	useMutation<AssignIssue, AssignIssueVariables>(MUTATION);
