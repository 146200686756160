import {
	ColumnType,
	UNSCHEDULED_COLUMN_ID,
} from '@atlassian/jira-common-constants/src/column-types.tsx';
import type { Column, StatusColumn, DateRangeColumn } from '../../../model/column/column-types.tsx';

export const getStatusColumn = (column: Column | undefined): StatusColumn | null =>
	column?.type === ColumnType.STATUS ? column : null;

export const getDateRangeColumn = (column: Column | undefined): DateRangeColumn | null =>
	column?.type === ColumnType.DATE_RANGE ? column : null;

export const isUnscheduledColumn = (column: Column | undefined): boolean =>
	column?.type === ColumnType.DATE_RANGE && column?.id === UNSCHEDULED_COLUMN_ID;
