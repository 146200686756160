import type { Action } from '@atlassian/react-sweet-state';
import type { State } from '../../types.tsx';

export const setHasCreatedIssueICC =
	(hasCreatedIssueICC: boolean): Action<State> =>
	({ getState, setState }) => {
		const { hasCreatedIssueICC: currentHasCreatedIssueICC } = getState();

		if (hasCreatedIssueICC === currentHasCreatedIssueICC) {
			return;
		}
		setState({
			hasCreatedIssueICC,
		});
	};
