export type FeatureLike = {
	readonly key: string;
	readonly status: string;
};

export type FeatureAnalyticsAttributes = {
	[key: string]: boolean;
};

export const toAnalyticsFeatureKey = (key: string): string => `is_${key}_enabled`;

export const getFeatureAnalyticsAttributes = <FeatureType extends FeatureLike>(
	allFeatures: FeatureType[],
): FeatureAnalyticsAttributes => {
	const result: FeatureAnalyticsAttributes = {};

	allFeatures.forEach((feature) => {
		result[toAnalyticsFeatureKey(feature.key)] = feature.status === 'ENABLED';
	});

	return result;
};
