import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	titleWithPermissionAndBacklog: {
		id: 'software.main.board-container.board.column.empty-cmp-kanban-dialog.title-with-permission-and-backlog',
		defaultMessage: 'Create and move issues from the backlog to get started.',
		description:
			'Title that is shown in the CMP Kanban Board (with backlog) empty state for the users who has issue create permission',
	},
	titleWithPermissionNoBacklog: {
		id: 'software.main.board-container.board.column.empty-cmp-kanban-dialog.title-with-permission-no-backlog',
		defaultMessage: 'Create an issue to get started.',
		description:
			'Title that is shown in the CMP Kanban Board (without backlog) empty state for the users who has issue create permission',
	},
	titleNoPermission: {
		id: 'software.main.board-container.board.column.empty-cmp-kanban-dialog.title-no-permission',
		defaultMessage: 'No issues yet',
		description:
			'Title that is shown in the CMP Kanban Board empty state for the users who has no issue create permission',
	},
	descriptionNoPermission: {
		id: 'software.main.board-container.board.column.empty-cmp-kanban-dialog.description-no-permission',
		defaultMessage: "Once your team has issues to work on, you'll see them here",
		description:
			'Description that is shown in the CMP Kanban Board empty state for the users who has no issue create permission',
	},
	button: {
		id: 'software.main.board-container.board.column.empty-cmp-kanban-dialog.button',
		defaultMessage: 'Create issue',
		description: 'The text on the button used to trigger global issue create',
	},
	jsmEmptyStateTitle: {
		id: 'software.main.board-container.board.column.empty-cmp-kanban-dialog.jsm-empty-state-title',
		defaultMessage: 'Visualize your work with a board',
		description: 'Title that is shown for JSM Kanban boards when the board is in empty state',
	},
	jsmEmptyStateDescription: {
		id: 'software.main.board-container.board.column.empty-cmp-kanban-dialog.jsm-empty-state-description',
		defaultMessage: 'Track all issues in your project, or view specific issues using filters.',
		description: 'Description that is shown for JSM Kanban boards when the board is in empty state',
	},
	titleWithPermissionAndBacklogIssueTermRefresh: {
		id: 'software.main.board-container.board.column.empty-cmp-kanban-dialog.title-with-permission-and-backlog-issue-term-refresh',
		defaultMessage: 'Create and move work items from the backlog to get started.',
		description:
			'Title that is shown in the CMP Kanban Board (with backlog) empty state for the users who has issue create permission',
	},
	titleWithPermissionNoBacklogIssueTermRefresh: {
		id: 'software.main.board-container.board.column.empty-cmp-kanban-dialog.title-with-permission-no-backlog-issue-term-refresh',
		defaultMessage: 'Create a work item to get started.',
		description:
			'Title that is shown in the CMP Kanban Board (without backlog) empty state for the users who has issue create permission',
	},
	titleNoPermissionIssueTermRefresh: {
		id: 'software.main.board-container.board.column.empty-cmp-kanban-dialog.title-no-permission-issue-term-refresh',
		defaultMessage: 'No work items yet',
		description:
			'Title that is shown in the CMP Kanban Board empty state for the users who has no issue create permission',
	},
	descriptionNoPermissionIssueTermRefresh: {
		id: 'software.main.board-container.board.column.empty-cmp-kanban-dialog.description-no-permission-issue-term-refresh',
		defaultMessage: "Once your team has work items to work on, you'll see them here",
		description:
			'Description that is shown in the CMP Kanban Board empty state for the users who has no issue create permission',
	},
	buttonIssueTermRefresh: {
		id: 'software.main.board-container.board.column.empty-cmp-kanban-dialog.button-issue-term-refresh',
		defaultMessage: 'Create work item',
		description: 'The text on the button used to trigger global issue create',
	},
	jsmEmptyStateDescriptionIssueTermRefresh: {
		id: 'software.main.board-container.board.column.empty-cmp-kanban-dialog.jsm-empty-state-description-issue-term-refresh',
		defaultMessage: 'Use filters to view specific work items, or track all work in your project.',
		description: 'Description that is shown for JSM Kanban boards when the board is in empty state',
	},
});
