export const REASON_CRITICAL_DATA_FAILED = 'critical-data-failed-unknown-reason';
export const REASON_CRITICAL_DATA_FAILED_WITH_STATUS_CODE = 'critical-data-failed-with-status-code';

export const REASON_ABORT_429_ERROR = '429-error';
export const REASON_ABORT_USER_NETWORK_ERROR = 'user-network-error';
export const REASON_ABORT_CHUNK_LOAD_ERROR = 'chunk-load-error';
export const REASON_ABORT_CLIENT_FETCH_ERROR = 'client-fetch-error';
export const REASON_ABORT_RESPONSE_NULL_ERROR = 'response-null-error';
export const REASON_ABORT_CLIENT_THIRD_PARTY_APP_ERROR = 'client-third-party-app-error';
export const VALUE_UNKNOWN = 'unknown';
