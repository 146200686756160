/**
 * @generated SignedSource<<00440ea84b892285846d9b8e65e5f2f5>>
 * @relayHash 16968863187b798a94c81fb2f3c02587
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 1d92172fafa12a38c14a21764204ae490c41baf36eb55b3ec10622230a6ef256

import type { ConcreteRequest, Query } from 'relay-runtime';
export type JiraSoftwareIssueSearchCustomInputContext = "BACKLOG" | "BOARD" | "NONE" | "%future added value";
export type JiraIssueSearchInput = {
  childIssuesInput?: JiraIssueSearchChildIssuesInput | null | undefined;
  customInput?: JiraIssueSearchCustomInput | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
};
export type JiraIssueSearchChildIssuesInput = {
  filterByProjectKeys?: ReadonlyArray<string> | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
  parentIssueKey: string;
};
export type JiraIssueSearchCustomInput = {
  jiraSoftwareInput?: JiraSoftwareIssueSearchCustomInput | null | undefined;
};
export type JiraSoftwareIssueSearchCustomInput = {
  additionalJql?: string | null | undefined;
  context?: JiraSoftwareIssueSearchCustomInputContext | null | undefined;
  jiraEntityId: string;
};
export type limitReachedFlagTotalIssueCountQuery$variables = {
  cloudId: string;
  issueSearchInput: JiraIssueSearchInput;
  skip: boolean;
};
export type limitReachedFlagTotalIssueCountQuery$data = {
  readonly jira?: {
    readonly issueSearchTotalCount: number | null | undefined;
  } | null | undefined;
};
export type limitReachedFlagTotalIssueCountQuery = {
  response: limitReachedFlagTotalIssueCountQuery$data;
  variables: limitReachedFlagTotalIssueCountQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "issueSearchInput"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "condition": "skip",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "cloudId",
                "variableName": "cloudId"
              },
              {
                "kind": "Variable",
                "name": "issueSearchInput",
                "variableName": "issueSearchInput"
              }
            ],
            "kind": "ScalarField",
            "name": "issueSearchTotalCount"
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "limitReachedFlagTotalIssueCountQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "limitReachedFlagTotalIssueCountQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "1d92172fafa12a38c14a21764204ae490c41baf36eb55b3ec10622230a6ef256",
    "metadata": {},
    "name": "limitReachedFlagTotalIssueCountQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "1b2bcb6691b0c1b0f0f93eb3b32f4323";

export default node;
