// SVG with custom colours

import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const CardLayoutDefaultIcon = (props: CustomGlyphProps) => (
	<svg
		width="58"
		height="27"
		viewBox="0 0 58 27"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		data-testid={props['data-testid']}
		aria-label={props['aria-label']}
	>
		<path
			d="M0 14H58H0ZM59 25C59 26.6569 57.6569 28 56 28H2C0.343146 28 -1 26.6569 -1 25H1C1 25.5523 1.44772 26 2 26H56C56.5523 26 57 25.5523 57 25H59ZM2 28C0.343146 28 -1 26.6569 -1 25V14H1V25C1 25.5523 1.44772 26 2 26V28ZM59 14V25C59 26.6569 57.6569 28 56 28V26C56.5523 26 57 25.5523 57 25V14H59Z"
			fill="currentColor"
			mask="url(#path-1-inside-1_1311_46)"
		/>
		<path
			d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H56C56.8284 0.5 57.5 1.17157 57.5 2V13.5H0.5V2Z"
			stroke="currentColor"
		/>
		<rect x="5" y="5" width="4" height="4" rx="1" fill="currentColor" />
		<rect x="5" y="18" width="4" height="4" rx="1" fill="currentColor" />
		<rect x="12" y="6" width="41" height="2" rx="1" fill="currentColor" />
		<rect x="12" y="19" width="41" height="2" rx="1" fill="currentColor" />
	</svg>
);
