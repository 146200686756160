import {
	OPEN_ISSUE_TRANSITION_MODAL,
	CLOSE_ISSUE_TRANSITION_MODAL,
} from '../../../actions/issue/issue-transition-modal/index.tsx';
import type { Action } from '../../../types.tsx';
import type { IssueTransitionModal } from './types.tsx';

const defaultState = { isOpen: false } as const;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	state: IssueTransitionModal = defaultState,
	action: Action,
): IssueTransitionModal => {
	if (action.type === OPEN_ISSUE_TRANSITION_MODAL) {
		return {
			isOpen: true,
			...action.payload,
		};
	}
	if (action.type === CLOSE_ISSUE_TRANSITION_MODAL) {
		return { isOpen: false };
	}

	return state;
};
