import { createSelector } from 'reselect';
import { isDialogVisibleSelector as isBacklogEnableDialogVisibleSelector } from '../board/backlog-enable-selectors.tsx';
import { isDialogVisibleSelector as isBacklogIssueMoveVisibleSelector } from '../board/backlog-issue-move-selectors.tsx';
import { getUiColumnLimitsModalOpen } from '../column/column-selectors.tsx';
import { isAssignIssueParentModalOpen } from '../issue/assign-issue-parent-selector.tsx';
import { isAddLabelModalOpen } from '../issue/issue-add-label-selectors.tsx';
import { isIssueFlagWithCommentModalOpen } from '../issue/issue-flag-with-comment-selectors.tsx';
import { isIssueModalShowingSelector } from '../issue/issue-modal-selectors.tsx';
import { getColumnDeleteModalIsOpen, isIssueDeleteModalOpen } from '../work/work-selectors.tsx';

export const isAnyModalOpenSelector = createSelector(
	[
		isIssueModalShowingSelector,
		isBacklogEnableDialogVisibleSelector,
		isBacklogIssueMoveVisibleSelector,
		getColumnDeleteModalIsOpen,
		isIssueDeleteModalOpen,
		isAddLabelModalOpen,
		isAssignIssueParentModalOpen,
		getUiColumnLimitsModalOpen,
		isIssueFlagWithCommentModalOpen,
	],
	(
		issueModalShowing,
		backlogIssueMoveDialogOpen,
		backlogEnableDialogOpen,
		columnDeleteModalIsOpen,
		issueDeleteModalOpen,
		addLabelModalOpen,
		assignIssueParentModalOpen,
		uiColumnLimitsModalOpen,
		issueFlagWithCommentModalOpen,
	) =>
		backlogIssueMoveDialogOpen ||
		backlogEnableDialogOpen ||
		columnDeleteModalIsOpen ||
		issueDeleteModalOpen ||
		addLabelModalOpen ||
		assignIssueParentModalOpen ||
		uiColumnLimitsModalOpen ||
		issueFlagWithCommentModalOpen,
);
