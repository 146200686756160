import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	toggleAccessibilityLabel: {
		id: 'software-filters.common.list.panel-toggle.toggle-accessibility-label',
		defaultMessage: 'Toggle panel',
		description: 'Accessibility label for a toggle to show / hide a panel in the backlog.',
	},
	toggleLabel: {
		id: 'software-filters.common.list.panel-toggle.toggle-label',
		defaultMessage: '{panelType} panel',
		description: 'Label beside a toggle, describing which panel that toggle will show / hide.',
	},
	toggleHint: {
		id: 'software-filters.common.list.panel-toggle.toggle-hint',
		defaultMessage: 'Press <kbd>{key}</kbd> to open and close',
		description: 'Hint message about keyboard shortcut to toggle (show / hide) the panel.',
	},
});
