import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	tooltip: {
		id: 'portfolio-3-plan-increment-common.ui.epic-story-point.tooltip',
		defaultMessage: "This issue has been estimated, but isn't included in capacity calculations.",
		description: 'Title for the page when users have no program boards',
	},
	tooltipIssueTermRefresh: {
		id: 'portfolio-3-plan-increment-common.ui.epic-story-point.tooltip-issue-term-refresh',
		defaultMessage:
			"This work item has been estimated, but isn't included in capacity calculations.",
		description: 'Title for the page when users have no program boards',
	},
});
