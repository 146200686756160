import { useCallback, useRef } from 'react';
import debounce from 'lodash/debounce';
import {
	createContainer,
	createActionsHook,
	createHook,
	createStore,
	type StoreActionApi,
} from '@atlassian/react-sweet-state';
import actions from './actions/index.tsx';
import type { Props, State, UserPropertyState, PropertyValue } from './types.tsx';

type Actions = typeof actions;

const initialState: State = {
	properties: {},
};

export const UserPropertiesStore = createStore<State, Actions>({
	initialState,
	actions,
	name: 'InsightsUserPropertiesStore',
});

export const useUserPropertiesActions = createActionsHook(UserPropertiesStore);

export const useUserProperty = createHook(UserPropertiesStore, {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	selector: (state: State, propertyKey: string): UserPropertyState<any> => {
		const isLoading = state.properties[propertyKey]?.isLoading;
		const currentValue = state.properties[propertyKey]?.value;
		const error = state.properties[propertyKey]?.error;

		const value = isLoading === false && currentValue !== undefined ? currentValue : null;

		return {
			value,
			isLoading: isLoading ?? false,
			error: error ?? null,
		};
	},
});

export const getNotExpiredPropertyValue = <T,>(
	propertyValue: PropertyValue<T>,
): PropertyValue<T> => {
	const currentDate = new Date().getTime();
	return Object.fromEntries(
		Object.entries(propertyValue).filter(
			([_, value]) => value.expireAt == null || new Date(value.expireAt).getTime() > currentDate,
		),
	);
};

const getExpireAt = (ttl: number): string => new Date(new Date().getTime() + ttl).toISOString();

export const useUserPropertyObject = <T,>(
	propertyKey: string,
	defaultTtlInMs?: number,
): [
	UserPropertyState<T>,
	{
		save: (
			saveKey: string,
			savePropertyValue: T,
			ttlInMs?: number,
			option?: { isResetOnFailEnabled?: boolean; debounceMs?: number },
		) => void;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		loadProperty: (...args: any) => void;
	},
] => {
	const [
		{ value: propertyValue, isLoading, error },
		{ saveProperty, loadProperty, savePropertyWithoutSendRequest, sendUpdatePropertyRequest },
	] = useUserProperty(propertyKey);

	const debouncedSendUpdatePropertyRequestRef = useRef<ReturnType<typeof debounce> | null>(null);

	const save = useCallback(
		(
			saveKey: string,
			savePropertyValue: T,
			ttlInMs?: number,
			options?: { isResetOnFailEnabled?: boolean; debounceMs?: number },
		) => {
			const ttl = ttlInMs || defaultTtlInMs || undefined;
			const newProperty = {
				...propertyValue,
				[saveKey]: {
					value: savePropertyValue,
					...(ttl ? { expireAt: getExpireAt(ttl) } : {}),
				},
			};
			const propertyValueNotExpired = getNotExpiredPropertyValue<T>(newProperty);
			if (options?.debounceMs) {
				if (debouncedSendUpdatePropertyRequestRef.current) {
					debouncedSendUpdatePropertyRequestRef.current.cancel();
				}
				debouncedSendUpdatePropertyRequestRef.current = debounce(
					sendUpdatePropertyRequest,
					options.debounceMs || 0,
				);

				savePropertyWithoutSendRequest(propertyKey, propertyValueNotExpired);
				debouncedSendUpdatePropertyRequestRef.current(propertyKey, propertyValueNotExpired);
			} else {
				saveProperty(propertyKey, propertyValueNotExpired, options?.isResetOnFailEnabled);
			}
		},
		[
			defaultTtlInMs,
			propertyValue,
			sendUpdatePropertyRequest,
			savePropertyWithoutSendRequest,
			propertyKey,
			saveProperty,
		],
	);

	const load = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(...args: any) => loadProperty.bind(null, propertyKey)(...args),
		[loadProperty, propertyKey],
	);

	if (!isLoading && !error && propertyValue != null) {
		const propertyValueNotExpired = getNotExpiredPropertyValue<T>(propertyValue);
		return [
			{
				value: propertyValueNotExpired,
				isLoading,
				error,
			},
			{ save, loadProperty: load },
		];
	}

	return [
		{
			value: propertyValue,
			isLoading,
			error,
		},
		{ save, loadProperty: load },
	];
};

const handlePropsChange =
	() =>
	({ dispatch }: StoreActionApi<State>, { preloadPropertyKeys }: Props) => {
		preloadPropertyKeys?.forEach((propertyKey: string) =>
			dispatch(actions.loadProperty(propertyKey)),
		);
	};

export const UserPropertiesContainer = createContainer<State, Actions, Props>(UserPropertiesStore, {
	onInit: handlePropsChange,
	onUpdate: handlePropsChange,
});
