import 'rxjs/add/observable/of';
import 'rxjs/add/observable/from';
import 'rxjs/add/operator/mergeMap';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import { type Observable as ObservableType, Observable } from 'rxjs/Observable';
import { fg } from '@atlassian/jira-feature-gating';
import type { Color } from '@atlassian/jira-issue-epic-color/src/common/types.tsx';
import {
	getRandomColor,
	toBackendColor,
} from '@atlassian/jira-issue-epic-color/src/common/utils.tsx';
import { PARENT_HIERARCHY_TYPE } from '@atlassian/jira-issue-type-hierarchies/src/index.tsx';
import {
	SYSTEM_LABEL_FIELD_ID,
	SINGLE_CUSTOM_FIELD_TYPE_KEY,
	CUSTOM_FIELD_OPTION_MISSED_ERROR,
	SWIMLANE_TEAMLESS,
} from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/constants.tsx';
import type { Sprint } from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/types.tsx';
import { fetchOptionsForCustomFields } from '@atlassian/jira-portfolio-3-plan-increment-common/src/services/fetch-options-for-custom-fields/index.tsx';
import type { Option } from '@atlassian/jira-portfolio-3-plan-increment-common/src/services/fetch-options-for-custom-fields/types.tsx';
import {
	transformDateValue,
	transformDateRangeUpdateValues,
} from '../../../../common/utils/increment-planning/index.tsx';
import type { DateRangeColumn } from '../../../../model/column/column-types.tsx';
import type { IncrementConfig } from '../../../../model/increment-config/increment-config-type.tsx';
import type {
	CreateIssueResponse,
	CreateValues,
	UpdateValues,
	CustomRequestHandlers,
} from '../../../../model/issue/issue-increment-planning-types.tsx';
import type { Issue } from '../../../../model/issue/issue-types.tsx';
import { getColumnById } from '../../../../state/selectors/column/column-selectors.tsx';
import { getIssueTypeById } from '../../../../state/selectors/issue/issue-selectors.tsx';
import { getIssueProjects } from '../../../../state/selectors/software/software-selectors.tsx';
import { getTeamSwimlaneColumnSprint } from '../../../../state/selectors/work/work-selectors.tsx';
import type { State } from '../../../../state/types.tsx';

const getTeamId = (teamId: string | null | undefined) =>
	teamId === SWIMLANE_TEAMLESS ? null : teamId;

export const transformOptimisticIssue = (
	optimisticIssue: Issue,
	dateFields: Omit<UpdateValues, 'estimate' | 'sprint' | 'team' | 'parent'>,
	epicLevel: boolean,
	sprint: Sprint | null | undefined,
): CreateValues => {
	const {
		projectId,
		teamId,
		typeId,
		summary,
		parentId,
		color,
		estimate,
		customFields,
		labels,
		priority,
	} = optimisticIssue;
	const team = getTeamId(teamId);
	const customFieldsInner = {
		...(customFields || {}),
		...(dateFields.customFields || {}),
	};
	return {
		project: projectId,
		...(!isNil(team) ? { team } : {}),
		...(!isNil(labels) && labels.length > 0 ? { labels } : {}),
		type: typeId,
		summary,
		...(!isNil(parentId) ? { parent: String(parentId) } : {}),
		estimate,
		...(epicLevel ? { color: getEpicColor(color) } : {}),
		...omit(dateFields, 'customFields'),
		...(isEmpty(customFieldsInner) ? {} : { customFields: customFieldsInner }),
		...(sprint && !epicLevel ? { sprint: `${sprint.id}` } : {}),
		...(!isNil(priority) && fg('issue_cards_in_program_board') ? { priority: priority.id } : {}),
	};
};
const setCustomFieldValue = (
	incrementConfig: IncrementConfig,
	optimisticIssue: Issue,
): ObservableType<Issue> => {
	const { projectId, typeId: issueTypeId } = optimisticIssue;
	const boardScopeFilter = incrementConfig?.boardScopeFilter;
	const filterFieldId = boardScopeFilter?.filterFieldId;
	// if there is not filterFieldId
	if (isNil(filterFieldId)) {
		return Observable.of(optimisticIssue);
	}
	if (filterFieldId === SYSTEM_LABEL_FIELD_ID) {
		const customFields = {
			[SYSTEM_LABEL_FIELD_ID]: [boardScopeFilter?.filterFieldValue || ''],
		};
		return Observable.of({
			...optimisticIssue,
			...customFields,
		});
	}
	const regex = /^(.*)_(.*)$/g;
	const fieldIdArray = regex.exec(filterFieldId || '') || [];
	const customFieldId = fieldIdArray.length > 3 ? '' : fieldIdArray[2];
	if (customFieldId.length < 1) {
		throw new Error("The customFieldId doesn't follow customfield_id format");
	}
	return fetchOptionsForCustomFields({
		projectId: `${projectId}`,
		issueTypeId: `${issueTypeId}`,
		customFieldId,
	}).flatMap((options: Option[]) => {
		const option = options.find((o) => o.value === boardScopeFilter?.filterFieldValue);
		if (isNil(option)) {
			throw new Error(CUSTOM_FIELD_OPTION_MISSED_ERROR);
		}
		let customFieldValue;
		if (boardScopeFilter?.filterFieldTypeKey === SINGLE_CUSTOM_FIELD_TYPE_KEY) {
			customFieldValue = option.id;
		} else {
			customFieldValue = [option.id];
		}
		return Observable.of<Issue>({
			...optimisticIssue,
			customFields: {
				[customFieldId]: customFieldValue,
			},
		});
	});
};

const createCardServiceInner = (
	state: State,
	incrementConfig: IncrementConfig,
	originalOptimisticIssue: Issue,
	customRequestHandlers: CustomRequestHandlers,
): ObservableType<Issue> => {
	const optimisticIssue = { ...originalOptimisticIssue };
	const column = getColumnById(state, optimisticIssue.columnId);
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const dateRange = (column as DateRangeColumn)?.dateRange;
	const {
		name: typeName,
		iconUrl: typeUrl,
		hierarchyLevelType,
	} = getIssueTypeById(state, optimisticIssue.typeId);
	const epicLevel: boolean = hierarchyLevelType === PARENT_HIERARCHY_TYPE;
	let dateFields = transformDateRangeUpdateValues(
		incrementConfig,
		// Only set start date for non epic issues
		!epicLevel ? transformDateValue(dateRange?.start) : undefined,
		transformDateValue(dateRange?.end),
	);
	const { columnId, teamId } = optimisticIssue;
	const sprint: Sprint | null | undefined = teamId
		? getTeamSwimlaneColumnSprint(state, columnId, teamId)
		: undefined;

	if (sprint) {
		if (epicLevel) {
			// for Epic level issues, update the date range to reflect the sprint dates if they exist
			if (sprint.endDate) {
				dateFields = transformDateRangeUpdateValues(
					incrementConfig,
					undefined,
					transformDateValue(sprint.endDate),
				);
			}
		} else {
			// for story level issues, if sprints are associated, don't update the date range, but need to update the sprint of issue
			dateFields = {};
			optimisticIssue.sprintId = `${sprint.id}`;
		}
	}

	// Estimate is an empty placeholder value that's only needed for optimistic update in the board,
	// but would break review changes in the plan as the format is incorrect for the plan
	const { estimate, ...description } = transformOptimisticIssue(
		optimisticIssue,
		dateFields,
		epicLevel,
		sprint,
	);

	const issueProjects = getIssueProjects(state);

	const createObservable = (result: CreateIssueResponse) => {
		const scenarioIssueId = result?.itemKey;
		//  we will not show the labels on the ip board issue card, so need to get rid of labels in here
		const optimisticIssueForBoard = !isEmpty(optimisticIssue.labels)
			? { ...optimisticIssue, labels: [] }
			: optimisticIssue;

		return Observable.of({
			...optimisticIssueForBoard,
			typeName,
			typeUrl,
			id: scenarioIssueId,
			key: issueProjects[optimisticIssue.projectId].key,
		});
	};

	if (!customRequestHandlers || !customRequestHandlers.addIssue) {
		throw new Error('no request handler found for creating issues');
	}
	const isCreateIssueResponse = (result: unknown): result is CreateIssueResponse =>
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		(result as CreateIssueResponse)?.itemKey !== undefined;

	return Observable.from(
		customRequestHandlers.addIssue({
			...description,
		}),
	).flatMap((result: unknown) => {
		if (isCreateIssueResponse(result)) {
			return createObservable(result);
		}
		// It should never reach here, adding the fallback due to the typechecks above
		return Observable.of(optimisticIssue);
	});
};

const createCardService = (
	state: State,
	incrementConfig: IncrementConfig,
	optimisticIssue: Issue,
	customRequestHandlers: CustomRequestHandlers,
): ObservableType<Issue> =>
	setCustomFieldValue(incrementConfig, optimisticIssue).flatMap((issue) =>
		createCardServiceInner(state, incrementConfig, issue, customRequestHandlers),
	);
export default createCardService;

function getEpicColor(color: Color | null | undefined) {
	return toBackendColor(color ?? getRandomColor());
}
