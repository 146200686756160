import type { Dispatch, Middleware } from 'redux';
import isEmpty from 'lodash/isEmpty';
import { fireTrackAnalytics } from '@atlassian/jira-analytics-web-react/src/utils/fire-track-event.tsx';
import type { Action } from '../../actions/index.tsx';
import type { State } from '../../reducers/types.tsx';
import type { MiddlewareAPI } from '../../types.tsx';
import getActionAnalyticsMap, { ANALYTIC_PREFIX } from './analytics-actions-map.tsx';
import type { AnalyticPayload } from './analytics-types.tsx';

const shouldTriggerAnalytics = ({ type }: Partial<Action> = {}) =>
	getActionAnalyticsMap()[type ?? ''] !== undefined;

const getAnalyticsForAction = (
	preActionState: State,
	postActionState: State,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	action: any,
): AnalyticPayload[] =>
	getActionAnalyticsMap()
		[action.type].map((createAnalytic) =>
			createAnalytic(ANALYTIC_PREFIX)(preActionState, postActionState, action),
		)
		.filter((analyticEvent) => analyticEvent !== null);

const triggerAnalyticsForAction = (analyticEvents: AnalyticPayload[]) => {
	if (isEmpty(analyticEvents)) {
		return;
	}

	analyticEvents.forEach(({ trackEvents = [] }) => {
		trackEvents.forEach((trackEvent) =>
			fireTrackAnalytics(trackEvent, { action: trackEvent.payload.action }),
		);
	});
};

// @ts-expect-error - Type 'MiddlewareAPI<S>' is not assignable to type 'MiddlewareAPI'.
const analyticsMiddleware: Middleware =
	(store: MiddlewareAPI) => (next: Dispatch) => (action: Action) => {
		if (!shouldTriggerAnalytics(action)) {
			return next(action);
		}
		const preActionState = store.getState();
		const result = next(action);
		const postActionState = store.getState();
		const analyticEvents = getAnalyticsForAction(preActionState, postActionState, action);
		triggerAnalyticsForAction(analyticEvents);

		return result;
	};

export default analyticsMiddleware;
