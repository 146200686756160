import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/operator/takeUntil';
import type { Epic, ActionsObservable } from 'redux-observable';
import { DISPOSE_STORE } from '../../constants.tsx';

export const disposableEpic =
	<A extends { type: any }, S, D>( // eslint-disable-line @typescript-eslint/no-explicit-any
		rootEpic: Epic<A, S>,
	): Epic<A, S> =>
	(action$: ActionsObservable<A>, store: MiddlewareAPI<S>, dependencies: D) =>
		rootEpic(action$, store, dependencies).takeUntil(action$.ofType(DISPOSE_STORE));
