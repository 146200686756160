import React, { memo, useState, useCallback, type ComponentType, type MouseEvent } from 'react';
import { styled } from '@compiled/react';
import isEqual from 'lodash/isEqual';
import traceUFOPress from '@atlaskit/react-ufo/trace-press';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { getValueFromColor } from '@atlassian/jira-issue-epic-color/src/common/utils.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { DEPENDENCY_LINE_CLASS_NAME } from '../constants.tsx';
import type { OverlayDependencyItem, OnDependencyClick } from '../types.tsx';
import messages from './messages.tsx';
import { getPath } from './utils.tsx';

type Props = {
	isSelected: boolean;
} & OverlayDependencyItem & {
		itemHeight: number;
		onClick: OnDependencyClick;
	};

/* Even though this component represents a single line, multiple elements are used to achieve all of the requirements.
 * 1. An additional element with a larger surface area is used to capture interactions to improve the UX.
 * 2. Gradients cannot be applied to SVG elements using CSS. For this we use a linearGradient element.
 */
const DependencyLine = ({ from, to, itemHeight, isOverlapping, isSelected, onClick }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isHovered, setIsHovered] = useState(false);

	const onPathClick = useCallback(
		(event: MouseEvent<SVGPathElement>) => {
			traceUFOPress('timeline-dependency-clicked', event.timeStamp);
			const analyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'line',
			});
			fireUIAnalytics(analyticsEvent, 'dependencyLine', { isOverlapping });

			onClick(event, {
				fromId: from.id,
				toId: to.id,
				isOverlapping,
				fromIndexOnScope: from.indexOnScope,
				toIndexOnScope: to.indexOnScope,
			});
		},
		[createAnalyticsEvent, isOverlapping, from, to, onClick],
	);

	const onPathMouseEnter = useCallback(() => {
		setIsHovered(true);
	}, []);

	const onPathMouseLeave = useCallback(() => {
		setIsHovered(false);
	}, []);

	const path = getPath(from, to, itemHeight);
	const gradientId = `linear-gradient-${from.id}-${to.id}`;

	return (
		<>
			{(isHovered || isSelected) && (
				<linearGradient
					id={gradientId}
					x1={from.x}
					x2={to.x}
					y1={from.y}
					y2={to.y}
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0%" stopColor={getValueFromColor(from.color)} />
					<stop offset="100%" stopColor={getValueFromColor(to.color)} />
				</linearGradient>
			)}
			<path
				d={path}
				fill="transparent"
				strokeWidth="2"
				stroke={getStrokeColor({ isOverlapping, isSelected, isHovered, gradientId })}
				pointerEvents="none"
				data-testid="aais-dependency-lines-overlay.common.ui.line.dependency-line"
			/>
			<Tooltip
				position="mouse"
				mousePosition="top"
				content={formatMessage(messages.viewDetail)}
				tag="g"
			>
				<InteractivePath
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={DEPENDENCY_LINE_CLASS_NAME}
					d={path}
					stroke="transparent"
					strokeWidth="9"
					fill="none"
					cursor="pointer"
					onClick={onPathClick}
					onMouseEnter={onPathMouseEnter}
					onMouseLeave={onPathMouseLeave}
					data-testid="aais-dependency-lines-overlay.common.ui.line.interactive-path"
				/>
			</Tooltip>
		</>
	);
};
DependencyLine.whyDidYouRender = true;
// Custom memo to handle common case where 'from' and 'to' objects change reference but not value
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default memo<Props>(DependencyLine, isEqual) as ComponentType<Props>;

type StrokeProps = {
	isOverlapping: boolean;
	isSelected: boolean;
	isHovered: boolean;
	gradientId: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InteractivePath = styled.path<Record<any, any>>({
	pointerEvents: 'visibleStroke',
});
export const getStrokeColor = ({
	isOverlapping,
	isSelected,
	isHovered,
	gradientId,
}: StrokeProps): string => {
	const isHighlighted = isSelected || isHovered;
	if (isOverlapping) {
		if (isHighlighted) {
			return token('color.background.danger.bold.hovered', colors.R500);
		}
		return token('color.background.danger.bold', colors.R300);
	}
	if (isHighlighted) {
		return `url(#${gradientId})`;
	}
	return token('color.border.bold', colors.N50);
};
