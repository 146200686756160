/* eslint-disable @atlassian/relay/unused-fields */
import { fetchQuery, graphql } from 'react-relay';
import { Observable as ObservableType } from 'rxjs/Observable';
import { createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import getRelayEnvironment from '@atlassian/jira-relay-environment/src/index.tsx';
import type { issueLinksPermissionsData_PermissionsAndIssueLinkTypesQuery } from '@atlassian/jira-relay/src/__generated__/issueLinksPermissionsData_PermissionsAndIssueLinkTypesQuery.graphql';
import { LINK_ISSUE } from '../../model/board/constants.tsx';
import type { IssuesLinksPermissionsResponse } from '../../model/issue-links-permissions/issue-links-permissions-types.tsx';
import type { IssuesLinksPermissionsProps } from './types.tsx';

export const issueLinksPermissionsDataQuery = graphql`
	query issueLinksPermissionsData_PermissionsAndIssueLinkTypesQuery(
		$cloudId: ID!
		$boardIdAri: ID!
		$isCMP: Boolean
	) {
		jira {
			issueLinkTypes(cloudId: $cloudId) @optIn(to: "JiraIssueLinkTypes") {
				edges {
					node {
						id @required(action: THROW)
						inwards @required(action: THROW)
						outwards @required(action: THROW)
					}
				}
			}
		}
		boardScope(boardId: $boardIdAri, isCMP: $isCMP) {
			currentUser {
				permissions
			}
		}
	}
`;

export const getIssueLinksPermissions = ({
	boardId,
	cloudId,
	isCMP,
}: IssuesLinksPermissionsProps): ObservableType<IssuesLinksPermissionsResponse> => {
	const boardIdAri = createAri({
		resourceOwner: 'jira-software',
		cloudId,
		resourceType: 'board',
		resourceId: String(boardId),
	});

	return ObservableType.fromPromise(
		fetchQuery<issueLinksPermissionsData_PermissionsAndIssueLinkTypesQuery>(
			getRelayEnvironment(),
			issueLinksPermissionsDataQuery,
			{
				boardIdAri,
				cloudId,
				isCMP,
			},
		)
			.toPromise()
			.then((data) => {
				const permissionsArray = data?.boardScope?.currentUser?.permissions || [];
				const issueLinkTypes = data?.jira?.issueLinkTypes?.edges?.length
					? data?.jira?.issueLinkTypes?.edges
					: [];
				return {
					canUserLinkIssue: permissionsArray.includes(LINK_ISSUE),
					issueLinkTypes,
				};
			}),
	);
};
