export type ICCSessionPayload = {
	sessionId?: string;
};

/** Sets the session ID for Inline create. */
export const SET_INLINE_CREATE_SESSION_ID =
	'state.actions.inline-create.SET_INLINE_CREATE_SESSION_ID' as const;

export type SetInlineCreateSessionId = {
	type: typeof SET_INLINE_CREATE_SESSION_ID;
	payload: ICCSessionPayload;
};

export const setInlineCreateSessionId = (sessionId?: string): SetInlineCreateSessionId => ({
	type: SET_INLINE_CREATE_SESSION_ID,
	payload: {
		sessionId,
	},
});

type SetSelectedProjectIdInIccPayload = {
	projectId: number;
};

/** Store the selected project id in ICC component */
export const SET_SELECTED_PROJECT_ID_IN_ICC =
	'state.actions.inline-create.SET_SELECTED_PROJECT_ID_IN_ICC' as const;

export type SetSelectedProjectIdInIccAction = {
	type: typeof SET_SELECTED_PROJECT_ID_IN_ICC;
	payload: SetSelectedProjectIdInIccPayload;
};

export const setSelectedProjectIdInIcc = (projectId: number): SetSelectedProjectIdInIccAction => ({
	type: SET_SELECTED_PROJECT_ID_IN_ICC,
	payload: {
		projectId,
	},
});

/** Clears the session ID for Inline create. */
export const CLEAR_INLINE_CREATE_SESSION_ID =
	'state.actions.inline-create.CLEAR_INLINE_CREATE_SESSION_ID' as const;

export type ClearInlineCreateSessionId = {
	type: typeof CLEAR_INLINE_CREATE_SESSION_ID;
};

export const clearInlineCreateSessionId = (): ClearInlineCreateSessionId => ({
	type: CLEAR_INLINE_CREATE_SESSION_ID,
});

export type Action =
	| SetInlineCreateSessionId
	| ClearInlineCreateSessionId
	| SetSelectedProjectIdInIccAction;
