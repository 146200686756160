import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	sprintSelection: {
		id: 'software.main.header.sprint-controls.sprint-details.popup-content.sprint-selection',
		defaultMessage:
			'{totalCount, plural, one {Sprints {selectedCount} of {totalCount}} other {Sprints {selectedCount} of {totalCount}}}',
		description:
			'Used when displaying a summary of all the sprints. Since we can select a subset of sprints, this message tells how many sprints are selected out of all the active sprints. This message is not displayed when we have only 1 sprint, so totalCount will always be > 1 and selectedCount will always be >= 1',
	},
	remainingDays: {
		id: 'software.main.header.sprint-controls.sprint-details.popup-content.remaining-days',
		defaultMessage: '{remainingDays} {remainingDays, plural, one {day} other {days}} left',
		description: 'Duration left in the current sprint, in days.',
	},
	startDate: {
		id: 'software.main.header.sprint-controls.sprint-details.popup-content.start-date',
		defaultMessage: 'Start date',
		description: 'Label for the sprint start date',
	},
	endDate: {
		id: 'software.main.header.sprint-controls.sprint-details.popup-content.end-date',
		defaultMessage: 'End date',
		description: 'Label for the sprint projected end date',
	},
	invalidDate: {
		id: 'software.main.header.sprint-controls.sprint-details.popup-content.invalid-date',
		defaultMessage: 'Invalid date',
		description:
			'Fallback text for a date that can’t be parsed thus cannot be properly displayed in the UI.',
	},
});
