import { useEffect } from 'react';
import isNil from 'lodash/isNil';
import { useIntl } from '@atlassian/jira-intl';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import { timeTrackingFormatter } from '@atlassian/jira-time-tracking-formatter/src/main.tsx';
import { useDiffCheck } from '../../../../../common/hooks/use-diff-check/index.tsx';
import {
	type Estimate,
	TMP_ORIGINAL_TIME_ESTIMATE,
} from '../../../../../model/issue/issue-types.tsx';
import { setEstimate } from '../../../../../state/actions/issue/estimate/index.tsx';
import { useBoardActionCreator, useBoardSelector } from '../../../../../state/index.tsx';
import { getTimeTrackingOptions } from '../../../../../state/selectors/work/work-selectors.tsx';
import type { FieldSyncOriginalEstimateProps } from './types.tsx';

export const FieldSyncOriginalEstimate = ({
	issue: { id, key, estimate },
	originalEstimateFieldId,
}: FieldSyncOriginalEstimateProps) => {
	const intl = useIntl();
	const timeTrackingOptions = useBoardSelector(getTimeTrackingOptions);
	const setEstimateInBoard = useBoardActionCreator((issueId: IssueId, value: Estimate) =>
		setEstimate(issueId, value),
	);

	const originalEstimateFromBoard = estimate?.value ?? null;

	const [originalEstimateFromIssueField, { setFieldValue: setOriginalEstimateInExtractedField }] =
		useFieldValue({
			issueKey: key,
			fieldKey: originalEstimateFieldId,
		});

	const originalEstimateFromBoardChanged = useDiffCheck([originalEstimateFromBoard]);
	const originalEstimateFromIssueFieldChanged = useDiffCheck([originalEstimateFromIssueField]);

	const storesNeedToSync = originalEstimateFromBoard !== originalEstimateFromIssueField;

	useEffect(() => {
		// Board -> Issue Field Sync
		if (originalEstimateFromBoardChanged && storesNeedToSync) {
			setOriginalEstimateInExtractedField(key, originalEstimateFieldId, originalEstimateFromBoard);
		}
	}, [
		key,
		originalEstimateFromBoard,
		originalEstimateFromBoardChanged,
		originalEstimateFieldId,
		setOriginalEstimateInExtractedField,
		storesNeedToSync,
		timeTrackingOptions,
	]);

	useEffect(() => {
		// Issue Field -> Board Sync
		if (
			// Ensure no sync will be called by Board as Board is the driver
			!originalEstimateFromBoardChanged &&
			originalEstimateFromIssueFieldChanged &&
			storesNeedToSync
		) {
			setEstimateInBoard(
				id,
				!isNil(originalEstimateFromIssueField)
					? {
							type: TMP_ORIGINAL_TIME_ESTIMATE,
							fieldId: originalEstimateFieldId,
							value: originalEstimateFromIssueField,
							valueAsText: timeTrackingFormatter(
								originalEstimateFromIssueField,
								timeTrackingOptions,
								intl,
							),
						}
					: null,
			);
		}
	}, [
		id,
		setEstimateInBoard,
		storesNeedToSync,
		originalEstimateFromBoardChanged,
		originalEstimateFromIssueField,
		originalEstimateFromIssueFieldChanged,
		timeTrackingOptions,
		intl,
		originalEstimateFieldId,
	]);

	return null;
};
