import React, { createContext, useRef, useContext, useEffect, type ReactNode } from 'react';

export class SuspenseTrackerSubject {
	suspendCount = 0;

	getHasSuspended(): boolean {
		return this.suspendCount !== 0;
	}

	/**
	 * Should be called on unmount of the tracked sub-tree
	 */
	reset() {
		this.suspendCount = 0;
	}

	/**
	 * Should be called when a suspend happens
	 */
	onSuspend() {
		this.suspendCount += 1;
	}
}

const SuspenseTrackerContext = createContext<SuspenseTrackerSubject>(new SuspenseTrackerSubject());

type ProviderProps = {
	children: ReactNode;
};

/**
 * Provides a tracker subject to a sub-tree. Different trees may use different providers to track
 * different suspend boundaries.
 */
export const SuspenseTrackerProvider = ({ children }: ProviderProps) => {
	const suspenseTrackerRef = useRef<SuspenseTrackerSubject>(new SuspenseTrackerSubject());

	useEffect(
		() => () => {
			suspenseTrackerRef.current?.reset();
		},
		[],
	);

	return (
		<SuspenseTrackerContext.Provider value={suspenseTrackerRef.current}>
			{children}
		</SuspenseTrackerContext.Provider>
	);
};

/**
 * Should be mounted on the `fallback=` path of the suspense boundary to be tracked.
 */
export const SuspenseTrackerFallback = () => {
	const suspenseTracker = useContext(SuspenseTrackerContext);
	useEffect(() => {
		suspenseTracker.onSuspend();
	}, [suspenseTracker]);
	return null;
};

/**
 * Returns true if at the time of this render, the sub-tree has been suspended at least once.
 *
 * Does not notify the component of changes in this value.
 */
export const useGetHasSuspended = (): boolean => {
	const suspenseTracker = useContext(SuspenseTrackerContext);
	return suspenseTracker.getHasSuspended();
};
