import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	successfulDeploymentsHeading: {
		id: 'automation-platform.automation-recommendation.explainer-section.successful-deployments-heading',
		defaultMessage: 'Move issues to done for successful deployments',
		description: 'Explains what the automation recommendation will do once configured',
	},
	failedDeploymentsHeading: {
		id: 'automation-platform.automation-recommendation.explainer-section.failed-deployments-heading',
		defaultMessage: 'Notify your team about failed deployments',
		description: 'Explains what the automation recommendation will do once configured',
	},
	versionsCreatedHeading: {
		id: 'automation-platform.automation-recommendation.explainer-section.versions-created-heading',
		defaultMessage: 'Create feature flags for assigned versions',
		description: 'Explains what the automation recommendation will do once configured',
	},
	criticalVulnerabilitiesHeading: {
		id: 'automation-platform.automation-recommendation.explainer-section.critical-vulnerabilities-heading',
		defaultMessage: 'Create issues for security vulnerabilities',
		description: 'Explains what the automation recommendation will do once configured',
	},
	successfulDeploymentsDescription: {
		id: 'automation-platform.automation-recommendation.explainer-section.successful-deployments-description',
		defaultMessage:
			'We found issues with successful deployments for this project. Create a rule to automatically move issues to done.',
		description: 'Explains to the user why are we showing this recommendation',
	},
	failedDeploymentsDescription: {
		id: 'automation-platform.automation-recommendation.explainer-section.failed-deployments-description',
		defaultMessage:
			'We found issues with failed deployments in this project. Create a rule to automatically notify the right people when this happens.',
		description: 'Explains to the user why are we showing this recommendation',
	},
	versionsCreatedDescription: {
		id: 'automation-platform.automation-recommendation.explainer-section.versions-created-description',
		defaultMessage:
			'We found issues with assigned versions for this project. Create a rule to automatically create feature flags.',
		description: 'Explains to the user why are we showing this recommendation',
	},
	criticalVulnerabilitiesDescription: {
		id: 'automation-platform.automation-recommendation.explainer-section.critical-vulnerabilities-description',
		defaultMessage:
			'We found security vulnerabilities in this project. Create a rule to automatically create issues.',
		description: 'Explains to the user why are we showing this recommendation',
	},
});
