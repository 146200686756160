import type { Action } from '@atlassian/react-sweet-state';
import type { State } from '../../types.tsx';

export const toggleKeepIccOpen =
	(): Action<State> =>
	({ getState, setState }) => {
		const { keepIccOpen: iccOpenState } = getState();

		setState({
			keepIccOpen: !iccOpenState,
		});
	};
