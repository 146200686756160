import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	activeGroupLabel: {
		id: 'portfolio-3-plan-increment-common.ui.board-switcher.active-group-label',
		defaultMessage: 'Active',
		description: 'Label for the active program boards group',
	},
	pastGroupLabel: {
		id: 'portfolio-3-plan-increment-common.ui.board-switcher.past-group-label',
		defaultMessage: 'Past',
		description: 'Label for the past program boards group',
	},
	createProgramBoard: {
		id: 'portfolio-3-plan-increment-common.ui.board-switcher.create-program-board',
		defaultMessage: 'Create a new program board',
		description: 'Button to create a new program board',
	},
	manageProgramBoards: {
		id: 'portfolio-3-plan-increment-common.ui.board-switcher.manage-program-boards',
		defaultMessage: 'Manage program boards',
		description: 'Link to the settings page to manage program boards',
	},
	manageProgramBoardsTitle: {
		id: 'portfolio-3-plan-increment-common.ui.board-switcher.manage-program-boards-title',
		defaultMessage: 'Navigate to Program board settings page',
		description: 'Title for link to the settings page to manage program boards',
	},
});
