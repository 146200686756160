import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	defaultTitle: {
		id: 'software-view-settings.common.panel.default-title',
		defaultMessage: 'View settings',
		description: "A settings menu for a board's view settings",
	},
	closeButtonLabel: {
		id: 'software-view-settings.common.panel.close-button-label',
		defaultMessage: 'Close',
		description: 'Label for close button with cross icon',
	},
});
