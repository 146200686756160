import React, { type ComponentType } from 'react';

export function asClientOnly<Props extends JSX.IntrinsicAttributes>(
	WrappedComponent: ComponentType<Props>,
): ComponentType<Props> {
	return (props: Props) => {
		if (__SERVER__) {
			return null;
		}
		return <WrappedComponent {...props} />;
	};
}

export default asClientOnly;
