import type { MigrationStatus } from '../../common/types/index.tsx';

export const ContainerType = {
	JIRA_PROJECT: 'JiraProject',
	SITE: 'Site',
} as const;

export type ContainerType = (typeof ContainerType)[keyof typeof ContainerType];

export const TransferStatus = {
	READY: 'READY',
	IN_PROGRESS: 'IN_PROGRESS',
	SUCCESS: 'SUCCESS',
	FAILED: 'FAILED',
	SKIPPED: 'SKIPPED',
	INCOMPLETE: 'INCOMPLETE',
	CANCELLED: 'CANCELLED',
} as const;

export type TransferStatus = (typeof TransferStatus)[keyof typeof TransferStatus];

export type Transfer = {
	transferId: string;
	businessStatus: TransferStatus;
	executionStatus: TransferStatus;
	statusMessage?: string;
	createdAt: number;
	startedAt?: string;
	lastUpdatedAt?: string;
	operationKey: string;
	progressPercentage?: number;
	progressMessage?: string;
	progressProperties?: {
		createdEntities?: number;
		failedEntities?: number;
		totalEntities?: number;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[key: string]: any;
	};
};

export type ContainerTransferStatus = {
	containerId: string;
	type: ContainerType;
	createdAt: number;
	startedAt?: string;
	lastUpdatedAt?: string;
	businessStatus: TransferStatus;
	executionStatus: TransferStatus;
	statusMessage?: string;
	transfers: Transfer[];
	hasErrorLogs?: boolean;
};

export type UsePollMigrationStatus = {
	migrationStatus: MigrationStatus;
};
