/**
 * @generated SignedSource<<6fa8139ab68de2adaf65c8eae3998ecd>>
 * @relayHash ea4312ab52f27e8938047934d2f94d9a
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 7136bf3a8b621191f5688b40ac28c430f5e6237f2ebcb6fea7a09680f67dff05

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { mainCompleteSprintMetadataQuery } from './mainCompleteSprintMetadataQuery.graphql';

const node: PreloadableConcreteRequest<mainCompleteSprintMetadataQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "7136bf3a8b621191f5688b40ac28c430f5e6237f2ebcb6fea7a09680f67dff05",
    "metadata": {},
    "name": "mainCompleteSprintMetadataQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
