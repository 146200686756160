import type { Dispatch } from 'redux';
import uniqBy from 'lodash/uniqBy';
import uniqWith from 'lodash/uniqWith';
import memoizeOne from 'memoize-one';
import { expVal } from '@atlassian/jira-feature-experiments';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import type { ColumnId as BoardKitColumnId } from '@atlassian/jira-platform-board-kit/src/common/types.tsx';
import { ColumnTransitionZonesContainer } from '@atlassian/jira-platform-board-kit/src/ui/column/column-transition-zones-container/main.tsx';
import type { CardTransitionZoneInfo } from '@atlassian/jira-platform-board-kit/src/ui/column/column-transition-zones-container/transition-zone/types.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { Status } from '../../../../../model/software/software-types.tsx';
import { NO_SWIMLANE } from '../../../../../model/swimlane/swimlane-modes.tsx';
import type { SwimlaneId } from '../../../../../model/swimlane/swimlane-types.tsx';
import {
	cardDragOverTransitionRequest,
	type CardDragOverTransitionRequestAction,
	cardDragSelectedTransition,
	type CardDragSelectedTransitionAction,
} from '../../../../../state/actions/card/index.tsx';
import {
	draggingIssueTransitionsForColumnSelector,
	makeGetShowAllTransitionZones,
} from '../../../../../state/selectors/card-transitions/card-transitions-selectors.tsx';
import {
	draggingCardHoverIntentSelector,
	draggingCardSourceColumnIdSelector,
	draggingCardSourceStatusSelector,
	draggingCardTargetColumnIdSelector,
} from '../../../../../state/selectors/card/card-selectors.tsx';
import { getIsCMPBoard } from '../../../../../state/selectors/software/software-selectors.tsx';
import { getSwimlaneMode } from '../../../../../state/selectors/swimlane/swimlane-mode-selectors.tsx';
import type { State } from '../../../../../state/types.tsx';
import messages from './messages.tsx';

type OwnProps = {
	columnId: BoardKitColumnId;
	swimlaneId?: SwimlaneId;
};
type StateProps = {
	hasSwimlanes: boolean;
	columnId: BoardKitColumnId;
	sourceColumnId: BoardKitColumnId;
	sourceStatus: Status | null | undefined;
	transitions: CardTransitionZoneInfo[];
	hoverIntent: boolean;
	columnDraggingOver: BoardKitColumnId | null;
	notAvailableMessage: MessageDescriptor;
	noTransitionMessage?: MessageDescriptor;
	showAllTransitionZones: boolean;
	isLoading?: boolean;
	shouldRenderFullColumnTransitionZone?: boolean;
	hideSourceStatusInLabel?: boolean;
};
type DispatchProps = {
	onCardDragOver: (transitionId: number | null, hoverIntent: boolean) => unknown;
	onTransitionSelected: (transitionId: number) => unknown;
};

const checkIfTransitionIsEqual = (source: CardTransitionZoneInfo, target: CardTransitionZoneInfo) =>
	Object.keys(source)
		.filter((key) => key !== 'transitionId')
		.every(
			(key) =>
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				source[key as keyof CardTransitionZoneInfo] ===
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				target[key as keyof CardTransitionZoneInfo],
		);

export const mapStateToProps = () => {
	const deduplicatedTransitionsFactory = memoizeOne((transitions: CardTransitionZoneInfo[]) =>
		uniqWith(transitions, checkIfTransitionIsEqual),
	);
	return (state: State, ownProps: OwnProps): StateProps => {
		const { columnId, swimlaneId } = ownProps;

		const hasSwimlanes = getSwimlaneMode(state) !== NO_SWIMLANE.id;
		const sourceColumnId = draggingCardSourceColumnIdSelector(state) || 0;
		const { transitions, isLoading } = draggingIssueTransitionsForColumnSelector(state)(
			Number(columnId),
		);

		const sourceStatus = draggingCardSourceStatusSelector(state);
		const showAllTransitionZones = makeGetShowAllTransitionZones(state)(swimlaneId);
		const isCMPBoard = getIsCMPBoard(state);

		// Determine IF there are duplicate target dropzones. This can be done if there are multiple
		// transitions that have the same target status Id.
		const shouldShowTransitionNames =
			uniqBy(transitions, 'targetStatusId').length !== transitions.length;

		// We can still get duplicates here that we don't want to render. If they have the same transition name,
		// targetStatusId and isGlobal we DO NOT want to render them because they are fundamentally identical.
		const deduplicatedTransitions = deduplicatedTransitionsFactory(transitions);

		return {
			columnId,
			sourceColumnId,
			hasSwimlanes,
			hoverIntent: draggingCardHoverIntentSelector(state),
			columnDraggingOver: draggingCardTargetColumnIdSelector(state) || null,
			notAvailableMessage: expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? messages.notAvailableMessageIssueTermRefresh
				: messages.notAvailableMessage,
			noTransitionMessage: expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? messages.noTransitionMessageIssueTermRefresh
				: messages.noTransitionMessage,
			transitions: deduplicatedTransitions,
			isLoading,
			sourceStatus,
			showAllTransitionZones,
			shouldRenderFullColumnTransitionZone: isCMPBoard,
			hideSourceStatusInLabel: !shouldShowTransitionNames,
		};
	};
};

export const mapDispatchToProps = (
	dispatch: Dispatch<CardDragOverTransitionRequestAction | CardDragSelectedTransitionAction>,
	{ columnId, swimlaneId }: OwnProps,
): DispatchProps => ({
	onCardDragOver: (transitionId, hoverIntent) =>
		dispatch(cardDragOverTransitionRequest(transitionId, Number(columnId), hoverIntent)),
	onTransitionSelected: (transitionId) =>
		dispatch(cardDragSelectedTransition(transitionId, Number(columnId), swimlaneId)),
});

const ConnectedColumnTransitionZonesContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
)(ColumnTransitionZonesContainer);

export default ConnectedColumnTransitionZonesContainer;
