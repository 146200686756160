import original from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { FetchUrlError } from '../../errors/index.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchJson = async <TResponse = any,>(
	url: string,
	options: RequestInit = {},
): Promise<TResponse> =>
	original(url, options).catch((error) => {
		throw new FetchUrlError(url, error);
	});
