import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';

export const getDestinationColumnIndex = (
	closestEdge: Edge,
	sourceColumnIndex: number,
	destinationColumnIndex: number,
) => {
	if (sourceColumnIndex < destinationColumnIndex) {
		return closestEdge === 'left' ? destinationColumnIndex - 1 : destinationColumnIndex;
	}

	return closestEdge === 'left' ? destinationColumnIndex : destinationColumnIndex + 1;
};

export const getDestinationCardIndex = ({
	closestEdge,
	sourceCardIndex,
	destinationCardIndex,
	isSameColumnAndSwimlane,
}: {
	closestEdge: Edge;
	sourceCardIndex: number;
	destinationCardIndex: number;
	isSameColumnAndSwimlane: boolean;
}) => {
	if (isSameColumnAndSwimlane && sourceCardIndex < destinationCardIndex) {
		return closestEdge === 'top' ? destinationCardIndex - 1 : destinationCardIndex;
	}

	return closestEdge === 'top' ? destinationCardIndex : destinationCardIndex + 1;
};
