import get from 'lodash/get';
import type {
	StringifiableValue,
	UrlBinding,
} from '@atlassian/jira-common-bind-url-to-state/src/index.tsx';
import * as presets from '@atlassian/jira-common-url-transform-presets/src/index.tsx';
import { cardAutoScrollRequestOnLoad } from '../../state/actions/card/card-auto-scroll/index.tsx';
import { openIssueModal } from '../../state/actions/issue/modal/index.tsx';
import { openInsightsPanel } from '../../state/actions/panels/index.tsx';
import { getUi } from '../../state/selectors/software/software-selectors.tsx';
import type { State, Dispatch, Action } from '../../state/types.tsx';

export default function softwareUrlBindings(
	isIncrementPlanningBoard = false,
): UrlBinding<State, Action>[] {
	/**
	 * We won't use any of the url keys listed below in increment planning boards.
	 */
	if (isIncrementPlanningBoard) {
		return [];
	}
	return [
		{
			urlKey: 'selectedIssue',
			transform: presets.string,
			getValueForUrl: (state: State) => {
				const key = get(getUi(state), ['issueModal', 'selectedIssueKey'], undefined);
				if (key) {
					return key;
				}
				return undefined;
			},
			setValueFromUrl: (dispatch: Dispatch, value: StringifiableValue) => {
				if (value && typeof value === 'string') {
					dispatch(openIssueModal(value));
					dispatch(cardAutoScrollRequestOnLoad(value));
				}
			},
		},
		{
			urlKey: 'isInsightsOpen',
			transform: presets.bool,
			getValueForUrl: (state: State) => {
				const key = get(getUi(state), ['panels', 'INSIGHTS_PANEL'], undefined);
				if (key) {
					return key;
				}
				return undefined;
			},
			setValueFromUrl: (dispatch: Dispatch, value: StringifiableValue) => {
				if (value) {
					dispatch(openInsightsPanel());
				}
			},
		},
	];
}
