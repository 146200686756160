import { useCallback } from 'react';
import type { IntlShape } from '@atlassian/jira-intl';
import { formatWithLocale } from '@atlassian/jira-platform-utils-date-fns/src/main.tsx';
import { formatTimestampWithIntl } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/format.tsx';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';

export const useFormatDateUTC = (
	intl: IntlShape,
	showYear = false,
	showTime = false,
	showTimeZoneName = false,
) =>
	useCallback(
		(date: number) => {
			const dateTimeFormatOptions = {
				day: '2-digit',
				month: 'short',
				timeZone: 'UTC',
				...(showYear && { year: 'numeric' }),
				...(showTime && {
					hour: 'numeric',
					minute: '2-digit',
					hour12: true,
				}),
				...(showTimeZoneName && { timeZoneName: 'short' }),
			};
			return formatTimestampWithIntl(intl, date, dateTimeFormatOptions);
		},
		[intl, showYear, showTime, showTimeZoneName],
	);

export const useFormatDate = () => {
	const locale = useLocale();

	return useCallback(
		(date: number, showYear = false) =>
			showYear
				? formatWithLocale(date, 'd MMM yyyy', locale)
				: formatWithLocale(date, 'd MMM', locale),
		[locale],
	);
};

export const useFormatDateRange = () => {
	const formatDate = useFormatDate();

	return useCallback(
		(startDate: number, endDate: number) => {
			const isDifferentYear =
				new Date(startDate).getUTCFullYear() !== new Date(endDate).getUTCFullYear();
			return `${formatDate(startDate, isDifferentYear)} – ${formatDate(endDate, isDifferentYear)}`;
		},
		[formatDate],
	);
};
