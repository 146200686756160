import {
	MIXED,
	type InlineCardCreateColumnConfig,
	type InlineCardCreateConfig,
	type InlineCardCreateMode,
} from '../../../model/inline-create/inline-card-create-types.tsx';

export const isFullOrPartialMode = (mode?: InlineCardCreateMode) =>
	mode === 'FULL' || mode === 'PARTIAL';

export const isIccEnabledForBoard = (iccConfig?: InlineCardCreateConfig) =>
	isFullOrPartialMode(iccConfig?.mode);

export const isIccEnabledForColumn = (
	iccColumnConfig?: InlineCardCreateColumnConfig,
	mixedCountsAsEnabled = true,
) =>
	isFullOrPartialMode(iccColumnConfig?.mode) ||
	(mixedCountsAsEnabled && iccColumnConfig?.mode === MIXED);
