import React, { memo } from 'react';
import { SIMPLE_SOFTWARE_BOARD } from '@atlassian/jira-common-constants/src/analytics-sources.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { ActionMenuForgeRuntimeContainer } from '@atlassian/jira-forge-ui-action-apps/src/ui/action-menu/runtime-container.tsx';
import { AsyncBoardAction } from '@atlassian/jira-forge-ui-async/src/ui/board-action/async.tsx';
import { BOARD_ACTION_MODULE } from '@atlassian/jira-forge-ui-constants/src/constants.tsx';

type BoardActionForgeRuntimeContainerProps = {
	onAppsFinishLoading?: () => void;
};

export const BoardActionForgeRuntimeContainer: React.FC<BoardActionForgeRuntimeContainerProps> =
	memo(({ onAppsFinishLoading }) => {
		return (
			<ErrorBoundary packageName="software" id="forge-board-action-runtime-container">
				<ActionMenuForgeRuntimeContainer
					onAppsFinishLoading={onAppsFinishLoading}
					forgeAppRenderer={AsyncBoardAction}
					module={BOARD_ACTION_MODULE}
					analyticsSource={SIMPLE_SOFTWARE_BOARD}
				/>
			</ErrorBoundary>
		);
	});
