import flow from 'lodash/flow';
import { AnalyticsEventToProps } from '@atlassian/jira-product-analytics-bridge';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { dismissFlag } from '../../state/actions/flags/index.tsx';
import { openIssueModal } from '../../state/actions/issue/modal/index.tsx';
import type { State } from '../../state/types.tsx';
import Flags from './view.tsx';

export default flow(
	connect(
		(state: State) => ({
			flags: state.ui.flags,
			externalId: 'software.board',
		}),
		{
			dismissFlag,
			openIssueModal,
		},
	),
	AnalyticsEventToProps('Flags', {
		onPrimaryActionClick: 'primaryActionClicked',
	}),
)(Flags);
