import { set, unset } from 'icepick';
import { INSIGHTS_PANEL, VIEW_SETTINGS_PANEL } from '../../../../model/panels/constants.tsx';
import {
	TOGGLE_PANEL,
	CLOSE_INSIGHTS_PANEL,
	OPEN_INSIGHTS_PANEL,
	CLOSE_VIEW_SETTINGS_PANEL,
} from '../../../actions/panels/index.tsx';
import type { Action } from '../../../types.tsx';
import type { PanelState } from './types.tsx';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const initialState = {} as PanelState;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: PanelState = initialState, action: Action): PanelState => {
	switch (action.type) {
		case TOGGLE_PANEL: {
			const {
				payload: { panel },
			} = action;

			// For other panels that exist in future, we can close them here if needed

			return state[panel] === true ? unset(state, panel) : set(state, panel, true);
		}

		case OPEN_INSIGHTS_PANEL: {
			return set(state, INSIGHTS_PANEL, true);
		}

		case CLOSE_INSIGHTS_PANEL: {
			return unset(state, INSIGHTS_PANEL);
		}

		case CLOSE_VIEW_SETTINGS_PANEL: {
			return unset(state, VIEW_SETTINGS_PANEL);
		}
		default: {
			// @ts-expect-error - TS2322: Type 'ShowErrorFlagAction | SetPersonAction | AddToSprintRequestAction | AddToSprintSuccessAction | ... 253 more ... | UnsupportedAction' is not assignable to type 'never'.
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
