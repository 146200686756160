import React, { memo, useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import type { CustomFilter as CustomFilterType } from '@atlassian/jira-filters/src/common/types.tsx';
import CustomFilter, {
	type CustomFilterProps,
} from '@atlassian/jira-filters/src/ui/filters/custom/index.tsx';
import { FireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { customFilter_softwareFilters_CustomFilterRelay$key } from '@atlassian/jira-relay/src/__generated__/customFilter_softwareFilters_CustomFilterRelay.graphql';

export type Props = Flow.Diff<
	CustomFilterProps,
	{
		filters?: CustomFilterType[];
	}
> & {
	customFiltersFragmentRef: customFilter_softwareFilters_CustomFilterRelay$key;
	screenName: string;
};

const CustomFilterRelay = ({ customFiltersFragmentRef, screenName, ...props }: Props) => {
	const { boardScope } = useFragment(
		graphql`
			fragment customFilter_softwareFilters_CustomFilterRelay on Query
			@argumentDefinitions(boardId: { type: "ID!" }) {
				boardScope(boardId: $boardId) @required(action: THROW) {
					customFilters @required(action: THROW) {
						# eslint-disable-next-line @atlassian/relay/unused-fields
						id @required(action: THROW)
						# eslint-disable-next-line @atlassian/relay/unused-fields
						name @required(action: THROW)
					}
				}
			}
		`,
		customFiltersFragmentRef,
	);

	// each item in an array is nullable & an object's keys readonly in relay's generated type
	const filters = useMemo(
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		() => (boardScope?.customFilters.filter(Boolean) as CustomFilterType[]) || [],
		[boardScope?.customFilters],
	);

	return (
		<>
			<CustomFilter {...props} filters={filters} />

			<FireOperationalAnalytics
				actionSubject="ui"
				action="taskSuccess"
				attributes={{
					screenName,
					task: 'loadCustomFilters',
					customFiltersCount: filters.length,
				}}
			/>
		</>
	);
};

export default memo(CustomFilterRelay);
