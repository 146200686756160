import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/switchMap';
import { OTHER } from '../../model/issue/issue-update-origin.tsx';
import { CARD_DATA_SET, type CardDataSetAction } from '../../state/actions/card/index.tsx';
import { updateDetailView } from '../../state/actions/detail-view/index.tsx';
import {
	ISSUE_ADD_LABELS_SUCCESS,
	type IssueAddLabelsSuccessAction,
} from '../../state/actions/issue/add-label-modal/index.tsx';
import {
	ISSUE_FLAG_WITH_COMMENT_SUCCESS,
	type IssueFlagWithCommentSuccessAction,
} from '../../state/actions/issue/flag-with-comment/index.tsx';
import {
	SET_PARENT_SUCCESS,
	UNSET_PARENT_SUCCESS,
	SET_PARENT_OPTIMISTIC,
	type SetParentSuccessAction,
	type UnsetParentSuccessAction,
	type SetParentOptimisticAction,
} from '../../state/actions/issue/parent/index.tsx';
import {
	ISSUE_RANK_TRANSITION_SUCCESS,
	type IssueRankTransitionSuccessAction,
} from '../../state/actions/issue/rank-transition/index.tsx';
import {
	ISSUE_UPDATE_SUCCESS,
	type IssueUpdateSuccessAction,
} from '../../state/actions/issue/update/index.tsx';
import {
	getIssueIdForModal,
	getIssueByIdForModal,
} from '../../state/selectors/issue/issue-modal-selectors.tsx';
import type { ActionsObservable, MiddlewareAPI } from '../../state/types.tsx';

export const detailViewUpdateEpic = (action$: ActionsObservable, store: MiddlewareAPI) =>
	action$
		.ofType(
			CARD_DATA_SET,
			ISSUE_UPDATE_SUCCESS,
			ISSUE_FLAG_WITH_COMMENT_SUCCESS,
			ISSUE_ADD_LABELS_SUCCESS,
			ISSUE_RANK_TRANSITION_SUCCESS,
			SET_PARENT_SUCCESS,
			UNSET_PARENT_SUCCESS,
			SET_PARENT_OPTIMISTIC,
		)
		.switchMap(
			(
				action:
					| CardDataSetAction
					| IssueUpdateSuccessAction
					| IssueFlagWithCommentSuccessAction
					| IssueAddLabelsSuccessAction
					| IssueRankTransitionSuccessAction
					| SetParentSuccessAction
					| UnsetParentSuccessAction
					| SetParentOptimisticAction,
			) => {
				const state = store.getState();
				const issueId = getIssueIdForModal(state);

				if (!issueId) {
					return Observable.empty<never>();
				}

				if (
					(action.type === CARD_DATA_SET || action.type === ISSUE_UPDATE_SUCCESS) &&
					action.payload.origin === OTHER &&
					action.payload.issue.id === issueId
				) {
					return Observable.of(updateDetailView());
				}

				if (
					action.type === ISSUE_FLAG_WITH_COMMENT_SUCCESS &&
					action.payload.issueIds.includes(issueId)
				) {
					return Observable.of(updateDetailView());
				}

				if (action.type === ISSUE_ADD_LABELS_SUCCESS && action.payload.includes(issueId)) {
					return Observable.of(updateDetailView());
				}

				if (action.type === ISSUE_RANK_TRANSITION_SUCCESS) {
					const parentIds = action.payload.issueIds.flatMap((id) => {
						const issue = getIssueByIdForModal(state)(id);
						// @ts-expect-error - TS2339 - Property 'parentId' does not exist on type 'Issue | IssueParent'.
						if (issue && issue.parentId !== undefined) {
							// @ts-expect-error - TS2339 - Property 'parentId' does not exist on type 'Issue | IssueParent'.
							return issue.parentId;
						}
						return [];
					});

					if (action.payload.issueIds.includes(issueId) || parentIds.includes(issueId)) {
						return Observable.of(updateDetailView());
					}
				}

				if (
					(action.type === SET_PARENT_SUCCESS || action.type === UNSET_PARENT_SUCCESS) &&
					action.payload.issueIds.includes(issueId)
				) {
					return Observable.of(updateDetailView());
				}

				if (
					action.type === SET_PARENT_OPTIMISTIC &&
					action.payload.issues.map((issue) => issue.id).includes(issueId)
				) {
					return Observable.of(updateDetailView());
				}

				return Observable.empty<never>();
			},
		);
