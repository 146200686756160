import { WORK_DATA_SET, WORK_DATA_CRITICAL_SET } from '../../../actions/work/index.tsx';
import type { Action } from '../../../types.tsx';
import type { IssueTypesByProjectState } from './types.tsx';

export const issueTypesByProjectReducer = (
	state: IssueTypesByProjectState = {},
	action: Action,
): IssueTypesByProjectState => {
	if (action.type === WORK_DATA_SET || action.type === WORK_DATA_CRITICAL_SET) {
		return action.payload.issueTypesByProject || {};
	}

	return state;
};
