import { ColumnType } from '@atlassian/jira-common-constants/src/column-types.tsx';
import {
	ChangeEventTypes,
	type ChangeEvent,
} from '@atlassian/jira-issue-view-model/src/change-type.tsx';
import { SUMMARY_TYPE, STATUS_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { values } from '@atlassian/jira-shared-types/src/general.tsx';
import { REFRESH_SOURCE_BENTO } from '../../model/constants.tsx';
import type { Issue } from '../../model/issue/issue-types.tsx';
import { BENTO } from '../../model/issue/issue-update-origin.tsx';
import { cardDataSet, type CardDataSetAction } from '../../state/actions/card/index.tsx';
import {
	setIssueChildrenRank,
	type SetIssueChildrenRankAction,
} from '../../state/actions/issue-children/index.tsx';
import {
	issueUpdateRequestWithTransactionId,
	type IssueUpdateRequestAction,
	setIssueParent,
	type SetIssueParentAction,
} from '../../state/actions/issue/update/index.tsx';
import { workRefreshData, type WorkRefreshDataAction } from '../../state/actions/work/index.tsx';
import {
	columnByStatusIdSelector,
	getStatusIdsOfColumns,
} from '../../state/selectors/column/column-selectors.tsx';
import { issueBaseTypesSelector } from '../../state/selectors/issue-type/issue-type-selectors.tsx';
import type { State } from '../../state/types.tsx';

export const FIELDS_IGNORED = ['description', 'reporter'];
export const FIELDS_THAT_CANNOT_BE_SEEN_ON_BOARD = ['reporter'];
export const FIELDS_WITH_OPTIMISTIC_UPDATE = ['summary', 'assignee', 'labels'];

export const FIELDS_WITH_SAVE_AND_UPDATE = ['assignee'];

const FieldsWithOptimisticCardUpdate = new Set<string>([STATUS_TYPE, SUMMARY_TYPE]);

const updateAssignee = (
	meta: {
		fieldId: string;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		fieldValue: any;
	},
	issue: Issue,
) => {
	if (meta.fieldValue === null) {
		return cardDataSet(
			{
				...issue,
				assigneeAccountId: null,
			},
			undefined,
			BENTO,
		);
	}

	const avatars = values(meta.fieldValue.avatarUrls);

	return cardDataSet(
		{
			...issue,
			assigneeAccountId: meta.fieldValue.accountId,
		},
		{
			id: meta.fieldValue.accountId,
			displayName: meta.fieldValue.displayName,
			avatarUrl: String(avatars[0]),
		},
		BENTO,
	);
};

export const checkIssueStatusUnmapped = (state: State, payload: ChangeEvent): boolean => {
	// to check unmapped status make sure field being updated is status
	if (payload.type === ChangeEventTypes.FIELD_CHANGED && payload.meta.fieldId === STATUS_TYPE) {
		const statusIdsOfColumns = getStatusIdsOfColumns(state);
		return !statusIdsOfColumns.includes(Number(payload.meta.fieldValue.id));
	}
	return false;
};

export const checkIssueStatusRemapped = (state: State, payload: ChangeEvent): boolean => {
	// Check if issue's status is changed to status mapped to board
	if (payload.type === ChangeEventTypes.FIELD_CHANGED && payload.meta.fieldId === STATUS_TYPE) {
		const statusIdsOfColumns = getStatusIdsOfColumns(state);
		return statusIdsOfColumns.includes(Number(payload.meta.fieldValue.id));
	}
	return false;
};

export const mapBentoResponseToIssue = (
	issue: Issue,
	state: State,
	payload: ChangeEvent,
	transactionId: string,
):
	| WorkRefreshDataAction
	| CardDataSetAction
	| IssueUpdateRequestAction
	| SetIssueParentAction
	| SetIssueChildrenRankAction
	| null => {
	if (!payload.type || !issue) {
		return null;
	}

	const { meta, type } = payload;

	if (type === ChangeEventTypes.ISSUE_TYPE_CHANGED) {
		const issueTypeName = meta.typeName || null;

		if (!issueTypeName) {
			return null;
		}

		const issueType = issueBaseTypesSelector(state).find(
			(issueBaseType) => issueBaseType.name === issueTypeName,
		);

		if (!issueType) {
			return workRefreshData(REFRESH_SOURCE_BENTO);
		}

		return cardDataSet(
			{
				...issue,
				typeId: Number(issueType.id),
				typeName: issueType.name,
				typeUrl: issueType.iconUrl,
			},
			undefined,
			BENTO,
		);
	}

	// If we get a field change request, try to handle it internally (without another API call)
	// Otherwise fire an action or do nothing for CMP
	if (payload.type === ChangeEventTypes.FIELD_CHANGE_REQUESTED) {
		switch (payload.meta.fieldId) {
			case 'assignee':
				return updateAssignee(payload.meta, issue);
			case 'summary':
			case 'priority':
			case 'labels': // labels has extraFields but we don't use that on the card itself
				return cardDataSet(
					{
						...issue,
						[payload.meta.fieldId]: payload.meta.fieldValue,
					},
					undefined,
					BENTO,
				);
			default: {
				// Some fields have no impact on the rendering of the board so we just
				// ignore them
				if (FIELDS_THAT_CANNOT_BE_SEEN_ON_BOARD.includes(payload.meta.fieldId)) {
					return null; // null will catch in issue-bento-update-epic
				}

				// If its CMP, There is no way to handle this yet. What happens normally in TMP
				// is we fire the `issueUpdateRequestWithTransactionId` action which in turn
				// fires a graphql query to fetch a new version of THAT card. This endpoint
				// doesn't officially support CMP as there are fields that we need for CMP
				// that aren't in the query
				return issueUpdateRequestWithTransactionId([issue.id], BENTO, transactionId);
			}
		}
	}

	// Bento extracted fields, that can update optimistically as they fire "FIELD_CHANGED"
	// afteru sucess API call.
	const optimisticallyUpdatableFields = FieldsWithOptimisticCardUpdate;
	if (
		payload.type === ChangeEventTypes.FIELD_CHANGED &&
		optimisticallyUpdatableFields.has(payload.meta.fieldId)
	) {
		// @ts-expect-error - TS2554 - Expected 1 arguments, but got 2.
		const column = columnByStatusIdSelector(state, Number(payload.meta.fieldValue.id));
		if (!column || issue?.extraFields?.some((ef) => ef.id === payload.meta.fieldId)) {
			return workRefreshData(REFRESH_SOURCE_BENTO);
		}

		return cardDataSet(
			{
				...issue,
				statusId: Number(payload.meta.fieldValue.id),
				columnId: column.id,
				isDone:
					column.type === ColumnType.STATUS
						? column.statuses.some((status) => status.isDone)
						: false,
			},
			undefined,
			BENTO,
		);
	}

	if (
		payload.type === ChangeEventTypes.FIELD_CHANGED &&
		FIELDS_WITH_SAVE_AND_UPDATE.includes(payload.meta.fieldId)
	) {
		switch (payload.meta.fieldId) {
			case 'assignee': {
				const currentAssignee = issue.assigneeAccountId;
				const payloadAssignee = payload.meta.fieldValue?.accountId || null;
				if (currentAssignee !== payloadAssignee) {
					return updateAssignee(payload.meta, issue);
				}
				return null;
			}
			default: {
				return null;
			}
		}
	}

	if (payload.type === ChangeEventTypes.ISSUE_RELATIONSHIP_UPDATED) {
		return setIssueParent(issue.id, Number(payload.meta.newParentIssueId));
	}

	if (payload.type === ChangeEventTypes.ISSUE_CHILDREN_ORDER_CHANGED) {
		const setIssueChildrenRankPayload = {
			issueIds: payload.meta.issueIds.map((id) => Number(id)),
			rankId: payload.meta.rankId,
			isRankAfter: payload.meta.isRankAfter,
		};
		return setIssueChildrenRank(setIssueChildrenRankPayload);
	}

	if (payload.type === ChangeEventTypes.CHILD_ISSUE_ADDED) {
		return issueUpdateRequestWithTransactionId([issue.id], BENTO, transactionId);
	}

	if (payload.type === ChangeEventTypes.SUBTASK_ADDED) {
		return workRefreshData(REFRESH_SOURCE_BENTO);
	}

	if (
		payload?.meta &&
		'fieldId' in payload.meta &&
		payload.meta.fieldId &&
		FIELDS_IGNORED.includes(payload.meta.fieldId)
	) {
		return null;
	}

	// Bento field update succeeded but change is not committed to the board yet via ChangeEventTypes.FIELD_CHANGE_REQUESTED
	if (
		payload.type === ChangeEventTypes.FIELD_CHANGED &&
		!FIELDS_WITH_OPTIMISTIC_UPDATE.includes(payload.meta.fieldId)
	) {
		return issueUpdateRequestWithTransactionId([issue.id], BENTO, transactionId);
	}

	// Bento field update failed -> revert optimistic change
	if (payload.type === ChangeEventTypes.FIELD_CHANGE_FAILED) {
		return issueUpdateRequestWithTransactionId([issue.id], BENTO, transactionId);
	}

	return null;
};
