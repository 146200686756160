import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { performPutRequest as originalPerformPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { FetchUrlError } from '../../errors/index.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const performPutRequest = <TResponse = any,>(
	url: string,
	options: RequestInit = {},
): Promise<TResponse> =>
	originalPerformPutRequest(url, options).catch((error: FetchError) => {
		throw new FetchUrlError(url, error);
	});
