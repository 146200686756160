import noop from 'lodash/noop';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import {
	contextPathSelector,
	getLocale,
	projectIdSelector,
	projectKeySelector,
} from '../../../../../state/selectors/software/software-selectors.tsx';
import type { State } from '../../../../../state/types.tsx';
import AddPeopleModal from './view.tsx';

type OwnProps = {
	isOpen: boolean;
	onClose: (() => void) | undefined;
	onAddSuccess: (() => void) | undefined;
	onError: (() => void) | undefined;
};

export default connect(
	(state: State, ownProps: OwnProps) => ({
		projectId: projectIdSelector(state),
		projectKey: projectKeySelector(state),
		baseUrl: contextPathSelector(state),
		locale: getLocale(state),
		isOpen: ownProps.isOpen,
		onClose: ownProps.onClose || noop,
		allowFlags: true,
		onAddSuccess: noop,
		onError: noop,
	}),
	() => ({}),
)(AddPeopleModal);
