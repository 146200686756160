import { CUSTOM_FILTER } from '../../../../../model/filter/filter-types.tsx';
import {
	FILTERED_CARD_SUCCESS,
	FILTERED_CARD_CLEAR,
	REFILTER_SUCCESS,
	FILTERED_CARD_FAILURE,
} from '../../../../actions/card/filtered-cards/index.tsx';
import {
	CUSTOM_FILTER_FORCE_REFRESH_START,
	SET_ALL_FILTERS,
} from '../../../../actions/filter/index.tsx';
import type { Action } from '../../../../types.tsx';
import type { FilteredCardsState } from './types.tsx';

export const initialState: FilteredCardsState = {
	isLoading: false,
	filteredCardsIds: null,
};

export const filteredCardsReducer = (state: FilteredCardsState = initialState, action: Action) => {
	if (action.type === SET_ALL_FILTERS && action.meta.filterType === CUSTOM_FILTER) {
		return {
			...state,
			isLoading: true,
		};
	}

	if (action.type === CUSTOM_FILTER_FORCE_REFRESH_START) {
		return {
			...state,
			isLoading: true,
		};
	}

	if (action.type === FILTERED_CARD_SUCCESS) {
		return {
			isLoading: false,
			filteredCardsIds: new Set<string>(action.payload),
		};
	}

	if (action.type === FILTERED_CARD_FAILURE) {
		return {
			isLoading: false,
			filteredCardsIds: null,
		};
	}

	if (action.type === FILTERED_CARD_CLEAR) {
		return initialState;
	}

	if (action.type === REFILTER_SUCCESS) {
		const { excludedCardIds, filteredCardIds } = action.payload;
		const updatedFilteredCardIds = new Set<string>([
			...(state.filteredCardsIds || []),
			...filteredCardIds,
		]);
		excludedCardIds.forEach((cardId: string) => updatedFilteredCardIds.delete(cardId)); // delete is faster than filtering on state
		return {
			isLoading: false,
			filteredCardsIds: updatedFilteredCardIds,
		};
	}

	return state;
};
