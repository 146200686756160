import { freeze } from 'icepick';
import {
	COLUMN_CLOSE_LIMITS_MODAL,
	COLUMN_OPEN_LIMITS_MODAL,
	COLUMN_SET_LIMITS_REQUEST,
} from '../../../../actions/column/limits/index.tsx';
import type { Action } from '../../../../types.tsx';
import type { ColumnLimitsModalState } from './types.tsx';

export const initialState: ColumnLimitsModalState = freeze({ isOpen: false });

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	state: ColumnLimitsModalState = initialState,
	action: Action,
): ColumnLimitsModalState => {
	switch (action.type) {
		case COLUMN_OPEN_LIMITS_MODAL:
			return freeze({
				isOpen: true,
				columnId: action.payload.columnId,
			});

		case COLUMN_SET_LIMITS_REQUEST:
		case COLUMN_CLOSE_LIMITS_MODAL:
			return freeze({ isOpen: false });
		default: {
			// @ts-expect-error - TS2322: Type 'ShowErrorFlagAction | SetPersonAction | AddToSprintRequestAction | AddToSprintSuccessAction | ... 254 more ... | UnsupportedAction' is not assignable to type 'never'.
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
