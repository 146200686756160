import parameters from '@atlassian/jira-relay/src/__generated__/mainJiraBoardFetchPullRequestDetailsForIssueQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const pullRequestDetailsEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-pr-details-popup" */ './src/ui'),
	),
	getPreloadProps: ({ issueId }: { issueId: string }) => ({
		queries: {
			pullRequestDetailsForIssue: {
				options: {
					fetchPolicy: 'store-and-network' as const,
				},
				parameters,
				variables: {
					issueId,
				},
			},
		},
	}),
});
