import { defineMessages } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const messages = defineMessages({
	revertChanges: {
		id: 'software.main.flags.undo-flag.revert-changes',
		defaultMessage: 'Undo',
		description:
			'Delayed update flag action. This action will undo the changes that the user has just made',
	},
}) as {
	revertChanges: MessageDescriptor;
};
