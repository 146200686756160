import { EXTENSION_FETCH_SUCCESS } from '../../../actions/extension/index.tsx';
import type { Action } from '../../../actions/index.tsx';
import type { ExtensionStateType } from './types.tsx';

const initialState: ExtensionStateType = {
	operations: [],
};

export default function extensionReducer(
	state: ExtensionStateType = initialState,
	action: Action,
): ExtensionStateType {
	if (action.type === EXTENSION_FETCH_SUCCESS) {
		return { ...state, ...action.payload.extension };
	}
	return state;
}
