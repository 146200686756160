import type { StatusCategory } from '../../../model/software/software-types.tsx';

export const STATUS_CATEGORY_LOAD_SUCCESS =
	'state.actions.status-category.STATUS_CATEGORY_LOAD_SUCCESS' as const;

export type StatusCategoryLoadSuccessAction = {
	type: typeof STATUS_CATEGORY_LOAD_SUCCESS;
	payload: {
		statusCategories: StatusCategory[];
	};
};

export const statusCategoryLoadSuccess = (
	statusCategories: StatusCategory[],
): StatusCategoryLoadSuccessAction => ({
	type: STATUS_CATEGORY_LOAD_SUCCESS,
	payload: {
		statusCategories,
	},
});

export const STATUS_CATEGORY_LOAD_FAILURE =
	'state.actions.status-category.STATUS_CATEGORY_LOAD_FAILURE' as const;

export type StatusCategoryLoadFailureAction = {
	type: typeof STATUS_CATEGORY_LOAD_FAILURE;
	error: string;
};

export const statusCategoryLoadFailure = (error: string): StatusCategoryLoadFailureAction => ({
	type: STATUS_CATEGORY_LOAD_FAILURE,
	error,
});

export type Action = StatusCategoryLoadSuccessAction | StatusCategoryLoadFailureAction;
