import fromPairs from 'lodash/fromPairs';
import get from 'lodash/get';
import memoizeOne from 'memoize-one';
import { getAllFeatureFlags } from '@atlassian/jira-feature-flagging-using-meta';

const PERF_FF_PREFIX = 'jsw.perf.';

const EXTRA_FFS_WHITELIST = new Set(['fe_platform_error_tracking_enabled_sentry']);

type FeatureFlags = {
	[key: string]: boolean | number | string;
};

const getPerformanceFFs: () => FeatureFlags = memoizeOne(() => {
	const featureFlags = Object.entries(getAllFeatureFlags() || {})
		// @ts-expect-error - TS2769 - No overload matches this call.
		.filter(([key]: [any]) => key.startsWith(PERF_FF_PREFIX)) // eslint-disable-line @typescript-eslint/no-explicit-any
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		.map(([key, value]: [any, any]) => [
			key.substring(PERF_FF_PREFIX.length),
			get(value, ['value'], null),
		]);
	return fromPairs(featureFlags);
});

const isConsistencyCheckValue = (value: string): boolean =>
	value === 'NEW' || value === 'OLD' || value === 'CHECK' || value === 'CHECK_RETURN_NEW';

export const getExtraFFs: (whitelist?: Set<string>) => FeatureFlags = memoizeOne(
	(whitelist: Set<string> = EXTRA_FFS_WHITELIST) => {
		const featurePairs = Object.entries(getAllFeatureFlags() || {})
			// @ts-expect-error - TS2769 - No overload matches this call.
			.filter(([key]: [any]) => whitelist.has(key)) // eslint-disable-line @typescript-eslint/no-explicit-any
			.map(([key, value]: [any, any]) => [key, get(value, ['value'], null)]); // eslint-disable-line @typescript-eslint/no-explicit-any

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const result: Record<string, any> = {};
		// @ts-expect-error - TS2345 - Argument of type '([key, value]: [any, any]) => void' is not assignable to parameter of type '(value: any[], index: number, array: any[][]) => void'.
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		featurePairs.forEach(([key, value]: [any, any]) => {
			result[key] = value;
			if (isConsistencyCheckValue(value)) {
				result[`${key}-new`] = value === 'NEW';
				result[`${key}-old`] = value === 'OLD';
				result[`${key}-check`] = value === 'CHECK';
				result[`${key}-check-return-new`] = value === 'CHECK_RETURN_NEW';
			}
		});

		return result;
	},
);

export const getPerformanceFFsString = memoizeOne(() => JSON.stringify(getPerformanceFFs()));

export const getNormalisedPerformanceFFs: () => Readonly<FeatureFlags> = memoizeOne(() => {
	const featureEntries = Object.entries({
		...getPerformanceFFs(),
		...getExtraFFs(),
	});

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const map: Record<string, any> = {};
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	featureEntries.forEach(([key, value]: [any, any]) => {
		const normalisedKey = key.replace(/\./g, '-');
		map[normalisedKey] = value;
		return map;
	});
	return map;
});
