import React from 'react';
import { styled } from '@compiled/react';
import Badge from '@atlaskit/badge';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { Container } from '@atlassian/jira-platform-card/src/common/ui/estimate/index.tsx';

type Props = {
	value: number | string;
};

export const EstimateFieldStatic = ({ value }: Props) => {
	const { formatNumber } = useIntl();
	return (
		<EstimateWrapper data-testid="software-board.common.fields.estimate-field.static.estimate-wrapper">
			<Badge appearance="default">{typeof value === 'number' ? formatNumber(value) : value}</Badge>
		</EstimateWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EstimateWrapper = styled(Container)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	paddingRight: `${gridSize / 4}px`,
});
