import React, { useState, useCallback } from 'react';
import type { Placement } from '@popperjs/core';
import { useIntl } from '@atlassian/jira-intl';
import { SHOULD_SHOW_PERSONALIZED_SAMPLE_DATA_FOR_KANBAN } from '@atlassian/jira-onboarding-core/src/constants.tsx';
import { sessionStorageProvider } from '@atlassian/jira-onboarding-core/src/utils/index.tsx';
import { DEFAULT_NUDGE_WIDTH } from '@atlassian/jira-onboarding-quickstart-core/src/common/constants.tsx';
import { POSITION_RIGHT_START } from '@atlassian/jira-onboarding-quickstart-core/src/common/ui/instrumented-nudge-spotlight-card/constants.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { useKeepIccOpenState } from '@atlassian/jira-software-onboarding-keep-icc-open/src/controllers/index.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import { BOARD_CONTAINER_NUDGE_ID } from '../../common/constants.tsx';
import { createOnboardingSpotlight } from '../../common/ui/onboarding-spotlight/index.tsx';
import type { OnboardingSpotlightProps } from '../../common/ui/onboarding-spotlight/types.tsx';
import { PortalledNudge } from '../../common/ui/portalled-nudge/index.tsx';
import { ViewTooltipSvg } from './asset.tsx';
import messages from './messages.tsx';

type SampleProjectDataNudge = {
	issueKey: string | undefined;
};
const position: Placement = POSITION_RIGHT_START;
const nudgeSpotlightProps = {
	width: DEFAULT_NUDGE_WIDTH,
	position,
};

export const SampleProjectDataNudge = ({ issueKey }: SampleProjectDataNudge) => {
	const { formatMessage } = useIntl();
	const [isHidden, setIsHidden] = useState(false);
	const { data } = useProjectContext();
	const { push } = useRouterActions();
	const isModalClosed = useKeepIccOpenState();

	const hide = useCallback(() => {
		sessionStorageProvider.remove(SHOULD_SHOW_PERSONALIZED_SAMPLE_DATA_FOR_KANBAN);
		setIsHidden(true);
	}, [setIsHidden]);

	const openTicket = useCallback(() => {
		hide();
		if (window) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			const redirectUrl = new URL(window.location.href);
			if (issueKey) {
				redirectUrl.searchParams.set('selectedIssue', issueKey);
			} else {
				redirectUrl.searchParams.set('selectedIssue', `${data?.projectKey}-1`);
			}

			push(redirectUrl.href);
		}
	}, [data, hide, push, issueKey]);

	const onboardingSpotlightProps: OnboardingSpotlightProps = {
		actions: [
			{
				content: formatMessage(messages.spotlightDismissAction),
				onClick: hide,
				appearance: 'subtle',
			},
			{
				content: formatMessage(messages.spotlightCtaAction),
				onClick: openTicket,
				appearance: 'primary',
			},
		],
		headingMessage: formatMessage(messages.spotlightHeading),
		contentMessage: formatMessage(messages.spotlightContent),
		image: ViewTooltipSvg(),
	};
	return (
		<PortalledNudge
			nudgeIdForAnalytics={BOARD_CONTAINER_NUDGE_ID}
			nudgeTargetId={BOARD_CONTAINER_NUDGE_ID}
			{...nudgeSpotlightProps}
			hidden={isHidden}
			setHidden={hide}
			onOutsideClick={() => {
				if (!isModalClosed) {
					return;
				}
				hide();
			}}
			spotlightCard={createOnboardingSpotlight({ ...onboardingSpotlightProps })}
		/>
	);
};
