import parameters from '@atlassian/jira-relay/src/__generated__/uiDesignDetailsPopupQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const designDetailsEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-design-details-popup" */ './src/ui'),
	),
	getPreloadProps: ({ issueId }: { issueId: string }) => ({
		queries: {
			designDetailsPopup: {
				options: {
					fetchPolicy: 'store-and-network' as const,
				},
				parameters,
				variables: {
					issueAri: issueId,
				},
			},
		},
	}),
});
