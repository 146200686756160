import type { Observable as ObservableType } from 'rxjs/Observable';
import { gqlTagSwag } from '@atlassian/jira-software-swag/src/index.tsx';
import { mutation$ } from '@atlassian/jira-software-swag/src/services/mutation/index.tsx';
import { cardCreateAnalyticsOptions } from '../../../common/graphql/metrics/index.tsx';
import type {
	BoardCardCreate,
	BoardCardCreateVariables,
} from './__generated_apollo__/BoardCardCreate';

const boardCardCreateOperationName = 'BoardCardCreate';

export const boardCardCreateMutation = gqlTagSwag`
mutation BoardCardCreate (
    $boardId: ID!,
    $cardTransitionId: ID,
    $rankBeforeCardId: Long,
    $newCards: [NewCard]!
    $destinationId: ID,
    $requireJqlContext: Boolean
	$swimlaneId: ID
) {
    boardCardCreate(input: {
        boardId: $boardId,
        cardTransitionId: $cardTransitionId,
        rankBeforeCardId: $rankBeforeCardId,
        newCards: $newCards
        destinationId: $destinationId
        requireJqlContext: $requireJqlContext
		swimlaneId: $swimlaneId
        
    }) {
        newCards {
            id
            issue {
                key
            }
        }
    }
}`;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	baseUrl: string,
	input: BoardCardCreateVariables,
): ObservableType<BoardCardCreate> => {
	const {
		boardId,
		cardTransitionId,
		rankBeforeCardId,
		newCards,
		destinationId,
		requireJqlContext,
		swimlaneId,
	} = input;

	const variables: BoardCardCreateVariables = {
		boardId,
		cardTransitionId,
		rankBeforeCardId,
		newCards,
		...(destinationId ? { destinationId } : {}),
		requireJqlContext,
		...(swimlaneId ? { swimlaneId } : {}),
	};

	return mutation$<BoardCardCreate>(
		baseUrl,
		boardCardCreateOperationName,
		boardCardCreateMutation,
		variables,
		cardCreateAnalyticsOptions,
	);
};
