import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorHeader: {
		id: 'automation-menu.popup.run-rule.manual-rules.error-header',
		defaultMessage: "We're having some trouble",
		description: "Header for the error section shown when rules can't be fetched",
	},
	errorDescription: {
		id: 'automation-menu.popup.run-rule.manual-rules.error-description',
		defaultMessage:
			"It's taking us longer than expected to show this content. It's probably a temporary problem. Try refreshing or navigating to another page.",
		description: "Description for the error section shown when rules can't be fetched",
	},
	issueSelectionLimitExceededHeader: {
		id: 'automation-menu.popup.run-rule.manual-rules.issue-selection-limit-exceeded-header',
		defaultMessage: 'Too many issues selected',
		description:
			'Header for the error message shown when the size of selectedIssueIds exceeds {limit}.',
	},
	issueSelectionLimitExceededDescription: {
		id: 'automation-menu.popup.run-rule.manual-rules.issue-selection-limit-exceeded-description',
		defaultMessage:
			'The maximum amount of issues you can select on a board is {limit, number}. Please deselect some issues and try again.',
		description:
			'Description for the error message shown when the size of selectedIssueIds exceeds {limit}.',
	},
});
