export const INSIGHTS_PACKAGE = 'jira.insights';

export const INSIGHTS_BOARD_PACKAGE = `${INSIGHTS_PACKAGE}.board`;

export const INSIGHTS_BACKLOG_PACKAGE = `${INSIGHTS_PACKAGE}.backlog`;

export const INSIGHTS_BOARD_SIMPLIFIED_PACKAGE = `${INSIGHTS_PACKAGE}.board-simplified`;

export const INSIGHTS_BACKLOG_SIMPLIFIED_PACKAGE = `${INSIGHTS_PACKAGE}.backlog-simplified`;

export const INSIGHTS_PACKAGE_BOUNDARY_ID = `${INSIGHTS_PACKAGE}.error-boundary`;
