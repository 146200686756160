import mapValues from 'lodash/mapValues';
import zipObject from 'lodash/zipObject';

import { getTenantContext_DEPRECATED_DO_NOT_USE } from '@atlassian/jira-common-util-get-tenant-context/src/index.tsx';
import { getFlags as apiGetFlags, setFlag as apiSetFlag } from './api.tsx';
import storage from './storage.tsx';
import type { HaveISeenItFlags, GetFlagRequest } from './types.tsx';

export const DEFAULT_TTL_NOT_SEEN = 1000 * 60 * 60; // 1 hour
export const DEFAULT_TTL_SEEN = 1000 * 60 * 60 * 24 * 30; // ~1 month

export const getFlags = async (request: GetFlagRequest): Promise<HaveISeenItFlags> => {
	const { atlassianAccountId, isAnonymous } = getTenantContext_DEPRECATED_DO_NOT_USE();
	const flagKeys = Object.keys(request);
	const retrieveFlagKeysLookup = zipObject(flagKeys);

	if (!atlassianAccountId || isAnonymous) {
		return mapValues(retrieveFlagKeysLookup, () => ({
			status: true,
			lastSeenDate: null,
		}));
	}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const flags: Record<string, any> = {};
	if (storage) {
		flagKeys.forEach((flagKey) => {
			// @ts-expect-error - Object is possibly 'null'.
			const value = storage.get(flagKey);
			if (value) {
				delete retrieveFlagKeysLookup[flagKey];
				flags[flagKey] = value;
			}
		});
	}
	const retrieveFlagKeys = Object.keys(retrieveFlagKeysLookup);
	if (retrieveFlagKeys.length > 0) {
		Object.assign(flags, await apiGetFlags(atlassianAccountId, retrieveFlagKeys));
	}

	if (storage) {
		const now = Date.now();
		retrieveFlagKeys.forEach((flagKey) => {
			// Not sure whats going wrong here - but i assume `apiGetFlags` may not always return all `retrieveFalgKeys`
			// and so `flags[flagKey]` can be undefined - so we have to guard against it
			if (!flags[flagKey]) {
				return;
			}

			const { status } = flags[flagKey];
			const ttlNotSeen = request[flagKey].ttlNotSeen || DEFAULT_TTL_NOT_SEEN;
			const ttlSeen = request[flagKey].ttlSeen || DEFAULT_TTL_SEEN;
			// @ts-expect-error - TS2531: Object is possibly 'null'.
			storage.set(flagKey, flags[flagKey], now + (status ? ttlSeen : ttlNotSeen));
		});
	}

	return flags;
};

/**
 * Mark the specified flag key as "seen".
 *
 * If the flag is cached in local storage as being "seen", then don't update the server.
 * @param flagKey
 * @param ttl - time to live in the cache. Default is 1 month
 * @returns {Promise.<void>}
 */
export const setFlag = async (flagKey: string, ttl: number = DEFAULT_TTL_SEEN): Promise<void> => {
	const { atlassianAccountId, isAnonymous } = getTenantContext_DEPRECATED_DO_NOT_USE();
	if (!atlassianAccountId || isAnonymous) {
		return;
	}
	if (storage) {
		const value = storage.get(flagKey);
		if (value && value.status) {
			return;
		}
		const now = new Date();
		storage.set(
			flagKey,
			{
				status: true,
				lastSeenDate: now.toISOString(),
			},
			now.getTime() + ttl,
		);
	}
	await apiSetFlag(atlassianAccountId, flagKey);
};
