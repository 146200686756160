import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { AccessLevel } from '@atlassian/jira-shared-types/src/project.tsx';

const defaultProjectRoles: string[] = ['Administrator', 'Member', 'Viewer'];
export const CUSTOM_PROJECT_ROLE_REPLACEMENT_STRING = 'Custom Role';

export const fireAddPeopleRenderedEvent = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	source: string | null | undefined,
	projectId: number,
	userProjectRoles: string[] | null,
	projectAccessLevel: AccessLevel | null,
) => {
	const analyticsEvent = createAnalyticsEvent({
		action: 'rendered',
		actionSubject: 'addPeopleButton',
		source,
	});
	fireUIAnalytics(analyticsEvent, {
		projectId,
		userProjectRoles,
		projectAccessLevel,
	});
};

// Remove all custom roles in the list and add the string 'Custom Role'
export const reduceCustomRoles = (userProjectRoles: string[]) => {
	let hasCustomRole = false;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const modifiedProjectRoles = userProjectRoles.reduce<Array<any>>((acc, role) => {
		const isDefaultRole = defaultProjectRoles.includes(role);
		hasCustomRole = hasCustomRole || !isDefaultRole;

		if (isDefaultRole) {
			acc.push(role);
		}
		return acc;
	}, []);

	if (hasCustomRole) {
		modifiedProjectRoles.push(CUSTOM_PROJECT_ROLE_REPLACEMENT_STRING);
	}
	return modifiedProjectRoles;
};
