import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	currentTitleAriaLabel: {
		id: 'software.main.header.title.current-title-aria-label',
		defaultMessage: '{currentTitle}, edit board title',
		description:
			'Text that is used as aria-label of button for assistive technologies. It allows screen reader users understand the button purpose',
	},
});
