import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { operationsSelector } from '../../../../state/selectors/board/board-selectors.tsx';
import type { State } from '../../../../state/types.tsx';
import ConnectItemsOld from './view.tsx';

type OwnProps = {
	hasOperations: boolean;
};

const mapStateToProps = (state: State): OwnProps => ({
	hasOperations: operationsSelector(state).length > 0,
});

// delete file on 'jira_nav4_eap_drop_2' clean up
export default connect(mapStateToProps)(ConnectItemsOld);
