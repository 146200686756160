export const UPDATE_DETAIL_VIEW = 'state.actions.detail-view.UPDATE_DETAIL_VIEW' as const;

export type UpdateDetailViewAction = {
	type: typeof UPDATE_DETAIL_VIEW;
};

export const updateDetailView = (): UpdateDetailViewAction => ({
	type: UPDATE_DETAIL_VIEW,
});

export type Action = UpdateDetailViewAction;
