import React from 'react';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<svg width="16" height="16" viewBox="4 4 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M7.50403 13.8563C7.50403 14.727 6.79278 15.4382 5.92212 15.4382C5.05146 15.4382 4.34021 14.727 4.34021 13.8563C4.34021 12.9857 5.05146 12.2744 5.92212 12.2744H7.50403V13.8563Z"
			fill="#E01E5A"
		/>
		<path
			d="M8.30115 13.8563C8.30115 12.9857 9.0124 12.2744 9.88307 12.2744C10.7537 12.2744 11.465 12.9857 11.465 13.8563V17.8172C11.465 18.6879 10.7537 19.3991 9.88307 19.3991C9.0124 19.3991 8.30115 18.6879 8.30115 17.8172V13.8563Z"
			fill="#E01E5A"
		/>
		<path
			d="M9.88307 7.50415C9.0124 7.50415 8.30115 6.79291 8.30115 5.92224C8.30115 5.05158 9.0124 4.34033 9.88307 4.34033C10.7537 4.34033 11.465 5.05158 11.465 5.92224V7.50415H9.88307Z"
			fill="#36C5F0"
		/>
		<path
			d="M9.88304 8.30127C10.7537 8.30127 11.465 9.01251 11.465 9.88317C11.465 10.7538 10.7537 11.4651 9.88304 11.4651H5.92212C5.05146 11.4651 4.34021 10.7538 4.34021 9.88317C4.34021 9.01251 5.05146 8.30127 5.92212 8.30127H9.88304Z"
			fill="#36C5F0"
		/>
		<path
			d="M16.2354 9.88317C16.2354 9.01251 16.9466 8.30127 17.8172 8.30127C18.6879 8.30127 19.3991 9.01251 19.3991 9.88317C19.3991 10.7538 18.6879 11.4651 17.8172 11.4651H16.2354V9.88317Z"
			fill="#2EB67D"
		/>
		<path
			d="M15.4381 9.88316C15.4381 10.7538 14.7269 11.4651 13.8562 11.4651C12.9855 11.4651 12.2743 10.7538 12.2743 9.88316V5.92225C12.2743 5.05158 12.9855 4.34033 13.8562 4.34033C14.7269 4.34033 15.4381 5.05158 15.4381 5.92225V9.88316Z"
			fill="#2EB67D"
		/>
		<path
			d="M13.8562 16.2354C14.7269 16.2354 15.4381 16.9466 15.4381 17.8172C15.4381 18.6879 14.7269 19.3991 13.8562 19.3991C12.9855 19.3991 12.2743 18.6879 12.2743 17.8172V16.2354H13.8562Z"
			fill="#ECB22E"
		/>
		<path
			d="M13.8562 15.4382C12.9855 15.4382 12.2743 14.727 12.2743 13.8563C12.2743 12.9857 12.9855 12.2744 13.8562 12.2744H17.8171C18.6878 12.2744 19.399 12.9857 19.399 13.8563C19.399 14.727 18.6878 15.4382 17.8171 15.4382H13.8562Z"
			fill="#ECB22E"
		/>
	</svg>
);
