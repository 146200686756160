/*!
 * This module contains a "CAPABILITIES" API and model for JSW UIF board.
 *
 * Any feature that is "gated" and only available on certain cases should be
 * added here.
 */
import {
	SERVICE_DESK_PROJECT,
	SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import getExplicitlyLicensedProducts from '@atlassian/jira-common-get-explicitly-licensed-products/src/index.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import {
	SOFTWARE,
	SERVICE_DESK,
	type ApplicationKey,
} from '@atlassian/jira-shared-types/src/application-key.tsx';
import { FREE_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useSubProduct } from '@atlassian/jira-subproduct/src/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { type CapabilityKey, hasCapability } from '../../../common/capability/index.tsx';
import { useBoardSelector } from '../../index.tsx';

import {
	getEntities,
	getIsIncrementPlanningBoard,
	projectTypeSelector,
	getCanEditBoard,
} from '../../selectors/software/software-selectors.tsx';

/**
 * Reads whether the current board is a CMP board. Use this or the
 * resource-router route name.
 */
export function useIsCMPBoard(): boolean {
	return useBoardSelector((state) => state.configuration.isCMPBoard);
}

/**
 * Reads whether the current board is a user board. Use this or the
 * resource-router route name.
 * @returns
 */
export function useIsUserBoard(): boolean {
	return useBoardSelector((state) => state.configuration.isUserBoard);
}

/**
 * Reads whether the current board is a JSM board. Use this or the
 * resource-router route name.
 */
export function useIsJSMBoard(): boolean {
	return useBoardSelector(projectTypeSelector) === SERVICE_DESK_PROJECT;
}
/**
 * Reads whether the current user has edit board permission.
 */
export function useCanEditBoard(): boolean {
	return useBoardSelector(getCanEditBoard);
}

/**
 * Reads whether the current board is a JSW board. Use this or the
 * resource-router route name.
 */
export function useIsJSWBoard(): boolean {
	return useBoardSelector(projectTypeSelector) === SOFTWARE_PROJECT;
}

/**
 * Reads whether the current board is an Increment Planning board (owned by Advanced Roadmaps Team). Use this or the
 * resource-router route name.
 */
export function useIsIncrementPlanningBoard(): boolean {
	return useBoardSelector(getIsIncrementPlanningBoard);
}

/**
 * If reading a capability on a view, use this hook.
 */
export function useCapability(key: CapabilityKey): boolean {
	const isCMPBoard = useIsCMPBoard();
	const isUserBoard = useIsUserBoard();
	const isIncrementPlanningBoard = useIsIncrementPlanningBoard();
	const allFeatures = useBoardSelector((state) => getEntities(state).board.config.allFeatures);
	const projectType = useBoardSelector(projectTypeSelector);

	return hasCapability(
		key,
		isCMPBoard,
		isIncrementPlanningBoard,
		allFeatures,
		projectType,
		isUserBoard,
	);
}

export function useIsFreeJswProject() {
	const { data: projectContext } = useProjectContext();
	const { appEditions } = useTenantContext();

	const isInsideFreeJswProject =
		appEditions.software === FREE_EDITION && projectContext?.projectType === SOFTWARE_PROJECT;

	return isInsideFreeJswProject;
}

const _getJSMJSWProductKey = (
	subProduct: string | null,
	explicitlyLicensedProducts: ApplicationKey[],
): ApplicationKey | null => {
	if (subProduct === 'software') {
		return SOFTWARE;
	}
	if (subProduct === 'serviceDesk') {
		return SERVICE_DESK;
	}

	// If subproduct is null, we are probably on a JSM, Jira Core or product-agnostic page
	// Just to be safe, we will allow the banner to render if there is only one product on the instance
	if (explicitlyLicensedProducts.length === 1) {
		if (explicitlyLicensedProducts[0] === SOFTWARE) {
			return SOFTWARE;
		}

		if (explicitlyLicensedProducts[0] === SERVICE_DESK) {
			return SERVICE_DESK;
		}
	}

	return null;
};

export function useShowPersistentUpgradeBanner() {
	const tenantContext = useTenantContext();
	const { isSiteAdmin } = tenantContext;
	const explicitlyLicensedProducts = getExplicitlyLicensedProducts(tenantContext);
	const product = _getJSMJSWProductKey(useSubProduct(), explicitlyLicensedProducts);

	return product && isSiteAdmin && product !== SOFTWARE && product !== SERVICE_DESK;
}
