/**
 * @generated SignedSource<<e18ba82933463bea77a039cb31341c83>>
 * @relayHash ec62760f3d36372780325706797b1fb2
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 91484d5c2ad4c1c580a3b56066bfe36c5fe9798b2804e958c3c96ecd098886b0

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { mainJiraBoardFetchPullRequestDetailsForIssueQuery } from './mainJiraBoardFetchPullRequestDetailsForIssueQuery.graphql';

const node: PreloadableConcreteRequest<mainJiraBoardFetchPullRequestDetailsForIssueQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "91484d5c2ad4c1c580a3b56066bfe36c5fe9798b2804e958c3c96ecd098886b0",
    "metadata": {},
    "name": "mainJiraBoardFetchPullRequestDetailsForIssueQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
