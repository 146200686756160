import { useCallback } from 'react';
import { unset } from 'icepick';
import type { UseMigrationIdManager, ProjectKey, MigrationId } from './types.tsx';
import { getMigrationIdStore, removeMigrationIdStore, setMigrationIdStore } from './utils.tsx';

export const useMigrationIdManager = (): UseMigrationIdManager => {
	const fetchMigrationId = useCallback((projectKey: ProjectKey) => {
		const migrationIdStore = getMigrationIdStore();

		return migrationIdStore[projectKey];
	}, []);

	const storeMigrationId = useCallback((projectKey: ProjectKey, migrationId: MigrationId) => {
		const migrationIdStore = getMigrationIdStore();

		setMigrationIdStore({ ...migrationIdStore, [projectKey]: migrationId });
	}, []);

	const removeMigrationId = useCallback((projectKey: ProjectKey) => {
		const migrationIdStore = getMigrationIdStore();

		if (!(projectKey in migrationIdStore)) {
			return;
		}

		const newMigrationIdStore = unset(migrationIdStore, projectKey);

		if (Object.keys(newMigrationIdStore).length === 0) {
			removeMigrationIdStore();
			return;
		}

		setMigrationIdStore(newMigrationIdStore);
	}, []);

	return { fetchMigrationId, storeMigrationId, removeMigrationId };
};
