import { fg } from '@atlassian/jira-feature-gating';
import { softwareBoardEmbedRoute } from '@atlassian/jira-router-routes-software-board-routes/src/softwareBoardEmbedRoute.tsx';
import { classicSoftwareBoardEmbedRoute } from '@atlassian/jira-router-routes-software-classic-board-routes/src/classicSoftwareBoardEmbedRoute.tsx';
import { useRouter } from '@atlassian/react-resource-router';
import { getIsIncrementPlanningBoardEmbed } from '../../../state/selectors/software/software-selectors.tsx';

export const useIsEmbedView = () => {
	const [{ route }] = useRouter();
	return fg('smart_links_for_plans')
		? route.name === softwareBoardEmbedRoute.name ||
				route.name === classicSoftwareBoardEmbedRoute.name ||
				getIsIncrementPlanningBoardEmbed()
		: route.name === softwareBoardEmbedRoute.name ||
				route.name === classicSoftwareBoardEmbedRoute.name;
};
