import { useMemo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isDefined = (value: any) => value !== null && value !== undefined;

const isCurrentBrowserSupported = () => {
	// Supported from:
	// Chrome 66
	// Safari 12.1
	// Edge 16

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (!isDefined(window?.AbortController)) {
		return false;
	}

	return true;
};

export const useIsCurrentBrowserSupported = () => {
	const isSupported = useMemo(() => isCurrentBrowserSupported(), []);
	return isSupported;
};
