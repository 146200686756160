import log from '@atlassian/jira-common-util-logging/src/log.tsx';

type Path = string[];
type StatusCode = string | null;

type ErrorData = {
	path?: Path;
	message?: string;
	queryName: string;
	statusCode?: StatusCode;
	errorType?: string | null;
	errorName?: string | null;
	hash?: string | null;
};

const logError = (type: string, m: string, extra: ErrorData) => {
	const { message, path, queryName, statusCode, errorType, errorName, hash } = extra;
	const params = {
		message: message || '',
		path: JSON.stringify(path || []),
		statusCode: String(statusCode || '[unknown]'),
		queryName,
		errorType: errorType ? String(errorType) : '',
		errorName: errorName ? String(errorName) : '',
		hash,
	};

	return log.safeErrorWithoutCustomerData(type, m, params);
};

const SwagErrorPlaceholder = '[errors in SWAG response]';

export const logApolloError = (
	path: Path,
	statusCode: StatusCode,
	queryName: string,
	errorType: string | null,
) =>
	logError('apollo.swag.error.graphql', '[GraphQL error]', {
		message: SwagErrorPlaceholder,
		path,
		statusCode,
		queryName,
		errorType,
	});

export const logApolloNetworkError = (
	networkError: string,
	statusCode: StatusCode,
	queryName: string,
) => {
	logError('apollo.swag.error.network', '[Network error]', {
		message: `[Network error]: ${networkError}`,
		statusCode,
		queryName,
	});
};

export const logRestError = (
	path: Path,
	statusCode: StatusCode,
	queryName: string,
	errorType: string | null,
) => {
	logError('rest.swag.error.graphql', '[GraphQL error]', {
		message: SwagErrorPlaceholder,
		path,
		statusCode,
		queryName,
		errorType,
	});
};

export const logRestNetworkError = (
	networkError: string,
	statusCode: StatusCode,
	queryName: string,
	errorName: string | null,
	hash?: string,
) => {
	logError('rest.swag.error.network', '[Network error]', {
		message: `[Network error]: ${networkError}`,
		statusCode,
		queryName,
		errorName,
		hash,
	});
};

export const logRestParsingError = (error: string, queryName: string) => {
	logError('rest.swag.error.parsing', '[Parsing error]', {
		message: `[Parsing error]: ${error}`,
		queryName,
	});
};

export const logRestRetry = ({
	queryName,
	statusCode,
	isRetrySuccessful,
	retryStatusCode,
}: {
	queryName: string;
	statusCode: StatusCode;
	isRetrySuccessful: boolean;
	retryStatusCode: StatusCode;
}) =>
	log.safeErrorWithoutCustomerData('rest.swag.retry', '[Retry]', {
		queryName,
		statusCode: statusCode || '',
		isRetrySuccessful: String(isRetrySuccessful),
		retryStatusCode: retryStatusCode || '',
	});

export const logApolloRetry = ({
	queryName,
	statusCode,
}: {
	queryName: string;
	statusCode: StatusCode;
}) =>
	log.safeErrorWithoutCustomerData('apollo.swag.retry', '[Retry]', {
		queryName,
		statusCode,
	});
