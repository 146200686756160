import React, { type ReactElement } from 'react';
import { SpotlightTransition } from '@atlaskit/onboarding';
import CoordinationClient from '@atlassian/jira-engagement/src/ui/coordination-client/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import messages from './messages.tsx';

const INSIGHTS_OPEN_UP_MESSAGE_ID = 'jira-insights-open-up-message';
type Props = {
	onOpenInsightsPanel: () => void;
	onDismiss: () => void;
	isOnboardingApplicable: boolean;
};
export const InsightsSpotlight = ({
	onOpenInsightsPanel,
	onDismiss,
	isOnboardingApplicable,
}: Props) => {
	const { formatMessage } = useIntl();

	return (
		<SpotlightTransition>
			{isOnboardingApplicable && (
				<CoordinationClient messageId={INSIGHTS_OPEN_UP_MESSAGE_ID}>
					<CoordinationStopProvider>
						{(stopEngagementMessage) => (
							<JiraSpotlight
								actions={[
									{
										onClick: () => {
											stopEngagementMessage?.();
											onOpenInsightsPanel();
											onDismiss();
										},
										text: formatMessage(messages.viewButtonLabel),
									},
									{
										onClick: () => {
											stopEngagementMessage?.();
											onDismiss();
										},
										text: formatMessage(messages.skipButtonLabel),
									},
								]}
								messageType="engagement"
								messageId={INSIGHTS_OPEN_UP_MESSAGE_ID}
								target="jira-insights-button"
								key={INSIGHTS_OPEN_UP_MESSAGE_ID}
								targetRadius={3}
								dialogPlacement="bottom right"
							>
								{formatMessage(messages.spotlightContent)}
							</JiraSpotlight>
						)}
					</CoordinationStopProvider>
				</CoordinationClient>
			)}
		</SpotlightTransition>
	);
};

type StopFunction = () => void;
type CoordinationStopProviderProps = {
	stop?: StopFunction;
	children: (arg1: StopFunction | undefined) => ReactElement;
};
const CoordinationStopProvider = ({ stop, children }: CoordinationStopProviderProps) =>
	children(stop);
