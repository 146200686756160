import React from 'react';
import { styled } from '@compiled/react';
import { SLA_VIEW_MODE_CARD } from '@atlassian/jira-issue-sla/src/common/constants.tsx';
import { AsyncLazySla } from '@atlassian/jira-issue-sla/src/ui/sla/async.tsx';
import type { SlaResponse } from '@atlassian/jira-issue-sla/src/ui/sla/types.tsx';
import { transformSlaResponse } from '@atlassian/jira-issue-sla/src/ui/sla/utils.tsx';

export const Sla = (props: SlaResponse) => {
	const transformedSla = transformSlaResponse(props);

	return (
		<SlaContainer>
			<AsyncLazySla value={transformedSla.value} viewMode={SLA_VIEW_MODE_CARD} />
		</SlaContainer>
	);
};

export default Sla;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SlaContainer = styled.div({
	display: 'flex',
	alignItems: 'flex-start',
});
