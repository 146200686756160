const appendAgilityReferrer = (url: string) => `${url}&referrer=agility`;

export const getProjectIssueNavigatorUrl = (contextPath: string, projectKey: string) =>
	`${contextPath}/projects/${projectKey}/issues`;

export const getNextGenProjectIssueNavigatorUrl = (contextPath: string, projectKey: string) =>
	`${contextPath}/jira/software/projects/${projectKey}/issues/`;

export const getResolvedIssuesUrl = (contextPath: string, projectKey: string) =>
	appendAgilityReferrer(
		`${getNextGenProjectIssueNavigatorUrl(contextPath, projectKey)}?filter=doneissues`,
	);

export const getOldIssuesUrl = (contextPath: string, projectKey: string) =>
	`${contextPath}/issues/?jql=${encodeURIComponent(
		`project = ${projectKey} AND (fixVersion in unreleasedVersions() OR fixVersion is EMPTY) AND statusCategory = Done AND updated <= -2w ORDER BY Rank ASC`,
	)}`;
