export const getColumnStatusMappingUrl = (
	projectKey: string,
	boardId: string,
	isCMPBoard = false,
): string =>
	isCMPBoard
		? `/jira/software/c/projects/${projectKey}/boards/${boardId}/?config=columns`
		: `/jira/software/projects/${projectKey}/settings/boards/${boardId}/columns`;

export const getManageCustomFiltersUrl = (projectKey: string, boardId: string): string =>
	`/jira/software/projects/${projectKey}/settings/boards/${boardId}/custom-filters`;

export const getIssueTypesUrl = (projectKey: string): string =>
	`/plugins/servlet/project-config/${projectKey}/issuetypes`;
