import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { fetchCanUserInviteService } from '../../services/can-user-invite/index.tsx';
import { setCanUserInvite } from '../../state/actions/can-user-invite/index.tsx';
import { WORK_DATA_SET } from '../../state/actions/work/index.tsx';
import {
	projectIdSelector,
	getIsCMPBoard,
} from '../../state/selectors/software/software-selectors.tsx';
import type { Action, ActionsObservable, MiddlewareAPI } from '../../state/types.tsx';

export const getCanUserInviteEpic = (
	action$: ActionsObservable,
	store: MiddlewareAPI,
): Observable<Action> =>
	action$.ofType(WORK_DATA_SET).switchMap(() => {
		const state = store.getState();
		const isCMPBoard = getIsCMPBoard(state);

		if (isCMPBoard) {
			return Observable.of();
		}

		const projectId = projectIdSelector(state);
		return fetchCanUserInviteService(projectId)
			.mergeMap((canUserInviteResponse) => Observable.of(setCanUserInvite(canUserInviteResponse)))
			.catch((error) => {
				if (!isClientFetchError(error)) {
					fireErrorAnalytics({
						error,
						meta: {
							id: 'board.can-user-invite.load.failure',
							packageName: 'jiraSoftwareBoard',
							teamName: 'a4t-tanuki',
						},
					});
				}
				return Observable.of();
			});
	});
