import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/startWith';
import 'rxjs/add/operator/map';
import {
	isPageVisible,
	supportedVisiblityEvent,
} from '@atlassian/jira-common-page-visibility/src/index.tsx';

/**
 * Returns a stream of the current page visibility state whenever it changes.
 * Emits 'true' when the page is visible and 'false' when the page is hidden.
 *
 * Will emit `null` if document is not defined.
 *
 * This Observable WILL EMIT a value at the start of the load. If you're using
 * it to trigger refreshes, you should skip 1 value.
 */
const getVisibility$: () => Observable<boolean | null> = () =>
	// @ts-expect-error - TS2322 - Type 'Observable<unknown>' is not assignable to type 'Observable<boolean | null>'.
	typeof document !== 'undefined'
		? Observable.fromEvent(document, supportedVisiblityEvent)
				// We want to send the current visibility on a new subscription.
				// If we use `.startWith(isPageVisible())` then new subscribers
				// will get the initial visibility at load and not the current
				// visibility as the initial value.
				.startWith(null)
				.map(isPageVisible)
		: new Subject().startWith(null);

export default getVisibility$;
