import type { Store, Middleware, PreloadedState } from 'redux';
import { createLogger as createReduxLogger } from 'redux-logger';
import type { Epic } from 'redux-observable';
import merge from 'lodash/merge';
import type { SwagApolloClient } from '@atlassian/jira-apollo-swag/src/index.tsx';
import type { ProjectType } from '@atlassian/jira-common-constants/src/index.tsx';
import { disposableEpic } from '@atlassian/jira-common-redux-disposable/src/controllers/disposable-epic/index.tsx';
import initiateStore from '@atlassian/jira-common-tangerine/src/state/initiate.tsx';
import errorHandlerReduxMiddleware from '@atlassian/jira-errors-handling/src/utils/redux-middleware.tsx';
import { CachedStoreManager } from '@atlassian/jira-software-redux-cached-store-manager/src/index.tsx';
import { createJiraSoftwareStoreContext } from '@atlassian/jira-software-redux-hooks/src/index.tsx';
import { APP_ID } from '../model/constants.tsx';
import type { CustomRequestHandlers } from '../model/issue/issue-increment-planning-types.tsx';
import getRootEpic from '../ops/software-root-epic.tsx';
import softwareOnReadyEpic from '../ops/software/software-on-ready-epic.tsx';
import { SET_ACTIVE_ISSUE_WITH_ICC } from './actions/card/index.tsx';
import type { Action } from './actions/index.tsx';
import analyticsMiddleware from './middleware/analytics/index.tsx';
import { actionMetricsMiddleware } from './middleware/metrics/index.tsx';
import resourcesCacheMiddleware from './middleware/resources-cache/index.tsx';
import createCombinedReducer from './reducers/reducer.tsx';
import type { State, BoardDependencies } from './types.tsx';

export type BoardStoreOptions = {
	isCMPBoard: boolean;
	isServer: boolean;
	projectType: ProjectType;
	swagClient: SwagApolloClient;
	/**
	 * Disable caching of redux store state. This is to enable integration tests
	 * to run without waiting for a refresh.
	 */
	disableStoreCaches: boolean;
	/**
	 * Below are props for ARJ increment planning board
	 */
	customRequestHandlers?: CustomRequestHandlers;
};

export type BoardStore = Store<State>;

/**
 * @deprecated Use create store with cache
 */
export default function createStore(
	state: PreloadedState<State>,
	options: BoardStoreOptions,
): BoardStore {
	const rootReducer = createCombinedReducer();
	const middlewares: Middleware[] = [];
	let initRootEpic;
	if (options.isServer) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		initRootEpic = softwareOnReadyEpic as Epic<Action, State>;
	} else {
		initRootEpic = disposableEpic(getRootEpic());
	}

	const boardDependencies: BoardDependencies = {
		isCMPBoard: options.isCMPBoard,
		swagClient: options.swagClient,
		customRequestHandlers: options.customRequestHandlers,
	};

	if (process.env.NODE_ENV === 'development') {
		middlewares.push(
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			createReduxLogger({
				collapsed: true,
				duration: true,
				predicate: (_getState, action) => action.type !== SET_ACTIVE_ISSUE_WITH_ICC,
			}) as Middleware,
		);
	}

	if (!options.isServer) {
		middlewares.push(analyticsMiddleware);
		middlewares.push(actionMetricsMiddleware);

		// @ts-expect-error - Argument of type '() => (next: (action: Action) => Action) => (action: Action) => Action' is not assignable to parameter of type 'Middleware'.
		middlewares.push(resourcesCacheMiddleware);
		middlewares.push(errorHandlerReduxMiddleware(APP_ID));
	}

	return initiateStore<State, Action, BoardDependencies>({
		appName: 'Software',
		rootReducer,
		rootEpic: initRootEpic,
		rootEpicDependencies: boardDependencies,
		middlewares,
		initialState: state,
	});
}

const {
	Provider: BoardReduxProvider,
	useSelector: useBoardSelector,
	useDispatch: useBoardDispatch,
	useActionCreator: useBoardActionCreator,
	useStore: useBoardStore,
} = createJiraSoftwareStoreContext<State, Action>();

export {
	BoardReduxProvider,
	useBoardSelector,
	useBoardDispatch,
	useBoardActionCreator,
	useBoardStore,
};

const cachedStoreManager = new CachedStoreManager({
	mergeCachedState(cachedState, preloadedState) {
		return {
			configuration: merge(preloadedState.configuration, cachedState.configuration),
			ui: merge(preloadedState.ui, {
				swimlane: cachedState.ui.swimlane,
			}),
			entities: cachedState.entities,
		};
	},
	storeFactory: createStore,
});

/**
 * Create a redux store and hydrate it with cached state if available.
 */
export const createStoreWithCache = (
	boardId: string,
	preloadedStateParam: Omit<PreloadedState<State>, 'entities'>,
	options: BoardStoreOptions,
): BoardStore => cachedStoreManager.createStore(boardId, preloadedStateParam, options);

/**
 * Clean-up redux store subscriptions.
 */
export const unmountCachedStore = () => {
	cachedStoreManager.unmount();
};
