import { useMutation, type MutationTuple } from '@apollo/react-hooks';
import gqlTagSwag from 'graphql-tag';
import type {
	SoftwareBoardUnclearCards_unclearCards as Response,
	SoftwareBoardUnclearCardsVariables as Variables,
} from './__generated_apollo__/SoftwareBoardUnclearCards';

export const MUTATION = gqlTagSwag`
    mutation SoftwareBoardUnclearCards($boardId: ID!, $cardIds: [ID!]!) {
        unclearCards(input: { boardId: $boardId, cardIds: $cardIds }) {
            message
            statusCode
        }
    }
`;

export const useUnclearCardsMutation = (): MutationTuple<Response, Variables> =>
	useMutation<Response, Variables>(MUTATION);
