import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { INSIGHTS_PACKAGE } from '@atlassian/jira-insights-common-constants/src/common/constants/package.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State, PropertyValue } from '../../types.tsx';
import { setUserProperties } from './utils.tsx';

export const saveProperty =
	<T,>(
		propertyKey: string,
		value: PropertyValue<T>,
		isResetOnFailEnabled = true,
	): Action<State, Props> =>
	async ({ setState, getState }, { accountId }) => {
		if (!accountId) {
			return;
		}

		const oldValue = getState().properties[propertyKey]?.value;

		setState({
			properties: {
				...getState().properties,
				[propertyKey]: {
					value,
					isLoading: false,
					error: null,
				},
			},
		});

		try {
			await setUserProperties(accountId, propertyKey, value);
		} catch (error: unknown) {
			if (error instanceof FetchError) {
				// rollback optimistic update on setUserProperties failure
				if (isResetOnFailEnabled) {
					setState({
						properties: {
							...getState().properties,
							[propertyKey]: {
								value: oldValue,
								isLoading: false,
								error: null,
							},
						},
					});
				}

				if (error.statusCode >= 500 && error.statusCode <= 599) {
					log.safeErrorWithoutCustomerData(
						`${INSIGHTS_PACKAGE}.user-properties-error`,
						`Failed to save user property with key: ${propertyKey}`,
						error,
					);
				}
			} else {
				throw error;
			}
		}
	};

export const savePropertyWithoutSendRequest =
	<T,>(propertyKey: string, value: PropertyValue<T>): Action<State, Props> =>
	({ setState, getState }, { accountId }) => {
		if (!accountId) {
			return;
		}

		setState({
			properties: {
				...getState().properties,
				[propertyKey]: {
					value,
					isLoading: false,
					error: null,
				},
			},
		});
	};

export const sendUpdatePropertyRequest =
	<T,>(propertyKey: string, value: PropertyValue<T>): Action<State, Props> =>
	async (_, { accountId }) => {
		if (!accountId) {
			return;
		}

		try {
			await setUserProperties(accountId, propertyKey, value);
		} catch (error: unknown) {
			if (error instanceof FetchError) {
				if (error.statusCode >= 500 && error.statusCode <= 599) {
					log.safeErrorWithoutCustomerData(
						`${INSIGHTS_PACKAGE}.user-properties-error`,
						`Failed to save user property with key: ${propertyKey}`,
						error,
					);
				}
			} else {
				throw error;
			}
		}
	};
