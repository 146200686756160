import React from 'react';
import { OnboardingNudgeErrorBoundary } from '@atlassian/jira-onboarding-quickstart-core/src/common/ui/onboarding-nudge-error-boundary/index.tsx';
import {
	BOARD_CONTAINER_NUDGE_ID,
	ERROR_BOUNDARY_ID_SAMPLE_PROJECT_DATA_NUDGE,
} from '@atlassian/jira-software-onboarding-nudges--next/src/common/constants.tsx';
import { SampleProjectDataNudge } from '@atlassian/jira-software-onboarding-nudges--next/src/ui/sample-project-data/index.tsx';
import { useBoardSelector } from '../../../../state/index.tsx';
import { getFirstNonEpicIssueInFirstColumn } from '../../../../state/selectors/software/software-selectors.tsx';

export const SampleProjectDataNudgeWrapper = () => {
	const issueKey = useBoardSelector(getFirstNonEpicIssueInFirstColumn);
	return (
		<OnboardingNudgeErrorBoundary
			id={ERROR_BOUNDARY_ID_SAMPLE_PROJECT_DATA_NUDGE}
			fallback={<></>}
			attributes={{
				nudgeId: BOARD_CONTAINER_NUDGE_ID,
			}}
		>
			<SampleProjectDataNudge issueKey={issueKey} />
		</OnboardingNudgeErrorBoundary>
	);
};
