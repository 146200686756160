/** @jsx jsx */
import React, { useMemo } from 'react';
import { styled, jsx } from '@compiled/react';
import noop from 'lodash/noop';
import { Box, xcss } from '@atlaskit/primitives';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import type { Person } from '../../../../common/types.tsx';
import { FILTER_PREFIX } from '../../../../common/ui/assignee/constants.tsx';
import AddButton from './add-button/index.tsx';
import Avatar from './avatar/index.tsx';
import messages from './messages.tsx';
import ShowMoreButton from './show-more-button/index.tsx';

export type CommonAssigneeFilterProps = {
	selectedUsers: AccountId[];
	users: Person[];
	maxUsers: number;
	// This is for Free edition touchpoint analytics
	// go/jfe-eslint
	screenName?: string;
	// go/jfe-eslint
	projectId: number;
	onToggle: (id: AccountId) => void;
	onAssigneeMenuOpen: () => void;
};

export type Props = CommonAssigneeFilterProps & {
	showAddPeopleButton: boolean;
	onAddPeopleClick: () => void;
	peopleRef?: React.Ref<HTMLButtonElement>;
};

type PropsWithIntl = Props & {
	intl: IntlShape;
};

const AssigneeFilter = (props: PropsWithIntl) => {
	const {
		users,
		selectedUsers,
		maxUsers,
		onAddPeopleClick,
		showAddPeopleButton,
		onToggle,
		onAssigneeMenuOpen,
		intl: { formatMessage },
		peopleRef,
	} = props;

	const mainList = useMemo(
		() => (users.length > maxUsers ? users.slice(0, maxUsers - 1) : users.slice(0)),
		[users, maxUsers],
	);
	const moreList = useMemo(
		() => (users.length > maxUsers ? users.slice(maxUsers - 1) : []),
		[users, maxUsers],
	);

	return (
		<Box
			as="fieldset"
			xcss={[isVisualRefreshEnabled() ? fieldsetStyles : fieldsetStylesOld]}
			data-test-id="filters.ui.filters.assignee.stateless.assignee-filter"
			testId="filters.ui.filters.assignee.stateless.assignee-filter"
			// eslint-disable-next-line jira/react/no-style-attribute
			style={{
				// Ensure layering of avatars does not conflict with anything else in the DOM tree
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
				zIndex: 0,
			}}
		>
			<AvatarFilterLegend id={FILTER_PREFIX}>{formatMessage(messages.label)}</AvatarFilterLegend>
			<AvatarsList>
				{mainList.map((user, index) => (
					<Avatar
						key={user.id}
						zIndex={maxUsers - index}
						user={user}
						isSelected={selectedUsers.includes(user.id)}
						onToggle={onToggle}
					/>
				))}
				{moreList.length > 0 && (
					<ShowMoreButton
						users={moreList}
						selectedUsers={selectedUsers}
						onItemActivated={onToggle}
						onOpen={onAssigneeMenuOpen}
					/>
				)}
				{showAddPeopleButton && (
					<AddButton peopleButtonRef={peopleRef} onClick={onAddPeopleClick} />
				)}
			</AvatarsList>
		</Box>
	);
};

AssigneeFilter.defaultProps = {
	onAddPeopleClick: noop,
	onAssigneeMenuOpen: noop,
	onToggle: noop,
	showAddPeopleButton: false,
};

export default injectIntl(AssigneeFilter);

const fieldsetStyles = xcss({
	flexShrink: 0,
	border: 0,
});

const fieldsetStylesOld = xcss({
	flexShrink: 0,
	border: 0,
	marginLeft: 'space.150',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarsList = styled.div({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarFilterLegend = styled.legend({
	position: 'absolute',
	height: '1px',
	width: '1px',
	padding: 0,
	border: 0,
	clip: 'rect(1px, 1px, 1px, 1px)',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
});
