/**
 * Browsers allowed to run sticky position are:
 * Firefox (any version)
 * Chrome > 58
 * Any other which supports non prefixed version of sticky
 */

let isSupported = false;

const isCSSStickySupported = (): boolean =>
	typeof window !== 'undefined' &&
	window.CSS &&
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	window.CSS.supports &&
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	window.CSS.supports('position', 'sticky');

const chromeInfo =
	typeof navigator !== 'undefined' ? navigator.userAgent.match(/Chrome\/(\d+)/) : null;
if (chromeInfo) {
	// Chrome <= 58 has buggy sticky support
	if (Array.isArray(chromeInfo) && chromeInfo.length === 2) {
		const chromeVersion = Number(chromeInfo[1]);
		const isBrowserBuggy = chromeVersion < 59;
		isSupported = !isBrowserBuggy && isCSSStickySupported();
	}
} else {
	isSupported = isCSSStickySupported();
}

export const isStickySupported = isSupported;
