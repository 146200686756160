import { defineMessages } from '@atlassian/jira-intl';
import type { FlagConfiguration } from '../../../../services/types.tsx';

const messages = defineMessages({
	title: {
		id: 'platform.ui.flags.flags.errors.server-error.title',
		defaultMessage: "We're having some trouble",
		description: '',
	},
	description: {
		id: 'platform.ui.flags.flags.errors.server-error.description',
		defaultMessage: 'Something went wrong on our end. Refresh the page and try again.',
		description: '',
	},
});

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (): FlagConfiguration => ({
	type: 'error',
	title: messages.title,
	description: messages.description,
});
