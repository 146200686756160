import React from 'react';
import CustomIcon, {
	SVG,
	type CustomIconProps,
} from '../../../../../common/ui/custom-icon/index.tsx';

// Because this is a logo it should be presented on white and design tokens are not used
/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
const LaunchDarklyGlyph: React.FunctionComponent = () => (
	<SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24px" width="24px">
		<path
			fill="#172B4D"
			fillRule="nonzero"
			d="M12.12 21.994a.448.448 0 0 1-.393-.247.422.422 0 0 1 .04-.448l4.654-6.468-8.154 3.365a.422.422 0 0 1-.175.038.44.44 0 0 1-.416-.316.427.427 0 0 1 .184-.485l7.166-4.27-12.62-.732a.435.435 0 0 1-.404-.436c0-.23.177-.42.404-.435l12.627-.732-7.17-4.27a.437.437 0 0 1 .237-.8c.06 0 .12.013.175.037l8.149 3.368-4.654-6.467a.427.427 0 0 1-.041-.45.446.446 0 0 1 .395-.246c.114 0 .223.048.302.131l9.276 9.362a.716.716 0 0 1 0 1.005l-9.28 9.365a.421.421 0 0 1-.302.131Z"
		/>
	</SVG>
);
/* eslint-enable @atlaskit/design-system/ensure-design-token-usage */

const LaunchDarklyIcon: React.FunctionComponent<CustomIconProps> = (props) => (
	<CustomIcon {...props} glyph={() => <LaunchDarklyGlyph />} />
);

export default LaunchDarklyIcon;
