import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/filter';
import 'rxjs/add/observable/from';
import 'rxjs/add/operator/mergeMap';
import { setAllFilters } from '../../state/actions/filter/index.tsx';
import {
	hasInvalidFilterValuesSelector,
	isCurrentUserLoaded,
	validFilterValuesSelector,
	isBoardConfigLoaded,
} from '../../state/selectors/work/work-selectors.tsx';
import type { MiddlewareAPI, ActionsObservable, State } from '../../state/types.tsx';

const makeFilter = (state: State) =>
	isBoardConfigLoaded(state) && isCurrentUserLoaded(state) && hasInvalidFilterValuesSelector(state);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable, store: MiddlewareAPI) =>
	action$
		.filter(() => makeFilter(store.getState()))
		.mergeMap(() => Observable.from([setAllFilters(validFilterValuesSelector(store.getState()))]));
