import { createSelector } from 'reselect';
import memoize from 'lodash/memoize';
import type { ExternalAction } from '@atlassian/jira-issue-view-store/src/actions/external-actions.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import type { IssueKey } from '../../../model/issue/issue-types.tsx';
import type { State } from '../../reducers/types.tsx';
import { getIssueChildren } from '../issue-children/index.tsx';
import { getIssueParents } from '../issue-parent/index.tsx';
import { getUi, getIssues } from '../software/software-selectors.tsx';

export const getIssueModal = (state: State) => getUi(state).issueModal;

export const getIssueKeyForModal = (state: State): string | null | undefined =>
	getIssueModal(state)?.selectedIssueKey;

export const getTimestampForModal = (state: State): number => getIssueModal(state)?.timestamp ?? 0;

export const getExternalActionForModal = (state: State): ExternalAction | undefined =>
	getIssueModal(state)?.externalAction;

export const getIssueByIdForModal = createSelector(
	[getIssueParents, getIssues, getIssueChildren],
	(issueParents, issues, issueChildren) => {
		const issueById = (issueId: IssueId) =>
			issueParents[String(issueId)] ||
			issues[String(issueId)] ||
			issueChildren[String(issueId)] ||
			null;
		return memoize(issueById);
	},
);

export const getIssueByKeyForModal = createSelector(
	[getIssueParents, getIssues, getIssueChildren],
	(issueParents, issues, issueChildren) => {
		const issueByKeyForModal = (issueKey: IssueKey | null | undefined) => {
			const allIssues = {
				...issueParents,
				...issues,
				...issueChildren,
			};

			return (
				Object.keys(allIssues)
					.map((key) => allIssues[key])
					.find((issue) => issue.key === issueKey) || null
			);
		};
		return memoize(issueByKeyForModal);
	},
);

export const getIssueIdForModal = (state: State) => {
	const issueKey = getIssueKeyForModal(state);
	const issue = getIssueByKeyForModal(state)(issueKey);

	return issue ? issue.id : null;
};

export const isIssueModalShowingSelector = (state: State) => getIssueKeyForModal(state) !== null;
