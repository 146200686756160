import React, { useRef, useState } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import DropdownMenu from '@atlaskit/dropdown-menu';
import AppIcon from '@atlaskit/icon/core/app';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { ButtonItem, LinkItem, MenuGroup, Section, type ButtonItemProps } from '@atlaskit/menu';
import Popup from '@atlaskit/popup';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { lazy, LazySuspense } from '@atlassian/react-loosely-lazy';
import type { BoardToolWebItem, BoardToolSectionSubItem } from '../../../../types.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const AsyncBoardToolWebPanelView = lazy(() =>
	import(/* webpackChunkName: "async-board-tool-web-panel" */ './board-tool-web-panel-view').then(
		({ BoardToolWebPanelView }) => ({ default: BoardToolWebPanelView }),
	),
);

interface Props {
	subItem: BoardToolSectionSubItem;
	onClickWebItem?: () => void;
	isTabNavigation?: boolean;
}

type MenuItemProps = Pick<ButtonItemProps, 'onClick' | 'iconBefore' | 'children' | 'testId'>;

const MenuItem = ({ onClick, iconBefore, children, testId }: MenuItemProps) => {
	return fg('make_board_menu_popup') ? (
		<ButtonItem testId={testId} onClick={onClick} iconBefore={iconBefore}>
			{children}
		</ButtonItem>
	) : (
		<LinkItem testId={testId} onClick={onClick} iconBefore={iconBefore}>
			{children}
		</LinkItem>
	);
};

export function BoardToolWebItemView({
	subItem,
	mode = 'dropdown-item',
	onClickWebItem,
	isTabNavigation = false,
}: {
	subItem: BoardToolWebItem;
	mode?: 'button' | 'dropdown-item';
	onClickWebItem?: () => void;
	isTabNavigation?: boolean;
}) {
	const webItemLinkRef = useRef<HTMLAnchorElement>(null);
	const handleOnClickWebItem = (e: React.MouseEvent | React.KeyboardEvent) => {
		e.preventDefault();
		webItemLinkRef.current?.click();
		if (onClickWebItem) onClickWebItem();
	};

	return (
		<>
			{mode === 'dropdown-item' ? (
				<Tooltip
					content={
						(getWillShowNav4() && !fg('jsm_views_connect_apps_bugfix')) || isTabNavigation
							? subItem.label
							: undefined
					}
				>
					<MenuItem
						onClick={handleOnClickWebItem}
						iconBefore={
							getWillShowNav4() && <AppIcon label={subItem.label} color={token('color.icon')} />
						}
						testId="software-connect-toolbar.common.ui.tool-sections.board-tool-section-view.board-tool-sub-item.link-item"
					>
						{subItem.label}
					</MenuItem>
				</Tooltip>
			) : (
				<Button
					testId="software-connect-toolbar.common.ui.tool-sections.board-tool-section-view.board-tool-sub-item.link-item"
					onClick={handleOnClickWebItem}
				>
					{subItem.label}
				</Button>
			)}

			{/**
			 * Connect add-ons work by a "DOM is source of truth" approach.
			 * This is a very outdated style. Class name on this element
			 * will be used by another script to attach event listeners and
			 * open this plug-in into a modal.
			 */}
			<HiddenLink
				ref={webItemLinkRef}
				href={subItem.url}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={subItem.cssClass ?? ''}
			>
				{subItem.label}
			</HiddenLink>
		</>
	);
}

export function BoardToolSubItem({ subItem, onClickWebItem, isTabNavigation = false }: Props) {
	const [isOpen, setIsOpen] = useState(false);
	if (subItem.type === 'webItem') {
		return (
			<BoardToolWebItemView
				onClickWebItem={onClickWebItem}
				subItem={subItem}
				isTabNavigation={isTabNavigation}
			/>
		);
	}
	if (subItem.type === 'section') {
		const content = subItem.subItems?.map((item) => (
			<BoardToolSubItem key={item.id} subItem={item} isTabNavigation={isTabNavigation} />
		));

		if (subItem.label === '') {
			return <>{content}</>;
		}

		if ((getWillShowNav4() && !fg('jsm_views_connect_apps_bugfix')) || isTabNavigation) {
			return (
				<Popup
					isOpen={isOpen}
					onClose={() => setIsOpen(false)}
					placement="left-start"
					trigger={(triggerProps) => (
						<Tooltip content={subItem.label}>
							<ButtonItem
								{...triggerProps}
								iconBefore={
									getWillShowNav4() && <AppIcon label={subItem.label} color={token('color.icon')} />
								}
								iconAfter={<ChevronRightIcon label="" color={token('color.icon')} />}
								onClick={() => setIsOpen(!isOpen)}
								testId="software-connect-toolbar.common.ui.tool-sections.board-tool-section-view.board-tool-sub-item.popup-menu-trigger"
							>
								{subItem.label}
							</ButtonItem>
						</Tooltip>
					)}
					content={() => (
						<MenuGroup maxWidth={240} onClick={(e) => e.stopPropagation()}>
							<Section>{content}</Section>
						</MenuGroup>
					)}
				/>
			);
		}

		return (
			<DropdownMenu
				placement="right-end"
				trigger={subItem.label}
				testId="software-connect-toolbar.common.ui.tool-sections.board-tool-section-view.board-tool-sub-item.dropdown-menu"
			>
				{content}
			</DropdownMenu>
		);
	}
	if (subItem.type === 'webPanel') {
		return (
			<JSErrorBoundary
				id="board-tool-web-panel-view"
				packageName="@atlassian/jira-software-connect-toolbar"
				teamName="a4t-tanuki"
			>
				<LazySuspense fallback="Loading...">
					<AsyncBoardToolWebPanelView webPanel={subItem} />
				</LazySuspense>
			</JSErrorBoundary>
		);
	}

	return null;
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HiddenLink = styled.a({
	display: 'none',
});
