import { useCallback } from 'react';
import isNil from 'lodash/isNil';
import { fg } from '@atlassian/jira-feature-gating';
import { FIELD_TYPE_MAP } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/constants.tsx';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import { SPRINT_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import type { MenuNode } from '@atlassian/jira-software-context-menu/src/common/types.tsx';
import { Capability } from '../../../../../../../../common/capability/index.tsx';
import {
	NO_SWIMLANE,
	SWIMLANE_BY_SUBTASK,
} from '../../../../../../../../model/swimlane/swimlane-modes.tsx';
import { getSwimlaneId } from '../../../../../../../../services/issue/issue-data-transformer.tsx';
import { issueMoveToBacklogRequest } from '../../../../../../../../state/actions/issue/move-to-backlog/index.tsx';
import {
	bulkIssueRankTransitionRequest,
	issueRankTransitionRequest,
} from '../../../../../../../../state/actions/issue/rank-transition/index.tsx';
import { useBoardSelector, useBoardActionCreator } from '../../../../../../../../state/index.tsx';
import { isBacklogEnabled as isBacklogEnabledSelector } from '../../../../../../../../state/selectors/board/board-selectors.tsx';
import {
	areAnySelectedCardsIssueChildren,
	isCardSelected,
} from '../../../../../../../../state/selectors/card/card-selectors.tsx';
import { getIssueChildren } from '../../../../../../../../state/selectors/issue-children/index.tsx';
import {
	boardIssuesSelector,
	isBoardIssueAChild,
} from '../../../../../../../../state/selectors/issue/board-issue-selectors.tsx';
import {
	getFirstIssueIdInSwimlane,
	getLastIssueIdInSwimlane,
} from '../../../../../../../../state/selectors/issue/issue-in-swimlane-selectors.tsx';
import {
	getFirstIssueInColumn,
	getLastIssueInColumn,
	getFirstIssueChildInColumnByParent,
	getLastIssueChildInColumnByParent,
} from '../../../../../../../../state/selectors/issue/issue-selectors.tsx';
import { isSprintsEnabled as isSprintsEnabledSelector } from '../../../../../../../../state/selectors/sprint/sprint-selectors.tsx';
import { getSwimlaneMode } from '../../../../../../../../state/selectors/swimlane/swimlane-mode-selectors.tsx';
import { getSwimlanes } from '../../../../../../../../state/selectors/swimlane/swimlane-selectors.tsx';
import { useCapability } from '../../../../../../../../state/state-hooks/capabilities/index.tsx';
import type { Props } from './types.tsx';
import { useMoveToContent } from './view.tsx';

const FIELD_KEY = FIELD_TYPE_MAP[SPRINT_TYPE];

export const useMoveTo = ({ issueId, selectedCardIds, isMultiSelectMode }: Props): MenuNode[] => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const analyticsEvent = createAnalyticsEvent({});

	const allIssues = useBoardSelector(boardIssuesSelector);
	const selectedIssue = allIssues[String(issueId)];
	const selectedCardsContainSubtasks = useBoardSelector((state) =>
		isMultiSelectMode
			? areAnySelectedCardsIssueChildren(state)
			: isBoardIssueAChild(state)(issueId),
	);
	const isSelected = useBoardSelector(isCardSelected)(issueId);

	const swimlaneMode = useBoardSelector(getSwimlaneMode);
	const hasSwimlanes = swimlaneMode !== NO_SWIMLANE.id;
	const swimlaneId = useBoardSelector((state) =>
		hasSwimlanes && !isNil(selectedIssue)
			? getSwimlaneId(swimlaneMode, getSwimlanes(state), selectedIssue)
			: null,
	);
	const issueChildren = useBoardSelector(getIssueChildren);
	const isMoveGroupCard = swimlaneMode !== SWIMLANE_BY_SUBTASK.id && issueId in issueChildren;
	const tryGetRankableIssueId = (id: IssueId | null | undefined) => {
		if (swimlaneMode !== SWIMLANE_BY_SUBTASK.id && id && id in issueChildren) {
			return issueChildren[id].parentId;
		}

		return id;
	};

	const firstIssueChildInColumnByParent = useBoardSelector((state) =>
		getFirstIssueChildInColumnByParent(state, selectedIssue?.columnId, selectedIssue?.parentId),
	);
	const lastIssueChildInColumnByParent = useBoardSelector((state) =>
		getLastIssueChildInColumnByParent(state, selectedIssue?.columnId, selectedIssue?.parentId),
	);

	const firstIssueIdInColumn = useBoardSelector((state) =>
		!hasSwimlanes || !swimlaneId
			? getFirstIssueInColumn(state, selectedIssue?.columnId)
			: getFirstIssueIdInSwimlane(state, selectedIssue?.columnId, swimlaneId),
	);
	const lastIssueIdInColumn = useBoardSelector((state) =>
		!hasSwimlanes || !swimlaneId
			? getLastIssueInColumn(state, selectedIssue?.columnId)
			: getLastIssueIdInSwimlane(state, selectedIssue?.columnId, swimlaneId),
	);

	const firstIssueId = isMoveGroupCard
		? firstIssueChildInColumnByParent
		: tryGetRankableIssueId(firstIssueIdInColumn);

	const lastIssueId = isMoveGroupCard
		? lastIssueChildInColumnByParent
		: tryGetRankableIssueId(lastIssueIdInColumn);

	const isFirst = issueId === firstIssueId;
	const isLast = issueId === lastIssueId;

	const canMoveToBacklog = useCapability(Capability.RENDER_MOVE_TO_BACKLOG_CONTEXT_MENU_ITEM);

	const isMoveToBacklogEnabled = useBoardSelector(
		(state) =>
			canMoveToBacklog &&
			isBacklogEnabledSelector(state) &&
			!isSprintsEnabledSelector(state) &&
			!selectedCardsContainSubtasks,
	);

	const fireAnalyticsEnd = useCallback(
		(isRankChange = true) => {
			if (selectedCardIds.length !== 1) {
				return;
			}
			getUpdateAnalyticsFlowHelper().fireAnalyticsEnd(FIELD_KEY, {
				analytics: createAnalyticsEvent({}),
				attributes: {
					fieldType: SPRINT_TYPE,
					...(isRankChange
						? { hasRankChanged: true }
						: {
								actionTaken: 'moveFromBoardToBacklog',
							}),
				},
			});
		},
		[createAnalyticsEvent, selectedCardIds.length],
	);

	const onMoveToBacklog = useBoardActionCreator(() => {
		const issueIdsToMove = selectedCardIds;
		const issueKeysToMove = selectedCardIds.map((cardId) => allIssues[String(cardId)].key);
		const firstColumnId = allIssues[String(selectedCardIds[0])].columnId;

		if (fg('one_event_rules_them_all_fg')) {
			fireAnalyticsEnd(false);
		}

		return issueMoveToBacklogRequest(issueIdsToMove, issueKeysToMove, firstColumnId);
	});

	const onMoveToTop = useBoardActionCreator(() => {
		if (isSelected && selectedCardIds.length > 1) {
			return bulkIssueRankTransitionRequest({
				issueIds: selectedCardIds,
				sourceColumnId: selectedIssue.columnId,
				destinationColumnId: selectedIssue.columnId,
				rankBeforeIssueId: firstIssueId,
				rankAfterIssueId: null,
				sourceSwimlaneId: null,
				destinationSwimlaneId: null,
				isSourceDone: false,
				isDestinationDone: false,
				analyticsEvent,
			});
		}
		if (fg('one_event_rules_them_all_fg')) {
			fireAnalyticsEnd();
		}
		return issueRankTransitionRequest({
			issueIds: [issueId],
			sourceColumnId: selectedIssue.columnId,
			destinationColumnId: selectedIssue.columnId,
			rankBeforeIssueId: firstIssueId,
			rankAfterIssueId: null,
			sourceSwimlaneId: null,
			destinationSwimlaneId: null,
			isSourceDone: false,
			isDestinationDone: false,
			analyticsEvent,
		});
	});

	const onMoveToBottom = useBoardActionCreator(() => {
		if (isSelected && selectedCardIds.length > 1) {
			return bulkIssueRankTransitionRequest({
				issueIds: selectedCardIds,
				sourceColumnId: selectedIssue.columnId,
				destinationColumnId: selectedIssue.columnId,
				rankBeforeIssueId: null,
				rankAfterIssueId: lastIssueId,
				sourceSwimlaneId: null,
				destinationSwimlaneId: null,
				isSourceDone: false,
				isDestinationDone: false,
				analyticsEvent,
			});
		}
		if (fg('one_event_rules_them_all_fg')) {
			fireAnalyticsEnd();
		}
		return issueRankTransitionRequest({
			issueIds: [issueId],
			sourceColumnId: selectedIssue.columnId,
			destinationColumnId: selectedIssue.columnId,
			rankBeforeIssueId: null,
			rankAfterIssueId: lastIssueId,
			sourceSwimlaneId: null,
			destinationSwimlaneId: null,
			isSourceDone: false,
			isDestinationDone: false,
			analyticsEvent,
		});
	});

	const onMouseEnter = useCallback(() => {
		getUpdateAnalyticsFlowHelper().fireAnalyticsStart(FIELD_KEY, {
			analytics: createAnalyticsEvent({}),
			attributes: {
				fieldType: SPRINT_TYPE,
			},
		});
	}, [createAnalyticsEvent]);

	return useMoveToContent({
		isMoveToBacklogEnabled,
		isMoveToTopActionVisible: !isFirst,
		isMoveToBottomActionVisible: !isLast,
		onMoveToBacklog,
		onMoveToTop,
		onMoveToBottom,
		...(selectedCardIds.length === 1 &&
			fg('one_event_rules_them_all_fg') && {
				onMouseEnter,
			}),
	});
};
