import React, { useMemo } from 'react';
import { styled } from '@compiled/react';
import isNil from 'lodash/isNil';
import Button from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import Link from '@atlaskit/link';
import { Stack, Box, xcss, Inline } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { safeLinkTypeName } from '@atlassian/jira-common-constants/src/issue-link-types.tsx';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { IssueLink } from '@atlassian/jira-issue-links-common/src/types.tsx';
import { LinkIssueFeedbackCollector } from '@atlassian/jira-issue-links-common/src/ui/feedback-button/index.tsx';
import {
	FireScreenAnalytics,
	ContextualAnalyticsData,
	DROPDOWN,
} from '@atlassian/jira-product-analytics-bridge';
import {
	EXCEED_MAX_SIZE_LINK_TESTID,
	LEFT_FOOTER_BUTTON_TESTID,
	RIGHT_FOOTER_BUTTON_TESTID,
	VIEW_IN_REPORT_BUTTON_TESTID,
} from '../common/constants.tsx';
import { getAnalyticsIssueType } from '../common/utils.tsx';
import { IssueLinksDetailsContent } from './issue-links-details-content/index.tsx';
import type { IssueLinksDetailsGroupProps } from './types.tsx';

export const IssueLinksDetailsGroup = ({
	issueLinkGroups,
	title,
	maxIssuesToDisplay = 50,
	exceedMaxSizeLinkOptions,
	leftFooterLinkOptions,
	rightFooterLinkOptions,
	viewInReportLinkOptions,
	showFeedbackCollector = true,
	feedbackCollectorPrefix,
	renderExternalLinksMessage,
	hideTitleWhenEmpty = false,
	issueTypeName,
	canUserLinkIssue,
	rightHeaderActions,
	...rest
}: IssueLinksDetailsGroupProps) => {
	const totalIssuesCount = useMemo(
		() =>
			issueLinkGroups
				? Object.values(issueLinkGroups).reduce((count, value) => count + value.length, 0)
				: 0,
		[issueLinkGroups],
	);

	const shouldHideTitleBlock = totalIssuesCount === 0 && hideTitleWhenEmpty;

	const renderContent = () => {
		let count = 0;
		if (!issueLinkGroups) return null;
		return Object.entries(issueLinkGroups).map(([key, issueLinkGroup], index) => {
			if (count < maxIssuesToDisplay) {
				return (
					<Stack key={index}>
						<GroupTitle>{key}</GroupTitle>
						{issueLinkGroup.map((issue) => {
							if (count < maxIssuesToDisplay) {
								count++;
								return (
									<IssueLinksDetailsContent
										{...rest}
										key={issue.key}
										issueLink={issue}
										canUserLinkIssue={canUserLinkIssue}
									/>
								);
							}
							return null;
						})}
					</Stack>
				);
			}
			return null;
		});
	};

	const screenAnalyticsAttributes = useMemo(() => {
		if (!issueLinkGroups) {
			return {};
		}
		const issueLinkGroupsKeys = Object.keys(issueLinkGroups);

		// Get number of issues for each issue status
		const issuesByStatus: { [statusAttributeName: string]: number } = {};
		issueLinkGroupsKeys.forEach((issueLinkType: string) => {
			issueLinkGroups[issueLinkType].forEach((issue: IssueLink) => {
				if (issue.statusName) {
					const statusAttributeName = `status_${safeStatusName(issue.statusName)}_issues_count`;
					issuesByStatus[statusAttributeName] = issuesByStatus[statusAttributeName] + 1 || 1;
				}
			});
		});

		return {
			numberOfRelationships: issueLinkGroupsKeys.length,
			relationships: issueLinkGroupsKeys.map(safeLinkTypeName),
			totalIssuesCount,
			issueTypeName: getAnalyticsIssueType(issueTypeName),
			...issuesByStatus,
		};
	}, [issueLinkGroups, totalIssuesCount, issueTypeName]);

	const feedbackCollectorLocation = feedbackCollectorPrefix
		? `${feedbackCollectorPrefix}-issue-links-popup`
		: 'issue-links-popup';

	return (
		<ContextualAnalyticsData sourceType={DROPDOWN} sourceName="IssueLinksDetails">
			<Box testId="issue-links-details.ui.box">
				{!shouldHideTitleBlock && (
					<Inline
						alignBlock="center"
						xcss={
							isNil(rightHeaderActions) && fg('dependency_visualisation_program_board_fe_and_be')
								? headingStyles
								: headingStyleForHeaderWithRightAction
						}
						spread="space-between"
						alignInline="start"
					>
						{fg('increment_board_a11y_fix') ? (
							<Heading size="xxsmall" as="h2">
								{title}
							</Heading>
						) : (
							<Title>{title}</Title>
						)}
						{showFeedbackCollector && (
							<LinkIssueFeedbackCollector
								feedbackCollectorLocation={feedbackCollectorLocation}
								appearance="icon"
							/>
						)}
						{fg('dependency_visualisation_program_board_fe_and_be') && rightHeaderActions}
					</Inline>
				)}
				<Box xcss={[bodyContainerStyles, shouldHideTitleBlock && bodyContainerHeaderHiddenStyles]}>
					<Stack space="space.050">{renderContent()}</Stack>

					{renderExternalLinksMessage &&
						(!exceedMaxSizeLinkOptions ||
							(exceedMaxSizeLinkOptions && totalIssuesCount <= maxIssuesToDisplay)) &&
						renderExternalLinksMessage()}

					{exceedMaxSizeLinkOptions && totalIssuesCount >= maxIssuesToDisplay && (
						<Box paddingBlockEnd="space.100" paddingBlockStart="space.100">
							<Button
								testId={EXCEED_MAX_SIZE_LINK_TESTID}
								onClick={exceedMaxSizeLinkOptions.onClick}
								appearance="link"
								spacing="none"
							>
								{exceedMaxSizeLinkOptions.text}
							</Button>
						</Box>
					)}

					{/* we only show the report link if the exceedMaxSizeLink isn't showing */}
					{viewInReportLinkOptions &&
						(!exceedMaxSizeLinkOptions ||
							(exceedMaxSizeLinkOptions && totalIssuesCount <= maxIssuesToDisplay)) &&
						fg('dependency_visualisation_program_board_fe_and_be') && (
							<Box paddingBlockEnd="space.100" paddingBlockStart="space.100">
								<Link testId={VIEW_IN_REPORT_BUTTON_TESTID} href={viewInReportLinkOptions.href}>
									{viewInReportLinkOptions.text}
								</Link>
							</Box>
						)}
				</Box>
				{(leftFooterLinkOptions || rightFooterLinkOptions) && (
					<Inline
						alignBlock="center"
						xcss={[
							footerStyles,
							shouldHideTitleBlock && !renderExternalLinksMessage && footerHeaderHiddenStyles,
						]}
						spread={leftFooterLinkOptions ? 'space-between' : undefined}
						alignInline={leftFooterLinkOptions ? 'start' : 'end'}
					>
						{leftFooterLinkOptions ? (
							<Inline xcss={leftFooterButtonStyles}>
								<Button
									testId={LEFT_FOOTER_BUTTON_TESTID}
									ref={leftFooterLinkOptions?.ref}
									iconBefore={leftFooterLinkOptions.icon}
									onClick={leftFooterLinkOptions?.onClick}
									appearance="subtle"
								>
									<Inline xcss={leftFooterButtonTextStyles}>{leftFooterLinkOptions?.text}</Inline>
								</Button>
							</Inline>
						) : null}
						{rightFooterLinkOptions && (
							<Button
								testId={RIGHT_FOOTER_BUTTON_TESTID}
								onClick={rightFooterLinkOptions.onClick}
								iconBefore={rightFooterLinkOptions.icon}
								appearance="subtle"
							>
								<Inline xcss={rightFooterButtonStyles}>{rightFooterLinkOptions.text}</Inline>
							</Button>
						)}
					</Inline>
				)}
				<FireScreenAnalytics attributes={screenAnalyticsAttributes} />
			</Box>
		</ContextualAnalyticsData>
	);
};

const safeStatusName = (statusName: string): string => {
	const status = ['to do', 'in progress', 'done'];
	return status.includes(statusName.toLowerCase())
		? statusName.toLowerCase().replaceAll(' ', '_')
		: 'custom';
};

const bodyContainerStyles = xcss({
	// @ts-expect-error - TS2322 - Type 'number' is not assignable to type 'AutoComplete<"100%" | "size.100" | "size.200" | "size.300" | "size.400" | "size.500" | "size.600" | "size.1000"> | undefined'.
	maxHeight: 310,
	marginTop: 'space.100',
	overflowY: 'auto',
	overflowX: 'hidden',
});

const bodyContainerHeaderHiddenStyles = xcss({
	marginTop: 'space.0',
});

const footerStyles = xcss({
	marginTop: 'space.100',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${gridSize * 50}px`,
});

const footerHeaderHiddenStyles = xcss({
	marginTop: 'space.0',
});

const leftFooterButtonStyles = xcss({
	marginLeft: 'space.negative.075',
});

const leftFooterButtonTextStyles = xcss({
	paddingLeft: 'space.100',
});

const rightFooterButtonStyles = xcss({
	marginRight: 'space.100',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Title = styled.p({
	textTransform: 'uppercase',
	font: token('font.body.small'),
	fontWeight: token('font.weight.bold'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const GroupTitle = styled.p({
	color: token('color.text.subtlest'),
	font: token('font.body.small'),
});

const headingStyles = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${gridSize * 64}px`,
	marginRight: 'space.100',
});

const headingStyleForHeaderWithRightAction = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${gridSize * 64}px`,
});
