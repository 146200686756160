import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	actionsMenuTriggerLabel: {
		id: 'platform-board-kit.card.jira-card-contents.actions-menu-trigger-label',
		defaultMessage: 'issue actions, { summary }',
		description:
			'Text to be used as actions menu trigger button. Is announce by screen reader when button is focused',
	},
	storyPointsUnitsLabel: {
		id: 'platform-board-kit.card.jira-card-contents.story-points-units-label',
		defaultMessage: 'story points',
		description: 'Units to be used as the tooltip for story points estimates',
	},
	storyPointsUnitsPluralisedLabel: {
		id: 'platform-board-kit.card.jira-card-contents.story-points-units-pluralised-label',
		defaultMessage: '{value, plural, one {# story point} other {# story points}}',
		description: 'Units to be used as the tooltip for story points estimates',
	},
	actionsMenuTriggerLabelIssueTermRefresh: {
		id: 'platform-board-kit.card.jira-card-contents.actions-menu-trigger-label-issue-term-refresh',
		defaultMessage: 'work item actions, { summary }',
		description:
			'Text to be used as actions menu trigger button. Is announce by screen reader when button is focused',
	},
});
