import React, { type MutableRefObject, useState, useRef, useLayoutEffect, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { NudgeSpotlight } from '../nudge-spotlight/NudgeSpotlight';
import { type NudgeSpotlightRef } from '../nudge-spotlight/types';
import type { PortalledNudgeProps, Rectangle } from './types';
import { useNudgeController } from '../../controllers/register-nudge-target';

/**
 * Creates a subscription into this component, that will return a boolean stating if
 * the HTML DOM node is associated with a given target ID.
 */
const useHasNudgeTargetNode = (id: string): boolean => {
	const controller = useNudgeController();
	const [hasRefValue, setHasRefValue] = useState(!!controller.nudgeTargetsMap.get(id)?.current);
	useLayoutEffect(() => {
		setHasRefValue(!!controller.nudgeTargetsMap.get(id)?.current);
		const unsubscribe = controller.subscribe(id, () => {
			setHasRefValue(!!controller.nudgeTargetsMap.get(id)?.current);
		});

		return () => {
			unsubscribe();
		};
	}, [controller, id]);
	return hasRefValue;
};

/**
 * When the nudge is shown, get and track the rectangle of the target element.
 */
const useNudgeRectangle = (
	isShown: boolean,
	id: string,
	nudgeContainer: MutableRefObject<HTMLElement | null>,
) => {
	const [innerRectangle, setInnerRectangle] = useState<Rectangle | null>(null);
	const frameId = useRef<number | null>(null);
	const controller = useNudgeController();
	const nudgeTarget = controller.nudgeTargetsMap.get(id)?.current;

	useLayoutEffect(() => {
		if (!isShown || !nudgeTarget || !nudgeContainer.current) {
			// Reset rectangle to eliminate risk of Spotlight rendering at
			// old position when nudge is e.g. hidden then shown in a different place.
			if (innerRectangle !== null) {
				setInnerRectangle(null);
			}
			return undefined;
		}

		// This is one approach to tracking the elements moving. See https://hello.atlassian.net/wiki/spaces/JST/pages/1759637849/Rendering+performance+Async+component+wrappers
		// for other options.
		const loop = () => {
			const targetRectangle = controller.nudgeTargetsMap.get(id)?.current?.getBoundingClientRect();
			if (!targetRectangle) {
				return undefined;
			}
			const newRectangle = {
				top: targetRectangle.top,
				left: targetRectangle.left,
				width: targetRectangle.width,
				height: targetRectangle.height,
			};
			if (
				innerRectangle == null ||
				innerRectangle.top !== newRectangle.top ||
				innerRectangle.left !== newRectangle.left ||
				innerRectangle.width !== newRectangle.width ||
				innerRectangle.height !== newRectangle.height
			) {
				setInnerRectangle(newRectangle);
			} else {
				frameId.current = requestAnimationFrame(loop);
			}
		};
		frameId.current = requestAnimationFrame(loop);

		return () => {
			if (frameId.current != null) {
				cancelAnimationFrame(frameId.current);
			}
		};
	}, [isShown, nudgeContainer, innerRectangle, nudgeTarget, controller.nudgeTargetsMap, id]);
	return innerRectangle;
};

/**
 * Create and append a DOM node to use for the nudge. This is on a separate root to the rest of the app.
 */
const usePortalContainer = () => {
	const nudgeContainer = useRef<HTMLDivElement | null>(null);
	useLayoutEffect(() => {
		if (!nudgeContainer.current) {
			nudgeContainer.current = document.createElement('div');
			document.body.appendChild(nudgeContainer.current);
		}

		return () => {
			if (nudgeContainer.current) {
				document.body.removeChild(nudgeContainer.current);
			}
		};
	}, []);
	return nudgeContainer;
};

/**
 * Renders this nudge, uses the `nudgeTargetId` to get the target DOM node reference and use it for positioning/sizing
 * both the nudge and tooltip. Please use useRegisterNudgeTarget to register the target element.
 */
export const PortalledNudge = (props: PortalledNudgeProps) => {
	const nudgeContainer = usePortalContainer();
	const hasRefValue = useHasNudgeTargetNode(props.nudgeTargetId);
	const innerRectangle = useNudgeRectangle(!props.hidden, props.nudgeTargetId, nudgeContainer);
	const innerRectanglePrevious = useRef(innerRectangle);
	const spotlightRef = useRef<NudgeSpotlightRef>(null);

	// Force update the Popper spotlight when the innerRectangle (nudge position) changes
	useEffect(() => {
		if (
			innerRectangle !== null &&
			innerRectanglePrevious.current !== innerRectangle &&
			spotlightRef.current !== null
		) {
			innerRectanglePrevious.current = innerRectangle;
			if (spotlightRef.current.forceUpdateCardPosition) {
				spotlightRef.current.forceUpdateCardPosition();
			}
		}
	}, [innerRectangle]);

	const hasNudgeTarget = hasRefValue;
	if (!nudgeContainer.current || !hasNudgeTarget || !innerRectangle) {
		return null;
	}

	return createPortal(
		<div
			style={{
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				position: 'fixed',

				top: innerRectangle.top,

				left: innerRectangle.left,
				height: innerRectangle.height,
				width: innerRectangle.width,
				zIndex: props.zIndex,
			}}
		>
			<NudgeSpotlight {...props} ref={spotlightRef}>
				<div
					style={{
						height: innerRectangle.height,
						width: innerRectangle.width,
					}}
				/>
			</NudgeSpotlight>
		</div>,
		nudgeContainer.current,
	);
};
