import once from 'lodash/once';
// @ts-expect-error - TS2614 - Module '"history/createBrowserHistory"' has no exported member 'BrowserHistory'. Did you mean to use 'import BrowserHistory from "history/createBrowserHistory"' instead?
import type { BrowserHistory } from 'history/createBrowserHistory';
import type { MemoryHistory } from 'history/createMemoryHistory';
import type { LocationShape } from '@atlassian/react-resource-router';
import type { ReactRouterCompatibleHistory } from '../../types.tsx';

/*
 * How is this different from src/packages/platform/services/history/src/safe-history/index.js ?
 */
export const createReactRouterCompatibleHistory = (
	history: BrowserHistory | MemoryHistory,
): ReactRouterCompatibleHistory => {
	let cleaned = false;
	let listeners: Array<{
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		unsubscribe: any;
	}> = [];

	// @ts-expect-error - TS2304 - Cannot find name 'HistoryAction'.
	const listen = (callback: (location: Location, action: HistoryAction) => void) => {
		if (cleaned) {
			// Replace with lodash/noop
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			return () => {};
		}

		const unsubscribe = once(history.listen(callback));

		const listener = {
			unsubscribe,
		};

		listeners.push(listener);

		return once(() => {
			listener.unsubscribe();
		});
	};

	const cleanup = () => {
		listeners.forEach(({ unsubscribe }) => {
			unsubscribe();
		});

		cleaned = true;
		listeners = [];
	};

	return {
		get length() {
			return history.length;
		},
		get location() {
			return history.location;
		},
		get action() {
			return history.action;
		},
		get index() {
			return history.index;
		},
		get entries() {
			return history.entries;
		},
		// @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type.
		push: (path: string | Partial<LocationShape>, state) => history.push(path, state),
		// @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type.
		replace: (path: string | Partial<LocationShape>, state) => history.replace(path, state),
		// @ts-expect-error - TS7006 - Parameter 'n' implicitly has an 'any' type.
		go: (n) => history.go(n),
		goBack: () => history.goBack(),
		goForward: () => history.goForward(),
		// @ts-expect-error - TS7019 - Rest parameter 'args' implicitly has an 'any[]' type.
		createHref: (...args) => history.createHref(...args),
		// Memory only
		// @ts-expect-error - TS7006 - Parameter 'n' implicitly has an 'any' type.
		canGo: (n) => {
			if (history.canGo) {
				return history.canGo(n);
			}
			return true;
		},
		listen,
		// @ts-expect-error - TS7006 - Parameter 'blocker' implicitly has an 'any' type.
		block: (blocker) => history.block(blocker),
		destroy: cleanup,
	};
};
