import type { BoardActionExtensionData } from '@atlassian/jira-forge-ui-types/src/common/types/extension-data.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { getBoardActionExtensionData } from '../../../../state/selectors/board/board-selectors.tsx';
import type { State } from '../../../../state/types.tsx';
import { BoardForgeActionMenuItems } from './view.tsx';

type StateProps = {
	extensionData: BoardActionExtensionData;
};

const mapStateToProps = (state: State): StateProps => ({
	extensionData: getBoardActionExtensionData(state),
});

export default connect(mapStateToProps)(BoardForgeActionMenuItems);
