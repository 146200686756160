import { freeze } from 'icepick';
import { createSelector } from 'reselect';
import isNil from 'lodash/isNil';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import { DEFAULT_BASE_ISSUE_TYPE_NAME } from '../../../model/constants.tsx';
import type {
	Issue,
	IssueParent,
	IssueTypeId,
	PartialIssue,
} from '../../../model/issue/issue-types.tsx';
import type { CardTypeKey, ProjectId } from '../../../model/software/software-types.tsx';
import { SWIMLANE_BY_SUBTASK } from '../../../model/swimlane/swimlane-modes.tsx';
import type { State } from '../../reducers/types.tsx';
import { getVisibleCardSelection } from '../card/card-selectors.tsx';
import { getIssueChildren } from '../issue-children/index.tsx';
import { firstParentIssueTypeNameSelector, issueParentsSelector } from '../issue-parent/index.tsx';
import {
	issueChildrenTypeIdsSelector,
	issueTypeIdsSelector,
} from '../issue-type/issue-type-selectors.tsx';
import {
	getIssues,
	getIssueProjects,
	getUi,
	getIsCMPBoard,
	getIsIncrementPlanningBoard,
} from '../software/software-selectors.tsx';
import { getSwimlaneMode } from '../swimlane/swimlane-mode-selectors.tsx';
import { boardIssuesAndChildrenSelector, boardIssuesSelector } from './board-issue-selectors.tsx';

type IssueType = {
	id: IssueTypeId;
	name: string;
	iconUrl: string;
};

export type PartialIssueParent = {
	id: IssueId;
	summary: string;
	key: string;
	issueType: IssueType;
	projectId?: ProjectId;
};

export const isAssignIssueParentModalOpen = (state: State): boolean =>
	Boolean(getUi(state).assignIssueParentModal?.isOpen);

export const getSelectedIssueIds = (state: State): IssueId[] =>
	getUi(state).assignIssueParentModal?.issueIds ?? [];

export const getSelectedIssues = createSelector(
	[boardIssuesSelector, boardIssuesAndChildrenSelector, getSelectedIssueIds],
	(boardIssues, boardIssuesAndChildren, selectedIssueIds): PartialIssue[] => {
		if (selectedIssueIds) {
			return selectedIssueIds
				.filter((id) => !isNil(boardIssuesAndChildren[id]))
				.map((id) => ({
					id: boardIssuesAndChildren[id].id,
					key: boardIssuesAndChildren[id].key,
					parentId: boardIssuesAndChildren[id].parentId,
					projectId: boardIssuesAndChildren[id].projectId,
				}));
		}
		return freeze([]);
	},
);

export const areIssueTypesInOneLevel = (
	levelIssueTypeIds: CardTypeKey[],
	typeIds: IssueTypeId[],
): boolean => typeIds.every((typeId) => levelIssueTypeIds.includes(String(typeId)));

export const isAssignIssueParentActionVisible = createSelector(
	[
		getVisibleCardSelection,
		boardIssuesSelector,
		issueTypeIdsSelector,
		issueChildrenTypeIdsSelector,
		issueParentsSelector,
		getIsCMPBoard,
		getIsIncrementPlanningBoard,
	],
	(
		visibleSelectedCards,
		boardIssues,
		baseIssueTypes,
		childrenIssueTypes,
		issueParents,
		isCMPBoard,
		isIncrementPlanningBoard,
	) =>
		(issue: Issue, isMultiSelect: boolean) => {
			const issueParentsPresent = issueParents.length > 0;
			const selectedCards = visibleSelectedCards;

			if (isCMPBoard && isMultiSelect) {
				// Changing parent for cards in bulk is not supported yet in CMP projects
				return false;
			}

			if (
				(isCMPBoard || isIncrementPlanningBoard) &&
				issueParents.find((issueParent) => issueParent.id === issue.id)
			) {
				// This issue is already an issue parent (Epic). It doesn't support Add Parent feature
				return false;
			}

			if (
				isIncrementPlanningBoard &&
				isMultiSelect &&
				selectedCards.some((id) => issueParents.find((issueParent) => issueParent.id === id))
			) {
				// The increment planning board doesn't support Add Parent if any issue parent is included in the selection
				return false;
			}

			if (!isMultiSelect) {
				return baseIssueTypes.includes(String(issue.typeId)) ? issueParentsPresent : true;
			}

			const typeIds = selectedCards.map((issueId) => boardIssues[String(issueId)].typeId);

			return areIssueTypesInOneLevel(baseIssueTypes, typeIds)
				? issueParentsPresent
				: areIssueTypesInOneLevel(childrenIssueTypes, typeIds);
		},
);

export const areSelectedIssuesSubtasksSelector = createSelector(
	[getSelectedIssueIds, getIssueChildren],
	(selectedIssueIds, issueChildren) =>
		selectedIssueIds &&
		selectedIssueIds.length > 0 &&
		selectedIssueIds.every((issueId) => issueChildren[issueId]),
);

export const issueHierarchyLevelSelector = createSelector(
	[getSwimlaneMode, areSelectedIssuesSubtasksSelector, getIsCMPBoard],
	(swimlaneMode, areSelectedIssuesSubtasks, isCMPBoard) => {
		if (isCMPBoard && areSelectedIssuesSubtasks) {
			return -1;
		}

		// when you switch swimlanes and nothing is selected we still want the issueHierarchyLevel for ICC
		if (areSelectedIssuesSubtasks === undefined) {
			return swimlaneMode === SWIMLANE_BY_SUBTASK.id ? -1 : 0;
		}
		return swimlaneMode === SWIMLANE_BY_SUBTASK.id && areSelectedIssuesSubtasks ? -1 : 0;
	},
);

export const parentIssueLevelNameSelector = createSelector(
	[
		getSwimlaneMode,
		areSelectedIssuesSubtasksSelector,
		firstParentIssueTypeNameSelector,
		getIsCMPBoard,
	],
	(swimlaneMode, areSelectedIssuesSubtasks, firstParentIssueTypeName, isCMPBoard) => {
		if (areSelectedIssuesSubtasks && (swimlaneMode === SWIMLANE_BY_SUBTASK.id || isCMPBoard)) {
			return DEFAULT_BASE_ISSUE_TYPE_NAME;
		}

		return firstParentIssueTypeName;
	},
);

const getNormalizedIssueType = (
	id: IssueTypeId,
	name?: string | null,
	iconUrl?: string | null,
): IssueType => ({
	id,
	name: name || '',
	iconUrl: iconUrl || '',
});

export const getIssueParentsForReparentModal = createSelector(
	[
		getSwimlaneMode,
		issueParentsSelector,
		getIssues,
		getIssueProjects,
		getSelectedIssues,
		areSelectedIssuesSubtasksSelector,
		getIsCMPBoard,
		getIsIncrementPlanningBoard,
	],
	(
		swimlaneMode,
		issueParents,
		issues,
		projects,
		selectedIssues,
		areSelectedIssuesSubtasks,
		isCMPBoard,
		isIncrementPlanningBoard,
	) => {
		if (areSelectedIssuesSubtasks && (swimlaneMode === SWIMLANE_BY_SUBTASK.id || isCMPBoard)) {
			// return base issues excluding epics as available parents for subtasks
			const issueParentKeys = issueParents.map((parent) => parent.id.toString());
			return Object.keys(issues)
				.filter((key) => !issueParentKeys.includes(key))
				.map((key) => issues[key])
				.map(({ id, summary, key, typeId, typeName, typeUrl }: Issue) => ({
					id,
					summary,
					key,
					issueType: getNormalizedIssueType(typeId, typeName, typeUrl),
				}));
		}

		let filteredIssueParents = issueParents;

		if (isIncrementPlanningBoard) {
			const selectedProjects = selectedIssues.map((issue) =>
				issue.projectId ? projects[issue.projectId] : null,
			);
			const selectedTmpProjects = selectedProjects.filter((project) => project && !project.isCMP);
			const hasCmpProjectSelected = selectedProjects.some((project) => project && project.isCMP);

			if (
				(selectedTmpProjects.length > 0 && hasCmpProjectSelected) ||
				selectedTmpProjects.length > 1
			) {
				// Return empty if there are mixed TMP and CMP issues selected or mixed TMP projects issues selected
				filteredIssueParents = [];
			} else if (selectedTmpProjects.length === 1) {
				// If there's only 1 TMP project on selected issues, filter the issue parents to be in the same project
				filteredIssueParents = issueParents.filter(
					(parent) => parent.projectId === selectedTmpProjects[0]?.id,
				);
			} else {
				// Filter issue parents to be in CMP project
				filteredIssueParents = issueParents.filter((parent) => {
					const parentProject = parent.projectId ? projects[parent.projectId] : null;
					return parentProject && parentProject.isCMP;
				});
			}
		}

		return filteredIssueParents.map(({ id, summary, key, issueType, projectId }: IssueParent) => ({
			id,
			summary,
			key,
			projectId,
			issueType: {
				id: parseInt(issueType.id, 10),
				name: issueType.name,
				iconUrl: issueType.iconUrl,
			},
		}));
	},
);
