import type { Observable as ObservableType } from 'rxjs/Observable';
import { operation$, type Variables, type MetricOptions } from '../operation/index.tsx';

export const mutation$ = <Data,>(
	baseUrl: string,
	operationName: string,
	query: string,
	variables?: Variables,
	analyticOptions?: MetricOptions,
): ObservableType<Data> =>
	operation$<Data>('mutation', baseUrl, operationName, query, variables, analyticOptions);
