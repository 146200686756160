import type { ComponentId } from '@atlassian/jira-shared-types/src/general.tsx';
import { UNASSIGNED_COMPONENT_ID } from '../../common/constants.tsx';
import type { FilterableIssue, MatcherCreator } from '../types.tsx';

export const componentMatcherCreator: MatcherCreator<ComponentId[], FilterableIssue> = (
	componentFilter,
) => {
	const componentSet = new Set(componentFilter);

	return ({ componentIds }) =>
		componentIds !== undefined &&
		(componentIds.some((componentId) => componentSet.has(componentId)) ||
			(componentIds.length === 0 && componentSet.has(UNASSIGNED_COMPONENT_ID)));
};
