import { useCallback } from 'react';
import { useCloneService } from '@atlassian/jira-clone-issue/src/services/clone/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useProjectKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useProjectType } from '@atlassian/jira-project-context-service/src/main.tsx';
import {
	type MenuNode,
	MenuNodeType,
} from '@atlassian/jira-software-context-menu/src/common/types.tsx';
import { CAN_CREATE_CARDS } from '../../../../../../../../model/permission/permission-types.tsx';
import { useBoardSelector } from '../../../../../../../../state/index.tsx';
import { getPermissionsSelector } from '../../../../../../../../state/selectors/board/board-permissions-selectors.tsx';
import { getIssueById } from '../../../../../../../../state/selectors/issue/issue-selectors.tsx';
import { CLONE_ISSUE_MENU_ID } from '../../constants.tsx';
import messages from '../../messages.tsx';

import type { ContentProps, Props } from './types.tsx';

export const useCloneIssueContent = ({
	isCloneEnabled,
	onCloneIssue,
}: ContentProps): MenuNode[] => {
	const { formatMessage } = useIntl();

	if (!isCloneEnabled) return [];

	return [
		{
			type: MenuNodeType.Item,
			label: formatMessage(messages.clone),
			id: CLONE_ISSUE_MENU_ID,
			onClick: onCloneIssue,
		},
	];
};

export const useCloneIssue = ({ issueKey, issueId }: Props) => {
	const canClone = useBoardSelector(getPermissionsSelector)[CAN_CREATE_CARDS];
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const projectKey = useProjectKey();
	const projectType = useProjectType(projectKey);
	const [, makeCloneRequest] = useCloneService(issueKey, projectType || null);
	const issue = useBoardSelector((state) => getIssueById(state, issueId));

	const onCloneIssue = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'cloneIssue');
		makeCloneRequest({
			summary: `CLONE - ${issue.summary}`,
		});
	}, [issue.summary, makeCloneRequest, createAnalyticsEvent]);

	return useCloneIssueContent({
		isCloneEnabled: canClone,
		onCloneIssue,
	});
};
