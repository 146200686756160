import ui_DesignModalQuery from '@atlassian/jira-relay/src/__generated__/ui_DesignModalQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const designModalEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-design-modal" */ './src'),
	),
	getPreloadProps: ({
		siteAri,
		issueAri,
		cloudId,
		designAri,
	}: {
		siteAri: string;
		issueAri: string;
		designAri?: string;
		cloudId: string;
	}) => ({
		queries: {
			designModal: {
				options: {
					fetchPolicy: 'store-and-network' as const,
				},
				parameters: ui_DesignModalQuery,
				variables: {
					siteAri,
					issueAri,
					cloudId,
					designAri,
				},
			},
		},
	}),
});
