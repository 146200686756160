import React, { useMemo, type KeyboardEvent, useRef } from 'react';
import noop from 'lodash/noop';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import {
	PopupSelect,
	CheckboxOption,
	components as componentsNext,
	type MenuListComponentProps,
	type PopupSelectProps,
	type OptionType,
	type OptionsType,
} from '@atlaskit/select';
import { N40 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { visuallyHiddenStyles } from '@atlassian/jira-accessibility/src/common/ui/screenreader-text/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { OPEN_CHANGE_PAYLOAD, OPEN_CHANGE_SUBJECT_ID } from './constants.tsx';
import ListFilterFooter from './footer/index.tsx';
import messages from './messages.tsx';
import ListFilterOption, { DefaultOptionLabel } from './option/index.tsx';
import ListFilterTrigger from './trigger/index.tsx';
import type { ListFilterProps, Option } from './types.tsx';
import {
	getOptionsFromProps,
	getSearchableProps,
	getSelectedOptionsFromProps,
	useGetOptionsWithSelectedOptionGroup,
} from './utils.tsx';

const MenuListWrapper = ({ children, ...rest }: MenuListComponentProps<OptionType, true>) => (
	<div data-testid="filters.common.ui.list.menu-list-wrapper">
		<componentsNext.MenuList {...rest}>{children}</componentsNext.MenuList>
	</div>
);

export const ListFilter = ({
	isOpen: defaultIsOpen,
	isTriggerDisabled,
	isNested,
	isLoading,
	isAsyncSearch,
	showMenuIfValuesAreEmpty,
	label,
	toggleLabel,
	buttonType,
	values,
	selectedValues: selectedValueIds,
	onChange,
	onInputChange,
	onOpenChange,
	noOptionsMessage,
	menuGroupTitle,
	inputValue,
	maxSelectionAllowed,
	maxSelectionReachedTooltip,
	onMouseEnterToTriggerButton,
	CustomOptionLabel,
	placeholder,
	shouldReturnFocus,
	...footerProps
}: ListFilterProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();
	const firstRender = useRef(true);

	const options = useMemo(
		() => getOptionsFromProps({ values, menuGroupTitle }),
		[values, menuGroupTitle],
	);

	const { optionsWithSelectedSection, updateSelectedOptionGroup, shouldTrackExperimentEvents } =
		useGetOptionsWithSelectedOptionGroup();

	if (
		firstRender.current === true &&
		defaultIsOpen === true &&
		fg('jira-make-it-easier-to-deselect-filter-items-gate')
	) {
		updateSelectedOptionGroup({ isOpen: defaultIsOpen, options, selectedValueIds });
	}
	if (firstRender.current === true) {
		firstRender.current = false;
	}

	const { selectedIdLookup, selectedOptions } = useMemo(
		() => getSelectedOptionsFromProps({ values, selectedValueIds }),
		[values, selectedValueIds],
	);

	const previousSelectedCount = useRef(selectedValueIds.length);

	/* Cannot return null here for async search as this would cause the
	 * filter to disappear completely when fetch returns no results.
	 */
	if (!showMenuIfValuesAreEmpty && !isAsyncSearch && values.length === 0) {
		return null;
	}

	// ================= //
	// === Callbacks === //
	// ================= //

	const onChangeHandler = (selected: OptionsType<OptionType>) => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		onChange(selected as Option[]);

		// Temporary analytics for the experiment
		if (
			shouldTrackExperimentEvents.current === true &&
			fg('jira-make-it-easier-to-deselect-filter-items-gate')
		) {
			if (selected.length > previousSelectedCount.current) {
				fireUIAnalytics(
					createAnalyticsEvent({
						actionSubject: 'filterItem',
						action: 'selected',
						actionSubjectId: 'listFilter',
					}),
				);
			} else if (selected.length < previousSelectedCount.current) {
				fireUIAnalytics(
					createAnalyticsEvent({
						actionSubject: 'filterItem',
						action: 'deselected',
						actionSubjectId: 'listFilter',
					}),
				);
			}
			previousSelectedCount.current = selected.length;
		}
	};

	const onOpenChangeHandler = (isOpen: boolean) => {
		const analyticsEvent = createAnalyticsEvent(OPEN_CHANGE_PAYLOAD);
		fireUIAnalytics(analyticsEvent, OPEN_CHANGE_SUBJECT_ID);

		onOpenChange(isOpen);

		updateSelectedOptionGroup({ isOpen, options, selectedValueIds });
	};

	const isOptionDisabled = (option: OptionType) =>
		selectedOptions.length >= maxSelectionAllowed && !selectedIdLookup.has(option.value.toString());

	// ============== //
	// === RENDER === //
	// ============== //

	// Select expects a function that returns a string, rather than a string primitive
	const getNoOptionsMessage =
		noOptionsMessage === undefined ? noOptionsMessage : () => noOptionsMessage;

	const renderTrigger: PopupSelectProps['target'] = (popupProps) =>
		fg('allow_jplat_filter_list_to_be_disabled') ? (
			<ListFilterTrigger
				{...popupProps}
				isDisabled={isTriggerDisabled}
				shouldReturnFocus={shouldReturnFocus}
				buttonType={buttonType}
				label={label}
				selectedCount={selectedOptions.length}
				isNested={isNested}
				onMouseEnter={onMouseEnterToTriggerButton}
			/>
		) : (
			<ListFilterTrigger
				{...popupProps}
				shouldReturnFocus={shouldReturnFocus}
				buttonType={buttonType}
				label={label}
				selectedCount={selectedOptions.length}
				isNested={isNested}
				onMouseEnter={onMouseEnterToTriggerButton}
			/>
		);

	const renderFooter = (): PopupSelectProps['footer'] =>
		(footerProps.showLink || footerProps.showPanelToggle) && (
			<ListFilterFooter label={label} toggleLabel={toggleLabel} {...footerProps} />
		);

	const renderOption = (option: OptionType) => {
		const { label: optionLabel, iconUrl, subLabel, value } = option;
		return (
			<ListFilterOption
				label={optionLabel}
				subLabel={subLabel}
				iconUrl={iconUrl}
				value={String(value)}
				isDisabled={isOptionDisabled(option)}
				maxSelectionReachedTooltip={maxSelectionReachedTooltip}
				CustomOptionLabel={CustomOptionLabel ?? DefaultOptionLabel}
			/>
		);
	};

	const onKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Escape') {
			event.stopPropagation();
		}
	};

	return (
		<PopupSelect
			aria-label={`Select ${label} filters`}
			// testId="filters.common.ui.list.menu-list-wrapper"
			isMulti
			isLoading={isLoading}
			backspaceRemovesValue
			hideSelectedOptions={false}
			closeMenuOnSelect={false}
			defaultIsOpen={defaultIsOpen}
			options={optionsWithSelectedSection || options}
			value={selectedOptions}
			inputValue={inputValue}
			noOptionsMessage={getNoOptionsMessage}
			placeholder={placeholder ?? formatMessage(messages.placeholder)}
			target={renderTrigger}
			footer={renderFooter()}
			formatOptionLabel={renderOption}
			components={{
				Option: CheckboxOption,
				DropdownIndicator: null,
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				MenuList: MenuListWrapper as React.ComponentType<MenuListComponentProps<OptionType, true>>,
			}}
			popperProps={{
				placement: isNested ? 'right-start' : 'bottom-start',
				// to render popup next to the trigger element to achieve DOM order
				strategy: 'fixed',
			}}
			onChange={onChangeHandler}
			onInputChange={onInputChange}
			isOptionDisabled={isOptionDisabled}
			onMenuOpen={() => onOpenChangeHandler(true)}
			onMenuClose={() => onOpenChangeHandler(false)}
			onKeyDown={onKeyDown}
			{...getSearchableProps({ isAsyncSearch, optionsCount: values.length, isNested })}
			// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides
			styles={
				optionsWithSelectedSection
					? {
							group: (base, props) => {
								const borderStyles =
									props.data.label === formatMessage(messages.selectedItemsGroupLabel)
										? { borderBottom: `1px solid ${token('color.border', N40)}` }
										: {};
								return { ...base, ...borderStyles, padding: 0 };
							},
							groupHeading: (base, props) => {
								if (props.data.label === formatMessage(messages.selectedItemsGroupLabel)) {
									// Make the text screen reader only
									return visuallyHiddenStyles;
								}
								return base;
							},
						}
					: undefined
			}
		/>
	);
};

ListFilter.defaultProps = {
	isOpen: false,
	isNested: false,
	isTriggerDisabled: false,
	isLoading: false,
	isAsyncSearch: false,
	isPanelToggleChecked: false,
	showLink: false,
	showPanelToggle: false,
	showMenuIfValuesAreEmpty: false,
	useHrefForUrl: false,
	values: [],
	selectedValues: [],
	menuGroupTitle: undefined,
	noOptionsMessage: undefined,
	maxSelectionAllowed: Number.MAX_SAFE_INTEGER,
	url: undefined,
	linkTitle: undefined,
	onChange: noop,
	onOpenChange: noop,
	onInputChange: noop,
	onTogglePanel: noop,
	onLinkItemClick: noop,
	onMouseEnterToTriggerButton: noop,
	toggleRef: noop,
};

export default ListFilter;
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { CustomOptionLabel, CustomOptionLabelProps } from './types';
