import { styled } from '@compiled/react';
import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/types';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ColumnDropIndicator = styled.div<{ edge: Edge }>({
	position: 'absolute',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	left: (props) => (props.edge === 'left' ? token('space.0', '0px') : 'auto'),

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	right: (props) => (props.edge === 'right' ? token('space.negative.025', '2px') : 'auto'),
	top: token('space.100', '8px'),
	width: '2px',
	zIndex: 5,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.icon.brand', colors.B300),
	pointerEvents: 'none',
	transform: 'translateX(-1px)',
	height: 'calc(100% - 7px)',

	'&::before': {
		content: "''",
		position: 'absolute',

		top: '-7px',

		left: '-3px',
		height: '4px',
		width: '4px',
		borderRadius: '50%',
		borderWidth: '2px',
		borderStyle: 'solid',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		borderColor: `${token('color.border.brand', colors.B300)}`,
	},
});
