import type { Observable as ObservableType } from 'rxjs/Observable';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import type { Option } from './types.tsx';

export const fetchOptionsForCustomFields = ({
	projectId,
	issueTypeId,
	customFieldId,
}: {
	projectId: string;
	issueTypeId: string;
	customFieldId: string;
}): ObservableType<Option[]> =>
	fetchJson$<Option[]>('/rest/jpo/1.0/customfield/getSettableOptions', {
		method: 'POST',
		body: JSON.stringify({
			customFieldId,
			issueTypeId,
			projectId,
		}),
	});
