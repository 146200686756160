import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { getHttpStatusCodeGroup } from '@atlassian/jira-fetch/src/utils/http-status-code.tsx';
import {
	isHttpClientErrorResponse,
	isClientFetchError,
	isHttpTooManyRequestsResponse,
} from '@atlassian/jira-fetch/src/utils/is-error.tsx';

export const FAIL_TO_TRANSITION_MESSAGE = 'Either transition or rank operation has failed';

const isHttpSuccessfulButFailedToMoveCard = (error: FetchError): boolean =>
	error.statusCode === 200 && error.message === FAIL_TO_TRANSITION_MESSAGE;

export const shouldFireFailureSLOMetric = (error: FetchError): boolean =>
	!isHttpClientErrorResponse(error) &&
	!isClientFetchError(error) &&
	!isHttpSuccessfulButFailedToMoveCard(error) &&
	!isHttpTooManyRequestsResponse(error);

export const getStatusCodeGroup = (error: FetchError): string =>
	error.statusCode === 429 ? '429' : getHttpStatusCodeGroup(error.statusCode);
