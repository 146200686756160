import { type Observable as ObservableType, Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import { fg } from '@atlassian/jira-feature-gating';
import {
	getBoardScopeGraphqlQuery,
	softwareBoardScopeCriticalDataOperationName,
} from '@atlassian/jira-software-board-fetch-scope-critical-query/src/index.tsx';
import { query$ } from '@atlassian/jira-software-swag/src/services/query/index.tsx';
import { boardScopeDataCriticalAnalyticsOptions } from './constants.tsx';
import type { BoardScopeGQL } from './types.tsx';

type Response = {
	boardScope: BoardScopeGQL;
};

export const parseResponse = (result: Response): ObservableType<BoardScopeGQL> =>
	Observable.of(result.boardScope);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (contextPath: string, boardId: string): ObservableType<BoardScopeGQL> =>
	query$<Response>(
		contextPath,
		softwareBoardScopeCriticalDataOperationName,
		getBoardScopeGraphqlQuery(),
		{
			boardId,
			useBackendIccConfig: fg('use_backend_tmp_icc_config_'),
			showIssueTransition: fg('issue_transition_screen_in_jsw_tmp_board'),
		},
		boardScopeDataCriticalAnalyticsOptions,
	).flatMap(parseResponse);
