import type { ComponentType } from 'react';
import type { AssigneeFieldProps } from '@atlassian/jira-issue-field-assignee/src/ui/popover/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const AsyncLazyIssueAssigneeFieldWithInviteAssign = lazy<ComponentType<AssigneeFieldProps>>(
	() =>
		import(
			/* webpackChunkName: "async-assignee-inline-edit-view-with-invite-assign" */ './index'
		).then(({ AssigneeFieldInviteAndAssignExperiment }) => AssigneeFieldInviteAndAssignExperiment),
);
