/**
 * @generated SignedSource<<27f6e515959b3fa71c98f3b9ea403490>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type customFilter_softwareFilters_CustomFilterRelay$data = {
  readonly boardScope: {
    readonly customFilters: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    } | null | undefined>;
  };
  readonly " $fragmentType": "customFilter_softwareFilters_CustomFilterRelay";
};
export type customFilter_softwareFilters_CustomFilterRelay$key = {
  readonly " $data"?: customFilter_softwareFilters_CustomFilterRelay$data;
  readonly " $fragmentSpreads": FragmentRefs<"customFilter_softwareFilters_CustomFilterRelay">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "boardId"
    }
  ],
  "kind": "Fragment",
  "name": "customFilter_softwareFilters_CustomFilterRelay",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "args": [
          {
            "kind": "Variable",
            "name": "boardId",
            "variableName": "boardId"
          }
        ],
        "concreteType": "BoardScope",
        "kind": "LinkedField",
        "name": "boardScope",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "concreteType": "CustomFilter",
              "kind": "LinkedField",
              "name": "customFilters",
              "plural": true,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": {
                    "kind": "ScalarField",
                    "name": "id"
                  },
                  "action": "THROW",
                  "path": "boardScope.customFilters.id"
                },
                {
                  "kind": "RequiredField",
                  "field": {
                    "kind": "ScalarField",
                    "name": "name"
                  },
                  "action": "THROW",
                  "path": "boardScope.customFilters.name"
                }
              ]
            },
            "action": "THROW",
            "path": "boardScope.customFilters"
          }
        ]
      },
      "action": "THROW",
      "path": "boardScope"
    }
  ],
  "type": "Query"
};

(node as any).hash = "ae7389daa2b7626fc0f4f542c785e41f";

export default node;
