import type { EstimationStatisticFieldId } from '../../../../model/work/work-types.tsx';
import { WORK_DATA_CRITICAL_SET, WORK_DATA_SET } from '../../../actions/work/index.tsx';
import type { Action } from '../../../types.tsx';

export const estimationStatisticReducer = (
	state: EstimationStatisticFieldId = '',
	action: Action,
): EstimationStatisticFieldId => {
	if (action.type === WORK_DATA_SET || action.type === WORK_DATA_CRITICAL_SET) {
		return action.payload.estimationStatistic;
	}
	return state;
};
