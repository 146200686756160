import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fetchExtensionData } from '../../rest/load-extension-data/index.tsx';
import { transformOperation } from '../../services/extension/operation-transformer.tsx';
import { extensionFetchSuccess } from '../../state/actions/extension/index.tsx';
import {
	SOFTWARE_APP_INITIAL_STATE_LOADED,
	SOFTWARE_APP_LOADED,
} from '../../state/actions/software/index.tsx';
import { WORK_REFRESH_DATA } from '../../state/actions/work/index.tsx';
import {
	contextPathSelector,
	getIsCMPBoard,
	rapidViewIdSelector,
} from '../../state/selectors/software/software-selectors.tsx';
import type { ActionsObservable, MiddlewareAPI, Action } from '../../state/types.tsx';

export default function extensionLoadEpic(
	action$: ActionsObservable,
	store: MiddlewareAPI,
): Observable<Action> {
	return action$
		.ofType(WORK_REFRESH_DATA, SOFTWARE_APP_INITIAL_STATE_LOADED, SOFTWARE_APP_LOADED)
		.switchMap((): Observable<Action> => {
			const state = store.getState();
			const isCMPBoard = getIsCMPBoard(state);
			const baseUrl = contextPathSelector(state);
			const boardId = rapidViewIdSelector(state);

			const handleError = (location: string, error: Error): Observable<Action> => {
				log.safeErrorWithoutCustomerData(location, error.message, error);
				return Observable.empty<Action>();
			};

			if (isCMPBoard) {
				return Observable.empty<Action>();
			}

			return fetchExtensionData(baseUrl, boardId)
				.map(transformOperation)
				.flatMap((extension) => Observable.of(extensionFetchSuccess(extension)))
				.catch((error) => handleError('software.extension.operations', error));
		});
}
