import { createStore, createHook } from '@atlassian/react-sweet-state';
import { actions } from './actions/index.tsx';
import type { State } from './types.tsx';

type Actions = typeof actions;

const initialState: State = {
	isOpen: 'closed',
	touchpoint: undefined,
};

const store = createStore<State, Actions>({
	name: 'jsw-enforce-storage-limit-modal',
	initialState,
	actions,
});

export const useEnforceStorageModal = createHook(store);
