import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { renameBoardRequest } from '../../../state/actions/board/rename/index.tsx';
import {
	boardTitleSelector,
	isEditableSelector,
} from '../../../state/selectors/board/board-title-selectors.tsx';
import Title from './view.tsx';

type StateProps = {
	canEdit: boolean;
	boardTitle: string;
};

type DispatchProps = {
	onSubmit: (title: string) => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: any): StateProps => ({
	canEdit: isEditableSelector(state),
	boardTitle: boardTitleSelector(state),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapDispatchToProps = (dispatch: any): DispatchProps => ({
	onSubmit: (title: string) => {
		dispatch(renameBoardRequest(title));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(Title);
