/**
 * @generated SignedSource<<90852bbbadfa8cb54369933bcd07910f>>
 * @relayHash 6d328d7c5990001d94828d4dceab9d71
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID cc8c5a4387bcd06b0b76ef6adf3e7146a5adb62c29ed11066b61634575d0ebe7

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type WorkSuggestionsContextAri = {
  projectAri: string;
  sprintAri?: string | null | undefined;
};
export type main_boardWorkSuggestions_Query$variables = {
  cloudId: string;
  contextAri: WorkSuggestionsContextAri;
  count?: number | null | undefined;
  cursor?: string | null | undefined;
  isPrRecentCommentEnabled: boolean;
  projectAri: string;
};
export type main_boardWorkSuggestions_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"main_insightsNextBestTask_TaskPanelController_dataGql">;
};
export type main_boardWorkSuggestions_Query = {
  response: main_boardWorkSuggestions_Query$data;
  variables: main_boardWorkSuggestions_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contextAri"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isPrRecentCommentEnabled"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectAri"
},
v6 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v7 = {
  "kind": "Variable",
  "name": "contextAri",
  "variableName": "contextAri"
},
v8 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v7/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
],
v9 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v10 = {
  "kind": "ScalarField",
  "name": "id"
},
v11 = {
  "concreteType": "WorkSuggestionsOrderScore",
  "kind": "LinkedField",
  "name": "orderScore",
  "plural": false,
  "selections": [
    {
      "concreteType": "WorkSuggestionsOrderScores",
      "kind": "LinkedField",
      "name": "byTaskType",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "major"
        },
        {
          "kind": "ScalarField",
          "name": "minor"
        }
      ]
    }
  ]
},
v12 = {
  "kind": "ScalarField",
  "name": "title"
},
v13 = {
  "kind": "ScalarField",
  "name": "issueKey"
},
v14 = {
  "kind": "ScalarField",
  "name": "issueId"
},
v15 = {
  "kind": "ScalarField",
  "name": "lastUpdated"
},
v16 = {
  "kind": "ScalarField",
  "name": "url"
},
v17 = {
  "kind": "ScalarField",
  "name": "approvalsCount"
},
v18 = {
  "kind": "ScalarField",
  "name": "commentCount"
},
v19 = {
  "kind": "ScalarField",
  "name": "repositoryName"
},
v20 = {
  "kind": "ScalarField",
  "name": "providerName"
},
v21 = [
  (v9/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/),
  (v16/*: any*/),
  (v17/*: any*/),
  (v18/*: any*/),
  (v19/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "name": "main_boardWorkSuggestions_Query",
    "selections": [
      {
        "args": [
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          },
          {
            "kind": "Variable",
            "name": "isPrRecentCommentEnabled",
            "variableName": "isPrRecentCommentEnabled"
          },
          {
            "kind": "Variable",
            "name": "projectAri",
            "variableName": "projectAri"
          }
        ],
        "kind": "FragmentSpread",
        "name": "main_insightsNextBestTask_TaskPanelController_dataGql"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "main_boardWorkSuggestions_Query",
    "selections": [
      {
        "concreteType": "WorkSuggestions",
        "kind": "LinkedField",
        "name": "workSuggestions",
        "plural": false,
        "selections": [
          {
            "args": (v8/*: any*/),
            "concreteType": "WorkSuggestionsConnection",
            "kind": "LinkedField",
            "name": "workSuggestionsByContextAri",
            "plural": false,
            "selections": [
              {
                "concreteType": "WorkSuggestionsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      {
                        "kind": "TypeDiscriminator",
                        "abstractKey": "__isWorkSuggestionsCommon"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "numberOfFailedBuilds"
                          },
                          (v15/*: any*/)
                        ],
                        "type": "WorkSuggestionsBuildTask"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "numberOfFailedDeployments"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "environmentType"
                          },
                          (v15/*: any*/)
                        ],
                        "type": "WorkSuggestionsDeploymentTask"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v12/*: any*/),
                          (v16/*: any*/),
                          (v17/*: any*/),
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v20/*: any*/)
                        ],
                        "type": "WorkSuggestionsPullRequestReviewTask"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v12/*: any*/),
                          (v16/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "needsWorkCount"
                          },
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v20/*: any*/)
                        ],
                        "type": "WorkSuggestionsPullRequestNeedsWorkTask"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v12/*: any*/),
                          (v14/*: any*/),
                          (v13/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "securityContainerName"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "status"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "introducedDate"
                          }
                        ],
                        "type": "WorkSuggestionsCriticalVulnerabilityTask"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v12/*: any*/),
                          (v13/*: any*/),
                          {
                            "concreteType": "WorkSuggestionsBlockedIssue",
                            "kind": "LinkedField",
                            "name": "blockedIssues",
                            "plural": true,
                            "selections": [
                              (v13/*: any*/),
                              (v12/*: any*/)
                            ]
                          },
                          (v14/*: any*/)
                        ],
                        "type": "WorkSuggestionsBlockingIssueTask"
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "name": "cursor"
                  }
                ]
              },
              {
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "endCursor"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "hasNextPage"
                  }
                ]
              }
            ]
          },
          {
            "args": (v8/*: any*/),
            "filters": [
              "contextAri"
            ],
            "handle": "connection",
            "key": "panel_workSuggestionsByContextAri",
            "kind": "LinkedHandle",
            "name": "workSuggestionsByContextAri"
          },
          {
            "args": [
              (v6/*: any*/),
              {
                "items": [
                  {
                    "kind": "Variable",
                    "name": "projectAris.0",
                    "variableName": "projectAri"
                  }
                ],
                "kind": "ListValue",
                "name": "projectAris"
              },
              {
                "kind": "Literal",
                "name": "sprintAutoDiscoveryLimit",
                "value": 0
              }
            ],
            "concreteType": "WorkSuggestionsByProjectsResponse",
            "kind": "LinkedField",
            "name": "suggestionsByProjects",
            "plural": false,
            "selections": [
              {
                "concreteType": "WorkSuggestionsPullRequestDraftTask",
                "kind": "LinkedField",
                "name": "draftPRSuggestions",
                "plural": true,
                "selections": (v21/*: any*/)
              },
              {
                "concreteType": "WorkSuggestionsPullRequestInactiveTask",
                "kind": "LinkedField",
                "name": "inactivePRSuggestions",
                "plural": true,
                "selections": (v21/*: any*/)
              },
              {
                "concreteType": "WorkSuggestionsPullRequestSuggestionsResponse",
                "kind": "LinkedField",
                "name": "recentPullRequests",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "WorkSuggestionsPRMergeableTask",
                    "kind": "LinkedField",
                    "name": "mergeableSuggestions",
                    "plural": true,
                    "selections": (v21/*: any*/)
                  },
                  {
                    "condition": "isPrRecentCommentEnabled",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "concreteType": "WorkSuggestionsPRCommentsTask",
                        "kind": "LinkedField",
                        "name": "newCommentsSuggestions",
                        "plural": true,
                        "selections": (v21/*: any*/)
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "cc8c5a4387bcd06b0b76ef6adf3e7146a5adb62c29ed11066b61634575d0ebe7",
    "metadata": {},
    "name": "main_boardWorkSuggestions_Query",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "68b2757e594f76bde3b7cd56fc573b1f";

export default node;
