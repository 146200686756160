import { useEffect } from 'react';
import {
	isIssueEntryGroup,
	type IssueEntry,
} from '@atlassian/jira-software-board-common/src/index.tsx';
import type { Result } from '@atlassian/jira-software-fast-virtual/src/services/use-virtual/index.tsx';
import { cardAutoScroll } from '../../../../../../state/actions/card/card-auto-scroll/index.tsx';
import { useBoardSelector, useBoardDispatch } from '../../../../../../state/index.tsx';
import { getCardAutoScroll } from '../../../../../../state/selectors/card/card-selectors.tsx';

export const useAutoScrollCard = (issueEntries: IssueEntry[], scrollTo: Result['scrollTo']) => {
	const autoScroll = useBoardSelector(getCardAutoScroll);
	const dispatch = useBoardDispatch();
	useEffect(() => {
		const autoScrollIssueId = autoScroll?.issueId;
		if (!autoScrollIssueId) {
			return;
		}

		// NOTE: Auto scrolling to a subtask may not work as expected
		const issueIndex = issueEntries.findIndex((issueEntry) =>
			isIssueEntryGroup(issueEntry)
				? issueEntry.issueIds.includes(autoScrollIssueId)
				: issueEntry.issueId === autoScrollIssueId,
		);

		if (issueIndex === -1) return;

		return scrollTo(issueIndex, {
			immediate: true,
			onComplete: () => dispatch(cardAutoScroll(null)),
		});
	}, [autoScroll, dispatch, issueEntries, scrollTo]);
};
