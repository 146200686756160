import { createSelector } from 'reselect';
import difference from 'lodash/difference';
import isNil from 'lodash/isNil';
import memoize from 'lodash/memoize';
import { IN_PROGRESS } from '@atlassian/jira-common-constants/src/status-categories.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { CardTransitionZoneInfo } from '@atlassian/jira-platform-board-kit/src/ui/column/column-transition-zones-container/transition-zone/types.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import type {
	CardTransition,
	CardTransitionEntities,
	IssueTypeTransitions,
} from '../../../model/card-transition/card-transition-types.tsx';
import type { ColumnId } from '../../../model/column/column-types.tsx';
import type {
	CoreIssue,
	Issue,
	IssueTypeId,
	SwimlaneId,
} from '../../../model/issue/issue-types.tsx';
import type { IssueProjectEntities } from '../../../model/software/software-types.tsx';
import { NO_SWIMLANE } from '../../../model/swimlane/swimlane-modes.tsx';
import type { State } from '../../reducers/types.tsx';
import type { CardDragState, OverTransition } from '../../reducers/ui/cards/drag/types.tsx';
import {
	draggingCardSourceColumnIdSelector,
	draggingCardTargetColumnIdSelector,
	getCardDragState,
	getDraggingCardId,
	draggingCardIssueTypeId,
	draggingCardIssueStatusId,
	draggingCardSourceSwimlaneIdSelector,
	getSelectedTransition,
} from '../card/card-selectors.tsx';
import { boardIssuesSelector } from '../issue/board-issue-selectors.tsx';
import { getIssueOrIssueChildById } from '../issue/issue-selectors.tsx';
import {
	getCardTransitions,
	getIsCMPBoard,
	getIssueProjects,
	getIssueTransitions,
	isBoardRankable,
} from '../software/software-selectors.tsx';
import { getSwimlaneMode } from '../swimlane/swimlane-mode-selectors.tsx';
import { isDraggingSubtaskSelector } from '../work/work-selectors.tsx';

export const activeTransitionSelector = createSelector(
	[getCardDragState],
	(dragState: CardDragState): OverTransition | undefined =>
		dragState.cardId != null && dragState.overTransition != null
			? dragState.overTransition
			: undefined,
);

export const activeTransitionNameSelector = createSelector(
	[draggingCardIssueTypeId, activeTransitionSelector, getCardTransitions],
	(issueTypeId, activeTransition, cardTransitions) =>
		activeTransition &&
		cardTransitions.columnIssueTypeTransitions[String(activeTransition.toColumnId)][
			String(issueTypeId)
		].filter((transition) => transition.id === activeTransition.transitionId)[0].name,
);

export const isActiveTransitionGlobalSelector = createSelector(
	[draggingCardIssueTypeId, activeTransitionSelector, getCardTransitions],
	(issueTypeId, activeTransition, cardTransitions) =>
		(activeTransition &&
			cardTransitions.columnIssueTypeTransitions[String(activeTransition.toColumnId)][
				String(issueTypeId)
			].filter((transition) => transition.id === activeTransition.transitionId)[0].isGlobal) ||
		false,
);

export const activeTransitionDestinationStatusSelector = createSelector(
	[draggingCardIssueTypeId, activeTransitionSelector, getCardTransitions],
	(issueTypeId, activeTransition, cardTransitions) => {
		const destinationStatusId =
			activeTransition &&
			cardTransitions.columnIssueTypeTransitions[String(activeTransition.toColumnId)][
				String(issueTypeId)
			].filter((transition) => transition.id === activeTransition.transitionId)[0]
				.destinationStatusId;
		return destinationStatusId != null
			? cardTransitions.issueTypeStatus[String(issueTypeId)][String(destinationStatusId)]
			: undefined;
	},
);

export const cardTransitionDestinationStatusSelector = createSelector(
	[boardIssuesSelector, getSelectedTransition, getCardTransitions],
	(boardIssues, selectedTransition, cardTransitions) =>
		(issueId: IssueId, destinationColumnId: ColumnId) => {
			const issueTypeId = boardIssues[String(issueId)]?.typeId;

			if (!issueTypeId) return undefined;

			const destinationTransitions =
				cardTransitions.columnIssueTypeTransitions[destinationColumnId]?.[String(issueTypeId)];

			if (!destinationTransitions?.length) return undefined;

			const destinationTransition = selectedTransition
				? destinationTransitions.find(({ id }) => id === selectedTransition.transitionId)
				: destinationTransitions[0];

			return destinationTransition
				? cardTransitions.issueTypeStatus[String(issueTypeId)][
						destinationTransition.destinationStatusId
					]
				: undefined;
		},
);

export const cardTransitionSourceStatusSelector = createSelector(
	[boardIssuesSelector, getCardTransitions],
	(boardIssues, cardTransitions) => (issueId: IssueId) => {
		const issue = boardIssues[String(issueId)];

		if (!issue) return undefined;

		const { statusId, typeId } = issue;
		return cardTransitions.issueTypeStatus[typeId]?.[statusId];
	},
);

const emptyArray: Array<CardTransitionZoneInfo> = [];

const getColumnTransitions = (
	cardTransitions: CardTransitionEntities,
	issueTypeId: number | undefined,
	issueStatusId: number | undefined,
	destinationColumnId: ColumnId,
	draggingCard: Issue,
	issueProjects: IssueProjectEntities,
) => {
	// Per column transitions, with filtering by project key and
	// source/destination statuses.
	const columnTransitions =
		cardTransitions.columnIssueTypeTransitions?.[String(destinationColumnId)]?.[
			String(issueTypeId)
		];

	const projectKey = draggingCard
		? Object.values(issueProjects).find((project) => project.id === draggingCard.projectId)?.key
		: null;

	if (columnTransitions && Array.isArray(columnTransitions)) {
		const statuses = cardTransitions.issueTypeStatus[String(issueTypeId)];

		return {
			transitions: columnTransitions
				.filter(
					(transition) =>
						!transition.isInitial &&
						(transition.sourceStatusId === issueStatusId || transition.isGlobal),
				)
				.filter((transition) => projectKey == null || transition.projectKey === projectKey)
				.filter((transition) => statuses[String(transition.destinationStatusId)])
				.map((transition) => {
					const toStatus = statuses[String(transition.destinationStatusId)];
					return {
						transitionId: transition.id,
						transitionName: transition.name,
						targetStatusName: toStatus.name,
						targetStatusId: transition.destinationStatusId,
						targetStatusCategory: toStatus.category || IN_PROGRESS,
						isGlobal: transition.isGlobal,
						isAvailable: true,
					};
				}),
		};
	}
	return { transitions: emptyArray };
};

const getDraggingCard = createSelector(
	[getDraggingCardId, getIssueOrIssueChildById],
	(draggingCardId, getIssueFn) => getIssueFn(draggingCardId),
);

export const draggingIssueTransitionsForColumnSelector = createSelector(
	[
		draggingCardIssueTypeId,
		draggingCardIssueStatusId,
		getCardTransitions,
		getIssueTransitions,
		getDraggingCard,
		getIssueProjects,
	],
	(
		issueTypeId,
		issueStatusId,
		cardTransitions,
		issueTransitionsById,
		draggingCard,
		issueProjects,
	) =>
		memoize(
			(
				destinationColumnId: ColumnId,
			): { transitions: CardTransitionZoneInfo[]; isLoading?: boolean } => {
				// Early termination if this is called incorrectly.
				if (!draggingCard) {
					return { transitions: emptyArray };
				}

				// Check if there are transitions specifically for this issue
				const issueTransitions = issueTransitionsById?.[String(draggingCard.id)];

				// If there are transitions specifically for this card, we need to handle them
				if (issueTransitions && issueTransitions.status !== 'failure') {
					if (issueTransitions.status === 'success') {
						// Per issue available transitions, this is fetched from the
						// back-end, so doesn't require project filters.
						const selfTransitions = issueTransitions.data[String(destinationColumnId)] || [];

						const statuses = cardTransitions.issueTypeStatus[String(issueTypeId)];

						return {
							transitions: selfTransitions
								.filter((transition) => transition && !transition.isInitial)
								.filter((transition) => statuses[String(transition.destinationStatusId)])
								.filter((transition) => transition.isAvailable)
								.map((transition) => {
									const toStatus = statuses[String(transition.destinationStatusId)];
									return {
										transitionId: transition.id,
										transitionName: transition.name,
										targetStatusName: toStatus.name,
										targetStatusId: transition.destinationStatusId,
										targetStatusCategory: toStatus.category || IN_PROGRESS,
										isGlobal: transition.isGlobal,
										isAvailable: transition.isAvailable,
									};
								}),
						};
					}

					return { transitions: emptyArray, isLoading: true };
				}

				return getColumnTransitions(
					cardTransitions,
					issueTypeId,
					issueStatusId,
					destinationColumnId,
					draggingCard,
					issueProjects,
				);
			},
		),
);

/**
 * If the user is dragging a sub-task or the board is ordered by rank
 * AND we are on a CMP board
 * AND we are dragging across swimlanes
 *
 * We will force rendering ALL transition zones (including the current status)
 * and hide the "transition to..." header.
 */
export const makeGetShowAllTransitionZones = createSelector(
	[
		getIsCMPBoard,
		draggingCardSourceSwimlaneIdSelector,
		getSwimlaneMode,
		isDraggingSubtaskSelector,
		isBoardRankable,
	],
	(isCMPBoard, draggingSwimlaneId, swimlaneMode, isDraggingSubtask, isOrderByRank) =>
		(swimlaneId: SwimlaneId | null | undefined): boolean => {
			const hasSwimlanes = swimlaneMode !== NO_SWIMLANE.id;
			const isDraggingAcrossSwimlanes = !isNil(swimlaneId) && draggingSwimlaneId !== swimlaneId;

			return (
				isCMPBoard &&
				(isDraggingSubtask || isOrderByRank) &&
				hasSwimlanes &&
				isDraggingAcrossSwimlanes
			);
		},
);

export const shouldDisableDropOnColumn = createSelector(
	[draggingCardSourceColumnIdSelector, draggingCardTargetColumnIdSelector],
	(sourceColumnId, targetColumnId) =>
		memoize((columnId: ColumnId) => {
			// Drags within same column always ok
			if (sourceColumnId === columnId) {
				return false;
			}
			// If last hovered over column is not this one, disable.
			return targetColumnId !== columnId;
		}),
);

const getNonEmptyIssueTypeIds = (issueTypeTransitions: IssueTypeTransitions) =>
	Object.keys(issueTypeTransitions).filter((issueTypeId) => {
		const transitions = issueTypeTransitions[issueTypeId];
		return transitions.length > 0;
	});

export const hasDifferentIssueTypeTransitionsInColumns = createSelector(
	getCardTransitions,
	({ columnIssueTypeTransitions }) => {
		const columnIds = Object.keys(columnIssueTypeTransitions);

		if (columnIds.length === 0) {
			return false;
		}

		const firstColumnId = columnIds[0];
		const firstColumnIssueTypes = columnIssueTypeTransitions[firstColumnId];

		let previousIssueTypeIds = getNonEmptyIssueTypeIds(firstColumnIssueTypes);

		for (let i = 1; i < columnIds.length; i += 1) {
			const columnId = columnIds[i];
			const columnIssueTypes = columnIssueTypeTransitions[columnId];
			const columnIssueTypeIds = getNonEmptyIssueTypeIds(columnIssueTypes);

			if (
				difference(previousIssueTypeIds, columnIssueTypeIds).length > 0 ||
				difference(columnIssueTypeIds, previousIssueTypeIds).length > 0
			) {
				return true;
			}
			previousIssueTypeIds = columnIssueTypeIds;
		}

		return false;
	},
);

export const getTransitionsByColumnAndIssueType = createSelector(
	[
		getCardTransitions,
		getIssueProjects,
		(
			_: State,
			props: {
				columnId: ColumnId;
				issueTypeId: IssueTypeId;
				projectId?: number;
			},
		) => props,
	],
	(cardTransitions, issueProjects, { columnId, issueTypeId, projectId }): CardTransition[] => {
		try {
			const projectKey = Object.values(issueProjects).find(
				(project) => project.id === projectId,
			)?.key;
			return cardTransitions.columnIssueTypeTransitions[String(columnId)][
				String(issueTypeId)
			].filter(
				(transition) =>
					!transition.isInitial && (!projectKey || transition.projectKey === projectKey),
			);
		} catch (_err) {
			log.unsafeErrorWithCustomerData(
				'jsw.board-selectors.getTransitionsByColumnAndIssueType',
				'Error retrieving issue transitions by column and issue type',
				{
					cardTransitions: JSON.stringify(cardTransitions),
					issueProjects: JSON.stringify(issueProjects),
					columnId,
					issueTypeId,
				},
			);
			return [];
		}
	},
);

export const getIsInitialTransition =
	(state: State) => (columnId: number) => (issueTypeId: number) =>
		!!getCardTransitions(state).columnIssueTypeTransitions[String(columnId)][
			String(issueTypeId)
		].find(({ isInitial }) => isInitial);

export const transitionsByColumnIdSelector = createSelector(
	[getCardTransitions, getIssueProjects],
	(cardTransition, issueProjects) =>
		(columnId: ColumnId | undefined, issue: Issue | CoreIssue | null | undefined) => {
			if (isNil(columnId) || isNil(issue) || isNil(issue.typeId)) return null;

			const projectKey = issue
				? Object.values(issueProjects).find((project) => project.id === issue.projectId)?.key
				: null;

			// Filter by project key since we can get a single transition returned once for each
			// project associated with a board and we do not want to return duplicates
			// which will affect the issue parent assign modal
			const transitionsByIssueType = cardTransition.columnIssueTypeTransitions[columnId]?.[
				issue.typeId
			]?.filter((transition) => transition.projectKey === projectKey);

			// only show transitions relevant to the parent issue moving from it's current
			// column/backlog to the specified end column
			const validTransitionsForParentIssue = transitionsByIssueType
				? transitionsByIssueType.filter(
						(transition) => transition.sourceStatusId === issue.statusId || transition.isGlobal,
					)
				: null;

			return validTransitionsForParentIssue ?? null;
		},
);
