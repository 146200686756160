import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const syncSprintDialog = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "async-sprint-association-dialog" */ './index').then(
			(module) => module.AsyncSprintAssociationDialog,
		),
	),
	getPreloadProps: () => ({}),
});
