import { templateRecommendationPriority } from '../../common/constants.tsx';
import type { TemplateRecommendation } from '../../common/types.tsx';

export const findHighestPriorityTemplate = (
	templates: TemplateRecommendation[],
): TemplateRecommendation | null => {
	const templateMap = Object.fromEntries(
		templates.map((template) => [template.id.value, template]),
	);

	const templateId = templateRecommendationPriority.find((id) => templateMap[id]);
	return templateId ? templateMap[templateId] : null;
};
