import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import {
	type MenuNode,
	MenuNodeType,
} from '@atlassian/jira-software-context-menu/src/common/types.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import { useBoardSelector } from '../../../../../../../../state/index.tsx';
import { selectedIssueKeysSelector } from '../../../../../../../../state/selectors/card/card-selectors.tsx';
import { VIEW_IN_ISSUE_SEARCH_MENU_ID } from '../../constants.tsx';
import messages from '../../messages.tsx';
import type { Props, ContentProps } from './types.tsx';

export const useViewIssueSearchContent = ({
	isViewInIssueSearchVisible,
	onViewIssueSearch,
}: ContentProps): MenuNode[] => {
	const { formatMessage } = useIntl();

	if (!isViewInIssueSearchVisible) return [];

	return [
		{
			type: MenuNodeType.Item,
			label: formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.viewInIssueSearchIssueTermRefresh
					: messages.viewInIssueSearch,
			),
			id: VIEW_IN_ISSUE_SEARCH_MENU_ID,
			onClick: onViewIssueSearch,
		},
	];
};

export const useViewIssueSearch = ({ isMultiSelectMode }: Props) => {
	const { push } = useRouterActions();
	const selectedIssueKeys = useBoardSelector(selectedIssueKeysSelector);

	const onViewIssueSearch = () => {
		const url = `/issues/?jql=${encodeURIComponent(`key IN (${selectedIssueKeys.join(',')})`)}`;
		push(url);
	};

	return useViewIssueSearchContent({
		onViewIssueSearch,
		isViewInIssueSearchVisible: isMultiSelectMode,
	});
};
