/**
 * Function to determine if the new filter refinement should be displayed
 */
export const shouldDisplayFilterRefinement = ({
	isIncrementPlanningBoard,
	isCMPBoard,
	isJSMBoard,
	isJSWBoard,
}: {
	isIncrementPlanningBoard: boolean;
	isCMPBoard: boolean;
	isJSMBoard: boolean;
	isJSWBoard: boolean;
}): boolean => !isIncrementPlanningBoard && !isCMPBoard && !isJSMBoard && isJSWBoard;
