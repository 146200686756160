// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentProps } from 'react';
import { EngagementSpotlight } from '@atlassiansox/engagekit-ts';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { fg } from '@atlassian/jira-feature-gating';
import BoardKitColumnHeader from '@atlassian/jira-platform-board-kit/src/common/ui/column-header/main.tsx';
import { Capability } from '../../../../../common/capability/index.tsx';
import { useBoardSelector } from '../../../../../state/index.tsx';
import {
	getIsCMPBoard,
	getIsIncrementPlanningBoard,
} from '../../../../../state/selectors/software/software-selectors.tsx';
import { useCapability } from '../../../../../state/state-hooks/capabilities/index.tsx';

type BoardKitColumnHeaderProps = JSX.LibraryManagedAttributes<
	typeof BoardKitColumnHeader,
	ComponentProps<typeof BoardKitColumnHeader>
>;

type Props = {
	isDone: boolean;
	isLastColumn: boolean;
} & BoardKitColumnHeaderProps;

const ColumnHeaderView = (props: Props) => {
	const { isDone, isLastColumn, ...otherProps } = props;
	const isCMPBoard = useBoardSelector(getIsCMPBoard);
	const isIncrementPlanningBoard = useBoardSelector(getIsIncrementPlanningBoard);
	const isFlexibleColumns = useCapability(Capability.FLEXIBLE_BOARD_COLUMNS);
	const shouldRemoveSpaceForMenu = fg('program_board_flexible_cadence')
		? isCMPBoard || isIncrementPlanningBoard
		: isCMPBoard;
	const renderBoardKitColumnHeader = () => (
		<BoardKitColumnHeader
			isDone={isDone}
			{...otherProps}
			isCMPBoard={isCMPBoard}
			shouldRemoveSpaceForMenu={shouldRemoveSpaceForMenu}
			isFlexibleColumns={isFlexibleColumns}
		/>
	);

	if (isLastColumn) {
		return (
			<>
				<SpotlightTarget name="DoneNotLastColumnMessage">
					{renderBoardKitColumnHeader()}
				</SpotlightTarget>
				<EngagementSpotlight engagementId="DoneNotLastColumnMessage" />
			</>
		);
	}

	if (isDone) {
		return (
			<>
				<SpotlightTarget name="DoneColumnMessage">{renderBoardKitColumnHeader()}</SpotlightTarget>
				<EngagementSpotlight engagementId="DoneColumnMessage" />
			</>
		);
	}

	return renderBoardKitColumnHeader();
};

export default ColumnHeaderView;
