import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import type {
	InlineCardCreateIssueTypeConfigGQL,
	InlineCardCreateModeGQL,
} from '@atlassian/jira-software-board-fetch-scope-critical/src/types.tsx';
import type { ColumnId } from '../column/column-types.tsx';
import type { SwimlaneId } from '../issue/issue-types.tsx';

export const NONE = 'NONE';
// need to open the GIC
export const PARTIAL = 'PARTIAL';
// some columns or some issuetyps are not applicable for ICC
export const MIXED = 'MIXED';

export const FULL = 'FULL';

export type InlineCardCreateMode = InlineCardCreateModeGQL;

export type InlineCardCreateIssueTypeConfig = {
	[issueTypeId: IssueTypeId]: InlineCardCreateIssueTypeConfigGQL[IssueTypeId];
};

export type InlineCardCreateColumnConfig = {
	mode: Exclude<InlineCardCreateMode, typeof NONE>;
	issueTypes?: InlineCardCreateIssueTypeConfig;
};

export type InlineCardCreateConfig = {
	mode: InlineCardCreateMode;
	requireJqlContext?: boolean;
	boardQuery?: string;
	swimlanes?: {
		[swimlaneId: SwimlaneId]:
			| typeof FULL
			| {
					[columnId: ColumnId]: {
						mode: typeof FULL;
					};
			  };
	};
	columns?: {
		[columnId: ColumnId]: InlineCardCreateColumnConfig;
	};
};
