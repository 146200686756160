import React, { useCallback, useMemo, useRef, useState, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import memoizeOne from 'memoize-one';
import { CustomThemeButton } from '@atlaskit/button';
import BranchIcon from '@atlaskit/icon/core/branch';
import CloudArrowUpIcon from '@atlaskit/icon/core/cloud-arrow-up';
import CommitIcon from '@atlaskit/icon/core/commit';
import MergeFailureIcon from '@atlaskit/icon/core/merge-failure';
import MergeSuccessIcon from '@atlaskit/icon/core/merge-success';
import PullRequestIcon from '@atlaskit/icon/core/pull-request';
import Popup, { type PopupProps } from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { getTheme } from '@atlassian/jira-common-theme/src/index.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { BOARD } from '@atlassian/jira-development-board-common/src/common/constants.tsx';
import type { Location } from '@atlassian/jira-development-board-common/src/common/types.tsx';
import {
	ButtonStatus,
	type DevInfoType,
	DevInfoTypes,
	type PopupOpenTrigger,
} from '@atlassian/jira-development-board-common/src/types.tsx';
import ErrorFallback from '@atlassian/jira-development-board-common/src/ui/error-fallback/index.tsx';
import { createIssueAri } from '@atlassian/jira-development-board-common/src/utils/index.tsx';
import {
	DeploymentNonProdFailed,
	DeploymentNonProdSuccess,
	DeploymentProdFailed,
	DeploymentProdSuccess,
} from '@atlassian/jira-development-common/src/ui/development-icon/index.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { ModalEntryPointContainer } from '@atlassian/jira-entry-point-modal-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { ExperienceStart } from '@atlassian/jira-experience-tracker/src/ui/experience-start/index.tsx';
import { useExperienceSuccess } from '@atlassian/jira-experience-tracker/src/ui/experience-success/index.tsx';
import DesignIcon from '@atlassian/jira-icons/src/ui/design-icon/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { designModalEntryPoint } from '@atlassian/jira-issue-design-modal/entrypoint.tsx';
import { type Ari, createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import { ContextualAnalyticsData, INLINE_DIALOG } from '@atlassian/jira-product-analytics-bridge';
import JiraRelayEnvironmentProvider from '@atlassian/jira-relay-environment-provider/src/index.tsx';
import type { FormatMessage } from '@atlassian/jira-shared-types/src/general.tsx';
import { DevInfoDetailsPopupSkeleton } from '@atlassian/jira-skeletons/src/ui/dev-info-details-popup/DevInfoDetailsPopupSkeleton.tsx';
import { isDevopsFeatureDisabledInFedRamp } from '@atlassian/jira-software-devops-fedramp-utils/src/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import {
	DeploymentEnvironment,
	DeploymentState,
	DevInfoButtonLabelMapping,
	experienceDetails,
	HOVER_TIMEOUT_MS,
	iconColor,
	metrics,
	packageDetails,
	popupEntryPoints,
} from './constants.tsx';
import messages from './messages.tsx';
import type { DeploymentDetails } from './types.tsx';

const DEV_INFO_BUTTON_ID = 'development-board-dev-info-icon.button-text';

const DevInfoIconsMapping = memoizeOne((formatMessage: FormatMessage) => ({
	[DevInfoTypes.BRANCH]: (
		<BranchIcon label={formatMessage(messages.branchIconLabel)} color={token('color.icon')} />
	),
	[DevInfoTypes.COMMIT]: (
		<CommitIcon label={formatMessage(messages.commitIconLabel)} color={token('color.icon')} />
	),
	[DevInfoTypes.PULLREQUEST]: (
		<PullRequestIcon
			label={formatMessage(messages.openPullRequestIconLabel)}
			color={token('color.icon')}
		/>
	),
	[DevInfoTypes.DECLINED]: (
		<MergeFailureIcon
			label={formatMessage(messages.declinedPullRequestIconLabel)}
			color={token('color.icon')}
		/>
	),
	[DevInfoTypes.MERGED]: (
		<MergeSuccessIcon
			label={formatMessage(messages.mergedPullRequestIconLabel)}
			color={token('color.icon.accent.green')}
		/>
	),
	[DevInfoTypes.DEPLOYMENT]: (
		<CloudArrowUpIcon
			label={formatMessage(messages.deploymentIconLabel)}
			color={token('color.icon')}
		/>
	),
	[DevInfoTypes.DESIGN]: (
		<Box xcss={DesignIconStyles}>
			<DesignIcon label={formatMessage(messages.designIconLabel)} />
		</Box>
	),
}));

const getDevInfoIcon = (
	devInfoType: DevInfoType,
	formatMessage: FormatMessage,
	deploymentDetails?: DeploymentDetails,
) => {
	if (deploymentDetails?.deploymentState && deploymentDetails?.deploymentEnvironment) {
		if (
			deploymentDetails.deploymentEnvironment === DeploymentEnvironment.PRODUCTION &&
			deploymentDetails.deploymentState === DeploymentState.SUCCESSFUL
		) {
			return (
				<DeploymentProdSuccess
					size="small"
					label={formatMessage(messages.deploymentProductionSuccessIconLabel)}
				/>
			);
		}
		if (
			deploymentDetails.deploymentEnvironment === DeploymentEnvironment.PRODUCTION &&
			deploymentDetails.deploymentState === DeploymentState.FAILED
		) {
			return (
				<DeploymentProdFailed
					size="small"
					label={formatMessage(messages.deploymentProductionFailedIconLabel)}
				/>
			);
		}
		if (
			deploymentDetails.deploymentEnvironment !== DeploymentEnvironment.PRODUCTION &&
			deploymentDetails.deploymentState === DeploymentState.SUCCESSFUL
		) {
			return (
				<DeploymentNonProdSuccess
					size="small"
					label={formatMessage(messages.deploymentNonProductionSuccessIconLabel)}
				/>
			);
		}
		if (
			deploymentDetails.deploymentEnvironment !== DeploymentEnvironment.PRODUCTION &&
			deploymentDetails.deploymentState === DeploymentState.FAILED
		) {
			return (
				<DeploymentNonProdFailed
					size="small"
					label={formatMessage(messages.deploymentNonProductionFailedIconLabel)}
				/>
			);
		}
	}
	return DevInfoIconsMapping(formatMessage)[devInfoType];
};

type DevInfoIconViewProps = {
	issueId: number | string;
	devInfoType: DevInfoType;
	/**
	 * Preventing default on context-menu clicks on this case is an
	 * anti-pattern.
	 * Users may want to right-click a link to open it on a new tab/window.
	 *
	 * On the new UIF boards, the application context-menu will be handled
	 * correctly such that it will not open when dev-info view is right-clicked.
	 *
	 * This prop will disable the `preventDefault` call within this component,
	 * allowing more browser native behavior to be followed (links wil be able
	 * to be right-clicked, despite the card context menu).
	 *
	 * This prop may be removed once UIF board is fully rolled-out and the
	 * legacy behavior isn't needed.
	 */
	allowNativeContextMenu?: boolean;
	customTrigger?: ReactNode;
	scopeId?: string;
	deploymentDetails?: DeploymentDetails;
	isStopPropagationOnClick?: boolean;
	placement?: PopupProps['placement'];
	offset?: PopupProps['offset'];
	zIndex?: number;
};

export const DevInfoIconView = ({
	issueId,
	devInfoType,
	allowNativeContextMenu,
	customTrigger,
	scopeId,
	deploymentDetails,
	isStopPropagationOnClick = true,
	placement = 'bottom-start',
	offset,
	/* Setting zIndex slighlty lower than the default so that other popups (like the right-click context menu) appear on top of this */
	zIndex = layers.layer - 1,
}: DevInfoIconViewProps) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [isHoverMode, setIsHoverMode] = useState<boolean>(true);
	const [shouldAutoFocus, setShouldAutoFocus] = useState<boolean>(true);
	const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
	const { formatMessage } = useIntl();
	const { cloudId } = useTenantContext();
	const issueAri: Ari = useMemo(
		() => createIssueAri(cloudId, issueId.toString()),
		[cloudId, issueId],
	);
	const siteAri = createAri({
		resourceOwner: 'jira',
		resourceType: 'site',
		resourceId: cloudId,
	});

	const initialTrigger = useRef<PopupOpenTrigger | null>(null);

	const devInfoIcon = getDevInfoIcon(devInfoType, formatMessage, deploymentDetails);

	const popupType =
		devInfoType === DevInfoTypes.MERGED || devInfoType === DevInfoTypes.DECLINED
			? DevInfoTypes.PULLREQUEST
			: devInfoType;

	const { id: experienceId, name: experienceName } = experienceDetails[popupType];

	const onExperienceSuccess = useExperienceSuccess({
		experience: experienceName,
	});

	const entryPointParams = useMemo(() => ({ issueId: issueAri }), [issueAri]);

	// eslint-disable-next-line @atlassian/react-entrypoint/prefer-entrypoint-file-import
	const { entryPointActions, entryPointReferenceSubject, stopMetric } = useEntryPoint(
		popupEntryPoints[popupType],
		entryPointParams,
		metrics[popupType],
	);

	// Entry point for the design modal that is used in the design details popup
	const designEntrypoint = useEntryPoint(designModalEntryPoint, {
		siteAri,
		issueAri,
		cloudId,
	});
	const designModalTriggerRef = useEntryPointButtonTrigger(designEntrypoint.entryPointActions);

	const entryPointErrorBoundaryProps = useMemo(
		() => ({
			...packageDetails[popupType],
			errorFallback: () => (
				<ErrorFallback devInfoType={devInfoType} issueAri={issueAri} scopeId={scopeId} />
			),
		}),
		[devInfoType, issueAri, popupType, scopeId],
	);

	const runtimeProps = useMemo(
		() => ({
			issueAri,
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			devInfoType: devInfoType as DevInfoType,
			trigger: initialTrigger.current ?? undefined,
			onReady: () => {
				stopMetric();
				onExperienceSuccess();
			},
			scopeId,
			modalTriggerRef: devInfoType === DevInfoTypes.DESIGN ? designModalTriggerRef : undefined,
		}),
		[issueAri, devInfoType, scopeId, designModalTriggerRef, stopMetric, onExperienceSuccess],
	);

	const handleDevIconClick = useCallback(() => {
		clearTimeout(timeoutRef.current);
		if (initialTrigger.current === null) {
			initialTrigger.current = 'click';
		}
		if (isOpen && isHoverMode) {
			setIsHoverMode(false);
		} else if (isOpen && !isHoverMode) {
			initialTrigger.current = null;
			setIsOpen(false);
			setIsHoverMode(true);
		} else {
			entryPointActions.load();
			setShouldAutoFocus(true);
			setIsOpen(true);
			setIsHoverMode(false);
		}
	}, [entryPointActions, isHoverMode, isOpen]);

	const handleMouseEnter = () => {
		if (!isHoverMode) {
			return;
		}

		clearTimeout(timeoutRef.current);

		setShouldAutoFocus(false);

		if (!isOpen) {
			entryPointActions.preload();

			timeoutRef.current = undefined;
			timeoutRef.current = setTimeout(() => {
				if (initialTrigger.current === null) {
					initialTrigger.current = 'hover';
				}

				entryPointActions.load();
				setIsOpen(true);
			}, HOVER_TIMEOUT_MS);
		}
	};

	const handleMouseLeave = () => {
		if (!isHoverMode) {
			return;
		}

		clearTimeout(timeoutRef.current);

		entryPointActions.cancelPreload();

		timeoutRef.current = undefined;
		if (isOpen) {
			timeoutRef.current = setTimeout(() => {
				initialTrigger.current = null;
				setShouldAutoFocus(true);
				setIsHoverMode(true);
				setIsOpen(false);
			}, HOVER_TIMEOUT_MS);
		}
	};

	if (iconColor[devInfoType] === undefined) {
		log.safeErrorWithoutCustomerData(
			'packages.development.board.dev-info-icon',
			'unexpected devInfoType',
			{
				devInfoType,
			},
		);
	}

	const customTheme = getTheme({
		buttonStyles: {
			subtle: {
				color: {
					// @ts-expect-error - TS2322 - Type '{ color: { default: string | undefined; hover: string | undefined; active: string | undefined; }; }' is not assignable to type 'CSSInterpolation'. | TS2532 - Object is possibly 'undefined'.
					default: iconColor[devInfoType][ButtonStatus.DEFAULT],
					hover: iconColor[devInfoType][ButtonStatus.HOVER],
					active: iconColor[devInfoType][ButtonStatus.ACTIVE],
				},
			},
		},
	});

	const devInfoTextId = `${DEV_INFO_BUTTON_ID}-${issueId}`;

	return (
		<span
			role="presentation"
			data-testid="development-board-dev-info-icon.container"
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onClick={(e) => {
				// Prevents clicks on the icon from opening the Issue view
				if (isStopPropagationOnClick) e.stopPropagation();
			}}
			onKeyDown={(e) => {
				// Prevents using keyboard to open the Issue view
				e.stopPropagation();
			}}
		>
			<Popup
				placement={placement}
				shouldFlip
				autoFocus={shouldAutoFocus}
				shouldUseCaptureOnOutsideClick
				testId="development-board-dev-info-icon.popup"
				trigger={(triggerProps) =>
					customTrigger != null ? (
						<div {...triggerProps}>{customTrigger}</div>
					) : (
						<>
							<CustomThemeButton
								{...triggerProps}
								onClick={handleDevIconClick}
								isSelected={isOpen && !isHoverMode}
								// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides
								theme={customTheme}
								appearance="subtle"
								spacing="compact"
								iconBefore={devInfoIcon}
								aria-describedby={devInfoTextId}
							/>
							<DevInfoButtonText id={devInfoTextId}>
								{DevInfoButtonLabelMapping(formatMessage)[devInfoType]}
							</DevInfoButtonText>
						</>
					)
				}
				isOpen={isOpen}
				onClose={() => {
					initialTrigger.current = null;
					setIsOpen(false);
					setIsHoverMode(true);
				}}
				offset={offset}
				zIndex={zIndex}
				content={() => (
					// eslint-disable-next-line jsx-a11y/no-static-element-interactions
					<div
						// We are stopping right-click event propagation to not show the custom context menu from the Jira Issue Card
						// and instead use the event default
						onContextMenu={(event) => {
							if (!allowNativeContextMenu) {
								event.preventDefault();
							}
						}}
					>
						<ExperienceStart
							analyticsSource={experienceName}
							experience={experienceName}
							experienceId={experienceId}
						/>

						<JiraEntryPointContainer
							entryPointReferenceSubject={entryPointReferenceSubject}
							{...entryPointErrorBoundaryProps}
							fallback={<DevInfoDetailsPopupSkeleton devinfoType={popupType} />}
							runtimeProps={runtimeProps}
						/>
					</div>
				)}
			/>
			<ModalEntryPointContainer
				entryPointReferenceSubject={designEntrypoint.entryPointReferenceSubject}
				id="jiraDesignModal"
				packageName="design-modal"
				entryPointActions={designEntrypoint.entryPointActions}
				runtimeProps={{
					initialSelectedDesign: 0,
				}}
			/>
		</span>
	);
};

const ERROR_ID = 'renderDevInfoIcon';
const PACKAGE_NAME = 'jiraDevelopmentBoardDevInfoIcon';

export type DevInfoIconProps = {
	issueId: number | string;
	devInfoType: DevInfoType;
	allowNativeContextMenu?: boolean;
	customTrigger?: ReactNode;
	scopeId?: string;
	deploymentDetails?: DeploymentDetails;
	isStopPropagationOnClick?: boolean;
	placement?: PopupProps['placement'];
	offset?: PopupProps['offset'];
	zIndex?: number;
	location?: Location;
};

const DevInfoIcon = ({
	issueId,
	devInfoType,
	allowNativeContextMenu,
	customTrigger,
	scopeId,
	deploymentDetails,
	isStopPropagationOnClick = true,
	placement,
	offset,
	zIndex,
	location = BOARD,
}: DevInfoIconProps) => {
	const attributes = {
		devInfoType: devInfoType ?? 'unexpected',
		location,
	};
	return (
		<JSErrorBoundary
			id={ERROR_ID}
			packageName={PACKAGE_NAME}
			teamName="fusion-isotopes"
			fallback="unmount"
			attributes={attributes}
			extraEventData={attributes}
			sendToPrivacyUnsafeSplunk
		>
			{!isDevopsFeatureDisabledInFedRamp() && (
				<JiraRelayEnvironmentProvider>
					<ContextualAnalyticsData
						sourceType={INLINE_DIALOG}
						sourceName="DevInfoIcon"
						attributes={attributes}
					>
						<DevInfoIconView
							issueId={issueId}
							devInfoType={devInfoType}
							allowNativeContextMenu={allowNativeContextMenu}
							customTrigger={customTrigger}
							scopeId={scopeId}
							deploymentDetails={deploymentDetails}
							isStopPropagationOnClick={isStopPropagationOnClick}
							placement={placement}
							offset={offset}
							zIndex={zIndex}
						/>
					</ContextualAnalyticsData>
				</JiraRelayEnvironmentProvider>
			)}
		</JSErrorBoundary>
	);
};

export default DevInfoIcon;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DevInfoButtonText = styled.span({
	display: 'none',
});

const DesignIconStyles = xcss({
	height: '16px',
	width: '16px',
	display: 'flex',
	justifyContent: 'center',
});
