import { useMemo } from 'react';
import { useJiraSoftwareProjectNavigation } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/common/project-sidebar-navigation-resource/index.tsx';
import { extractData } from '@atlassian/jira-navigation-apps-sidebar-next-gen-use-features/src/controllers/features/utils/extract-data.tsx';
import { useSidebarFeatureToggled } from '@atlassian/jira-tmp-feature-toggle/src/controllers/board-features/index.tsx';
import { useClearSidebarToggled } from '@atlassian/jira-tmp-feature-toggle/src/controllers/use-clear-sidebar-toggled/index.tsx';
import { useShouldApplyFeatureToggle } from '@atlassian/jira-tmp-feature-toggle/src/controllers/use-should-apply-tmp-feature-toggle/index.tsx';
import type { UseFeaturesReturn } from './types.tsx';

export const useFeatures = (): UseFeaturesReturn => {
	const { data, loading, error } = useJiraSoftwareProjectNavigation();
	const isEligibleForFeatureToggle = useShouldApplyFeatureToggle();
	const [sidebarFeatureToggled] = useSidebarFeatureToggled();

	const extractedData = useMemo(() => data && extractData(data), [data]);

	// merged feature status before navigation resource gets refreshed
	// if and only if there are features toggled and they are eligible for the feature
	const features = useMemo(() => {
		if (!extractedData || !isEligibleForFeatureToggle || !sidebarFeatureToggled) {
			return extractedData;
		}

		return { ...extractedData, ...sidebarFeatureToggled };
	}, [extractedData, isEligibleForFeatureToggle, sidebarFeatureToggled]);

	useClearSidebarToggled();

	return { data: features, loading, error };
};
