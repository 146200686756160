import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/concat';
import 'rxjs/add/operator/takeWhile';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import { REFRESH_SOURCE_CARD_CLEAR_UNDO } from '../../model/constants.tsx';
import {
	type CardClearUndoAction,
	CARD_CLEAR_UNDO,
	cardClearRevert,
} from '../../state/actions/card/index.tsx';
import { workRefreshData } from '../../state/actions/work/index.tsx';
import type { ActionsObservable } from '../../state/types.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable) =>
	action$.ofType(CARD_CLEAR_UNDO).mergeMap((action: CardClearUndoAction) => {
		const { undoPromise, retry, optimisticId } = action.payload;
		return Observable.concat(
			Observable.of(cardClearRevert(optimisticId)),
			Observable.fromPromise(undoPromise)
				.takeWhile(() => retry)
				.map(() => workRefreshData(REFRESH_SOURCE_CARD_CLEAR_UNDO))
				.catch(() => Observable.of(workRefreshData(REFRESH_SOURCE_CARD_CLEAR_UNDO))),
		);
	});
