import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import debounce from 'lodash/debounce';
import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { useAutobatch } from '@atlassian/jira-software-react-scheduler/src/ui/autobatch/index.tsx';
import { zIndex, layout, columnHeaderHeight } from '../../../common/constants/styles/index.tsx';
import { LoadingContainer } from './loading-container/index.tsx';
import { NoTransitionContainer } from './no-transition-container/index.tsx';
import ColumnTransitionToZone from './transition-to-zone/index.tsx';
import { HOVER_INTENT_DELAY_MS } from './transition-zone/constants.tsx';
import { ColumnTransitionZone } from './transition-zone/main.tsx';
import type { CardTransitionZoneInfo } from './transition-zone/types.tsx';
import type { ColumnTransitionZonesContainerProps } from './types.tsx';

export const ColumnTransitionZonesContainer = ({
	columnId,
	swimlaneId,
	columnDraggingOver,
	onCardDragOver,
	hoverIntent,
	onTransitionSelected,
	hasSwimlanes,
	sourceStatus,
	notAvailableMessage,
	noTransitionMessage,
	isLoading,
	shouldRenderFullColumnTransitionZone,
	hideSourceStatusInLabel,
	transitions: allTransitions,
	sourceColumnId,
	showAllTransitionZones,
}: ColumnTransitionZonesContainerProps) => {
	const autobatch = useAutobatch();
	const [allowSelfColumnTransitions, setAllowSelfColumnTransitions] = useState(false);
	const containerRef = useRef<HTMLDivElement | null>(null);

	const hoverIntentOnThisColumn = hoverIntent && columnDraggingOver === columnId;
	const debouncedOnDragOverTransition = useMemo(
		() =>
			debounce((transitionId: number | null, hoverIntentOnZone: boolean) => {
				if (!hoverIntentOnThisColumn) {
					onCardDragOver(transitionId, hoverIntentOnZone);
				}
			}, HOVER_INTENT_DELAY_MS),
		[hoverIntentOnThisColumn, onCardDragOver],
	);

	useEffect(
		() => () => {
			debouncedOnDragOverTransition.cancel();
		},
		[debouncedOnDragOverTransition],
	);

	const onHoverOverTransitionToZone = useCallback(() => {
		setAllowSelfColumnTransitions(true);
	}, []);

	const renderTransitions = (transitions: CardTransitionZoneInfo[]) => {
		if (isLoading) {
			return <LoadingContainer />;
		}

		if (transitions.length === 0 && noTransitionMessage) {
			return (
				<InnerContainer
					hasSwimlanes={hasSwimlanes}
					zoneCount={transitions.length}
					shouldRenderFullColumnTransitionZone={Boolean(shouldRenderFullColumnTransitionZone)}
					data-component-selector="platform-board-kit.ui.column.column-transition-zones-container.inner-container"
				>
					<NoTransitionContainer noTransitionMessage={noTransitionMessage} />
				</InnerContainer>
			);
		}
		return (
			<InnerContainer
				hasSwimlanes={hasSwimlanes}
				zoneCount={transitions.length}
				shouldRenderFullColumnTransitionZone={Boolean(shouldRenderFullColumnTransitionZone)}
				data-component-selector="platform-board-kit.ui.column.column-transition-zones-container.inner-container"
			>
				{transitions.map(
					({
						transitionId,
						transitionName,
						targetStatusName,
						targetStatusCategory,
						isAvailable,
						targetStatusId,
						isGlobal,
					}) => {
						const filteredSourceStatus = sourceStatus?.id === targetStatusId ? null : sourceStatus;
						const filteredTransitionName =
							sourceStatus?.id === targetStatusId ? null : transitionName;

						return (
							<ColumnTransitionZone
								key={transitionId}
								isHidden={hoverIntent}
								transitionId={transitionId}
								transitionName={filteredTransitionName}
								targetStatusName={targetStatusName}
								onDragOver={debouncedOnDragOverTransition}
								targetStatusCategory={targetStatusCategory}
								isTransitionAvailable={isAvailable}
								isTransitionGlobal={isGlobal}
								notAvailableMessage={notAvailableMessage}
								sourceStatus={filteredSourceStatus}
								onTransitionSelection={onTransitionSelected}
								hideSourceStatusInLabel={hideSourceStatusInLabel}
								columnId={columnId}
								swimlaneId={swimlaneId}
							/>
						);
					},
				)}
			</InnerContainer>
		);
	};

	useEffect(() => {
		if (!containerRef.current || isLoading || allTransitions.length === 0) return undefined;

		return dropTargetForElements({
			element: containerRef.current,
			onDragLeave: () => {
				autobatch(() => setAllowSelfColumnTransitions(false));
			},
		});
	}, [isLoading, allTransitions.length, allowSelfColumnTransitions, onCardDragOver, autobatch]);

	let transitions = allTransitions;

	if (!showAllTransitionZones && columnId === sourceColumnId) {
		transitions = allTransitions.filter(
			(t) => !t.isGlobal || (sourceStatus && t.targetStatusId !== sourceStatus.id),
		);

		if (transitions.length === 0) {
			return null;
		}
	}

	if (
		!showAllTransitionZones &&
		columnId === sourceColumnId &&
		!allowSelfColumnTransitions &&
		!hoverIntent
	) {
		return <ColumnTransitionToZone onHover={onHoverOverTransitionToZone} />;
	}

	return (
		<OuterContainer
			data-component-selector="platform-board-kit.ui.column.column-transition-zones-container.outer-transition-container"
			data-testid="platform-board-kit.ui.column.column-transition-zones-container.outer-container"
			isHidden={!shouldRenderFullColumnTransitionZone && hoverIntent}
			hasSwimlanes={hasSwimlanes}
			shouldRenderFullColumnTransitionZone={Boolean(shouldRenderFullColumnTransitionZone)}
			innerRef={containerRef}
		>
			{renderTransitions(transitions)}
		</OuterContainer>
	);
};

// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InnerContainer = styled.div<{
	hasSwimlanes?: boolean;
	zoneCount: number;
	shouldRenderFullColumnTransitionZone: boolean;
}>`
	--full-column-height: calc(
		var(--board-scroll-element-height) * 1px - ${columnHeaderHeight + 8}px
	);
	position: sticky;
	width: 100%;
	display: flex;
	flex-direction: column;
	top: ${({ hasSwimlanes }) => (hasSwimlanes ? columnHeaderHeight * 2 : columnHeaderHeight)}px;
	transition: opacity 0.2s;
	background-color: ${({ theme }) => theme.background};
	height: ${({ shouldRenderFullColumnTransitionZone }) =>
		shouldRenderFullColumnTransitionZone ? 'var(--full-column-height)' : '100%'};
	max-height: ${({ shouldRenderFullColumnTransitionZone, zoneCount }) =>
		shouldRenderFullColumnTransitionZone
			? 'var(--full-column-height)'
			: `${zoneCount * columnHeaderHeight * 2}px`};
`;

// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const OuterContainer = styled.div<{
	hasSwimlanes?: boolean;
	isHidden: boolean;
	shouldRenderFullColumnTransitionZone: boolean;
}>`
	position: absolute;
	top: ${({ hasSwimlanes }) => (hasSwimlanes ? 0 : columnHeaderHeight)}px;
	height: 100%;
	width: 100%;
	z-index: ${({ shouldRenderFullColumnTransitionZone }) =>
		shouldRenderFullColumnTransitionZone
			? zIndex.fullColumnHeightTransitionZones
			: zIndex.transitionZones};
	background-color: ${({ theme }) => theme.background};
	border-radius: ${layout.cardListRadius}px;
	${({ isHidden }) => isHidden && 'display: none;'};
`;
