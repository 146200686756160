import { useEffect, useMemo, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import { useJiraSoftwareProjectNavigation } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/common/project-sidebar-navigation-resource/index.tsx';
import { usePreviousWithInitial } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { useSidebarFeatureToggled } from '../board-features/index.tsx';
import { useShouldApplyFeatureToggle } from '../use-should-apply-tmp-feature-toggle/index.tsx';

export const useClearSidebarToggled = () => {
	const { data, refresh } = useJiraSoftwareProjectNavigation();
	const [sidebarFeatureToggled, { clearSiderbarFeatureToggled }] = useSidebarFeatureToggled();
	const isEligibleForFeatureToggle = useShouldApplyFeatureToggle();
	const sidebarHasChanged = useRef(false);

	const previousData = usePreviousWithInitial(data);

	// if resource data has changed since the last refresh then remove the sidebar toggled data.
	// i.e. whatever that has been toggled is reflected on the latest resource
	const shouldClear = useMemo(
		() => isEligibleForFeatureToggle && !isEqual(previousData, data),
		[isEligibleForFeatureToggle, previousData, data],
	);

	useEffect(() => {
		if (shouldClear) {
			clearSiderbarFeatureToggled();
		}
	}, [shouldClear, clearSiderbarFeatureToggled]);

	useEffect(() => {
		sidebarHasChanged.current = isEligibleForFeatureToggle && !!sidebarFeatureToggled;
	}, [isEligibleForFeatureToggle, sidebarFeatureToggled]);

	// refresh the navigation resource if sidebar has changed
	useEffect(
		() => () => {
			if (sidebarHasChanged.current) {
				refresh();
			}
		},
		[refresh],
	);
};
