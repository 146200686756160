import React, {
	Component,
	type ComponentType,
	cloneElement,
	type ReactNode,
	type ReactElement,
} from 'react';
import ReactDOM from 'react-dom';
import { styled as styled2 } from '@compiled/react';
// Remove ThemeProvider on jsw_compiled_migration_tanuki FF cleanup
// eslint-disable-next-line jira/restricted/styled-components, jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { ThemeProvider } from 'styled-components';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import { colors } from '@atlaskit/theme';

import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import {
	layout,
	minCardHeight,
	zIndex,
	columnHeaderHeight,
} from '../../../../common/constants/styles/index.tsx';
import {
	type ColumnId,
	type SwimlaneId,
	type ColumnThemeProps,
	ColumnTheme,
} from '../../../../common/types.tsx';
import { ColumnWrapper, columnThemes } from '../../../../common/ui/column/index.tsx';
import { Overlay } from '../../../../common/ui/overlay/index.tsx';
import { TransitionZoneIndicatorContainerContext } from '../../../../common/ui/transition-zone-indicator/index.tsx';

const cardListCssClass = '__board-test-hook__column';

type ColumnRenderFunction<T> = (props: T) => ReactElement;

export type Props = {
	isDragging: boolean;
	isDraggable: boolean;
	isUpdating: boolean;
	isOutlined?: boolean;
	isDropDisabled?: boolean;
	isCollapsed?: boolean;
	isFlexible?: boolean;
	id: ColumnId;
	header?: ReactElement | null | undefined;
	appearance: ColumnTheme;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	children?: ColumnRenderFunction<any> | ReactNode | null | undefined;
	swimlaneId?: SwimlaneId;
	footer: ReactNode;
	TransitionZones?: ComponentType<{
		columnId: ColumnId;
		swimlaneId?: SwimlaneId;
	}>;
};

// eslint-disable-next-line jira/react/no-class-components
export default class Column extends Component<Props> {
	static defaultProps = {
		isDragging: false,
		isDropDisabled: false,
		isUpdating: false,
		isOutlined: false,
		isCollapsed: false,
		appearance: 'default',
		footer: null,
	};

	static contextType = TransitionZoneIndicatorContainerContext;

	context!: React.ContextType<typeof TransitionZoneIndicatorContainerContext>;

	getTheme = (theme?: ColumnTheme | null) => {
		if (theme && columnThemes[theme]) {
			return columnThemes[theme];
		}

		return columnThemes.default;
	};

	renderPortalTransitionZone() {
		const { id, swimlaneId, TransitionZones } = this.props;
		if (isNil(TransitionZones)) return null;

		if (this.context) {
			const ref = this.context.getRef(id);
			if (ref && ref.current) {
				return ReactDOM.createPortal(
					<TransitionZones columnId={id} swimlaneId={swimlaneId} />,
					ref.current,
				);
			}
		}
		return <TransitionZones columnId={id} swimlaneId={swimlaneId} />;
	}

	renderAkColumn(theme: ColumnThemeProps) {
		const {
			isDragging,
			isDraggable,
			isOutlined,
			isUpdating,
			header,
			appearance,
			TransitionZones,
			isCollapsed,
			isFlexible,
		} = this.props;

		// FIXME: This needs to be checked - done as part of typescript upgrade.
		// It can be more than ReactNode and typescript is not happy about this!
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const children = this.props.children as ReactNode;

		const renderChildren = () => {
			// If you want to use render props this component will give you back the props and some droppable
			// context for the external component to consume
			// Example: src/packages/software/board/board-container/board/columns-container/column/view.js
			if (!__SERVER__ && isFunction(this.props.children)) {
				// Our v-list under this column will need visibility over droppableProps
				const renderFunction: ColumnRenderFunction<Props> = this.props.children || (() => <div />);

				return renderFunction(this.props);
			}

			// Fallback to generic non-virtualized implementation
			return (
				<CardDroppableTarget
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className="__board-test-hook__card-list-container"
				>
					{children}
					{this.props.footer}
				</CardDroppableTarget>
			);
		};

		return (
			<ThemeProvider theme={theme}>
				<StyledColumnWrapper
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={cardListCssClass}
					isCollapsed={isCollapsed}
					isFlexible={isFlexible}
					appearance={appearance ?? ColumnTheme.Default}
				>
					{header &&
						cloneElement(header, {
							isDragging,
							isDraggable,
							isUpdating,
							appearance,
						})}
					{TransitionZones && this.renderPortalTransitionZone()}
					{renderChildren()}
					{isOutlined && <Outline withHeader={!!header} />}
					{isUpdating && <Overlay appearance={appearance ?? ColumnTheme.Default} />}
				</StyledColumnWrapper>
			</ThemeProvider>
		);
	}

	render() {
		const columnTheme = this.getTheme(this.props.appearance);
		return this.renderAkColumn(columnTheme);
	}
}

const outlineWidth = 2;

type OutlineProps = { withHeader?: boolean };
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled,@atlaskit/ui-styling-standard/no-exported-styles -- To migrate as part of go/ui-styling-standard
export const Outline = styled2.div<OutlineProps>({
	position: 'absolute',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.icc,
	borderWidth: `${outlineWidth}px`,
	borderStyle: 'solid',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderColor: token('color.border.focused', colors.B200),
	marginLeft: token('space.negative.025', '-2px'),
	borderRadius: token('space.075', '6px'),
	pointerEvents: 'none',
	left: token('space.025', '2px'),
	width: `calc(100% - ${outlineWidth * 2}px)`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ withHeader }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		`calc(100% - ${withHeader ? columnHeaderHeight : 0}) + ${outlineWidth * 2}px)`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	top: ({ withHeader }) => (withHeader ? columnHeaderHeight : 0),
});

type CardDroppableTargetProps = { isDisabled?: boolean };
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled,@atlaskit/ui-styling-standard/no-exported-styles -- To migrate as part of go/ui-styling-standard
export const CardDroppableTarget = styled2.div<CardDroppableTargetProps>({
	flex: '1 0 auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	padding: `${layout.smallGutter - gridSize / 4}px ${layout.smallGutter}px ${layout.smallGutter}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minHeight: `${minCardHeight}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	opacity: ({ isDisabled }) => (isDisabled ? '0.5' : '1'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledColumnWrapper = styled2(ColumnWrapper)({
	display: 'flex',
	flexDirection: 'column',
});
