import React, { forwardRef } from 'react';
import { IconButton, type IconButtonProps } from '@atlaskit/button/new';
import SprintIcon from '@atlaskit/icon/glyph/sprint';
import type { TriggerProps } from '@atlaskit/popup';
import { useIntl } from '@atlassian/jira-intl';

import messages from './messages.tsx';

const TOOLTIP_OPTIONS: IconButtonProps['tooltip'] = {
	hideTooltipOnClick: true,
};

export type Props = Partial<Omit<TriggerProps, 'ref'>> &
	Pick<IconButtonProps, 'isSelected' | 'onClick' | 'isDisabled' | 'analyticsContext'>;

const SprintDetailsTriggerButton = forwardRef(
	(extraProps: Props, ref: React.Ref<HTMLButtonElement>) => {
		const { formatMessage } = useIntl();
		return (
			<IconButton
				ref={ref}
				icon={SprintIcon}
				label={formatMessage(messages.tooltip)}
				isTooltipDisabled={false}
				tooltip={TOOLTIP_OPTIONS}
				testId="software-board.header.sprint-controls.sprint-details.trigger-button.icon-button"
				{...extraProps}
			/>
		);
	},
);

export default SprintDetailsTriggerButton;
