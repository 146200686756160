import uuid from 'uuid/v4';
import type { Extension } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import {
	createStore,
	createContainer,
	createHook,
	type Action,
} from '@atlassian/react-sweet-state';

export type OpenedForgeApp<
	E extends Extension = Extension,
	D extends Record<string, unknown> = Record<string, unknown>,
> = {
	id: string;
	extension: E;
	extensionData: D;
	action?: string;
	uuid: string;
	isLoading: boolean;
};

export const ForgeAppsContainer = createContainer();

type ForgeAppsState = {
	openedApps: OpenedForgeApp[];
};

const actions = {
	openApp:
		(
			appId: string,
			extension: Extension,
			action?: string,
			extensionData: Record<string, unknown> = {},
		): Action<ForgeAppsState> =>
		({ setState, getState }) => {
			const openedApps = getState().openedApps;
			setState({
				openedApps: [
					...openedApps.filter((app) => app.id !== appId || app.action !== action),
					{
						id: appId,
						extension,
						extensionData,
						action,
						uuid: uuid(),
						isLoading: true,
					},
				],
			});
		},
	setAppLoaded:
		(appId: string, action?: string): Action<ForgeAppsState> =>
		({ setState, getState }) => {
			const openedApps = getState().openedApps;
			setState({
				openedApps: openedApps.map((app) =>
					app.id === appId && app.action === action && app.isLoading
						? { ...app, isLoading: false }
						: app,
				),
			});
		},
	closeApps:
		(): Action<ForgeAppsState> =>
		({ setState }) => {
			setState({ openedApps: [] });
		},
	closeApp:
		(appId: string, action?: string): Action<ForgeAppsState> =>
		({ setState, getState }) => {
			const openedApps = getState().openedApps;
			setState({
				openedApps: openedApps.filter((app) => app.id !== appId || app.action !== action),
			});
		},
};

const Store = createStore<ForgeAppsState, typeof actions>({
	containedBy: ForgeAppsContainer,
	initialState: { openedApps: [] },
	actions,
});

export const useForgeApps = createHook(Store);

const isAppLoading = (
	state: ForgeAppsState,
	{ appId, action }: { appId: string; action?: string },
) => {
	return state.openedApps.some((app) => app.id === appId && app.action === action && app.isLoading);
};

export const useIsAppLoading = createHook(Store, {
	selector: isAppLoading,
});

const isAnyAppLoading = (state: ForgeAppsState) => state.openedApps.some((app) => app.isLoading);

export const useIsAnyAppLoading = createHook(Store, {
	selector: isAnyAppLoading,
});
