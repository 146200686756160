import { chain, freeze, set, setIn, unset } from 'icepick';
import isNil from 'lodash/isNil';
import union from 'lodash/union';
import { ColumnType } from '@atlassian/jira-common-constants/src/column-types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { SWIMLANE_TEAMLESS } from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/constants.tsx';
import { values } from '@atlassian/jira-shared-types/src/general.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import { IP_BOARD_STATUS_FIELD_ID } from '../../../../common/constants.tsx';
import { CARD_DND_TYPE } from '../../../../model/constants.tsx';
import { ASSIGNEE, PARENT } from '../../../../model/swimlane/swimlane-types.tsx';
import { entityArrayToMap } from '../../../../services/software/software-data-transformer.tsx';
import { BACKLOG_ISSUE_MOVE_EXECUTE } from '../../../actions/board/backlog-issue-move/index.tsx';
import { CARD_CLEAR, CARD_DATA_SET, CARD_DELETE } from '../../../actions/card/index.tsx';
import { COLUMN_DELETE_REQUEST } from '../../../actions/column/delete/index.tsx';
import type { Action } from '../../../actions/index.tsx';
import { ISSUE_ADD_LABELS } from '../../../actions/issue/add-label-modal/index.tsx';
import { ASSIGN_TO_ME_REQUEST } from '../../../actions/issue/assign-to-me/index.tsx';
import { ISSUE_BULK_UPDATE_REQUEST } from '../../../actions/issue/bulk-update/index.tsx';
import { SET_CHILD_ISSUES_METADATA } from '../../../actions/issue/child-issues-metadata/index.tsx';
import {
	ISSUE_CREATE_REQUEST,
	ISSUE_CREATE_SUCCESS,
} from '../../../actions/issue/create/index.tsx';
import { ISSUE_DELETE_REQUEST } from '../../../actions/issue/delete/index.tsx';
import { DEV_STATUS_LOAD_SUCCESS } from '../../../actions/issue/dev-status/index.tsx';
import { SET_ESTIMATE } from '../../../actions/issue/estimate/index.tsx';
import { ISSUE_FLAG_WITH_COMMENT_REQUEST } from '../../../actions/issue/flag-with-comment/index.tsx';
import { ISSUE_CHANGE_SWIMLANE } from '../../../actions/issue/index.tsx';
import {
	ISSUE_LINKS_CREATE,
	ISSUE_LINKS_REMOVE_SUCCESS,
	ISSUE_LINKS_ADD_UPDATE_SUCCESS,
} from '../../../actions/issue/issue-link/index.tsx';
import { ISSUE_MOVE_TO_BACKLOG_REQUEST } from '../../../actions/issue/move-to-backlog/index.tsx';
import { SET_PARENT_OPTIMISTIC } from '../../../actions/issue/parent/index.tsx';
import { ISSUE_RANK_TEAM_DATE_REQUEST } from '../../../actions/issue/rank-team-date/index.tsx';
import { ISSUE_RANK_TRANSITION_UPDATE_OPTIMISTIC } from '../../../actions/issue/rank-transition/index.tsx';
import { SET_SUMMARY } from '../../../actions/issue/summary/index.tsx';
import {
	ISSUE_UPDATE_SUCCESS,
	SET_ISSUE_PARENT,
	ISSUE_INCREMENT_PLANNING_UPDATE,
} from '../../../actions/issue/update/index.tsx';
import { WORK_DATA_SET, WORK_DATA_CRITICAL_SET } from '../../../actions/work/index.tsx';
import type { IssuesState } from './types.tsx';

export const issueEntitiesReducer = (
	state: IssuesState = freeze({}),
	action: Action,
): IssuesState => {
	if (action.type === WORK_DATA_SET || action.type === WORK_DATA_CRITICAL_SET) {
		const {
			payload: { issues },
		} = action;
		return freeze(entityArrayToMap(issues));
	}

	if (action.type === ISSUE_RANK_TEAM_DATE_REQUEST) {
		const {
			payload: { issueIds, destinationColumnId, destinationSwimlaneId },
		} = action;
		const changeChain = chain(state);
		issueIds.forEach((issueId) => {
			if (!state[String(issueId)]) return;
			changeChain.setIn([String(issueId), 'columnId'], destinationColumnId);
			changeChain.setIn(
				[String(issueId), 'teamId'],
				destinationSwimlaneId === SWIMLANE_TEAMLESS ? undefined : destinationSwimlaneId,
			);
			changeChain.setIn([String(issueId), 'hasScenarioChanges'], true);
		});

		return changeChain.value();
	}

	if (action.type === ISSUE_BULK_UPDATE_REQUEST) {
		const {
			payload: { issueIds, sprint },
		} = action;
		const changeChain = chain(state);
		issueIds.forEach((issueId) => {
			if (!state[String(issueId)] || sprint === undefined) return;
			changeChain.setIn([String(issueId), 'sprintId'], sprint);
		});
		return changeChain.value();
	}

	if (action.type === ISSUE_CREATE_REQUEST) {
		const {
			payload: { issues, type },
		} = action;
		if (type !== CARD_DND_TYPE) return state;
		const newState = { ...state };
		issues.forEach((issue) => {
			newState[String(issue.id)] = issue;
		});
		return freeze(newState);
	}

	if (action.type === ISSUE_CREATE_SUCCESS) {
		const {
			payload: { temporaryIssueIds, issues },
		} = action;
		const newState = { ...state };

		if (temporaryIssueIds.some((id) => state[String(id)])) {
			temporaryIssueIds.forEach((temporaryId) => {
				delete newState[String(temporaryId)];
			});
			issues.forEach((issue) => {
				newState[String(issue.id)] = issue;
			});
			return freeze(newState);
		}
		return state;
	}

	if (action.type === ISSUE_UPDATE_SUCCESS) {
		const {
			payload: { issue },
		} = action;
		const issueId = String(issue.id);
		if (state[issueId]) return set(state, issueId, issue);
		return state;
	}

	if (action.type === CARD_DATA_SET) {
		const {
			payload: { issue },
		} = action;
		const issueId = String(issue.id);
		const issueInState = state[issueId];
		if (issueInState) {
			return set(state, issueId, {
				...issue,
				devStatus: issue.devStatus || issueInState.devStatus,
			});
		}
		if (state[issueId]) return set(state, issueId, issue);
		return state;
	}

	if (action.type === ISSUE_DELETE_REQUEST) {
		const {
			payload: { issue },
		} = action;
		return unset(state, String(issue.id));
	}

	if (action.type === CARD_DELETE) {
		const {
			payload: { issueId, parentId, isDone },
		} = action;

		// if the deleted issue is a base level issue, remove the issue right away
		if (state[String(issueId)]) {
			return unset(state, String(issueId));
		}

		// if the deleted issue is a subtask, update children properties of the issue
		if (state[String(parentId)]) {
			return chain(state)
				.updateIn([String(parentId), 'numCompleteChildren'], (value: number) =>
					isDone ? Math.max(value - 1, 0) : value,
				)
				.updateIn([String(parentId), 'numTotalChildren'], (value: number) => Math.max(value - 1, 0))
				.value();
		}

		// if the deleted issue is an epic, update the issues that are children of the epic
		const newState = chain(state);

		values(state).forEach(({ id, parentId: issueParentId }) => {
			if (issueId && issueId === issueParentId) {
				newState.setIn([String(id), 'parentId'], null);
			}
		});

		return newState.value();
	}

	if (action.type === CARD_CLEAR) {
		const {
			payload: { cardIds },
		} = action;

		const newState = chain(state);
		cardIds.forEach((cardId) => {
			newState.unset(String(cardId));
		});
		return newState.value();
	}

	if (action.type === ISSUE_MOVE_TO_BACKLOG_REQUEST) {
		const {
			payload: { issueIds },
		} = action;
		const newState = chain(state);
		issueIds.forEach((issueId) => {
			newState.unset(String(issueId));
		});
		return newState.value();
	}

	if (action.type === ISSUE_RANK_TRANSITION_UPDATE_OPTIMISTIC) {
		const {
			payload: { issueIds, destinationColumnId, isDestinationDone, destinationStatus, isCMPBoard },
		} = action;

		const changeChain = chain(state);
		issueIds.forEach((issueId) => {
			if (!state[String(issueId)]) return;
			changeChain.setIn([String(issueId), 'columnId'], destinationColumnId);

			if (destinationStatus) {
				changeChain.setIn([String(issueId), 'statusId'], Number(destinationStatus.id));
			}

			if (!isCMPBoard) {
				// We use `isDone` to show issue key strikethrough as an indicator of issue resolution.
				// Only for TMP, status' category equaling `Done` means the issue is resolved.
				// For CMP, we cannot determine an issue's resolution completely client-side due to the complexities of non-simplified workflows.
				// As we fire a data refresh request on each issue transition for CMP, the correct `isDone` value will be pulled from the server anyway with a slight delay.
				changeChain.setIn([String(issueId), 'isDone'], isDestinationDone);
			}
		});

		return changeChain.value();
	}

	if (action.type === BACKLOG_ISSUE_MOVE_EXECUTE) {
		const {
			payload: { issueIds },
		} = action;
		const changeChain = chain(state);

		issueIds.forEach((id) => changeChain.unset(String(id)));

		return changeChain.value();
	}

	if (action.type === ISSUE_CHANGE_SWIMLANE) {
		const {
			payload: { issueIds, swimlaneValues },
		} = action;
		const changeChain = chain(state);

		issueIds.forEach((issueId) => {
			if (!state[String(issueId)]) return;
			if (swimlaneValues.type === ASSIGNEE) {
				changeChain.setIn([String(issueId), 'assigneeAccountId'], swimlaneValues.assigneeAccountId);
			}
			if (swimlaneValues.type === PARENT) {
				changeChain.setIn([String(issueId), 'parentId'], swimlaneValues.parentId);
			}
		});

		return changeChain.value();
	}

	if (action.type === DEV_STATUS_LOAD_SUCCESS) {
		const { payload } = action;
		const newState = chain(state);

		Object.keys(payload).forEach((issueIdString) => {
			if (!state[String(issueIdString)]) return;
			newState.setIn([issueIdString, 'devStatus'], payload[issueIdString]);
		});

		return newState.value();
	}

	if (action.type === ISSUE_FLAG_WITH_COMMENT_REQUEST) {
		const {
			payload: { issueIds, flag },
		} = action;
		const newState = chain(state);

		issueIds.forEach((id) => {
			if (state[String(id)]) {
				newState.setIn([String(id), 'isFlagged'], flag);
			}
		});

		return newState.value();
	}

	if (action.type === ASSIGN_TO_ME_REQUEST) {
		const {
			payload: { issueId, assigneeAccountId },
		} = action;
		return state[String(issueId)]
			? setIn(state, [String(issueId), 'assigneeAccountId'], assigneeAccountId)
			: state;
	}

	if (action.type === COLUMN_DELETE_REQUEST) {
		const newState = { ...state };
		const { payload } = action;
		Object.keys(state)
			.filter((issueKey) => state[issueKey].columnId === payload.columnId)
			.forEach((issueKey) => {
				if (!payload.closestColumn) {
					delete newState[issueKey];
				} else if (payload.closestColumn.type === ColumnType.STATUS) {
					newState[issueKey] = {
						...newState[issueKey],
						columnId: payload.closestColumn.id,
						statusId: payload.closestColumn.statuses[0].id,
					};
				}
			});
		return newState;
	}

	if (action.type === ISSUE_ADD_LABELS) {
		const {
			payload: { issueIds, labels },
		} = action;
		const newState = chain(state);

		issueIds.forEach((id) => {
			if (!state[String(id)]) return;
			const currentLabels = state[String(id)].labels || [];
			newState.setIn([String(id), 'labels'], union(currentLabels, labels));
		});

		return newState.value();
	}

	if (action.type === SET_PARENT_OPTIMISTIC) {
		const {
			payload: { issues, parentId },
		} = action;
		const newState = chain(state);

		issues.forEach((issue) => {
			if (!state[String(issue.id)]) return;
			newState.setIn([String(issue.id), 'parentId'], parentId);
			newState.setIn([String(issue.id), 'hasScenarioChanges'], true);
		});

		return newState.value();
	}

	if (action.type === SET_ESTIMATE) {
		const {
			payload: { issueId, estimate },
		} = action;

		if (state[String(issueId)]) {
			return chain(state)
				.setIn([String(issueId), 'estimate'], estimate)
				.setIn([String(issueId), 'hasScenarioChanges'], true)
				.value();
		}

		return state;
	}

	if (!fg('issue_view_in_program_board') && action.type === SET_ISSUE_PARENT) {
		const {
			payload: { issueId, issueParentId },
		} = action;

		if (state[String(issueId)]) {
			return chain(state)
				.setIn([String(issueId), 'parentId'], issueParentId)
				.setIn([String(issueId), 'hasScenarioChanges'], true)
				.value();
		}

		return state;
	}

	if (action.type === SET_ISSUE_PARENT && fg('issue_view_in_program_board')) {
		const {
			payload: { issueId, issueParentId, hasScenarioChanges = true },
		} = action;

		if (state[String(issueId)]) {
			const newState = chain(state);
			newState.setIn([String(issueId), 'parentId'], issueParentId);
			if (hasScenarioChanges) {
				newState.setIn([String(issueId), 'hasScenarioChanges'], hasScenarioChanges);
			}
			return newState.value();
		}

		return state;
	}

	if (action.type === SET_CHILD_ISSUES_METADATA) {
		const { payload: issues } = action;
		const newState = chain(state);
		issues.forEach((issue) => {
			if (!state[String(issue.id)]) return;
			newState.setIn([String(issue.id), 'numTotalChildren'], issue.numTotalChildren || 0);
			newState.setIn([String(issue.id), 'numCompleteChildren'], issue.numCompleteChildren || 0);
		});
		return newState.value();
	}

	if (!fg('issue_view_in_program_board') && action.type === SET_SUMMARY) {
		const {
			payload: { issueId, summary },
		} = action;

		if (state[String(issueId)]) {
			return chain(state)
				.setIn([String(issueId), 'summary'], summary)
				.setIn([String(issueId), 'hasScenarioChanges'], true)
				.value();
		}

		return state;
	}

	if (action.type === SET_SUMMARY && fg('issue_view_in_program_board')) {
		const {
			payload: { issueId, summary, hasScenarioChanges = true },
		} = action;

		if (state[String(issueId)]) {
			const newState = chain(state);
			newState.setIn([String(issueId), 'summary'], summary);
			if (hasScenarioChanges) {
				newState.setIn([String(issueId), 'hasScenarioChanges'], hasScenarioChanges);
			}
			return newState.value();
		}

		return state;
	}

	if (
		!fg('dependency_visualisation_program_board_fe_and_be') &&
		action.type === ISSUE_LINKS_CREATE
	) {
		const {
			payload: { newIssueLinks },
		} = action;

		const newIssueLinksGroups = Object.entries(newIssueLinks);
		const newState = chain(state);
		newIssueLinksGroups.forEach(([issueId, issueLinks]) => {
			newState.setIn(
				[String(issueId), 'issueLinks'],
				[...(state[String(issueId)]?.issueLinks ?? []), ...issueLinks],
			);
			newState.setIn([String(issueId), 'hasScenarioChanges'], true);
		});
		return newState.value();
	}

	if (
		action.type === ISSUE_LINKS_ADD_UPDATE_SUCCESS &&
		fg('dependency_visualisation_program_board_fe_and_be')
	) {
		const {
			payload: { newIssueLinks },
		} = action;

		const newIssueLinksGroups = Object.entries(newIssueLinks);
		const newState = chain(state);
		newIssueLinksGroups.forEach(([issueId, issueLinks]) => {
			newState.setIn([String(issueId), 'issueLinks'], issueLinks);
			newState.setIn([String(issueId), 'hasScenarioChanges'], true);
		});
		return newState.value();
	}

	if (action.type === ISSUE_LINKS_REMOVE_SUCCESS) {
		const {
			payload: { issueLinksToRemove },
		} = action;

		const issueLinksGroups = Object.entries(issueLinksToRemove);
		const newState = chain(state);
		const updateState = (issueId: IssueId | undefined, issueLinkIdToRemove: string) => {
			if (issueId) {
				const issueLinks = state[String(issueId)]?.issueLinks ?? [];
				newState.setIn(
					[String(issueId), 'issueLinks'],
					issueLinks.filter((issueLink) => issueLinkIdToRemove !== issueLink.id),
				);
				newState.setIn([String(issueId), 'hasScenarioChanges'], true);
			}
		};

		issueLinksGroups.forEach(([issueId, issueLinkId]) => {
			const issueLinks = state[String(issueId)]?.issueLinks ?? [];
			const issueLinkToRemove = issueLinks.find((issueLink) => issueLinkId === issueLink.id);

			// Update issue links state in the source issue
			updateState(issueLinkToRemove?.sourceId, issueLinkId);

			// Update issue links state in the destination issue
			updateState(issueLinkToRemove?.destinationId, issueLinkId);
		});
		return newState.value();
	}

	if (action.type === ISSUE_INCREMENT_PLANNING_UPDATE && fg('issue_cards_in_program_board')) {
		const {
			payload: { issueId, fieldId, fieldValue },
		} = action;
		if (
			fieldId === IP_BOARD_STATUS_FIELD_ID &&
			typeof fieldValue === 'object' &&
			!isNil(fieldValue)
		) {
			return state[String(issueId)]
				? chain(state)
						.setIn([String(issueId), 'statusId'], fieldValue.statusId)
						.setIn([String(issueId), 'hasScenarioChanges'], true)
						.setIn([String(issueId), 'isDone'], fieldValue.isDone)
						.value()
				: state;
		}

		return state[String(issueId)]
			? setIn(state, [String(issueId), 'hasScenarioChanges'], true)
			: state;
	}

	return state;
};
