import { createSelector } from 'reselect';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { IssueLinksStatsState } from '../../reducers/entities/issue-links-stats/types.tsx';
import type { State } from '../../reducers/types.tsx';
import { getEntities } from '../software/software-selectors.tsx';

export const getIssueLinksStatsState = (state: State): IssueLinksStatsState =>
	getEntities(state).issueLinksStats;

export const getIsLoadingIssueLinksStatsForCard = createSelector(
	[getIssueLinksStatsState],
	(linkedIssuesStats) => (cardId: IssueId) =>
		linkedIssuesStats?.loadingStatisticsItems?.includes(cardId) ||
		linkedIssuesStats?.loadingStatisticsItems?.includes(Number(cardId)),
);

export const getIssueLinksStatsForCard = createSelector(
	[getIssueLinksStatsState],
	(linkedIssuesStats) => (cardId: IssueId) => {
		const issueStats = linkedIssuesStats?.statisticItems[cardId];
		let issueLinkCountSum = 0;

		issueStats?.forEach((issueStat) => {
			issueLinkCountSum += issueStat.issueLinkCount;
		});
		return issueLinkCountSum;
	},
);
