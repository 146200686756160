import React, { Component } from 'react';
import noop from 'lodash/noop';
import type { RealtimeEvent, RealtimeEventType } from './common/types/events.tsx';
import type { EventPayload } from './common/types/payloads.tsx';
import { RealtimeClient } from './services/realtime-client/index.tsx';
import { RealtimeProvider } from './services/realtime-provider/index.tsx';

export type Props = {
	appId: string;
	channels: string[];
	events: string[];
	onJoin: (channels: string[]) => void;
	onReceive: (event: RealtimeEvent) => void;
	onLeave: (channels: string[]) => void;
};

// eslint-disable-next-line jira/react/no-class-components
export default class Realtime extends Component<Props> {
	static defaultProps = {
		onJoin: noop,
		onReceive: noop,
		onLeave: noop,
	};

	onEventReceive = (eventType: RealtimeEventType, payload: EventPayload) => {
		const event: RealtimeEvent = {
			type: eventType,
			payload,
		};

		this.props.onReceive(event);
	};

	render() {
		const { appId, events, onJoin, onLeave, channels } = this.props;

		return (
			<RealtimeProvider appId={appId} onJoin={onJoin} onLeave={onLeave} channels={channels}>
				<RealtimeClient events={events} onReceive={this.onEventReceive} />
			</RealtimeProvider>
		);
	}
}
