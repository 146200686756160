import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	editCover: {
		id: 'software.main.board-container.board.card-container.card.context-menu.menu-items.edit-cover.edit-cover',
		defaultMessage: 'Edit cover image',
		description: 'Label for context menu action to open edit card cover popup',
	},
	newLozenge: {
		id: 'software.main.board-container.board.card-container.card.context-menu.menu-items.edit-cover.new-lozenge',
		defaultMessage: 'New',
		description: 'Lozenge label to indicate that a menu action is new',
	},
});
