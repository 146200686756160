/**
 * @generated SignedSource<<1084bead4ae00878b2af2c8348929c25>>
 * @relayHash eed264a6d119fbca146eb1de817b2b96
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 2ca7f0754b23691dc5194f86cd173cbad109ceb27dcdfea5465741efb60afa5b

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { mainJiraBoardFetchCommitDetailsForIssueQuery } from './mainJiraBoardFetchCommitDetailsForIssueQuery.graphql';

const node: PreloadableConcreteRequest<mainJiraBoardFetchCommitDetailsForIssueQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "2ca7f0754b23691dc5194f86cd173cbad109ceb27dcdfea5465741efb60afa5b",
    "metadata": {},
    "name": "mainJiraBoardFetchCommitDetailsForIssueQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
