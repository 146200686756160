import { combineReducers } from 'redux';
import type { Action } from '../../../types.tsx';
import { swimlanesCollapsedReducer } from './collapsed/reducer.tsx';
import { swimlaneModeReducer } from './mode/reducer.tsx';
import type { SwimlaneState } from './types.tsx';

export default combineReducers<SwimlaneState, Action>({
	collapsed: swimlanesCollapsedReducer,
	mode: swimlaneModeReducer,
});
