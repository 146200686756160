import React, { useState, forwardRef, memo, type Ref } from 'react';
import { styled } from '@compiled/react';
import { LoadingButton, type LoadingButtonProps } from '@atlaskit/button';
import { Inline } from '@atlaskit/primitives';
import {
	Show,
	type Breakpoint,
	UNSAFE_useMediaQuery as useMediaQuery,
} from '@atlaskit/primitives/responsive';
import Tooltip from '@atlaskit/tooltip';
import { Tokens } from '@atlassian/jira-custom-theme-constants/src/constants.tsx';

export const RESPONSIVE_THEMED_BUTTON_DEFAULT_TEST_ID = 'responsive-themed-button';

export type ResponsiveThemedButtonBaseProps = {
	// Atlaskit Icon to be displayed on the button. Format is icon={YourIconName}
	icon: React.ReactChild;
	// Label text the expanded button and tooltip on the collapsed (icon-only) button
	label: string;
	// Media query above which the button will be shown in expanded form. Default is lg.
	breakpoint?: Exclude<Breakpoint, 'xxs'>;
};

export type ResponsiveThemedButtonProps =
	// Inherits Atlaskit button props except some exceptions where we are handling something explicitly or not supporting it
	Omit<LoadingButtonProps, 'iconBefore' | 'children' | 'spacing' | 'as'> &
		ResponsiveThemedButtonBaseProps & { as?: keyof JSX.IntrinsicElements };

export const ResponsiveThemedButton = memo(
	forwardRef(({ as, ...props }: ResponsiveThemedButtonProps, ref: Ref<HTMLButtonElement>) => {
		const {
			icon,
			label,
			breakpoint = 'lg',
			appearance,
			testId,
			'aria-label': ariaLabel,
			...restProps
		} = props;

		const mediaQueryBreakpoint = useMediaQuery(`above.${breakpoint}`, (event) =>
			setIsAboveBreakpoint(event.matches),
		);
		const [isAboveBreakpoint, setIsAboveBreakpoint] = useState(mediaQueryBreakpoint?.matches);

		return (
			<Inline>
				<Show below={breakpoint ?? 'lg'}>
					<Tooltip content={label}>
						<CustomButton
							{...restProps}
							as={as}
							ref={!isAboveBreakpoint ? ref : undefined}
							appearance={appearance ?? 'default'}
							iconBefore={icon}
							testId={`${testId ?? RESPONSIVE_THEMED_BUTTON_DEFAULT_TEST_ID}.collapsed`}
							aria-label={ariaLabel || label}
						/>
					</Tooltip>
				</Show>
				<Show above={breakpoint ?? 'lg'}>
					<CustomButton
						{...restProps}
						as={as}
						ref={isAboveBreakpoint ? ref : undefined}
						appearance={appearance ?? 'default'}
						iconBefore={icon}
						testId={`${testId ?? RESPONSIVE_THEMED_BUTTON_DEFAULT_TEST_ID}.expanded`}
						aria-label={ariaLabel || label}
					>
						{label}
					</CustomButton>
				</Show>
			</Inline>
		);
	}),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CustomButton = styled(LoadingButton)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-background-brand-bold': Tokens.COLOR_BACKGROUND_BRAND_BOLD,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-background-brand-bold-hovered': Tokens.COLOR_BACKGROUND_BRAND_BOLD_HOVERED,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-background-brand-bold-pressed': Tokens.COLOR_BACKGROUND_BRAND_BOLD_PRESSED,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-background-disabled': Tokens.COLOR_BACKGROUND_DISABLED,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-background-selected': Tokens.COLOR_BACKGROUND_SELECTED,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-background-neutral': Tokens.COLOR_BACKGROUND_NEUTRAL,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-background-neutral-hovered': Tokens.COLOR_BACKGROUND_NEUTRAL_HOVERED,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-background-neutral-pressed': Tokens.COLOR_BACKGROUND_NEUTRAL_PRESSED,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-background-neutral-subtle-hovered': Tokens.COLOR_BACKGROUND_NEUTRAL_SUBTLE_HOVERED,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-background-neutral-subtle-pressed': Tokens.COLOR_BACKGROUND_NEUTRAL_SUBTLE_PRESSED,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-icon-subtle': Tokens.COLOR_TEXT_SUBTLE,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-icon': Tokens.COLOR_TEXT,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-icon-selected': Tokens.COLOR_TEXT_SELECTED,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-link': Tokens.COLOR_LINK,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-link-pressed': Tokens.COLOR_LINK_PRESSED,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-text': Tokens.COLOR_TEXT,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-text-disabled': Tokens.COLOR_TEXT_DISABLED,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-text-inverse': Tokens.COLOR_TEXT_INVERSE,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-text-selected': Tokens.COLOR_TEXT_SELECTED,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-text-subtle': Tokens.COLOR_TEXT_SUBTLE,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-border': Tokens.COLOR_BORDER,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	'--ds-border-selected': Tokens.COLOR_BORDER_SELECTED,
});
