import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	editButtonLabel: {
		id: 'software.main.board-container.board.card-container.card.story-point-field.edit-button-label',
		defaultMessage: 'Story point estimate: {storyPoints}',
		description: 'Screen reader output for story point estimate field',
	},
	tooltipForEpicEstimate: {
		id: 'software.main.board-container.board.card-container.card.story-point-field.tooltip-for-epic-estimate',
		defaultMessage: 'This issue isn’t included in capacity calculations',
		description: 'Tooltip for the epic estimate in the program board',
	},
	tooltipForEpicEstimateIssueTermRefresh: {
		id: 'software.main.board-container.board.card-container.card.story-point-field.tooltip-for-epic-estimate-issue-term-refresh',
		defaultMessage: 'This issue isn’t included in capacity calculations',
		description: 'Tooltip for the epic estimate in the program board',
	},
});
