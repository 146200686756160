import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	childlessTooltip: {
		id: 'software.main.board-container.board.swimlane.childless-tooltip',
		defaultMessage: 'Issues without a subtask or visible parent',
		description:
			'Tooltip content for last swimlane summary categorising issues which do not have subtasks and visible parent ',
	},
	childlessTooltipIssueTermRefresh: {
		id: 'software.main.board-container.board.swimlane.childless-tooltip-issue-term-refresh',
		defaultMessage: 'Work items without a subtask or visible parent',
		description:
			'Tooltip content for last swimlane summary categorising issues which do not have subtasks and visible parent ',
	},
	lozengeButtonLabel: {
		id: 'software.main.board-container.board.swimlane.lozeng-button-label',
		defaultMessage: 'More info about team details',
		description: 'Label for the button that shows the lozenge tooltip',
	},
});
