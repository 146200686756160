import 'rxjs/add/operator/map';
import type { Observable } from 'rxjs/Observable';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import type { JQLSwimlaneData } from '../../model/work/work-types.tsx';
import { fetchTMPFilteredCardIds } from '../../rest/filtered-cards/index.tsx';
import { fetchCMPBoardScopeData } from '../board-scope-graphql/non-critical/mobile-rest/index.tsx';
import type { ServiceContext } from '../service-context.tsx';

type FetchFilteredCardsResult = {
	cardIds: string[];
	jqlSwimlanes?: JQLSwimlaneData[];
};

/**
 * Runs a set of custom filter IDs custom filters against a set of card IDs
 */
export function fetchFilteredCardIds(
	ctx: ServiceContext,
	{
		issueIds,
		customFilterIds,
	}: {
		issueIds: IssueId[];
		customFilterIds: string[];
	},
): Observable<FetchFilteredCardsResult> {
	if (ctx.isCMPBoard) {
		return fetchCMPBoardScopeData(ctx.boardId, {
			activeQuickFilters: customFilterIds,
			projectType: ctx.projectType,
		}).map(({ issues, issueChildren, jqlSwimlanes }) => {
			const resultSet = new Set([...issues, ...issueChildren].map(({ id }) => id));
			return {
				cardIds: issueIds.filter((id) => resultSet.has(id)).map((id) => String(id)),
				jqlSwimlanes,
			};
		});
	}

	return fetchTMPFilteredCardIds({ boardId: ctx.boardId, issueIds, customFilterIds }).map(
		(cardIds) => ({ cardIds }),
	);
}
