import type { Observable } from 'rxjs/Observable';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';

export interface BoardDeferredDataResponse {
	swimlanesData?: {
		rapidViewId: number;
		customSwimlanesData: {
			swimlanes: {
				id: number;
				query: string;
			}[];
		};
	};
	sprintsData?: {
		rapidViewId: number;
		sprints: {
			id: number;
			canUpdateSprint: boolean;
		}[];
	};
	workflowData?: {
		isUsingSimplifiedWorkflow: boolean;
	};
}

export interface BoardDeferredDataParams {
	rapidViewId: string;
	fields: string[];
	sprintIds?: number[];
}

export function fetchBoardDeferredData({
	rapidViewId,
	fields,
	sprintIds,
}: BoardDeferredDataParams): Observable<BoardDeferredDataResponse> {
	return fetchJson$('/rest/greenhopper/1.0/xboard/work', {
		method: 'POST',
		body: JSON.stringify({
			rapidViewId,
			fields,
			sprintIds,
		}),
	});
}
