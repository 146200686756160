import uuid from 'uuid';
import {
	SUCCESS_STYLE,
	FAILURE_STYLE,
	PAUSED_BREACHED_STYLE,
	PAUSED_UNDER_THIRTY_REMAINING_STYLE,
	ONGOING_BREACHED_STYLE,
	PAUSED_STYLE,
	ONGOING_UNDER_THIRTY_REMAINING_STYLE,
	ONGOING_STYLE,
} from './constants.tsx';
import { SlaFieldTransformer } from './queues-common.tsx';
import {
	type GenericFieldDataTransformerInput,
	type SlaResponse,
	type SlaStatus,
	COMPLETED,
	PAUSED,
} from './types.tsx';

export const getStyles = (
	isBreached: boolean,
	status: SlaStatus,
	timeRemainingInMinutes: number,
) => {
	if (status === COMPLETED && !isBreached) {
		return SUCCESS_STYLE;
	}
	if (status === COMPLETED && isBreached) {
		return FAILURE_STYLE;
	}
	if (status === PAUSED) {
		if (timeRemainingInMinutes < 0) {
			return PAUSED_BREACHED_STYLE;
		}
		if (timeRemainingInMinutes < 30) {
			return PAUSED_UNDER_THIRTY_REMAINING_STYLE;
		}
		return PAUSED_STYLE;
	}
	if (timeRemainingInMinutes < 0) {
		return ONGOING_BREACHED_STYLE;
	}
	if (timeRemainingInMinutes < 30) {
		return ONGOING_UNDER_THIRTY_REMAINING_STYLE;
	}
	return ONGOING_STYLE;
};

export const createUUID = () => {
	//  Here session is one scroll, session is destroyed on component unmount.
	let sessionUuid: string | undefined;
	return (reset = false): string => {
		if (reset) sessionUuid = undefined;
		if (sessionUuid !== undefined) {
			return sessionUuid;
		}
		sessionUuid = uuid.v4();
		return sessionUuid;
	};
};

export const transformSlaResponse = (slaProps: SlaResponse) => {
	const props: GenericFieldDataTransformerInput<SlaResponse> = {
		storedValue: {
			// @ts-expect-error Type 'SlaFieldResponse' is not assignable to type 'Sla | SlaError'.ts(2322)
			value: slaProps,
		},
	};

	return SlaFieldTransformer(props);
};
