import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { styled } from '@compiled/react';
import {
	type Edge,
	attachClosestEdge,
	extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { CARD_DND_TYPE } from '@atlassian/jira-platform-board-kit/src/common/constants/drag-drop/index.tsx';
import {
	isIssueEntryGroup,
	type IssueEntry,
	type IssueEntryGroup,
	DEFAULT_CARD_HEIGHT,
} from '@atlassian/jira-software-board-common/src/index.tsx';
import { useVirtual } from '@atlassian/jira-software-fast-virtual/src/services/use-virtual/index.tsx';

import { useAutobatch } from '@atlassian/jira-software-react-scheduler/src/ui/autobatch/index.tsx';

import { useBoardSelector } from '../../../../state/index.tsx';
import { getIssueChildren } from '../../../../state/selectors/issue-children/index.tsx';
import { getIssueById } from '../../../../state/selectors/issue/issue-selectors.tsx';
import {
	getMissingParents,
	isBoardRankable,
} from '../../../../state/selectors/software/software-selectors.tsx';
import { platformIssueSelector } from '../../../../state/selectors/work/work-selectors.tsx';
import CardContainer, { type Props as CardContainerProps } from '../card-container/index.tsx';
import { useRowRenderer } from '../virtual-board/fast-virtual-list/row-renderer/index.tsx';
import { useCardHeightCache } from '../virtual-board/fast-virtual-list/use-card-height-cache/index.tsx';
import type { RenderCardCallback } from '../virtual-board/types.tsx';

type CardGroupSelectorProps = {
	parentKey: string;
	parentSummary: string;
	isRankable: boolean;
};

export type CardGroupProps = Omit<CardContainerProps, 'id'> & {
	issueIdGroup: IssueEntryGroup;
	offsetTop: number;
} & CardGroupSelectorProps;

const CARD_PADDING = 14;
const CARD_GROUP_HEADER_HEIGHT_WITH_PARENT = 3;
const CARD_GROUP_HEADER_HEIGHT = 29;

export const CardGroup = ({
	issueIdGroup: { issueIds, isAttachedToParent, parentId },
	parentKey,
	parentSummary,
	isRankable,
	index: cardGroupIndex,
	offsetTop,
	...props
}: CardGroupProps) => {
	const autobatch = useAutobatch();
	const containerRef = useRef<HTMLDivElement>(null);
	const [closestEdge, setClosestEdge] = useState<Edge | null>(null);
	const batchSetClosestEdge = useCallback(
		(edge: Edge | null) => autobatch(() => setClosestEdge(edge)),
		[autobatch],
	);
	const getPlatformIssueById = useBoardSelector(platformIssueSelector);
	const issueChildren = useBoardSelector(getIssueChildren);

	const cardHeightCache = useCardHeightCache();
	const cacheOptions = useMemo(
		() => ({
			cache: cardHeightCache,
			getCacheKey: (index: number) => issueIds[index],
		}),
		[issueIds, cardHeightCache],
	);
	const { rows, totalSize, isScrolling } = useVirtual({
		rowCount: issueIds.length,
		getDefaultRowSize: () => DEFAULT_CARD_HEIGHT,
		offsetTop:
			offsetTop +
			(isAttachedToParent ? CARD_GROUP_HEADER_HEIGHT_WITH_PARENT : CARD_GROUP_HEADER_HEIGHT),
		cacheOptions,
	});

	const cardContainerProps = useRef(props);
	cardContainerProps.current = props;
	const renderCard: RenderCardCallback = useCallback(
		(issueEntry, index) =>
			!isIssueEntryGroup(issueEntry) && (
				<CardContainer
					{...cardContainerProps.current}
					key={issueEntry.issueId}
					id={issueEntry.issueId}
					index={index}
					groupParentId={parentId}
				/>
			),
		[parentId],
	);
	const issueEntries = useMemo(
		() => issueIds.map<IssueEntry>((issueId) => ({ type: 'issue', issueId })),
		[issueIds],
	);

	const renderer = useRowRenderer({
		cardEntries: issueEntries,
		footer: null,
		footerIndex: issueEntries.length,
		placeholder: null,
		renderCard,
		showFooter: false,
	});

	useEffect(
		() =>
			dropTargetForElements({
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				element: containerRef.current as HTMLElement,
				getIsSticky: () => true,
				getData: ({ input, element }) => {
					const issueChild = getPlatformIssueById(issueIds[0]);
					return attachClosestEdge(
						{
							type: CARD_DND_TYPE,
							cardId: parentId,
							cardIndex: cardGroupIndex,
							columnId: issueChild?.columnId,
							swimlaneId: issueChild?.swimlaneId,
						},
						{
							input,
							element,
							allowedEdges: ['top', 'bottom'],
						},
					);
				},
				canDrop: (args) =>
					args.source.data.type === CARD_DND_TYPE &&
					isRankable &&
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					!((args.source.data.cardId as number) in issueChildren),
				onDrag: ({ self }) => {
					batchSetClosestEdge(extractClosestEdge(self.data));
				},
				onDragEnter: ({ self }) => {
					batchSetClosestEdge(extractClosestEdge(self.data));
				},
				onDragLeave: () => batchSetClosestEdge(null),
				onDrop: () => batchSetClosestEdge(null),
			}),
		[
			issueChildren,
			issueIds,
			parentId,
			cardGroupIndex,
			getPlatformIssueById,
			isRankable,
			batchSetClosestEdge,
		],
	);

	return (
		<CardGroupContainer
			ref={containerRef}
			isAttachedToParent={isAttachedToParent}
			data-testid="software-board.board-container.board.card-group.card-group"
		>
			{!isAttachedToParent && (
				<Tooltip content={parentSummary}>
					<CardGroupHeader
						data-testid="software-board.board-container.board.card-group.card-group-header"
						aria-hidden
					>
						<CardGroupHeaderKey>{parentKey}</CardGroupHeaderKey>
						<CardGroupHeaderSummary>{parentSummary}</CardGroupHeaderSummary>
					</CardGroupHeader>
				</Tooltip>
			)}

			<CardGroupDroppableContainer
				as={fg('jsw_board_card_group_a11y_fix') ? 'ul' : undefined}
				// eslint-disable-next-line jira/react/no-style-attribute
				style={{ minHeight: totalSize }}
			>
				{rows.map(({ index, top, forceRemeasure, measure }) =>
					renderer({
						index,
						isScrolling,
						forceRemeasure,
						measure,
						offsetTop: top - offsetTop,
						style: {
							position: 'absolute',
							top: top - offsetTop,
							width: `calc(100% - ${CARD_PADDING}px)`,
							contain: 'style',
							marginTop: '0px',
						},
					}),
				)}
			</CardGroupDroppableContainer>

			{closestEdge && <CardGroupDropIndicator edge={closestEdge} />}
		</CardGroupContainer>
	);
};

const ConnectedCardGroup = (props: Omit<CardGroupProps, keyof CardGroupSelectorProps>) => {
	const parent = useBoardSelector((state) => {
		const { parentId } = props.issueIdGroup;
		return getIssueById(state, parentId) || getMissingParents(state)[parentId];
	});

	const isRankable = useBoardSelector(isBoardRankable);
	return (
		!!parent && (
			<CardGroup
				{...props}
				parentKey={parent.key}
				parentSummary={parent.summary}
				isRankable={isRankable}
			/>
		)
	);
};

export default ConnectedCardGroup;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardGroupContainer = styled.div<{ isAttachedToParent: boolean }>({
	padding: token('space.075', '6px'),
	marginBottom: token('space.025', '2px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.accent.gray.subtlest', colors.N40),
	borderWidth: '1px',
	borderStyle: 'solid',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderColor: `${token('color.border', colors.N40)}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderRadius: ({ isAttachedToParent }) => (isAttachedToParent ? '0 0 6px 6px' : '6px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginTop: ({ isAttachedToParent }) =>
		isAttachedToParent ? `${token('space.negative.050', '-4px')}` : `${token('space.025', '2px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardGroupDroppableContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	margin: 0,
	padding: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardGroupHeaderKey = styled.span({
	marginRight: token('space.050', '4px'),
	font: token('font.body.UNSAFE_small'),
	fontWeight: token('font.weight.bold'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardGroupHeaderSummary = styled.span({
	flex: 1,
	display: 'inline-block',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardGroupDropIndicator = styled.div<{ edge: Edge }>({
	position: 'absolute',
	left: 0,
	right: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	bottom: ({ edge }) => (edge === 'bottom' ? '-1px' : undefined),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	top: ({ edge }) => (edge === 'top' ? '-1px' : undefined),
	height: '2px',
	zIndex: 2,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.icon.brand', colors.B300),
	pointerEvents: 'none',
	'&::before': {
		content: "''",
		position: 'absolute',
		height: '4px',
		width: '4px',
		top: '-3px',
		left: '-7px',
		borderRadius: '50%',
		borderWidth: '2px',
		borderStyle: 'solid',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		borderColor: `${token('color.border.brand', colors.B300)}`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardGroupHeader = styled.div({
	marginBottom: token('space.050', '4px'),
	color: token('color.text.subtlest', '#626F8'),
	font: token('font.body.UNSAFE_small'),
	display: 'flex',
	flexWrap: 'nowrap',
});
