import React from 'react';
import { styled } from '@compiled/react';
import { borderRadius } from '@atlassian/jira-common-styles/src/main.tsx';

type Props = {
	src: string;
	alt: string;
	size: string;
};

const Icon = ({ src, alt, size }: Props) => (
	<IconImage src={src} alt={alt} width={size} height={size} />
);

Icon.defaultProps = { alt: '', size: '16px' };

export default Icon;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconImage = styled.img({
	flex: '0 0 auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius}px`,
});
