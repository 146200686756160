import { useEffect, useState } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { JiraSoftwareBoardAri } from '@atlassian/ari/jira-software/board';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useFlagService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import {
	fireTrackAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import type { limitReachedFlagTotalIssueCountQuery } from '@atlassian/jira-relay/src/__generated__/limitReachedFlagTotalIssueCountQuery.graphql';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import type { BaseFlagProps } from '../../common/ui/types.tsx';
import { useFlagActions } from '../../common/ui/utils.tsx';
import messages from './messages.tsx';

const LIMIT_REACHED_FLAG_ID = 'jsw-issue-limit-reached-flag';

type LimitReachedFlagProps = BaseFlagProps & {
	isProjectAdmin: boolean;
};

export const LimitReachedFlag = ({
	boardId,
	isProjectAdmin,
	isCMP,
	projectKey,
	views: flagViews,
}: LimitReachedFlagProps) => {
	const [flagShown, setFlagShown] = useState(false);

	const { formatMessage } = useIntl();
	const { showFlag } = useFlagService();
	const { cloudId } = useTenantContext();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const views = flagViews.map((flagView) => flagView.view);
	const shouldFetchTotalCount = views.length === 1 && ['BACKLOG', 'BOARD'].includes(views[0]);

	const data = useLazyLoadQuery<limitReachedFlagTotalIssueCountQuery>(
		graphql`
			query limitReachedFlagTotalIssueCountQuery(
				$cloudId: ID!
				$issueSearchInput: JiraIssueSearchInput!
				$skip: Boolean!
			) {
				jira @skip(if: $skip) {
					issueSearchTotalCount(cloudId: $cloudId, issueSearchInput: $issueSearchInput)
						@optIn(to: ["JiraTotalIssueCount"])
				}
			}
		`,
		{
			cloudId,
			issueSearchInput: {
				customInput: {
					jiraSoftwareInput: {
						context: views[0] === 'BOARD' ? 'BOARD' : 'BACKLOG',
						jiraEntityId: JiraSoftwareBoardAri.create({
							boardId: boardId.toString(),
							siteId: cloudId,
						}).toString(),
					},
				},
			},
			skip: !shouldFetchTotalCount,
		},
		{ fetchPolicy: 'network-only' },
	);

	const totalCount = data.jira?.issueSearchTotalCount;

	const flagActions = useFlagActions({ isProjectAdmin, isCMP, projectKey, flagType: 'reached' });

	useEffect(() => {
		if (shouldFetchTotalCount && typeof totalCount !== 'number') {
			return;
		}

		const getFlagTitle = () => {
			if (isCMP) {
				return views[0] === 'BOARD'
					? messages.boardLimitReachedFlagTitle
					: messages.backlogLimitReachedFlagTitle;
			}

			if (views.includes('BACKLOG') && views.includes('SPRINTS')) {
				return messages.tmpBacklogSprintsLimitReachedFlagTitle;
			}
			if (views.includes('BACKLOG') && views.includes('BOARD')) {
				return messages.tmpBacklogBoardLimitReachedFlagTitle;
			}
			if (views.includes('BACKLOG')) {
				return messages.tmpBacklogLimitReachedFlagTitle;
			}
			if (views.includes('SPRINTS')) {
				return messages.tmpSprintsLimitReachedFlagTitle;
			}
			if (views.includes('BOARD')) {
				return messages.boardLimitReachedFlagTitle;
			}

			return messages.boardLimitReachedFlagTitle;
		};

		const getFlagMessage = () => {
			if (isCMP) {
				if (views[0] === 'BOARD') {
					const boardLimitReachedAdminFlagBodyMessage = expVal(
						'issue-terminology-refresh-m2-replace',
						'isEnabled',
						false,
					)
						? messages.boardLimitReachedAdminFlagBodyIssueTermRefresh
						: messages.boardLimitReachedAdminFlagBody;

					const boardLimitReachedEndUserFlagBodyMessage = expVal(
						'issue-terminology-refresh-m2-replace',
						'isEnabled',
						false,
					)
						? messages.boardLimitReachedEndUserFlagBodyIssueTermRefresh
						: messages.boardLimitReachedEndUserFlagBody;

					return isProjectAdmin
						? boardLimitReachedAdminFlagBodyMessage
						: boardLimitReachedEndUserFlagBodyMessage;
				}

				const backlogLimitReachedAdminFlagBodyMessage = expVal(
					'issue-terminology-refresh-m2-replace',
					'isEnabled',
					false,
				)
					? messages.backlogLimitReachedAdminFlagBodyIssueTermRefresh
					: messages.backlogLimitReachedAdminFlagBody;

				const backlogLimitReachedEndUserFlagBodyMessage = expVal(
					'issue-terminology-refresh-m2-replace',
					'isEnabled',
					false,
				)
					? messages.backlogLimitReachedEndUserFlagBodyIssueTermRefresh
					: messages.backlogLimitReachedEndUserFlagBody;

				return isProjectAdmin
					? backlogLimitReachedAdminFlagBodyMessage
					: backlogLimitReachedEndUserFlagBodyMessage;
			}

			if (views.includes('BACKLOG') && views.includes('SPRINTS')) {
				return expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.tmpBacklogSprintsLimitReachedFlagBodyIssueTermRefresh
					: messages.tmpBacklogSprintsLimitReachedFlagBody;
			}
			if (views.includes('BACKLOG') && views.includes('BOARD')) {
				return expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.tmpBacklogBoardLimitReachedFlagBodyIssueTermRefresh
					: messages.tmpBacklogBoardLimitReachedFlagBody;
			}
			if (views.includes('BACKLOG')) {
				return expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.tmpBacklogLimitReachedFlagBodyIssueTermRefresh
					: messages.tmpBacklogLimitReachedFlagBody;
			}
			if (views.includes('SPRINTS')) {
				return expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.tmpSprintsLimitReachedFlagBodyIssueTermRefresh
					: messages.tmpSprintsLimitReachedFlagBody;
			}
			if (views.includes('BOARD')) {
				return expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.tmpBoardLimitReachedFlagBodyIssueTermRefresh
					: messages.tmpBoardLimitReachedFlagBody;
			}

			return expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? messages.tmpBoardLimitReachedFlagBodyIssueTermRefresh
				: messages.tmpBoardLimitReachedFlagBody;
		};

		const flagTitle = getFlagTitle();
		const flagDescription = getFlagMessage();

		if (!flagShown) {
			showFlag({
				id: LIMIT_REACHED_FLAG_ID,
				type: 'warning',
				title: flagTitle,
				description: formatMessage(flagDescription, { issueCount: totalCount }),
				isAutoDismiss: false,
				actions: flagActions,
				onDismissed: (_, analyticsEvent) => {
					if (analyticsEvent) {
						fireUIAnalytics(analyticsEvent, 'jswIssueLimitReachedFlag', {
							totalIssueCount: totalCount,
						});
					}
				},
			});

			fireTrackAnalytics(createAnalyticsEvent({}), 'flag shown', 'jswIssueLimitReachedFlag', {
				totalIssueCount: totalCount,
			});

			setFlagShown(true);
		}
	}, [
		formatMessage,
		showFlag,
		isProjectAdmin,
		flagActions,
		totalCount,
		flagShown,
		createAnalyticsEvent,
		isCMP,
		shouldFetchTotalCount,
		views,
	]);

	return null;
};
