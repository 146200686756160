import React, { type ComponentPropsWithoutRef, useCallback, useMemo, memo } from 'react';
import noop from 'lodash/noop';
import { useIntl } from '@atlassian/jira-intl';
import { toProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { CheckboxSelectEntry } from '../../../common/types.tsx';
import ListFilter from '../../../common/ui/list/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const IssueProjectFilter = ({
	isOpen = false, // only used for VR testing purposes
	isNested = false,
	issueProjects = {},
	onChange = noop,
	selectedProjects = [],
}: Props) => {
	const { formatMessage } = useIntl();
	const values = useMemo(
		(): CheckboxSelectEntry[] =>
			Object.values(issueProjects)
				.map(({ avatar, id, name }) => ({
					iconUrl: avatar,
					label: name,
					value: String(id),
				}))
				.sort((a, b) => a.label.localeCompare(b.label)),
		[issueProjects],
	);

	const onChangeValue = useCallback(
		(entries: CheckboxSelectEntry[]) => {
			const analyticAttributes = { isNested };
			onChange(
				entries.map(({ value }) => toProjectId(value)),
				analyticAttributes,
			);
		},
		[onChange, isNested],
	);

	return (
		<ListFilter
			isOpen={isOpen}
			isNested={isNested}
			label={formatMessage(messages.label)}
			onChange={onChangeValue}
			selectedValues={selectedProjects.map(String)}
			values={values}
		/>
	);
};

IssueProjectFilter.displayName = 'IssueProjectFilter';
IssueProjectFilter.whyDidYouRender = true;

export default memo<
	JSX.LibraryManagedAttributes<
		typeof IssueProjectFilter,
		ComponentPropsWithoutRef<typeof IssueProjectFilter>
	>
>(IssueProjectFilter);
