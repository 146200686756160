import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import { CAN_RELEASE } from '../../../model/permission/permission-types.tsx';
import {
	type Version,
	type FilteredVersionsType,
	type VersionMenuItemEntities,
	type VersionMenuItem,
	ENABLED,
	NO_AVAILABLE_ISSUE_OR_VERSION,
	NO_PERMISSION,
} from '../../../model/version/version-types.tsx';
import { getPermissionsSelector } from '../board/board-permissions-selectors.tsx';
import { getColumns } from '../column/column-selectors.tsx';
import { boardIssuesSelector } from '../issue/board-issue-selectors.tsx';
import {
	getVersionData,
	getIssueProjects,
	getOrderedColumnIds,
} from '../software/software-selectors.tsx';
import { getFilteredIssueIdsByColumn } from '../work/work-selectors.tsx';

export const activeVersionsMenuItemEntitiesSelector = createSelector(
	[getIssueProjects, getVersionData],
	(projects, versionData): VersionMenuItemEntities => {
		const { versionsPerProject } = versionData;
		const versionMenuItemEntities: VersionMenuItemEntities = {};

		Object.keys(versionsPerProject).flatMap((id: string) =>
			versionsPerProject[id]
				.filter((version: Version) => version.released === false)
				.forEach((activeVersion: Version) => {
					versionMenuItemEntities[activeVersion.id] = {
						id: activeVersion.id.toString(),
						name: activeVersion.name,
						projectKey: projects[id]?.key,
					};
				}),
		);

		return versionMenuItemEntities;
	},
);

export const getFilteredVersionsSelector = createSelector(
	[
		activeVersionsMenuItemEntitiesSelector,
		getOrderedColumnIds,
		getColumns,
		getFilteredIssueIdsByColumn,
		boardIssuesSelector,
		getPermissionsSelector,
	],
	(
		activeVersions,
		orderedColumnIds,
		allColumns,
		filteredIssueIdsByColumn,
		allIssues,
		boardPermissions,
	): FilteredVersionsType => {
		// always release issues in the right most column
		const lastColumnId = orderedColumnIds[orderedColumnIds.length - 1];
		const lastColumnName = allColumns[lastColumnId]?.name;

		const canRelease = boardPermissions[CAN_RELEASE];
		if (!canRelease) {
			return {
				releaseState: NO_PERMISSION,
				versionMenuItems: [],
				hasIssuesWithoutVersion: false,
				lastColumnName,
			};
		}

		const filteredIssueIds = filteredIssueIdsByColumn[lastColumnId];
		if (!filteredIssueIds || filteredIssueIds.length === 0 || !lastColumnId) {
			return {
				releaseState: NO_AVAILABLE_ISSUE_OR_VERSION,
				versionMenuItems: [],
				hasIssuesWithoutVersion: false,
				lastColumnName,
			};
		}

		const hasIssuesWithoutVersion = filteredIssueIds.some(
			(issueId) => !allIssues[issueId]?.fixVersions?.length,
		);

		const versionMenuItems = isEmpty(activeVersions)
			? new Set<VersionMenuItem>()
			: new Set(
					filteredIssueIds
						.flatMap((issueId) => allIssues[issueId]?.fixVersions ?? [])
						.map((versionId) => activeVersions[versionId])
						.filter((version) => version != null),
				);

		return {
			releaseState: ENABLED,
			versionMenuItems: [...versionMenuItems],
			hasIssuesWithoutVersion,
			lastColumnName,
		};
	},
);
