import React, { useMemo } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { LinkItem } from '@atlaskit/menu';
import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useContainerContext } from '@atlassian/jira-providers-container-context/src/main.tsx';
import {
	type ContainerContext,
	isUserContext,
	isProjectContext,
} from '@atlassian/jira-providers-container-context/src/types.tsx';
import { useBoardSelector } from '../../../../state/index.tsx';
import messages from './messages.tsx';

export type ConfigureBoardMenuItemProps = {};

/**
 * Reads the CMP selected tab from localStorage. This is set by the legacy FE on:
 *
 * `jira-components/jira-legacy-frontend/greenhopper-frontend/src/main/resources/jira-agile-module/includes/js/rapid/state/RapidBoardState.js#389,408,414,431`
 *
 * In case there is an error reading/parsing the value of it its missing we will
 * use the 'default' tab.
 */
export const readSelectedConfigTab = (): string => {
	const defaultValue = 'default';
	try {
		const value = localStorage.getItem('selectedConfigTab');
		return value ? JSON.parse(value) : defaultValue;
	} catch (err) {
		return defaultValue;
	}
};

export function buildConfigureBoardUrl({
	containerData,
	boardId,
	selectedConfigTab,
}: {
	containerData: ContainerContext | null;
	boardId: string;
	selectedConfigTab: string;
}): string {
	// containerData should never be null, but we are being defensive here.
	if (containerData == null) return '';

	if (isProjectContext(containerData) && containerData.project.type === SERVICE_DESK_PROJECT) {
		return `/jira/servicedesk/projects/${containerData.project.key}/boards/${boardId}/?config=${selectedConfigTab}`;
	}

	return isUserContext(containerData)
		? `/jira/people/${containerData.user.accountId}/boards/${boardId}/?config=${selectedConfigTab}`
		: `/jira/software/c/projects/${containerData.project.key}/boards/${boardId}/?config=${selectedConfigTab}`;
}

function useConfigureBoardUrl() {
	const boardId = useBoardSelector((state) => state.configuration.rapidViewId);

	// Read the current settings tab from localStorage to redirect.
	const selectedConfigTab = useMemo(readSelectedConfigTab, []);
	const [{ data: containerData }] = useContainerContext();
	const configureBoardUrl = useMemo(
		() =>
			buildConfigureBoardUrl({
				containerData,
				boardId,
				selectedConfigTab,
			}),
		[boardId, containerData, selectedConfigTab],
	);

	return configureBoardUrl;
}

const ConfigureBoardMenuItem = () => {
	const { formatMessage } = useIntl();

	const configureBoardUrl = useConfigureBoardUrl();

	return fg('make_board_menu_popup') ? (
		<LinkItem
			href={configureBoardUrl}
			data-testid="software-board.header.menu.configure-board.custom-item"
		>
			{formatMessage(messages.displayText)}
		</LinkItem>
	) : (
		<DropdownItem
			href={configureBoardUrl}
			data-testid="software-board.header.menu.configure-board.custom-item"
		>
			{formatMessage(messages.displayText)}
		</DropdownItem>
	);
};

export default ConfigureBoardMenuItem;
