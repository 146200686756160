import {
	HIDDEN,
	VISIBLE,
} from '@atlassian/jira-platform-inline-card-create/src/common/constants.tsx';
import type {
	TriggerAppearance,
	CardType as IccCardType,
} from '@atlassian/jira-platform-inline-card-create/src/index.tsx';
import type { ColumnId } from '../../../../../model/column/column-types.tsx';
import type { InlineCardCreateConfig } from '../../../../../model/inline-create/inline-card-create-types.tsx';
import type { CardType, CardTypeKey } from '../../../../../model/software/software-types.tsx';

export type ShouldDisplayNudgeParams = {
	isTheFirstCurrentSwimlaneOpen: boolean;
	triggerAppearance: TriggerAppearance;
	cardIndex: number;
	canShowIccNudge: boolean;
	isDismissed: boolean;
};

export const shouldDisplayNudge = ({
	isTheFirstCurrentSwimlaneOpen,
	triggerAppearance,
	cardIndex,
	canShowIccNudge,
	isDismissed,
}: ShouldDisplayNudgeParams): boolean => {
	if (!isTheFirstCurrentSwimlaneOpen || !canShowIccNudge) return false;
	if (isDismissed) return false;

	const displayNudgeInBetweenCards = triggerAppearance === HIDDEN && cardIndex === 2;

	const displayNudgeOnVisibleButton = triggerAppearance === VISIBLE;

	return displayNudgeInBetweenCards || displayNudgeOnVisibleButton;
};

export const mapInlineCardCreateCardType = ({ id, name, iconUrl }: CardType): IccCardType => ({
	id: Number(id),
	name,
	iconUrl,
	isDisabled: false,
});

export const isPartialIccMode = (
	columnId: ColumnId,
	cardTypeId: CardTypeKey,
	config?: InlineCardCreateConfig,
): boolean =>
	config?.mode === 'PARTIAL' ||
	config?.columns?.[columnId]?.mode === 'PARTIAL' ||
	config?.columns?.[columnId]?.issueTypes?.[cardTypeId] === 'PARTIAL';
