import type { FetchPolicy } from 'react-relay';
import type { EntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';

export const DevInfoTypes = Object.freeze({
	BRANCH: 'branch',
	COMMIT: 'commit',
	PULLREQUEST: 'pullrequest',
	DECLINED: 'declined',
	MERGED: 'merged',
	DEPLOYMENT: 'deployment',
	DESIGN: 'design',
});

export const DevInfoTypeMap = {
	[DevInfoTypes.BRANCH]: 'branch',
	[DevInfoTypes.COMMIT]: 'commit',
	[DevInfoTypes.PULLREQUEST]: 'pull request',
	[DevInfoTypes.MERGED]: 'pull request',
	[DevInfoTypes.DECLINED]: 'pull request',
	[DevInfoTypes.DEPLOYMENT]: 'deployment',
	[DevInfoTypes.DESIGN]: 'design',
} as const;

export const ButtonStatus = Object.freeze({
	ACTIVE: 'active',
	HOVER: 'hover',
	DEFAULT: 'default',
});

export type ButtonStatusType = (typeof ButtonStatus)[keyof typeof ButtonStatus];

export type DevInfoType = (typeof DevInfoTypes)[keyof typeof DevInfoTypes];

export type QueryOptions = {
	fetchKey?: string | number;
	fetchPolicy?: FetchPolicy;
};

export type PopupOpenTrigger = 'click' | 'hover';

export type PopupTriggerProps = {
	issueAri: Ari;
	trigger?: PopupOpenTrigger;
	devInfoType?: DevInfoType;
	queryOptions?: QueryOptions;
	onReady: () => void;
	onQueryError?: () => void;
	scopeId?: string;
	modalTriggerRef?: EntryPointButtonTrigger;
};
