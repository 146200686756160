import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import isNumber from 'lodash/isNumber';
import { Observable } from 'rxjs/Observable';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import {
	convertCapacity,
	convertCapacityFromPlanningUnitToSeconds,
} from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/utils.tsx';
import {
	updateIterationCapacity,
	removeIterationCapacity,
} from '../../rest/team/update-iteration-capacity/index.tsx';
import {
	type UpdateIterationCapacityRequestAction,
	type IterationCapacityCompletePayload,
	UPDATE_ITERATION_CAPACITY_REQUEST,
	updateIterationCapacitySuccess,
	updateIterationCapacityFailed,
} from '../../state/actions/team/index.tsx';
import {
	getPlanId,
	rapidViewIdSelector,
} from '../../state/selectors/software/software-selectors.tsx';
import { getTeamById } from '../../state/selectors/team/team-selectors.tsx';
import type { ActionsObservable, MiddlewareAPI } from '../../state/types.tsx';

export function updateIterationCapacityEpic(action$: ActionsObservable, store: MiddlewareAPI) {
	return action$
		.ofType(UPDATE_ITERATION_CAPACITY_REQUEST)
		.mergeMap((action: UpdateIterationCapacityRequestAction) => {
			const {
				payload: {
					capacity,
					planningUnit,
					workingHoursPerDay,
					iterationId,
					teamId,
					totalCapacity,
					defaultCapacity,
				},
			} = action;
			const { planningStyle } = getTeamById(store.getState())(teamId);
			const planId = getPlanId(store.getState());
			const incrementId = rapidViewIdSelector(store.getState());
			const currentCapacity = totalCapacity ?? defaultCapacity;

			if (capacity !== '') {
				const newCapacity = parseFloat(capacity);
				const convertedCapacity =
					(isNumber(currentCapacity) &&
						convertCapacity(currentCapacity, planningUnit, workingHoursPerDay)) ||
					0;

				if (Number.isNaN(newCapacity) || convertedCapacity === newCapacity) {
					return Observable.empty<never>();
				}
			}

			const success = (payload: IterationCapacityCompletePayload) =>
				Observable.of(updateIterationCapacitySuccess(payload));

			const error = (err?: Error) => {
				log.safeErrorWithoutCustomerData(
					'increment.planning.iteration.capacity.update',
					'Failed to update IP board iteration capacity',
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					err as Error,
				);
				return Observable.of(updateIterationCapacityFailed());
			};

			if (!planId) {
				return error(new Error('planId is empty in the update iteration capacity request'));
			}

			if (!incrementId) {
				return error(new Error('incrementId is empty in the update iteration capacity request'));
			}

			const requestPayload = {
				iterationId,
				planningUnit,
				teamId,
				schedulingMode: planningStyle,
				planId,
				incrementId,
			};

			if (capacity === '') {
				return removeIterationCapacity(requestPayload)
					.flatMap(() => success({ teamId, iterationId, capacity: null }))
					.catch((err) => error(err));
			}

			const parsed = parseFloat(capacity);
			const convertedCapacity = convertCapacityFromPlanningUnitToSeconds({
				capacity: parsed || 0,
				planningUnit,
				workingHours: workingHoursPerDay,
			});

			return updateIterationCapacity({
				...requestPayload,
				capacity: convertedCapacity,
			})
				.flatMap(() => success({ teamId, iterationId, capacity: convertedCapacity }))
				.catch((err) => error(err));
		});
}
