import uuid from 'uuid';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import {
	type FlagData,
	type UndoFlagData,
	FLAG_TYPE_WARNING,
	FLAG_TYPE_UNDO,
	FLAG_TYPE_SUCCESS,
	FLAG_TYPE_ERROR,
	FLAG_TYPE_INFO,
} from '../../../model/flags/flag-types.tsx';

export type FlagMeta = {
	flag: FlagData;
};

export type FlagParameters = {
	titleMessage: MessageDescriptor;
	descriptionMessage?: MessageDescriptor;
	rawDescriptionMessage?: string;
	errorMessages?: string[];
	id?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	context?: any;
};

export const warningFlagMeta = (flagParameters: FlagParameters): FlagMeta => ({
	flag: {
		type: FLAG_TYPE_WARNING,
		id: flagParameters.id || uuid.v4(),
		titleMessage: flagParameters.titleMessage,
		descriptionMessage: flagParameters.descriptionMessage,
		rawDescriptionMessage: flagParameters.rawDescriptionMessage,
		context: flagParameters.context,
	},
});

export const undoFlagMeta = (
	flagParameters: FlagParameters & { links: UndoFlagData['links'] } & {
		descriptionMessage: MessageDescriptor;
	},
): { flag: UndoFlagData } => ({
	flag: {
		type: FLAG_TYPE_UNDO,
		id: flagParameters.id || uuid.v4(),
		titleMessage: flagParameters.titleMessage,
		descriptionMessage: flagParameters.descriptionMessage,
		context: flagParameters.context,
		links: flagParameters.links,
	},
});

export const successFlagMeta = (flagParameters: FlagParameters): FlagMeta => ({
	flag: {
		type: FLAG_TYPE_SUCCESS,
		id: flagParameters.id || uuid.v4(),
		titleMessage: flagParameters.titleMessage,
		descriptionMessage: flagParameters.descriptionMessage,
		context: flagParameters.context,
	},
});

export const errorFlagMeta = (flagParameters: FlagParameters): FlagMeta => ({
	flag: {
		type: FLAG_TYPE_ERROR,
		id: flagParameters.id || uuid.v4(),
		titleMessage: flagParameters.titleMessage,
		descriptionMessage: flagParameters.descriptionMessage,
		errorMessages: flagParameters.errorMessages,
		context: flagParameters.context,
	},
});

export const infoFlagMeta = (flagParameters: FlagParameters): FlagMeta => ({
	flag: {
		type: FLAG_TYPE_INFO,
		id: flagParameters.id || uuid.v4(),
		titleMessage: flagParameters.titleMessage,
		descriptionMessage: flagParameters.descriptionMessage,
		errorMessages: flagParameters.errorMessages,
		context: flagParameters.context,
	},
});
