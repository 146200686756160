import React from 'react';
import Icon from '@atlaskit/icon';
import type { CustomGlyphProps } from '@atlaskit/icon/types';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import type { GoalStateValue } from '@atlassian/jira-shared-types/src/goal.tsx';
import { mapStateToBgColor, mapStateToColor } from '../../../common/utils.tsx';
import messages from './messages.tsx';

const GoalGlyph = (props: CustomGlyphProps) => (
	<svg {...props} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
		<g fill="currentColor" fillRule="evenodd">
			<path
				d="M16 9C16 12.866 12.866 16 9 16C5.13401 16 2 12.866 2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9ZM18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM11 9C11 10.1046 10.1046 11 9 11C7.89543 11 7 10.1046 7 9C7 7.89543 7.89543 7 9 7C10.1046 7 11 7.89543 11 9ZM13 9C13 11.2091 11.2091 13 9 13C6.79086 13 5 11.2091 5 9C5 6.79086 6.79086 5 9 5C11.2091 5 13 6.79086 13 9Z"
				fillRule="evenodd"
			/>
		</g>
	</svg>
);

const GoalIcon = ({
	state,
	ariaLabel,
}: {
	state: GoalStateValue;
	ariaLabel?: string | undefined;
}) => {
	const { formatMessage } = useIntl();

	return (
		<Box
			xcss={iconContainerStyles}
			// eslint-disable-next-line jira/react/no-style-attribute
			style={{
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				backgroundColor: mapStateToBgColor(state),
			}}
			aria-label={ariaLabel}
		>
			<Icon
				glyph={GoalGlyph}
				label={formatMessage(messages.goalIconLabel)}
				primaryColor={mapStateToColor(state)}
			/>
		</Box>
	);
};

const iconContainerStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: '24px',
	minWidth: '24px',
	height: '24px',
	borderRadius: 'border.radius',
	marginRight: 'space.025',
});

export { GoalIcon };
