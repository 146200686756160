import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { LABELS_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import {
	type MenuNode,
	MenuNodeType,
} from '@atlassian/jira-software-context-menu/src/common/types.tsx';
import { showIssueAddLabelModal } from '../../../../../../../../state/actions/issue/add-label-modal/index.tsx';
import { useBoardActionCreator, useBoardSelector } from '../../../../../../../../state/index.tsx';
import { isCardSelected } from '../../../../../../../../state/selectors/card/card-selectors.tsx';
import { ADD_LABEL_ID } from '../../constants.tsx';
import messages from '../../messages.tsx';
import { useMenuItemWithStartAnalytics } from '../../utils.tsx';
import type { Props } from './types.tsx';

export const useAddLabel = ({ issueId, selectedCardIds }: Props): MenuNode[] => {
	const { formatMessage } = useIntl();
	const isSelected = useBoardSelector((state) => isCardSelected(state)(issueId));
	const issueIds = isSelected ? selectedCardIds : [issueId];

	const onAddLabel = useBoardActionCreator(() => showIssueAddLabelModal(issueIds));

	if (fg('one_event_rules_them_all_fg')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		return useMenuItemWithStartAnalytics({
			fieldKey: LABELS_TYPE,
			fieldType: LABELS_TYPE,
			shouldFireAnalytics: selectedCardIds?.length === 1,
			menuItemProps: {
				label: formatMessage(messages.label),
				id: ADD_LABEL_ID,
				onClick: onAddLabel,
			},
		});
	}

	return [
		{
			type: MenuNodeType.Item,
			label: formatMessage(messages.label),
			id: ADD_LABEL_ID,
			onClick: onAddLabel,
		},
	];
};
