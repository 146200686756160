import React, { type ReactNode, type RefObject, createContext, useMemo } from 'react';
import type { ColumnId } from '../../types.tsx';

type contextValueType = {
	setRef: (id: ColumnId, ref: RefObject<HTMLDivElement>) => void;
	getRef: (id: ColumnId) => RefObject<HTMLDivElement> | undefined;
	removeRef: (id: ColumnId) => void;
};

export const TransitionZoneIndicatorContainerContext = createContext<contextValueType | null>(null);

export const TransitionZoneIndicatorContainerProvider = ({ children }: { children: ReactNode }) => {
	const contextValue = useMemo(() => {
		const transitionZoneIndicatorRefMap: Record<ColumnId, RefObject<HTMLDivElement>> = {};

		return {
			setRef: (id: ColumnId, ref: RefObject<HTMLDivElement>) => {
				transitionZoneIndicatorRefMap[id] = ref;
			},
			getRef: (id: ColumnId) => transitionZoneIndicatorRefMap[id],
			removeRef: (id: ColumnId) => {
				delete transitionZoneIndicatorRefMap[id];
			},
		};
	}, []);

	return (
		<TransitionZoneIndicatorContainerContext.Provider value={contextValue}>
			{children}
		</TransitionZoneIndicatorContainerContext.Provider>
	);
};
