/* This is a wrapper around @atlaskit/page-header to patch a bug where text alignment
 in actions is incorrect. This common component is no longer necessary when
 https://ecosystem.atlassian.net/browse/AK-6385 is resolved.
 */
import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import AkPageHeader from '@atlaskit/page-header';

// Prop type taken from @atlaskit/page-header@7.0.7
type Props = {
	/** Page breadcrumbs to be rendered above the title. */
	breadcrumbs?: ReactNode;
	/** Contents of the action bar to be rendered next to the page title. */
	actions?: ReactNode;
	/** Contents of the header bar to be rendered below the page title. */
	bottomBar?: ReactNode;
	/** Content of the page title. The text would be trimmed if it doesn't fit the
     header width and end with an ellipsis */
	children?: ReactNode;
	/** Disable default styles for page title */
	disableTitleStyles?: boolean;
	/** Prevent the title from wrapping across lines */
	truncateTitle?: boolean;
};

const PageHeader = ({ actions, ...props }: Props) => (
	<AkPageHeader
		{...props}
		// @ts-expect-error - TS2322 - Type 'Element | null' is not assignable to type 'ReactElement<any, string | JSXElementConstructor<any>> | undefined'.
		actions={actions ? <ActionsWrapper>{actions}</ActionsWrapper> : null}
	/>
);

export default PageHeader;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ActionsWrapper = styled.div({
	textAlign: 'left',
});
