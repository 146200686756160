import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';

export type FlagType = 'WARNING' | 'SUCCESS' | 'ERROR' | 'UNDO' | 'INFO';

export const FLAG_TYPE_WARNING = 'WARNING';
export const FLAG_TYPE_SUCCESS = 'SUCCESS';
export const FLAG_TYPE_ERROR = 'ERROR';
export const FLAG_TYPE_UNDO = 'UNDO';
export const FLAG_TYPE_INFO = 'INFO';

export type UndoFlagData = {
	id: string;
	type: typeof FLAG_TYPE_UNDO;
	titleMessage: MessageDescriptor;
	descriptionMessage: MessageDescriptor;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	context?: any;
	links: {
		content: MessageDescriptor;
		href?: string;
	}[];
};

export type FlagData =
	| {
			id: string;
			type:
				| typeof FLAG_TYPE_WARNING
				| typeof FLAG_TYPE_SUCCESS
				| typeof FLAG_TYPE_ERROR
				| typeof FLAG_TYPE_INFO;
			titleMessage: MessageDescriptor;
			descriptionMessage?: MessageDescriptor;
			/**
			 * On some cases, the back-end will send an error message that is
			 * already internationalized.
			 */
			rawDescriptionMessage?: string;
			/**
			 * It is used for error flag to show multiple error messages as
			 * un-order list.
			 * for single error message, use rawDescriptionMessage or descriptionMessage
			 */
			errorMessages?: string[];
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			context?: any;
	  }
	| UndoFlagData;
