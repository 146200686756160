import { type AccountId, toAccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import { UNASSIGNED_USER_ID } from '../../common/constants.tsx';
import type { FilterableIssue, MatcherCreator } from '../types.tsx';

export const assigneeMatcherCreator: MatcherCreator<AccountId[], FilterableIssue> = (
	assigneeFilter,
) => {
	const assigneeSet = new Set(assigneeFilter);

	return ({ assignee }) =>
		(assignee !== undefined && assigneeSet.has(assignee.id)) ||
		(assignee === undefined && assigneeSet.has(toAccountId(UNASSIGNED_USER_ID)));
};
