import type { Attributes } from '@atlassian/jira-product-analytics-bridge';

export class ExperienceError extends Error {
	constructor(error: Error, extraProps?: Attributes) {
		super(error.message);
		this.name = error.name;
		this.stack = error.stack;
		Object.assign(this, { ...error });
		Object.assign(this, extraProps);
	}
}
