import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import 'rxjs/add/observable/merge';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import { Observable } from 'rxjs/Observable';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import { REFRESH_SOURCE_TRANSITION } from '../../../model/constants.tsx';
import type { ProjectId, TransitionId } from '../../../model/software/software-types.tsx';
import { issueTransitionService } from '../../../services/board-card-move/index.tsx';
import { makeServiceContext } from '../../../services/service-context.tsx';
import {
	MISSING_PARENT_RESOLVE_REQUEST,
	type MissingParentResolveRequestAction,
	missingParentResolveSuccess,
	missingParentResolveFailure,
	missingParentResolveOptimistic,
} from '../../../state/actions/issue/missing-parent-resolve/index.tsx';
import { workRefreshData } from '../../../state/actions/work/index.tsx';
import { getDestinationStatusSelector } from '../../../state/selectors/card-create/card-create-selectors.tsx';
import { getTransitionsByColumnAndIssueType } from '../../../state/selectors/card-transitions/card-transitions-selectors.tsx';
import { getMissingParents } from '../../../state/selectors/software/software-selectors.tsx';
import type { Action, ActionsObservable, MiddlewareAPI } from '../../../state/types.tsx';
import {
	getStatusCodeGroup,
	shouldFireFailureSLOMetric,
} from '../../utils/issue-rank-transition/index.tsx';
import {
	openTransitionScreen,
	TRANSITION_SCREEN_CANCEL,
	TRANSITION_SCREEN_ERROR,
	TRANSITION_SCREEN_SUCCESS,
} from '../issue-rank-transition-epic/transition-screens/index.tsx';

const handleSuccess = ({
	optimisticId,
	issueId,
	issueKey,
	transitionId,
	projectId,
	analyticsEvent,
}: {
	optimisticId: string;
	issueId: IssueId;
	issueKey: IssueKey;
	transitionId: TransitionId;
	projectId: ProjectId;
	analyticsEvent: UIAnalyticsEvent;
}) => {
	if (analyticsEvent) {
		fireTrackAnalytics(analyticsEvent, 'missingParentResolution success', {
			issueKey,
			issueId,
			transitionId,
			projectId,
		});
	}

	return Observable.from([
		missingParentResolveSuccess({ optimisticId, issueId, analyticsEvent }),
		workRefreshData(REFRESH_SOURCE_TRANSITION),
	]);
};

const handleError = ({
	optimisticId,
	issueId,
	issueKey,
	transitionId,
	projectId,
	analyticsEvent,
	error,
}: {
	optimisticId: string;
	issueId: IssueId;
	issueKey: IssueKey;
	transitionId: TransitionId;
	projectId: ProjectId;
	analyticsEvent: UIAnalyticsEvent;
	error: FetchError;
}): Observable<Action> => {
	log.safeErrorWithoutCustomerData(
		'missing.parent.resolution.failure',
		'Failed to resolve missing parent',
		error,
	);

	if (analyticsEvent && shouldFireFailureSLOMetric(error)) {
		fireTrackAnalytics(analyticsEvent, 'missingParentResolution failed', {
			errorMessage: error.message ? error.message : error,
			statusCodeGroup: getStatusCodeGroup(error),
			issueKey,
			issueId,
			transitionId,
			projectId,
		});
	}

	return Observable.of(
		missingParentResolveFailure({ optimisticId, issueId, analyticsEvent, error }),
	);
};

const hasTransitionScreen = (
	store: MiddlewareAPI,
	action: MissingParentResolveRequestAction,
): boolean => {
	const state = store.getState();
	const {
		payload: { destinationColumnId, transitionId, issueTypeId, projectId },
	} = action;

	const destinationTransitions = getTransitionsByColumnAndIssueType(state, {
		columnId: destinationColumnId,
		issueTypeId,
		projectId,
	});

	const transitionIndex = !isNil(transitionId)
		? destinationTransitions.findIndex((transition) => transition.id === transitionId)
		: null;

	const transition = !isNil(transitionIndex) ? destinationTransitions[transitionIndex] : null;

	return Boolean(transition?.hasScreen);
};

const handleTransitionScreen = (
	store: MiddlewareAPI,
	action: MissingParentResolveRequestAction,
): Observable<Action> => {
	const {
		payload: { issueId, issueKey, transitionId, projectId },
		meta: { analyticsEvent },
	} = action;
	const dispatch = store.dispatch.bind(store);

	return openTransitionScreen({
		issueId: String(issueId),
		issueKey,
		transitionId,
		analyticsEvent,
		dispatch,
	}).flatMap((result) => {
		if (result.type === TRANSITION_SCREEN_SUCCESS) {
			return handleMissingParentResolveAction(store, action);
		}
		if (result.type === TRANSITION_SCREEN_CANCEL) {
			return Observable.empty();
		}
		if (result.type === TRANSITION_SCREEN_ERROR) {
			log.safeErrorWithoutCustomerData(
				'missing.parent.transition.screen.error',
				'Failed to resolve missing parent from transition screen',
				result.error,
			);

			fireTrackAnalytics(analyticsEvent, 'missingParentTransitionScreen error', {
				triggerPointKey: 'software-board-missing-parent-resolution-screen',
				isModalOpen: true,
				issueId,
				issueKey,
				transitionId,
				projectId,
			});

			return Observable.empty();
		}
		return Observable.empty();
	});
};

function handleMissingParentResolveAction(
	store: MiddlewareAPI,
	action: MissingParentResolveRequestAction,
): Observable<Action> {
	const state = store.getState();
	const {
		payload: { issueId, issueKey, issueTypeId, destinationColumnId, transitionId, projectId },
		meta: { analyticsEvent },
	} = action;
	if (isNil(issueId) || isNil(transitionId) || isNil(destinationColumnId))
		return Observable.empty();

	const missingParents = getMissingParents(state);
	if (isEmpty(missingParents)) return Observable.empty();

	const missingParent = missingParents[issueId];
	if (isNil(missingParent) || isNil(missingParent.projectId) || isNil(missingParent.typeId))
		return Observable.empty();

	const destinationStatus = getDestinationStatusSelector(state)(
		destinationColumnId,
		issueTypeId,
		transitionId,
	);

	const transitionOptimisticAction = missingParentResolveOptimistic({
		issueId,
		doneStatusName: destinationStatus?.name || '',
	});

	const optimisticId = transitionOptimisticAction.meta.optimistic.id;
	const ctx = makeServiceContext(state);
	return Observable.merge(
		Observable.of(transitionOptimisticAction),
		issueTransitionService(ctx, {
			issueKeys: [missingParent.key],
			selectedTransitionId: transitionId,
			targetColumn: destinationColumnId,
		})
			.flatMap(() =>
				handleSuccess({
					optimisticId,
					issueId,
					issueKey,
					transitionId,
					projectId,
					analyticsEvent,
				}),
			)
			.catch((error) =>
				handleError({
					optimisticId,
					issueId,
					issueKey,
					transitionId,
					projectId,
					analyticsEvent,
					error,
				}),
			),
	);
}

export function missingParentResolveEpic(
	action$: ActionsObservable,
	store: MiddlewareAPI,
): Observable<Action> {
	return action$
		.ofType(MISSING_PARENT_RESOLVE_REQUEST)
		.mergeMap((action: MissingParentResolveRequestAction) => {
			if (hasTransitionScreen(store, action)) {
				return handleTransitionScreen(store, action);
			}
			return handleMissingParentResolveAction(store, action);
		}, 10);
}
