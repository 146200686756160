/**
 * Binary search for the nearest value to a `needle`, given high and low indexes
 * and a `getValue` function to get the value at a certain index.
 */
export const findNearestIndex = (
	lowIndexDontRead: number,
	highIndexDontRead: number,
	getValue: (index: number) => number,
	needle: number,
): number => {
	// JFE linting doesn't allow changing the arg as a local.
	let low = lowIndexDontRead;
	let high = highIndexDontRead;

	while (low < high) {
		const middleIndex = Math.floor((high + low) / 2);
		const candidate = getValue(middleIndex);
		if (candidate > needle) {
			high = middleIndex - 1;
		} else if (candidate < needle) {
			low = middleIndex + 1;
		} else {
			return middleIndex;
		}
	}
	return low;
};

interface ViewportRectangle {
	/**
	 * The scroll-top position of the scroll container. This is the distance
	 * between the visible top edge and the top-most edge of the element.
	 */
	scrollTop: number;
	/**
	 * The height of the scroll container.
	 */
	height: number;
}

type RowPosition = {
	/**
	 * The estimated position of this row as a distance from the top-most edge of
	 * its scroll container.
	 *
	 * This must be accurate once the row is visible, but can be a lower-bound
	 * estimate while it is off-screen.
	 */
	top: number;
	/**
	 * The estimated bottom position of this row as a distance between the top-most
	 * edge of its scroll container to the bottom edge of the row.
	 */
	bottom: number;
};

/**
 * Index range. `end` is non-inclusive.
 */
interface Range {
	start: number;
	end: number;
}

/**
 * Given the viewport rectangle and positions for every row in a virtual list,
 * plus an overscan height for over-rendering above and under the visible
 * rectangle, return the range of rows to render if any.
 */
export const getRenderedRange = (
	viewportRectangle: ViewportRectangle,
	rowPositions: ReadonlyArray<RowPosition>,
	overscanHeight: number,
): Range => {
	if (rowPositions.length === 0) {
		return { start: 0, end: 0 };
	}

	// Render elements starting at the top edge of the scroll container, minus
	// some overscan height.
	const startPositionTop = viewportRectangle.scrollTop - overscanHeight;

	// And up to elements at the bottom edge of the scroll container, plus some
	// overscan height.
	// NOTE: This needs to default to 0 otherwise it is NaN which means that isAbove is true.
	const endPositionTop =
		(viewportRectangle?.scrollTop ?? 0) + (viewportRectangle?.height ?? 0) + overscanHeight;

	// If the viewport fully above or under the rows, render nothing
	const isAbove = endPositionTop < rowPositions[0].top;
	const isUnder = startPositionTop > rowPositions[rowPositions.length - 1].bottom;

	if (isAbove || isUnder) {
		return { start: 0, end: 0 };
	}

	const startIndex = findNearestIndex(
		0,
		rowPositions.length,
		(i) => rowPositions[i].bottom,
		startPositionTop,
	);
	let endIndex = startIndex;
	while (endIndex < rowPositions.length && rowPositions[endIndex].top <= endPositionTop) {
		endIndex += 1;
	}

	return { start: startIndex, end: endIndex };
};
