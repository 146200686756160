import { WORK_DATA_SET, WORK_DATA_CRITICAL_SET } from '../../../../actions/work/index.tsx';
import type { Action } from '../../../../types.tsx';
import type { SelectedSprintState } from './types.tsx';

export const selectedSprintsReducer = (
	state: SelectedSprintState = null,
	action: Action,
): SelectedSprintState => {
	if (action.type === WORK_DATA_SET || action.type === WORK_DATA_CRITICAL_SET) {
		const { sprints } = action.payload;
		return sprints && sprints.length > 0 ? sprints.map((sprint) => sprint.id) : null;
	}

	return state;
};
