import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/mergeMap';
import isNil from 'lodash/isNil';
import { Observable } from 'rxjs/Observable';
import { TOP_OF_CONTAINER, BOTTOM_OF_CONTAINER } from '../../model/constants.tsx';
import { NO_SWIMLANE } from '../../model/swimlane/swimlane-modes.tsx';
import { getSwimlaneId } from '../../services/issue/issue-data-transformer.tsx';
import {
	type IssueMoveTopOrBottomOfContainerAction,
	ISSUE_MOVE_TOP_OR_BOTTOM_OF_CONTAINER,
	issueRankTransitionRequest,
} from '../../state/actions/issue/rank-transition/index.tsx';
import { getCardSelection } from '../../state/selectors/card/card-selectors.tsx';
import {
	getFirstIssueIdInSwimlane,
	getLastIssueIdInSwimlane,
	isFirstIssueInSwimlane,
	isLastIssueInSwimlane,
} from '../../state/selectors/issue/issue-in-swimlane-selectors.tsx';
import {
	getIssueById,
	isFirstIssueInColumn,
	isLastIssueInColumn,
	getFirstIssueInColumn,
	getLastIssueInColumn,
} from '../../state/selectors/issue/issue-selectors.tsx';
import { getSwimlaneMode } from '../../state/selectors/swimlane/swimlane-mode-selectors.tsx';
import { getSwimlanes } from '../../state/selectors/swimlane/swimlane-selectors.tsx';
import type { ActionsObservable, MiddlewareAPI } from '../../state/types.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable, store: MiddlewareAPI) =>
	action$
		.ofType(ISSUE_MOVE_TOP_OR_BOTTOM_OF_CONTAINER)
		.mergeMap((action: IssueMoveTopOrBottomOfContainerAction) => {
			const state = store.getState();

			const {
				payload: { placement, fireShortcutAnalyticsFn },
				meta: { analyticsEvent },
			} = action;

			const issueIds = getCardSelection(state);
			// will be removed if multi-select movement is added
			if (issueIds.length !== 1) {
				return Observable.empty<never>();
			}

			const issueId = issueIds[0];
			const issue = getIssueById(state, issueId);

			const swimlaneMode = getSwimlaneMode(state);
			const hasSwimlanes = swimlaneMode !== NO_SWIMLANE.id;

			const isTop = placement === TOP_OF_CONTAINER;
			const isBottom = placement === BOTTOM_OF_CONTAINER;

			const isFirst = !hasSwimlanes
				? isFirstIssueInColumn(state, issueId)
				: isFirstIssueInSwimlane(state, issueId);
			const isLast = !hasSwimlanes
				? isLastIssueInColumn(state, issueId)
				: isLastIssueInSwimlane(state, issueId);

			if ((isTop && isFirst) || (isBottom && isLast)) {
				return Observable.empty<never>();
			}

			const swimlaneId = hasSwimlanes
				? getSwimlaneId(swimlaneMode, getSwimlanes(state), issue)
				: null;

			const firstIssueId =
				!hasSwimlanes || isNil(swimlaneId)
					? getFirstIssueInColumn(state, issue.columnId)
					: getFirstIssueIdInSwimlane(state, issue.columnId, swimlaneId);
			const lastIssueId =
				!hasSwimlanes || isNil(swimlaneId)
					? getLastIssueInColumn(state, issue.columnId)
					: getLastIssueIdInSwimlane(state, issue.columnId, swimlaneId);

			const payload = {
				issueIds,
				sourceColumnId: issue.columnId,
				destinationColumnId: issue.columnId,
				rankBeforeIssueId: isTop ? firstIssueId : null,
				rankAfterIssueId: isBottom ? lastIssueId : null,
				sourceSwimlaneId: null,
				destinationSwimlaneId: null,
				isSourceDone: false,
				isDestinationDone: false,
				analyticsEvent,
			};

			const attributes = {
				key: isTop ? 's t' : 's b',
				id: isTop ? 'sendToTopKeyboardShortcut' : 'sendToBottomKeyboardShortcut',
				attributes: {
					isMultiSelect: issueIds.length > 1,
				},
			};
			fireShortcutAnalyticsFn(attributes);

			return Observable.of(issueRankTransitionRequest(payload));
		});
