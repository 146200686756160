import React from 'react';
import { styled as styled2 } from '@compiled/react';
import Button from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import { PopupContainer, PopupTitle } from '../../styled.tsx';
import { DevInfoTypeMap, type DevInfoType, DevInfoTypes } from '../../types.tsx';
import { useShowDevDetailDialogForPopup } from '../../utils/hooks/use-dev-ops-details-dialog-loader-actions-for-popup/index.tsx';
import messages from './messages.tsx';

type ErrorFallbackProps = {
	issueAri: Ari;
	devInfoType: DevInfoType;
	scopeId?: string;
};

const ErrorFallback = ({ devInfoType, issueAri, scopeId }: ErrorFallbackProps) => {
	const { formatMessage } = useIntl();
	const { showDevDetailDialogForPopup } = useShowDevDetailDialogForPopup(scopeId);
	const handleLinkClick = () => {
		showDevDetailDialogForPopup(devInfoType, issueAri);
	};

	const devInfoTypeName = DevInfoTypeMap[devInfoType];
	const errorMessage =
		devInfoType === DevInfoTypes.DESIGN
			? formatMessage(messages.designErrorDescription, { devInfoType: devInfoTypeName })
			: formatMessage(messages.errorDescription, { devInfoType: devInfoTypeName });

	return (
		<PopupContainer>
			<PopupTitle>
				{formatMessage(messages.errorPopupTitle, {
					devInfoType: DevInfoTypeMap[devInfoType],
				})}
			</PopupTitle>
			<Heading size="xsmall">
				<Header>
					<ErrorIcon label="" primaryColor={token('color.icon.danger', colors.R300)} />
					<Title>{formatMessage(messages.errorTitle)}</Title>
				</Header>
			</Heading>
			<Description>
				{errorMessage}
				{devInfoType !== DevInfoTypes.DESIGN && (
					<StyledButton appearance="link" onClick={handleLinkClick}>
						{formatMessage(messages.developmentErrorDescriptionCta)}
					</StyledButton>
				)}
			</Description>
		</PopupContainer>
	);
};

export default ErrorFallback;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Title = styled2.span({
	marginLeft: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Description = styled2.p({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N200),
	marginLeft: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledButton = styled2(Button)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		paddingLeft: 0,
		fontWeight: token('font.weight.regular'),
		display: 'block',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> span': {
		margin: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Header = styled2.div({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N200),
	marginTop: token('space.100', '8px'),
});
