import type { Observable } from 'rxjs/Observable';
import fetchText$ from '@atlassian/jira-fetch/src/utils/as-text-stream.tsx';

export function updateIterationCapacity({
	iterationId,
	teamId,
	planningUnit,
	planId,
	incrementId,
	capacity,
}: {
	iterationId: number;
	teamId: string;
	planningUnit: string;
	planId: string;
	incrementId: string;
	capacity: number;
}): Observable<string | null> {
	return fetchText$(`/rest/jpo/2.0/plans/${planId}/increments/${incrementId}/capacity/add`, {
		method: 'POST',
		body: JSON.stringify({
			iterationId,
			teamId,
			planningUnit,
			capacity,
		}),
	});
}

export function removeIterationCapacity({
	iterationId,
	teamId,
	planningUnit,
	planId,
	incrementId,
}: {
	iterationId: number;
	teamId: string;
	planningUnit: string;
	planId: string;
	incrementId: string;
}): Observable<string | null> {
	return fetchText$(`/rest/jpo/2.0/plans/${planId}/increments/${incrementId}/capacity/delete`, {
		method: 'POST',
		body: JSON.stringify({
			iterationId,
			teamId,
			planningUnit,
		}),
	});
}
