import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import map from 'lodash/map';
import { Observable } from 'rxjs/Observable';
import uuid from 'uuid';
import { getNormalisedPerformanceFFs } from '@atlassian/jira-common-long-task-metrics/src/common/util/collectors.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { scheduleAfterRender } from '@atlassian/jira-software-react-scheduler/src/index.tsx';
import { Capability } from '../../../common/capability/index.tsx';
import type { DevStatusMap } from '../../../model/issue/issue-types.tsx';
import { devStatusService } from '../../../services/issue/dev-status/index.tsx';
import { makeServiceContext } from '../../../services/service-context.tsx';
import { devStatusLoadInteraction } from '../../../services/utils/performance-analytics/index.tsx';
import {
	devStatusLoadFailure,
	devStatusLoadSuccess,
	devStatusLoadRequest,
	DEV_STATUS_LOAD,
	type DevStatusLoadSuccessAction,
	type DevStatusLoadFailureAction,
	type DevStatusLoadRequestAction,
} from '../../../state/actions/issue/dev-status/index.tsx';
import { getCapability } from '../../../state/selectors/capability/capability-selectors.tsx';
import { boardOrderedIssueIdsSelector } from '../../../state/selectors/issue/board-issue-selectors.tsx';
import { getEntities } from '../../../state/selectors/software/software-selectors.tsx';
import { workIssuesSelector } from '../../../state/selectors/work/work-selectors.tsx';
import type { MiddlewareAPI, ActionsObservable, Action } from '../../../state/types.tsx';

export interface DevStatusLoadHooks {
	onStart?: (store: MiddlewareAPI, action: DevStatusLoadRequestAction) => void;
	onSuccess?: (
		store: MiddlewareAPI,
		action: DevStatusLoadRequestAction,
		result: DevStatusMap,
	) => void;
	onFailure?: (store: MiddlewareAPI, action: DevStatusLoadRequestAction) => void;
}

export const getNumOfIssuesHasDevStatus = (result: DevStatusMap) => Object.keys(result).length;

export const defaultHooks: DevStatusLoadHooks = {
	onSuccess: (store, action, result) => {
		const { concurrentId } = action.payload;
		scheduleAfterRender(() => {
			const state = store.getState();
			devStatusLoadInteraction(concurrentId).stop({
				customData: {
					...getNormalisedPerformanceFFs(),
					numOfIssuesHasDevStatus: getNumOfIssuesHasDevStatus(result),
					numOfIssues: workIssuesSelector(state).length,
				},
			});
		});
	},
};

const handleFailure = (error: Error) => {
	log.safeErrorWithoutCustomerData(
		'board.dev-status.load.failure',
		'Dev status failed to load',
		error,
	);

	return Observable.of(devStatusLoadFailure());
};

const handleSuccessNoop = () => Observable.of(devStatusLoadSuccess({}));

const handleSuccess = (result: DevStatusMap) => Observable.of(devStatusLoadSuccess(result));

// To limit server load we restrict loading of dev status on cards to smaller boards (SW-8406).
export const MAX_BOARD_SIZE_FOR_DEV_STATUS = 100;

export const handleDevStatusLoadRequest = (
	store: MiddlewareAPI,
	action: DevStatusLoadRequestAction,
	hooks: DevStatusLoadHooks = defaultHooks,
): Observable<DevStatusLoadSuccessAction | DevStatusLoadFailureAction> => {
	if (hooks.onStart) {
		hooks.onStart(store, action);
	}

	const state = store.getState();
	const ctx = makeServiceContext(state);
	const issueIds = map(getEntities(state).issues, (issue) => issue.id);
	const issueChildrenIds = map(getEntities(state).issueChildren, (issueChild) => issueChild.id);
	const issueParentIds = map(getEntities(state).issueParents, (issueParent) => issueParent.id);

	let allIssueIds;

	if (ctx.isCMPBoard) {
		allIssueIds = [...issueIds, ...issueChildrenIds, ...issueParentIds];
	} else {
		allIssueIds = issueIds;
	}

	return devStatusService(ctx, { issueIds: allIssueIds })
		.flatMap((result) => {
			if (hooks.onSuccess) {
				hooks.onSuccess(store, action, result);
			}
			return handleSuccess(result);
		})
		.catch((error) => {
			if (hooks.onFailure) {
				hooks.onFailure(store, action);
			}
			return handleFailure(error);
		});
};

// This epic could implement a check for repos connected to the projects on the board, before firing
// the dev status request. See the git diff of this comment for an example implementation.
export function devStatusLoadEpic(
	action$: ActionsObservable,
	store: MiddlewareAPI,
): Observable<Action> {
	return action$.ofType(DEV_STATUS_LOAD).switchMap(() => {
		if (!getCapability(store.getState())(Capability.ENABLE_DEVOPS_ON_BOARD)) {
			// No Capability to fetch status
			return handleSuccessNoop();
		}

		const issuesCount = boardOrderedIssueIdsSelector(store.getState()).length;
		if (issuesCount === 0) {
			// No cards on the board
			return handleSuccessNoop();
		}

		const concurrentId = uuid();
		devStatusLoadInteraction(concurrentId).start();
		return handleDevStatusLoadRequest(store, devStatusLoadRequest(concurrentId));
	});
}
