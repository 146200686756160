import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import type { PartialIssue, IssueParent } from '../../../../model/issue/issue-types.tsx';
import type { Status } from '../../../../model/software/software-types.tsx';
import flagMessages from '../../../../view/flags/messages.tsx';
import { warningFlagMeta, type FlagMeta } from '../../meta/flag-meta.tsx';
import {
	type OptimisticUiMeta,
	beginOptimisticUiMeta,
	commitOptimisticUiMeta,
	revertOptimisticUiWithFlagMeta,
} from '../../meta/optimistic-ui-meta.tsx';

export const SET_PARENT_OPTIMISTIC = 'state.actions.issue.parent.SET_PARENT_OPTIMISTIC' as const;

export type SetParentOptimisticAction = {
	type: typeof SET_PARENT_OPTIMISTIC;
	payload: {
		issues: PartialIssue[];
		parentId: number | null;
		requestPromise: Promise<undefined>;
	};
	meta: OptimisticUiMeta;
};

export const setParentOptimistic = (
	issues: PartialIssue[],
	parentId: number | null,
	requestPromise: Promise<undefined>,
): SetParentOptimisticAction => ({
	type: SET_PARENT_OPTIMISTIC,
	payload: {
		issues,
		parentId,
		requestPromise,
	},
	meta: beginOptimisticUiMeta(),
});

export const SET_PARENT_SUCCESS = 'state.actions.issue.parent.SET_PARENT_SUCCESS' as const;

export type SetParentSuccessAction = {
	type: typeof SET_PARENT_SUCCESS;
	payload: {
		issueIds: IssueId[];
	};
	meta: OptimisticUiMeta;
};

export const setParentSuccess = (
	optimisticId: string,
	issueIds: IssueId[],
): SetParentSuccessAction => ({
	type: SET_PARENT_SUCCESS,
	payload: { issueIds },
	meta: commitOptimisticUiMeta(optimisticId),
});

export const SET_PARENT_FAILURE = 'state.actions.issue.parent.SET_PARENT_FAILURE' as const;

export type SetParentFailureAction = {
	type: typeof SET_PARENT_FAILURE;
	meta: FlagMeta & OptimisticUiMeta;
};

export const setParentFailure = (optimisticId: string): SetParentFailureAction => ({
	type: SET_PARENT_FAILURE,
	meta: {
		...warningFlagMeta({
			id: SET_PARENT_FAILURE,
			titleMessage: flagMessages.genericTitle,
			descriptionMessage: flagMessages.genericDescription,
		}),
		...revertOptimisticUiWithFlagMeta(optimisticId),
	},
});

export const UNSET_PARENT_SUCCESS = 'state.actions.issue.parent.UNSET_PARENT_SUCCESS' as const;

export type UnsetParentSuccessAction = {
	type: typeof UNSET_PARENT_SUCCESS;
	payload: {
		issueIds: IssueId[];
	};
	meta: OptimisticUiMeta;
};

export const unsetParentSuccess = (
	optimisticId: string,
	issueIds: IssueId[],
): UnsetParentSuccessAction => ({
	type: UNSET_PARENT_SUCCESS,
	payload: { issueIds },
	meta: commitOptimisticUiMeta(optimisticId),
});

export const UNSET_PARENT_FAILURE = 'state.actions.issue.parent.UNSET_PARENT_FAILURE' as const;

export type UnsetParentFailureAction = {
	type: typeof UNSET_PARENT_FAILURE;
	meta: OptimisticUiMeta;
};

export const unsetParentFailure = (optimisticId: string): UnsetParentFailureAction => ({
	type: UNSET_PARENT_FAILURE,
	meta: {
		...warningFlagMeta({
			id: UNSET_PARENT_FAILURE,
			titleMessage: flagMessages.genericTitle,
			descriptionMessage: flagMessages.genericDescription,
		}),
		...revertOptimisticUiWithFlagMeta(optimisticId),
	},
});

export const SET_ISSUE_PARENT_STATUS =
	'state.actions.issue.parent.SET_ISSUE_PARENT_STATUS' as const;

export type SetIssueParentStatusAction = {
	type: typeof SET_ISSUE_PARENT_STATUS;
	payload: {
		issueId: IssueId;
		status: Status;
	};
};

export const setIssueParentStatus = (
	issueId: IssueId,
	status: Status,
): SetIssueParentStatusAction => ({
	type: SET_ISSUE_PARENT_STATUS,
	payload: {
		issueId,
		status,
	},
});

export const SET_ISSUE_PARENT_SUMMARY =
	'state.actions.issue.parent.SET_ISSUE_PARENT_SUMMARY' as const;

export type SetIssueParentSummaryAction = {
	type: typeof SET_ISSUE_PARENT_SUMMARY;
	payload: {
		issueId: IssueId;
		issueSummary: string;
	};
};

export const setIssueParentSummary = (
	issueId: IssueId,
	issueSummary: string,
): SetIssueParentSummaryAction => ({
	type: SET_ISSUE_PARENT_SUMMARY,
	payload: {
		issueId,
		issueSummary,
	},
});

export const ADD_ISSUE_PARENT = 'state.actions.issue.parent.ADD_ISSUE_PARENT' as const;

export type AddIssueParentAction = {
	type: typeof ADD_ISSUE_PARENT;
	payload: {
		issue: IssueParent;
	};
};

export const addIssueParent = ({ issue }: { issue: IssueParent }): AddIssueParentAction => ({
	type: ADD_ISSUE_PARENT,
	payload: {
		issue,
	},
});

export type Action =
	| SetParentOptimisticAction
	| SetParentSuccessAction
	| SetParentFailureAction
	| UnsetParentSuccessAction
	| UnsetParentFailureAction
	| SetIssueParentStatusAction
	| SetIssueParentSummaryAction
	| AddIssueParentAction;
