import assign from 'lodash/assign';
import fromPairs from 'lodash/fromPairs';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { ColumnType } from '@atlassian/jira-common-constants/src/column-types.tsx';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import {
	DONE,
	statusCategoryForId,
	type StatusCategory,
} from '@atlassian/jira-common-constants/src/status-categories.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	BASE_HIERARCHY_TYPE,
	CHILD_HIERARCHY_TYPE,
	PARENT_HIERARCHY_TYPE,
} from '@atlassian/jira-issue-type-hierarchies/src/index.tsx';
import type { SwimlaneModeId } from '@atlassian/jira-platform-board-kit/src/ui/swimlane/types.tsx';
import type {
	GlobalConfigGQL,
	BoardScopeGQL,
	IssueTypeGQL,
	IssueParentResponseGQL,
	InlineCardCreateConfigGQL,
	BoardScopeBoardGQLCritical,
	CardChildGQL,
	ColumnCardGQL,
	BoardFeatureGQL,
	BoardScopeBoardGQLNonCritical,
	JiraGQL,
} from '@atlassian/jira-software-board-fetch-scope-critical/src/types.tsx';
import type { BoardPermissions } from '../../../model/board/board-types.tsx';
import { EDIT_BOARD_CONFIG } from '../../../model/board/constants.tsx';
import type {
	CardTransition,
	CardTransitionEntities,
	ColumnIssueTypeTransitions,
	IssueTypeStatus,
} from '../../../model/card-transition/card-transition-types.tsx';
import type { ColumnGQL, StatusColumn } from '../../../model/column/column-types.tsx';
import {
	FULL,
	MIXED,
	PARTIAL,
	type InlineCardCreateConfig,
	type InlineCardCreateIssueTypeConfig,
} from '../../../model/inline-create/inline-card-create-types.tsx';
import type { IssueMediaCollection } from '../../../model/issue/issue-media-types.tsx';
import type { Issue, IssueArray, IssueParent } from '../../../model/issue/issue-types.tsx';
import type { PeopleArray } from '../../../model/people/people-types.tsx';
import type {
	CardType,
	CardTypeKey,
	ColumnKey,
	IssueProject,
} from '../../../model/software/software-types.tsx';
import type { SprintArray } from '../../../model/sprint/sprint-types.tsx';
import { NO_SWIMLANE } from '../../../model/swimlane/swimlane-modes.tsx';
import type { BoardConfig } from '../../../model/work/config-types.tsx';
import type {
	WorkData,
	WorkDataCritical,
	NonCriticalDataResponse,
	EstimationStatisticFieldId,
} from '../../../model/work/work-types.tsx';
import { getBoardPermissions } from '../../board/permissions/transformer.tsx';
import { swimlaneStrategiesToMode } from '../../swimlane/swimlane-data-transformer.tsx';
import { calculateEstimateValue } from '../../utils/estimation/index.tsx';

export const getIssues = (boardScopeGQL: BoardScopeGQL): IssueArray => {
	const issues = (boardScopeGQL.board.columns || []).reduce(
		(acc, col) =>
			acc.concat(
				col.cards
					// TODO: remove when backend fixed
					.filter((card) =>
						boardScopeGQL.board.issueChildren
							? boardScopeGQL.board.issueChildren.every((child) => child.issue.id !== card.issue.id)
							: true,
					)
					.map((card) => {
						const issueCard: Issue = {
							id: Number(card.issue.id),
							key: card.issue.key,
							summary: card.issue.summary,
							assigneeAccountId: get(card, ['issue', 'assignee', 'accountId'], null),
							typeId: Number(card.issue.type.id),
							typeName: card.issue.type.name,
							typeUrl: card.issue.type.iconUrl,
							statusId: Number(card.issue.status.id),
							projectId: Number(get(boardScopeGQL, ['projectLocation', 'id'], '-1')),
							columnId: Number(col.id),
							isFlagged: card.flagged,
							labels: [...card.issue.labels],
							isDone: card.done,
							parentId: card.parentId ? Number(card.parentId) : null,
							estimate: calculateEstimateValue(
								card.estimate,
								boardScopeGQL.estimation.current?.customFieldId,
							),
							priority: card.priority ? { ...card.priority } : null,
							dueDate: card.dueDate || null,
							numCompleteChildren: card.childIssuesMetadata ? card.childIssuesMetadata.complete : 0,
							numTotalChildren: card.childIssuesMetadata ? card.childIssuesMetadata.total : 0,
							sprintId: card.issue?.activeSprint?.id || '',
						};
						return issueCard;
					}),
			),
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		[] as IssueArray,
	);

	// We fetch the board/cards array since it as the correct ordering rather
	// than the mismash of orderings created by the joining of column.cards above
	return issues.sort((a, b) => {
		const firstIndex = (boardScopeGQL.board.cards || []).findIndex(
			(card) => Number(card.id) === a.id,
		);
		const secondIndex = (boardScopeGQL.board.cards || []).findIndex(
			(card) => Number(card.id) === b.id,
		);
		return firstIndex - secondIndex;
	});
};

export const getIssueChildren = (
	issueChildren: ReadonlyArray<CardChildGQL>,
	projectId: number,
	columns: ReadonlyArray<StatusColumn>,
	estimationStatistic: EstimationStatisticFieldId,
): IssueArray => {
	if (isEmpty(columns)) return [];

	return (issueChildren || []).map((card) => ({
		id: Number(card.issue.id),
		key: card.issue.key,
		summary: card.issue.summary,
		assigneeAccountId: get(card, ['issue', 'assignee', 'accountId'], null),
		typeId: Number(card.issue.type.id),
		typeName: card.issue.type.name,
		typeUrl: card.issue.type.iconUrl,
		statusId: Number(card.issue.status.id),
		projectId,
		columnId: Number(
			(
				columns.find((column) =>
					column?.statuses.some(({ id }) => id === Number(card.issue.status.id)),
				) || {}
			).id,
		),
		isFlagged: card.flagged,
		labels: [...card.issue.labels],
		isDone: card.done,
		parentId: card.parentId ? Number(card.parentId) : null,
		estimate: calculateEstimateValue(card.estimate, estimationStatistic),
		priority: card.priority ? { ...card.priority } : null,
		dueDate: card.dueDate || null,
		numCompleteChildren: 0,
		numTotalChildren: 0,
		sprintId: card.issue.activeSprint?.id || '',
	}));
};

export const getPeople = (
	boardScopeBoardGQL: BoardScopeBoardGQLCritical | BoardScopeBoardGQLNonCritical,
): PeopleArray =>
	boardScopeBoardGQL.assignees.map((a) => ({
		id: a.accountId,
		displayName: a.displayName,
		avatarUrl: a.avatarUrl,
	}));

export const getProject = (boardScopeGQL: BoardScopeGQL): IssueProject => ({
	id: Number(get(boardScopeGQL, ['projectLocation', 'id'], '-1')),
	key: get(boardScopeGQL, ['projectLocation', 'key'], 'unknown'),
	name: get(boardScopeGQL, ['projectLocation', 'name'], 'unknown'),
	projectTypeKey: SOFTWARE_PROJECT,
});

// Temporary workaround, to be removed once swag returns ids instead of keys
const statusCategoryKeyIdMap = {
	undefined: 1,
	new: 2,
	indeterminate: 4,
	done: 3,
} as const;

const getStatusCategoryForKey = (key?: keyof typeof statusCategoryKeyIdMap): StatusCategory =>
	statusCategoryForId(
		statusCategoryKeyIdMap[key || 'undefined'] || statusCategoryKeyIdMap.undefined,
	);

export const transformColumn = (column: ColumnGQL): StatusColumn => ({
	type: ColumnType.STATUS,
	id: Number(column.id),
	name: column.name,
	maxIssueCount: isNil(column.maxIssueCount) ? null : column.maxIssueCount,
	statuses: column.columnStatus.map((columnStatus) => {
		const category = getStatusCategoryForKey(
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			columnStatus.status.category as keyof typeof statusCategoryKeyIdMap,
		);

		return {
			id: Number(columnStatus.status.id),
			name: columnStatus.status.name,
			isInitial: columnStatus.transitions.some((transition) => transition.isInitial),
			isDone: category === DONE,
			category,
		};
	}),
});

export const getUserSwimlaneMode = (
	boardScopeGQL: BoardScopeGQL,
	projectIssueTypes: CardType[],
): SwimlaneModeId => {
	const swimlaneStrategy = (
		boardScopeGQL.userSwimlaneStrategy || boardScopeGQL.board.swimlaneStrategy
	).toLowerCase();

	if (
		swimlaneStrategy === 'issuechildren' &&
		!projectIssueTypes.some((issueType) => issueType.hierarchyLevelType === CHILD_HIERARCHY_TYPE)
	) {
		return NO_SWIMLANE.id;
	}
	// @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'SwimlaneModeId'.
	return swimlaneStrategiesToMode[swimlaneStrategy] || NO_SWIMLANE.id;
};

export const getSprints = (boardScopeGQL: BoardScopeGQL): SprintArray =>
	boardScopeGQL.sprints.map((sprint) => ({
		id: Number(sprint.id),
		name: sprint.name,
		goal: sprint.goal,
		canUpdateSprint: true,
		daysRemaining: sprint.daysRemaining,
		startDate: sprint.startDate,
		endDate: sprint.endDate,
	}));

export const getIssueTypes = (issueTypes: ReadonlyArray<IssueTypeGQL>): CardType[] =>
	issueTypes.map((issueType) => ({
		id: issueType.id,
		name: issueType.name,
		iconUrl: issueType.iconUrl,
		hierarchyLevelType: issueType.hierarchyLevelType || BASE_HIERARCHY_TYPE,
		default: false,
		description: '',
		hasRequiredFields: !!issueType.hasRequiredFields,
	}));

export const getParentIssues = (boardScopeGQL: BoardScopeGQL): IssueParent[] =>
	// @ts-expect-error - TS2322 - Type '{ id: number; key: string; summary: string; status: { id: string; name: string; category: StatusCategory; } | null; issueType: { id: string; name: string; iconUrl: string; }; color: Color | ... 1 more ... | undefined; }[]' is not assignable to type 'IssueParent[]'.
	get(boardScopeGQL, ['issueParents'], []).map((issueParent: IssueParentResponseGQL) => {
		let status = null;

		if (issueParent.issue && issueParent.issue.status) {
			const { id, name, category } = issueParent.issue.status;
			status = {
				id,
				name,
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				category: getStatusCategoryForKey(category as keyof typeof statusCategoryKeyIdMap),
			};
		}

		return {
			id: Number(issueParent.id),
			key: issueParent.key,
			summary: issueParent.summary,
			status,
			issueType: {
				id: issueParent.issueType.id,
				name: issueParent.issueType.name,
				iconUrl: issueParent.issueType.iconUrl,
			},
			color: issueParent.color,
		};
	});

const isEnabled = (checkKey: string, features: ReadonlyArray<BoardFeatureGQL>): boolean =>
	features.filter(({ key, status }) => key === checkKey && status === 'ENABLED').length > 0;

export const getIssuesCountByColumn = (boardScopeGQL: BoardScopeGQL): Record<ColumnKey, number> => {
	const columns = get(boardScopeGQL, ['board', 'columns'], []);
	if (isEmpty(columns)) return {};

	return fromPairs(columns.map(({ id, cards }) => [id, cards.length]));
};

export const getBoardConfig = (
	boardScopeGQL: BoardScopeGQL,
	jiraGQL: JiraGQL,
	globalConfigGQL: GlobalConfigGQL,
): BoardConfig => ({
	isCardMediaEnabled: get(boardScopeGQL, ['board', 'cardMedia', 'enabled'], false),
	boardIssueListKey: get(boardScopeGQL, ['backlog', 'boardIssueListKey']),
	requestColumnMigration: get(boardScopeGQL, ['backlog', 'requestColumnMigration']),
	issuesCountByColumn: getIssuesCountByColumn(boardScopeGQL),
	isSprintsEnabled: isEnabled('SPRINTS', boardScopeGQL.features),
	isBacklogEnabled: isEnabled('BACKLOG', boardScopeGQL.features),
	isManageRulesEnabled:
		get(boardScopeGQL, ['projectLocation', 'isSimplifiedProject'], false) &&
		// @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'never'.
		get(boardScopeGQL, ['currentUser', 'permissions'], []).includes(EDIT_BOARD_CONFIG),
	timeTrackingOptions: get(jiraGQL, ['timeTrackingOptions']),
	...(!fg('use_backend_tmp_icc_config_') && {
		isAnyStatusInCreateEnabled: get(globalConfigGQL, [
			'applicationProperties',
			'createStatusIsEnabled',
		]),
	}),
});

export const getCardTransitions = (
	projectKey: string,
	columns: ColumnGQL[] = [],
	parentHierarchyIssueTypeId?: CardTypeKey,
): CardTransitionEntities => {
	const columnIssueTypeTransitions: ColumnIssueTypeTransitions = {};
	const issueTypeStatus: IssueTypeStatus = {};

	columns.forEach((column) => {
		columnIssueTypeTransitions[column.id] = {};
		column.columnStatus.forEach((colStatus) => {
			// We dont show transitions related to epic issue type on board
			const filteredTransitions = parentHierarchyIssueTypeId
				? colStatus.transitions.filter(
						(transition) => transition.cardType.id !== parentHierarchyIssueTypeId,
					)
				: colStatus.transitions;

			filteredTransitions.forEach((transition) => {
				columnIssueTypeTransitions[column.id][transition.cardType.id] =
					columnIssueTypeTransitions[column.id][transition.cardType.id] || [];

				issueTypeStatus[transition.cardType.id] = issueTypeStatus[transition.cardType.id] || {};
				issueTypeStatus[transition.cardType.id][colStatus.status.id] = {
					...colStatus.status,
					id: Number(colStatus.status.id),
					category: getStatusCategoryForKey(
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						colStatus.status.category as keyof typeof statusCategoryKeyIdMap,
					),
				};

				const cardTransition: CardTransition = {
					id: Number(transition.id),
					name: transition.name,
					sourceStatusId:
						transition.originStatus != null ? Number(transition.originStatus.id) : null,
					destinationStatusId: Number(transition.status.id),
					isGlobal: transition.isGlobal,
					isInitial: transition.isInitial,
					hasConditions: transition.hasConditions,
					hasScreen: transition.hasScreen,
					projectKey,
				};
				columnIssueTypeTransitions[column.id][transition.cardType.id].push(cardTransition);
			});
		});
	});
	return { columnIssueTypeTransitions, issueTypeStatus };
};

export const getIssueMediaFromColumnCards = (
	boardGQL: BoardScopeBoardGQLCritical | BoardScopeBoardGQLNonCritical,
): IssueMediaCollection => {
	if (isEmpty(boardGQL.columns)) return {};
	return boardGQL.columns // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		.reduce((acc, column) => acc.concat(column.cards), [] as ColumnCardGQL[])
		.reduce((map, card: ColumnCardGQL) => {
			const { coverMedia } = card;
			if (!coverMedia) {
				return Object.assign(map, {
					[String(card.id)]: null,
				});
			}
			return Object.assign(map, {
				[String(card.id)]: {
					isHiddenByUser: coverMedia.hiddenByUser,
					endpointUrl: coverMedia.endpointUrl,
					clientId: coverMedia.clientId,
					token: coverMedia.token,
					attachmentId: coverMedia.attachmentId,
					attachmentMediaApiId: coverMedia.attachmentMediaApiId,
				},
			});
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		}, {} as IssueMediaCollection);
};

export const getIssueMediaFromSwimlaneCards = (
	boardScopeGQL: BoardScopeGQL,
): IssueMediaCollection => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const issuesMedia: Record<string, any> = {};
	(boardScopeGQL.board.apolloSwimlanes || []).forEach((swimlane) =>
		swimlane.columnsInSwimlane.forEach((column) =>
			column.cards.forEach((card) => {
				const { coverMedia } = card;
				issuesMedia[String(card.issue.id)] = coverMedia
					? {
							isHiddenByUser: coverMedia.hiddenByUser,
							endpointUrl: coverMedia.endpointUrl,
							clientId: coverMedia.clientId,
							token: coverMedia.token,
							attachmentId: coverMedia.attachmentId,
							attachmentMediaApiId: coverMedia.attachmentMediaApiId,
						}
					: null;
			}),
		),
	);
	return issuesMedia;
};

export const getIssueMediaFromIssueChildren = (
	boardGQL: BoardScopeBoardGQLNonCritical | BoardScopeBoardGQLCritical,
): IssueMediaCollection =>
	boardGQL.issueChildren.reduce((map, card: CardChildGQL) => {
		const { coverMedia } = card;

		if (!coverMedia) {
			return Object.assign(map, {
				[String(card.id)]: null,
			});
		}
		return Object.assign(map, {
			[String(card.id)]: {
				isHiddenByUser: coverMedia.hiddenByUser,
				endpointUrl: coverMedia.endpointUrl,
				clientId: coverMedia.clientId,
				token: coverMedia.token,
				attachmentId: coverMedia.attachmentId,
				attachmentMediaApiId: coverMedia.attachmentMediaApiId,
			},
		});
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	}, {} as IssueMediaCollection);

export const transformCriticalData = (boardScopeGQL: BoardScopeGQL): WorkDataCritical => {
	const issues = getIssues(boardScopeGQL);
	const people = getPeople(boardScopeGQL.board);
	const projects = [getProject(boardScopeGQL)];
	const config = {
		rankCustomFieldId: Number(boardScopeGQL.board.rankCustomFieldId),
		boardIsRankable: true,
	};
	const hasFilteredIssues = boardScopeGQL.board.hasClearedIssues;
	const columns =
		(!isEmpty(boardScopeGQL.board.columns) && boardScopeGQL.board.columns.map(transformColumn)) ||
		[];

	const projectIssueTypes = getIssueTypes(
		get(boardScopeGQL, ['projectLocation', 'issueTypes'], []),
	);
	const swimlaneModeId = getUserSwimlaneMode(boardScopeGQL, projectIssueTypes);
	const sprints = getSprints(boardScopeGQL);
	const features = get(boardScopeGQL, ['features'], []);
	const isSprintsEnabled =
		features.filter((f) => f.key === 'SPRINTS' && f.status === 'ENABLED').length > 0;
	const isBacklogEnabled =
		features.filter((f) => f.key === 'BACKLOG' && f.status === 'ENABLED').length > 0;
	const isReportsEnabled =
		features.filter((f) => f.key === 'REPORTS' && f.status === 'ENABLED').length > 0;
	const isEstimationFeatureEnabled =
		features.filter((f) => f.key === 'ESTIMATION' && f.status === 'ENABLED').length > 0;
	const isReleasesFeatureEnabled =
		features.filter((f) => f.key === 'RELEASES' && f.status === 'ENABLED').length > 0;

	const capabilities = {
		BACKLOG: isBacklogEnabled,
		SPRINTS: isSprintsEnabled,
		REPORTS: isReportsEnabled,
		ESTIMATION: isEstimationFeatureEnabled,
		RELEASES: isReleasesFeatureEnabled,
	};

	const estimationStatistic = get(boardScopeGQL, ['estimation', 'current', 'customFieldId']);

	// fetching the Epic issue type from all issueType
	const parentHierarchyIssueType = projectIssueTypes.find(
		(issueType) => issueType.hierarchyLevelType === PARENT_HIERARCHY_TYPE,
	);

	const boardPermissions = getBoardPermissions(boardScopeGQL.currentUser.permissions);

	let inlineCardCreate: InlineCardCreateConfigGQL | undefined;

	if (fg('use_backend_tmp_icc_config_')) {
		inlineCardCreate = boardScopeGQL.board.inlineCardCreateConfig;
	} else {
		inlineCardCreate = canHaveICC(boardPermissions, isSprintsEnabled, sprints)
			? { mode: FULL }
			: undefined;
	}

	return {
		issues,
		people,
		projects,
		config,
		hasFilteredIssues,
		columns,
		swimlaneModeId,
		sprints,
		isSprintsEnabled,
		boardName: boardScopeGQL.board.name,
		capabilities,
		inlineCardCreate,
		isInlineColumnEditEnabled: get(
			boardScopeGQL,
			['board', 'editConfig', 'inlineColumnEdit', 'enabled'],
			false,
		),
		issueParents: getParentIssues(boardScopeGQL),
		issueTypes: getIssueTypes(get(boardScopeGQL, ['board', 'issueTypes'], [])),
		projectIssueTypes: [...projectIssueTypes],
		boardPermissions,
		issueChildren: getIssueChildren(
			get(boardScopeGQL, ['board', 'issueChildren'], []),
			projects[0].id,
			columns,
			estimationStatistic,
		),
		allFeatures: [...boardScopeGQL.features.map((feature) => ({ ...feature }))],
		cardTransitions: getCardTransitions(
			projects[0].key,
			boardScopeGQL.board.columns.map((column) => ({ ...column })),
			parentHierarchyIssueType?.id,
		),
		estimationStatistic,
	};
};

// Defines the inline card create config for the TMP board
// Based on config, inline create experience can vary per columns and issue types (e.g. can trigger GIC with pre-filled fields)
//
// Conditions for inline create to appear:
// 1. User has create issue permission
// 2. If board is scrum board and there is an active sprint
// 3. Issues can be created in any statuses (if not, then inline create restricted to columns with initial statuses only)
// 4. If issue types have required fields, then inline create is partial (it will accept input, than open GIC with pre-filled fields)
//
// This serves as an interim solution until the ICC eligibility conditions are transferred to BE, similar to the process implemented for the CMP board
const getInlineCardCreate = (
	boardScopeGQL: BoardScopeGQL,
	globalConfigGQL: GlobalConfigGQL,
): InlineCardCreateConfig | undefined => {
	const boardPermissions = getBoardPermissions(boardScopeGQL.currentUser.permissions);
	const isSprintsEnabled = isEnabled('SPRINTS', boardScopeGQL.features);
	const isIccEnabled = canHaveICC(boardPermissions, isSprintsEnabled, getSprints(boardScopeGQL));
	const { columns } = boardScopeGQL.board;

	if (!isIccEnabled || isEmpty(columns)) {
		return undefined;
	}

	const isAnyStatusInCreateEnabled = get(globalConfigGQL, [
		'applicationProperties',
		'createStatusIsEnabled',
	]);

	const issueTypes = getIssueTypes(
		get(boardScopeGQL, ['projectLocation', 'issueTypes'], []),
	).filter(
		// Filter out issue types with hierarchy level PARENT or higher, to avoid unneccessary
		// logic with MIXED icc mode when only parent issue types have required fields.
		(issueType) =>
			issueType.hierarchyLevelType === BASE_HIERARCHY_TYPE ||
			issueType.hierarchyLevelType === CHILD_HIERARCHY_TYPE,
	);

	const hasRequiredFields = issueTypes.some((issueType) => !!issueType.hasRequiredFields);

	if (isAnyStatusInCreateEnabled !== false && !hasRequiredFields) {
		return { mode: FULL };
	}

	const initalConfig: InlineCardCreateConfig = {
		mode: MIXED,
		columns: {},
	};

	const issueTypesInIcc: InlineCardCreateIssueTypeConfig | undefined = hasRequiredFields
		? issueTypes.reduce(
				(issueTypesAcc, issueType) =>
					Object.assign(issueTypesAcc, {
						[issueType.id]: issueType.hasRequiredFields ? PARTIAL : FULL,
					}),
				{},
			)
		: undefined;

	const inlineCardCreateConfig: InlineCardCreateConfig = columns.reduce((acc, column) => {
		if (
			acc.columns !== undefined &&
			(isAnyStatusInCreateEnabled !== false ||
				column.columnStatus.some((status) => status.transitions.some((t) => t.isInitial)))
		) {
			acc.columns[Number(column.id)] = {
				mode: hasRequiredFields ? MIXED : FULL,
				issueTypes: issueTypesInIcc,
			};
		}
		return acc;
	}, initalConfig);

	return inlineCardCreateConfig;
};
const canHaveICC = (
	boardPermissions: BoardPermissions,
	isSprintsEnabled: boolean,
	sprints: SprintArray,
): boolean => boardPermissions.createIssue === true && (!isSprintsEnabled || sprints.length > 0);

export const transformNonCriticalData = (
	nonCriticalDataResponse: NonCriticalDataResponse,
): WorkData => ({
	...transformCriticalData(nonCriticalDataResponse.boardScope),
	boardConfig: getBoardConfig(
		nonCriticalDataResponse.boardScope,
		nonCriticalDataResponse.jira,
		nonCriticalDataResponse.globalConfig,
	),
	issueMedia: assign(
		getIssueMediaFromIssueChildren(nonCriticalDataResponse.boardScope.board),
		getIssueMediaFromColumnCards(nonCriticalDataResponse.boardScope.board),
	),
	requestTypes: [],
	statuses: [],
	...(!fg('use_backend_tmp_icc_config_') && {
		inlineCardCreate: getInlineCardCreate(
			nonCriticalDataResponse.boardScope,
			nonCriticalDataResponse.globalConfig,
		),
	}),
});
