import { combineReducers } from 'redux';
import { optimistic } from 'redux-optimistic-ui';
import type { Action } from '../../types.tsx';
import boardReducer from './board/reducer.tsx';
import { cardTransitionsReducer } from './card-transitions/reducer.tsx';
import { columnsReducer } from './columns/reducer.tsx';
import { customFiltersReducer } from './custom-filters/reducer.tsx';
import { estimationStatisticReducer } from './estimation-statistic/reducer.tsx';
import { incrementConfigReducer } from './increment-config/reducer.tsx';
import { inlineCreateReducer } from './inline-create/reducer.tsx';
import { issueChildrenReducer } from './issue-children/reducer.tsx';
import { IssueLinkTypesReducer } from './issue-link-types/reducer.tsx';
import { issueLinksPermissionsReducer } from './issue-links-permissions/reducer.tsx';
import { issueLinksStatsReducer } from './issue-links-stats/reducer.tsx';
import { issueMediaReducer } from './issue-media/reducer.tsx';
import issueParentsReducer from './issue-parents/reducer.tsx';
import { issuePriorityInformationReducer } from './issue-priorities/reducer.tsx';
import { issueProjectsReducer } from './issue-projects/reducer.tsx';
import issueTransitionsReducer from './issue-transitions/reducer.tsx';
import { issueTypesByProjectReducer } from './issue-types-by-project/reducer.tsx';
import { issueTypesReducer } from './issue-types/reducer.tsx';
import { issueEntitiesReducer } from './issues/reducer.tsx';
import { jqlSwimlanesReducer } from './jql-swimlanes/reducer.tsx';
import { missingParentsReducer } from './missing-parents/reducer.tsx';
import { peopleReducer } from './people/reducer.tsx';
import { projectIssueTypesReducer } from './project-issue-types/reducer.tsx';
import { requestTypesReducer } from './request-types/reducer.tsx';
import { sprintsReducer } from './sprints/reducer.tsx';
import { statusCategoriesReducer } from './status-categories/reducer.tsx';
import { statusesReducer } from './statuses/reducer.tsx';
import { teamsReducer } from './teams/reducer.tsx';
import type { EntitiesState } from './types.tsx';
import { versionDataReducer } from './version/reducer.tsx';

export default optimistic<EntitiesState>(
	combineReducers<EntitiesState, Action>({
		board: boardReducer,
		columns: columnsReducer,
		issueChildren: issueChildrenReducer,
		issueMedia: issueMediaReducer,
		issueParents: issueParentsReducer,
		issueProjects: issueProjectsReducer,
		issueTypes: issueTypesReducer,
		projectIssueTypes: projectIssueTypesReducer,
		requestTypes: requestTypesReducer,
		issues: issueEntitiesReducer,
		people: peopleReducer,
		sprints: sprintsReducer,
		statusCategories: statusCategoriesReducer,
		statuses: statusesReducer,
		cardTransitions: cardTransitionsReducer,
		issueTransitions: issueTransitionsReducer,
		estimationStatistic: estimationStatisticReducer,
		inlineCreate: inlineCreateReducer,
		customFilters: customFiltersReducer,
		jqlSwimlanes: jqlSwimlanesReducer,
		versionData: versionDataReducer,
		missingParents: missingParentsReducer,
		teams: teamsReducer,
		incrementConfig: incrementConfigReducer,
		issueTypesByProject: issueTypesByProjectReducer,
		issueLinksStats: issueLinksStatsReducer,
		issueLinkTypes: IssueLinkTypesReducer,
		issueLinksPermissions: issueLinksPermissionsReducer,
		issuePriorityInformation: issuePriorityInformationReducer,
	}),
);
