export const JIRA_UPFLOW_IFRAME = 'jiraUpFlowIframe';

export const UPSELL_EDITION_EXPERIENCE_NAME = 'upsell-edition';
export const UPFLOW_MODAL_ANALYTICS_SOURCE = 'upflowIFrame';

export const INITIATE_SPA_CLOSE = 'INITIATE_SPA_CLOSE';
export const START_EDITION_POLLING = 'START_EDITION_POLLING';
export const FINISH_EDITION_POLLING = 'FINISH_EDITION_POLLING';
export const TRACK_EXPERIENCE_FAIL = 'TRACK_EXPERIENCE_FAIL';
export const TRACK_EXPERIENCE_SUCCESS = 'TRACK_EXPERIENCE_SUCCESS';
export const SPA_READY = 'SPA_READY';
