import React from 'react';
import { ViewExperienceTrackingProvider } from '@atlassian/jira-common-experience-tracking-viewing/src/view/experience-tracking-provider/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useRelayResource } from '@atlassian/jira-relay-utils/src/services/resources/index.tsx';
import { boardRelayResource } from '@atlassian/jira-router-resources-board-resource/src/index.tsx';
import { JIRA_SOFTWARE } from '@atlassian/jira-shared-types/src/application.tsx';
import { useIsEmbedView } from '@atlassian/jira-software-board/src/common/hooks/use-is-embed-view/index.tsx';
import { checkAuthentication } from '@atlassian/jira-spa-apps-common/src/interceptors/authentication/index.tsx';
import { checkLicense } from '@atlassian/jira-spa-apps-common/src/interceptors/license/index.tsx';
import { RedirectToLoginPage } from '@atlassian/jira-spa-apps-common/src/redirect/login/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import type { RouteContext } from '@atlassian/react-resource-router';
import getMeta from '@atlassian/jira-get-meta';
import { useIsCMPBoardRoute } from '../../common/util.tsx';
import { BoardWithRouterCMP } from './board-with-router-cmp/index.tsx';
import { BoardWithRouterRelay } from './board-with-router-relay/index.tsx';
import { BoardWithRouter } from './board-with-router/index.tsx';

const BoardWithRouterWrapper = (props: { hasSoftwareLicense: boolean }) => {
	const isCMPBoard = useIsCMPBoardRoute();

	if (isCMPBoard) {
		return <BoardWithRouterCMP {...props} />;
	}

	if (fg('critical_board_query_with_relay')) {
		/* eslint-disable-next-line react-hooks/rules-of-hooks */
		const response = useRelayResource(boardRelayResource);
		return response.queryReference != null ? (
			// @ts-expect-error - TS2322 - Type 'PreloadedQuery<OperationType, Record<string, unknown>>' is not assignable to type 'PreloadedQuery<boardWithRouterRelayQuery, Record<string, unknown>>'.
			<BoardWithRouterRelay {...props} boardQueryReference={response.queryReference} />
		) : null;
	}

	return <BoardWithRouter {...props} />;
};

type Props = RouteContext;

export const Board = ({ route, match }: Props) => {
	const boardId = Number(match.params.boardId);
	const tenantContext = useTenantContext();
	const isAuthenticated = checkAuthentication(tenantContext);
	const hasSoftwareLicense = checkLicense(tenantContext, 'software');
	const isCMPBoard = useIsCMPBoardRoute();
	const isEmbedView = useIsEmbedView();

	if (!isAuthenticated) {
		return <RedirectToLoginPage />;
	}

	return (
		<ViewExperienceTrackingProvider
			experienceId={`${boardId}`}
			experience="viewBoard"
			analyticsSource="board"
			edition={tenantContext.appEditions.software}
			application={JIRA_SOFTWARE}
			additionalAttributes={{
				isClassic: isCMPBoard,
				isUIF: true,
				taskId: boardId,
				...(fg('add_shard_to_viewbacklog_and_viewboard_event')
					? { shard: getMeta('ajs-shard') ?? {}, region: getMeta('ajs-region') ?? {} }
					: {}),
				...(typeof isEmbedView === 'boolean' ? { isEmbed: isEmbedView } : {}),
			}}
		>
			{route ? <BoardWithRouterWrapper hasSoftwareLicense={hasSoftwareLicense} /> : null}
		</ViewExperienceTrackingProvider>
	);
};
