import React, { type ReactNode, memo } from 'react';
import AppBase from '@atlassian/jira-app-base/src/index.tsx';
import { IccProvider } from '@atlassian/jira-platform-inline-card-create/src/services/context/index.tsx';

type Props = {
	children: ReactNode;
};

// all root level Board providers
export const BoardProvider = memo<Props>((props: Props) => {
	const { children } = props;
	return (
		<AppBase>
			<IccProvider>{children}</IccProvider>
		</AppBase>
	);
});
