import { WORK_DATA_SET, WORK_DATA_CRITICAL_SET } from '../../../actions/work/index.tsx';
import type { Action } from '../../../types.tsx';
import type { IssuePriorityInformationState } from './types.tsx';

export const initialState = {
	issuePriorities: {},
	issuePrioritySchemes: {},
	projectToPrioritySchemeAssociations: {},
};

export const issuePriorityInformationReducer = (
	state: IssuePriorityInformationState = initialState,
	action: Action,
): IssuePriorityInformationState => {
	if (action.type === WORK_DATA_SET || action.type === WORK_DATA_CRITICAL_SET) {
		if (action.payload.issuePriorityInformation !== undefined) {
			return action.payload.issuePriorityInformation;
		}
	}

	return state;
};
