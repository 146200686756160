import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	purpleLabel: {
		id: 'issue-epic-color.common.purple-label',
		defaultMessage: 'purple',
		description: 'Tooltip text when hovering purple color in colour palette context menu.',
	},
	blueLabel: {
		id: 'issue-epic-color.common.blue-label',
		defaultMessage: 'blue',
		description: 'Tooltip text when hovering blue colour palette context menu.',
	},
	greenLabel: {
		id: 'issue-epic-color.common.green-label',
		defaultMessage: 'green',
		description: 'Tooltip text when hovering green colour palette context menu.',
	},
	tealLabel: {
		id: 'issue-epic-color.common.teal-label',
		defaultMessage: 'teal',
		description: 'Tooltip text when hovering teal colour palette context menu.',
	},
	yellowLabel: {
		id: 'issue-epic-color.common.yellow-label',
		defaultMessage: 'yellow',
		description: 'Tooltip text when hovering yellow colour palette context menu.',
	},
	orangeLabel: {
		id: 'issue-epic-color.common.orange-label',
		defaultMessage: 'orange',
		description: 'Tooltip text when hovering orange colour palette context menu.',
	},
	greyLabel: {
		id: 'issue-epic-color.common.grey-label',
		defaultMessage: 'grey',
		description: 'Tooltip text when hovering grey colour palette context menu.',
	},
	darkPurpleLabel: {
		id: 'issue-epic-color.common.dark-purple-label',
		defaultMessage: 'dark purple',
		description: 'Tooltip text when hovering dark purple colour palette context menu.',
	},
	darkBlueLabel: {
		id: 'issue-epic-color.common.dark-blue-label',
		defaultMessage: 'dark blue',
		description: 'Tooltip text when hovering dark blue colour palette context menu.',
	},
	darkGreenLabel: {
		id: 'issue-epic-color.common.dark-green-label',
		defaultMessage: 'dark green',
		description: 'Tooltip text when hovering dark green colour palette context menu.',
	},
	darkTealLabel: {
		id: 'issue-epic-color.common.dark-teal-label',
		defaultMessage: 'dark teal',
		description: 'Tooltip text when hovering dark teal colour palette context menu.',
	},
	darkYellowLabel: {
		id: 'issue-epic-color.common.dark-yellow-label',
		defaultMessage: 'dark yellow',
		description: 'Tooltip text when hovering dark yellow colour palette context menu.',
	},
	darkOrangeLabel: {
		id: 'issue-epic-color.common.dark-orange-label',
		defaultMessage: 'dark orange',
		description: 'Tooltip text when hovering dark orange colour palette context menu.',
	},
	darkGreyLabel: {
		id: 'issue-epic-color.common.dark-grey-label',
		defaultMessage: 'dark grey',
		description: 'Tooltip text when hovering dark grey colour palette context menu.',
	},
});
