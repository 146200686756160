import React from 'react';
import { styled } from '@compiled/react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from '@atlassian/jira-software-board-css-module/src/card-compact.module.css';

type CardColorProps = {
	color: string | undefined | null;
	testId?: string;
};

export const CardColor = ({ color, testId }: CardColorProps) => {
	if (!color) {
		return null;
	}

	return (
		<CardColorInner
			color={color}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={styles.cardColorInner}
			{...(testId ? { 'data-testid': testId } : {})}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardColorInner = styled.div<{ color: string }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: (props) => props.color,
	height: '100%',
	position: 'absolute',
	left: 0,
	top: 0,
	width: '4px',
	borderTopLeftRadius: '3px',
	borderBottomLeftRadius: '3px',
});
