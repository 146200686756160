import { loadProperty } from './load-property/index.tsx';
import {
	saveProperty,
	savePropertyWithoutSendRequest,
	sendUpdatePropertyRequest,
} from './save-property/index.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	loadProperty,
	saveProperty,
	savePropertyWithoutSendRequest,
	sendUpdatePropertyRequest,
};
