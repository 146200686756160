export const IS_SPRINTS_ENABLED = 'IS_SPRINTS_ENABLED' as const;
export const IS_POLLING_ENABLED = 'IS_POLLING_ENABLED' as const;
export const IS_CARD_MEDIA_SWITCH_ENABLED = 'IS_CARD_MEDIA_SWITCH_ENABLED' as const;

export type Features = {
	IS_SPRINTS_ENABLED?: boolean;
	IS_POLLING_ENABLED?: boolean;
	IS_CARD_MEDIA_SWITCH_ENABLED?: boolean;
};

export type FeatureType =
	| typeof IS_SPRINTS_ENABLED
	| typeof IS_POLLING_ENABLED
	| typeof IS_CARD_MEDIA_SWITCH_ENABLED;
