import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	upgradeToAddUsersHeader: {
		id: 'up-flow-upgrade-to-add-users-dialog.upgrade-to-add-users-header',
		defaultMessage: 'Upgrade to add users',
		description:
			'Content header for up-flow inline dialog. Shown when users try to add people but have reached the user limit.',
	},
	upgradeToAddUsersContent: {
		id: 'up-flow-upgrade-to-add-users-dialog.upgrade-to-add-users-content',
		defaultMessage:
			"You've reached the maximum number of users for your plan.\nRemove existing users or upgrade to grow your team.",
		description:
			'Content for up-flow inline dialog. Shown when users try to add people but have reached the user limit.',
	},
	upgradeButtonLabel: {
		id: 'up-flow-upgrade-to-add-users-dialog.upgrade-button-label',
		defaultMessage: 'Try it now',
		description: 'Text shown on the primary button',
	},
	learnMoreButtonLabel: {
		id: 'up-flow-upgrade-to-add-users-dialog.learn-more-button-label',
		defaultMessage: 'Learn more',
		description: 'Text shown on the link button',
	},
});
