import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/mergeMap';
import type { IssueKey, IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { DEFAULT_JQL_SWIMLANE_ID } from '../../model/constants.tsx';
import type { Sprint } from '../../model/sprint/sprint-types.tsx';
import { SWIMLANE_BY_JQL } from '../../model/swimlane/swimlane-modes.tsx';
import {
	CHECK_GLOBAL_ISSUE_CREATE,
	type CheckGlobalIssueCreateAction,
} from '../../state/actions/check-global-issue-create/index.tsx';
import {
	issueCreateFilteredV2,
	issueCreateMovedBetweenJqlSwimlanes,
} from '../../state/actions/flags/index.tsx';
import { cardCreateGlobal, cardCreateUnmapped } from '../../state/actions/issue/create/index.tsx';
import { getJQLSwimlanesData } from '../../state/selectors/board/board-selectors.tsx';
import { getStatusIdsOfColumns } from '../../state/selectors/column/column-selectors.tsx';
import {
	hasNoActiveSprintStateSelector,
	activeSprintsSelector,
} from '../../state/selectors/sprint/sprint-selectors.tsx';
import { getSwimlaneMode } from '../../state/selectors/swimlane/swimlane-mode-selectors.tsx';
import { getInvisibleIssueKeys } from '../../state/selectors/work/work-selectors.tsx';
import type { ActionsObservable, MiddlewareAPI } from '../../state/types.tsx';

type JqlSwimlanesMap = {
	[id: string]: Set<IssueId>;
};

export const checkGlobalIssueCreateEpic = (action$: ActionsObservable, store: MiddlewareAPI) =>
	// @ts-expect-error - TS2684 - The 'this' context of type 'ActionsObservable<Action>' is not assignable to method's 'this' of type 'Observable<CheckGlobalIssueCreateAction>'.
	action$.ofType(CHECK_GLOBAL_ISSUE_CREATE).mergeMap((action: CheckGlobalIssueCreateAction) => {
		const {
			payload: { issues: globalIssues },
		} = action;

		if (globalIssues.length === 0) {
			return Observable.empty<never>();
		}

		const state = store.getState();

		const cardKeys = globalIssues.map((issue) => String(issue.issueKey));
		const columnStatusIds = getStatusIdsOfColumns(state).map((statusId) => String(statusId));

		const unmappedIssuesExist = globalIssues
			.filter((issue) => issue.createdIssueDetails.fields.issuetype.hierarchyLevel < 1)
			.some((issue) => !columnStatusIds.includes(issue.createdIssueDetails.fields.status.id));

		if (unmappedIssuesExist) {
			return Observable.of(cardCreateUnmapped(cardKeys));
		}

		const hasNoActiveSprint = hasNoActiveSprintStateSelector(state);

		const sprints: Sprint[] | null = hasNoActiveSprint ? null : activeSprintsSelector(state);

		// If issues are not present on the board (not being in a current sprint) or are hidden due to any filter, show a flag as well
		const invisibleIssueKeys: IssueKey[] = getInvisibleIssueKeys(state)(cardKeys);
		if (invisibleIssueKeys.length > 0) {
			return hasNoActiveSprint
				? Observable.of(issueCreateFilteredV2(invisibleIssueKeys))
				: Observable.of(issueCreateFilteredV2(invisibleIssueKeys, sprints));
		}

		if (getSwimlaneMode(state) === SWIMLANE_BY_JQL.id) {
			const jqlSwimlanes = getJQLSwimlanesData(state);

			if (!jqlSwimlanes) {
				return Observable.of(cardCreateGlobal(cardKeys));
			}

			const jqlSwimlanesMap: JqlSwimlanesMap = {};
			let defaultSwimlaneId = '';
			jqlSwimlanes.forEach((swimlane) => {
				if (swimlane.isDefaultSwimlane) {
					defaultSwimlaneId = swimlane.id;
				}
				Object.assign(jqlSwimlanesMap, {
					[swimlane.id]: new Set(swimlane.issueIds),
				});
			});

			// If we have not returned before this, then we know that the issue is present on the board.
			// We just need to check if any of the created issues is missing from the swimlane that it was created in,
			// and then we can infer that it has been moved to a different swimlane.
			const issuesRemovedFromSwimlane = globalIssues.find((issue) => {
				if (!issue.jqlSwimlaneId) {
					return false;
				}
				const originalSwimlaneId =
					issue.jqlSwimlaneId === DEFAULT_JQL_SWIMLANE_ID ? defaultSwimlaneId : issue.jqlSwimlaneId;
				return !jqlSwimlanesMap[originalSwimlaneId]?.has(issue.issueId);
			});

			if (issuesRemovedFromSwimlane) {
				return Observable.of(issueCreateMovedBetweenJqlSwimlanes(cardKeys));
			}
		}

		return Observable.of(cardCreateGlobal(cardKeys));
	});
