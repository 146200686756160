import type { Observable as ObservableType } from 'rxjs/Observable';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import { gqlTagSwag } from '@atlassian/jira-software-swag/src/index.tsx';
import { query$ } from '@atlassian/jira-software-swag/src/services/query/index.tsx';
import type { RapidViewId } from '../../model/software/software-types.tsx';
import type { CardIssueTransitions } from './__generated_apollo__/CardIssueTransitions';

export const queryName = 'CardIssueTransitions';

export const QUERY = gqlTagSwag`
query CardIssueTransitions($boardId: ID!, $cardIds: [ID!]!) {
    boardScope(boardId: $boardId) {
        cards(cardIds: $cardIds) {
            issue {
                transitions {
                    id
                    name
                    isAvailable
                    isConditional
                    isGlobal
                    toStatusId
                    isInitial
                    hasScreen
                }
            }
        }
    }
}`;

export const fetchCardIssueTransitions = (
	contextPath: string,
	boardId: RapidViewId,
	cardIds: IssueId[],
): ObservableType<CardIssueTransitions> =>
	query$<CardIssueTransitions>(contextPath, queryName, QUERY, {
		boardId,
		cardIds,
	});
