import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/filter';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/take';
import { REFRESH_SOURCE_INLINE_CREATE } from '../../model/constants.tsx';
import {
	issueCreateFilteredV2,
	type IssueCreateFilteredV2Action,
} from '../../state/actions/flags/index.tsx';
import { WORK_DATA_SET, WORK_REFRESH_DATA } from '../../state/actions/work/index.tsx';
import { getIsCustomBoardWithIcc } from '../../state/selectors/inline-create/inline-create-selectors.tsx';
import type { ActionsObservable, MiddlewareAPI } from '../../state/types.tsx';

// Issues filtered on the server will not be returned in WORK_DATA_SET.
// This can happen when the board has a custom filter, and the created issue does not
// match the filter, or the jql of the filter failed to be parsed
export const displayFlagForIssuesFilteredOnServerEpic = (
	action$: ActionsObservable,
	store: MiddlewareAPI,
): Observable<IssueCreateFilteredV2Action> =>
	action$
		.ofType(WORK_REFRESH_DATA)
		.filter(({ payload }) => payload.source === REFRESH_SOURCE_INLINE_CREATE)
		.filter(() => getIsCustomBoardWithIcc(store.getState()))
		.switchMap((workRefreshDataAction) => {
			const newIssues = workRefreshDataAction.payload.issues;

			// Do nothing if there are no new issues, or we have already shown a flag to let the user
			// know that the issue(s) is not visible on the board
			if (!newIssues?.length || workRefreshDataAction.payload.hasShownFlag) {
				return Observable.empty();
			}

			return action$
				.ofType(WORK_DATA_SET)
				.take(1)
				.mergeMap((workDataSetAction) => {
					const boardIssuesSet = new Set(
						workDataSetAction.payload.issues.map((existingIssue) => String(existingIssue.id)),
					);

					const invisibleIssueKeys = newIssues
						.filter((newIssue) => !boardIssuesSet.has(newIssue.issueId))
						.map((newIssue) => newIssue.issueKey);

					if (invisibleIssueKeys.length) {
						return Observable.of(issueCreateFilteredV2(invisibleIssueKeys, undefined, true));
					}

					return Observable.empty();
				});
		});
