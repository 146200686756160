import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import {
	boardDeferredDataService,
	type BoardDeferredData,
} from '../../services/board/board-deferred-data/index.tsx';
import {
	BOARD_DEFERRED_DATA_LOAD,
	boardDeferredDataLoadFailure,
	boardDeferredDataLoadSuccess,
} from '../../state/actions/board/board-deferred-data/index.tsx';
import { getIsCMPBoard } from '../../state/selectors/software/software-selectors.tsx';
import type { Action, ActionsObservable, MiddlewareAPI } from '../../state/types.tsx';

const handleFailure = (error: Error) => {
	log.safeErrorWithoutCustomerData(
		'board.deferred-data.load.failure',
		'Board deferred data failed to load',
		error,
	);

	return Observable.of(boardDeferredDataLoadFailure());
};

const handleSuccess = (result: BoardDeferredData) =>
	Observable.of(boardDeferredDataLoadSuccess(result));

const handleSuccessNoop = () => Observable.empty<Action>();

export function boardDeferredDataLoadEpic(
	action$: ActionsObservable,
	store: MiddlewareAPI,
): Observable<Action> {
	return action$.ofType(BOARD_DEFERRED_DATA_LOAD).switchMap(() => {
		const state = store.getState();

		// Only applies to CMP boards
		const isCMPBoard = getIsCMPBoard(state);
		if (!isCMPBoard) {
			return handleSuccessNoop();
		}

		return boardDeferredDataService(state)
			.flatMap((result) => handleSuccess(result))
			.catch((error) => handleFailure(error));
	});
}
