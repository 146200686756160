import { UNDEFINED } from '@atlassian/jira-common-constants/src/status-categories.tsx';
import ColumnHeaderTransitionOverlay, {
	type ColumnHeaderTransitionProps,
} from '@atlassian/jira-platform-board-kit/src/ui/column/column-header-transition-overlay/index.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import {
	activeTransitionDestinationStatusSelector,
	activeTransitionNameSelector,
	isActiveTransitionGlobalSelector,
} from '../../../../../../state/selectors/card-transitions/card-transitions-selectors.tsx';
import { draggingCardSourceStatusSelector } from '../../../../../../state/selectors/card/card-selectors.tsx';
import type { State } from '../../../../../../state/types.tsx';

const mapStateToProps = (state: State): ColumnHeaderTransitionProps => {
	const activeTransitionName = activeTransitionNameSelector(state);
	const destinationStatus = activeTransitionDestinationStatusSelector(state);

	return {
		source: activeTransitionName || '',
		sourceStatus: draggingCardSourceStatusSelector(state),
		isTransitionGlobal: isActiveTransitionGlobalSelector(state),
		target: (destinationStatus && destinationStatus.name) || '',
		targetStatusCategory: (destinationStatus && destinationStatus.category) || UNDEFINED,
	};
};

export default connect(mapStateToProps, {})(ColumnHeaderTransitionOverlay);
