/**
 * @generated SignedSource<<a6471efb4cf0d88bef3ca4f275ac9826>>
 * @relayHash a7c5c4d6570548dfbbb896009ae5fc47
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 90bffcc09f8865bee8f7861946866baaa79e714bb9afc19568bc71a86050977b

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { uiJiraSoftwareEditSprintModalQuery } from './uiJiraSoftwareEditSprintModalQuery.graphql';

const node: PreloadableConcreteRequest<uiJiraSoftwareEditSprintModalQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "90bffcc09f8865bee8f7861946866baaa79e714bb9afc19568bc71a86050977b",
    "metadata": {},
    "name": "uiJiraSoftwareEditSprintModalQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
