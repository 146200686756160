import React, { useMemo } from 'react';
import { DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { useFetchedToolSections } from '@atlassian/jira-software-connect-toolbar/src/controllers/index.tsx';
import { BoardConnectMenuItems } from '@atlassian/jira-software-connect-toolbar/src/ui/board-connect-menu-items/index.tsx';
import type { BoardMenuItemLocation } from '@atlassian/jira-software-connect-toolbar/src/ui/board-connect-menu-items/types.tsx';
import { findSection } from '@atlassian/jira-software-connect-toolbar/src/ui/board-connect-menu-items/utils.tsx';
import { useIsCMPBoard } from '../../../../state/state-hooks/capabilities/index.tsx';
import Operations from '../../extension/index.tsx';

type BoardConnectItemsProps = {
	projectKey: string;
	boardId: string;
	onClickWebItem?: () => void;
	hasOperations?: boolean;
};

const BoardConnectItems = ({
	projectKey,
	boardId,
	onClickWebItem,
	hasOperations,
}: BoardConnectItemsProps) => {
	const isCMPBoard = useIsCMPBoard();
	const toolSections = useFetchedToolSections({ boardId, projectKey, mode: 'work' });

	const shouldRenderSection = useMemo(() => {
		const connectMenuItemLocations: BoardMenuItemLocation[] = [
			'board-tools-1',
			'view-actions-work',
			'view-actions-print',
		];

		return Boolean(
			toolSections &&
				(connectMenuItemLocations
					.map(
						(location) =>
							(findSection(toolSections, location)?.subItems?.filter((item) => item.id != null)
								.length ?? 0) > 0,
					)
					.includes(true) ||
					toolSections?.filter((section) => section.id !== 'board-tools-section').length > 0),
		);
	}, [toolSections]);

	return isCMPBoard
		? shouldRenderSection && (
				<DropdownItemGroup hasSeparator>
					<BoardConnectMenuItems
						boardId={boardId}
						projectKey={projectKey}
						location="board-tools-1"
						onClickWebItem={onClickWebItem}
						mode="work"
					/>
					<BoardConnectMenuItems
						boardId={boardId}
						projectKey={projectKey}
						location="view-actions-work"
						mode="work"
					/>
					<BoardConnectMenuItems
						boardId={boardId}
						projectKey={projectKey}
						location="view-actions-print"
						onClickWebItem={onClickWebItem}
						mode="work"
					/>
					<BoardConnectMenuItems boardId={boardId} projectKey={projectKey} mode="work" />
				</DropdownItemGroup>
			)
		: hasOperations && (
				<DropdownItemGroup hasSeparator>
					<Operations />
				</DropdownItemGroup>
			);
};

// delete file on 'jira_nav4_eap_drop_2' clean up
export default BoardConnectItems;
