import React from 'react';
import { useBoardSelector } from '../../../../state/index.tsx';
import {
	activeSprintsSelector,
	getSelectedOrAllSprintsSelector,
} from '../../../../state/selectors/sprint/sprint-selectors.tsx';
import SprintDetails from './view.tsx';

export const SprintDetailsButton = () => {
	const selectedSprints = useBoardSelector(getSelectedOrAllSprintsSelector);
	const activeSprints = useBoardSelector(activeSprintsSelector);

	// IN THEORY the selector can return an empty array as well as null (accordign to typedef).
	// HOWEVER, in practice this selector is a bit "complicated"
	// so the "empty array" situation should never happen.
	// Anyway, let's be defensive and handle both null and empty.
	if (!activeSprints?.length) {
		return null;
	}

	return <SprintDetails activeSprints={activeSprints} selectedSprints={selectedSprints} />;
};
