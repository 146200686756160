/** @jsx jsx */
import { memo, type MouseEvent } from 'react';
import { css, keyframes, jsx } from '@compiled/react';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { TOOLTIP_DELAY } from '../../../common/constants.tsx';
import type { Props } from './types.tsx';

const fadeInAnimation = keyframes({
	from: {
		opacity: 0,
	},
	to: {
		opacity: 1,
	},
});

const triggerStyles = css({
	display: 'block',
	position: 'relative',
	zIndex: 1,
	margin: `${token('space.negative.050', '-4px')} 0`,
	padding: 0,
	border: 'none',
	outline: 'none',
	height: token('space.100', '8px'),
	width: '100%',
	backgroundColor: 'transparent',
	opacity: 0,
	'&:hover': {
		cursor: 'pointer',
		animationName: fadeInAnimation,
		animationDuration: '200ms',
		animationDelay: '200ms',
		animationFillMode: 'forwards',
	},
	'&:focus-within': {
		cursor: 'pointer',
		opacity: 1,
		'&:hover': {
			animation: 'none',
		},
	},
});

const buttonStyles = css({
	display: 'block',
	position: 'absolute',
	transform: `translate(${token('space.negative.150', '-10px')}, ${token('space.negative.075', '-6px')})`,
	zIndex: 1,
	margin: 0,
	padding: 0,
	outline: 'none',
	height: token('space.250', '20px'),
	width: token('space.250', '20px'),
	border: 'none',
	borderRadius: token('border.radius', '3px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.brand.bold', colors.B300),
	cursor: 'inherit',
});

// eslint-disable-next-line jira/styled/styled-component-order
const centeredIconStyles = xcss({
	margin: 'space.negative.025',
	height: token('space.300', '24px'),
	width: token('space.300', '24px'),
});

// eslint-disable-next-line jira/styled/styled-component-order
const dividerStyles = xcss({
	position: 'absolute',
	transform: 'translateY(3px)',
	height: token('space.025', '2px'),
	width: '100%',
});

const TriggerFallback = (props: { onClick: (arg1: MouseEvent<HTMLElement>) => void }) => (
	<div css={triggerStyles} {...props} />
);

const HiddenTrigger = ({
	label,
	onClick,
	TriggerWrapper = TriggerFallback,
	shouldFocusTrigger = false,
}: Props) => (
	<TriggerWrapper onClick={onClick}>
		<Tooltip content={label} position="left" delay={TOOLTIP_DELAY} hideTooltipOnClick>
			<button
				css={buttonStyles}
				data-testid="platform-inline-card-create.ui.trigger.hidden.button"
				autoFocus={shouldFocusTrigger}
			>
				<Box xcss={centeredIconStyles}>
					<EditorAddIcon label={label} primaryColor={token('color.icon.inverse', colors.N0)} />
				</Box>
			</button>
		</Tooltip>
		<Box xcss={dividerStyles} backgroundColor="color.background.brand.bold" />
	</TriggerWrapper>
);

export { HiddenTrigger };
export default memo<Props>(HiddenTrigger);
