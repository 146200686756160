import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	storyPointsDisplayLabel: {
		id: 'software.main.services.utils.estimation.story-points-display-label',
		defaultMessage: '{value, plural, one {# story point} other {# story points}}',
		description: 'Units to be used as the tooltip for story points estimates',
	},
	originalEstimateDisplayLabel: {
		id: 'software.main.services.utils.estimation.original-estimate-display-label',
		defaultMessage: '{value} original estimate',
		description: 'Units to be used as the tooltip for original time estimates',
	},
	remainingEstimateDisplayLabel: {
		id: 'software.main.services.utils.estimation.remaining-estimate-display-label',
		defaultMessage: '{value} remaining',
		description: 'Units to be used as the tooltip for remaining time estimates',
	},
});
