import { useRef } from 'react';
import { useContainerContext } from '@atlassian/jira-providers-container-context/src/main.tsx';

export const useIsProjectType = ({
	onIsProjectTypeUpdated,
}: {
	onIsProjectTypeUpdated: (arg1: { isProjectType: boolean }) => void;
}) => {
	const [{ data: containerContext }] = useContainerContext();
	const isProjectType = containerContext?.contextType === 'project';
	const previousIsProjectType = useRef<boolean | undefined>();
	if (previousIsProjectType.current !== isProjectType) {
		onIsProjectTypeUpdated({ isProjectType });
		previousIsProjectType.current = isProjectType;
	}
};
