import { useEffect } from 'react';
import { UNSCHEDULED_COLUMN_ID } from '@atlassian/jira-common-constants/src/column-types.tsx';
import type { Result } from '@atlassian/jira-software-fast-virtual/src/services/use-virtual/index.tsx';
import type { ColumnId } from '../../../../../../model/column/column-types.tsx';
import type { SwimlaneId } from '../../../../../../model/swimlane/swimlane-types.tsx';
import { cardAutoScrollUnscheduledColumnIcc } from '../../../../../../state/actions/card/card-auto-scroll/index.tsx';
import { useBoardSelector, useBoardDispatch } from '../../../../../../state/index.tsx';
import { getCardAutoScroll } from '../../../../../../state/selectors/card/card-selectors.tsx';
import { getIsIncrementPlanningBoard } from '../../../../../../state/selectors/software/software-selectors.tsx';

export const useScrollToUnscheduledColumnICC = (
	columnId: ColumnId,
	swimlaneId: SwimlaneId | undefined | null,
	issueCount: number,
	scrollTo: Result['scrollTo'],
) => {
	const isIncrementPlanningBoard = useBoardSelector(getIsIncrementPlanningBoard);
	const autoScroll = useBoardSelector(getCardAutoScroll);
	const autoScrollSwimlaneId = autoScroll?.unscheduledColumnSwimlaneId;
	const isImmediate = autoScroll?.isImmediate;
	const dispatch = useBoardDispatch();

	useEffect(() => {
		if (
			isIncrementPlanningBoard &&
			autoScrollSwimlaneId &&
			swimlaneId &&
			columnId === UNSCHEDULED_COLUMN_ID &&
			swimlaneId === autoScrollSwimlaneId
		) {
			scrollTo(issueCount, {
				immediate: isImmediate,
				hasVirtualisedChildren: true,
				onComplete: () => {
					dispatch(cardAutoScrollUnscheduledColumnIcc(null));
				},
			});
		}
	}, [
		isIncrementPlanningBoard,
		autoScrollSwimlaneId,
		isImmediate,
		issueCount,
		scrollTo,
		columnId,
		swimlaneId,
		dispatch,
	]);
};
