import React, { type ComponentType } from 'react';
import type Client from '@atlaskit/pubsub';
import { Consumer } from '../../../common/utils/context.utils.tsx';

export function withPubSubClient<Props>(
	WrappedComponent: ComponentType<Props & { client: Client | null }>,
): ComponentType<Props> {
	return (props: Props) => (
		<Consumer>{({ client }) => <WrappedComponent {...props} client={client} />}</Consumer>
	);
}

export default withPubSubClient;
