/** @jsx jsx */
import React, { type ReactNode } from 'react';
import { css, jsx } from '@compiled/react';
import { FILTER_FIELD_ERROR } from '../../../common/constants.tsx';
import ProgramBoardCustomFieldNotFound from './not-found/index.tsx';
import ProgramBoardCustomFieldNotInPlan from './not-in-plan/index.tsx';
import ProgramBoardCustomFieldOptionNotFound from './option-not-found/index.tsx';
import ProgramBoardCustomFieldTrashed from './trashed/index.tsx';
import type { Props } from './types.tsx';

const ProgramBoardCustomFieldError = ({ planId, height, boardScopeFilter, isReadOnly }: Props) => {
	let errorScreen;
	switch (boardScopeFilter.filterFieldError) {
		case FILTER_FIELD_ERROR.NOT_IN_PLAN:
			errorScreen = (
				<ProgramBoardCustomFieldNotInPlan
					planId={planId}
					customFieldId={boardScopeFilter.filterFieldId}
					customFieldName={boardScopeFilter.filterFieldName}
					isReadOnly={isReadOnly}
				/>
			);
			break;
		case FILTER_FIELD_ERROR.OPTION_NOT_FOUND:
			errorScreen = (
				<ProgramBoardCustomFieldOptionNotFound
					customFieldName={boardScopeFilter.filterFieldName}
					isReadOnly={isReadOnly}
				/>
			);
			break;
		case FILTER_FIELD_ERROR.NOT_FOUND:
			errorScreen = <ProgramBoardCustomFieldNotFound isReadOnly={isReadOnly} />;
			break;
		default:
			errorScreen = (
				<ProgramBoardCustomFieldTrashed
					customFieldName={boardScopeFilter.filterFieldName}
					isReadOnly={isReadOnly}
				/>
			);
			break;
	}
	if (height) {
		return <Wrapper height={height}>{errorScreen}</Wrapper>;
	}

	return errorScreen;
};

// Need to use style attribute as it's dynamic values.
// See https://atlassian.design/components/eslint-plugin-ui-styling-standard/no-dynamic-styles/usage

const styles = css({ display: 'flex', alignItems: 'center' });
const Wrapper = ({ height, children }: { height: string; children: ReactNode }) => (
	// eslint-disable-next-line jira/react/no-style-attribute
	<div css={styles} style={{ height }}>
		{children}
	</div>
);

export default ProgramBoardCustomFieldError;
