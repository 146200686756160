import { createSelector } from 'reselect';
import { Capability } from '../../../common/capability/index.tsx';
import messages from '../../../model/swimlane/messages.tsx';
import {
	defaultSwimlanes,
	NO_SWIMLANE,
	SWIMLANE_BY_JQL,
	SWIMLANE_BY_PARENT_ISSUE,
	SWIMLANE_BY_PROJECT,
	SWIMLANE_BY_REQUEST_TYPE,
	SWIMLANE_BY_SUBTASK,
	type SwimlaneMode,
} from '../../../model/swimlane/swimlane-modes.tsx';
import type { State } from '../../reducers/types.tsx';
import type { ModeState } from '../../reducers/ui/swimlane/mode/types.tsx';
import { getCapability } from '../capability/capability-selectors.tsx';
import {
	firstParentIssueTypeNameSelector,
	hasIssueParentsSelector,
} from '../issue-parent/index.tsx';
import { issueChildrenTypesSelector } from '../issue-type/issue-type-selectors.tsx';
import {
	getEntities,
	getHierarchyNomenclature,
	getIsCMPBoard,
} from '../software/software-selectors.tsx';

export const getSwimlaneMode = (state: State): ModeState => state.ui.swimlane.mode;

export const getJqlSwimlanesData = (state: State) => getEntities(state).jqlSwimlanes?.swimlanes;

export const getSwimlaneModes = createSelector(
	hasIssueParentsSelector,
	firstParentIssueTypeNameSelector,
	issueChildrenTypesSelector,
	getJqlSwimlanesData,
	getIsCMPBoard,
	getCapability,
	getHierarchyNomenclature,
	(
		hasIssueParents,
		firstParentIssueTypeName,
		issueChildrenTypes,
		jqlSwimlanesData,
		isCMPBoard,
		hasCapability,
		hierarchyNomenclature,
	) => {
		const swimlaneModes: SwimlaneMode[] = [...defaultSwimlanes];

		if (hasIssueParents && hasCapability(Capability.RENDER_EPIC_SWIMLANE_OPTION)) {
			const displayText =
				isCMPBoard && hierarchyNomenclature
					? hierarchyNomenclature.firstLevelName
					: firstParentIssueTypeName;

			swimlaneModes.push({
				...SWIMLANE_BY_PARENT_ISSUE,
				values: {
					displayText,
				},
			});
		}

		if (hasCapability(Capability.RENDER_REQUEST_TYPE_SWIMLANE_OPTION)) {
			swimlaneModes.push(SWIMLANE_BY_REQUEST_TYPE);
		}

		const hasIssueChildrens = issueChildrenTypes && issueChildrenTypes.length > 0;
		if (hasIssueChildrens && isCMPBoard) {
			if (hasCapability(Capability.RENDER_STORIES_SWIMLANE_OPTION)) {
				swimlaneModes.push({
					...SWIMLANE_BY_SUBTASK,
					message: messages.stories,
				});
			}
		} else if (hasIssueChildrens) {
			swimlaneModes.push({
				...SWIMLANE_BY_SUBTASK,
				values: {
					displayText: issueChildrenTypes[0].name,
				},
			});
		}

		if (
			jqlSwimlanesData &&
			jqlSwimlanesData.length > 0 &&
			hasCapability(Capability.RENDER_QUERIES_SWIMLANE_OPTION)
		) {
			swimlaneModes.push(SWIMLANE_BY_JQL);
		}

		if (isCMPBoard && hasCapability(Capability.RENDER_PROJECTS_SWIMLANE_OPTION)) {
			swimlaneModes.push(SWIMLANE_BY_PROJECT);
		}

		return swimlaneModes;
	},
);

export const hasSwimlanes = (state: State) => getSwimlaneMode(state) !== NO_SWIMLANE.id;

export const isSwimlanesByJqlAvailable = createSelector(getSwimlaneModes, (swimlaneModes) =>
	swimlaneModes.includes(SWIMLANE_BY_JQL),
);
