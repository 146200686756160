import React from 'react';
import { SkeletonItem } from '@atlaskit/menu';
import { Bleed, Box, xcss } from '@atlaskit/primitives';

export type SkeletonProps = {
	testId?: string;
	noPadding?: boolean;
	smallerWidth?: boolean;
};

export const IssueLinksDetailsPopupSkeleton = ({
	testId,
	smallerWidth,
	noPadding = false,
}: SkeletonProps) => (
	<Box testId={testId} xcss={smallerWidth ? smallerWidthStyles : undefined}>
		<Bleed inline={noPadding ? 'space.200' : undefined}>
			<SkeletonItem width="60%" />
			<SkeletonItem width="100%" hasIcon />
			<SkeletonItem width="100%" hasIcon />
		</Bleed>
	</Box>
);

const smallerWidthStyles = xcss({
	width: '80%',
});
