import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { State } from '../../state/reducers/types.tsx';
import { boardNameSelector } from '../../state/selectors/board/board-title-selectors.tsx';
import { getSelectedOrAllSprintsSelector } from '../../state/selectors/sprint/sprint-selectors.tsx';
import DocumentTitle from './view.tsx';

export default connect(
	(state: State) => ({
		boardName: boardNameSelector(state),
		sprints: getSelectedOrAllSprintsSelector(state),
	}),
	{},
)(injectIntl(DocumentTitle));
