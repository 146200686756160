import type { FetchOptions } from '@atlassian/jira-insights-common-constants/src/common/types/fetch.tsx';
import { fetchJson } from '@atlassian/jira-insights-fetch/src/services/fetch/fetch-json/index.tsx';

export const fetchInsightPermission = async (
	{
		boardId,
	}: {
		boardId: number;
	},
	options?: FetchOptions,
): Promise<boolean> => {
	const response = await fetchJson<{
		allowed: boolean;
	}>(`/rest/internal/board/${boardId}/insight-permission`, options);

	return response && response.allowed;
};
