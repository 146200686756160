import type { Observable as ObservableType } from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import type { canInviteOthersResponse } from '../../model/can-user-invite/can-user-invite-types.tsx';

const getCanInviteOthersUrl = (projectId: number) =>
	`/rest/internal/latest/invite-user/project/${projectId}/can-invite-others`;

export const getUserInviteCapability = (
	projectId: number,
): ObservableType<canInviteOthersResponse> =>
	fetchJson$(getCanInviteOthersUrl(projectId), {
		method: 'GET',
	});
