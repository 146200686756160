import React from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import type { HighlightedTextPart } from '../common/utils/index.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const Mark = styled.mark({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.selected.hovered', colors.B75),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N800),
});

export type Props = {
	parts: HighlightedTextPart[];
};

const HighlightText = ({ parts }: Props) => (
	<>
		{parts.map((part, i) => {
			if (part.type === 'text') {
				return part.content;
			}

			return <Mark key={i}>{part.content}</Mark>;
		})}
	</>
);

export default HighlightText;
