import { useEffect } from 'react';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useBoardSelector } from '../../../../../state/index.tsx';
import {
	boardHasIncompleteUnassignedWorkSelector,
	userHasIncompleteAssignedIssuesSelector,
} from '../../../../../state/selectors/unassigned-filter-cta/unassigned-filter-cta-selector.tsx';

export const UnassignedFilterCTABoardAnalytics = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const userHasIncompleteAssignedIssues = useBoardSelector(userHasIncompleteAssignedIssuesSelector);
	const boardHasIncompleteUnassignedWork = useBoardSelector(
		boardHasIncompleteUnassignedWorkSelector,
	);

	useEffect(() => {
		const event = createAnalyticsEvent({});

		fireTrackAnalytics(event, 'boardInState viewed', {
			userHasIncompleteAssignedIssues,
			boardHasIncompleteUnassignedWork,
		});
	}, [createAnalyticsEvent, userHasIncompleteAssignedIssues, boardHasIncompleteUnassignedWork]);

	return null;
};
