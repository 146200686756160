import React from 'react';
import type { PresenceType } from '@atlaskit/avatar';
import Tooltip, { type TooltipProps } from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export type Props = TooltipProps & {
	presence?: PresenceType;
};

const PresenceTooltip = ({ presence, content, children, ...restProps }: Props) => {
	const { formatMessage } = useIntl();

	const contentWithPresence =
		presence === 'online'
			? formatMessage(messages.presenceToolTipOnline, { name: String(content) })
			: formatMessage(messages.presenceToolTipOffline, { name: String(content) });

	return (
		<Tooltip content={presence ? contentWithPresence : content} {...restProps}>
			{children}
		</Tooltip>
	);
};

export default PresenceTooltip;
