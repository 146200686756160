import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useEvent } from '@atlassian/jira-software-react-use-event/src/index.tsx';
import type { OnContextMenuParams } from './types.tsx';

export const useOnContextMenu = ({ childrenRef, openContextMenuRef }: OnContextMenuParams) => {
	const onContextMenu = useEvent((e: React.MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
		e.stopPropagation();
		if (!childrenRef?.current || !openContextMenuRef?.current) {
			!childrenRef?.current &&
				fireErrorAnalytics({
					error: new Error('Trying to open a context menu while childrenRef is undefined'),
					meta: {
						id: 'jira-software-context-menu-controller-on-container-update-flow-start-error',
						packageName: 'jiraSoftwareContextMenu',
						teamName: 'deliveroo',
					},
				});
			return;
		}
		e.preventDefault();

		const { top, left } = childrenRef.current.getBoundingClientRect();
		const position = {
			x: e.clientX - left,
			y: e.clientY - top,
		};

		openContextMenuRef.current.onOpenContextMenu({
			position,
			analyticsEvent,
		});
	});

	return { onContextMenu };
};
