import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const planIssueStatus = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "async-plan-issue-status" */ './src/ui/index').then(
			(module) => module.AsyncPlanIssueStatus,
		),
	),
	getPreloadProps: () => ({}),
});
