import React from 'react';
import { EngagementSpotlight } from '@atlassiansox/engagekit-ts';
import { SpotlightManager, SpotlightTarget } from '@atlaskit/onboarding';
import { useIntl } from '@atlassian/jira-intl';
import { createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import { integrationTypes } from '@atlassian/jira-share-button/src/constants.tsx';
import ShareButton from '@atlassian/jira-share-button/src/index.tsx';
import { useShareIntegrations } from '@atlassian/jira-share-integrations/src/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import messages from './messages.tsx';

type Props = {
	poSpotlightShareButtonId?: string;
	projectId: string;
	shareContentType: string;
	onTriggerButtonClick: () => void;
};

export const JswShareButton = ({
	projectId,
	shareContentType,
	onTriggerButtonClick,
	poSpotlightShareButtonId,
}: Props) => {
	const cloudId = useCloudId();
	const { formatMessage } = useIntl();
	const { shareIntegrations } = useShareIntegrations('board');

	return (
		<SpotlightManager>
			<SpotlightTarget name="ccEcoBoardBacklogShare">
				<ShareButton
					integrationType={integrationTypes.CLASSIC_BOARD}
					triggerButtonStyle="icon-only"
					productId="jira"
					subProductId="jira-software"
					shareTitle={formatMessage(messages.shareTitle)}
					shareFormTitle={formatMessage(messages.shareFormTitle)}
					shareContentType={shareContentType}
					objectAri={createAri({
						resourceOwner: 'jira',
						cloudId,
						resourceType: 'project',
						resourceId: projectId,
					})}
					copyTooltipText={formatMessage(messages.shareTooltipText)}
					shareIntegrations={shareIntegrations}
					shareFormHelperMessage={formatMessage(messages.shareHelperMessage)}
					onTriggerButtonClick={onTriggerButtonClick}
					poSpotlightShareButtonId={poSpotlightShareButtonId}
				/>
				<EngagementSpotlight name="ccEcoBoardBacklogShare" />
			</SpotlightTarget>
		</SpotlightManager>
	);
};
