import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/merge';
import 'rxjs/add/operator/bufferCount';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/merge';
import isNil from 'lodash/isNil';
import { Observable } from 'rxjs/Observable';
import uuid from 'uuid';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { ColumnId } from '../../model/column/column-types.tsx';
import {
	REFRESH_SOURCE_BULK_ISSUE_RANK,
	REFRESH_SOURCE_TRANSITION,
} from '../../model/constants.tsx';
import type { BulkIssuesRankRequestPayload } from '../../model/issue/issue-types.tsx';
import type { OptimisticId } from '../../model/software/software-types.tsx';
import { parseJiraErrorMessages } from '../../rest/software/software-api-error.tsx';
import { bulkIssueRankService } from '../../services/board-card-move/index.tsx';
import { makeServiceContext } from '../../services/service-context.tsx';
import { issueChildrenInFinalColumnRequest } from '../../state/actions/issue-children/index.tsx';
import {
	type BulkIssueRankSuccessPayload,
	type BulkIssueRankFailurePayload,
	type BulkIssueRankTransitionRequestAction,
	BULK_ISSUE_RANK_TRANSITION_REQUEST,
	issueRankTransitionRequest,
	ISSUE_RANK_TRANSITION_FAILURE,
	ISSUE_RANK_TRANSITION_SUCCESS,
	bulkIssueRankOptimistic,
	bulkIssueRankSuccess,
	bulkIssueRankFailure,
} from '../../state/actions/issue/rank-transition/index.tsx';
import { workRefreshData } from '../../state/actions/work/index.tsx';
import { getTransitionsByColumnAndIssueType } from '../../state/selectors/card-transitions/card-transitions-selectors.tsx';
import {
	firstGlobalTransitionIdForColumnWithProjectFilteringSelector,
	isLastColumn,
} from '../../state/selectors/column/column-selectors.tsx';
import { getIssueChildren } from '../../state/selectors/issue-children/index.tsx';
import {
	boardIssuesSelector,
	boardOrderedIssueIdsSelector,
} from '../../state/selectors/issue/board-issue-selectors.tsx';
import {
	getIssueKeysFromIssueIds,
	getRankConfig,
} from '../../state/selectors/issue/issue-selectors.tsx';
import {
	getIsCMPBoard,
	getOrderedIssueChildrenIds,
	getOrderedIssueIds,
} from '../../state/selectors/software/software-selectors.tsx';
import type { Action, ActionsObservable, MiddlewareAPI, State } from '../../state/types.tsx';
import {
	getStatusCodeGroup,
	shouldFireFailureSLOMetric,
} from '../utils/issue-rank-transition/index.tsx';

const checkIfAnyIssueHasScreen = (
	state: State,
	payload: BulkIssueRankTransitionRequestAction['payload'],
) => {
	const { issueIds, sourceColumnId, destinationColumnId, transitionId } = payload;
	const allIssues = boardIssuesSelector(state);

	for (const issueId of issueIds) {
		const issue = allIssues[String(issueId)];
		const destinationTransitions = getTransitionsByColumnAndIssueType(state, {
			columnId: destinationColumnId,
			issueTypeId: issue.typeId,
			projectId: issue.projectId,
		});

		let selectedTransitionId = transitionId;

		// Set the new value based on the project key
		selectedTransitionId =
			isNil(transitionId) && sourceColumnId !== destinationColumnId
				? firstGlobalTransitionIdForColumnWithProjectFilteringSelector(state)(
						destinationColumnId,
						issue,
					)
				: transitionId;

		const transitionIndex =
			selectedTransitionId != null
				? destinationTransitions.findIndex((transition) => transition.id === transitionId)
				: null;
		const transition = transitionIndex != null ? destinationTransitions[transitionIndex] : null;
		if (transition?.hasScreen) {
			return true;
		}
	}
	return false;
};

const handleBulkIssueRankSuccess = ({
	payload,
	optimisticId,
	boardId,
	analyticsEvent,
}: {
	payload: BulkIssueRankSuccessPayload;
	optimisticId: OptimisticId;
	boardId: string;
	analyticsEvent: UIAnalyticsEvent;
}) => {
	if (analyticsEvent) {
		const { issueIds, rankAfterId, rankBeforeId, columnId } = payload;
		fireTrackAnalytics(analyticsEvent, 'bulkIssueRank success', {
			boardId,
			issueIds,
			rankAfterId,
			rankBeforeId,
			columnId,
		});
	}
	return Observable.from([
		bulkIssueRankSuccess({ payload, optimisticId, analyticsEvent }),
		workRefreshData(REFRESH_SOURCE_BULK_ISSUE_RANK),
	]);
};

const handleBulkIssueRankError = ({
	payload,
	optimisticId,
	boardId,
	error,
	analyticsEvent,
}: {
	payload: BulkIssueRankFailurePayload;
	optimisticId: OptimisticId;
	boardId: string;
	error: FetchError;
	analyticsEvent: UIAnalyticsEvent;
}) => {
	log.safeErrorWithoutCustomerData(
		'bulk.issue.rank.failure',
		'Failed to rank multiple issues',
		error,
	);

	if (analyticsEvent && shouldFireFailureSLOMetric(error)) {
		const { issueIds, issueKeys, rankAfterId, rankBeforeId, columnId } = payload;
		fireTrackAnalytics(analyticsEvent, 'bulkIssueRank failed', {
			errorMessage: error.message ? error.message : error,
			statusCodeGroup: getStatusCodeGroup(error),
			boardId,
			issueIds,
			issueKeys,
			rankAfterId,
			rankBeforeId,
			columnId,
		});
	}

	return Observable.of(
		bulkIssueRankFailure({
			payload,
			optimisticId,
			errorMessages: parseJiraErrorMessages(error),
			analyticsEvent,
		}),
	);
};

const doBulkIssueRank = ({
	request,
	columnId,
	boardId,
	analyticsEvent,
}: {
	request: BulkIssuesRankRequestPayload;
	columnId: ColumnId;
	boardId: string;
	analyticsEvent: UIAnalyticsEvent;
}): Observable<Action> => {
	const { issueIds, issueKeys, rankAfterId, rankBeforeId } = request;

	if (isNil(issueIds)) {
		return Observable.empty<never>();
	}

	const bulkIssueRankOptimisticAction = bulkIssueRankOptimistic({
		issueIds,
		rankAfterId,
		rankBeforeId,
	});

	const optimisticId = bulkIssueRankOptimisticAction.meta.optimistic.id;

	return Observable.merge(
		Observable.of(bulkIssueRankOptimisticAction),
		bulkIssueRankService(request)
			.flatMap(() =>
				handleBulkIssueRankSuccess({
					payload: { issueIds, rankAfterId, rankBeforeId, columnId },
					boardId,
					optimisticId,
					analyticsEvent,
				}),
			)
			.catch((error) =>
				handleBulkIssueRankError({
					payload: { issueIds, issueKeys, rankAfterId, rankBeforeId, columnId },
					boardId,
					optimisticId,
					error,
					analyticsEvent,
				}),
			),
	);
};

export function bulkIssueRankTransitionRequestEpic(
	action$: ActionsObservable,
	store: MiddlewareAPI,
): Observable<Action> {
	return action$
		.ofType(BULK_ISSUE_RANK_TRANSITION_REQUEST)
		.mergeMap((action: BulkIssueRankTransitionRequestAction) => {
			const state = store.getState();
			const isCMPBoard = getIsCMPBoard(state);
			const { rankCustomFieldId, boardIsRankable } = getRankConfig(state);

			const {
				payload: { issueIds, ...restPayload },
				meta: { analyticsEvent },
			} = action;

			const {
				sourceColumnId,
				destinationColumnId,
				rankAfterIssueId,
				rankBeforeIssueId,
				transitionId,
			} = restPayload;

			// Transition screen check only works for single issue
			if (!isNil(issueIds) && issueIds.length > 1) {
				const canBulkRankIssues =
					isCMPBoard &&
					boardIsRankable &&
					sourceColumnId === destinationColumnId &&
					isNil(transitionId);

				// TNK-628: bulk issue rank within the same column
				if (canBulkRankIssues) {
					const request: BulkIssuesRankRequestPayload = {
						customFieldId: rankCustomFieldId,
						issueIds,
						issueKeys: getIssueKeysFromIssueIds(state)(issueIds),
						rankAfterId: rankAfterIssueId ?? null,
						rankBeforeId: rankBeforeIssueId ?? null,
					};

					const { boardId } = makeServiceContext(state);

					return doBulkIssueRank({
						request,
						columnId: destinationColumnId,
						boardId,
						analyticsEvent,
					});
				}

				const hasScreen = checkIfAnyIssueHasScreen(state, action.payload);
				if (hasScreen) {
					return Observable.empty<never>();
				}
			}

			const getSortedIssuedIds = () => {
				const orderedAllIssueIds = isCMPBoard
					? boardOrderedIssueIdsSelector(state)
					: [...getOrderedIssueIds(state), ...getOrderedIssueChildrenIds(state)];

				const orderedIssueIds = orderedAllIssueIds.filter((issueId) => issueIds.includes(issueId));

				// Fire the requests in the reverse order when ranking after an issue, so we will receive the right order within optimistic update
				if (rankAfterIssueId) {
					orderedIssueIds.reverse();
				}
				return orderedIssueIds;
			};

			const batchId = uuid.v4();
			const shouldRetainRankingOrder = isCMPBoard && transitionId;
			const mode = shouldRetainRankingOrder ? 'TRANSITION_ONLY' : 'TRANSITION_AND_RANK';
			const bufferCount = shouldRetainRankingOrder ? 1 : issueIds.length;
			const requestActions$ = Observable.from(
				shouldRetainRankingOrder
					? [
							issueRankTransitionRequest({
								issueIds,
								batchId,
								mode,
								...restPayload,
								analyticsEvent,
							}),
						]
					: getSortedIssuedIds().map((issueId) =>
							issueRankTransitionRequest({
								issueIds: [issueId],
								batchId,
								mode,
								...restPayload,
								analyticsEvent,
							}),
						),
			);

			const resultActions$ = action$
				.ofType(ISSUE_RANK_TRANSITION_SUCCESS, ISSUE_RANK_TRANSITION_FAILURE)
				.filter((resultAction) => resultAction.payload.batchId === batchId)
				.bufferCount(bufferCount)
				.flatMap((bulkRankTransitionActions) => {
					const success = bulkRankTransitionActions.every(
						({ type }) => type === ISSUE_RANK_TRANSITION_SUCCESS,
					);

					const actions: Action[] = [];
					if (isCMPBoard && issueIds.length === 1) {
						const issueChild = getIssueChildren(state)[issueIds[0]];
						const isDestinationLastColumn = isLastColumn(state)(destinationColumnId);
						const isMoveIssueChildToFinalColumn = !!issueChild && isDestinationLastColumn;

						if (isMoveIssueChildToFinalColumn && issueChild?.parentId) {
							actions.push(issueChildrenInFinalColumnRequest(issueChild.parentId));
						}
					}

					if (isCMPBoard || !success) {
						actions.push(workRefreshData(REFRESH_SOURCE_TRANSITION));
					}

					return actions;
				});

			return requestActions$.merge(resultActions$);
		});
}
