import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type {
	UseFetchError,
	TrackDataFetchAnalyticsProps,
	TrackDataFetchAnalyticsReturn,
} from '../types.tsx';
import { statusCodeGroups, analyticsStatuses } from './constants.tsx';

export const getStatusCodeGroup = (statusCode: number | null) => {
	if (statusCode === null) {
		return statusCodeGroups.UNKNOWN;
	}

	if (statusCode >= 100 && statusCode <= 199) {
		return statusCodeGroups.INFORMATIONAL_RESPONSES;
	}
	if (statusCode >= 200 && statusCode <= 299) {
		return statusCodeGroups.SUCCESS_RESPONSES;
	}
	if (statusCode >= 300 && statusCode <= 399) {
		return statusCodeGroups.REDIRECTS;
	}

	if (statusCode >= 400 && statusCode <= 499) {
		return statusCodeGroups.CLIENT_ERRORS;
	}

	if (statusCode >= 500 && statusCode <= 599) {
		return statusCodeGroups.SERVER_ERRORS;
	}

	return statusCodeGroups.UNKNOWN;
};

const includeSample = (sampleRate: number): boolean => Math.random() <= 1 / sampleRate;

const noopAnalytics: TrackDataFetchAnalyticsReturn = Object.freeze({
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	start: () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	succeeded: () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	failed: () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	aborted: () => {},
});

export const trackDataFetchAnalytics = ({
	action = 'dataFetch',
	actionSubject,
	packageName,
	createAnalyticsEvent,
	sampleRate,
}: TrackDataFetchAnalyticsProps): Readonly<TrackDataFetchAnalyticsReturn> => {
	if (actionSubject == null) {
		return noopAnalytics;
	}

	if (sampleRate != null && !includeSample(sampleRate)) {
		return noopAnalytics;
	}

	// Note: Operational analytics do NOT use actionSubjectId!
	const actionSubjectAndAction = `${actionSubject} ${action}`;

	return Object.freeze({
		start: () => {
			fireOperationalAnalytics(createAnalyticsEvent({}), actionSubjectAndAction, {
				status: analyticsStatuses.STARTED,
				packageName,
			});
		},

		succeeded: () => {
			fireOperationalAnalytics(createAnalyticsEvent({}), actionSubjectAndAction, {
				statusCodeGroup: '2xx',
				status: analyticsStatuses.SUCCEEDED,
				packageName,
			});
		},

		failed: (e?: UseFetchError) => {
			const error =
				e !== null && e !== undefined ? e : new Error('Unknown error, e should be present');

			if (!isClientFetchError(e)) {
				fireOperationalAnalytics(createAnalyticsEvent({}), actionSubjectAndAction, {
					statusCodeGroup: getStatusCodeGroup(error instanceof FetchError ? error.statusCode : -1),
					status: analyticsStatuses.FAILED,
					packageName,
				});

				return;
			}

			// No network connection error
			fireErrorAnalytics({
				meta: {
					id: actionSubject,
					packageName,
				},
				error,
				sendToPrivacyUnsafeSplunk: true,
			});
		},

		aborted: () => {
			fireOperationalAnalytics(createAnalyticsEvent({}), actionSubjectAndAction, {
				status: analyticsStatuses.ABORTED,
				packageName,
			});
		},
	});
};
