export const DETAIL_VIEW_SIDEBAR = 'SIDEBAR' as const;
export const DETAIL_VIEW_MODAL = 'MODAL' as const;
export const DETAIL_VIEW_DEFAULT = 'DEFAULT' as const;
export const BOARD_DETAIL_VIEW_MODE = 'board-detail-view-mode';

export type SwimlaneModeId =
	| 'NO_SWIMLANE'
	| 'SWIMLANE_BY_ASSIGNEE'
	| 'SWIMLANE_BY_PARENT_ISSUE'
	| 'SWIMLANE_BY_SUBTASK'
	| 'SWIMLANE_BY_JQL'
	| 'SWIMLANE_BY_PROJECT'
	| 'SWIMLANE_BY_ASSIGNEE_UNASSIGNED_FIRST'
	| 'SWIMLANE_BY_TEAM'
	| 'SWIMLANE_BY_REQUEST_TYPE';

export type SwimlaneTypeName =
	| 'none'
	| 'jql'
	| 'assignee'
	| 'assigneeunassignedfirst'
	| 'issueparent'
	| 'issuechildren'
	| 'project'
	| 'team'
	| 'requesttype';

export const swimlaneModeToStrategies: { [key in SwimlaneModeId]: SwimlaneTypeName } = {
	NO_SWIMLANE: 'none',
	SWIMLANE_BY_JQL: 'jql',
	SWIMLANE_BY_ASSIGNEE: 'assignee',
	SWIMLANE_BY_ASSIGNEE_UNASSIGNED_FIRST: 'assigneeunassignedfirst',
	SWIMLANE_BY_PARENT_ISSUE: 'issueparent',
	SWIMLANE_BY_SUBTASK: 'issuechildren',
	SWIMLANE_BY_PROJECT: 'project',
	SWIMLANE_BY_TEAM: 'team',
	SWIMLANE_BY_REQUEST_TYPE: 'requesttype',
} as const;
