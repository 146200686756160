import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	issueTitle: {
		id: 'automation-menu.common.issue-title',
		defaultMessage: 'Issue',
		description: 'Title of the menu item for issue triggers category',
	},
	issueDescription: {
		id: 'automation-menu.common.issue-description',
		defaultMessage: 'Created, transitioned, updated and more',
		description: 'Description of the menu item for issue triggers category',
	},
	devopsTitle: {
		id: 'automation-menu.common.devops-title',
		defaultMessage: 'Branch, commit or pull requests ',
		description: 'Title of the menu item for devops triggers category',
	},
	devopsDescription: {
		id: 'automation-menu.common.devops-description',
		defaultMessage: 'Created, merged, declined',
		description: 'Description of the menu item for devops triggers category',
	},
	devopsBuildAndDeployTitle: {
		id: 'automation-menu.common.devops-build-and-deploy-title',
		defaultMessage: 'Deployment or build',
		description: 'Title of the menu item for devops build and deployment triggers category',
	},
	devopsBuildAndDeployDescription: {
		id: 'automation-menu.common.devops-build-and-deploy-description',
		defaultMessage: 'Succeeded, failed and more',
		description: 'Description of the menu item for devops build and deployment triggers category',
	},
	integrationTitle: {
		id: 'automation-menu.common.integration-title',
		defaultMessage: 'Integration',
		description: 'Title of the menu item for integration triggers category',
	},
	integrationDescription: {
		id: 'automation-menu.common.integration-description',
		defaultMessage: 'Trigger rules using incoming webhooks',
		description: 'Description of the menu item for integration triggers category',
	},
	scheduleTitle: {
		id: 'automation-menu.common.schedule-title',
		defaultMessage: 'Schedule',
		description: 'Title of the menu item for schedule triggers category',
	},
	scheduleDescription: {
		id: 'automation-menu.common.schedule-description',
		defaultMessage: 'Trigger rules on a regular schedule',
		description: 'Description of the menu item for schedule triggers category',
	},
	allTriggersTitle: {
		id: 'automation-menu.common.all-triggers-title',
		defaultMessage: 'View all triggers',
		description: 'Title of the menu item for all triggers',
	},
	allTriggersDescription: {
		id: 'automation-menu.common.all-triggers-description',
		defaultMessage: 'Sprints, versions and more',
		description: 'Description of the menu item for all triggers',
	},
});
