import { memo, useLayoutEffect } from 'react';
import { runRecalculateSubscribers } from '@atlassian/jira-software-fast-virtual/src/services/use-element-offset/index.tsx';
import { useBoardSelector } from '../../../../../../state/index.tsx';
import { getSwimlaneMode } from '../../../../../../state/selectors/swimlane/swimlane-mode-selectors.tsx';
import { getCollapsedSwimlanes } from '../../../../../../state/selectors/swimlane/swimlane-selectors.tsx';
import { workFilteredIssuesSelector } from '../../../../../../state/selectors/work/work-selectors.tsx';

/**
 * fast-virtual **must** have runRecalculateSubscribers calls manually added
 * when the offsets are known to have changed, otherwise there will be flickers.
 */
export const ForceRecalculateSubscribers = memo(() => {
	const filteredCards = useBoardSelector(workFilteredIssuesSelector);
	const swimlaneMode = useBoardSelector(getSwimlaneMode);
	const collapsedSwimlaneIds = useBoardSelector(getCollapsedSwimlanes);

	useLayoutEffect(() => {
		runRecalculateSubscribers();
	}, [filteredCards, swimlaneMode, collapsedSwimlaneIds]);

	return null;
});
