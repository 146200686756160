/** @jsx jsx */
import React, { forwardRef } from 'react';
import { css, jsx } from '@compiled/react';
import type { ButtonProps } from '@atlaskit/button';

type Props = ButtonProps;

const Label = forwardRef<HTMLLabelElement, Props>((props, ref) => (
	// eslint-disable-next-line jsx-a11y/label-has-associated-control
	<label
		ref={ref}
		css={styles}
		htmlFor={props.id}
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		className={props.className}
		// eslint-disable-next-line react/no-children-prop
		children={props.children}
	/>
));

export default Label;
const styles = css({ userSelect: 'none' });
