import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	remainingDaysLabel: {
		id: 'software.main.header.sprint-remaining-days.remaining-days-label',
		defaultMessage: '{remainingDays} {remainingDays, plural, one {day} other {days}}',
		description: 'label shown on tooltip for sprint remaining days in board header',
	},
	remainingDays: {
		id: 'software.main.header.sprint-remaining-days.remaining-days',
		defaultMessage: '{remainingDays} {remainingDays, plural, one {day} other {days}} remaining',
		description: 'header shown in tooltip on label hover',
	},
	startDate: {
		id: 'software.main.header.sprint-remaining-days.start-date',
		defaultMessage: 'Start date:',
		description: 'start date shown in tooltip on label hover',
	},
	endDate: {
		id: 'software.main.header.sprint-remaining-days.end-date',
		defaultMessage: 'Projected end date:',
		description: 'projected date shown in tooltip on label hover',
	},
	invalidDate: {
		id: 'software.main.header.sprint-remaining-days.invalid-date',
		defaultMessage: 'Invalid date',
		description: 'Fallback text for an invalid date being parsed',
	},
});
