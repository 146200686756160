import React from 'react';
import { Anchor, xcss } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { proxyContextSafeUrl } from '@atlassian/jira-portfolio-3-common/src/api/index.tsx';
import type { Props } from './types.tsx';

export default function IssueLink(props: Props) {
	const { projectKey, issueKey, issueId, xcssStyles, children } = props;
	const fullIssueKey = issueKey ? `${projectKey}-${issueKey}` : projectKey;

	return (
		<Anchor
			href={proxyContextSafeUrl(`/browse/${fullIssueKey}`)}
			rel="noopener noreferrer"
			id={issueId && `issue-link-${issueId}`}
			xcss={[anchorStyles, ...(xcssStyles ? [xcssStyles] : [])]}
			target="_blank"
			draggable={fg('allows_dragging_an_issue_from_issue_link') ? false : undefined}
		>
			{children || fullIssueKey}
		</Anchor>
	);
}

const anchorStyles = xcss({
	outlineOffset: 'space.0',
});
