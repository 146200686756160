import { WORK_DATA_SET, WORK_DATA_CRITICAL_SET } from '../../../actions/work/index.tsx';
import type { Action } from '../../../types.tsx';
import type { IssueLinkTypesState } from './types.tsx';

export const IssueLinkTypesReducer = (
	state: IssueLinkTypesState = [],
	action: Action,
): IssueLinkTypesState => {
	if (action.type === WORK_DATA_SET || action.type === WORK_DATA_CRITICAL_SET) {
		return action.payload.issueLinkTypes || [];
	}

	return state;
};
