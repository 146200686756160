import React, { type MouseEvent, memo, useCallback, useMemo, useState, useEffect } from 'react';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';
import omit from 'lodash/omit';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { setMark } from '@atlassian/jira-common-performance/src/marks.tsx';
import performance from '@atlassian/jira-common-performance/src/performance.tsx';
import {
	type AnalyticsAttributes,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { CLEAR } from '../common/constants.tsx';
import type { Filters, FilterType } from '../common/types.tsx';
import SoftwareFiltersStateless from './stateless/index.tsx';
import type { APIProps, OnFilterChange } from './stateless/types.tsx';
import { getNormalizedFilters, onFilterChangeStartInteraction } from './utils.tsx';

export type Props = APIProps;

type CreateAnalyticsEvent =
	| ((data: { action: string; actionSubject: string }) => UIAnalyticsEvent)
	| null;

const clearAndSetPerformanceMark = (markKey: string) => {
	if (performance && performance.clearMarks) {
		performance.clearMarks(markKey);
	}
	setMark(markKey);
};

const useDebouncedOnChange = (
	onChange: OnFilterChange,
	createAnalyticsEvent: CreateAnalyticsEvent,
) => {
	const debouncedOnChange = useMemo(
		() =>
			debounce((filters: Filters, filterType: FilterType, attributes?: AnalyticsAttributes) => {
				if (createAnalyticsEvent) {
					const analyticsEvent = createAnalyticsEvent({
						action: 'changed',
						actionSubject: 'filterBar',
					});

					fireUIAnalytics(analyticsEvent, 'changeFilterBar', {
						...attributes,
						filterType,
					});

					onChange(getNormalizedFilters(filters), filterType, attributes, analyticsEvent);
				} else {
					onChange(getNormalizedFilters(filters), filterType, attributes);
				}
			}, 5),
		[onChange, createAnalyticsEvent],
	);
	return debouncedOnChange;
};

const useOnChangeFilterValues = (
	filter: Filters,
	onChange: OnFilterChange,
	// @ts-expect-error - TS7006 - Parameter 'onChangeInteractionStart' implicitly has an 'any' type.
	onChangeInteractionStart,
	// @ts-expect-error - TS7006 - Parameter 'onCustomFilterChangeInteractionStart' implicitly has an 'any' type.
	onCustomFilterChangeInteractionStart,
	createAnalyticsEvent: CreateAnalyticsEvent,
	onClearCustomFilterInteractionStart: () => void,
	isCompanyManaged: boolean,
) => {
	const [localFilter, setFilters] = useState<Filters>(filter);

	useEffect(() => {
		setFilters(filter);
	}, [filter]);
	const debouncedOnChange = useDebouncedOnChange(onChange, createAnalyticsEvent);
	const onChangeFilterValues = useCallback(
		(filters: Filters, fieldType: FilterType | null, attributes?: AnalyticsAttributes) => {
			if (fieldType !== null) {
				clearAndSetPerformanceMark('filterBarStart');
				onFilterChangeStartInteraction(
					fieldType,
					onChangeInteractionStart,
					onCustomFilterChangeInteractionStart,
				);
				const values = filters[fieldType] ?? [];
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
				let updatedFilter: Filters = { ...localFilter, [fieldType]: values as any };
				if (values.length === 0) {
					isCompanyManaged && onClearCustomFilterInteractionStart();
					updatedFilter = omit(localFilter, fieldType);
				}
				setFilters(updatedFilter);
				// fieldType could in theory not be accurate on a final debounced call,
				// however there's only a 5ms window during which this may happen.

				debouncedOnChange(updatedFilter, fieldType, attributes);
			}
		},
		[
			onChangeInteractionStart,
			onCustomFilterChangeInteractionStart,
			setFilters,
			localFilter,
			debouncedOnChange,
			isCompanyManaged,
			onClearCustomFilterInteractionStart,
		],
	);

	const clearFilter = useCallback(() => {
		const emptyFilters = {
			TEXT: undefined,
			ASSIGNEE: undefined,
			LABEL: undefined,
			SPRINT: undefined,
			ISSUE_PARENT: undefined,
			ISSUE_PROJECT: undefined,
			ISSUE_TYPE: undefined,
			VERSION: undefined,
			REQUEST_TYPE: undefined,
			STATUS_CATEGORY: undefined,
			STATUS: undefined,
			CUSTOM_FILTER: undefined,
			DEPENDENCIES: undefined,
		};
		isCompanyManaged && onClearCustomFilterInteractionStart();

		setFilters(emptyFilters);
		// A 2nd `null` argument is required so that the analytics event doesn't get treated as the
		// filter type on filter analytics.
		onChange(getNormalizedFilters(emptyFilters), null, undefined);
	}, [onChange, isCompanyManaged, onClearCustomFilterInteractionStart]);

	return [localFilter, onChangeFilterValues, clearFilter] as const;
};

const SoftwareFilters = (props: Props) => {
	const {
		filter,
		onChange,
		onClearAllButtonClicked,
		onChangeInteractionFeedback = noop,
		onChangeInteractionStart = noop,
		onCustomFilterChangeInteractionFeedback = noop,
		onCustomFilterChangeInteractionStart = noop,
		onExperienceSuccess = noop,
		isCompanyManaged = false,
		onClearCustomFilterInteractionStart = noop,
	} = props;

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [localFilters, onChangeFilterValues, clearFilter] = useOnChangeFilterValues(
		filter,
		onChange,
		onChangeInteractionStart,
		onCustomFilterChangeInteractionStart,
		createAnalyticsEvent,
		onClearCustomFilterInteractionStart,
		isCompanyManaged,
	);

	useEffect(() => {
		onExperienceSuccess();
	}, [onExperienceSuccess]);

	useEffect(() => {
		if (!isEqual(filter, localFilters)) {
			clearAndSetPerformanceMark('filterBarStop');
			onChangeInteractionFeedback();
			if (!isEqual(filter.CUSTOM_FILTER, localFilters.CUSTOM_FILTER)) {
				onCustomFilterChangeInteractionFeedback();
			}
		}
	}, [filter, localFilters, onChangeInteractionFeedback, onCustomFilterChangeInteractionFeedback]);

	clearAndSetPerformanceMark('oldFilterBarStop');

	const onClear = useCallback(
		(event: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			// we always have to pass void entityType, so analytics events will always be as third argument
			clearFilter();
			onClearAllButtonClicked && onClearAllButtonClicked(event, analyticsEvent);
			const customAnalyticsEvent = createAnalyticsEvent({
				action: 'changed',
				actionSubject: 'filterBar',
			});
			fireUIAnalytics(customAnalyticsEvent, 'changeFilterBar', {
				filterType: CLEAR,
			});
		},
		[clearFilter, onClearAllButtonClicked, createAnalyticsEvent],
	);

	return (
		<SoftwareFiltersStateless
			{...props}
			filter={localFilters}
			onClearAllButtonClicked={onClear}
			onChange={onChangeFilterValues}
		/>
	);
};

export default memo<Props>(SoftwareFilters);
