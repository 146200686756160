import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	textFilter: {
		id: 'software.main.header.controls-bar.filter-bar.text-filter',
		defaultMessage: 'Search',
		description:
			'The placeholder of the input textbox used to enter text to filter issues by in the board view',
	},
	screenReaderInstruction: {
		id: 'software.main.header.controls-bar.filter-bar.screen-reader-instruction',
		defaultMessage:
			'As you type to search or apply filters, the board updates with issues to match.',
		description: 'Describes what the issue filters do for screen reader users.',
	},
	filterPlaceholder: {
		id: 'software.main.header.controls-bar.filter-bar.filter-placeholder',
		defaultMessage: 'Search board',
		description: 'The placeholder of the input textbox used to filter issues in the backlog view',
	},
	screenReaderInstructionIssueTermRefresh: {
		id: 'software.main.header.controls-bar.filter-bar.screen-reader-instruction-issue-term-refresh',
		defaultMessage:
			'As you type to search or apply filters, the board updates with work items to match.',
		description: 'Describes what the issue filters do for screen reader users.',
	},
});
