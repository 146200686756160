import escapeRegExp from 'lodash/escapeRegExp';
import isEqual from 'lodash/isEqual';
import uniqBy from 'lodash/uniqBy';
import memoizeOne from 'memoize-one';
import type { HighlightedTextPart } from './types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { HighlightedTextPart };

export const removeAccents = memoizeOne((text: string): string =>
	text.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
);

export const getHighlightRegExp = memoizeOne(
	(highlight: string[]): RegExp => {
		const escapedFilter = highlight.map((phrase) => escapeRegExp(phrase)).join('|');
		return new RegExp(escapedFilter, 'gmi');
	},
	(newArgs, lastArgs) => {
		if (newArgs.length !== lastArgs.length) {
			return false;
		}

		const equalityFn = isEqual;
		return newArgs.every((newArg, index) => equalityFn(newArg, lastArgs[index]));
	},
);

export const makeText = (content: string): HighlightedTextPart => ({ type: 'text', content });

export const makeHighlight = (content: string): HighlightedTextPart => ({
	type: 'highlight',
	content,
});

export const matchAll = (str: string, regExp: RegExp) => {
	const ms = [];
	let m = regExp.exec(str);
	while (m) {
		ms.push(m);
		m = regExp.exec(str);
	}
	return ms;
};

export const getParts = (highlight: string[] | null, text: string): HighlightedTextPart[] => {
	if (highlight == null || highlight.filter((phrase) => phrase !== '').length === 0) {
		return [makeText(text)];
	}

	const parts: Array<HighlightedTextPart> = [];

	// Long text lengths will cause "Invalid regular expression" error
	const truncatedHighlight = highlight.map((phrase) => phrase.substring(0, 255));

	const highlightRegExp = getHighlightRegExp(truncatedHighlight.filter((phrase) => phrase !== ''));

	const textWithoutAccents = removeAccents(text);
	const textMatches = matchAll(text, highlightRegExp);
	const textWithoutAccentsMatches = matchAll(textWithoutAccents, highlightRegExp);
	const highlightMatches = uniqBy([...textWithoutAccentsMatches, ...textMatches], 'index');

	let currentPosition = 0;
	highlightMatches.forEach((match) => {
		const matchText = text.slice(match.index, match.index + match[0].length);
		const leading = text.slice(currentPosition, match.index);

		if (leading !== '') {
			parts.push(makeText(leading));
		}

		parts.push(makeHighlight(matchText));
		currentPosition = match.index + matchText.length;
	});

	const trailing = text.slice(currentPosition);
	if (trailing !== '') {
		parts.push(makeText(trailing));
	}

	return parts;
};
