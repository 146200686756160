import React, { type ComponentType } from 'react';
import Placeholder from '@atlaskit/react-ufo/placeholder';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { Props } from './ui/types.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const FilterRefinementComponentAsync = lazyForPaint<ComponentType<Props>>(
	() => import(/* webpackChunkName: "async-filter-refinement" */ './ui/index'),
	{ ssr: false },
);

const FilterRefinementAsync: ComponentType<Props> = (props: Props) => (
	<Placeholder name="async-filter-refinement" fallback={<></>}>
		<FilterRefinementComponentAsync {...props} />
	</Placeholder>
);

export default FilterRefinementAsync;
