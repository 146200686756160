import get from 'lodash/get';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { RetryLink } from 'apollo-link-retry';
import { fragmentCacheRedirect, fragmentLinkState } from 'apollo-link-state-fragment';
import type {
	ApolloClientConfig,
	CacheShape,
} from '@atlassian/jira-apollo-multiple-clients/src/common/types.tsx';
import getXsrfToken from '@atlassian/jira-platform-xsrf-token/src/index.tsx';
import {
	logApolloError,
	logApolloNetworkError,
	logApolloRetry,
	logRestParsingError,
} from '@atlassian/jira-software-swag/src/services/log-error/index.tsx';
import { UFOLoggerLink } from '@atlassian/ufo-apollo-log/link';
import introspectionQueryResultData from './common/graphql/fragment-types.json';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { getErrorFlag as getFlagFromError } from '@atlassian/jira-software-swag/src/services/error-to-flag/index.tsx';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports, jira/restricted/graphql-tag
export { default as gqlTagSwag } from 'graphql-tag';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { FlagFromError } from './services/get-flag-from-error/index.tsx';

const retryLink = new RetryLink({
	attempts: {
		max: 2,
		retryIf: (networkError, operation) => {
			const { operationName } = operation;
			const statusCode = get(networkError, ['statusCode'], null);
			// retry once when SWAG Node is dead.
			const shouldRetry = [502, 503, 504].includes(Number(statusCode));

			if (shouldRetry) {
				logApolloRetry({ queryName: operationName, statusCode });
			}

			return shouldRetry;
		},
	},
});

const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
	const { operationName } = operation;

	if (graphQLErrors) {
		if (Array.isArray(graphQLErrors)) {
			graphQLErrors.map(({ path, extensions }) => {
				const statusCode = get(extensions, ['statusCode'], null);
				const errorType = get(extensions, ['errorType'], null);

				return logApolloError(path, statusCode, operationName, errorType);
			});
		} else {
			logRestParsingError('Unexpected error message format', JSON.stringify(graphQLErrors));
		}
	}

	if (networkError) {
		const statusCode = get(networkError, ['statusCode'], null);
		logApolloNetworkError(networkError.toString(), statusCode, operationName);
	}
});

// Exporting fragmentMatcher to use it in StoryBook examples
const fragmentMatcher = new IntrospectionFragmentMatcher({
	introspectionQueryResultData,
});

const cacheConfig = {
	cacheRedirects: {
		Query: {
			...fragmentCacheRedirect(),
		},
	},
	freezeResults: true,
	fragmentMatcher,
} as const;

const cache = new InMemoryCache(cacheConfig);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const customFetch = (uri: any, options: any) => {
	const body = options && options.body ? JSON.parse(options.body) : null;
	return fetch(`${uri}?operation=${body ? body.operationName : 'unknown'}`, options);
};

const xsrfLink = () =>
	setContext((operation, previousContext) => {
		const { headers } = previousContext;
		// set empty xsrfToken for SSR as cookies are unavailable
		const xsrfToken = __SERVER__ ? '' : getXsrfToken();

		if (!xsrfToken) {
			return previousContext;
		}

		return {
			...previousContext,
			headers: {
				...headers,
				'atl-xsrf-token': xsrfToken,
			},
		};
	});

const clientCreator = (baseUrl: string) =>
	new ApolloClient<CacheShape>({
		name: 'SWAG',
		link: ApolloLink.from([
			retryLink,
			xsrfLink(),
			errorLink,
			UFOLoggerLink,
			fragmentLinkState(cache),
			new HttpLink({
				uri: `${baseUrl}/jsw2/graphql`,
				credentials: 'same-origin',
				fetch: customFetch,
			}),
		]),
		cache,
		assumeImmutableResults: true,
	});

export type SwagApolloClient = ApolloClient<CacheShape>;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default {
	clientId: 'SWAG',
	clientCreator,
} as ApolloClientConfig;
