import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	presenceOnlineLabel: {
		id: 'software-filters.filters.assignee.stateless.avatar.presence-online-label',
		defaultMessage: '{name} (online)',
		description: 'The alt text of the avatar for screen reader when the user is online.',
	},
	presenceOfflineLabel: {
		id: 'software-filters.filters.assignee.stateless.avatar.presence-offline-label',
		defaultMessage: '{name} (offline)',
		description: 'The alt text of the avatar for screen reader when the user is offline.',
	},
	avatarInputCheckboxLabel: {
		id: 'software-filters.filters.assignee.stateless.avatar.avatar-input-checkbox-label',
		defaultMessage: 'assignee filter avatar',
		description: 'label for the avatar button filter by assignee',
	},
});
