import type { State } from '@atlassian/jira-issue-transition-trigger/src/common/types.tsx';

export const OPEN_ISSUE_TRANSITION_MODAL =
	'state.actions.issue.update-issue-parent-modal.OPEN_ISSUE_TRANSITION_MODAL' as const;

export type OpenIssueTransitionModalAction = {
	type: typeof OPEN_ISSUE_TRANSITION_MODAL;
	payload: {
		issueId: string;
		issueKey: string;
		transitionId: string;
		onDialogSuccess?: State['onDialogSuccess'];
		onDialogError?: State['onDialogError'];
		onDialogCancel?: State['onDialogCancel'];
	};
};

export const openIssueTransitionModal = (
	payload: OpenIssueTransitionModalAction['payload'],
): OpenIssueTransitionModalAction => ({
	type: OPEN_ISSUE_TRANSITION_MODAL,
	payload,
});

export const CLOSE_ISSUE_TRANSITION_MODAL =
	'state.actions.issue.update-issue-parent-modal.CLOSE_ISSUE_TRANSITION_MODAL' as const;

export type CloseIssueTransitionModalAction = {
	type: typeof CLOSE_ISSUE_TRANSITION_MODAL;
};

export const closeIssueTransitionModal = (): CloseIssueTransitionModalAction => ({
	type: CLOSE_ISSUE_TRANSITION_MODAL,
});

export type Action = OpenIssueTransitionModalAction | CloseIssueTransitionModalAction;
