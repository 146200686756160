import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	timeUnitWeek: {
		id: 'time-tracking-formatter.time-unit-week',
		defaultMessage: 'w',
		description: 'The time suffix (abbreviation) used for the "week" unit',
	},
	timeUnitDay: {
		id: 'time-tracking-formatter.time-unit-day',
		defaultMessage: 'd',
		description: 'The time suffix (abbreviation) used for the "day" unit',
	},
	timeUnitHour: {
		id: 'time-tracking-formatter.time-unit-hour',
		defaultMessage: 'h',
		description: 'The time suffix (abbreviation) used for the "hour" unit',
	},
	timeUnitMinute: {
		id: 'time-tracking-formatter.time-unit-minute',
		defaultMessage: 'm',
		description: 'The time suffix (abbreviation) used for the "minute" unit',
	},
	timeUnitLongWeek: {
		id: 'time-tracking-formatter.time-unit-long-week',
		defaultMessage: '{week, plural, zero {weeks} one {week} other {weeks} }',
		description: 'The time suffix (long form) used for the singular and plural "week" unit',
	},
	timeUnitLongDay: {
		id: 'time-tracking-formatter.time-unit-long-day',
		defaultMessage: '{day, plural, zero {days} one {day} other {days} }',
		description: 'The time suffix (long form) used for the singular and plural "day" unit',
	},
	timeUnitLongHour: {
		id: 'time-tracking-formatter.time-unit-long-hour',
		defaultMessage: '{hour, plural, zero {hours} one {hour} other {hours} }',
		description: 'The time suffix (long form) used for the singular and plural "hour" unit',
	},
	timeUnitLongMinute: {
		id: 'time-tracking-formatter.time-unit-long-minute',
		defaultMessage: '{minute, plural, zero {minutes} one {minute} other {minutes} }',
		description: 'The time suffix (long form) used for the singular and plural "minute" unit',
	},
});
