import without from 'lodash/without';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { expValEquals, UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { commonErrorAnalyticsMeta, getFeatureMap } from './constants.tsx';
import type {
	ToggleableBoardFeature,
	FeatureStatus,
	ToggleableFeatureKey,
	BoardFeature,
} from './types.tsx';

export const getStatus = (hasFeature: boolean): FeatureStatus =>
	hasFeature ? 'ENABLED' : 'DISABLED';

export const filterAndSort = (features: BoardFeature[]): ToggleableBoardFeature[] => {
	const [expConfig] = UNSAFE_noExposureExp('jsw_simplify_navigation_m2');

	const featureMap = getFeatureMap({
		isSimplifyNavExperimentEnabled: expConfig.get('isEnabled', false),
		isListViewEnabled: expValEquals('jsw_list_view', 'cohort', 'variation'),
	});
	return features
		.filter((f): f is ToggleableBoardFeature => Object.keys(featureMap).includes(f.feature))
		.sort((a, b) => featureMap[a.feature].order - featureMap[b.feature].order);
};

export const findDependentFeature = (
	features: ToggleableBoardFeature[],
	featureId: ToggleableFeatureKey,
): ToggleableBoardFeature | undefined => {
	const featureToDisable = features.find((f) => f.feature === featureId);
	// Will only show the modal when the current status is enabled for both the feature to disable and the dependent feature
	if (featureToDisable?.state !== 'ENABLED') {
		return undefined;
	}

	// For the scope of the feature toggle, we assume there is only one dependency for now
	return features.find((f) => f.prerequisites.includes(featureId) && f.state === 'ENABLED');
};

export const updateFeatureState =
	(featureId: ToggleableFeatureKey, dependentFeatureId?: ToggleableFeatureKey) =>
	(feature: ToggleableBoardFeature): ToggleableBoardFeature =>
		feature.feature === featureId || (dependentFeatureId && feature.feature === dependentFeatureId)
			? {
					...feature,
					state: feature.state === 'ENABLED' ? 'DISABLED' : 'ENABLED',
				}
			: feature;
/**
 * since featureMap is our source of truth
 * check if there is any unmatched key after filtering through the api response
 * @param features
 */
export const fireErrorAnalyticsOnUnmatchFeatureKey = (
	filteredFeatures: ToggleableBoardFeature[],
) => {
	const [expConfig] = UNSAFE_noExposureExp('jsw_simplify_navigation_m2');

	const featureMap = getFeatureMap({
		isSimplifyNavExperimentEnabled: expConfig.get('isEnabled', false),
		isListViewEnabled: expValEquals('jsw_list_view', 'cohort', 'variation'),
	});
	if (filteredFeatures.length < Object.keys(featureMap).length) {
		const unmatchedFeatureKeys = without(
			Object.keys(featureMap),
			...filteredFeatures.map((f) => f.feature),
		);
		fireErrorAnalytics({
			error: new Error("Some feature keys don't match . Please check API response"),
			meta: {
				...commonErrorAnalyticsMeta,
				id: 'unmatchFeatureKey',
			},
			attributes: {
				unmatchedFeatureKeys: unmatchedFeatureKeys.toString(),
			},
		});
	}
};
