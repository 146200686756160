// eslint-disable-next-line jira/restricted/react
import React, { PureComponent, useCallback, useEffect, useRef } from 'react';
import { findDOMNode } from 'react-dom';
import AkInlineDialog from '@atlaskit/inline-dialog';
import type { WithCloseOnScrollProps } from './types.tsx';

const INLINE_DIALOG_PADDING = 32;
const THRESHOLD = 50;
const TIMEOUT = 500;

function WithCloseOnScroll({ isOpen, children }: WithCloseOnScrollProps) {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const containerElement = document.body;
	const distanceXRef = useRef<number>(0);
	const distanceYRef = useRef<number>(0);
	const timeoutIdRef = useRef<unknown>();

	const shouldClose = () =>
		Math.abs(distanceXRef.current) >= THRESHOLD || Math.abs(distanceYRef.current) >= THRESHOLD;

	const resetThresholdChecking = useCallback(() => {
		distanceYRef.current = 0;
		distanceXRef.current = 0;
		timeoutIdRef.current = undefined;
	}, []);

	const closeDialog = useCallback(() => containerElement?.click(), [containerElement]);

	// Function will check if the scroll delta (both horizontal and vertical) is
	// larger than the threshold and close the dialog.
	// If the scroll delta is less than the threshold, we sum all scroll deltas in 500ms.
	// If the sum is larger than the threshold, we close the dialog.
	// Reasoning: want to prevent the dialog from closing immediately if the user accidentally scrolls a few pixels.
	const handleWheel = useCallback(
		(e: WheelEvent) => {
			e.stopPropagation();

			distanceXRef.current += e.deltaX;
			distanceYRef.current += e.deltaY;

			if (shouldClose()) {
				closeDialog();
				resetThresholdChecking();
			}

			if (!timeoutIdRef.current) {
				timeoutIdRef.current = setTimeout(() => {
					if (shouldClose()) {
						closeDialog();
					}

					resetThresholdChecking();
				}, TIMEOUT);
			}
		},
		[closeDialog, resetThresholdChecking],
	);

	useEffect(() => {
		if (isOpen) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.addEventListener('wheel', handleWheel, {
				passive: true,
			});
		} else {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.removeEventListener('wheel', handleWheel);
		}

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		return () => window.removeEventListener('wheel', handleWheel);
	}, [isOpen, handleWheel]);

	return <>{children}</>;
}
// eslint-disable-next-line jira/react/no-class-components, @typescript-eslint/no-explicit-any
export default class InlineDialog extends PureComponent<any> {
	static defaultProps = {
		testId: 'data-inline-dialog-content',
	};

	// eslint-disable-next-line react/sort-comp
	updateContainerStyles = () => {
		const { maxWidth, minWidth, isOpen, noPaddings, noHorizontalScroll, zIndex, overflow } =
			this.props;
		if (!isOpen) return;
		// eslint-disable-next-line react/no-find-dom-node
		const node = findDOMNode(this);
		// this should find dialog content container,
		// keep it updated as you upgrade @atlaskit/inline-dialog
		const container = node && node.nextElementSibling;
		if (!(container instanceof HTMLElement)) return;

		if (noPaddings) {
			container.style.padding = '0';
		}

		if (maxWidth) {
			const width = noPaddings ? maxWidth : maxWidth + INLINE_DIALOG_PADDING;
			container.style.maxWidth = `${width}px`;
			container.style.overflow = 'auto';
		}

		if (minWidth) {
			const width = noPaddings ? minWidth : minWidth + INLINE_DIALOG_PADDING;
			container.style.minWidth = `${width}px`;
			container.style.overflow = 'auto';
		}

		if (overflow) {
			container.style.overflow = overflow;
		}

		if (noHorizontalScroll) {
			container.style.maxWidth = 'initial';
		}

		container.style.maxHeight = 'initial';
		//  custom zIndex can be used only when we have 2 or more inline dialogs and has to define their priorities
		// initially it has been added because of https://bulldog.internal.atlassian.com/browse/JPOS-4104
		container.style.zIndex = zIndex;
	};

	componentDidMount() {
		this.updateContainerStyles();
	}

	componentDidUpdate() {
		this.updateContainerStyles();
	}

	render() {
		return this.props.closeOnScroll ? (
			<WithCloseOnScroll isOpen={this.props.isOpen}>
				{/* @ts-expect-error - TS2741 - Property 'content' is missing in type '{ children?: ReactNode; }' but required in type 'Pick<Pick<PropsWithChildren<InlineDialogProps>, "children" | "content" | "testId" | "strategy" | "isOpen" | "placement" | "onContentBlur" | "onContentClick" | "onContentFocus" | "onClose"> & RefAttributes<...> & WithContextProps, "children" | ... 10 more ... | "onClose">'. */}
				<AkInlineDialog {...this.props} />
			</WithCloseOnScroll>
		) : (
			// @ts-expect-error - TS2741 - Property 'content' is missing in type '{ children?: ReactNode; }' but required in type 'Pick<Pick<PropsWithChildren<InlineDialogProps>, "children" | "content" | "testId" | "strategy" | "isOpen" | "placement" | "onContentBlur" | "onContentClick" | "onContentFocus" | "onClose"> & RefAttributes<...> & WithContextProps, "children" | ... 10 more ... | "onClose">'.
			<AkInlineDialog {...this.props} />
		);
	}
}
