import React, { useEffect, useRef, type ReactElement, type ReactNode } from 'react';
import { SpotlightTransition } from '@atlaskit/onboarding';
import { Text } from '@atlaskit/primitives';
import { N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import CoordinationClient from '@atlassian/jira-engagement/src/ui/coordination-client/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	ContextualAnalyticsData,
	MODAL,
	fireScreenAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { nextBestTaskFeatureResource } from '@atlassian/jira-router-resources-next-best-task/src/services/index.tsx';
import { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import { useResource } from '@atlassian/react-resource-router';
import {
	NEXT_BEST_TASK_ANALYTICS,
	WORK_SUGGESTIONS_SPOTLIGHT_MESSAGE_ID,
	WORK_SUGGESTIONS_VIEW_SETTINGS_SPOTLIGHT_PANEL_NAME,
	WORK_SUGGESTIONS_VIEW_SETTINGS_SPOTLIGHT_VIEW_SETTINGS_STEP_NAME,
} from '../../common/constants.tsx';
import {
	useBoardWorkSuggestionsSpotlightContainer,
	BoardWorkSuggestionsSpotlightContainer,
} from '../../services/spotlight-container/index.tsx';

import messages from './messages.tsx';

type SpotlightProps = {
	shouldShowSpotlight: boolean;
	children: JSX.Element;
};

type SpotlightInnerProps = {
	onStop?: () => void;
};

type StopFunction = () => void;

type CoordinationStopProviderProps = {
	stop?: StopFunction;
	children: (arg1: StopFunction | undefined) => ReactElement;
};

const ViewSettingsSpotlightContainerInner = ({ onStop }: SpotlightInnerProps) => {
	const { formatMessage } = useIntl();
	const clickedOnNext = useRef<boolean>(false);
	const hadInitSpotlight = useRef<boolean>(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [
		{ activeViewSettingsSpotlight, hasViewedViewSettingsSpotlight, openViewSettings },
		{ onViewSettingsSpotlightInit, onViewSettingsSpotlightNext, onViewSettingsSpotlightEnd },
	] = useBoardWorkSuggestionsSpotlightContainer();

	const fireAnalytic = (action: string) => {
		fireUIAnalytics(
			createAnalyticsEvent({
				actionSubject: 'button',
				action: 'clicked',
			}),
			`${NEXT_BEST_TASK_ANALYTICS}.spotlight.action`,
			{
				action,
			},
		);
	};

	const end = (action: string) => {
		fireAnalytic(action);
		onViewSettingsSpotlightEnd();
		onStop?.();
	};

	const next = () => {
		fireAnalytic('next');
		if (clickedOnNext.current) {
			// Don't want user to click on multiple times and re-trigger this.
			return;
		}
		if (openViewSettings) {
			openViewSettings();
			// It has problem with view settings popup (not panel),
			// if we are not adding setTimeout in here for some reason
			setTimeout(() => {
				onViewSettingsSpotlightNext();
			});
		}
		clickedOnNext.current = true;
	};

	const shouldIgnoreSpotlight =
		activeViewSettingsSpotlight === null || hasViewedViewSettingsSpotlight;

	useEffect(() => {
		// If we render this component we will set has view spotlight to false
		if (hasViewedViewSettingsSpotlight && !hadInitSpotlight.current) {
			onViewSettingsSpotlightInit();
			hadInitSpotlight.current = true;
		}
	}, [hasViewedViewSettingsSpotlight, onViewSettingsSpotlightInit]);

	useEffect(() => {
		if (!shouldIgnoreSpotlight) {
			fireScreenAnalytics(createAnalyticsEvent({}), {
				step: activeViewSettingsSpotlight + 1,
			});
		}
	}, [createAnalyticsEvent, shouldIgnoreSpotlight, activeViewSettingsSpotlight]);

	const renderWorkSuggestionsSpotlight = () => {
		if (shouldIgnoreSpotlight) {
			return null;
		}
		const spotlights = [
			<JiraSpotlight
				// if no onOpenViewSettings callback only show one step and end
				actionsBeforeElement={openViewSettings ? '1/2' : '1/1'}
				actions={
					openViewSettings
						? [
								// If we have view settings open callback show next button
								// otherwise show ok button
								{
									onClick: () => next(),
									text: formatMessage(messages.next),
								},
							]
						: [
								{
									onClick: () => end('dismiss'),
									text: formatMessage(messages.dismiss),
								},
							]
				}
				target={WORK_SUGGESTIONS_VIEW_SETTINGS_SPOTLIGHT_PANEL_NAME}
				key={WORK_SUGGESTIONS_VIEW_SETTINGS_SPOTLIGHT_PANEL_NAME}
				dialogPlacement="right top"
				targetRadius={8}
				messageId="insights-next-best-task.ui.view-settings-spotlight.jira-spotlight"
				messageType="engagement"
			>
				{formatMessage(messages.workSuggestionsViewSettingsPanelSpotlight)}
			</JiraSpotlight>,
			<JiraSpotlight
				actionsBeforeElement="2/2"
				actions={[
					{
						onClick: () => end('ok'),
						text: formatMessage(messages.ok),
					},
				]}
				target={WORK_SUGGESTIONS_VIEW_SETTINGS_SPOTLIGHT_VIEW_SETTINGS_STEP_NAME}
				key={WORK_SUGGESTIONS_VIEW_SETTINGS_SPOTLIGHT_VIEW_SETTINGS_STEP_NAME}
				targetRadius={3}
				targetBgColor={token('color.background.input', N0)}
				dialogPlacement="bottom right"
				messageId="insights-next-best-task.ui.view-settings-spotlight.jira-spotlight.1"
				messageType="engagement"
			>
				<Text as="span">
					{formatMessage(messages.workSuggestionsViewSettingsSettingsSpotlight, {
						b: (chunks: ReactNode[]) => (
							<Text weight="bold" as="strong">
								{chunks}
							</Text>
						),
					})}
				</Text>
			</JiraSpotlight>,
		];

		return spotlights[activeViewSettingsSpotlight];
	};
	return (
		<>
			{!hasViewedViewSettingsSpotlight && (
				<SpotlightTransition>{renderWorkSuggestionsSpotlight()}</SpotlightTransition>
			)}
		</>
	);
};

export const CoordinationStopProvider = ({ stop, children }: CoordinationStopProviderProps) =>
	children(stop);

export const ViewSettingsSpotlightContainer = ({
	children,
	shouldShowSpotlight,
}: SpotlightProps) => {
	const { data: isWorkSuggestionsEnabledData, loading: loadingWorkSuggestionsEnabled } =
		useResource(nextBestTaskFeatureResource);
	const hadOptedIn = !!isWorkSuggestionsEnabledData?.hasUserOptedIn;

	return (
		<BoardWorkSuggestionsSpotlightContainer>
			{children}
			{shouldShowSpotlight && !loadingWorkSuggestionsEnabled && hadOptedIn && (
				<CoordinationClient
					messageId={WORK_SUGGESTIONS_SPOTLIGHT_MESSAGE_ID}
					messageType="engagement"
				>
					<CoordinationStopProvider>
						{(stop) => (
							<ContextualAnalyticsData sourceName="workSuggestionsSpotlight" sourceType={MODAL}>
								<ViewSettingsSpotlightContainerInner onStop={stop} />
							</ContextualAnalyticsData>
						)}
					</CoordinationStopProvider>
				</CoordinationClient>
			)}
		</BoardWorkSuggestionsSpotlightContainer>
	);
};
