import { useMemo, useState } from 'react';

type Transformer<T> = (item: T) => string;

const removeAccents = (text: string): string =>
	text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

const doesStringContainText = (itemString: string, text: string) =>
	itemString.toLowerCase().includes(text.toLowerCase()) ||
	removeAccents(itemString).toLowerCase().includes(text.toLowerCase());

/**
 * Provides simple text-based filtering for a list of items.
 * @param items the array of items to be filtered
 * @param itemToStringTransformer a function to transform an item to a string for matching against the filter value
 * @param defaultFilterValue sets the initial filter value, which defaults to an empty string
 * @returns the filtered items and a callback to update the filter value
 * */
export const useTextFilter = <T,>(
	items: T[],
	itemToStringTransformer: Transformer<T>,
	defaultFilterValue = '',
): [T[], (text: string) => void] => {
	const [filterValue, setFilterValue] = useState(defaultFilterValue);

	const filteredItems = useMemo(() => {
		if (!filterValue) {
			return items;
		}

		return items.filter((item) =>
			doesStringContainText(itemToStringTransformer(item), filterValue),
		);
	}, [filterValue, itemToStringTransformer, items]);

	return [filteredItems, setFilterValue];
};
