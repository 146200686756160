import React from 'react';
import { TimeEstimateKey } from '@atlassian/jira-providers-issue/src/model/issue-system-fields.tsx';
import { FieldSyncOriginalEstimate } from './field-sync-original-estimate/index.tsx';
import { FieldSyncStoryPoint } from './field-sync-story-point/index.tsx';
import type { FieldSyncEstimateProps } from './types.tsx';

export const FieldSyncEstimate = ({
	issue,
	estimateFieldId,
	projectKey,
}: FieldSyncEstimateProps) => {
	if (estimateFieldId === TimeEstimateKey) {
		return <FieldSyncOriginalEstimate issue={issue} originalEstimateFieldId={estimateFieldId} />;
	}
	return (
		<FieldSyncStoryPoint
			issue={issue}
			storypointsFieldId={estimateFieldId}
			projectKey={projectKey}
		/>
	);
};
