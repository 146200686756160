import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useIsFirstActivationDateWithinLast30Days } from '@atlassian/jira-onboarding-core/src/controllers/is-first-activation-date-within-last-30-days/index.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { FREE_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';

export const useIsTargetedForRaisingAwarenessOfImporter = () => {
	const isSiteAdmin = useIsSiteAdmin();
	const { data } = useProjectContext();
	const { software: edition } = useAppEditions();
	const isWithin30Day = useIsFirstActivationDateWithinLast30Days();
	const isSoftwareProject = data?.projectType === SOFTWARE_PROJECT;

	return (
		data?.simplified &&
		isSiteAdmin &&
		isWithin30Day &&
		edition === FREE_EDITION &&
		isSoftwareProject
	);
};
