import React from 'react';
import noop from 'lodash/noop';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import type { Props } from './types.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const DevDetailDialogLoaderWithContainer = lazy(
	() => import(/* webpackChunkName: "async-dev-panel-details-loader" */ './main'),
	{ ssr: false },
);

export const DevDetailsDialogLoaderAsync = (props: Props) => (
	<Placeholder name="dev-detail-dialog-loader-with-container" fallback={<></>}>
		<DevDetailDialogLoaderWithContainer {...props} />
	</Placeholder>
);

DevDetailsDialogLoaderAsync.defaultProps = {
	onCloseDevDetailDialog: noop,
};

export default DevDetailsDialogLoaderAsync;
