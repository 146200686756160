import memoizeOne from 'memoize-one';
import { getAriConfig, toAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { boardWithRouterRelayQuery$data } from '@atlassian/jira-relay/src/__generated__/boardWithRouterRelayQuery.graphql';
import type {
	BoardScopeBoardGQLCritical,
	BoardScopeCriticalGQL,
} from '@atlassian/jira-software-board-fetch-scope-critical/src/types.tsx';

export const getIdFromAri = (ari: string) => {
	const possibleAri = toAri(ari);
	if (possibleAri) {
		return getAriConfig(possibleAri).resourceId;
	}
	return ari;
};

const getBoard = (data: boardWithRouterRelayQuery$data): BoardScopeBoardGQLCritical => {
	const {
		boardScope: { board },
	} = data;

	return {
		name: board.name,
		swimlaneStrategy: board.swimlaneStrategy,
		rankCustomFieldId: board.rankCustomFieldId,
		assignees: board.assignees.filter(Boolean).map((assignee) => ({
			accountId: assignee.accountId,
			displayName: assignee.name,
			avatarUrl: assignee.picture,
		})),
		// @ts-expect-error - TS2322 - Type '{ id: string; name: string; maxIssueCount: number | null; status: { id: string; name: string; }[]; columnStatus: { status: { readonly category: string; readonly id: string; readonly name: string; }; transitions: { ...; }[]; }[]; isInitial: boolean; isDone: boolean; cards: { ...; }[]; }[]' is not assignable to type 'readonly ColumnWithCardsGQL[]'.
		columns: board.columns.filter(Boolean).map((column) => ({
			id: column.id,
			name: column.name,
			maxIssueCount: column.maxCardCount,
			status: column.columnStatus.map((cs) => ({
				id: cs.status.id,
				name: cs.status.name,
			})),
			columnStatus: column.columnStatus.map((cs) => ({
				status: cs.status,
				transitions: cs.cardTypeTransitions.map((transition) => ({
					id: transition.transitionId,
					name: transition.name,
					status: transition.status,
					originStatus: transition.originStatus,
					cardType: transition.cardType,
					isGlobal: transition.isGlobal,
					isInitial: transition.isInitial,
					hasConditions: transition.hasConditions,
				})),
			})),
			isInitial: column.isInitial,
			isDone: column.isDone,
			cards: column.cards.filter(Boolean).map((card) => ({
				id: getIdFromAri(card.id),
				activeSprint: card.activeSprint
					? {
							id: getIdFromAri(card.activeSprint.id),
						}
					: null,
				issue: {
					id: getIdFromAri(card.id),
					key: card.key,
					summary: card.summary,
					labels: card.labels.filter(Boolean),
					type: card.type,
					status: card.status,
					assignee: card.assignee
						? {
								accountId: card.assignee.accountId,
							}
						: null,
				},
				flagged: card.flagged,
				done: card.done,
				parentId: card.parentId != null ? getIdFromAri(card.parentId) : null,
				estimate: card.estimate ? card.estimate : null,
				priority: card.priority,
				dueDate: card.dueDate,
				childIssuesMetadata: card.childCardsMetadata,
			})),
		})),
		// @ts-expect-error - TS2322 - Type '({ readonly hierarchyLevelType: AGG$CardTypeHierarchyLevelType; readonly iconUrl: string; readonly id: string; readonly name: string; } | null)[]' is not assignable to type 'readonly IssueTypeGQL[]'.
		issueTypes: board.cardTypes.filter(Boolean),
		// @ts-expect-error - TS2322 - Type '{ id: string; issue: { assignee: { accountId: string; displayName: string; avatarUrl: AGG$URL; } | null; id: string; key: string; summary: string; labels: (string | null)[]; status: { readonly id: string; }; type: { ...; }; }; ... 5 more ...; dueDate: string | null; }[]' is not assignable to type 'readonly CardChildGQL[]'.
		issueChildren: board.cardChildren.filter(Boolean).map((card) => ({
			id: getIdFromAri(card.id),
			issue: {
				assignee:
					card.assignee != null
						? {
								accountId: card.assignee.accountId,
								displayName: card.assignee.name,
								avatarUrl: card.assignee.picture,
							}
						: null,
				id: getIdFromAri(card.id),
				key: card.key,
				summary: card.summary,
				labels: card.labels.filter(Boolean),
				status: card.status,
				type: card.type,
			},
			flagged: card.flagged,
			done: card.done,
			parentId: card.parentId != null ? getIdFromAri(card.parentId) : null,
			estimate: card.estimate ? card.estimate : null,
			priority: card.priority,
			dueDate: card.dueDate,
		})),
		cards: board.cards.filter(Boolean).map((card) => ({
			id: getIdFromAri(card.id),
		})),
		editConfig: {
			inlineColumnEdit: board.editConfig.inlineColumnEdit,
			inlineIssueCreate: board.editConfig.inlineCardCreate,
		},
		hasClearedIssues: board.hasClearedCards,
		inlineIssueCreate: board.inlineCardCreate,
	};
};

export const transformAggToSwagData = memoizeOne(
	(data: boardWithRouterRelayQuery$data): BoardScopeCriticalGQL => {
		const { boardScope } = data;

		return {
			userSwimlaneStrategy: boardScope.userSwimlaneStrategy,
			board: getBoard(data),
			sprints: boardScope.sprints.filter(Boolean).map((sprint) => ({
				...sprint,
				goal: sprint.goal || '',
				id: getIdFromAri(sprint.id),
				cards: sprint.cards.filter(Boolean),
			})),
			// @ts-expect-error - TS2322 - Type '({ readonly category: string; readonly key: AGG$SoftwareBoardFeatureKey; readonly status: BoardFeatureStatus; readonly toggle: BoardFeatureToggleStatus; } | null)[]' is not assignable to type 'readonly BoardFeatureGQL[]'.
			features: boardScope.features.filter(Boolean),
			projectLocation: {
				id: getIdFromAri(boardScope.projectLocation.id),
				key: boardScope.projectLocation.key,
				name: boardScope.projectLocation.name,
				isSimplifiedProject: true,
				// @ts-expect-error - TS2322 - Type '({ readonly hierarchyLevelType: AGG$CardTypeHierarchyLevelType; readonly iconUrl: string; readonly id: string; readonly name: string; } | null)[]' is not assignable to type 'readonly IssueTypeGQL[]'.
				issueTypes: boardScope.projectLocation.cardTypes.filter(Boolean),
			},
			issueParents: boardScope.cardParents.filter(Boolean).map((cardParent) => ({
				id: getIdFromAri(cardParent.id),
				key: cardParent.key,
				summary: cardParent.summary,
				issue: {
					status: cardParent.status,
				},
				issueType: cardParent.cardType,
				color: cardParent.color,
			})),
			currentUser: {
				permissions: boardScope.currentUser.permissions.filter(Boolean),
			},
			estimation: boardScope.estimation,
		};
	},
);
