/**
 * @generated SignedSource<<53221fefa0f4443cf65a4215c479359b>>
 * @relayHash 369bf10bb8009c658a35cf0bdf685efa
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 50d8c5a327ce393f92430f04532d211b7f0cc460a938b554e063e4a413b3c9b6

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { mainJiraBoardFetchBranchDetailsForIssueQuery } from './mainJiraBoardFetchBranchDetailsForIssueQuery.graphql';

const node: PreloadableConcreteRequest<mainJiraBoardFetchBranchDetailsForIssueQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "50d8c5a327ce393f92430f04532d211b7f0cc460a938b554e063e4a413b3c9b6",
    "metadata": {},
    "name": "mainJiraBoardFetchBranchDetailsForIssueQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
