import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	branchIconLabel: {
		id: 'development-board-dev-info-icon.branch-icon-label',
		defaultMessage: 'branch',
		description:
			'Accessible label for a Branch icon displayed on a Card when it has at least 1 active branch associated.\n' +
			'"Branch" is a technical term from the git tool and is most often not translated. \n' +
			'It is acceptable to transliterate this term to another alphabet/script.',
	},
	commitIconLabel: {
		id: 'development-board-dev-info-icon.commit-icon-label',
		defaultMessage: 'commit',
		description:
			'Accessible label for a Commit icon displayed on a Card when it has at least 1 commit associated.\n' +
			'"Commit" is a technical term from the git tool and is most often not translated. \n' +
			'It is acceptable to transliterate this term to another alphabet/script.',
	},
	designIconLabel: {
		id: 'development-board-dev-info-icon.design-icon-label',
		defaultMessage: 'Linked design data',
		description:
			'Accessible label for a Design icon displayed on a Card when it has at least 1 design associated.',
	},
	deploymentIconLabel: {
		id: 'development-board-dev-info-icon.deployment-icon-label',
		defaultMessage: 'Linked deployment data.',
		description:
			'Accessible label for a Deployment icon displayed on a Card when it has at least 1 deployment associated.',
	},
	deploymentProductionSuccessIconLabel: {
		id: 'development-board-dev-info-icon.deployment-production-success-icon-label',
		defaultMessage: 'Successful deployment to production.',
		description:
			'Accessible label for a Deployment icon which deployment to production successful.',
	},
	deploymentProductionFailedIconLabel: {
		id: 'development-board-dev-info-icon.deployment-production-failed-icon-label',
		defaultMessage: 'Failed deployment to production.',
		description: 'Accessible label for a Deployment icon which deployment to production failed.',
	},
	deploymentNonProductionSuccessIconLabel: {
		id: 'development-board-dev-info-icon.deployment-non-production-success-icon-label',
		defaultMessage: 'Successful deployment to non-production environment.',
		description:
			'Accessible label for a Deployment icon which deployment to non-production successful.',
	},
	deploymentNonProductionFailedIconLabel: {
		id: 'development-board-dev-info-icon.deployment-non-production-failed-icon-label',
		defaultMessage: 'Failed deployment to non-production environment.',
		description:
			'Accessible label for a Deployment icon which deployment to non-production failed.',
	},
	openPullRequestIconLabel: {
		id: 'development-board-dev-info-icon.open-pull-request-icon-label',
		defaultMessage: 'pull request',
		description:
			'Accessible label for a Pull Request icon displayed on a Card when it has at least 1 open Pull Request associated.\n' +
			'"Pull Request" is a technical term from the git tool and is most often not translated. \n' +
			'It is acceptable to transliterate this term to another alphabet/script.',
	},
	mergedPullRequestIconLabel: {
		id: 'development-board-dev-info-icon.merged-pull-request-icon-label',
		defaultMessage: 'merged pull request',
		description:
			'Accessible label for a merged Pull Request icon displayed on a Card when it has at least 1 merged Pull Request associated.\n' +
			'"Pull Request" is a technical term from the git tool and is most often not translated. \n' +
			'It is acceptable to transliterate this term to another alphabet/script.',
	},
	declinedPullRequestIconLabel: {
		id: 'development-board-dev-info-icon.declined-pull-request-icon-label',
		defaultMessage: 'declined pull request',
		description:
			'Accessible label for a declined Pull Request icon displayed on a Card when it has at least 1 declined Pull Request associated.\n' +
			'"Pull Request" is a technical term from the git tool and is most often not translated. \n' +
			'It is acceptable to transliterate this term to another alphabet/script.',
	},
	branchIconButtonLabel: {
		id: 'development-board-dev-info-icon.branch-icon-button-label',
		defaultMessage: 'Select to open branch details.',
		description:
			'Accessible label for a Branch icon button displayed on a Card when it has at least 1 active branch associated.\n' +
			'"Branch" is a technical term from the git tool and is most often not translated. \n' +
			'It is acceptable to transliterate this term to another alphabet/script.',
	},
	commitIconButtonLabel: {
		id: 'development-board-dev-info-icon.commit-icon-button-label',
		defaultMessage: 'Select to open commit details.',
		description:
			'Accessible label for a Commit icon button displayed on a Card when it has at least 1 commit associated.\n' +
			'"Commit" is a technical term from the git tool and is most often not translated. \n' +
			'It is acceptable to transliterate this term to another alphabet/script.',
	},
	pullRequestIconButtonLabel: {
		id: 'development-board-dev-info-icon.pull-request-icon-button-label',
		defaultMessage: 'Select to open pull request details.',
		description:
			'Accessible label for a Pull Request icon button displayed on a Card when it has at least 1 Pull Request associated.\n' +
			'"Pull Request" is a technical term from the git tool and is most often not translated. \n' +
			'It is acceptable to transliterate this term to another alphabet/script.',
	},
	deploymentIconButtonLabel: {
		id: 'development-board-dev-info-icon.deployment-icon-button-label',
		defaultMessage: 'Select to open deployment details.',
		description:
			'Accessible label for a Deployment icon button displayed on a Card when it has at least 1 deployment associated.',
	},
	designIconButtonLabel: {
		id: 'development-board-dev-info-icon.design-icon-button-label',
		defaultMessage: 'Select to open design details.',
		description:
			'Accessible label for a Design icon button displayed on a Card when it has at least 1 design associated.',
	},
});
