import React from 'react';
import { CustomCardFieldContainer } from '../html-card-custom-field-content/main.tsx';

type Props = { field: { text?: string } };

export const TextCardCustomFieldContent = ({ field }: Props) =>
	field.text ? (
		<CustomCardFieldContainer
			isNone={field.text === 'None'}
			data-testid="platform-card.common.ui.custom-fields.card-custom-field.text-card-custom-field-content.field"
		>
			{field.text}
		</CustomCardFieldContainer>
	) : null;
