import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { ApplicationKey } from '@atlassian/jira-shared-types/src/application-key.tsx';
import type { BaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';

export type RemainingSeatResponse = {
	remainingSeat: number;
};

const toRemainingSeats = (json: RemainingSeatResponse) => json.remainingSeat;

export const getRemainingSeats = (
	baseUrl: BaseUrl,
	applicationKey: ApplicationKey,
): Promise<number> =>
	performGetRequest(
		`/rest/internal/2/invite-user/application/remaining-seat?key=${applicationKey}`,
	).then((json) => toRemainingSeats(json));
