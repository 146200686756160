import {
	createContainer,
	createStore,
	createActionsHook,
	createHook,
} from '@atlassian/react-sweet-state';
import { actions } from './actions/index.tsx';
import type { IssueLinksModalEntrypointApi, State } from './types.tsx';

export const initialState: State = {
	issueData: undefined,
	modalContent: undefined,
};

export type Actions = typeof actions;

export const store = createStore<State, Actions>({
	initialState,
	actions,
	name: 'issue-links-modal-entrypoint-store',
});

export const IssueLinkedModalEntrypointContainer = createContainer<State, Actions>(store, {
	onInit:
		() =>
		({ setState }: IssueLinksModalEntrypointApi) => {
			setState(initialState);
		},
});

export const useIssueLinkedModalEntrypointActions = createActionsHook(store);
export const useIssueLinkedModalEntrypointStore = createHook(store);
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { setIssueLinkModalEntryPointSubject } from './actions';
