import { fg } from '@atlassian/jira-feature-gating';
import parameters from '@atlassian/jira-relay/src/__generated__/uiDevOpsBoardQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const deploymentDetailsEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-deployment-details-popup" */ './src/ui'),
	),
	getPreloadProps: ({ issueId }: { issueId: string }) => ({
		queries: {
			deployments: {
				options: {
					fetchPolicy: 'store-and-network' as const,
				},
				parameters,
				variables: {
					issueAris: [issueId],
					isDevOpsDataAgsMigrationOn: fg('solaris_migrate_summurized_deployments_to_ags_re'),
				},
			},
		},
	}),
});
