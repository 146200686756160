import type { FetchOptions } from '@atlassian/jira-insights-common-constants/src/common/types/fetch.tsx';
import { useFetch } from '@atlassian/jira-insights-fetch/src/controllers/use-fetch/main.tsx';
import { fetchJson } from '@atlassian/jira-insights-fetch/src/services/fetch/fetch-json/index.tsx';
import { AUTOMATION_RECOMMENDATIONS_PREFIX, PACKAGE_NAME } from '../../common/constants.tsx';
import type { RecommendationsPathProps, RecommendationsHookProps } from '../../common/types.tsx';
import { getAutomationRecommendationsUrl } from '../../common/utils.tsx';
import type { RecommendationResponse } from './types.tsx';

const fetchRecommendation = async (
	{ env, cloudId, target }: RecommendationsPathProps,
	options?: FetchOptions,
): Promise<RecommendationResponse> => {
	const url = await getAutomationRecommendationsUrl({ env, cloudId, target });

	const response = await fetchJson<RecommendationResponse>(url, options);

	return response;
};

export const useAutomationRecommendations = ({
	env,
	cloudId,
	target,
	shouldFetch = true,
}: RecommendationsHookProps) =>
	useFetch(
		{
			onFetch: (options) => fetchRecommendation({ env, cloudId, target }, options),
			analyticsTracking: {
				actionSubject: `useFetch.${AUTOMATION_RECOMMENDATIONS_PREFIX}`,
				packageName: PACKAGE_NAME,
			},
			loggingKey: `${PACKAGE_NAME}.${AUTOMATION_RECOMMENDATIONS_PREFIX}`,
			isAutoFetchable: shouldFetch,
		},
		[env, cloudId, target, shouldFetch],
	);
