import { merge } from 'icepick';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import { defaultOptions } from './fetch-default-options.tsx';
import type { JiraFetchOptions } from './as-json-stream.tsx';
import { TRACE_ID_HEADER } from './constants.tsx';
import FetchError from './errors.tsx';
import { getReroutableURL } from './get-reroutable-url.tsx';
import { makeObservabilityHeaders } from './observability-headers.tsx';

const noContentStatus = 204;

// This function returns a stream which emits a single string value on success, and throws
// an error on failure
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (url: string, options: JiraFetchOptions = {}): Observable<string | null> =>
	Observable.of(url)
		.mergeMap(() =>
			fetch(
				getReroutableURL(url),
				merge(merge(defaultOptions, options), makeObservabilityHeaders(url)),
			),
		)
		.mergeMap((response) => {
			if (!response.ok) {
				const { status } = response;
				const traceId = response.headers.get(TRACE_ID_HEADER);
				if (traceId != null && traceId !== '') {
					throw new FetchError(
						status,
						`Fetch call failed with status code: ${response.status}`,
						traceId,
					);
				} else {
					throw new FetchError(status);
				}
			}

			if (response.status === noContentStatus) {
				// We want it to return a `null` value.
				// Using `Observable.empty<never>()` would just hang as `mergeMap`
				// will be waiting for values that would never come.
				return Observable.of(null);
			}

			// @ts-expect-error - TS2339 - Property 'responseText' does not exist on type 'Response'.
			return Observable.of(response.responseText || 'OK');
		});
