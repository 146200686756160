import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import {
	contextPathSelector,
	rapidViewIdSelector,
} from '../../state/selectors/software/software-selectors.tsx';
import type { State } from '../../state/types.tsx';
import { getFeaturesUrl } from '../software/software-api-urls.tsx';

export const executeBacklogEnable = (state: State) => {
	const contextPath = contextPathSelector(state);
	const rapidViewId = rapidViewIdSelector(state);

	const url = getFeaturesUrl(contextPath, rapidViewId);
	return fetchJson$(url, {
		method: 'PUT',
		body: JSON.stringify({
			boardId: rapidViewId,
			feature: 'jsw.agility.backlog',
			enabling: true,
		}),
	});
};
