import { useCallback } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { FIELD_TYPE_MAP } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/constants.tsx';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import { BASE_HIERARCHY_TYPE } from '@atlassian/jira-issue-type-hierarchies/src/index.tsx';
import { SPRINT_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	type MenuNode,
	MenuNodeType,
} from '@atlassian/jira-software-context-menu/src/common/types.tsx';
import { Capability } from '../../../../../../../../common/capability/index.tsx';
import { removeFromSprintRequest } from '../../../../../../../../state/actions/sprints/remove-from-sprint/index.tsx';
import { useBoardActionCreator, useBoardSelector } from '../../../../../../../../state/index.tsx';
import { getCapability } from '../../../../../../../../state/selectors/capability/capability-selectors.tsx';
import { getIssueById } from '../../../../../../../../state/selectors/issue/issue-selectors.tsx';
import { getProjectIssueTypes } from '../../../../../../../../state/selectors/software/software-selectors.tsx';
import { REMOVE_FROM_SPRINT_MENU_ID } from '../../constants.tsx';
import messages from '../../messages.tsx';
import type { ContentProps, Props } from './types.tsx';

export const useRemoveFromSprintContent = ({
	isRemoveFromSprintEnabled,
	onRemoveFromSprint,
	shouldFireAnalytics,
}: ContentProps): MenuNode[] => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onClick = useCallback(() => {
		if (shouldFireAnalytics) {
			getUpdateAnalyticsFlowHelper().fireAnalyticsEnd(FIELD_TYPE_MAP[SPRINT_TYPE], {
				analytics: createAnalyticsEvent({}),
				attributes: {
					fieldType: SPRINT_TYPE,
					actionTaken: 'remove',
				},
			});
		}
		onRemoveFromSprint();
	}, [createAnalyticsEvent, onRemoveFromSprint, shouldFireAnalytics]);

	if (!isRemoveFromSprintEnabled) return [];
	return [
		{
			type: MenuNodeType.Item,
			label: formatMessage(messages.removeFromSprint),
			id: REMOVE_FROM_SPRINT_MENU_ID,
			onClick,
		},
	];
};

export const useRemoveFromSprint = ({ issueId, selectedCardIds }: Props): MenuNode[] => {
	const canRemoveFromSprint = useBoardSelector((state) =>
		getCapability(state)(Capability.REMOVE_FROM_SPRINT),
	);
	const issue = useBoardSelector((state) => getIssueById(state, issueId));
	const isBaseType =
		useBoardSelector(getProjectIssueTypes)[issue?.typeId]?.hierarchyLevelType ===
		BASE_HIERARCHY_TYPE;

	// TODO: add bulk remove from sprint once feature is added in UIF
	const onRemoveFromSprint = useBoardActionCreator(() => removeFromSprintRequest(issueId));

	return useRemoveFromSprintContent({
		isRemoveFromSprintEnabled: canRemoveFromSprint && isBaseType,
		onRemoveFromSprint,
		...(fg('one_event_rules_them_all_fg') && {
			shouldFireAnalytics: selectedCardIds?.length === 1,
		}),
	});
};
