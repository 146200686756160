import { useEffect } from 'react';
import omit from 'lodash/omit';
import { useRouter } from '@atlassian/react-resource-router';
import { QUERY_ENABLED_VALUE } from '../../../common/constants.tsx';
import type { View } from '../../../common/types/constant.tsx';
import { ViewSetting } from '../../../common/types/settings.tsx';
import type { CardDetailsState } from '../../../common/types/state.tsx';
import { isViewSettingsPanelAndTailoredViewExperimentEnabled } from '../../../common/utils/index.tsx';
import { getViewSettingsCardDetails as getViewSettingsCardDetailsFromLocalStorage } from '../../../services/local-storage/index.tsx';
import { useViewSettingsActions } from '../../index.tsx';

export const cardDetailsQueryMap = {
	[ViewSetting.SHOW_EPICS]: 'showEpics',
	[ViewSetting.SHOW_STATUSES]: 'showStatuses',
	[ViewSetting.SHOW_VERSIONS]: 'showVersions',
	[ViewSetting.SHOW_ASSIGNEE]: 'showAssignee',
	[ViewSetting.SHOW_DAYS_IN_COLUMN]: 'showDaysInColumn',
	[ViewSetting.SHOW_ESTIMATE]: 'showEstimate',
	[ViewSetting.SHOW_ISSUE_KEY]: 'showIssueKey',
	[ViewSetting.SHOW_ISSUE_LINKS_STATS]: 'showIssueLinksStats',
	[ViewSetting.SHOW_DUE_DATE]: 'showDueDate',
	[ViewSetting.SHOW_ISSUE_TYPE]: 'showIssueType',
	[ViewSetting.SHOW_PRIORITY]: 'showPriority',
	[ViewSetting.SHOW_DEVELOPMENT]: 'showDevelopment',
	[ViewSetting.SHOW_LABELS]: 'showLabels',
	[ViewSetting.SHOW_CARD_COVERS]: 'showCardCovers',
} as const;

export const useCardDetailsInitialState = (
	boardId: number,
	view: (typeof View)[keyof typeof View],
	isDaysInColumnEnabled: boolean,
	isDueDateEnabled: boolean,
	isLabelsEnabled: boolean,
	isEstimateEnabled: boolean,
	isPriorityEnabled: boolean,
) => {
	const [{ location, query }, { replace }] = useRouter();
	const { setViewSettingsCardDetails } = useViewSettingsActions();

	useEffect(() => {
		if (!isViewSettingsPanelAndTailoredViewExperimentEnabled() || !query) {
			return;
		}
		const cardDetailsFromLocalStorage = getViewSettingsCardDetailsFromLocalStorage({
			boardId,
			view,
			isDaysInColumnEnabled,
			isDueDateEnabled,
			isLabelsEnabled,
			isEstimateEnabled,
			isPriorityEnabled,
		});

		const getInitialCardValue = (stateKey: keyof CardDetailsState) => {
			const queryKey = cardDetailsQueryMap[stateKey];
			return query[queryKey]
				? query[queryKey] === QUERY_ENABLED_VALUE
				: cardDetailsFromLocalStorage[stateKey];
		};

		const cardDetailsState = {
			[ViewSetting.SHOW_EPICS]: getInitialCardValue(ViewSetting.SHOW_EPICS),
			[ViewSetting.SHOW_STATUSES]: getInitialCardValue(ViewSetting.SHOW_STATUSES),
			[ViewSetting.SHOW_VERSIONS]: getInitialCardValue(ViewSetting.SHOW_VERSIONS),
			[ViewSetting.SHOW_ASSIGNEE]: getInitialCardValue(ViewSetting.SHOW_ASSIGNEE),
			[ViewSetting.SHOW_DAYS_IN_COLUMN]: getInitialCardValue(ViewSetting.SHOW_DAYS_IN_COLUMN),
			[ViewSetting.SHOW_ESTIMATE]: getInitialCardValue(ViewSetting.SHOW_ESTIMATE),
			[ViewSetting.SHOW_ISSUE_KEY]: getInitialCardValue(ViewSetting.SHOW_ISSUE_KEY),
			[ViewSetting.SHOW_ISSUE_LINKS_STATS]: getInitialCardValue(ViewSetting.SHOW_ISSUE_LINKS_STATS),
			[ViewSetting.SHOW_ISSUE_TYPE]: getInitialCardValue(ViewSetting.SHOW_ISSUE_TYPE),
			[ViewSetting.SHOW_PRIORITY]: getInitialCardValue(ViewSetting.SHOW_PRIORITY),
			[ViewSetting.SHOW_DEVELOPMENT]: getInitialCardValue(ViewSetting.SHOW_DEVELOPMENT),
			[ViewSetting.SHOW_DUE_DATE]: getInitialCardValue(ViewSetting.SHOW_DUE_DATE),
			[ViewSetting.SHOW_LABELS]: getInitialCardValue(ViewSetting.SHOW_LABELS),
			[ViewSetting.SHOW_CARD_COVERS]: getInitialCardValue(ViewSetting.SHOW_CARD_COVERS),
		};

		setViewSettingsCardDetails({
			boardId,
			view,
			cardDetailsState,
		});

		// remove card view initial settings param
		replace({
			...location,
			search: new URLSearchParams(omit(query, Object.values(cardDetailsQueryMap))).toString(),
		});
		// we want to run this hook on initial render only
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};
