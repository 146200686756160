import {
	COLUMN_DELETE,
	COLUMN_DELETE_MODAL_CLOSE,
} from '../../../../actions/column/delete/index.tsx';
import type { Action } from '../../../../types.tsx';
import type { DeleteModalState } from './types.tsx';

export const columnDeleteModalReducer = (
	state: DeleteModalState = { isOpen: false },
	action: Action,
): DeleteModalState => {
	if (action.type === COLUMN_DELETE) {
		const { columnId } = action.payload;
		return { isOpen: true, columnId };
	}

	if (action.type === COLUMN_DELETE_MODAL_CLOSE) {
		return { isOpen: false };
	}

	return state;
};
