import { bindActionCreators, type Dispatch } from 'redux';
import isNil from 'lodash/isNil';
import DoneCardsButton from '@atlassian/jira-platform-board-kit/src/ui/card/done-cards-button/index.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { getResolvedIssuesUrl, getOldIssuesUrl } from '../../../../../services/work/work-links.tsx';
import { doneIssuesButtonClick, type Action } from '../../../../../state/actions/issue/index.tsx';
import type { State } from '../../../../../state/reducers/types.tsx';
import {
	contextPathSelector,
	getIsCMPBoard,
	projectKeySelector,
} from '../../../../../state/selectors/software/software-selectors.tsx';

type OwnProps = {
	limitJql?: string;
};

export const encodeLimitJqlUrl = (url: string) => {
	const [path, jqlQuery] = url.split('?jql=');
	const encodedJqlQuery = encodeURIComponent(jqlQuery);
	return `${path}?jql=${encodedJqlQuery}`;
};

export const mapStateToProps = (state: State, ownProps: OwnProps) => {
	const isCMPBoard = getIsCMPBoard(state);
	const { limitJql } = ownProps;
	const getUrl = () => {
		if (isCMPBoard) {
			if (!isNil(limitJql)) {
				return encodeLimitJqlUrl(limitJql);
			}
			return getOldIssuesUrl(contextPathSelector(state), projectKeySelector(state));
		}
		return getResolvedIssuesUrl(contextPathSelector(state), projectKeySelector(state));
	};
	return {
		url: getUrl(),
		isCMPBoard,
	};
};

const connector = connect(mapStateToProps, (dispatch: Dispatch<Action>) => ({
	onClick: bindActionCreators(doneIssuesButtonClick, dispatch),
}));

export default connector(DoneCardsButton);
