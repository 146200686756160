/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import HiddenTrigger from '@atlassian/jira-platform-inline-card-create/src/ui/trigger/hidden/main.tsx';

type Props = {
	label: string;
	onClick: () => void;
};

// z-index is because the trigger must appear above a focused card
const triggerElementStyles = css({
	display: 'block',
	position: 'relative',
	zIndex: 1,
	margin: `${token('space.negative.050', '-4px')} 0`,
	padding: '0',
	border: 'none',
	outline: 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize}px`,
	width: '100%',
	backgroundColor: 'transparent',
	opacity: 1,
	'&:hover': {
		animation: 'none',
	},
	'&:focus-within': {
		cursor: 'pointer',
		opacity: 1,
		'&:hover': {
			animation: 'none',
		},
	},
});

const TriggerWrapper = () => <div css={triggerElementStyles} />;

export const Trigger = (props: Props) => (
	<HiddenTrigger TriggerWrapper={TriggerWrapper} {...props} />
);
