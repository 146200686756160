import { colors, createTheme } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import {
	BACKGROUND_COLOR_THEME_TOKEN,
	DARK_MODE,
	DEFAULT_THEME_STATE,
	DISABLED_THEME_STATE,
	FLAGGED_AND_SELECTED_THEME_STATE,
	FLAGGED_THEME_STATE,
	HOVER_ENTER_THEME_STATE,
	HOVER_LEAVE_THEME_STATE,
	LIGHT_MODE,
	SELECTED_THEME_STATE,
	TEXT_COLOR_THEME_TOKEN,
} from '../constants.tsx';
import type {
	CssProp,
	ThemeProps,
	ThemeTokens,
	TokenTheme,
	HoverThemeState,
	ThemeMode,
} from '../types.tsx';

// The hex code for the colour is used as that value does not exist in the colors from '@atlaskit/theme'
const cardFlaggedColor = '#FFF7D6';
const cardFlaggedAndHoveredColor = '#F8E6A0';
const cardFlaggedAndSelectedColor = '#F5CD47';

const selectedDisabledAndDefaultThemeState = {
	[SELECTED_THEME_STATE]: {
		[HOVER_LEAVE_THEME_STATE]: {
			[LIGHT_MODE]: token('color.background.selected', colors.B50),
			[DARK_MODE]: token('color.background.brand.bold', colors.B500),
		},
		[HOVER_ENTER_THEME_STATE]: {
			[LIGHT_MODE]: token('color.background.selected.hovered', colors.B75),
			[DARK_MODE]: token('color.background.neutral.bold.hovered', colors.B400),
		},
	},
	[DISABLED_THEME_STATE]: {
		[HOVER_LEAVE_THEME_STATE]: {
			[LIGHT_MODE]: token('color.background.disabled', colors.N0),
			[DARK_MODE]: token('color.background.disabled', colors.N800),
		},
		[HOVER_ENTER_THEME_STATE]: {
			[LIGHT_MODE]: token('color.background.disabled', colors.N0),
			[DARK_MODE]: token('color.background.disabled', colors.N800),
		},
	},
	[DEFAULT_THEME_STATE]: {
		[HOVER_LEAVE_THEME_STATE]: {
			[LIGHT_MODE]: token('elevation.surface.raised', colors.N0),
			[DARK_MODE]: token('elevation.surface.raised', colors.DN30),
		},
		[HOVER_ENTER_THEME_STATE]: {
			[LIGHT_MODE]: token('color.background.neutral.subtle.hovered', colors.N20),
			[DARK_MODE]: token('color.background.neutral.bold.hovered', colors.DN40),
		},
	},
};

const cardTheme: TokenTheme = {
	[BACKGROUND_COLOR_THEME_TOKEN]: {
		[FLAGGED_AND_SELECTED_THEME_STATE]: {
			[HOVER_LEAVE_THEME_STATE]: {
				[LIGHT_MODE]: token('color.background.warning.pressed', cardFlaggedAndSelectedColor),
				[DARK_MODE]: token('color.background.warning.pressed', cardFlaggedAndSelectedColor),
			},
			[HOVER_ENTER_THEME_STATE]: {
				[LIGHT_MODE]: token('color.background.warning.hovered', cardFlaggedAndHoveredColor),
				[DARK_MODE]: token('color.background.warning.hovered', cardFlaggedAndHoveredColor),
			},
		},

		[FLAGGED_THEME_STATE]: {
			[HOVER_LEAVE_THEME_STATE]: {
				[LIGHT_MODE]: token('color.background.warning', cardFlaggedColor),
				[DARK_MODE]: token('color.background.warning', cardFlaggedColor),
			},
			[HOVER_ENTER_THEME_STATE]: {
				[LIGHT_MODE]: token('color.background.warning.hovered', cardFlaggedAndHoveredColor),
				[DARK_MODE]: token('color.background.warning.hovered', cardFlaggedAndHoveredColor),
			},
		},
		...selectedDisabledAndDefaultThemeState,
	},
	[TEXT_COLOR_THEME_TOKEN]: {
		[FLAGGED_AND_SELECTED_THEME_STATE]: {
			[HOVER_LEAVE_THEME_STATE]: {
				[LIGHT_MODE]: token('color.text', colors.N800),
				[DARK_MODE]: token('color.text', colors.P50),
			},
			[HOVER_ENTER_THEME_STATE]: {
				[LIGHT_MODE]: token('color.text', colors.N800),
				[DARK_MODE]: token('color.text', colors.P50),
			},
		},
		[FLAGGED_THEME_STATE]: {
			[HOVER_LEAVE_THEME_STATE]: {
				[LIGHT_MODE]: token('color.text', colors.N800),
				[DARK_MODE]: token('color.text', colors.R50),
			},
			[HOVER_ENTER_THEME_STATE]: {
				[LIGHT_MODE]: token('color.text', colors.N800),
				[DARK_MODE]: token('color.text', colors.R50),
			},
		},
		[SELECTED_THEME_STATE]: {
			[HOVER_LEAVE_THEME_STATE]: {
				[LIGHT_MODE]: token('color.text', colors.N800),
				[DARK_MODE]: token('color.text', colors.B50),
			},
			[HOVER_ENTER_THEME_STATE]: {
				[LIGHT_MODE]: token('color.text', colors.N800),
				[DARK_MODE]: token('color.text', colors.B50),
			},
		},
		[DISABLED_THEME_STATE]: {
			[HOVER_LEAVE_THEME_STATE]: {
				[LIGHT_MODE]: token('color.text', colors.N80),
				[DARK_MODE]: token('color.text', colors.N300),
			},
			[HOVER_ENTER_THEME_STATE]: {
				[LIGHT_MODE]: token('color.text', colors.N80),
				[DARK_MODE]: token('color.text', colors.N300),
			},
		},
		[DEFAULT_THEME_STATE]: {
			[HOVER_LEAVE_THEME_STATE]: {
				[LIGHT_MODE]: token('color.text', colors.N800),
				[DARK_MODE]: token('color.text', colors.N80),
			},
			[HOVER_ENTER_THEME_STATE]: {
				[LIGHT_MODE]: token('color.text', colors.N800),
				[DARK_MODE]: token('color.text', colors.N80),
			},
		},
	},
};

type TokenOptions = {
	cssProp: CssProp;
	isSelected?: Boolean;
	isFlagged?: Boolean;
	isDisabled?: Boolean;
	hoverState?: HoverThemeState;
	mode: ThemeMode;
};

const getCssPropValue = ({
	cssProp,
	isSelected = false,
	isFlagged = false,
	isDisabled = false,
	hoverState = HOVER_LEAVE_THEME_STATE,
	mode,
}: TokenOptions): string => {
	if (isFlagged && isSelected) {
		return cardTheme[cssProp][FLAGGED_AND_SELECTED_THEME_STATE][hoverState][mode];
	}
	if (isFlagged) {
		return cardTheme[cssProp][FLAGGED_THEME_STATE][hoverState][mode];
	}
	if (isSelected) {
		return cardTheme[cssProp][SELECTED_THEME_STATE][hoverState][mode];
	}
	if (isDisabled) {
		return cardTheme[cssProp][DISABLED_THEME_STATE][hoverState][mode];
	}

	return cardTheme[cssProp][DEFAULT_THEME_STATE][hoverState][mode];
};

const getBackgroundColor = ({ isSelected, isFlagged, isDisabled, mode }: ThemeProps): string =>
	getCssPropValue({
		cssProp: BACKGROUND_COLOR_THEME_TOKEN,
		isSelected,
		isFlagged,
		isDisabled,
		mode,
	});

const getTextColor = ({ isSelected, isFlagged, isDisabled, mode }: ThemeProps): string =>
	getCssPropValue({
		cssProp: TEXT_COLOR_THEME_TOKEN,
		isSelected,
		isFlagged,
		isDisabled,
		mode,
	});

const getHoverBackgroundColor = ({ isSelected, isFlagged, isDisabled, mode }: ThemeProps): string =>
	getCssPropValue({
		cssProp: BACKGROUND_COLOR_THEME_TOKEN,
		isSelected,
		isFlagged,
		isDisabled,
		mode,
		hoverState: HOVER_ENTER_THEME_STATE,
	});

const getHoverTextColor = ({ isSelected, isFlagged, isDisabled, mode }: ThemeProps): string =>
	getCssPropValue({
		cssProp: TEXT_COLOR_THEME_TOKEN,
		isSelected,
		isFlagged,
		isDisabled,
		mode,
		hoverState: HOVER_ENTER_THEME_STATE,
	});

const defaultTheme = ({ isSelected, isFlagged, isDisabled, mode }: ThemeProps): ThemeTokens => ({
	backgroundColor: getBackgroundColor({ isSelected, isFlagged, isDisabled, mode }),
	textColor: getTextColor({ isSelected, isFlagged, isDisabled, mode }),
	hoverBackgroundColor: getHoverBackgroundColor({ isSelected, isFlagged, isDisabled, mode }),
	hoverTextColor: getHoverTextColor({ isSelected, isFlagged, isDisabled, mode }),
});

export const Theme = createTheme<ThemeTokens, ThemeProps>(defaultTheme);
