import { freeze, merge } from 'icepick';
import { COLUMN_CREATE_SUCCESS } from '../../../actions/column/create/index.tsx';
import { COLUMN_DELETE_SUCCESS } from '../../../actions/column/delete/index.tsx';
import { COLUMN_RENAME_SUCCESS } from '../../../actions/column/rename/index.tsx';
import { WORK_DATA_SET, WORK_DATA_CRITICAL_SET } from '../../../actions/work/index.tsx';
import type { Action } from '../../../types.tsx';
import type { CardTransitionsState } from './types.tsx';

const initialState = freeze({
	columnIssueTypeTransitions: {},
	issueTypeStatus: {},
});

export const cardTransitionsReducer = (
	state: CardTransitionsState = initialState,
	action: Action,
): CardTransitionsState => {
	if (action.type === WORK_DATA_SET || action.type === WORK_DATA_CRITICAL_SET) {
		const {
			payload: { cardTransitions },
		} = action;

		return freeze(cardTransitions || initialState);
	}

	if (action.type === COLUMN_CREATE_SUCCESS) {
		const {
			payload: { cardTransitions },
		} = action;

		if (cardTransitions) {
			return freeze(cardTransitions);
		}
	}

	if (action.type === COLUMN_DELETE_SUCCESS) {
		const {
			payload: { cardTransitions },
		} = action;

		if (cardTransitions) {
			return freeze(cardTransitions);
		}
	}

	if (action.type === COLUMN_RENAME_SUCCESS) {
		const {
			payload: { cardTransitions },
		} = action;
		if (cardTransitions) {
			return merge(state, cardTransitions);
		}
	}

	return state;
};
