import React from 'react';
import { Inline, xcss } from '@atlaskit/primitives';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { ENTITY_FIELD_KEYS } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/constants.tsx';
import {
	type MenuNode,
	MenuNodeType,
} from '@atlassian/jira-software-context-menu/src/common/types.tsx';
import { LINK_ISSUE_MENU_ID } from '../../constants.tsx';
import { useMenuItemWithStartAnalytics } from '../../utils.tsx';
import messages from './messages.tsx';
import type { ContentProps } from './types.tsx';

export const useLinkContent = ({
	onClick,
	onMouseEnter,
	canUserLinkIssue,
	hasIssueLinkTypes,
	message,
}: ContentProps): MenuNode[] => {
	const { formatMessage } = useIntl();

	if (fg('one_event_rules_them_all_fg')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		return useMenuItemWithStartAnalytics({
			fieldKey: ENTITY_FIELD_KEYS.ISSUE_LINKS,
			fieldType: ENTITY_FIELD_KEYS.ISSUE_LINKS,
			shouldFireAnalytics: true,
			menuItemProps: {
				label: () => (
					<Inline alignBlock="center" space="space.100" xcss={messageContainerStyles}>
						{message ??
							formatMessage(
								expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
									? messages.linkIssueKeyIssueTermRefresh
									: messages.linkIssueKey,
							)}
					</Inline>
				),
				id: LINK_ISSUE_MENU_ID,
				onClick,
				onMouseEnter,
				analytics: {
					attributes: {
						selectedOption: LINK_ISSUE_MENU_ID,
					},
				},
			},
			shouldHide: !hasIssueLinkTypes || !canUserLinkIssue,
		});
	}

	return hasIssueLinkTypes && canUserLinkIssue
		? [
				{
					type: MenuNodeType.Item,
					label: () => (
						<Inline alignBlock="center" space="space.100" xcss={messageContainerStyles}>
							{message ??
								formatMessage(
									expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
										? messages.linkIssueKeyIssueTermRefresh
										: messages.linkIssueKey,
								)}
						</Inline>
					),
					id: LINK_ISSUE_MENU_ID,
					onClick,
					onMouseEnter,
					analytics: {
						attributes: {
							selectedOption: LINK_ISSUE_MENU_ID,
						},
					},
				},
			]
		: [];
};

const messageContainerStyles = xcss({
	lineHeight: '20px',
});
