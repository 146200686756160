import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'portfolio-3-plan-increment-common.ui.error-screens.custom-field.not-found.title',
		defaultMessage: 'This board’s custom field has been deleted.',
		description: 'Title for the custom field not found error screen',
	},
	description: {
		id: 'portfolio-3-plan-increment-common.ui.error-screens.custom-field.not-found.description',
		defaultMessage:
			'The custom field used to create this board has been deleted from your site by an administrator, and can’t be restored.',
		description: 'Description for custom field not found error screen',
	},
	createNewBoard: {
		id: 'portfolio-3-plan-increment-common.ui.error-screens.custom-field.not-found.create-new-board',
		defaultMessage: 'Create new board',
		description: 'Button to add new board in the custom field not found error screen',
	},
	imgAlt: {
		id: 'portfolio-3-plan-increment-common.ui.error-screens.custom-field.not-found.img-alt',
		defaultMessage: 'Drawing of a magnifying glass with a cross mark',
		description: 'Alt text for the image illustration',
	},
});
