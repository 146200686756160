import 'rxjs/add/observable/from';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/throttleTime';
import 'rxjs/add/operator/mergeMap';
import type { AnyAction } from 'redux';
import isNil from 'lodash/isNil';
import { Observable } from 'rxjs/Observable';
import { cardAutoScroll } from '../../state/actions/card/card-auto-scroll/index.tsx';
import {
	CARD_NEXT,
	CARD_PREV,
	cardSingleSelect,
	cardFocus,
	type CardRowNavigationAction,
} from '../../state/actions/card/index.tsx';
import { openIssueModalDebounced } from '../../state/actions/issue/modal/index.tsx';
import { getCardActive } from '../../state/selectors/card/card-selectors.tsx';
import { isIssueModalShowingSelector } from '../../state/selectors/issue/issue-modal-selectors.tsx';
import {
	getFirstSelectableIssueAscending,
	getFirstSelectableIssueDescending,
	getNextSelectableIssue,
	getPrevSelectableIssue,
	isActiveCardVisible as isActiveCardVisibleSelector,
} from '../../state/selectors/issue/issue-navigation-selectors.tsx';
import { getIssueById } from '../../state/selectors/issue/issue-selectors.tsx';
import type { ActionsObservable, MiddlewareAPI } from '../../state/types.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable, store: MiddlewareAPI) =>
	action$
		.ofType(CARD_NEXT, CARD_PREV)
		.throttleTime(200)
		.mergeMap(({ type, payload }: CardRowNavigationAction) => {
			const state = store.getState();
			const activeCardId = getCardActive(state);
			// Applying a filter or collapsing a swimlane does not clear the active card,
			// So we need to check manually if it is actually visible.
			const isActiveCardVisible = isActiveCardVisibleSelector(state);

			const isCardNext = type === CARD_NEXT;
			const getFirstSelectableIssueFn = isCardNext
				? getFirstSelectableIssueAscending
				: getFirstSelectableIssueDescending;
			const getNextOrPrevIssueFn = isCardNext ? getNextSelectableIssue : getPrevSelectableIssue;

			const newActiveIssueId =
				isNil(activeCardId) || !isActiveCardVisible
					? getFirstSelectableIssueFn(state)
					: getNextOrPrevIssueFn(state)(activeCardId);

			const attributes = isCardNext
				? { id: 'nextIssueKeyboardShortcut', key: 'j' }
				: { id: 'previousIssueKeyboardShortcut', key: 'k' };
			payload.fireShortcutAnalyticsFn(attributes);

			if (isNil(newActiveIssueId)) {
				return Observable.empty<never>();
			}

			const actions: AnyAction[] = [
				cardSingleSelect(newActiveIssueId),
				cardFocus(newActiveIssueId),
				cardAutoScroll(newActiveIssueId),
			];

			if (isIssueModalShowingSelector(state)) {
				const issueKey = getIssueById(state, newActiveIssueId).key;
				actions.push(openIssueModalDebounced(issueKey));
			}

			return Observable.from(actions);
		});
