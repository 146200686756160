import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

export const METRIC_NOT_FOUND = 'metric not found';

const SEMIBOLD = 600;
export const NO_GOAL_ERROR_PATTERN = /No goal with this id \d+ for time metric \d+/;

export const SLA_CUSTOM_FIELD_TYPE = 'com.atlassian.servicedesk:sd-sla-field' as const;

const defaultBackgroundColor = token('color.background.accent.gray.subtlest', '#F1F2F4');
const successFailureBackgroundColor = token('color.background.accent.gray.subtlest', '#F1F2F4');
const defaultTextColor = token('color.text.subtle', '#44546F');

export const SUCCESS_STYLE = {
	backgroundColor: successFailureBackgroundColor,
	textColor: defaultTextColor,
	iconColor: token('color.icon.success', '#22A06B'),
	fontWeight: token('font.weight.bold'),
	backgroundIconColor: 'transparent',
};

export const FAILURE_STYLE = {
	backgroundColor: successFailureBackgroundColor,
	textColor: defaultTextColor,
	iconColor: token('color.icon.danger', '#E34935'),
	fontWeight: token('font.weight.bold'),
	backgroundIconColor: 'transparent',
};

export const PAUSED_STYLE = {
	backgroundColor: defaultBackgroundColor,
	textColor: defaultTextColor,
	iconColor: token('color.icon.subtle', '#626F86'),
	fontWeight: SEMIBOLD,
	backgroundIconColor: 'transparent',
};

export const PAUSED_BREACHED_STYLE = {
	backgroundColor: defaultBackgroundColor,
	textColor: defaultTextColor,
	fontWeight: SEMIBOLD,
	iconColor: token('color.icon.inverse', colors.N0),
	backgroundIconColor: token('color.icon.danger', '#E34935'),
};

export const PAUSED_UNDER_THIRTY_REMAINING_STYLE = {
	backgroundColor: defaultBackgroundColor,
	textColor: defaultTextColor,
	fontWeight: SEMIBOLD,
	iconColor: token('color.icon.inverse', colors.N0),
	backgroundIconColor: token('color.icon.warning', colors.Y400),
};

export const ONGOING_STYLE = {
	backgroundColor: defaultBackgroundColor,
	textColor: defaultTextColor,
	iconColor: token('color.icon.subtle', '#626F86'),
	fontWeight: SEMIBOLD,
	backgroundIconColor: 'transparent',
};

export const ONGOING_BREACHED_STYLE = {
	backgroundColor: token('color.background.danger.bold', '#CA3521'),
	textColor: token('color.text.inverse', colors.N0),
	iconColor: token('color.icon.inverse', colors.N0),
	fontWeight: token('font.weight.bold'),
	backgroundIconColor: 'transparent',
};

export const ONGOING_UNDER_THIRTY_REMAINING_STYLE = {
	backgroundColor: token('color.background.warning.bold', '#E2B203'),
	textColor: token('color.text.warning.inverse', '#172B4D'),
	iconColor: token('color.text.warning.inverse', '#172B4D'),
	fontWeight: token('font.weight.bold'),
	backgroundIconColor: 'transparent',
};
