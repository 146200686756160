import React, { useEffect, useState } from 'react';
import InfoIcon from '@atlaskit/icon/glyph/info';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useFlagService } from '@atlassian/jira-flags';
import {
	fireTrackAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import type { BaseFlagProps } from '../../common/ui/types.tsx';
import { useFlagActions } from '../../common/ui/utils.tsx';
import messages from './messages.tsx';

const LIMIT_APPROACHING_FLAG_ID = 'jsw-issue-approaching-reached-flag';

type LimitApproachingFlagProps = BaseFlagProps & {
	isProjectAdmin: boolean;
};

export const LimitApproachingFlag = ({
	isCMP,
	isProjectAdmin,
	projectKey,
	views: flagViews,
}: LimitApproachingFlagProps) => {
	const [flagShown, setFlagShown] = useState(false);
	const { showFlag } = useFlagService();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const flagActions = useFlagActions({
		isProjectAdmin,
		isCMP,
		projectKey,
		flagType: 'approaching',
	});

	const views = flagViews.map((v) => v.view);

	useEffect(() => {
		const getFlagTitle = () => {
			if (isCMP) {
				return views[0] === 'BOARD'
					? messages.boardLimitApproachingFlagTitle
					: messages.backlogLimitApproachingFlagTitle;
			}

			if (views.includes('BACKLOG') && views.includes('SPRINTS')) {
				return messages.tmpBacklogSprintsLimitApproachingFlagTitle;
			}
			if (views.includes('BACKLOG') && views.includes('BOARD')) {
				return messages.tmpBacklogBoardLimitApproachingFlagTitle;
			}
			if (views.includes('BACKLOG')) {
				return messages.tmpBacklogLimitApproachingFlagTitle;
			}
			if (views.includes('SPRINTS')) {
				return messages.tmpSprintsLimitApproachingFlagTitle;
			}
			if (views.includes('BOARD')) {
				return messages.boardLimitApproachingFlagTitle;
			}

			return messages.boardLimitApproachingFlagTitle;
		};

		const getFlagMessage = () => {
			if (isCMP) {
				if (views[0] === 'BOARD') {
					const boardLimitApproachingAdminFlagBodyMessage = expVal(
						'issue-terminology-refresh-m2-replace',
						'isEnabled',
						false,
					)
						? messages.boardLimitApproachingAdminFlagBodyIssueTermRefresh
						: messages.boardLimitApproachingAdminFlagBody;

					const boardLimitApproachingEndUserFlagBodyMessage = expVal(
						'issue-terminology-refresh-m2-replace',
						'isEnabled',
						false,
					)
						? messages.boardLimitApproachingEndUserFlagBodyIssueTermRefresh
						: messages.boardLimitApproachingEndUserFlagBody;

					return isProjectAdmin
						? boardLimitApproachingAdminFlagBodyMessage
						: boardLimitApproachingEndUserFlagBodyMessage;
				}

				const backlogLimitApproachingAdminFlagBodyMessage = expVal(
					'issue-terminology-refresh-m2-replace',
					'isEnabled',
					false,
				)
					? messages.backlogLimitApproachingAdminFlagBodyIssueTermRefresh
					: messages.backlogLimitApproachingAdminFlagBody;

				const backlogLimitApproachingEndUserFlagBodyMessage = expVal(
					'issue-terminology-refresh-m2-replace',
					'isEnabled',
					false,
				)
					? messages.backlogLimitApproachingEndUserFlagBodyIssueTermRefresh
					: messages.backlogLimitApproachingEndUserFlagBody;

				return isProjectAdmin
					? backlogLimitApproachingAdminFlagBodyMessage
					: backlogLimitApproachingEndUserFlagBodyMessage;
			}

			if (views.includes('BACKLOG') && views.includes('SPRINTS')) {
				return expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.tmpBacklogSprintsLimitApproachingFlagBodyIssueTermRefresh
					: messages.tmpBacklogSprintsLimitApproachingFlagBody;
			}
			if (views.includes('BACKLOG') && views.includes('BOARD')) {
				return expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.tmpBacklogBoardLimitApproachingFlagBodyIssueTermRefresh
					: messages.tmpBacklogBoardLimitApproachingFlagBody;
			}
			if (views.includes('BACKLOG')) {
				return expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.tmpBacklogLimitApproachingFlagBodyIssueTermRefresh
					: messages.tmpBacklogLimitApproachingFlagBody;
			}
			if (views.includes('SPRINTS')) {
				return expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.tmpSprintsLimitApproachingFlagBodyIssueTermRefresh
					: messages.tmpSprintsLimitApproachingFlagBody;
			}
			if (views.includes('BOARD')) {
				return expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.tmpBoardLimitApproachingFlagBodyIssueTermRefresh
					: messages.tmpBoardLimitApproachingFlagBody;
			}

			return expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? messages.tmpBoardLimitApproachingFlagBodyIssueTermRefresh
				: messages.tmpBoardLimitApproachingFlagBody;
		};

		const flagTitle = getFlagTitle();
		const flagDescription = getFlagMessage();

		if (!flagShown) {
			showFlag({
				id: LIMIT_APPROACHING_FLAG_ID,
				type: 'info',
				title: flagTitle,
				description: flagDescription,
				isAutoDismiss: false,
				actions: flagActions,
				icon: <InfoIcon primaryColor={token('color.icon.information', colors.B300)} label="" />,
				onDismissed: (_, analyticsEvent) => {
					if (analyticsEvent) {
						fireUIAnalytics(analyticsEvent, 'jswIssueLimitApproachingFlag');
					}
				},
			});

			fireTrackAnalytics(createAnalyticsEvent({}), 'flag shown', 'jswIssueLimitApproachingFlag');
		}
		setFlagShown(true);
	}, [showFlag, isProjectAdmin, flagActions, flagShown, createAnalyticsEvent, isCMP, views]);

	return null;
};
