import { ensureState } from 'redux-optimistic-ui';
import { getIn } from 'icepick';
import { createSelector } from 'reselect';
import type { IssuePriorityInformationState } from '../../reducers/entities/issue-priorities/types.tsx';
import type { State } from '../../reducers/types.tsx';

export const getIssuePriorityInformation = (state: State): IssuePriorityInformationState =>
	ensureState(state.entities).issuePriorityInformation;

export const getIssuePrioritySchemeByProjectId = createSelector(
	[getIssuePriorityInformation],
	(issuePriorityInformation) => (projectId: string) => {
		const schemeId = getIn(issuePriorityInformation, [
			'projectToPrioritySchemeAssociations',
			projectId,
		]);
		return getIn(issuePriorityInformation, ['issuePrioritySchemes', schemeId]);
	},
);

export const getPriorities = createSelector(
	[getIssuePriorityInformation],
	(issuePriorityInformation) => issuePriorityInformation?.issuePriorities || {},
);

export const getPrioritiesByProjectId = createSelector(
	[getIssuePrioritySchemeByProjectId, getPriorities],
	(getSchemeByProjectId, priorities) => (projectId: string) => {
		const scheme = getSchemeByProjectId(projectId);
		const priorityIds = scheme?.issuePriorityIds || [];
		return priorityIds.map((id: string) => priorities[id]);
	},
);

export const getDefaultPriorityByProjectId = createSelector(
	[getIssuePrioritySchemeByProjectId, getPriorities],
	(getSchemeByProjectId, priorities) => (projectId: string) => {
		const scheme = getSchemeByProjectId(projectId);
		const defaultIssuePriorityId = scheme?.defaultIssuePriorityId;
		return getIn(priorities, [defaultIssuePriorityId]);
	},
);
