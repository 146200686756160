import 'rxjs/add/observable/of';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/forkJoin';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/merge';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import isNil from 'lodash/isNil';
import { Observable } from 'rxjs/Observable';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { isHttpTooManyRequestsResponse } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import {
	type Attributes,
	fireOperationalAnalytics,
	fireTrackAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import type {
	IssueKey,
	IssueTransitionAndRankRequestPayload,
} from '../../../model/issue/issue-types.tsx';
import { TRANSITION } from '../../../model/issue/issue-update-origin.tsx';
import { ASSIGNEE, PARENT, STORY } from '../../../model/swimlane/swimlane-types.tsx';
import fetchDeleteIssueParent from '../../../rest/delete-issue-parent/index.tsx';
import {
	fetchSetCMPIssueParent,
	fetchSetIssueParent,
} from '../../../rest/set-issue-parent/index.tsx';
import {
	hasJiraErrorMessages,
	parseJiraErrorMessages,
} from '../../../rest/software/software-api-error.tsx';
import {
	issueRankTransitionService,
	issueTransitionService,
} from '../../../services/board-card-move/index.tsx';
import { getIssueCreateOrUpdateFields } from '../../../services/issue/issue-data-transformer.tsx';
import { issueUpdateService } from '../../../services/issue/issue-update-service.tsx';
import { makeServiceContext } from '../../../services/service-context.tsx';
import {
	issueRankTransitionSuccess,
	issueRankTransitionFailure,
	type IssueRankTransitionRequestAction,
	ISSUE_RANK_TRANSITION_REQUEST,
	issueRankTransitionAbort,
	issueRankTransitionFailureFieldRequired,
	issueRankTransitionUpdateOptimistic,
} from '../../../state/actions/issue/rank-transition/index.tsx';
import { issueUpdateRequestWithTransactionId } from '../../../state/actions/issue/update/index.tsx';
import { getTransitionsByColumnAndIssueType } from '../../../state/selectors/card-transitions/card-transitions-selectors.tsx';
import {
	getStatusesByColumnId,
	isDoneColumnSelector,
	firstGlobalTransitionIdForColumnWithProjectFilteringSelector,
} from '../../../state/selectors/column/column-selectors.tsx';
import { boardIssuesSelector } from '../../../state/selectors/issue/board-issue-selectors.tsx';
import {
	getIssueById,
	getIssueTypeLevelForBoardIssue,
	getRankConfig,
} from '../../../state/selectors/issue/issue-selectors.tsx';
import {
	contextPathSelector,
	rapidViewIdSelector,
	projectIdSelector,
	getIsCMPBoard,
} from '../../../state/selectors/software/software-selectors.tsx';
import { makeSwimlaneValuesSelector } from '../../../state/selectors/swimlane/swimlane-selectors.tsx';
import type { Action, ActionsObservable, MiddlewareAPI, State } from '../../../state/types.tsx';
import {
	getStatusCodeGroup,
	shouldFireFailureSLOMetric,
} from '../../utils/issue-rank-transition/index.tsx';
import {
	openTransitionScreen,
	TRANSITION_SCREEN_CANCEL,
	TRANSITION_SCREEN_ERROR,
	TRANSITION_SCREEN_SUCCESS,
	SOFTWARE_BOARD_ISSUE_TRANSITION,
} from './transition-screens/index.tsx';

/**
 * Add extra parameters to the analytics event
 */
export function updateAnalyticsPayload(
	analyticsEvent: UIAnalyticsEvent,
	state: State,
	{
		payload: {
			issueIds,
			sourceColumnId,
			destinationColumnId,
			sourceSwimlaneId,
			destinationSwimlaneId,
			isDestinationDone,
			sourceStatus,
			destinationStatus,
		},
	}: IssueRankTransitionRequestAction,
): void {
	const updatedItems = [];
	let rankOnlyOp = true;
	const firstStatusInDestinationColumn = getStatusesByColumnId(state)(destinationColumnId)[0];

	updatedItems.push({
		name: 'statusCategory',
		oldValName: sourceStatus?.category,
		// destinationStatus is undefined when board is in default mode, retrieve first status from destination column
		newValName: destinationStatus
			? destinationStatus?.category
			: firstStatusInDestinationColumn.category,
	});

	if (sourceSwimlaneId !== destinationSwimlaneId) {
		updatedItems.push(
			{
				name: 'assignee',
			},
			{
				name: 'swimlane',
			},
		);
		rankOnlyOp = false;
	}
	if (sourceColumnId !== destinationColumnId) {
		updatedItems.push({
			name: 'status',
			oldValId: sourceStatus?.id,
			// destinationStatus is undefined when board is in default mode, retrieve first status from destination column
			newValId: destinationStatus ? destinationStatus?.id : firstStatusInDestinationColumn.id,
		});

		const isSourceDone = isDoneColumnSelector(state)(sourceColumnId);
		if (isSourceDone !== isDestinationDone) {
			updatedItems.push({
				name: 'resolution',
				oldValId: isSourceDone,
				newValId: isDestinationDone,
			});
		}
		rankOnlyOp = false;
	}
	if (rankOnlyOp) {
		updatedItems.push({
			name: `customfield_${getRankConfig(state).rankCustomFieldId}`,
		});
	}
	const issueTypeLevel = getIssueTypeLevelForBoardIssue(state)(issueIds[0]);
	analyticsEvent.update({ updatedItems, issueTypeLevel });
}

export function buildTransitionRankRequest(
	state: State,
	{
		payload: {
			issueIds,
			sourceColumnId,
			destinationColumnId,
			rankBeforeIssueId,
			rankAfterIssueId,
			transitionId,
		},
	}: IssueRankTransitionRequestAction,
): IssueTransitionAndRankRequestPayload {
	const rapidViewId = rapidViewIdSelector(state);
	const allIssues = boardIssuesSelector(state);
	const issueKeys = issueIds.map((issueId) => allIssues[String(issueId)]?.key);
	const { rankCustomFieldId } = getRankConfig(state);

	const requestPayload = {
		rapidViewId,
		issueKeys,
		rankCustomFieldId,
		rankBeforeIssueIdOrKey: rankBeforeIssueId,
		rankAfterIssueIdOrKey: rankAfterIssueId,
	};

	// If FF enabled, make sure we filter by project key as well

	const issue = allIssues[String(issueIds[0])];
	const selectedTransition =
		isNil(transitionId) && sourceColumnId !== destinationColumnId
			? firstGlobalTransitionIdForColumnWithProjectFilteringSelector(state)(
					destinationColumnId,
					issue,
				)
			: transitionId;

	return {
		...requestPayload,
		selectedTransitionId: selectedTransition,
	};
}

export function buildRankRequest(
	state: State,
	{ payload: { issueIds, rankBeforeIssueId, rankAfterIssueId } }: IssueRankTransitionRequestAction,
): IssueTransitionAndRankRequestPayload {
	const rapidViewId = rapidViewIdSelector(state);
	const allIssues = boardIssuesSelector(state);
	const issueKeys = issueIds.map((issueId) => allIssues[String(issueId)]?.key);
	const { rankCustomFieldId } = getRankConfig(state);

	return {
		rapidViewId,
		issueKeys,
		rankCustomFieldId,
		rankBeforeIssueIdOrKey: rankBeforeIssueId,
		rankAfterIssueIdOrKey: rankAfterIssueId,
	};
}

/**
 * Perform all updates for a swimlane move drag operation and return an
 * observable that completes after all of the operations emit.
 *
 * If multiple issues are moved between swimlanes at the same time, this will
 * fire requests concurrently.
 */
function doSwimlaneMove(
	state: State,
	{
		payload: { issueIds, sourceSwimlaneId, destinationSwimlaneId },
	}: IssueRankTransitionRequestAction,
): Observable<unknown> {
	const ctx = makeServiceContext(state);
	const allIssues = boardIssuesSelector(state);
	const contextPath = contextPathSelector(state);
	const projectId = projectIdSelector(state);
	const isCMPBoard = getIsCMPBoard(state);

	const values =
		destinationSwimlaneId && sourceSwimlaneId !== destinationSwimlaneId
			? makeSwimlaneValuesSelector(state)(destinationSwimlaneId)
			: null;
	if (!values) {
		return Observable.empty<never>();
	}

	if (values.type === ASSIGNEE) {
		const issueFields = getIssueCreateOrUpdateFields(values);
		return Observable.forkJoin(
			issueIds.map((issueId) =>
				issueUpdateService(ctx, {
					issueKey: allIssues[String(issueId)]?.key,
					values: issueFields,
				}),
			),
		);
	}

	if (values.type === STORY) {
		if (isCMPBoard) {
			return Observable.forkJoin(
				issueIds.map((issueId) => {
					const issueKey = getIssueById(state, issueId)?.key;
					return fetchSetCMPIssueParent(issueKey, Number(values.parentId));
				}),
			);
		}

		return fetchSetIssueParent(contextPath, projectId, Number(values.parentId), {
			issueIds,
		});
	}

	if (values.type === PARENT) {
		if (isCMPBoard) {
			return Observable.forkJoin(
				issueIds.map((issueId) => {
					const issueKey = getIssueById(state, issueId)?.key;
					return fetchSetCMPIssueParent(
						issueKey,
						values.parentId ? Number(destinationSwimlaneId) : null,
					);
				}),
			);
		}

		if (values.parentId) {
			return fetchSetIssueParent(contextPath, projectId, Number(destinationSwimlaneId), {
				issueIds,
			});
		}
		// If parentId is null then we need to unassign the parent
		return fetchDeleteIssueParent(contextPath, projectId, { issueIds });
	}

	return Observable.empty<never>();
}

type HandleSuccessInput = {
	issueKeys: IssueKey[];
	issueIds: IssueId[];
	analyticsEvent: UIAnalyticsEvent;
	txId: string;
	extraAnalyticsAttrs?: Attributes;
	parentIds?: (IssueId | null)[];
	batchId?: string;
	isCMPBoard: boolean;
	destinationStatus?: string;
};

export const handleSuccess = ({
	issueKeys,
	issueIds,
	analyticsEvent,
	txId,
	extraAnalyticsAttrs,
	parentIds,
	batchId,
	isCMPBoard,
	destinationStatus,
}: HandleSuccessInput): Observable<Action> => {
	if (analyticsEvent) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		fireTrackAnalytics(analyticsEvent, 'cards moved', {
			...extraAnalyticsAttrs,
			issueKeys: issueKeys.toString(),
			issueIds: issueIds.toString(),
		} as Attributes);
	}

	if (analyticsEvent && parentIds) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		fireTrackAnalytics(analyticsEvent, 'issueParent updated', {
			...extraAnalyticsAttrs,
			issueKeys: issueKeys.toString(),
			issueIds: issueIds.toString(),
			parentId: parentIds.toString(),
		} as Attributes);
	}

	const actions: Action[] = [
		issueRankTransitionSuccess({
			optimisticId: txId,
			issueIds,
			batchId,
			analyticsEvent,
			issueKeys,
			destinationStatus,
		}),
	];

	if (!isCMPBoard) {
		actions.push(issueUpdateRequestWithTransactionId(issueIds, TRANSITION, txId));
	}

	return Observable.from(actions);
};

export function handleIssueTransitionFailure({
	error,
	txId,
	batchId,
	analyticsEvent,
}: {
	error: FetchError;
	txId: string;
	batchId?: string;
	analyticsEvent: UIAnalyticsEvent;
}) {
	log.safeErrorWithoutCustomerData(
		'transition.and.rank.failure',
		'Failed to transition issue',
		error,
	);

	if (analyticsEvent && shouldFireFailureSLOMetric(error)) {
		fireOperationalAnalytics(analyticsEvent, 'cards moveFailed', {
			errorMessage: error.message ? error.message : error,
			statusCodeGroup: getStatusCodeGroup(error),
		});
	}

	if (isHttpTooManyRequestsResponse(error)) {
		fireOperationalAnalytics(analyticsEvent, 'cards moveAborted', {
			errorMessage: error.message ? error.message : error,
			statusCodeGroup: '429',
		});
	}

	return Observable.of(issueRankTransitionFailure(txId, batchId));
}

export function handleIssueRankTransitionAction(
	store: MiddlewareAPI,
	action: IssueRankTransitionRequestAction,
): Observable<Action> {
	const state = store.getState();
	const {
		payload: {
			issueIds,
			sourceColumnId,
			destinationColumnId,
			sourceSwimlaneId,
			destinationSwimlaneId,
			batchId,
			transitionId,
			isDestinationDone,
			destinationStatus,
			rankAfterIssueId,
			rankBeforeIssueId,
			isRankable,
			mode,
		},
		meta: { analyticsEvent },
	} = action;
	const txIdOld = String(action.meta.optimistic?.id);
	const requestPayload = buildTransitionRankRequest(state, action);
	updateAnalyticsPayload(analyticsEvent, state, action);

	const allIssues = boardIssuesSelector(state);
	const issueParents = issueIds.map((issueId) => allIssues[String(issueId)].parentId);

	const issue = allIssues[String(issueIds[0])];
	const destinationTransitions = getTransitionsByColumnAndIssueType(state, {
		columnId: destinationColumnId,
		issueTypeId: issue.typeId,
		projectId: issue.projectId,
	});

	const transitionIndex =
		requestPayload.selectedTransitionId != null
			? destinationTransitions.findIndex(
					(transition) => transition.id === requestPayload.selectedTransitionId,
				)
			: null;

	const transition = transitionIndex != null ? destinationTransitions[transitionIndex] : null;
	const extraAnalyticsAttrs: Record<string, unknown> = {
		movedColumns: sourceColumnId !== destinationColumnId,
		hasMovedSwimlanes: sourceSwimlaneId !== destinationSwimlaneId,
		columnTransitionsCount: destinationTransitions.length,
		transitionIndex,
	};
	const isCMPBoard = getIsCMPBoard(state);

	const transitionOptimisticUpdateAction = issueRankTransitionUpdateOptimistic({
		sourceColumnId,
		issueIds,
		destinationColumnId,
		isDestinationDone,
		destinationStatus,
		rankAfterIssueId,
		rankBeforeIssueId,
		isRankable,
		mode,
		isCMPBoard,
		analyticsEvent,
	});
	const txId = transitionOptimisticUpdateAction.meta.optimistic.id ?? txIdOld;
	const transitionOptimisticUpdate$ = Observable.of(transitionOptimisticUpdateAction);

	const error = (err: FetchError): Observable<Action> => {
		!transition?.hasScreen &&
			fireTrackAnalytics(analyticsEvent, 'issueTransition failed', {
				triggerPointKey: SOFTWARE_BOARD_ISSUE_TRANSITION,
				isModalOpen: false,
			});

		return hasJiraErrorMessages(err)
			? Observable.of(
					issueRankTransitionFailureFieldRequired(txId, issue.key, parseJiraErrorMessages(err)),
				)
			: handleIssueTransitionFailure({ error: err, txId, batchId, analyticsEvent });
	};

	const success = () => {
		!transition?.hasScreen &&
			fireTrackAnalytics(analyticsEvent, 'issueTransition success', {
				triggerPointKey: SOFTWARE_BOARD_ISSUE_TRANSITION,
				isModalOpen: false,
			});
		return handleSuccess({
			issueKeys: requestPayload.issueKeys,
			issueIds,
			analyticsEvent,
			txId,
			extraAnalyticsAttrs,
			parentIds: issueParents,
			batchId,
			isCMPBoard,
			destinationStatus: destinationStatus?.name,
		});
	};

	const hasScreen = transition?.hasScreen === true;

	const doTransitionRank = (): Observable<Action> => {
		const ctx = makeServiceContext(store.getState());

		if (hasScreen) {
			const dispatch = store.dispatch.bind(store);
			// TODO this only works for single issue drag, also if this is
			// aborted or fails the correct behaviour would be to revert the
			// entire operation, but we will already have committed issue field
			// changes.
			return openTransitionScreen({
				issueId: String(issue.id),
				issueKey: issue.key,
				transitionId: transition.id,
				analyticsEvent,
				dispatch,
			}).flatMap((result) => {
				if (result.type === TRANSITION_SCREEN_SUCCESS) {
					if (!isRankable) {
						return Observable.merge(transitionOptimisticUpdate$, success());
					}

					const shouldRankRequest =
						mode === 'TRANSITION_AND_RANK' && (rankBeforeIssueId || rankAfterIssueId);

					// don't rank request if there are no issues to rank against
					if (!shouldRankRequest) {
						return Observable.merge(transitionOptimisticUpdate$, success());
					}

					const requestRankPayload = buildRankRequest(state, action);
					return Observable.merge(
						transitionOptimisticUpdate$,
						issueRankTransitionService(ctx, requestRankPayload)
							.flatMap(() => success())
							.catch((err) => error(err)),
					);
				}
				if (result.type === TRANSITION_SCREEN_CANCEL) {
					return Observable.of(issueRankTransitionAbort(txId));
				}
				if (result.type === TRANSITION_SCREEN_ERROR) {
					return error(result.error);
				}
				return Observable.empty();
			});
		}

		if (mode === 'TRANSITION_ONLY') {
			// Call the transition API used in legacy CMP to retain the ranking order after transition,
			// remove this once issueRankTransitionService supports this
			return issueTransitionService(ctx, {
				issueKeys: requestPayload.issueKeys,
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				selectedTransitionId: transitionId!,
				targetColumn: destinationColumnId,
			})
				.flatMap(() => success())
				.catch((err) => error(err));
		}

		return issueRankTransitionService(ctx, requestPayload)
			.flatMap(() => success())
			.catch((err) => error(err));
	};

	const hasRankOrTransitionChanged = Boolean(
		requestPayload.rankAfterIssueIdOrKey != null ||
			requestPayload.rankBeforeIssueIdOrKey != null ||
			requestPayload.selectedTransitionId != null,
	);
	const hasSwimlaneChanged = Boolean(
		destinationSwimlaneId && sourceSwimlaneId !== destinationSwimlaneId,
	);

	if (!hasSwimlaneChanged) {
		return Observable.merge(
			hasScreen ? Observable.empty() : transitionOptimisticUpdate$,
			doTransitionRank(),
		);
	}

	return Observable.merge(
		hasScreen ? Observable.empty() : transitionOptimisticUpdate$,
		doSwimlaneMove(state, action)
			.catch((err) => {
				log.safeErrorWithoutCustomerData(
					'pre.issue.transition.update.failure',
					'Failed to prepare issues data to transition',
					err,
				);
				if (hasRankOrTransitionChanged) {
					return Observable.of(null);
				}
				throw err;
			})
			.flatMap(() => (hasRankOrTransitionChanged ? doTransitionRank() : success()))
			.catch((err) => error(err)),
	);
}

const concurrencyLimits = 10;

export function issueRankTransitionEpic(
	action$: ActionsObservable,
	store: MiddlewareAPI,
): Observable<Action> {
	return action$
		.ofType(ISSUE_RANK_TRANSITION_REQUEST)
		.mergeMap(
			(action: IssueRankTransitionRequestAction) => handleIssueRankTransitionAction(store, action),
			concurrencyLimits,
		);
}
