import React, { useCallback } from 'react';
import { IconButton } from '@atlaskit/button/new';
import AddIcon from '@atlaskit/icon/core/add';
import { UNSCHEDULED_COLUMN_ID } from '@atlassian/jira-common-constants/src/column-types.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { initiateIccInUnscheduledColumn } from '../../../../../../state/actions/card/index.tsx';
import { useBoardDispatch, useBoardSelector } from '../../../../../../state/index.tsx';
import { isUnscheduledColumnICCOpen } from '../../../../../../state/selectors/card/card-selectors.tsx';
import { isIccEnabledForColumnOrSwimlane } from '../../../../../../state/selectors/inline-create/inline-create-selectors.tsx';
import { isSwimlaneCollapsed } from '../../../../../../state/selectors/swimlane/swimlane-selectors.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const CreateUnscheduledIssueButton = ({ teamId }: Props) => {
	const { formatMessage } = useIntl();
	const dispatch = useBoardDispatch();
	const isCollapsed = useBoardSelector((state) => isSwimlaneCollapsed(state, teamId));
	const isOpen = useBoardSelector((state) =>
		isUnscheduledColumnICCOpen(state, teamId, UNSCHEDULED_COLUMN_ID),
	);
	const isIccEnabled = useBoardSelector((state) =>
		isIccEnabledForColumnOrSwimlane(state, teamId, UNSCHEDULED_COLUMN_ID),
	);

	const onClick = useCallback(() => {
		dispatch(initiateIccInUnscheduledColumn(teamId, isCollapsed));
	}, [dispatch, teamId, isCollapsed]);

	if (!isIccEnabled) {
		return null;
	}
	return (
		<IconButton
			spacing="compact"
			icon={AddIcon}
			label={formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.labelIssueTermRefresh
					: messages.label,
			)}
			isTooltipDisabled={false}
			onClick={onClick}
			isDisabled={!isCollapsed && isOpen}
		/>
	);
};

export default CreateUnscheduledIssueButton;
