import { fg } from '@atlassian/jira-feature-gating';
import { CAN_MANAGE_SPRINT } from '../../model/permission/permission-types.tsx';
import { getPermissionsSelector } from '../../state/selectors/board/board-permissions-selectors.tsx';
import { hasActiveSprintsSelector } from '../../state/selectors/sprint/sprint-selectors.tsx';
import type { State } from '../../state/types.tsx';

export const shouldDisableStandup = ({
	boardType,
	isSprintsEnabled,
	hasActiveSprint,
}: {
	boardType: string | undefined;
	isSprintsEnabled: boolean;
	hasActiveSprint: boolean;
}) => {
	if (boardType === 'Kanban') {
		return false;
	}

	return isSprintsEnabled && !hasActiveSprint;
};

export function getIsCompleteSprintButtonVisible(state: State): boolean {
	if (fg('jsw-jet-yamato-fe-permissions-seq-sprint-actions')) {
		// allow the render of a disabled button if no permissions to manage the sprint
		return hasActiveSprintsSelector(state);
	}
	return hasActiveSprintsSelector(state) && !!getPermissionsSelector(state)[CAN_MANAGE_SPRINT];
}
