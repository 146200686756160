import React, { useCallback, type SyntheticEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { useIsDismissed } from '@atlassian/jira-onboarding-quickstart-core/src/services/index.tsx';
import ColumnConfigurationButton from '@atlassian/jira-platform-board-kit/src/ui/column/column-configuration/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { BoardCreateColumnButtonWrapperNudgeAsync } from '@atlassian/jira-software-onboarding-nudges--next/src/ui/board-create-column/async.tsx';
import { ConditionalNudgeWrapper } from '@atlassian/jira-software-onboarding-nudges--next/src/ui/conditional-nudge-wrapper/index.tsx';
import { BoardCreateColumnKanbanTourNudgeAsync } from '@atlassian/jira-software-onboarding-nudges--next/src/ui/kanban-onboarding-tour/board-create-column/async.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';

type Props = {
	isKanbanBoard: boolean;
	isCMPBoard: boolean;
	url: string;
};

const ColumnConfiguration = ({ url, isKanbanBoard, isCMPBoard }: Props) => {
	const { push } = useRouterActions();
	const [isOnboardingDismissed] = useIsDismissed();

	const onClick = useCallback(
		(event: SyntheticEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'clickColumnConfigurationButton');

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			isCMPBoard ? window.open(url, '_self') : push(url);
		},
		[push, url, isCMPBoard],
	);

	const AsyncNudgeComponent = isKanbanBoard
		? BoardCreateColumnKanbanTourNudgeAsync
		: BoardCreateColumnButtonWrapperNudgeAsync;

	return (
		<ConditionalNudgeWrapper
			conditionsToApplyWrapper={!isOnboardingDismissed}
			Wrapper={AsyncNudgeComponent}
		>
			<ColumnConfigurationButton onClick={onClick} />
		</ConditionalNudgeWrapper>
	);
};

export default ColumnConfiguration;
