// Note: Metrics key need to be added to key - enum list in the data-portal registry
// - https://data-portal.internal.atlassian.com/analytics/registry/28587?tabIndex=Attributes

import { metrics } from '@atlassian/browser-metrics';

const APP_METRIC_KEY = 'software.next-gen.boards';

const COLUMN_CREATE_METRIC_KEY = `${APP_METRIC_KEY}.column.create`;
const COLUMN_CREATE_SLO = 4000;

export const columnCreateAnalyticsOptions = {
	analyticKey: COLUMN_CREATE_METRIC_KEY,
	slo: COLUMN_CREATE_SLO,
} as const;

export const columnCreateInteraction = metrics.concurrent.interaction({
	key: COLUMN_CREATE_METRIC_KEY,
	histogram: {
		response: '50_100_150_200_300_500',
		result: '100_500_1000_2000_3000_4000_5000_6000',
	},
});

const COLUMN_RENAME_METRIC_KEY = `${APP_METRIC_KEY}.column.rename`;
const COLUMN_RENAME_SLO = 4000;

export const columnRenameAnalyticsOptions = {
	analyticKey: COLUMN_RENAME_METRIC_KEY,
	slo: COLUMN_RENAME_SLO,
} as const;

export const columnRenameInteraction = metrics.concurrent.interaction({
	key: COLUMN_RENAME_METRIC_KEY,
	histogram: {
		response: '50_100_150_200_300_500',
		result: '100_500_1000_2000_3000_4000_5000_6000',
	},
});

const COLUMN_RANK_METRIC_KEY = `${APP_METRIC_KEY}.column.rank`;
const COLUMN_RANK_SLO = 2000;

export const columnRankAnalyticsOptions = {
	analyticKey: COLUMN_RANK_METRIC_KEY,
	slo: COLUMN_RANK_SLO,
} as const;

export const columnRankInteraction = metrics.concurrent.interaction({
	key: COLUMN_RANK_METRIC_KEY,
	histogram: {
		response: '50_100_150_200_300_500',
		result: '100_500_1000_2000_3000_4000_5000_6000',
	},
});

const COLUMN_DELETE_METRIC_KEY = `${APP_METRIC_KEY}.column.delete`;
const COLUMN_DELETE_SLO = 4000;

export const columnDeleteAnalyticsOptions = {
	analyticKey: COLUMN_DELETE_METRIC_KEY,
	slo: COLUMN_DELETE_SLO,
} as const;

export const columnDeleteInteraction = metrics.concurrent.interaction({
	key: COLUMN_DELETE_METRIC_KEY,
	histogram: {
		response: '50_100_150_200_300_500',
		result: '100_500_1000_2000_3000_4000_5000_6000',
	},
});

const COLUMN_SET_LIMIT_METRIC_KEY = `${APP_METRIC_KEY}.column.set-limit`;
const COLUMN_SET_LIMIT_SLO = 2000;

export const columnSetLimitAnalyticsOptions = {
	analyticKey: COLUMN_SET_LIMIT_METRIC_KEY,
	slo: COLUMN_SET_LIMIT_SLO,
} as const;

export const columnSetLimitInteraction = metrics.interaction({
	key: COLUMN_SET_LIMIT_METRIC_KEY,
	histogram: {
		response: '50_100_150_200_300_500',
		result: '100_500_1000_2000_3000_4000_5000_6000',
	},
});

const BOARD_SCOPE_NON_CRITICAL_METRIC_KEY = `${APP_METRIC_KEY}.boardscope.non-critical`;
const BOARD_SCOPE_NON_CRITICAL_SLO = 1500;

export const boardScopeDataNonCriticalAnalyticsOptions = {
	analyticKey: BOARD_SCOPE_NON_CRITICAL_METRIC_KEY,
	slo: BOARD_SCOPE_NON_CRITICAL_SLO,
} as const;

const CARD_CREATE_METRIC_KEY = `${APP_METRIC_KEY}.card.create`;
const CARD_CREATE_SLO = 1000;
export const cardCreateAnalyticsOptions = {
	analyticKey: CARD_CREATE_METRIC_KEY,
	slo: CARD_CREATE_SLO,
} as const;

const LOAD_EXTENSION_METRIC_KEY = `${APP_METRIC_KEY}.load.extension`;
const LOAD_EXTENSION_SLO = 1000;

export const loadExtensionAnalyticsOptions = {
	analyticKey: LOAD_EXTENSION_METRIC_KEY,
	slo: LOAD_EXTENSION_SLO,
} as const;

const SET_USER_SWIMLANE_METRIC_KEY = `${APP_METRIC_KEY}.set.user.swimlane`;
const SET_USER_SWIMLANE_SLO = 1000;

export const setUserSwimlaneAnalyticsOptions = {
	analyticKey: SET_USER_SWIMLANE_METRIC_KEY,
	slo: SET_USER_SWIMLANE_SLO,
} as const;

const BOARD_RENAME_METRIC_KEY = `${APP_METRIC_KEY}.board.rename`;
const BOARD_RENAME_SLO = 1000;

export const boardRenameAnalyticsOptions = {
	analyticKey: BOARD_RENAME_METRIC_KEY,
	slo: BOARD_RENAME_SLO,
} as const;

export const completeSprintModalLoad = metrics.interaction({
	key: 'jsw-complete-sprint-modal',
});

const DISMISS_MIGRATION_MODAL_METRIC_KEY = `${APP_METRIC_KEY}.board.migration.dismiss`;
const DISMISS_MIGRATION_MODAL_SLO = 1000;

export const dismissMigrationModalAnalyticsOptions = {
	analyticKey: DISMISS_MIGRATION_MODAL_METRIC_KEY,
	slo: DISMISS_MIGRATION_MODAL_SLO,
} as const;
