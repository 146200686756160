import type { Action } from '@atlassian/react-sweet-state';
import type { State } from '../../types.tsx';

export const setKeepIccOpenOnListView =
	(value: boolean): Action<State> =>
	({ setState }) => {
		setState({
			keepIccOpenOnListView: value,
		});
	};
