import type { Observable as ObservableType } from 'rxjs/Observable';
import { mutation$ } from '@atlassian/jira-software-swag/src/services/mutation/index.tsx';
import { setUserSwimlaneAnalyticsOptions } from '../../common/graphql/metrics/index.tsx';
import type { SwimlaneStrategyMutationGQLData } from './types.tsx';

export const setUserSwimlaneStrategyOperationName = 'SetUserSwimlaneStrategy';
export const userSwimlaneStrategyMutation = `mutation ${setUserSwimlaneStrategyOperationName} ($boardId: ID!, $strategy: SwimlaneStrategy!) {
    setUserSwimlaneStrategy(input: {boardId: $boardId, strategy: $strategy}) {
        statusCode
        strategy
        message
    }
}`;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	contextPath: string,
	boardId: string,
	swimlaneStrategy: string,
): ObservableType<SwimlaneStrategyMutationGQLData> => {
	const transformedSwimlaneStrategyForMutation = swimlaneStrategy.toUpperCase();
	return mutation$(
		contextPath,
		setUserSwimlaneStrategyOperationName,
		userSwimlaneStrategyMutation,
		{ boardId, strategy: transformedSwimlaneStrategyForMutation },
		setUserSwimlaneAnalyticsOptions,
	);
};
