import 'rxjs/add/observable/throw';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import get from 'lodash/get';
import { type Observable as ObservableType, Observable } from 'rxjs/Observable';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { BOARD } from '@atlassian/jira-software-resource-invalidator/src/common/types.tsx';
// We import this directly because if we import it via index it could lead to a circular dependency
import CacheUpdateSubject from '@atlassian/jira-software-resource-invalidator/src/controllers/resources-cache-update-subject/index.tsx';
import type { NonCriticalDataResponse, WorkData } from '../../../model/work/work-types.tsx';
import fetchGraphQLData from '../../../rest/board-scope-graphql/non-critical/index.tsx';
import { fetchPlanDataRest } from '../../../rest/plan/index.tsx';
import type { ServiceContext } from '../../service-context.tsx';
import { transformNonCriticalData } from '../transformer/index.tsx';
import { fetchCMPBoardScopeData } from './mobile-rest/index.tsx';

export default function fetchBoardScopeDataService(
	contextPath: string,
	boardId: string,
): ObservableType<WorkData> {
	return fetchGraphQLData(contextPath, boardId)
		.flatMap((nonCritialDataResponse: NonCriticalDataResponse) => {
			// Everytime we fetch this data, we can use it to hydrate the BOARD resources-cache
			CacheUpdateSubject.next({ origin: BOARD, payload: nonCritialDataResponse });

			return Observable.of(transformNonCriticalData(nonCritialDataResponse));
		})
		.catch((error) => {
			log.safeErrorWithoutCustomerData(
				'board.non-critical.load.boardscope.graphql.failure',
				'Failed to load board scope data via GraphQl',
				{ message: get(error, 'message', '') || get(error, [0, 'message', '']) },
			);
			return Observable.throw(error);
		});
}

type FetchWorkModeDataParameters = {
	activeQuickFilters?: string[];
	includeHidden?: boolean;
};

export function fetchIncrementalPlanningBoard(
	planId: string,
	scenarioId: string,
	incrementId: string,
): ObservableType<WorkData> {
	return fetchPlanDataRest(+planId, +scenarioId, +incrementId);
}

export function fetchWorkModeData(
	ctx: ServiceContext,
	parameters: FetchWorkModeDataParameters = {},
): ObservableType<WorkData> {
	if (ctx.isIncrementPlanningBoard) {
		if (!ctx.planId || !ctx.scenarioId || !ctx.boardId) {
			throw new Error('planId and scenarioId must be provided for incremental planning board');
		}
		return fetchIncrementalPlanningBoard(ctx.planId, ctx.scenarioId, ctx.boardId);
	}

	if (ctx.isCMPBoard) {
		const { boardId } = ctx;
		return fetchCMPBoardScopeData(boardId, {
			projectType: ctx.projectType,
			...parameters,
		});
	}

	return fetchBoardScopeDataService(ctx.contextPath, ctx.boardId);
}
