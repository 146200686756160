import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import type { FlagMeta } from '../../meta/flag-meta.tsx';
import {
	beginOptimisticUiMeta,
	commitOptimisticUiMeta,
	revertOptimisticUiWithFlagMeta,
	type OptimisticUiMeta,
} from '../../meta/optimistic-ui-meta.tsx';

export const ISSUE_MEDIA_VISIBILITY_CHANGE_REQUEST =
	'state.actions.issue.media.ISSUE_MEDIA_VISIBILITY_CHANGE_REQUEST' as const;

export type IssueMediaVisibilityChangeRequestAction = {
	type: typeof ISSUE_MEDIA_VISIBILITY_CHANGE_REQUEST;
	payload: {
		issueId: IssueId;
		isVisible: boolean;
	};
	meta: OptimisticUiMeta;
};

export const issueMediaVisibilityChangeRequest = (
	issueId: IssueId,
	isVisible: boolean,
): IssueMediaVisibilityChangeRequestAction => ({
	type: ISSUE_MEDIA_VISIBILITY_CHANGE_REQUEST,
	payload: {
		issueId,
		isVisible,
	},
	meta: beginOptimisticUiMeta(),
});

export const ISSUE_MEDIA_VISIBILITY_CHANGE_SUCCESS =
	'state.actions.issue.media.ISSUE_MEDIA_VISIBILITY_CHANGE_SUCCESS' as const;

export type IssueMediaVisibilityChangeSuccessAction = {
	type: typeof ISSUE_MEDIA_VISIBILITY_CHANGE_SUCCESS;
	payload: {
		issueId: IssueId;
		isVisible: boolean;
	};
	meta: OptimisticUiMeta;
};

export const issueMediaVisibilityChangeSuccess = (
	optimisticId: string,
	issueId: IssueId,
	isVisible: boolean,
): IssueMediaVisibilityChangeSuccessAction => ({
	type: ISSUE_MEDIA_VISIBILITY_CHANGE_SUCCESS,
	payload: {
		issueId,
		isVisible,
	},
	meta: commitOptimisticUiMeta(optimisticId),
});

export const ISSUE_MEDIA_VISIBILITY_CHANGE_FAILURE =
	'state.actions.issue.media.ISSUE_MEDIA_VISIBILITY_CHANGE_FAILURE' as const;

export type IssueMediaVisibilityChangeFailureAction = {
	type: typeof ISSUE_MEDIA_VISIBILITY_CHANGE_FAILURE;
	meta: OptimisticUiMeta & FlagMeta;
};

export const issueMediaVisibilityChangeFailure = (
	optimisticId: string,
): IssueMediaVisibilityChangeFailureAction => ({
	type: ISSUE_MEDIA_VISIBILITY_CHANGE_FAILURE,
	meta: revertOptimisticUiWithFlagMeta(optimisticId),
});

export type Action =
	| IssueMediaVisibilityChangeRequestAction
	| IssueMediaVisibilityChangeSuccessAction
	| IssueMediaVisibilityChangeFailureAction;
