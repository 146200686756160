import { expVal } from '@atlassian/jira-feature-experiments';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import flagMessages from '../../../../view/flags/messages.tsx';
import { warningFlagMeta, type FlagMeta } from '../../meta/flag-meta.tsx';

export const SHOW_ISSUE_ADD_LABEL_MODAL =
	'state.actions.issue.add-label-modal.SHOW_ISSUE_ADD_LABEL_MODAL' as const;

export type ShowIssueAddLabelModalAction = {
	type: typeof SHOW_ISSUE_ADD_LABEL_MODAL;
	payload: IssueId[];
};

export const showIssueAddLabelModal = (issueIds: IssueId[]): ShowIssueAddLabelModalAction => ({
	type: SHOW_ISSUE_ADD_LABEL_MODAL,
	payload: issueIds,
});

export const HIDE_ISSUE_ADD_LABEL_MODAL =
	'state.actions.issue.add-label-modal.HIDE_ISSUE_ADD_LABEL_MODAL' as const;

export type HideIssueAddLabelModalAction = {
	type: typeof HIDE_ISSUE_ADD_LABEL_MODAL;
};

export const hideIssueAddLabelModal = (): HideIssueAddLabelModalAction => ({
	type: HIDE_ISSUE_ADD_LABEL_MODAL,
});

export const ISSUE_ADD_LABELS = 'state.actions.issue.add-label-modal.ISSUE_ADD_LABELS' as const;

/**
 * Add labels into the redux state. Does not trigger any HTTP request.
 *
 * This is called when the labels modal is submitted.
 */
export type IssueAddLabelsAction = {
	type: typeof ISSUE_ADD_LABELS;
	payload: {
		issueIds: IssueId[];
		labels: string[];
	};
};

/**
 * Add labels into the redux state. Does not trigger any HTTP request.
 */
export const issueAddLabels = (issueIds: IssueId[], labels: string[]): IssueAddLabelsAction => ({
	type: ISSUE_ADD_LABELS,
	payload: {
		issueIds,
		labels,
	},
});

export const ISSUE_ADD_LABELS_SUCCESS =
	'state.actions.issue.add-label-modal.ISSUE_ADD_LABELS_SUCCESS' as const;

export type IssueAddLabelsSuccessAction = {
	type: typeof ISSUE_ADD_LABELS_SUCCESS;
	payload: IssueId[];
};

export const issueAddLabelsSuccess = (issueIds: IssueId[]): IssueAddLabelsSuccessAction => ({
	type: ISSUE_ADD_LABELS_SUCCESS,
	payload: issueIds,
});

export const ISSUE_ADD_LABELS_FAILURE =
	'state.actions.issue.add-label-modal.ISSUE_ADD_LABELS_FAILURE' as const;

export type IssueAddLabelsFailureAction = {
	type: typeof ISSUE_ADD_LABELS_FAILURE;
	payload: {
		savedIssueIds: IssueId[];
		failedIssueIds: IssueId[];
	};
	meta: FlagMeta;
};

export const issueAddLabelsFailure = (
	savedIssueIds: IssueId[],
	failedIssueIds: IssueId[],
): IssueAddLabelsFailureAction => ({
	type: ISSUE_ADD_LABELS_FAILURE,
	payload: {
		savedIssueIds,
		failedIssueIds,
	},
	meta: {
		...warningFlagMeta({
			titleMessage: flagMessages.genericTitle,
			descriptionMessage: expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? flagMessages.issueLabelsSaveFailedIssueTermRefresh
				: flagMessages.issueLabelsSaveFailed,
		}),
	},
});

export type Action =
	| ShowIssueAddLabelModalAction
	| HideIssueAddLabelModalAction
	| IssueAddLabelsAction
	| IssueAddLabelsSuccessAction
	| IssueAddLabelsFailureAction;
