import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import type { ColumnId } from '../../../../model/column/column-types.tsx';
import type { SwimlaneId } from '../../../../model/issue/issue-types.tsx';

export const CARDS_MOVE = 'state.action.card.card-move.CARDS_MOVE';

export type CardsMoveAction = {
	type: typeof CARDS_MOVE;
	payload: {
		cardIds: IssueId[];
		sourceColumnId: ColumnId;
		destinationColumnId: ColumnId;
		sourceCardIndex: number;
		destinationCardIndex: number;
		sourceSwimlaneId: SwimlaneId | null | undefined;
		destinationSwimlaneId: SwimlaneId | null | undefined;
		analyticsEvent: UIAnalyticsEvent;
	};
};

export const cardsMove = (payload: CardsMoveAction['payload']): CardsMoveAction => ({
	type: CARDS_MOVE,
	payload,
});

export const GROUP_CARDS_MOVE = 'state.action.card.card-move.GROUP_CARDS_MOVE';

export type Action = CardsMoveAction;
