import { useState, useRef } from 'react';
import { createFilter } from '@atlaskit/select';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	FILTER_MENU_MIN_WIDTH,
	FILTER_MENU_MAX_WIDTH,
	FILTER_SEARCH_THRESHOLD,
	FILTER_MENU_MIN_WIDTH_ON_NESTED,
	EXPERIMENT_MAKE_IT_EASIER_TO_DESELECT_FILTER_ITEMS_THRESHOLD,
} from './constants.tsx';
import messages from './messages.tsx';
import type { ListFilterProps, GroupOption, Option } from './types.tsx';

const isValueGroupOption = (value: GroupOption | Option): value is GroupOption =>
	'options' in value;

export const containsOnlyOptions = (values: (GroupOption | Option)[]): values is Option[] =>
	values.every((value) => !isValueGroupOption(value));

/* For the options to have a title, they must be part of a group. If future consumers
 * want to have arbitrary groups, this component will require broader API changes.
 *
 * (!) NOTE: Options must maintain reference equality when the selected options change, otherwise
 * react-select exhibits unexpected focus behaviour. Be careful with any changes to memoization here.
 */
export const getOptionsFromProps = ({
	values,
	menuGroupTitle = undefined,
}: {
	values: ListFilterProps['values'];
	menuGroupTitle?: ListFilterProps['menuGroupTitle'];
}) => {
	// menuGroupTitle should be deprecated
	if (menuGroupTitle && containsOnlyOptions(values))
		return [{ label: menuGroupTitle, options: values }];

	return values;
};

/* We only receive the selected ids,
 * so we need to transform them into options entities.
 */
export const getSelectedOptionsFromProps = ({
	values,
	selectedValueIds,
}: {
	values: ListFilterProps['values'];
	selectedValueIds: ListFilterProps['selectedValues'];
}) => {
	const selectedIdLookup = selectedValueIds.reduce((acc: Set<string>, cur: string) => {
		acc.add(cur);
		return acc;
	}, new Set());

	const valuesToLook = values.flatMap((value) =>
		isValueGroupOption(value) ? value.options : [value],
	);

	const selectedOptions = valuesToLook.filter(({ value }) => selectedIdLookup.has(value));

	return { selectedIdLookup, selectedOptions };
};

/* Filters can used with async or sync searching, and we need different behaviours for each.
 * The main limitation is that search only displays when the number of options exceeds the provided threshold.
 */
export const getSearchableProps = ({
	optionsCount,
	isAsyncSearch,
	defaultSearchThreshold = FILTER_SEARCH_THRESHOLD,
	isNested,
}: {
	optionsCount: number;
	isAsyncSearch: boolean;
	defaultSearchThreshold?: number;
	isNested?: boolean;
}) => {
	// Search input should always be visible for async selects
	const searchThreshold = isAsyncSearch ? -1 : defaultSearchThreshold;
	const isSearchable = optionsCount > searchThreshold;
	// Due to renderToParent the grid size will change so, incresing the min width for low resolutions
	const filterMenuMinWidth = isNested ? FILTER_MENU_MIN_WIDTH_ON_NESTED : FILTER_MENU_MIN_WIDTH;
	const maxMenuWidth = FILTER_MENU_MAX_WIDTH;
	// Set the min and max width to be the same so updated search results don't cause the menu to resize
	const minMenuWidth = isSearchable ? maxMenuWidth : filterMenuMinWidth;

	// Don't filter anything on the frontend for async selects
	const filter = createFilter({ stringify: (option) => option.label });
	const filterOption = isAsyncSearch ? undefined : filter;

	return { minMenuWidth, maxMenuWidth, searchThreshold, filterOption };
};

/* Returns filter options with the selected options pinned to the top

If productionising, remove the options.length check https://hello.atlassian.net/wiki/spaces/Spork/pages/4215800674/Make+it+easier+to+deselect+filters+-+Requirements+Cut+Corners#Cut-Corners
Note we check optionsWithSelectedSection !== null for the edge case where there is a selected section and then the # of items in the filter drops below 10.
In this case we want to run logic to remove the selected items section, otherwise it will stay around regardless of selections until there are 10+ items again */
export const useGetOptionsWithSelectedOptionGroup = () => {
	const { formatMessage } = useIntl();

	const [optionsWithSelectedSection, setOptionsWithSelectedSection] = useState<
		GroupOption[] | null
	>(null);

	const shouldTrackExperimentEvents = useRef(false);

	const updateSelectedOptionGroup = ({
		isOpen,
		options,
		selectedValueIds,
	}: {
		isOpen: boolean;
		options: (GroupOption | Option)[];
		selectedValueIds: string[];
	}) => {
		if (fg('jira-make-it-easier-to-deselect-filter-items-gate')) {
			if (
				// To avoid annoyingly shifting items around while the user has the dropdown open, when only shuffle items on an opening
				isOpen === true &&
				// At least for now, scope this change so we only have a selected items section if the options don't have any existing groupings
				containsOnlyOptions(options) &&
				(options.length > EXPERIMENT_MAKE_IT_EASIER_TO_DESELECT_FILTER_ITEMS_THRESHOLD ||
					optionsWithSelectedSection !== null)
			) {
				if (expVal('jira-make-it-easier-to-deselect-filter-items', 'isEnabled', false)) {
					if (
						selectedValueIds.length === 0 ||
						options.length <= EXPERIMENT_MAKE_IT_EASIER_TO_DESELECT_FILTER_ITEMS_THRESHOLD
					) {
						setOptionsWithSelectedSection(null);
						shouldTrackExperimentEvents.current = false;
					} else {
						// reorder selected parents to be at the top
						const selected = options.filter((option) => selectedValueIds.includes(option.value));
						const unselected = options.filter((option) => !selectedValueIds.includes(option.value));

						const optionsWithSelectedOptionGroup = [
							{
								label: formatMessage(messages.selectedItemsGroupLabel),
								options: selected,
							},
							{
								label: '',
								options: unselected,
							},
						];

						setOptionsWithSelectedSection(optionsWithSelectedOptionGroup);
						shouldTrackExperimentEvents.current = true;
					}
				} else if (
					selectedValueIds.length === 0 ||
					options.length <= EXPERIMENT_MAKE_IT_EASIER_TO_DESELECT_FILTER_ITEMS_THRESHOLD
				) {
					shouldTrackExperimentEvents.current = false;
				} else {
					shouldTrackExperimentEvents.current = true;
				}
			}
		}
	};

	return { optionsWithSelectedSection, updateSelectedOptionGroup, shouldTrackExperimentEvents };
};
