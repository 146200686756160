import { styled } from '@compiled/react';
import { type SizeType, BORDER_WIDTH, AVATAR_SIZES } from '@atlaskit/avatar';
import { colors } from '@atlaskit/theme';

import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { Z_INDEX, OVERLAP_PIXELS } from './constants.tsx';

export const hiddenCheckboxStyles = {
	position: 'absolute',
	width: '100%',
	height: '100%',
	padding: 0,
	border: 0,
	opacity: 0,
	overflow: 'hidden',
	'white-space': 'nowrap',
	cursor: 'pointer',
};

type AvatarWrapperProps = {
	selected?: boolean;
	zIndex: number;
	jiggle?: boolean;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AvatarWrapperOld = styled.div<AvatarWrapperProps>({
	display: 'inline-flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 5}px`,
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	marginLeft: `-${OVERLAP_PIXELS}px`,
	background: 'transparent',
	verticalAlign: 'top',
	padding: 0,
	transition: 'transform 0.1s ease-out',
	borderRadius: '50%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	zIndex: (props: AvatarWrapperProps) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		props.selected ? props.zIndex + Z_INDEX.SELECTED : props.zIndex,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:first-of-type': {
		marginLeft: 0,
	},
	'&:hover, &:focus, &:focus-within': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		transform: `translateY(${(props: AvatarWrapperProps) => (props.jiggle ? -gridSize / 2 : 0)}px)`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		zIndex: (props: AvatarWrapperProps) => props.zIndex + Z_INDEX.HOVER,
		'&::after': {
			position: 'absolute',
			content: "''",
			width: '100%',
			top: 0,
			left: '-1px',
			right: '-1px',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			bottom: `-${(props: AvatarWrapperProps) => (props.jiggle ? gridSize / 2 : 0)}px`,
			zIndex: -1,
			transform: 'translateY(4px)',
			cursor: 'pointer',
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AvatarWrapperNew = styled(AvatarWrapperOld)({
	height: 'unset',
});

export const AvatarWrapper = componentWithCondition(
	isVisualRefreshEnabled,
	AvatarWrapperNew,
	AvatarWrapperOld,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ActionAvatar = styled.button<{ size?: SizeType }>({
	margin: 0,
	padding: 0,
	borderRadius: '50%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderWidth: `${BORDER_WIDTH}px`,
	borderStyle: 'solid',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderColor: `${token('color.text.inverse', colors.N0)}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.neutral', colors.N20),
	transition: 'background-color 0.1s ease-out 0s',
	cursor: 'pointer',
	'&:focus': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		boxShadow: `0 0 0 ${gridSize / 4}px ${token('color.background.neutral.pressed', colors.N30)}`,
		outline: 'none',
	},
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.background.neutral.hovered', colors.N30),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ActionAvatarSpacer = styled.span({
	borderRadius: '50%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${AVATAR_SIZES.medium}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${AVATAR_SIZES.medium}px`,
	backgroundColor: 'transparent',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.T30),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	lineHeight: `${AVATAR_SIZES.medium}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AvatarBorder = styled.div<{ selected?: boolean }>({
	borderRadius: '50%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderWidth: `${BORDER_WIDTH}px`,
	borderStyle: 'solid',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderColor: (props) => (props.selected ? token('color.icon.brand', colors.B400) : 'transparent'),
});
