import type { IssuesLinksPermissionsResponse } from '../../../model/issue-links-permissions/issue-links-permissions-types.tsx';

export const FETCH_ISSUE_LINKS_PERMISSIONS =
	'state.actions.issue-links-stats.FETCH_ISSUE_LINKS_PERMISSIONS' as const;

export type FetchIssueLinksPermissionsAction = {
	type: typeof FETCH_ISSUE_LINKS_PERMISSIONS;
};

export const fetchIssueLinksPermissions = (): FetchIssueLinksPermissionsAction => ({
	type: FETCH_ISSUE_LINKS_PERMISSIONS,
});

export const SET_ISSUE_LINKS_PERMISSIONS =
	'state.actions.issue-links-stats.SET_ISSUE_LINKS_PERMISSIONS' as const;

export type SetIssueLinksPermissionsAction = {
	type: typeof SET_ISSUE_LINKS_PERMISSIONS;
	payload: IssuesLinksPermissionsResponse;
};

export const setIssueLinksPermissions = (
	payload: IssuesLinksPermissionsResponse,
): SetIssueLinksPermissionsAction => ({
	type: SET_ISSUE_LINKS_PERMISSIONS,
	payload,
});

export type Action = FetchIssueLinksPermissionsAction | SetIssueLinksPermissionsAction;
