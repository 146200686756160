import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	scopeFilterFieldValueLabel: {
		id: 'portfolio-3-plan-increment-common.ui.increment-forms.board-scope-filter.scope-filter-field-value-label',
		defaultMessage: 'Value',
		description: 'Label for the program board scope filter value',
	},
	scopeFilterFieldValuePlaceholder: {
		id: 'portfolio-3-plan-increment-common.ui.increment-forms.board-scope-filter.scope-filter-field-value-placeholder',
		defaultMessage: 'Select value',
		description: 'Placeholder for the program board scope filter value',
	},
	scopeFilterFieldLabel: {
		id: 'portfolio-3-plan-increment-common.ui.increment-forms.board-scope-filter.scope-filter-field-label',
		defaultMessage: 'Field',
		description: 'Label for the program board scope filter field',
	},
	scopeFilterFieldPlaceholder: {
		id: 'portfolio-3-plan-increment-common.ui.increment-forms.board-scope-filter.scope-filter-field-placeholder',
		defaultMessage: 'Select field',
		description: 'Placeholder for the program board scope filter field',
	},
});

export default messages;
