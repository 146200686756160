import { createContext } from 'react';
import type { AnyEntryPoint } from '../../../common/types.tsx';
import type { UseEntryPoint } from '../../use-entry-point/index.tsx';

export const createUseEntryPointContext = <TEntryPoint extends AnyEntryPoint>() =>
	createContext<UseEntryPoint<TEntryPoint>>({
		entryPointActions: {
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			cancelPreload() {},
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			load() {},
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			preload() {},
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			unload() {},
		},
		entryPointReferenceSubject: {
			getValue: () => null,
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			subscribe: () => () => {},
		},
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		stopMetric() {},
	});
