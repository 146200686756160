import { combineEpics } from 'redux-observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/mergeMap';
import sumBy from 'lodash/sumBy';
import update from 'lodash/update';
import { Observable } from 'rxjs/Observable';
import { SUBTASK_DND_TYPE } from '../../model/constants.tsx';
import { SWIMLANE_BY_SUBTASK } from '../../model/swimlane/swimlane-modes.tsx';
import { setChildIssuesMetadata } from '../../state/actions/issue/child-issues-metadata/index.tsx';
import {
	ISSUE_CREATE_REQUEST,
	type IssueCreateRequestAction,
} from '../../state/actions/issue/create/index.tsx';
import {
	ISSUE_DELETE_REQUEST,
	type IssueDeleteRequestAction,
} from '../../state/actions/issue/delete/index.tsx';
import {
	ISSUE_RANK_TRANSITION_REQUEST,
	type IssueRankTransitionRequestAction,
} from '../../state/actions/issue/rank-transition/index.tsx';
import { getIssues } from '../../state/selectors/software/software-selectors.tsx';
import { getSwimlaneMode } from '../../state/selectors/swimlane/swimlane-mode-selectors.tsx';
import type { Action, State, ActionsObservable, MiddlewareAPI } from '../../state/types.tsx';

export const issueCreateRequest = (action$: ActionsObservable, store: MiddlewareAPI) =>
	action$.ofType(ISSUE_CREATE_REQUEST).mergeMap((action: IssueCreateRequestAction) => {
		const {
			payload: { issues, type },
			meta: { swimlaneId },
		} = action;

		const allIssues = getIssues(store.getState());

		if (type !== SUBTASK_DND_TYPE) return Observable.empty<never>();

		const parent = allIssues[String(swimlaneId)];
		if (!parent) return Observable.empty<never>();

		return Observable.of(
			setChildIssuesMetadata([
				{
					id: parent.id,
					numTotalChildren: parent.numTotalChildren + issues.length,
					numCompleteChildren:
						parent.numCompleteChildren + sumBy(issues, (issue) => (issue.isDone ? 1 : 0)),
				},
			]),
		);
	});

export const issueDeleteRequest = (action$: ActionsObservable, store: MiddlewareAPI) =>
	action$.ofType(ISSUE_DELETE_REQUEST).mergeMap((action: IssueDeleteRequestAction) => {
		const {
			payload: { issue },
		} = action;

		const state = store.getState();

		if (getSwimlaneMode(state) !== SWIMLANE_BY_SUBTASK.id) return Observable.empty<never>();

		const issues = getIssues(state);
		const parent = issues[String(issue.parentId)];

		if (!parent) return Observable.empty<never>();

		return Observable.of(
			setChildIssuesMetadata([
				{
					id: parent.id,
					numTotalChildren: parent.numTotalChildren - 1,
					numCompleteChildren: parent.numCompleteChildren - (issue.isDone ? 1 : 0),
				},
			]),
		);
	});

export const issueRankTransitionRequest = (action$: ActionsObservable, store: MiddlewareAPI) =>
	action$
		.ofType(ISSUE_RANK_TRANSITION_REQUEST)
		.mergeMap((action: IssueRankTransitionRequestAction) => {
			const {
				payload: {
					issueIds,
					sourceSwimlaneId,
					destinationSwimlaneId,
					isSourceDone,
					isDestinationDone,
				},
			} = action;

			const state = store.getState();

			if (getSwimlaneMode(state) !== SWIMLANE_BY_SUBTASK.id) return Observable.empty<never>();

			const issues = getIssues(state);
			const sourceParent = issues[String(sourceSwimlaneId)];
			const destinationParent = issues[String(destinationSwimlaneId)];

			if (
				!sourceParent ||
				!destinationParent ||
				(sourceSwimlaneId === destinationSwimlaneId && isSourceDone === isDestinationDone)
			) {
				return Observable.empty<never>();
			}

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const updatedIssues: Record<string, any> = {};
			updatedIssues[String(sourceSwimlaneId)] = {
				numTotalChildren: sourceParent.numTotalChildren,
				numCompleteChildren: sourceParent.numCompleteChildren,
			};
			updatedIssues[String(destinationSwimlaneId)] = {
				numTotalChildren: destinationParent.numTotalChildren,
				numCompleteChildren: destinationParent.numCompleteChildren,
			};

			if (sourceSwimlaneId !== destinationSwimlaneId) {
				update(
					updatedIssues,
					// @ts-expect-error - TS2322 - Type 'string | null | undefined' is not assignable to type 'PropertyName'.
					[sourceSwimlaneId, 'numTotalChildren'],
					(current) => current - issueIds.length,
				);
				update(
					updatedIssues,
					// @ts-expect-error - TS2322 - Type 'string | null | undefined' is not assignable to type 'PropertyName'.
					[destinationSwimlaneId, 'numTotalChildren'],
					(current) => current + issueIds.length,
				);
			}
			if (isSourceDone) {
				update(
					updatedIssues,
					// @ts-expect-error - TS2322 - Type 'string | null | undefined' is not assignable to type 'PropertyName'.
					[sourceSwimlaneId, 'numCompleteChildren'],
					(current) => current - issueIds.length,
				);
			}
			if (isDestinationDone) {
				update(
					updatedIssues,
					// @ts-expect-error - TS2322 - Type 'string | null | undefined' is not assignable to type 'PropertyName'.
					[destinationSwimlaneId, 'numCompleteChildren'],
					(current) => current + issueIds.length,
				);
			}

			const updatedItems = Object.keys(updatedIssues).map((id) => {
				const { numTotalChildren, numCompleteChildren } = updatedIssues[id];
				return {
					id: Number(id),
					numTotalChildren,
					numCompleteChildren,
				};
			});

			return Observable.of(setChildIssuesMetadata(updatedItems));
		});

export default combineEpics<Action, State>(
	issueCreateRequest,
	issueDeleteRequest,
	issueRankTransitionRequest,
);
