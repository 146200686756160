/**
 * @generated SignedSource<<bc3d20ecb89f269e5ad3b832be7a9157>>
 * @relayHash 17884dd7a2725afd1ecddbac0203e0c6
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 40d424bfde4b11217f082daf833da3f2b16249a245232509f69ddfc2ece3f52d

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type BoardCardMoveInput = {
  boardId?: string | null | undefined;
  cardIds?: ReadonlyArray<string | null | undefined> | null | undefined;
  rank?: CardRank | null | undefined;
  swimlaneId?: string | null | undefined;
  transition?: string | null | undefined;
};
export type CardRank = {
  afterCardId?: string | null | undefined;
  beforeCardId?: string | null | undefined;
};
export type boardCardMoveGraphqlMutation$variables = {
  input?: BoardCardMoveInput | null | undefined;
};
export type boardCardMoveGraphqlMutation$data = {
  readonly boardCardMove: {
    readonly clientMutationId: string | null | undefined;
    readonly issuesWereTransitioned: boolean | null | undefined;
    readonly message: string | null | undefined;
    readonly statusCode: number | null | undefined;
    readonly success: boolean | null | undefined;
  } | null | undefined;
};
export type boardCardMoveGraphqlMutation = {
  response: boardCardMoveGraphqlMutation$data;
  variables: boardCardMoveGraphqlMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MoveCardOutput",
    "kind": "LinkedField",
    "name": "boardCardMove",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "clientMutationId"
      },
      {
        "kind": "ScalarField",
        "name": "statusCode"
      },
      {
        "kind": "ScalarField",
        "name": "issuesWereTransitioned"
      },
      {
        "kind": "ScalarField",
        "name": "success"
      },
      {
        "kind": "ScalarField",
        "name": "message"
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "boardCardMoveGraphqlMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "boardCardMoveGraphqlMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "40d424bfde4b11217f082daf833da3f2b16249a245232509f69ddfc2ece3f52d",
    "metadata": {},
    "name": "boardCardMoveGraphqlMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "07779c221ada4d8e57caa4881dae0ab4";

export default node;
