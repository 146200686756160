import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'software.main.flags.card-cover-toggle-failed.title',
		defaultMessage: 'We couldn’t show your card images',
		description: 'Title for the card cover toggle failed flag',
	},
	description: {
		id: 'software.main.flags.card-cover-toggle-failed.description',
		defaultMessage: 'Reload the page and try again.',
		description: 'Description for the card cover toggle failed flag',
	},
});
