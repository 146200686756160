import parameters, {
	type mainAsyncIssueLinksTypesQuery$variables,
} from '@atlassian/jira-relay/src/__generated__/mainAsyncIssueLinksTypesQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const linkIssuePopupEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-link-issue-popup" */ './src'),
	),
	getPreloadProps: ({ cloudId }: mainAsyncIssueLinksTypesQuery$variables) => ({
		queries: {
			issueLinksTypes: {
				options: { fetchPolicy: 'store-or-network' as const },
				parameters,
				variables: {
					cloudId,
				},
			},
		},
	}),
});
