export class AnalyticsBucketHandler {
	buckets: number[];

	formatLabel: (low: number, high?: number) => string;

	constructor(
		buckets: number[],
		options?: {
			formatLabel: (low: number, high?: number) => string;
		},
	) {
		this.buckets = buckets;
		this.formatLabel =
			options?.formatLabel ??
			((low: number, high: undefined | number) => `${low}-${high ?? 'Inf'}`);
	}

	/**
	 * Returns the lowest matching bucket for this measurement.
	 *
	 * For example, if buckets are [0, 10, 20, 30] and the measurement is 15,
	 * this function will return 10 meaning this measurement is in the 10-20
	 * range.
	 */
	getBucket(measurement: number): string {
		const bucketIndex = this.buckets.findIndex((bucket) => measurement < bucket);

		if (bucketIndex < 0) {
			return this.formatLabel(this.buckets[this.buckets.length - 1]);
		}

		return this.formatLabel(this.buckets[bucketIndex - 1], this.buckets[bucketIndex]);
	}
}

const mbBytes = 1024 * 1024; // number of bytes in a MB
const minMs = 60 * 1000; // number of ms in a minute

export const memoryBucketHandler = new AnalyticsBucketHandler(
	[0, 100 * mbBytes, 200 * mbBytes, 400 * mbBytes, 800 * mbBytes, 1600 * mbBytes, 3200 * mbBytes],
	{
		formatLabel: (lowBucket, highBucket) =>
			`${lowBucket / mbBytes}-${highBucket != null ? highBucket / mbBytes : 'Inf'}MB`,
	},
);

export const sessionTimeBucketHandler = new AnalyticsBucketHandler(
	[0, 2 * minMs, 5 * minMs, 10 * minMs, 20 * minMs],
	{
		formatLabel: (lowBucket, highBucket) =>
			`${lowBucket / minMs}-${highBucket != null ? highBucket / minMs : 'Inf'}MIN`,
	},
);
