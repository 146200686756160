import { SET_IS_FAVOURITE } from '../../../../actions/board/board-favourite/index.tsx';
import type { Action } from '../../../../actions/index.tsx';
import type { BoardFavouriteState } from './types.tsx';

const initialState: BoardFavouriteState = { isFavourite: undefined };

export const boardFavouriteReducer = (
	state: BoardFavouriteState = initialState,
	action: Action,
): BoardFavouriteState => {
	switch (action.type) {
		case SET_IS_FAVOURITE:
			return { isFavourite: action.payload.isFavourite };
		default:
			return state;
	}
};
