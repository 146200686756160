import { createStore } from '@atlassian/react-sweet-state';
import * as actions from './actions/index.tsx';
import type { State } from './types.tsx';

export type Actions = typeof actions;

export const store = createStore<State, Actions>({
	name: 'issue-clone',
	initialState: {
		canModifyReporter: true,
		cloneFetchStatus: null,
	},
	actions,
});
