import { memo, useRef, useEffect } from 'react';
import noop from 'lodash/noop';
import type { ExternalAction } from '@atlassian/jira-issue-view-store/src/actions/external-actions.tsx';
import { useQueryParam } from '@atlassian/jira-software-router-utils/src/services/query-param/index.tsx';
import { useBoardSelector } from '../../state/index.tsx';
import { getIssueModal } from '../../state/selectors/issue/issue-modal-selectors.tsx';

export type Props = {
	onOpenIssueModal: (issueKey: string, externalAction?: ExternalAction) => void;
	onCloseIssueModal: (issueKey: string | null) => void;
};

export const SyncWithRouting = ({ onOpenIssueModal = noop, onCloseIssueModal = noop }: Props) => {
	const isInitialMount = useRef(true);
	const [selectedIssue] = useQueryParam('selectedIssue');
	const issueModal = useBoardSelector(getIssueModal);
	const issueModalRef = useRef(issueModal);

	useEffect(() => {
		if (issueModalRef.current !== issueModal) {
			issueModalRef.current = issueModal;
		}
	}, [issueModal]);

	useEffect(() => {
		if (isInitialMount.current) {
			isInitialMount.current = false;
		} else if (selectedIssue) {
			if (issueModalRef?.current?.selectedIssueKey) {
				onOpenIssueModal(selectedIssue, issueModalRef?.current?.externalAction);
			} else {
				onOpenIssueModal(selectedIssue);
			}
		} else {
			onCloseIssueModal(null);
		}
	}, [selectedIssue, onOpenIssueModal, onCloseIssueModal]);

	return null;
};

export default memo<Props>(SyncWithRouting);
