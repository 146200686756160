export const DRAG_START = 'state.actions.board.drag.DRAG_START' as const;

export type DragMode = 'FLUID' | 'SNAP';

export type DragStartAction = {
	type: typeof DRAG_START;
	payload?: {
		draggableId: string;
		mode: DragMode;
	};
};

export const dragStart = (draggableId?: string, mode?: DragMode): DragStartAction =>
	draggableId && mode
		? {
				type: DRAG_START,
				payload: {
					draggableId,
					mode,
				},
			}
		: { type: DRAG_START };

export const DRAG_END = 'state.actions.board.drag.DRAG_END' as const;

export type DragEndAction = {
	type: typeof DRAG_END;
};

export const dragEnd = (): DragEndAction => ({
	type: DRAG_END,
});

export type Action = DragStartAction | DragEndAction;
