import type { Dispatch } from 'redux';
import Extension from '@atlassian/jira-common-components-ecosystem-operations/src/index.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { REFRESH_SOURCE_MODAL_CLOSED } from '../../../model/constants.tsx';
import { workRefreshData } from '../../../state/actions/work/index.tsx';
import { operationsSelector } from '../../../state/selectors/board/board-selectors.tsx';
import type { State } from '../../../state/types.tsx';

export const mapStateToProps = (state: State) => ({
	operations: operationsSelector(state),
});

type DispatchProps = {
	onModalClose: () => void;
};

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onModalClose: () => {
		dispatch(workRefreshData(REFRESH_SOURCE_MODAL_CLOSED));
	},
});

const ConnectedExtension = connect(mapStateToProps, mapDispatchToProps)(Extension);

export default ConnectedExtension;
