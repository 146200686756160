import { useCallback } from 'react';
import { useRetros } from '../index.tsx';

export const useShowCreateRetroOrCrossSellModal = () => {
	const [retrosState, retrosActions] = useRetros();

	return useCallback(() => {
		if (!retrosState.shouldCreateRetro) {
			return;
		}

		const action = retrosActions.setLoadCreateRetroModal;

		action(true);
	}, [retrosState.shouldCreateRetro, retrosActions]);
};
