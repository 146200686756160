import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

export const Separator = () => <Box xcss={separatorStyles} />;

const separatorStyles = xcss({
	height: token('space.300', '16px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderRight: `1px solid ${token('color.border', colors.N40)}`,
});
