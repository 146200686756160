import isNil from 'lodash/isNil';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { CUSTOM_FIELD_OPTION_MISSED_ERROR } from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/constants.tsx';
import type { Issue } from '../../model/issue/issue-types.tsx';
import {
	flatErrorCollection,
	parseErrorCollection,
} from '../../rest/software/software-api-error.tsx';
import cardCreateIPService from '../../services/card/create/increment-planning/index.tsx';
import cardCreateService from '../../services/card/create/index.tsx';
import { issueRankJPOService } from '../../services/issue/issue-update-service.tsx';
import {
	type IssueCreateRequestAction,
	issueCreateSuccess,
	issueCreateFailure,
	ISSUE_CREATE_REQUEST,
	issueCreateIPValidateFailure,
	customFieldMissedInIPBoard,
} from '../../state/actions/issue/create/index.tsx';
import { displayWarning } from '../../state/actions/software/index.tsx';
import { getShouldCreateWithSwimlaneContext } from '../../state/selectors/inline-create/inline-create-selectors.tsx';
import { getIssueTypeById } from '../../state/selectors/issue/issue-selectors.tsx';
import {
	getIncrementConfig,
	getIsIncrementPlanningBoard,
	getIssueProjects,
} from '../../state/selectors/software/software-selectors.tsx';
import type {
	ActionsObservable,
	MiddlewareAPI,
	BoardDependencies,
	Action,
} from '../../state/types.tsx';

const createAndRankIssuesInIPBoard = (
	action: IssueCreateRequestAction,
	store: MiddlewareAPI,
	dependencies?: BoardDependencies,
): Observable<Action> => {
	const {
		payload: { temporaryIssueIds, issues: optimisticIssues },
		meta: {
			insertBefore,
			optimistic: { id: optimisticId },
			columnIndex,
			cardIndex,
			analyticsEvent,
		},
	} = action;
	const optimisticIssue = optimisticIssues[0];
	const { projectId, typeId } = optimisticIssue;
	const state = store.getState();
	const customRequestHandlers = dependencies ? dependencies.customRequestHandlers : {};
	const incrementConfig = getIncrementConfig(state);

	const validateError = (analyticEvent: AnalyticsEvent, err?: Error) => {
		log.safeErrorWithoutCustomerData(
			'create.scenario.issue.validate.failure',
			'planId, scenarioId and incrementconfig cannot be null in IP board',
			err,
		);
		return Observable.of(issueCreateIPValidateFailure(analyticEvent, err));
	};
	const onError = (error: Error, source: 'rank' | 'addIssue'): Observable<Action> => {
		const errorCollection = parseErrorCollection(error.message);

		// error is a SwagError which contains nested objects
		// nested objects are not accepted by the backend
		const flattenedError = new Error(error.message);
		flattenedError.stack = error.stack;
		flattenedError.name = error.name;
		// @ts-expect-error - TS2339 - Property 'traceId' does not exist on type 'Error'.
		flattenedError.traceId = error.traceId;

		log.safeErrorWithoutCustomerData(
			'board.issue-create.failure',
			`Failed to ${source} when create issue in IP board`,
			flattenedError,
		);
		if (error.message === CUSTOM_FIELD_OPTION_MISSED_ERROR) {
			const boardScopeFilter = incrementConfig?.boardScopeFilter;

			return Observable.from([
				customFieldMissedInIPBoard({
					fieldValue: boardScopeFilter?.filterFieldValue || '',
					issueTypeName: getIssueTypeById(state, typeId).name,
					projectName: getIssueProjects(state)[projectId].name,
					fieldName: boardScopeFilter?.filterFieldName || '',
				}),
				issueCreateFailure(optimisticId, analyticsEvent, error),
			]);
		}
		return Observable.from([
			issueCreateFailure(optimisticId, analyticsEvent, error),
			displayWarning(errorCollection ? flatErrorCollection(errorCollection) : errorCollection),
		]);
	};
	const onSuccess = (issue: Issue) =>
		Observable.of(
			issueCreateSuccess(
				optimisticId,
				temporaryIssueIds,
				[issue],
				columnIndex,
				cardIndex,
				analyticsEvent,
			),
		);

	if (isNil(incrementConfig)) {
		return validateError(analyticsEvent, new Error('incrementconfig cannot be null'));
	}

	if (!customRequestHandlers || !customRequestHandlers.rankIssue) {
		return validateError(
			analyticsEvent,
			new Error('no request handler found for creating or ranking issues'),
		);
	}

	if (isNil(projectId)) {
		return validateError(analyticsEvent, new Error('projectId for new issue cannot be null'));
	}
	if (isNil(typeId)) {
		return validateError(analyticsEvent, new Error('issue typeId for new issue cannot be null'));
	}

	const { rankIssue } = customRequestHandlers;

	const doRank = (issue: Issue) =>
		issueRankJPOService({
			issueIds: [issue.id],
			rankBeforeIssueId: insertBefore,
			isLastPosition: isNil(insertBefore),
			requestHandler: rankIssue,
		})
			.flatMap(() => onSuccess(issue))
			.catch((error: Error) => onError(error, 'rank'));

	return cardCreateIPService(
		store.getState(),
		incrementConfig,
		optimisticIssues[0],
		customRequestHandlers,
	)
		.flatMap((issue: Issue) => doRank(issue))
		.catch((error) => onError(error, 'addIssue'));
};

const createAndRankIssues = (
	action: IssueCreateRequestAction,
	store: MiddlewareAPI,
): Observable<Action> => {
	const {
		payload: { temporaryIssueIds, issues: optimisticIssues },
		meta: {
			insertBefore,
			optimistic: { id: optimisticId },
			columnIndex,
			cardIndex,
			analyticsEvent,
			transitionInfo,
			swimlaneId,
		},
	} = action;

	const shouldCreateWithSwimlaneContext = getShouldCreateWithSwimlaneContext(store.getState())(
		swimlaneId,
	);

	return cardCreateService(
		store.getState(),
		insertBefore,
		optimisticIssues,
		transitionInfo,
		shouldCreateWithSwimlaneContext ? swimlaneId : undefined,
	)
		.flatMap((issues: Issue[]) =>
			Observable.of(
				issueCreateSuccess(
					optimisticId,
					temporaryIssueIds,
					issues,
					columnIndex,
					cardIndex,
					analyticsEvent,
				),
			),
		)
		.catch((error) => {
			const errorCollection = parseErrorCollection(error.message);

			// error is a SwagError which contains nested objects
			// nested objects are not accepted by the backend
			const flattenedError = new Error(error.message);
			flattenedError.stack = error.stack;
			flattenedError.name = error.name;
			// @ts-expect-error - TS2339 - Property 'traceId' does not exist on type 'Error'.
			flattenedError.traceId = error.traceId;

			log.safeErrorWithoutCustomerData(
				'board.issue-create.failure',
				'Failed to create issue',
				flattenedError,
			);
			return Observable.from([
				issueCreateFailure(optimisticId, analyticsEvent, error),
				displayWarning(errorCollection ? flatErrorCollection(errorCollection) : errorCollection),
			]);
		});
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	action$: ActionsObservable,
	store: MiddlewareAPI,
	dependencies?: BoardDependencies,
) =>
	action$.ofType(ISSUE_CREATE_REQUEST).mergeMap((action: IssueCreateRequestAction) => {
		if (getIsIncrementPlanningBoard(store.getState())) {
			return createAndRankIssuesInIPBoard(action, store, dependencies);
		}
		return createAndRankIssues(action, store);
	});
