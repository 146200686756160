import React, { useEffect, useRef, useMemo, memo } from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import isNil from 'lodash/isNil';
import { styledComponentWithCondition } from '@atlassian/jira-compiled-migration/src/ui/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { sharedStyles } from '@atlassian/jira-platform-board-kit/src/index.tsx';
import { useIccContext } from '@atlassian/jira-platform-inline-card-create/src/services/context/index.tsx';
import { Capability } from '../../../../common/capability/index.tsx';
import { usePersonalizedSampleData } from '../../../../common/hooks/use-personalized-sample-data/index.tsx';
import type { CardType } from '../../../../model/software/software-types.tsx';
import { SWIMLANE_BY_PROJECT } from '../../../../model/swimlane/swimlane-modes.tsx';
import { useBoardSelector } from '../../../../state/index.tsx';
import {
	getDefaultProjectIdForIssueCreation,
	getIssueTypesForIssueCreation,
} from '../../../../state/selectors/card-create/card-create-selectors.tsx';
import { getSwimlaneMode } from '../../../../state/selectors/swimlane/swimlane-mode-selectors.tsx';
import {
	columnsWithResolutionSelector,
	getLastFilteredIssueTypeId,
	getLastFilteredProjectId,
	platformSwimlaneSelector,
	unscheduledColumnSelector,
} from '../../../../state/selectors/work/work-selectors.tsx';
import {
	useCapability,
	useIsIncrementPlanningBoard,
} from '../../../../state/state-hooks/capabilities/index.tsx';
import { SampleProjectDataNudgeWrapper } from '../sample-project-data-nudge-wrapper/index.tsx';
import Column from './column/index.tsx';
import type { Props } from './types.tsx';
import { useSetViewModeFromLocalStorage } from './utils/use-set-view-mode-from-local-storage/index.tsx';
// @ts-expect-error - DISABLE_VIRTUALISATION is not defined in window global

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
const shouldDisableVirtualisation = !!window.DISABLE_VIRTUALISATION;

const EMPTY_ARRAY: CardType[] = [];

const ColumnsContainer = ({
	swimlaneId,
	isLastSwimlane,
	isFirstSwimlane,
	isUnscheduledWorkColumn = false,
	offsetTop = 0,
}: Props) => {
	const { sampleProjectDataNudgeRef, shouldShowSampleProjectDataNudge } =
		usePersonalizedSampleData();

	const isFlexible = useCapability(Capability.FLEXIBLE_BOARD_COLUMNS);
	const columns = useBoardSelector(
		isUnscheduledWorkColumn ? unscheduledColumnSelector : columnsWithResolutionSelector,
	);
	const lastFilteredIssueTypeId = useBoardSelector(getLastFilteredIssueTypeId);
	const lastFilteredProjectId = useBoardSelector(getLastFilteredProjectId);
	const defaultProjectId = useBoardSelector(getDefaultProjectIdForIssueCreation);

	const swimlaneMode = useBoardSelector(getSwimlaneMode);

	const getProjectFromSwimlaneId = useBoardSelector(platformSwimlaneSelector);

	const ref = useRef<HTMLElement>(null);
	const { initialProjectId: projectId, onChangeCardType, onChangeProject } = useIccContext();

	const isIPBoard = useIsIncrementPlanningBoard();

	const projectIdToSync = useMemo(() => {
		if (isIPBoard) {
			// Return the last filtered project ID if it exists
			if (lastFilteredProjectId) {
				return lastFilteredProjectId;
			}
			// Otherwise return defaultProjectId if projectId has not been set in inline card create context
			if (isNil(projectId) && !isNil(defaultProjectId)) {
				return defaultProjectId;
			}
		}

		if (swimlaneMode === SWIMLANE_BY_PROJECT.id && swimlaneId) {
			const swimlaneProjectId = getProjectFromSwimlaneId(swimlaneId)?.id;
			return swimlaneProjectId ? Number(swimlaneProjectId) : null;
		}

		return null;
	}, [
		isIPBoard,
		swimlaneMode,
		lastFilteredProjectId,
		projectId,
		defaultProjectId,
		getProjectFromSwimlaneId,
		swimlaneId,
	]);

	useEffect(() => {
		if (!isNil(projectIdToSync)) {
			onChangeProject(projectIdToSync);
		}
	}, [onChangeProject, projectIdToSync]);

	const projectIdForIssueType = isIPBoard ? projectId : defaultProjectId;

	const getIssueTypesForIssueCreationFn = useBoardSelector(getIssueTypesForIssueCreation);

	const issueTypes = useBoardSelector(() =>
		!isNil(projectIdForIssueType)
			? getIssueTypesForIssueCreationFn(projectIdForIssueType)
			: EMPTY_ARRAY,
	);
	// when the issue type filter changed, for IP board, will verify if the lastFilteredIssueTypeId is
	// the valid issue type of the project.
	useEffect(() => {
		if (isIPBoard) {
			const isValidIssueType = (issueType: number) =>
				issueTypes.filter((_issueType) => Number(_issueType.id) === issueType).length > 0;

			if (!isNil(lastFilteredIssueTypeId) && isValidIssueType(lastFilteredIssueTypeId)) {
				onChangeCardType(lastFilteredIssueTypeId);
			}
		} else {
			onChangeCardType(lastFilteredIssueTypeId || null);
		}
	}, [lastFilteredIssueTypeId, onChangeCardType, issueTypes, isIPBoard]);

	// We need to check the local storage view mode value when navigating back from backlog where view mode is always set to sidebar
	useSetViewModeFromLocalStorage();

	if (!__SERVER__) {
		return (
			<BoardContainer
				innerRef={sampleProjectDataNudgeRef ?? ref}
				isFlexible={isFlexible}
				isSwimlaneMode={Boolean(swimlaneId)}
				shouldShowSampleProjectDataNudge={shouldShowSampleProjectDataNudge}
			>
				{columns.map((column, index) => (
					<Column
						useVirtualisation={!shouldDisableVirtualisation}
						key={column.id}
						columnIndex={index}
						column={column}
						swimlaneId={swimlaneId}
						isLastSwimlane={isLastSwimlane}
						isFirstSwimlane={isFirstSwimlane}
						offsetTop={offsetTop}
						isUnscheduledWorkColumnPanel={isUnscheduledWorkColumn}
					/>
				))}
				{shouldShowSampleProjectDataNudge && <SampleProjectDataNudgeWrapper />}
			</BoardContainer>
		);
	}

	return (
		<BoardContainer
			innerRef={sampleProjectDataNudgeRef ?? ref}
			isFlexible={isFlexible}
			isSwimlaneMode={Boolean(swimlaneId)}
			shouldShowSampleProjectDataNudge={shouldShowSampleProjectDataNudge}
		>
			{columns.map((column, index) => (
				<Column
					useVirtualisation={false}
					key={column.id}
					columnIndex={index}
					column={column}
					swimlaneId={swimlaneId}
					isLastSwimlane={isLastSwimlane}
					isFirstSwimlane={isFirstSwimlane}
					offsetTop={offsetTop}
					isUnscheduledWorkColumnPanel={isUnscheduledWorkColumn}
				/>
			))}
			{shouldShowSampleProjectDataNudge && <SampleProjectDataNudgeWrapper />}
		</BoardContainer>
	);
};

export default memo(ColumnsContainer);

// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BoardContainerControl = styled.div<{
	isFlexible: boolean;
	isSwimlaneMode: boolean;
	shouldShowSampleProjectDataNudge: boolean;
}>`
	display: ${(props) => (props.isFlexible ? 'grid' : 'flex')};
	grid-template-columns: ${({ isFlexible }) => {
		if (!isFlexible) {
			return 'initial';
		}
		return `repeat(auto-fit,minmax(${sharedStyles.columnMinWidth}px,1fr))`;
	}};
	grid-auto-flow: column;
	flex: 1 1 auto;
	${({ isFlexible, isSwimlaneMode }) =>
		isFlexible &&
		!isSwimlaneMode &&
		'min-height: calc(var(--board-scroll-element-height) * 1px - 8px)'};
	width: ${(shouldShowSampleProjectDataNudge) =>
		shouldShowSampleProjectDataNudge ? 'max-content' : undefined};
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const BoardContainerExperiment = styled2.div<{
	isFlexible: boolean;
	isSwimlaneMode: boolean;
	shouldShowSampleProjectDataNudge: boolean;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: ({ isFlexible }) => (isFlexible ? 'grid' : 'flex'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	gridTemplateColumns: ({ isFlexible }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		!isFlexible ? 'initial' : `repeat(auto-fit,minmax(${sharedStyles.columnMinWidth}px,1fr))`,
	gridAutoFlow: 'column',
	flex: '1 1 auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	minHeight: ({ isFlexible, isSwimlaneMode }) =>
		isFlexible && !isSwimlaneMode
			? 'calc(var(--board-scroll-element-height) * 1px - 8px)'
			: undefined,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ shouldShowSampleProjectDataNudge }) =>
		shouldShowSampleProjectDataNudge ? 'max-content' : undefined,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const BoardContainer = styledComponentWithCondition(
	() => fg('jsw_compiled_migration_tanuki'),
	BoardContainerExperiment,
	BoardContainerControl,
);
