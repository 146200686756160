import { createRouterSelector } from '@atlassian/react-resource-router';

export const isPlanEmbedPath = (path: string) => {
	const embedPathRegex =
		/^\/jira\/plans\/\d+\/scenarios\/\d+\/(timeline|summary|calendar|program\/\d+|dependencies)\/embed$/;
	return embedPathRegex.test(path);
};

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
export const isPlanEmbed = () => window && isPlanEmbedPath(window.location.pathname);

export const useIsPlanEmbed = createRouterSelector(({ location: { pathname } }) =>
	isPlanEmbedPath(pathname),
);
