import { getNormalisedPerformanceFFs } from '@atlassian/jira-common-long-task-metrics/src/common/util/collectors.tsx';
import { customFilterInteraction } from '../utils/performance-analytics/index.tsx';

export const setCustomFilterInteractionFeedbackMark = () => {
	customFilterInteraction.mark('feedback');
};

export const setCustomFilterInteractionStartMark = () => {
	customFilterInteraction.start();
};

export const cancelCustomFilterInteraction = () => {
	customFilterInteraction.cancel();
};

export const stopCustomFilterInteraction = (
	numCustomFiltersApplied: number,
	numIssueIds: number,
	isCompanyManaged: boolean,
) => {
	customFilterInteraction.stop({
		customData: {
			...getNormalisedPerformanceFFs(),
			numCustomFiltersApplied,
			numIssueIds,
			isCompanyManaged,
		},
	});
};
