import { ResponseNullError } from '@atlassian/jira-early-script-utils/src/types.tsx';
import type { MobileRestApiResponse } from '@atlassian/jira-software-board-uif-types/src/index.tsx';
import { type FetchUIFBoardDataParams, fetchUIFBoardData } from '../fetch-uif-board-data/index.tsx';
import { setBoardCachedEntry } from '../uif-board-cache/index.tsx';

export interface BoardDataStreamResult {
	cachedPromise: Promise<MobileRestApiResponse | null>;
	refreshPromise: Promise<MobileRestApiResponse>;
}

export function fetchUIFBoardDataStream({
	boardId,
	queryParameters,
	fields,
}: FetchUIFBoardDataParams): BoardDataStreamResult {
	const { cachedPromise: cachedBoardData, promise } = fetchUIFBoardData({
		boardId,
		queryParameters,
		fields,
	});

	const cachedPromise = cachedBoardData.then((cache) => cache?.data ?? null);

	const refreshPromise = (async () => {
		const response = await promise;

		if (!response) {
			throw new ResponseNullError('CMP fetchUIFBoardData returned null');
		}

		setBoardCachedEntry(
			{ boardId },
			{
				timestamp: Date.now(),
				etag: response.etag,
				data: response,
			},
		);

		return response;
	})();

	return { cachedPromise, refreshPromise };
}
