/**
 * The width of the map is determined by the board size / scaling factor.
 * A lower number means a wider minimap.
 * The map will not exceed the maxMapWidth defined below.
 */
export const minScalingFactor = 14;

/**
 * The map dimensions
 */
export const maxMapWidth = 100;

/**
 * When the ratio of total board width to viewport width exceeds this constant, the map will show.
 */
export const showMapRatio = 1.1;
