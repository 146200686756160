export const POSITION_LEFT = 'left';
export const POSITION_RIGHT = 'right';
export const POSITION_BOTTOM = 'bottom';
export const POSITION_TOP = 'top';
export const POSITION_TOP_START = 'top-start';
export const POSITION_TOP_END = 'top-end';
export const POSITION_BOTTOM_START = 'bottom-start';
export const POSITION_BOTTOM_END = 'bottom-end';
export const POSITION_RIGHT_START = 'right-start';
export const POSITION_RIGHT_END = 'right-end';
export const POSITION_LEFT_START = 'left-start';
export const POSITION_LEFT_END = 'left-end';

export const DEFAULT_SCROLL_TO_NUDGE = {
	scrollIntoViewOptions: { inline: 'end', behavior: 'smooth' },
} as const;
