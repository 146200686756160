import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import type { ColumnId } from '../../../../model/column/column-types.tsx';
import type { IssueTypeId } from '../../../../model/issue/issue-types.tsx';
import type {
	OptimisticId,
	ProjectId,
	TransitionId,
} from '../../../../model/software/software-types.tsx';
import {
	type OptimisticUiMeta,
	beginOptimisticUiMeta,
	commitOptimisticUiMeta,
	revertOptimisticUiMeta,
} from '../../meta/optimistic-ui-meta.tsx';

// missing parent issue transition request
export const MISSING_PARENT_RESOLVE_REQUEST =
	'state.actions.issue.missing-parent-issue-transition.MISSING_PARENT_ISSUE_RESOLVE' as const;

type MissingParentResolveRequestPayload = {
	issueId: IssueId;
	issueTypeId: IssueTypeId;
	issueKey: IssueKey;
	projectId: ProjectId;
	transitionId: TransitionId;
	destinationColumnId: ColumnId;
};

export type MissingParentResolveRequestAction = {
	type: typeof MISSING_PARENT_RESOLVE_REQUEST;
	payload: MissingParentResolveRequestPayload;
	meta: {
		analyticsEvent: AnalyticsEvent;
	};
};

export const missingParentResolveRequest = ({
	issueId,
	issueTypeId,
	issueKey,
	projectId,
	transitionId,
	destinationColumnId,
	analyticsEvent,
}: MissingParentResolveRequestPayload & {
	analyticsEvent: AnalyticsEvent;
}): MissingParentResolveRequestAction => ({
	type: MISSING_PARENT_RESOLVE_REQUEST,
	payload: {
		issueId,
		issueTypeId,
		issueKey,
		transitionId,
		projectId,
		destinationColumnId,
	},
	meta: {
		analyticsEvent,
	},
});

// missing parent issue transition optimistic
export const MISSING_PARENT_RESOLVE_OPTIMISTIC =
	'state.actions.issue.missing-parent-issue-transition.MISSING_PARENT_RESOLVE_OPTIMISTIC' as const;

export type MissingParentResolveOptimisticAction = {
	type: typeof MISSING_PARENT_RESOLVE_OPTIMISTIC;
	payload: {
		issueId: IssueId;
		doneStatusName: string;
	};
	meta: OptimisticUiMeta;
};

export const missingParentResolveOptimistic = ({
	issueId,
	doneStatusName,
}: {
	issueId: IssueId;
	doneStatusName: string;
}): MissingParentResolveOptimisticAction => ({
	type: MISSING_PARENT_RESOLVE_OPTIMISTIC,
	payload: {
		issueId,
		doneStatusName,
	},
	meta: {
		...beginOptimisticUiMeta(),
	},
});

// missing parent issue transition success
export const MISSING_PARENT_RESOLVE_SUCCESS =
	'state.actions.issue.missing-parent-issue-transition.MISSING_PARENT_RESOLVE_SUCCESS' as const;

export type MissingParentResolveSuccessAction = {
	type: typeof MISSING_PARENT_RESOLVE_SUCCESS;
	payload: {
		issueId: IssueId;
	};
	meta: {
		analyticsEvent: AnalyticsEvent;
	} & OptimisticUiMeta;
};

export const missingParentResolveSuccess = ({
	optimisticId,
	issueId,
	analyticsEvent,
}: {
	optimisticId: OptimisticId;
	issueId: IssueId;
	analyticsEvent: AnalyticsEvent;
}): MissingParentResolveSuccessAction => ({
	type: MISSING_PARENT_RESOLVE_SUCCESS,
	payload: {
		issueId,
	},
	meta: {
		...commitOptimisticUiMeta(optimisticId),
		analyticsEvent,
	},
});

// missing parent issue transition error
export const MISSING_PARENT_RESOLVE_FAILURE =
	'state.actions.issue.missing-parent-issue-transition.MISSING_PARENT_RESOLVE_FAILURE' as const;

export type MissingParentResolveFailureAction = {
	type: typeof MISSING_PARENT_RESOLVE_FAILURE;
	payload: {
		issueId: IssueId;
		error: Error;
	};
	meta: {
		analyticsEvent: AnalyticsEvent;
	};
};

export const missingParentResolveFailure = ({
	optimisticId,
	issueId,
	error,
	analyticsEvent,
}: {
	optimisticId: OptimisticId;
	issueId: IssueId;
	error: Error;
	analyticsEvent: AnalyticsEvent;
}): MissingParentResolveFailureAction => ({
	type: MISSING_PARENT_RESOLVE_FAILURE,
	payload: {
		issueId,
		error,
	},
	meta: {
		...revertOptimisticUiMeta(optimisticId),
		analyticsEvent,
	},
});

export type Action =
	| MissingParentResolveRequestAction
	| MissingParentResolveSuccessAction
	| MissingParentResolveFailureAction
	| MissingParentResolveOptimisticAction;
