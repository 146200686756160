import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'up-flow-iframe.chunk-load-error-flag.title',
		defaultMessage: 'Something went wrong',
		description: 'Flag title. Informs a user of a general error.',
	},
	description: {
		id: 'up-flow-iframe.chunk-load-error-flag.description',
		defaultMessage: 'Refresh the page and try again.',
		description: 'Flag description. Informs a user of a general error.',
	},
	refreshPageButton: {
		id: 'up-flow-iframe.chunk-load-error-flag.refresh-page-button',
		defaultMessage: 'Refresh page',
		description:
			'Flag action button description. Indication refresh page action to clear the error.',
	},
});
