import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import isString from 'lodash/isString';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { createRetro } from '../services/confluence/create-page-retro/index.tsx';
import { createWhiteboard } from '../services/confluence/create-whiteboard-retro/index.tsx';
import type { ConfluenceWhiteboardError } from '../services/confluence/types.tsx';
import { initialState } from './constants.tsx';
import type { State, ConfluenceData, RetroType } from './types.tsx';

const resetState =
	(): Action<State> =>
	({ setState }) => {
		setState(initialState);
	};

const resetCreatedData =
	(): Action<State> =>
	({ getState, setState }) => {
		const state = getState();
		setState({
			...state,
			createRetroPage: initialState.createRetroPage,
			createWhiteboard: initialState.createWhiteboard,
		});
	};

const setSprintId =
	(sprintId: string): Action<State> =>
	({ setState }) => {
		setState({ sprintId });
	};

const setShouldCreateRetro =
	(shouldCreateRetro: boolean): Action<State> =>
	({ setState }) => {
		setState({ shouldCreateRetro });
	};

const setLoadCreateRetroModal =
	(loadCreateRetroModal: boolean): Action<State> =>
	({ setState }) => {
		setState({ loadCreateRetroModal });
	};

const setLoadCrossFlowModal =
	(loadCrossFlowModal: boolean): Action<State> =>
	({ setState }) => {
		setState({ loadCrossFlowModal });
	};

const setIsRetroLinked =
	(isRetroLinked: boolean): Action<State> =>
	({ setState }) => {
		setState({ isRetroLinked });
	};

const setIsRetroLinking =
	(isRetroLinking: boolean): Action<State> =>
	({ setState }) => {
		setState({ isRetroLinking });
	};

const setRetroLinkingError =
	(retroLinkingError: Error): Action<State> =>
	({ setState }) => {
		setState({ retroLinkingError });
	};

const setIsRetroPublished =
	(isRetroPublished: boolean): Action<State> =>
	({ setState }) => {
		setState({ isRetroPublished });
	};

const setConfluenceData =
	(data: ConfluenceData): Action<State> =>
	({ getState, setState }) => {
		const { confluenceData } = getState();
		setState({
			confluenceData: {
				...confluenceData,
				...data,
			},
		});
	};

const setRetroType =
	(retroType: RetroType): Action<State> =>
	({ setState }) => {
		setState({ retroType });
	};

const createRetroPage =
	(
		cloudId: string,
		spaceKey: string,
		parentPageId: string | null,
		sprintId: string,
		blueprintId?: string | undefined,
	): Action<State> =>
	async ({ getState, setState }) => {
		const state = getState();
		setState({
			createRetroPage: {
				...state.createRetroPage,
				loading: true,
				error: undefined,
			},
		});

		try {
			const retroPage = await createRetro(cloudId, spaceKey, parentPageId, sprintId, blueprintId);
			setState({
				createRetroPage: {
					...state.createRetroPage,
					loading: false,
					data: retroPage,
				},
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setState({
				createRetroPage: {
					...state.createRetroPage,
					loading: false,
					error,
				},
			});
		}
	};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isConfluenceWhiteboardsError = (error: any): error is ConfluenceWhiteboardError =>
	isPlainObject(error) &&
	isArray(error.errors) &&
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	error.errors.every((e: any) => isPlainObject(e) && isString(e.title) && isString(e.code));

const isConfluenceWhiteboardsDisabled = async (error: unknown): Promise<boolean> => {
	if (!(error instanceof FetchError && error.originalResponse)) {
		return false;
	}
	const errorResponse = await error.originalResponse.json();
	// Check for title until more specific error code is available (CBT-3079)
	return (
		isConfluenceWhiteboardsError(errorResponse) &&
		errorResponse.errors[0].title === 'Whiteboards are not enabled'
	);
};

const createRetroWhiteboard =
	(
		cloudId: string,
		spaceId: string,
		parentId: string | null,
		templateKey?: string,
	): Action<State> =>
	async ({ getState, setState }) => {
		const state = getState();
		setState({
			createWhiteboard: {
				...state.createWhiteboard,
				loading: true,
				error: undefined,
			},
		});

		try {
			const whiteboard = await createWhiteboard(cloudId, spaceId, parentId, templateKey);
			setState({
				createWhiteboard: {
					...state.createWhiteboard,
					loading: false,
					data: whiteboard,
				},
			});
		} catch (error: unknown | Error) {
			setState({
				isWhiteboardsDisabled:
					state.isWhiteboardsDisabled || (await isConfluenceWhiteboardsDisabled(error)),
				createWhiteboard: {
					...state.createWhiteboard,
					loading: false,
					error: error instanceof Error ? error : undefined,
				},
			});
		}
	};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	resetState,
	resetCreatedData,
	setSprintId,
	setShouldCreateRetro,
	setLoadCreateRetroModal,
	setLoadCrossFlowModal,
	setIsRetroLinked,
	setIsRetroLinking,
	setRetroLinkingError,
	setIsRetroPublished,
	setConfluenceData,
	createRetroPage,
	setRetroType,
	createRetroWhiteboard,
};
