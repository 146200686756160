import React from 'react';
import type { StandupControls } from '../../common/types.tsx';
import { EndStandupButton } from './end-standup-button/index.tsx';
import { StartStandupButton } from './start-standup-button/index.tsx';

export const StandupControlButtons = ({
	isActiveStandup,
	setIsActiveStandup,
	isStandupDisabled,
	boardId,
}: StandupControls) => {
	return isActiveStandup ? (
		<EndStandupButton setIsActiveStandup={setIsActiveStandup} boardId={boardId} />
	) : (
		<StartStandupButton
			isStandupDisabled={isStandupDisabled}
			setIsActiveStandup={setIsActiveStandup}
			boardId={boardId}
		/>
	);
};
