import { useImperativeHandle } from 'react';
import type { OnOpenContextMenuParams, ContextMenuParams } from '../../common/types.tsx';
import { useContextMenuActions } from '../context-menu/index.tsx';

export const useSetOpenContextMenuRef = ({ scope, openContextMenuRef }: ContextMenuParams) => {
	const { openMenu } = useContextMenuActions();

	const onOpenContextMenu = ({ position, analyticsEvent }: OnOpenContextMenuParams) => {
		openMenu({ position, analyticsEvent, scope, openedFrom: 'rightClick' });
	};

	useImperativeHandle(openContextMenuRef, () => ({ onOpenContextMenu }));
};
