import type { IssueLinkedStatsApi } from '../types.tsx';

export const handleOnClose =
	() =>
	({ setState }: IssueLinkedStatsApi) => {
		setState({
			openedIssueKey: '',
		});
	};

export const handleOnOpenLinkedIssueDetailsPopup =
	(openedIssueKey: string) =>
	({ setState }: IssueLinkedStatsApi) => {
		setState({
			openedIssueKey,
		});
	};

export const actions = {
	handleOnClose,
	handleOnOpenLinkedIssueDetailsPopup,
} as const;

export type Actions = typeof actions;
