import React, { type ReactElement } from 'react';
import type {
	ApplicationPermissions,
	TenantContext,
} from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import { TenantContextSubscriber } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';

export type LicenseType = 'software' | 'servicedesk';
export type Props = {
	type: LicenseType;
	children: (arg1: { result: boolean }) => ReactElement;
};

const appPermissionMapper: Record<LicenseType, keyof ApplicationPermissions> = {
	software: 'hasSoftwareAccess',
	servicedesk: 'hasServiceDeskAccess',
};

/**
 * Imperative License Check
 */
export const checkLicense = (tenantContext: TenantContext, type: LicenseType): boolean =>
	Boolean(tenantContext.appPermissions && tenantContext.appPermissions[appPermissionMapper[type]]);

export const LicenseCheck = ({ children, type }: Props) => (
	<TenantContextSubscriber>
		{({ tenantContext }) => {
			const result = checkLicense(tenantContext, type);
			return children({ result });
		}}
	</TenantContextSubscriber>
);
