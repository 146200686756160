import { useLayoutEffect } from 'react';
import type { RefObject } from '@atlassian/jira-shared-types/src/general.tsx';

type ResizeObserverCallback = (entry: ResizeObserverEntry) => void;

let resizeObserver: ResizeObserver;

const callbacks = new Map<Element, ResizeObserverCallback>();

const runCallbacks = (entries: ResizeObserverEntry[]) => {
	entries.forEach((entry: ResizeObserverEntry) => {
		const { target } = entry;
		const callback = callbacks.get(target);

		callback?.(entry);
	});
};

const observeElement = (target: HTMLElement, callback: ResizeObserverCallback) => {
	if (typeof ResizeObserver === 'undefined') {
		return;
	}

	if (!resizeObserver) {
		resizeObserver = new ResizeObserver(runCallbacks);
	}

	callbacks.set(target, callback);
	resizeObserver.observe(target);
};

const unobserveElement = (target: HTMLElement) => {
	if (!resizeObserver) {
		return;
	}

	callbacks.delete(target);
	resizeObserver.unobserve(target);
};

// NOTE: The callback parameter needs to be memoised (with useCallback) to avoid infinite re-rendering
export const useResizeObserver = (
	ref: RefObject<HTMLElement>,
	callback: ResizeObserverCallback,
) => {
	useLayoutEffect(() => {
		const element = ref.current;

		element && observeElement(element, callback);

		return () => {
			element && unobserveElement(element);
		};
	}, [ref, callback]);
};
