import React, { createContext, useContext, useEffect, useState } from 'react';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { DefaultBulkOpsFlow } from './session/index.tsx';
import type { Props, BulkOpsAnalyticsControllerContext } from './types.tsx';

const BulkOpsAnalyticsContext = createContext<BulkOpsAnalyticsControllerContext>(null);

export const BulkOpsAnalyticsProvider = (props: Props) => {
	const [bulkOpsFlow] = useState<BulkOpsAnalyticsControllerContext>(new DefaultBulkOpsFlow());
	const [bulkOpsSessionID, setBulkOpsSessionID] = useState(
		bulkOpsFlow?.getFlowAttributes().bulkOpsSessionID || undefined,
	);

	useEffect(() => {
		const unsubscribe = bulkOpsFlow?.registerAnalyticsEventPublisher((sessionId) => {
			setBulkOpsSessionID(sessionId);
		});

		return () => {
			unsubscribe?.();
		};
	}, [bulkOpsFlow]);

	return (
		<BulkOpsAnalyticsContext.Provider value={bulkOpsFlow}>
			<ContextualAnalyticsData attributes={{ bulkOpsSessionID }}>
				{props.children}
			</ContextualAnalyticsData>
		</BulkOpsAnalyticsContext.Provider>
	);
};

export const useBulkOpsSessionContext = (): BulkOpsAnalyticsControllerContext =>
	useContext(BulkOpsAnalyticsContext);
