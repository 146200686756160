import { type RefObject, type MutableRefObject, useRef, useState, useEffect } from 'react';
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom';
import debounce from 'lodash/debounce';
import range from 'lodash/range';
import { useScrollState } from '../use-scroll-state/index.tsx';

interface UseElementOffsetParams {
	initialOffset: number;
}

interface UseElementOffsetResult {
	offsetTop: number;
	ref: MutableRefObject<HTMLDivElement | null>;
}

interface RecalculateGroup {
	measureOffsetTop: () => number;
	onChange: (measure: number) => void;
	offsetTop: number;
	offsetTopRef: RefObject<number>;
}

const MINIMUM_OFFSET_DIFF = 0.05;

/**
 * VISIBLE FOR TESTING
 *
 * We keep subscribers to offset recalculation on a set. For each list we attach
 * a subscriber and when any of the lists' intersection/resize observers fire or
 * if the window resizes we re-measure all of the lists and batch a single
 * update to all offsets.
 */
export const recalculateSubscribers = new Set<RecalculateGroup>();

/**
 * Trigger a batched update for all list offsets that have changed. Corrects
 * rounding error by ignoring 0.05px changes.
 */
export const runRecalculateSubscribers = (): number => {
	const recalculateSubscribersArr = Array.from(recalculateSubscribers);
	const changes = recalculateSubscribersArr
		.map((subscriber: RecalculateGroup) => ({
			newOffsetTop: subscriber.measureOffsetTop(),
			subscriber,
		}))
		.filter(
			({ subscriber, newOffsetTop }) =>
				Math.abs((subscriber.offsetTopRef.current ?? 0) - newOffsetTop) > MINIMUM_OFFSET_DIFF,
		);

	if (!changes.length) {
		return 0;
	}

	unstable_batchedUpdates(() => {
		changes.forEach(({ subscriber, newOffsetTop }) => {
			subscriber.onChange(newOffsetTop);
		});
	});

	return changes.length;
};

// Debounced version of the call used because during DND, the ResizeObserver can
// crash due to being triggered too much
const debouncedRunRecalculateSubscribers = debounce(runRecalculateSubscribers, 50);

let resizeObserver: ResizeObserver | null = null;
if (typeof ResizeObserver !== 'undefined') {
	resizeObserver = new ResizeObserver(debouncedRunRecalculateSubscribers);
}
let intersectionObserver: IntersectionObserver | null = null;
if (typeof IntersectionObserver !== 'undefined') {
	intersectionObserver = new IntersectionObserver(runRecalculateSubscribers, {
		// Will fire every 1/5000 size visibility change.
		// A list with more than 5000 rows will fail to detect single row
		// changes.
		// A list with 5000 rows will only detect 1 row shifts at a time.
		threshold: range(5000).map((i, _, arr) => i / arr.length),
	});
}

/**
 * VISIBLE FOR TESTING
 */
export function useElementOffsetWithRef({
	initialOffset,
	ref,
}: UseElementOffsetParams & { ref: RefObject<HTMLDivElement | null> }): number {
	const [offsetTop, setOffsetTop] = useState(initialOffset);
	const previousOffsetTopRef = useRef(initialOffset);
	const { scrollElement } = useScrollState();

	useEffect(() => {
		const el = ref.current;
		if (!el) {
			throw new Error('Invariant failed, observed element is null on mount');
		}

		const onChange = (size: number) => {
			setOffsetTop(size);
			previousOffsetTopRef.current = size;
		};

		const measureOffsetTop = () => {
			if (!el || !scrollElement) {
				return previousOffsetTopRef.current;
			}

			// scrollElement defaults to Window.
			// This default value is normally only seen in Storybook.
			if (
				scrollElement instanceof Window ||
				// FIXME JIV-14148 - unclear behavior in Storybook mount strict - Window is not Window
				!scrollElement.getBoundingClientRect
			) {
				return 0;
			}

			return (
				el.getBoundingClientRect().top -
				scrollElement.getBoundingClientRect().top +
				scrollElement.scrollTop
			);
		};

		onChange(measureOffsetTop());
		const recalculateGroup = {
			measureOffsetTop,
			onChange,
			offsetTop: previousOffsetTopRef.current,
			offsetTopRef: previousOffsetTopRef,
		};
		recalculateSubscribers.add(recalculateGroup);
		resizeObserver?.observe(el);
		intersectionObserver?.observe(el);

		const onResize = (): void => {
			debouncedRunRecalculateSubscribers();
		};
		if (recalculateSubscribers.size === 1) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.addEventListener('resize', onResize);
		}

		return () => {
			recalculateSubscribers.delete(recalculateGroup);
			resizeObserver?.unobserve(el);
			intersectionObserver?.unobserve(el);

			if (recalculateSubscribers.size === 0) {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.removeEventListener('resize', onResize);
			}

			// Fire once when we clean this up since when we unmount an element we also
			// want to trigger the callback (e.g. removing a column or cardlist)
			debouncedRunRecalculateSubscribers();
		};
	}, [ref, scrollElement]);

	return offsetTop;
}

/**
 * Track the `top` edge of an element by attaching resize and intersection
 * observers to it.
 *
 * It's assumed that every relevant element has one of these attached to it.
 * Whenever any of them change, all the rectangles are updated.
 *
 * There's no contract of the exact timing the offset will be corrected.
 *
 * For example, if an unrelated element above the target is removed from the
 * DOM, this the target's offset might only be updated when it starts to
 * intersect the page, as opposed to as soon as the unrelated element is
 * removed.
 *
 * In the near future, the application may programmatically trigger recalculation,
 * to reduce flickering on the case above, without depending on asynchronous
 * updates coming from IntersectionObserver.
 */
export function useElementOffset({
	initialOffset,
}: UseElementOffsetParams): UseElementOffsetResult {
	const ref = useRef<HTMLDivElement | null>(null);
	const offsetTop = useElementOffsetWithRef({ initialOffset, ref });

	return {
		ref,
		offsetTop,
	};
}
