import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/from';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import { REFRESH_SOURCE_COLUMN_CREATE } from '../../model/constants.tsx';
import columnCreateService, {
	type ColumnCreateData,
} from '../../services/column-graphql/create/index.tsx';
import {
	type ColumnCreateRequestAction,
	columnCreateSuccess,
	columnCreateFailure,
	COLUMN_CREATE_REQUEST,
} from '../../state/actions/column/create/index.tsx';
import { workRefreshData } from '../../state/actions/work/index.tsx';
import {
	rapidViewIdSelector,
	contextPathSelector,
	projectKeySelector,
	getIsCMPBoard,
} from '../../state/selectors/software/software-selectors.tsx';
import type { ActionsObservable, MiddlewareAPI, State, Action } from '../../state/types.tsx';

const columnCreate = (state: State, action: ColumnCreateRequestAction) => {
	const contextPath = contextPathSelector(state);
	const boardId = rapidViewIdSelector(state);
	const projectKey = projectKeySelector(state);
	const { name } = action.payload;
	return columnCreateService(contextPath, boardId, name, projectKey);
};

const handleFailure = (action: ColumnCreateRequestAction, error: string) => {
	const {
		meta: { analyticsEvent },
	} = action;
	return Observable.of(
		columnCreateFailure(
			action.meta.optimistic.id,
			action.payload.temporaryColumnId,
			error,
			analyticsEvent,
		),
	);
};

const handleSuccess = (
	action: ColumnCreateRequestAction,
	result: ColumnCreateData,
	state: State,
) => {
	const {
		payload: { temporaryColumnId },
		meta: { optimistic, analyticsEvent },
	} = action;
	const { column, columns, cardTransitions } = result;

	const isCMPBoard = getIsCMPBoard(state);
	const actions: Action[] = [
		columnCreateSuccess(
			optimistic.id,
			temporaryColumnId,
			column,
			columns,
			cardTransitions,
			analyticsEvent,
		),
	];

	if (isCMPBoard) {
		actions.push(workRefreshData(REFRESH_SOURCE_COLUMN_CREATE));
	}

	return Observable.from(actions);
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable, store: MiddlewareAPI) =>
	action$.ofType(COLUMN_CREATE_REQUEST).mergeMap((action) =>
		columnCreate(store.getState(), action)
			.flatMap((result) => handleSuccess(action, result, store.getState()))
			.catch((error) => handleFailure(action, error)),
	);
