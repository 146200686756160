import get from 'lodash/get';
import type { SwagError } from '@atlassian/jira-software-swag/src/common/utils/error/index.tsx';

export const isBoardSizeLimitError = (error?: unknown | SwagError | null): boolean =>
	error != null &&
	// @ts-expect-error - TS2571 - Object is of type 'unknown'.
	error.graphQLErrors != null &&
	// @ts-expect-error - TS2571 - Object is of type 'unknown'.
	Array.isArray(error.graphQLErrors) &&
	// @ts-expect-error - TS2571 - Object is of type 'unknown'.
	error.graphQLErrors.length > 0 &&
	// @ts-expect-error - TS2571 - Object is of type 'unknown'.
	error.graphQLErrors[0] != null &&
	// @ts-expect-error - TS2571 - Object is of type 'unknown'.
	get(error.graphQLErrors[0], ['extensions', 'statusCode']) &&
	// @ts-expect-error - TS2571 - Object is of type 'unknown'.
	get(error.graphQLErrors[0], ['extensions', 'errorType']) === 'jsw.board-size-limit-exceeded';
