import type { MigrationIdStore } from './types.tsx';

const MIGRATION_ID_STORE_KEY = 'X2C-Migration-Id-Store';

export const getMigrationIdStore = (): MigrationIdStore =>
	JSON.parse(localStorage.getItem(MIGRATION_ID_STORE_KEY) || '{}');

export const setMigrationIdStore = (migrationIdStore: MigrationIdStore) =>
	localStorage.setItem(MIGRATION_ID_STORE_KEY, JSON.stringify(migrationIdStore));

export const removeMigrationIdStore = () => localStorage.removeItem(MIGRATION_ID_STORE_KEY);
