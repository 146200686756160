import { useEffect } from 'react';
import isNil from 'lodash/isNil';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import { useDiffCheck } from '../../../../../common/hooks/use-diff-check/index.tsx';
import {
	type Estimate,
	TMP_STORY_POINTS,
	INCREMENT_PLANNING_ESTIMATE_FIELD,
} from '../../../../../model/issue/issue-types.tsx';
import { setEstimate } from '../../../../../state/actions/issue/estimate/index.tsx';
import { useBoardActionCreator } from '../../../../../state/index.tsx';
import { useIsIncrementPlanningBoard } from '../../../../../state/state-hooks/capabilities/index.tsx';
import type { FieldSyncStoryPointProps } from './types.tsx';

export const FieldSyncStoryPoint = ({
	issue: { id, key, estimate },
	storypointsFieldId,
	projectKey,
}: FieldSyncStoryPointProps) => {
	const setEstimateInBoard = useBoardActionCreator((issueId: IssueId, value: Estimate) =>
		setEstimate(issueId, value),
	);
	const storyPointFromBoard = estimate?.value ?? null;
	const isIPBoard = useIsIncrementPlanningBoard();
	// In the increment planning board, scenario issue keys do not yet have a numeric identifier as
	// they have not been created in Jira yet. In this case, the key is equal to the projectKey,
	// and thus not unique. Instead, we pass the issue id to the field config service (as it
	// requires a unique identifier for each issue).

	const uniqueKey = isIPBoard && key === projectKey ? `${id}` : key;
	const [storyPointFromIssueField, { setFieldValue: setStoryPointInExtractedFields }] =
		useFieldValue({
			issueKey: uniqueKey,
			fieldKey: storypointsFieldId,
		});
	const storyPointFromBoardChanged = useDiffCheck([storyPointFromBoard]);
	const storyPointFromIssueFieldChanged = useDiffCheck([storyPointFromIssueField]);
	const storesNeedToSync = storyPointFromBoard !== storyPointFromIssueField;
	useEffect(() => {
		// Board -> Issue Field Sync
		if (storyPointFromBoardChanged && storesNeedToSync) {
			setStoryPointInExtractedFields(uniqueKey, storypointsFieldId, storyPointFromBoard);
		}
	}, [
		storypointsFieldId,
		key,
		setStoryPointInExtractedFields,
		storesNeedToSync,
		storyPointFromBoard,
		storyPointFromBoardChanged,
		uniqueKey,
	]);

	useEffect(() => {
		// Issue Field -> Board Sync
		if (
			// Ensure no sync will be called by Board as Board is the driver
			!storyPointFromBoardChanged &&
			storyPointFromIssueFieldChanged &&
			storesNeedToSync
		) {
			let estimateFieldValue: Estimate = null;
			if (isIPBoard) {
				estimateFieldValue = {
					type: INCREMENT_PLANNING_ESTIMATE_FIELD,
					fieldId: storypointsFieldId,
					value: storyPointFromIssueField || '',
				};
			} else if (!isNil(storyPointFromIssueField)) {
				estimateFieldValue = {
					type: TMP_STORY_POINTS,
					fieldId: storypointsFieldId,
					value: storyPointFromIssueField,
				};
			}

			setEstimateInBoard(id, estimateFieldValue);
		}
	}, [
		storypointsFieldId,
		id,
		setEstimateInBoard,
		storesNeedToSync,
		storyPointFromBoardChanged,
		storyPointFromIssueField,
		storyPointFromIssueFieldChanged,
		isIPBoard,
	]);

	return null;
};
