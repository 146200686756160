import React from 'react';
import { styled } from '@compiled/react';
import { Section } from '@atlaskit/menu';
import type { TemplateRecommendation } from '@atlassian/jira-automation-platform/src/common/types.tsx';
import { AutomationRecommendation } from '@atlassian/jira-automation-platform/src/ui/automation-recommendation/index.tsx';
import Skeleton from '../skeleton/styled.tsx';

type EmptyStateProps = {
	initialised?: boolean;
	canManageAutomations: boolean;
	baseAutomationUrl: string;
	setClosed: () => void;
	recommendations?: TemplateRecommendation[];
	fallback?: React.ReactNode;
};

export const ConditionalRecommendation = ({
	initialised = true,
	canManageAutomations,
	baseAutomationUrl,
	setClosed,
	recommendations,
	fallback = null,
}: EmptyStateProps) => {
	if (!initialised) {
		return <Skeleton />;
	}

	if (!canManageAutomations || !recommendations || recommendations.length === 0) {
		return fallback != null ? <Section>{fallback}</Section> : null;
	}

	return (
		<RecommendationSection>
			<AutomationRecommendation
				baseAutomationUrl={baseAutomationUrl}
				templates={recommendations}
				setClosed={setClosed}
			/>
		</RecommendationSection>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RecommendationSection = styled(Section)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'::after': { display: 'none' },
});
