import {
	columnCreateInteraction,
	columnDeleteInteraction,
	columnRankInteraction,
	columnRenameInteraction,
	columnSetLimitInteraction,
} from '../../../../common/graphql/metrics/index.tsx';
import type {
	ColumnCreateRequestAction,
	ColumnCreateSuccessAction,
} from '../../../actions/column/create/index.tsx';
import type {
	ColumnDeleteRequestAction,
	ColumnDeleteSuccessAction,
} from '../../../actions/column/delete/index.tsx';
import type {
	ColumnRankRequestAction,
	ColumnRankSuccessAction,
} from '../../../actions/column/rank/index.tsx';
import type {
	ColumnRenameRequestAction,
	ColumnRenameSuccessAction,
} from '../../../actions/column/rename/index.tsx';
import type { State } from '../../../reducers/types.tsx';

export const columnRankRequestPerformance =
	() => (preState: State, state: State, action: ColumnRankRequestAction) => {
		const concurrentId = action.meta.optimistic.id;
		columnRankInteraction(concurrentId).start();
		return null;
	};

export const columnRankSuccessPerformance =
	() => (preState: State, state: State, action: ColumnRankSuccessAction) => {
		const concurrentId = action.meta.optimistic.id;
		columnRankInteraction(concurrentId).stop();
		return null;
	};

export const columnRenameRequestPerformance =
	() => (preState: State, state: State, action: ColumnRenameRequestAction) => {
		const concurrentId = action.meta.optimistic.id;
		columnRenameInteraction(concurrentId).start();
		return null;
	};

export const columnRenameSuccessPerformance =
	() => (preState: State, state: State, action: ColumnRenameSuccessAction) => {
		const concurrentId = action.meta.optimistic.id;
		columnRenameInteraction(concurrentId).stop();
		return null;
	};

export const columnCreateRequestPerformance =
	() => (preState: State, state: State, action: ColumnCreateRequestAction) => {
		const concurrentId = String(action.payload.temporaryColumnId);
		columnCreateInteraction(concurrentId).start();
		return null;
	};

export const columnCreateSuccessPerformance =
	() => (preState: State, state: State, action: ColumnCreateSuccessAction) => {
		const concurrentId = String(action.payload.temporaryColumnId);
		columnCreateInteraction(concurrentId).stop();
		return null;
	};

export const columnDeleteRequestPerformance =
	() => (preState: State, state: State, action: ColumnDeleteRequestAction) => {
		const concurrentId = String(action.meta.optimistic.id);
		columnDeleteInteraction(concurrentId).start();
		return null;
	};

export const columnDeleteSuccessPerformance =
	() => (preState: State, state: State, action: ColumnDeleteSuccessAction) => {
		const concurrentId = String(action.meta.optimistic.id);
		columnDeleteInteraction(concurrentId).stop();
		return null;
	};

export const columnLimitRequestPerformance = () => () => {
	columnSetLimitInteraction.start();
	return null;
};

export const columnLimitSuccessPerformance = () => () => {
	columnSetLimitInteraction.stop();
	return null;
};
