import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	filterIconLabel: {
		id: 'software.main.board-container.board.unassigned-filter-cta-experiment.filter-icon-label',
		defaultMessage: 'Filter',
		description: 'Accessibility label for the filter icon in an information box',
	},
	unassignedFilterCtaCloseButtonLabel: {
		id: 'software.main.board-container.board.unassigned-filter-cta-experiment.unassigned-filter-cta-close-button-label',
		defaultMessage: 'Dismiss find available work faster message',
		description: 'Accessibility label for the close icon button in the unassigned filter CTA',
	},
	unassignedFilterCtaTitle: {
		id: 'software.main.board-container.board.unassigned-filter-cta-experiment.unassigned-filter-cta-title',
		defaultMessage: 'Find available work faster',
		description: 'Title for the unassigned filter CTA. Informs the user of the purpose of the CTA',
	},
	unassignedFilterCtaDescription: {
		id: 'software.main.board-container.board.unassigned-filter-cta-experiment.unassigned-filter-cta-description',
		defaultMessage: 'Only show unassigned work on the board.',
		description:
			'Description for the unassigned filter CTA. Informs the user as to what the CTA does',
	},
	unassignedFilterCtaButtonText: {
		id: 'software.main.board-container.board.unassigned-filter-cta-experiment.unassigned-filter-cta-button-text',
		defaultMessage: 'Turn on unassigned filter',
		description:
			'Button text for the unassigned filter CTA. Clicking the button turns on the unassigned issues filter',
	},
});
