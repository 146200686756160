import React, { useEffect } from 'react';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useIssueLinkedModalEntrypointStore } from '@atlassian/jira-issue-links-common/src/controllers/issue-links-modal-entrypoint-store/index.tsx';
import { linkIssuePopupEntryPoint } from '@atlassian/jira-link-issue-popup/entrypoint.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';

type Props = {
	onSuccessfulIssueLink?: (issueId: number) => void;
};

export const LinkIssuePopup = ({ onSuccessfulIssueLink }: Props) => {
	const cloudId = useCloudId();
	const [state, { setIssueLinkModalEntryPointSubject }] = useIssueLinkedModalEntrypointStore();
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		linkIssuePopupEntryPoint,
		{ cloudId },
	);

	const handleClose = () => {
		setIssueLinkModalEntryPointSubject({ issueData: null });
		entryPointActions.unload();
	};

	const handleSuccessfulIssueLink = () => {
		if (onSuccessfulIssueLink && state.issueData) {
			onSuccessfulIssueLink(Number(state.issueData.issueId));
		}
		handleClose();
	};

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		if (state.issueData) {
			entryPointActions.load();
		}
	}, [entryPointActions, state.issueData]);

	if (!state.issueData || !state.issueData.issueId || !state.issueData.issueKey) {
		return null;
	}

	return (
		<JiraEntryPointContainer
			id="businessLinkIssuePopupEntryPoint"
			packageName="@atlassian/jira-link-issue-popup"
			teamName="jira-werewolves" // TODO ownership will be changed after warranty period https://jbusiness.atlassian.net/browse/JSWMOON-936
			entryPointReferenceSubject={entryPointReferenceSubject}
			runtimeProps={{
				issueId: state.issueData.issueId,
				issueKey: state.issueData.issueKey,
				onSuccessfulIssueLink: handleSuccessfulIssueLink,
				onClose: handleClose,
				trackOriginSource: 'JWM',
			}}
		/>
	);
};
