import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import {
	contextPathSelector,
	rapidViewIdSelector,
} from '../../state/selectors/software/software-selectors.tsx';
import type { State } from '../../state/types.tsx';
import { getBacklogIssueMoveUrl } from '../software/software-api-urls.tsx';

export const executeBacklogIssueMove = (state: State) => {
	const rapidViewId = rapidViewIdSelector(state);
	const contextPath = contextPathSelector(state);
	const url = getBacklogIssueMoveUrl(contextPath);
	return fetchJson$(url, {
		method: 'PUT',
		body: JSON.stringify({
			rapidViewId,
		}),
	});
};
