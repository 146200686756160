import 'rxjs/add/observable/of';
import 'rxjs/add/observable/zip';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import { Observable } from 'rxjs/Observable';
import { SPRINT_STATES } from '@atlassian/jira-shared-types/src/rest/jira/sprint.tsx';
import {
	ISSUE_CHANGE_TEAM_REQUEST,
	type IssueChangeTeamRequestAction,
} from '../../state/actions/issue/change-team/index.tsx';
import { issueRankTeamDateRequest } from '../../state/actions/issue/rank-team-date/index.tsx';
import { swimlaneColumnClosedSprintInvalidMove } from '../../state/actions/swimlane/index.tsx';
import { getTeamSprintsForIterations } from '../../state/selectors/team/team-selectors.tsx';
import type { ActionsObservable, MiddlewareAPI, Action } from '../../state/types.tsx';

export function handleIssueChangeTeam(
	store: MiddlewareAPI,
	action: IssueChangeTeamRequestAction,
): Observable<Action> {
	const state = store.getState();
	const { sourceSwimlaneId, destinationColumnId, destinationSwimlaneId } = action.payload;
	const teamSprintsForIterations = getTeamSprintsForIterations(state, destinationSwimlaneId);

	if (teamSprintsForIterations) {
		const sprint = teamSprintsForIterations[destinationColumnId];
		const isClosedSprint = sprint && sprint.state === SPRINT_STATES.CLOSED;

		const movingToClosedSprintColumn = sourceSwimlaneId !== destinationSwimlaneId && isClosedSprint;

		if (movingToClosedSprintColumn) {
			return Observable.of(swimlaneColumnClosedSprintInvalidMove(sprint.name));
		}
	}

	return Observable.of(issueRankTeamDateRequest(action.payload));
}

export function cardChangeTeamEpic(action$: ActionsObservable, store: MiddlewareAPI) {
	return action$
		.ofType(ISSUE_CHANGE_TEAM_REQUEST)
		.mergeMap((action: IssueChangeTeamRequestAction) => handleIssueChangeTeam(store, action));
}
