import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	workSuggestionsViewSettingsPanelSpotlight: {
		id: 'insights-next-best-task.view-settings-spotlight.work-suggestions-view-settings-panel-spotlight',
		defaultMessage:
			'If we have suggestions for what you can work on next, you’ll see them on the board when you select your avatar.',
		description:
			'This is the spotlight onboarding message for the view settings panel in the work suggestions view. It gives the guidance for the users on work suggestions.',
	},
	workSuggestionsViewSettingsSettingsSpotlight: {
		id: 'insights-next-best-task.view-settings-spotlight.work-suggestions-view-settings-settings-spotlight',
		defaultMessage:
			'To switch <b>Work suggestions</b> on or off, use the toggle in <b>View settings</b>.',
		description:
			'This is the spotlight onboarding message for the view settings toggle for Work Suggestions. It explains this button is used to control the toggle on or off for Work Suggestions.',
	},
	next: {
		id: 'insights-next-best-task.view-settings-spotlight.next',
		defaultMessage: 'Next',
		description:
			'This is the spotlight onboarding next action message. It will shown on the action button for the users to go to the next step in the onboarding process.',
	},
	ok: {
		id: 'insights-next-best-task.view-settings-spotlight.ok',
		defaultMessage: 'OK',
		description:
			'This is the spotlight onboarding end action message. It will shown on the action button for the users to go to the end of the onboarding process.',
	},
	dismiss: {
		id: 'insights-next-best-task.view-settings-spotlight.dismiss',
		defaultMessage: 'Dismiss',
		description:
			'This is the spotlight onboarding dismiss action message. It will shown on the action button for the users to dismiss the onboarding process.',
	},
});
