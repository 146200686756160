import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	viewSettingsButtonStepTitle: {
		id: 'software-view-settings.view-settings-panel-onboarding.view-settings-button-step-title',
		defaultMessage: 'Customize your view',
		description: 'View settings panel onboarding first step title',
	},
	viewSettingsButtonStepContent: {
		id: 'software-view-settings.view-settings-panel-onboarding.view-settings-button-step-content',
		defaultMessage: 'Use view settings to simplify the look and feel of your board and backlog.',
		description: 'View settings panel onboarding first step title',
	},
	viewSettingsPanelFirstPartContent: {
		id: 'software-view-settings.view-settings-panel-onboarding.view-settings-panel-first-part-content',
		defaultMessage: 'Switch on what you need, and switch off what you don’t.',
		description: 'View settings panel onboarding step first part of the content',
	},
	viewSettingsPanelSecondPartContent: {
		id: 'software-view-settings.view-settings-panel-onboarding.view-settings-panel-second-part-content',
		defaultMessage:
			'Choose to show or hide panels, filters, and issue fields, and change the density of your view.',
		description: 'View settings panel onboarding step second part of the content',
	},
	addPeopleButtonStepTitle: {
		id: 'software-view-settings.view-settings-panel-onboarding.add-people-button-step-title',
		defaultMessage: 'Share your view',
		description: 'Add people button onboarding step title',
	},
	addPeopleButtonStepContent: {
		id: 'software-view-settings.view-settings-panel-onboarding.add-people-button-step-content',
		defaultMessage:
			'Now when you add a new teammate to Jira, you can share your view settings so they see what you see. Includes view settings and filters.',
		description: 'Add people button onboarding step content',
	},
	nextStepButton: {
		id: 'software-view-settings.view-settings-panel-onboarding.next-step-button',
		defaultMessage: 'Next',
		description: 'Onboarding next step button text',
	},
	dismissStepButton: {
		id: 'software-view-settings.view-settings-panel-onboarding.dismiss-step-button',
		defaultMessage: 'Dismiss',
		description: 'Onboarding dismiss button text',
	},
	doneStepButton: {
		id: 'software-view-settings.view-settings-panel-onboarding.done-step-button',
		defaultMessage: 'Done',
		description: 'Onboarding done button text',
	},
	viewSettingsPanelSecondPartContentIssueTermRefresh: {
		id: 'software-view-settings.view-settings-panel-onboarding.view-settings-panel-second-part-content-issue-term-refresh',
		defaultMessage:
			'Choose to show or hide panels, filters, and fields, and change the density of your view.',
		description: 'View settings panel onboarding step second part of the content',
	},
});
