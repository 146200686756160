import { closeICC } from './close-icc/index.tsx';
import { setHasCreatedIssueICC } from './set-has-created-issue-icc/index.tsx';
import { setTriggerReason } from './set-trigger-reason/index.tsx';
import { toggleKeepIccOpen } from './toggle-keep-icc-open/index.tsx';
import { setKeepIccOpenOnListView } from './set-keep-icc-open-on-list-view/index.tsx';

export const actions = {
	toggleKeepIccOpen,
	setKeepIccOpenOnListView,
	setHasCreatedIssueICC,
	setTriggerReason,
	closeICC,
} as const;
