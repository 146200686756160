// Note to maintainer: Add GraphQL enum on the server instead of using a scalar
// then replace all of this any you'll be back to what you intend
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const EDIT_BOARD_CONFIG = 'EDIT_BOARD_CONFIG' as unknown as AGG$SoftwareBoardPermission;
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const DELETE_ISSUE = 'DELETE_ISSUE' as unknown as AGG$SoftwareBoardPermission;
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const CREATE_ISSUE = 'CREATE_ISSUE' as unknown as AGG$SoftwareBoardPermission;
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const MANAGE_SPRINT = 'MANAGE_SPRINT' as unknown as AGG$SoftwareBoardPermission;
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const EDIT_ISSUE = 'EDIT_ISSUE' as unknown as AGG$SoftwareBoardPermission;
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const MANAGE_AUTOMATION = 'MANAGE_AUTOMATION' as unknown as AGG$SoftwareBoardPermission;
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const LINK_ISSUE = 'LINK_ISSUE' as unknown as AGG$SoftwareBoardPermission;
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ARCHIVE_ISSUE = 'ARCHIVE_ISSUE' as unknown as AGG$SoftwareBoardPermission;
