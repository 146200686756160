import React from 'react';
import '@atlaskit/css-reset';
import { ErrorBoundaryFlag as ErrorBoundary } from '@atlassian/jira-error-boundary-flag-renderer/src/ErrorBoundaryFlag.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { NextGenBoardSkeleton } from '@atlassian/jira-skeletons/src/ui/next-gen-board/NextGenBoardSkeleton.tsx';
import {
	SuspenseTrackerProvider,
	SuspenseTrackerFallback,
} from '@atlassian/jira-software-suspense-tracker/src/index.tsx';
import { MarkProductStart } from '@atlassian/jira-spa-performance-breakdown/src/utils/mark-product-start/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { withRouter } from '@atlassian/react-resource-router';
import { Board } from './view/board/index.tsx';

const RouterWrapper = withRouter(Board);

const BoardSPAApp = () => {
	const children = (
		<Placeholder
			name="next-gen-board"
			fallback={
				<>
					<NextGenBoardSkeleton />
					<SuspenseTrackerFallback />
				</>
			}
		>
			<MarkProductStart />
			<ErrorBoundary id="spa-app-bootstrap.next-gen.board">
				{/* @ts-expect-error - TS2739 - Type '{}' is missing the following properties from type 'RouteContext': location, query, route, match, action */}
				<RouterWrapper />
			</ErrorBoundary>
		</Placeholder>
	);

	if (fg('critical_board_query_with_relay')) {
		return (
			<UFOSegment name="next-gen-board">
				<SuspenseTrackerProvider>{children}</SuspenseTrackerProvider>
			</UFOSegment>
		);
	}
	return <UFOSegment name="next-gen-board">{children}</UFOSegment>;
};

export default BoardSPAApp;
