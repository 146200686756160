import React from 'react';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { IssueLinksStats as IssueLinksStatsComponent } from '@atlassian/jira-issue-links-stats/src/index.tsx';
import { useShowIssueLinksStats } from '@atlassian/jira-software-view-settings/src/controllers/index.tsx';
import { useBoardSelector } from '../../../../../../state/index.tsx';
import { getIssueLinksPermissions } from '../../../../../../state/selectors/issue-links-permissions/issue-links-permissions-selectors.tsx';
import {
	getIsLoadingIssueLinksStatsForCard,
	getIssueLinksStatsForCard,
} from '../../../../../../state/selectors/issue-links-stats/issue-links-stats-selectors.tsx';
import { getIssueTypeById } from '../../../../../../state/selectors/issue/issue-selectors.tsx';
import type { LinkedIssuesStatsProps } from './types.tsx';

export const IssueLinksStats = ({
	id,
	typeId,
	issueKey,
	summary,
	onIssueUnlink,
}: LinkedIssuesStatsProps) => {
	const shouldShowIssueLinksStats = useShowIssueLinksStats();

	const issueLinksCount = useBoardSelector((state) => getIssueLinksStatsForCard(state)(`${id}`));

	const isLoadingIssueLinkStats = useBoardSelector((state) =>
		getIsLoadingIssueLinksStatsForCard(state)(`${id}`),
	);
	const { canUserLinkIssue } = useBoardSelector((boardState) =>
		getIssueLinksPermissions(boardState),
	);

	const issueType = useBoardSelector((state) => getIssueTypeById(state, Number(typeId)));

	if (!id || !shouldShowIssueLinksStats || (issueLinksCount <= 0 && !isLoadingIssueLinkStats)) {
		return <></>;
	}

	return (
		<IssueLinksStatsComponent
			issueLinksCount={issueLinksCount}
			issueKey={issueKey}
			issueId={`${id}`}
			issueSummary={summary}
			issueType={issueType}
			isLoading={isLoadingIssueLinkStats}
			feedbackCollectorPrefix="board"
			onIssueUnlink={onIssueUnlink}
			canUserLinkIssue={canUserLinkIssue}
			withDueDate={false}
			sourceProductType={SOFTWARE_PROJECT}
		/>
	);
};
