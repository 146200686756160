export const PREVENT_INLINE_EDITING = 'state.actions.board.PREVENT_INLINE_EDITING' as const;

export type PreventInlineEditingAction = {
	type: typeof PREVENT_INLINE_EDITING;
	payload: { preventInlineEditing: boolean };
};

export const preventInlineEditingAction = (
	preventInlineEditing: boolean,
): PreventInlineEditingAction => ({
	type: PREVENT_INLINE_EDITING,
	payload: { preventInlineEditing },
});

export type Action = PreventInlineEditingAction;
