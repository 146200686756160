import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noGoals: {
		id: 'software-filters.filters.goals.no-goals',
		defaultMessage: 'There are no matching goals',
		description:
			'This message is shown in the goal filter when there are no goals issue to select from given the user search input',
	},
	placeholder: {
		id: 'software-filters.filters.goals.placeholder',
		defaultMessage: 'Choose goals',
		description: 'Placeholder text. Appears on the search box of goals filter.',
	},
	label: {
		id: 'software-filters.filters.goals.label',
		defaultMessage: 'Goals',
		description: 'This is the label of a dropdown menu to filter Jira issues based on goals.',
	},
});
