export const OPEN_TEAM_SPRINT_ASSOCIATION_MODEL =
	'state.actions.sprint.sprint-association-modal.OPEN_TEAM_SPRINT_ASSOCIATION_MODEL';

export type TeamSprintAssociationModalOpenAction = {
	type: typeof OPEN_TEAM_SPRINT_ASSOCIATION_MODEL;
	payload: {
		teamId: string;
	};
};

export const openTeamAssociationModal = (teamId: string): TeamSprintAssociationModalOpenAction => ({
	type: OPEN_TEAM_SPRINT_ASSOCIATION_MODEL,
	payload: {
		teamId,
	},
});

export const CLOSE_TEAM_SPRINT_ASSOCIATION_MODEL =
	'state.actions.sprint.sprint-association-modal.CLOSE_TEAM_SPRINT_ASSOCIATION_MODEL';

export type TeamSprintAssociationModalCloseAction = {
	type: typeof CLOSE_TEAM_SPRINT_ASSOCIATION_MODEL;
};

export const closeTeamAssociationModal = (): TeamSprintAssociationModalCloseAction => ({
	type: CLOSE_TEAM_SPRINT_ASSOCIATION_MODEL,
});

export type Action = TeamSprintAssociationModalOpenAction | TeamSprintAssociationModalCloseAction;
