import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import isNil from 'lodash/isNil';
import { Observable } from 'rxjs/Observable';
import {
	type CardDeleteOptimisticAction,
	CARD_DELETE_OPTIMISTIC,
	cardDelete,
} from '../../state/actions/card/index.tsx';
import { getIssueChildren } from '../../state/selectors/issue-children/index.tsx';
import { getIssues } from '../../state/selectors/software/software-selectors.tsx';
import type { ActionsObservable, MiddlewareAPI } from '../../state/types.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable, store: MiddlewareAPI) =>
	action$.ofType(CARD_DELETE_OPTIMISTIC).mergeMap((action: CardDeleteOptimisticAction) => {
		const state = store.getState();
		const issues = getIssues(state);
		const issueChildren = getIssueChildren(state);

		const { issueId } = action.payload;
		const { parentId, isDone } = issues[String(issueId)] || issueChildren[String(issueId)] || {};

		return Observable.of(
			cardDelete(
				issueId,
				!isNil(parentId) && parentId !== 0 ? Number(parentId) : null,
				isDone || false,
			),
		);
	});
