import { freeze } from 'icepick';
import { UPDATE_DETAIL_VIEW } from '../../../actions/detail-view/index.tsx';
import {
	OPEN_ISSUE_MODAL,
	CLOSE_ISSUE_MODAL,
	RESET_EXTERNAL_ACTION,
} from '../../../actions/issue/modal/index.tsx';
import type { Action } from '../../../types.tsx';
import type { IssueModalState } from './types.tsx';

const initialState: IssueModalState = freeze({
	selectedIssueKey: null,
	timestamp: 0,
	externalAction: undefined,
});

export const issueModalReducer = (state: IssueModalState = initialState, action: Action) => {
	if (action.type === OPEN_ISSUE_MODAL) {
		if (state.selectedIssueKey !== action.payload.issueKey) {
			return {
				...state,
				selectedIssueKey: action.payload.issueKey,
				externalAction: action.payload.externalAction,
			};
		}
		return state;
	}

	if (action.type === UPDATE_DETAIL_VIEW) {
		return {
			...state,
			timestamp: Date.now(),
		};
	}

	if (action.type === CLOSE_ISSUE_MODAL) {
		return initialState;
	}

	if (action.type === RESET_EXTERNAL_ACTION) {
		return {
			...state,
			externalAction: undefined,
		};
	}

	return state;
};
