import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { CardCreateUnmappedFlag } from '@atlassian/jira-software-flags/src/ui/card-create-unmapped/index.tsx';
import { getColumnStatusMappingUrl } from '../../common/urls/index.tsx';
import { CAN_EDIT_BOARD } from '../../model/permission/permission-types.tsx';
import { getPermissionsSelector } from '../../state/selectors/board/board-permissions-selectors.tsx';
import {
	getIsCMPBoard,
	projectKeySelector,
	rapidViewIdSelector,
} from '../../state/selectors/software/software-selectors.tsx';
import type { State } from '../../state/types.tsx';

type OwnProps = {
	cardKeys: string[];
	id: string | number;
	onDismissed: undefined | ((arg1: string | number) => void);
};

type StateProps = {
	isConfigureBoardEnabled: boolean;
	boardConfigUrl: string;
};

export type Props = OwnProps & StateProps;

const mapStateToProps = (state: State): StateProps => {
	const permissions = getPermissionsSelector(state);
	return {
		boardConfigUrl: getColumnStatusMappingUrl(
			projectKeySelector(state),
			rapidViewIdSelector(state),
		),
		isConfigureBoardEnabled: permissions[CAN_EDIT_BOARD] === true && !getIsCMPBoard(state),
	};
};

export default connect(mapStateToProps, {})(CardCreateUnmappedFlag);
