import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'up-flow-iframe.common.commerce-api-error-flag.title',
		defaultMessage: "We can't upgrade your plan right now",
		description: 'Flag title for error flag when we can not retrieve commerce server information',
	},
	description: {
		id: 'up-flow-iframe.common.commerce-api-error-flag.description',
		defaultMessage: 'Try again, or if this keeps happening try contacting support',
		description:
			'Detailed instructions for error flag when we can not retrieve commerce server information',
	},
});
