import { freeze } from 'icepick';
import { createSelector } from 'reselect';
import get from 'lodash/get';
import last from 'lodash/last';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import { ASSIGNEE, LABEL, SPRINT } from '../../../model/filter/filter-types.tsx';
import type { IssueKey } from '../../../model/issue/issue-types.tsx';
import type { State } from '../../reducers/types.tsx';
import { getPeople } from '../people/people-selectors.tsx';
import { getBoardConfig } from '../software/software-selectors.tsx';
import { getWorkFilters } from '../work/work-selectors.tsx';
import { boardIssuesSelector } from './board-issue-selectors.tsx';
import { getRankConfig } from './issue-selectors.tsx';

export type IssueListPayload = {
	issueList: {
		addTo: string[];
	};
};

export const getIssueListPayload = createSelector([getBoardConfig], (config) =>
	config.boardIssueListKey
		? {
				issueList: {
					addTo: [config.boardIssueListKey],
				},
			}
		: null,
);

export type RankPayload = {
	[key: string]: {
		rankBeforeIssue: IssueKey;
	};
};

const getRankPayloadWithInsertBefore = createSelector(
	[getRankConfig, boardIssuesSelector],
	(rankConfig, allIssues) => (insertBefore: IssueId | null | undefined) =>
		rankConfig.boardIsRankable
			? {
					[`customfield_${rankConfig.rankCustomFieldId}`]: {
						rankBeforeIssue: allIssues[String(insertBefore)].key,
					},
				}
			: null,
);

export const getRankPayload: (
	state: State,
	insertBefore: IssueId | null | undefined,
) => RankPayload | null = (state: State, insertBefore?: IssueId | null) =>
	insertBefore ? getRankPayloadWithInsertBefore(state)(insertBefore) : null;

export type AssigneeFilterPayload = {
	assignee:
		| {
				name: string;
		  }
		| {
				id: string;
		  };
};

const emptyArray = freeze([]);

const getAppliedAssigneeFilters = createSelector([getWorkFilters], (filters) =>
	get(filters, ['values', ASSIGNEE], emptyArray),
);

export const getAssigneeFilterPayload = createSelector(
	[getAppliedAssigneeFilters, getPeople],
	(assignees, people) => {
		if (assignees.length > 0) {
			const { id } = people[assignees[assignees.length - 1]] || {};
			return id == null ? null : { assignee: { id } };
		}

		return null;
	},
);

export const getAssigneeId = (state: State) =>
	getAssigneeFilterPayload(state)?.assignee?.id || null;

export const getAppliedLabelFilters = createSelector([getWorkFilters], (filters) =>
	get(filters, ['values', LABEL], emptyArray),
);

export const getLastFilteredSprintId = createSelector([getWorkFilters], (filters) =>
	last(get(filters, ['values', SPRINT], emptyArray)),
);
