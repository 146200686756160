import type { StoreApi, QueryGithubOnboardingRef } from './types.tsx';

export const actions = {
	setIsAutoOpened:
		(isAutoOpened: boolean) =>
		({ setState }: StoreApi) => {
			setState({ isAutoOpened });
		},
	setIsNbtToggleButtonVisible:
		(isNbtToggleButtonVisible: boolean) =>
		({ setState }: StoreApi) => {
			setState({ isNbtToggleButtonVisible });
		},
	setIsOnlyCurrentUserSelected:
		(isOnlyCurrentUserSelected: boolean) =>
		({ setState }: StoreApi) => {
			if (!isOnlyCurrentUserSelected) {
				setState({ isOnlyCurrentUserSelected, isAutoOpened: false, isHiddenByUser: false });
			} else {
				setState({ isOnlyCurrentUserSelected });
			}
		},
	setIsOnlyNBTEnabledAvatarSelected:
		(isOnlyNBTEnabledAvatarSelected: boolean) =>
		({ setState }: StoreApi) => {
			if (!isOnlyNBTEnabledAvatarSelected) {
				setState({ isOnlyNBTEnabledAvatarSelected, isAutoOpened: false, isHiddenByUser: false });
			} else {
				setState({ isOnlyNBTEnabledAvatarSelected });
			}
		},
	setQueryGithubOnboardingRef:
		(queryGithubOnboardingRef: QueryGithubOnboardingRef) =>
		({ getState, setState }: StoreApi) => {
			if (getState().queryGithubOnboardingRef == null) {
				setState({ queryGithubOnboardingRef });
			}
		},

	setIsHiddenByUser:
		(isHiddenByUser: boolean) =>
		({ setState }: StoreApi) => {
			setState({
				isHiddenByUser,
			});
		},
} as const;

export type Actions = typeof actions;
