import { useCallback } from 'react';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { MigrationId } from '@atlassian/jira-project-create-importer-common/src/common/types/index.tsx';
import {
	useErrorLogFileName,
	createDownloadLogsUrl,
} from '@atlassian/jira-project-create-importer-common/src/common/utils.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';

export const downloadFile = (fileUrl: string, fileName: string): void => {
	// Need to create a link to trigger the download instead of redirecting

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const link = document.createElement('a');
	link.href = fileUrl;
	link.download = fileName;
	link.click();
};

export const useDownloadLogs = (migrationId: MigrationId | undefined, projectName: string) => {
	const cloudId = useCloudId();
	const fileName = useErrorLogFileName(projectName);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	return useCallback(() => {
		if (!migrationId) {
			return;
		}

		const url = createDownloadLogsUrl({ cloudId, migrationId, fileName });

		const uiEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'downloadX2CLogsLink',
		});
		fireUIAnalytics(uiEvent);

		downloadFile(url, fileName);
	}, [migrationId, cloudId, createAnalyticsEvent, fileName]);
};
