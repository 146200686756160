import memoizeOne from 'memoize-one';

type Point = {
	x: number;
	y: number;
};

const calculateMidPoint = (from: Point, to: Point): Point => ({
	x: (from.x + to.x) / 2,
	y: (from.y + to.y) / 2,
});

const getY = (p1: Point, p2: Point, x: number): number => {
	const m = (p2.y - p1.y) / (p2.x - p1.x);
	return m * (x - p1.x) + p1.y;
};

// Generates half of the complex segment
const generateSegment = (start: Point, end: Point, offset: Point): string => {
	const c1 = {
		x: start.x + offset.x,
		y: start.y,
	};

	const c2 = {
		x: start.x + offset.x,
		y: start.y + offset.y,
	};

	const s1 = {
		x: start.x,
		y: getY(c2, end, start.x),
	};

	return `M${start.x} ${start.y} C${c1.x} ${c1.y} ${c2.x} ${c2.y} ${s1.x} ${s1.y} L${s1.x} ${s1.y} ${end.x} ${end.y}`;
};

const getSegmentOffsets = (from: Point, to: Point, halfItemHeight: number): [Point, Point] => {
	const fromOffset = {
		x: halfItemHeight,
		y: from.y <= to.y ? halfItemHeight : -halfItemHeight,
	};

	const toOffset = {
		x: -fromOffset.x,
		y: -fromOffset.y,
	};

	return [fromOffset, toOffset];
};

// Generates a path made up of two segments for when the dependency start is after the end
const generateComplexPath = (from: Point, to: Point, halfItemHeight: number) => {
	const middle = calculateMidPoint(from, to);

	const [fromOffset, toOffset] = getSegmentOffsets(from, to, halfItemHeight);

	return `${generateSegment(from, middle, fromOffset)} ${generateSegment(to, middle, toOffset)}`;
};

//  Generates a single path for when the dependency start is before the end (happy case)
const generateSinglePath = (from: Point, to: Point, itemHeight: number) => {
	const diffX = Math.abs(from.x - to.x);

	const progression = itemHeight + diffX * 0.1;

	return `M${from.x} ${from.y} C${from.x + progression} ${from.y} ${to.x - progression} ${to.y} ${
		to.x
	} ${to.y}`;
};

export const getPath: (from: Point, to: Point, itemHeight: number) => string = memoizeOne(
	(from: Point, to: Point, itemHeight: number) =>
		from.x > to.x
			? generateComplexPath(from, to, itemHeight / 2)
			: generateSinglePath(from, to, itemHeight),
);
