import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';
import type { Attributes } from '@atlassian/jira-product-analytics-web-client-types';
import { AnalyticsEvent } from '../../../types/analytics.tsx';

export const VIEW_SETTINGS_ACTION_SUBJECT_KEY = 'viewSettings' as const;

export const useSendAnalyticsEvent = () => {
	const sendAnalyticsEvent = (
		action: string,
		actionSubject: string,
		eventType: (typeof AnalyticsEvent)[keyof typeof AnalyticsEvent],
		attributes: Attributes = {},
		source: string,
	) => {
		getAnalyticsWebClientPromise().then((client) => {
			const analyticsClient = client.getInstance();
			if (!analyticsClient) {
				return Promise.resolve();
			}

			const event = {
				action,
				actionSubject,
				actionSubjectId: VIEW_SETTINGS_ACTION_SUBJECT_KEY,
				attributes: { ...attributes },
				source,
			};

			switch (eventType) {
				case AnalyticsEvent.UI_EVENT:
					return analyticsClient.sendUIEvent(event);
				case AnalyticsEvent.OPERATIONAL_EVENT:
					return analyticsClient.sendOperationalEvent(event);
				case AnalyticsEvent.TRACK_EVENT:
					return analyticsClient.sendTrackEvent(event);
				default:
			}
			return undefined;
		});
	};

	return sendAnalyticsEvent;
};
