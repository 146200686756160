export const MOVE_TO_MENU_ID = 'actions/move-to' as const;
export const MOVE_TO_TOP_OF_COLUMN_MENU_ID = 'actions/move-to/top-of-column' as const;
export const MOVE_TO_BOTTOM_OF_COLUMN_MENU_ID = 'actions/move-to/bottom-of-column' as const;
export const MOVE_TO_BACKLOG_MENU_ID = 'actions/move-to/backlog' as const;

export const FLAG_MENU_ID = 'actions/flag' as const;
export const FLAG_ADD_FLAG_MENU_ID = 'actions/flag/add-flag' as const;
export const FLAG_REMOVE_FLAG_MENU_ID = 'actions/flag/remove-flag' as const;
export const ADD_LABEL_ID = 'actions/add-label' as const;

export const COPY_ISSUE_LINK_MENU_ID = 'actions/copy-issue-link' as const;
export const COPY_ISSUE_KEY_MENU_ID = 'actions/copy-issue-key' as const;
export const VIEW_IN_ISSUE_SEARCH_MENU_ID = 'actions/view-in-issue-search' as const;

export const HEADER_BULK_INDICATOR_MENU_ID = 'bulk-indicator' as const;

export const DELETE_ISSUE_MENU_ID = 'actions/delete-issue' as const;
export const ARCHIVE_ISSUE_MENU_ID = 'actions/archive-issue' as const;
export const CLONE_ISSUE_MENU_ID = 'actions/clone-issue' as const;
export const REMOVE_FROM_SPRINT_MENU_ID = 'actions/remove-from-sprint' as const;

export const LINK_ISSUE_MENU_ID = 'actions/link-issue' as const;

export const SEPARATOR_MENU_ID = 'separator' as const;

export const CHANGE_PARENT_MENU_ID = 'actions/change-parent' as const;

export const BULK_CHANGE_MENU_ID = 'actions/bulk-change' as const;

export const CHANGE_TEAM_MENU_ID = 'actions/change-team' as const;
export const CHANGE_TEAM_SEARCH_MENU_ID = 'actions/change-team/search' as const;
export const CHANGE_TEAM_UNASSIGN_MENU_ID = 'actions/change-team/unassign' as const;

export const CHANGE_DATE_RANGE_MENU_ID = 'actions/change-date-range' as const;
export const UNSCHEDULE_DATE_RANGE_MENU_ID = 'actions/change-date-range/unschedule' as const;
export const SCHEDULE_DATE_RANGE_MENU_ID = 'actions/change-date-range/schedule-column' as const;

export const EDIT_COVER_MENU_ID = 'actions/edit-cover' as const;
