import { fg } from '@atlassian/jira-feature-gating';
import { nextBestTaskFeatureResource } from '@atlassian/jira-router-resources-next-best-task/src/services/index.tsx';
import { useResource } from '@atlassian/react-resource-router';
import { useNextBestTaskContainer } from '../next-best-task-container/index.tsx';

export const useNextBestTaskPanel = () => {
	const [
		{
			isAutoOpened,
			isHiddenByUser,
			isNbtToggleButtonVisible,
			isOnlyCurrentUserSelected,
			isOnlyNBTEnabledAvatarSelected,
		},
		{ setIsAutoOpened, setIsNbtToggleButtonVisible },
	] = useNextBestTaskContainer();

	const { data: isWorkSuggestionsEnabledData } = useResource(nextBestTaskFeatureResource);

	const hasUserOptedIn = !!isWorkSuggestionsEnabledData?.hasUserOptedIn;

	const NBTAvatarSelected = fg('jira-work-suggestions-unassigned-avatar-selected')
		? isOnlyNBTEnabledAvatarSelected
		: isOnlyCurrentUserSelected;

	const isReadyToFetchNBT = NBTAvatarSelected && hasUserOptedIn && !isHiddenByUser;
	const isOpen = isReadyToFetchNBT && isAutoOpened;

	return {
		isReadyToFetchNBT,
		isOpen,
		isAutoOpened,
		isNbtToggleButtonVisible,
		setIsAutoOpened,
		isHiddenByUser,
		setIsNbtToggleButtonVisible,
	};
};
