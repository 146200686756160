import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import fetchText$ from '@atlassian/jira-fetch/src/utils/as-text-stream.tsx';
import { REFRESH_SOURCE_FAILURE } from '../../model/constants.tsx';
import { getCardMediaUrl } from '../../rest/software/software-api-urls.tsx';
import {
	cardMediaToggleSuccess,
	cardMediaToggleFailure,
	type CardMediaToggleRequestAction,
	CARD_MEDIA_TOGGLE_REQUEST,
} from '../../state/actions/board/menu/index.tsx';
import { workRefreshData } from '../../state/actions/work/index.tsx';
import {
	contextPathSelector,
	rapidViewIdSelector,
} from '../../state/selectors/software/software-selectors.tsx';
import type { MiddlewareAPI, ActionsObservable, Action } from '../../state/types.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable, store: MiddlewareAPI) =>
	action$.ofType(CARD_MEDIA_TOGGLE_REQUEST).mergeMap((action: CardMediaToggleRequestAction) => {
		const state = store.getState();
		const {
			meta: {
				optimistic: { id: txId },
			},
			payload,
		} = action;

		const contextPath = contextPathSelector(state);
		const rapidViewId = rapidViewIdSelector(state);

		return fetchText$(getCardMediaUrl(contextPath, rapidViewId, payload), {
			method: 'PUT',
		})
			.flatMap(() => Observable.of(cardMediaToggleSuccess(txId)))
			.catch(() =>
				Observable.of(
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					cardMediaToggleFailure(txId) as Action,
					workRefreshData(REFRESH_SOURCE_FAILURE),
				),
			);
	});
