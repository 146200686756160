import React from 'react';
import { Footer as FooterAfe } from '@atlassian/automation-manual-triggers';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';

interface Props {
	baseAutomationUrl: string;
	hasSeparator?: boolean;
}

export const Footer = ({ baseAutomationUrl }: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	return (
		<FooterAfe
			baseAutomationUrl={baseAutomationUrl}
			onCreateAutomationFooterLinkClicked={() => {
				const analyticsEvent = createAnalyticsEvent({
					actionSubject: 'link',
					action: 'clicked',
				});
				fireUIAnalytics(analyticsEvent, 'manualRulesActionsMenu.adminFooter.createAutomation', {
					userType: 'admin',
				});
			}}
			onManageAutomationsFooterLinkClicked={() => {
				const analyticsEvent = createAnalyticsEvent({
					actionSubject: 'link',
					action: 'clicked',
				});
				fireUIAnalytics(analyticsEvent, 'manualRulesActionsMenu.adminFooter.manageAutomations', {
					userType: 'admin',
				});
			}}
			onAuditLogFooterLinkClicked={() => {
				const analyticsEvent = createAnalyticsEvent({
					actionSubject: 'link',
					action: 'clicked',
				});
				fireUIAnalytics(analyticsEvent, 'manualRulesActionsMenu.adminFooter.auditLog', {
					userType: 'admin',
				});
			}}
		/>
	);
};

export default Footer;
