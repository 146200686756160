import { useCallback } from 'react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { getLongTasksMetrics } from '@atlassian/jira-common-long-task-metrics/src/index.tsx';
import { dndReporter } from '@atlassian/jira-common-long-task-metrics/src/reporters/software-dnd.tsx';
import clearMarksWithPrefix from '@atlassian/jira-common-performance/src/clear-marks-with-prefix.tsx';
import { setMark } from '@atlassian/jira-common-performance/src/marks.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	DRAG_DROP_UNKNOWN_FIELD_ID,
	FIELD_TYPE_MAP,
} from '@atlassian/jira-issue-analytics/src/services/update-issue-field/constants.tsx';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import { CARD_DND_TYPE } from '@atlassian/jira-platform-board-kit/src/common/constants/drag-drop/index.tsx';
import type {
	DraggableData,
	DraggableCardData,
	CardId,
	ColumnId,
	DraggableColumnData,
	SwimlaneId,
} from '@atlassian/jira-platform-board-kit/src/common/types.tsx';
import {
	getColumnDescriptor,
	getCardDescriptor,
} from '@atlassian/jira-platform-board-kit/src/common/utils/descriptor/index.tsx';
import { useBoardDragDropContext as usePlatformBoardDragDropContext } from '@atlassian/jira-platform-board-kit/src/ui/board-drag-drop-context/use-board-drag-drop-context/index.tsx';
import {
	ASSIGNEE_TYPE,
	PARENT_TYPE,
	STATUS_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { IP_BOARD_TYPE } from '../../../../../common/constants.tsx';
import { BOARD_LONG_TASKS_DND_MARK_NAME } from '../../../../../model/constants.tsx';
import { ASSIGNEE, PARENT, STORY } from '../../../../../model/swimlane/swimlane-types.tsx';
import { dragEnd, dragStart } from '../../../../../state/actions/board/drag/index.tsx';
import { cardsMove } from '../../../../../state/actions/card/card-move/index.tsx';
import {
	cardDragSelectedTransition,
	cardGetIssueTransitions,
} from '../../../../../state/actions/card/index.tsx';
import { columnRankRequest } from '../../../../../state/actions/column/rank/index.tsx';
import { useBoardDispatch, useBoardSelector, useBoardStore } from '../../../../../state/index.tsx';
import {
	cardTransitionDestinationStatusSelector,
	cardTransitionSourceStatusSelector,
} from '../../../../../state/selectors/card-transitions/card-transitions-selectors.tsx';
import {
	getCardSelection,
	getCardDragState,
	getSelectedTransition,
	hasNonGlobalTransitions,
	isHoverIntended,
	makeGetHasConditions,
} from '../../../../../state/selectors/card/card-selectors.tsx';
import { hasMultipleStatusesPerColumn } from '../../../../../state/selectors/column/column-selectors.tsx';
import {
	getOrderedColumnIds,
	sessionIdSelector,
} from '../../../../../state/selectors/software/software-selectors.tsx';
import { makeSwimlaneValuesSelector } from '../../../../../state/selectors/swimlane/swimlane-selectors.tsx';
import { getIssuesIdsForColumn } from '../../../../../state/selectors/work/work-selectors.tsx';
import { useIsIncrementPlanningBoard } from '../../../../../state/state-hooks/capabilities/index.tsx';

type BoardDragDropContextProps = {
	onDragEnd?: () => void;
};

export const useBoardDragDropContext = ({
	onDragEnd: onDragEndProp,
}: BoardDragDropContextProps) => {
	const dispatch = useBoardDispatch();
	const orderedColumnIds = useBoardSelector(getOrderedColumnIds);
	const getSomeTransitionsHaveConditions = useBoardSelector(makeGetHasConditions);
	const boardSessionId = useBoardSelector(sessionIdSelector);
	const boardStore = useBoardStore();
	const isIPBoard = useIsIncrementPlanningBoard();
	const overTransitionId = useBoardSelector(
		(state) => getCardDragState(state).overTransition?.transitionId,
	);
	const selectedTransitionId = useBoardSelector(
		(state) => getSelectedTransition(state)?.transitionId,
	);
	const cardSelection = useBoardSelector(getCardSelection);
	const getSwimlaneValues = useBoardSelector(makeSwimlaneValuesSelector);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const hasCustomTransitions = useBoardSelector(hasNonGlobalTransitions);
	const hasMultipleStatuses = useBoardSelector(hasMultipleStatusesPerColumn);
	const isHoverIntendedDuringDrag = useBoardSelector((state) =>
		hasCustomTransitions || hasMultipleStatuses ? isHoverIntended(state) : false,
	);
	const isTransitionSelected = useBoardSelector((state) => {
		const transitionId = getSelectedTransition(state)?.transitionId;
		return hasCustomTransitions || hasMultipleStatuses ? !!transitionId : false;
	});

	const getDestinationStatus = useBoardSelector(cardTransitionDestinationStatusSelector);
	const getSourceStatus = useBoardSelector(cardTransitionSourceStatusSelector);

	const fireAnalyticsStart = fg('one_event_rules_them_all_fg')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useCallback(
				(cardId: CardId) => {
					// Only fire when a single card is dragged. If selected cards is different to the one being dragged, selected cards won't be included
					const shouldFireStartAnalytics =
						cardSelection.length <= 1 || !cardSelection.includes(cardId);

					if (shouldFireStartAnalytics) {
						getUpdateAnalyticsFlowHelper().fireAnalyticsStart(DRAG_DROP_UNKNOWN_FIELD_ID, {
							analytics: createAnalyticsEvent({}),
							attributes: {
								fieldType: DRAG_DROP_UNKNOWN_FIELD_ID,
								isDragEditing: true,
							},
						});
					}
				},
				[cardSelection, createAnalyticsEvent],
			)
		: undefined;

	const fireAnalyticsEnd = fg('one_event_rules_them_all_fg')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useCallback(
				(
					cardIds: CardId[],
					sourceSwimlaneId?: SwimlaneId | null,
					destinationSwimlaneId?: SwimlaneId | null,
					sourceColumnId?: ColumnId,
					destinationColumnId?: ColumnId,
				) => {
					const shouldFireEndAnalytics =
						cardSelection.length <= 1 || !cardSelection.includes(cardIds[0]);
					if (shouldFireEndAnalytics && fg('one_event_rules_them_all_fg')) {
						const updatedFields = [];
						const swimlaneValues = destinationSwimlaneId
							? getSwimlaneValues(destinationSwimlaneId)
							: undefined;
						const oldSwimlaneValues = sourceSwimlaneId
							? getSwimlaneValues(sourceSwimlaneId)
							: undefined;

						if (sourceSwimlaneId !== destinationSwimlaneId && oldSwimlaneValues && swimlaneValues) {
							if (swimlaneValues.type === ASSIGNEE && oldSwimlaneValues.type === ASSIGNEE) {
								updatedFields.push({
									analytics: createAnalyticsEvent({}),
									attributes: {
										fieldType: FIELD_TYPE_MAP[ASSIGNEE_TYPE],
										oldValId: oldSwimlaneValues.assigneeAccountId,
										newValId: swimlaneValues.assigneeAccountId,
										isDragEditing: true,
										fieldKey: ASSIGNEE_TYPE,
									},
								});
							}
							if (
								(swimlaneValues.type === PARENT && oldSwimlaneValues.type === PARENT) ||
								(swimlaneValues.type === STORY && oldSwimlaneValues.type === STORY)
							) {
								updatedFields.push({
									analytics: createAnalyticsEvent({}),
									attributes: {
										fieldKey: PARENT_TYPE,
										fieldType: FIELD_TYPE_MAP[PARENT_TYPE],
										oldValId: oldSwimlaneValues.parentId,
										newValId: swimlaneValues.parentId,
										isDragEditing: true,
									},
								});
							}
						}
						if (sourceColumnId !== destinationColumnId) {
							const sourceStatus = getSourceStatus(cardIds[0]);
							const destinationStatus = getDestinationStatus(
								cardIds[0],
								Number(destinationColumnId),
							);

							updatedFields.push({
								analytics: createAnalyticsEvent({}),
								attributes: {
									fieldKey: STATUS_TYPE,
									fieldType: FIELD_TYPE_MAP[STATUS_TYPE],
									oldValId: sourceStatus?.id,
									newValId: destinationStatus?.id,
									isDragEditing: true,
								},
							});
						}

						updatedFields.forEach((attributes, index) =>
							getUpdateAnalyticsFlowHelper().fireAnalyticsEnd(
								DRAG_DROP_UNKNOWN_FIELD_ID,
								attributes,
								index === updatedFields.length - 1,
							),
						);
					}
				},
				[
					cardSelection,
					createAnalyticsEvent,
					getDestinationStatus,
					getSourceStatus,
					getSwimlaneValues,
				],
			)
		: undefined;

	const onDragStart = useCallback(
		(draggableData: DraggableCardData | DraggableColumnData) => {
			clearMarksWithPrefix(BOARD_LONG_TASKS_DND_MARK_NAME);
			setMark(BOARD_LONG_TASKS_DND_MARK_NAME);
			const draggableId =
				draggableData.type === CARD_DND_TYPE
					? getCardDescriptor(draggableData.cardId)
					: getColumnDescriptor(draggableData.columnId, draggableData.swimlaneId ?? undefined);

			dispatch(dragStart(draggableId, 'FLUID'));
			const analyticsEvent = createAnalyticsEvent({
				action: 'dragStarted',
				actionSubject: draggableData.type === CARD_DND_TYPE ? 'card' : 'column',
			});

			if (fireAnalyticsStart && draggableData.type === CARD_DND_TYPE) {
				fireAnalyticsStart(draggableData.cardId);
			}

			if (!isIPBoard && draggableData.type === CARD_DND_TYPE) {
				const { cardId } = draggableData;
				const hasConditions = getSomeTransitionsHaveConditions(cardId);
				if (hasConditions) {
					dispatch(cardGetIssueTransitions(cardId, analyticsEvent));
				}
			}
			fireUIAnalytics(analyticsEvent, isIPBoard ? { source: IP_BOARD_TYPE } : {});
		},
		[
			createAnalyticsEvent,
			dispatch,
			fireAnalyticsStart,
			getSomeTransitionsHaveConditions,
			isIPBoard,
		],
	);

	const onDragEnd = useCallback(
		(draggableData: DraggableData, targetData: DraggableData | undefined) => {
			onDragEndProp?.();
			getLongTasksMetrics('dnd').stop(dndReporter, {
				type: draggableData.type,
				boardSessionId,
				isPragmaticBoardEnabled: true,
			});
			dispatch(dragEnd());

			const analyticsEvent = createAnalyticsEvent({
				action: 'dragEnded',
				actionSubject: draggableData.type === CARD_DND_TYPE ? 'card' : 'column',
			});

			let analyticAttributes;

			if (!isIPBoard) {
				analyticAttributes =
					draggableData.type === 'CARD'
						? {
								dndType: draggableData.type,
								hasNonGlobalTransitions: hasCustomTransitions,
								isTransitionSelected,
								hoverIntent: isHoverIntendedDuringDrag,
								sameColumn: draggableData.columnId === targetData?.columnId,
							}
						: {
								dndType: draggableData.type,
							};
			} else {
				analyticAttributes = {
					source: IP_BOARD_TYPE,
				};
			}

			fireUIAnalytics(analyticsEvent, analyticAttributes);
		},
		[
			onDragEndProp,
			boardSessionId,
			dispatch,
			createAnalyticsEvent,
			isIPBoard,
			hasCustomTransitions,
			isTransitionSelected,
			isHoverIntendedDuringDrag,
		],
	);

	const onColumnMove = useCallback(
		(columnId: ColumnId, sourceColumnIndex: number, destinationColumnIndex: number) => {
			if (!isIPBoard) {
				const analyticsEvent = createAnalyticsEvent({
					action: 'moved',
					actionSubject: 'column',
				});
				dispatch(
					columnRankRequest(
						Number(columnId),
						orderedColumnIds[destinationColumnIndex],
						sourceColumnIndex,
						analyticsEvent,
					),
				);
			}
		},
		[createAnalyticsEvent, dispatch, orderedColumnIds, isIPBoard],
	);

	const onCardsMove = useCallback(
		({
			cardIds,
			sourceColumnId,
			destinationColumnId,
			sourceCardIndex,
			destinationCardIndex,
			sourceSwimlaneId,
			destinationSwimlaneId,
		}: {
			cardIds: CardId[];
			sourceColumnId: ColumnId;
			destinationColumnId: ColumnId;
			sourceCardIndex: number;
			destinationCardIndex?: number;
			sourceSwimlaneId?: SwimlaneId | null;
			destinationSwimlaneId?: SwimlaneId | null;
		}) => {
			const analyticsEvent = createAnalyticsEvent({
				action: 'updated',
				actionSubject: 'issue',
			});

			if (overTransitionId && !selectedTransitionId) {
				dispatch(
					cardDragSelectedTransition(
						overTransitionId,
						Number(destinationColumnId),
						destinationSwimlaneId ?? undefined,
					),
				);
			}

			if (fireAnalyticsEnd) {
				fireAnalyticsEnd(
					cardIds,
					sourceSwimlaneId,
					destinationSwimlaneId,
					sourceColumnId,
					destinationColumnId,
				);
			}

			const finalDestinationCardIndex = Number.isInteger(destinationCardIndex)
				? destinationCardIndex
				: getIssuesIdsForColumn(boardStore.getState(), {
						swimlaneId: destinationSwimlaneId,
						columnId: Number(destinationColumnId),
					})?.length;

			dispatch(
				cardsMove({
					cardIds,
					sourceColumnId: Number(sourceColumnId),
					destinationColumnId: Number(destinationColumnId),
					sourceCardIndex,
					destinationCardIndex: finalDestinationCardIndex ?? 0,
					sourceSwimlaneId,
					destinationSwimlaneId,
					analyticsEvent,
				}),
			);
		},
		[
			createAnalyticsEvent,
			overTransitionId,
			selectedTransitionId,
			fireAnalyticsEnd,
			boardStore,
			dispatch,
		],
	);

	usePlatformBoardDragDropContext({ onDragStart, onDragEnd, onColumnMove, onCardsMove });
};
