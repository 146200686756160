import { combineReducers } from 'redux';
import type { Action } from '../../../types.tsx';
import cardAutoScrollReducer from './auto-scroll/reducer.tsx';
import { cardWithIccReducer } from './card-with-icc/reducer.tsx';
import { cardDragReducer } from './drag/reducer.tsx';
import { filteredCardsReducer } from './filtered-cards/reducer.tsx';
import cardFocusReducer from './focused/reducer.tsx';
import { cardInlineEditingReducer } from './inline-editing/reducer.tsx';
import { cardSelectionReducer } from './selected/reducer.tsx';
import { cardTransactionsReducer } from './transactions/reducer.tsx';
import type { CardsState } from './types.tsx';

export default combineReducers<CardsState, Action>({
	selected: cardSelectionReducer,
	transactions: cardTransactionsReducer,
	drag: cardDragReducer,
	focused: cardFocusReducer,
	cardWithIcc: cardWithIccReducer,
	filteredCards: filteredCardsReducer,
	autoScroll: cardAutoScrollReducer,
	inlineEditing: cardInlineEditingReducer,
});
