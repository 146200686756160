import { INSIGHTS_BOARD_PACKAGE, INSIGHTS_BOARD_SIMPLIFIED_PACKAGE } from './constants.tsx';

type ParametersType = {
	isSimplifiedProject: boolean;
	boardId: number;
};

export const buildBoardScopeName = ({ isSimplifiedProject, boardId }: ParametersType): string =>
	`${
		isSimplifiedProject ? INSIGHTS_BOARD_SIMPLIFIED_PACKAGE : INSIGHTS_BOARD_PACKAGE
	}-boardId:${boardId}`;
