import { useState, useCallback } from 'react';
import isNil from 'lodash/isNil';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { ERROR_REPORTING_TEAM, PACKAGE_NAME, SERVICES_ERROR_ID } from '../../common/constants.tsx';
import type { FetchIssueCount, IssueCountResponse, FetchIssueCountPayload } from './types.tsx';

export const useFetchIssueCount = ({
	planId,
	scenarioId,
	start,
	iterationsCount,
	iterationLength,
	filterFieldId,
	filterFieldValue,
}: FetchIssueCount) => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [issueCount, setIssueCount] = useState<number | null>(null);

	const fetchIssueCount = useCallback(async () => {
		try {
			if (isNil(planId)) {
				throw new Error('planId is empty in the fetch issue count request');
			}

			if (isNil(scenarioId)) {
				throw new Error('scenarioId is empty in the fetch issue count request');
			}

			setIsLoading(true);

			const payload: FetchIssueCountPayload = {
				start,
				iterationsCount,
				iterationLength,
				filterFieldId,
				filterFieldValue:
					// We don't clear the field value if the filter field is not set, so we should only send the value when the field id is defined
					filterFieldId ? filterFieldValue : undefined,
			};

			const options = {
				body: JSON.stringify(payload),
			};

			const { issueCount: responseIssueCount } = await performPostRequest<IssueCountResponse>(
				`/rest/jpo/2.0/plans/${planId}/increment/board/preview/scenarios/${scenarioId}/countIssues`,
				options,
			);

			setIssueCount(responseIssueCount);
			setError(null);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-shadow
		} catch (error: any) {
			fireErrorAnalytics({
				meta: {
					id: SERVICES_ERROR_ID,
					packageName: PACKAGE_NAME,
					teamName: ERROR_REPORTING_TEAM,
				},
				attributes: {
					serviceName: 'useFetchIssueCount',
				},
				error,
				sendToPrivacyUnsafeSplunk: true,
			});
			setError(error);
			setIssueCount(null);
		} finally {
			setIsLoading(false);
		}
	}, [
		planId,
		scenarioId,
		start,
		iterationsCount,
		iterationLength,
		filterFieldId,
		filterFieldValue,
	]);
	return {
		isLoading,
		error,
		issueCount,
		fetchIssueCount,
	};
};
