import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';

import { Observable } from 'rxjs/Observable';
import { getDescriptor } from '@atlassian/jira-platform-board-kit/src/common/utils/descriptor/index.tsx';
import { type DragStartAction, DRAG_START } from '../../state/actions/board/drag/index.tsx';
import { cardCancelSelection } from '../../state/actions/card/index.tsx';
import { getCardSelection } from '../../state/selectors/card/card-selectors.tsx';
import type { Action, ActionsObservable, MiddlewareAPI, State } from '../../state/types.tsx';

export const handleCardDragStart = (
	payload: DragStartAction['payload'],
	state: State,
): Observable<Action> => {
	if (!payload) return Observable.empty<never>();

	const cardId = `${getDescriptor(payload.draggableId).id}`;
	const selectedIds = getCardSelection(state).map((id) => `${id}`);

	if (selectedIds.length === 0 || selectedIds.includes(cardId)) {
		return Observable.empty<never>();
	}

	return Observable.of(cardCancelSelection());
};

export function cardDragStartEpic(
	action$: ActionsObservable,
	store: MiddlewareAPI,
): Observable<Action> {
	return action$
		.ofType(DRAG_START)
		.mergeMap((action: DragStartAction) => handleCardDragStart(action.payload, store.getState()));
}
