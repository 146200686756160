type RapidViewId = string;

export const getBoardPrettyUrl = (
	contextPath: string,
	projectKey: string,
	rapidViewId: RapidViewId,
) => `${contextPath}/jira/software/projects/${projectKey}/boards/${rapidViewId}`;

export const getBacklogPrettyUrl = (
	contextPath: string,
	projectKey: string,
	rapidViewId: RapidViewId,
	isCMPBoard: boolean,
	additionalParams: string[] = [],
) => {
	const paramString = additionalParams.map((param) => `${param}`).join('&');
	return `${contextPath}/jira/software${
		isCMPBoard ? '/c' : ''
	}/projects/${projectKey}/boards/${rapidViewId}/backlog${
		additionalParams.length ? `?${paramString}` : ''
	}`;
};
