import React, { useState, useEffect } from 'react';
import AkPopup from '@atlaskit/popup';
import type {
	ManualRule,
	ManualRuleExecutor,
} from '@atlassian/jira-automation-platform/src/common/types.tsx';
import { ManualRulesContainer } from '@atlassian/jira-automation-platform/src/ui/manual-rules-container/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import {
	ContextualAnalyticsData,
	DROPDOWN,
	FireTrackAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { getBaseAutomationUrl } from '../../common/utils.tsx';
import { useMenuContextActions } from '../../controllers/menu-context/index.tsx';
import { useSelectedIssuesSubscription } from '../../controllers/selected-issues/index.tsx';
import RunRule from './run-rule/main.tsx';
import type { Props } from './types.tsx';

const Popup = ({
	Trigger,
	projectKey,
	projectId,
	projectType,
	isSimplified,
	isUIF,
	initialIssuesSelection,
	selectedIssueKeys,
	selectedIssueIds,
	canManageAutomations = false,
}: Props) => {
	const [isOpen, setIsOpen] = useState(false);
	const [, { setMenuContext }] = useMenuContextActions();
	const { environment, cloudId } = useTenantContext();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		setMenuContext({
			canManageAutomations,
			projectAri: createAri({
				resourceOwner: 'jira',
				cloudId,
				resourceType: 'project',
				resourceId: projectId,
			}),
			projectId,
			// For the URL, we want the old one for UIF/CMP and the new one for TMP
			baseAutomationUrl: getBaseAutomationUrl({
				projectKey,
				projectType,
				isSimplified,
			}),
		}); // Store in a sweet state store to avoid prop drilling.
	}, [
		setMenuContext,
		canManageAutomations,
		cloudId,
		projectId,
		projectKey,
		projectType,
		isSimplified,
	]);

	// If we have a TMP OR CMP/UIF project, we need to add the newer method of subscribing
	// to issue changes (which doesn't include using the legacy bridge)
	useSelectedIssuesSubscription(
		isSimplified || isUIF,
		initialIssuesSelection,
		selectedIssueKeys,
		projectKey,
	);

	const generateAkPopup = (
		initialised: boolean,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		error: any,
		rules: ManualRule[],
		executingRuleId: number | null,
		executeRule: ManualRuleExecutor,
		triggerFetch: () => Promise<void>,
	) => (
		<AkPopup
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			placement="bottom-end"
			content={() => (
				<RunRule
					initialised={initialised}
					error={error}
					rules={rules}
					executingRuleId={executingRuleId}
					executeRule={executeRule}
					triggerFetch={triggerFetch}
					selectedIssueIds={selectedIssueIds}
					setClosed={() => setIsOpen(false)}
				/>
			)}
			trigger={({ ...triggerProps }) => (
				<Trigger
					{...triggerProps}
					onClick={() => {
						if (!isOpen) {
							const analyticsEvent = createAnalyticsEvent({
								actionSubject: 'automationBolt',
								action: 'opened',
							});
							const attributes = fg('selected-issue-id-limit')
								? { selectedIssueCount: selectedIssueIds?.length }
								: {};

							fireUIAnalytics(analyticsEvent, attributes);
						}
						setIsOpen(!isOpen);
					}}
					isSelected={isOpen}
				/>
			)}
		/>
	);

	return (
		<ContextualAnalyticsData
			sourceName="automationMenu"
			sourceType={DROPDOWN}
			attributes={{ isSimplified, canManageAutomations }}
		>
			<FireTrackAnalytics
				action="viewed"
				actionSubject="button"
				actionSubjectId="automationMenuButton"
			/>
			<ManualRulesContainer cloudId={cloudId} issueIds={selectedIssueIds ?? []} env={environment}>
				{({ triggerFetch, initialised, error, rules, invokingRuleId, invokeRuleOrShowDialog }) =>
					generateAkPopup(
						initialised,
						error,
						rules,
						invokingRuleId,
						invokeRuleOrShowDialog,
						triggerFetch,
					)
				}
			</ManualRulesContainer>
		</ContextualAnalyticsData>
	);
};

export default Popup;
