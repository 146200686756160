import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { Observable } from 'rxjs/Observable';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import {
	fireTrackAnalytics,
	fireOperationalAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import type {
	CardTransition,
	ColumnIssueTypeTransitions,
} from '../../model/card-transition/card-transition-types.tsx';
import type { Issue } from '../../model/issue/issue-types.tsx';
import type { IssueProject } from '../../model/software/software-types.tsx';
import { fetchCardIssueTransitionsService } from '../../services/card-issue-transitions/index.tsx';
import {
	CARD_GET_ISSUE_TRANSITIONS,
	cardGetIssueTransitionsFailure,
	cardSetIssueTransitions,
} from '../../state/actions/card/index.tsx';
import type {
	ColumnCardTransitions,
	IssueTransition,
} from '../../state/reducers/entities/issue-transitions/types.tsx';
import { getDraggingCardId } from '../../state/selectors/card/card-selectors.tsx';
import { getIssueById } from '../../state/selectors/issue/issue-selectors.tsx';
import {
	getCardTransitions,
	contextPathSelector,
	rapidViewIdSelector,
	getIssueProjects,
} from '../../state/selectors/software/software-selectors.tsx';
import type { ActionsObservable, MiddlewareAPI } from '../../state/types.tsx';

export const LOG_INFO_KEY = 'card-fetch-issue-transitions.info';
export const LOG_FAILURE_KEY = 'card-fetch-issue-transitions.failure';

export const toColumnsTransitionMapWithProject =
	(
		issue: Issue,
		issueProject: IssueProject,
		columnIssueTypeTransitions: ColumnIssueTypeTransitions,
	) =>
	(issueTransitions: IssueTransition[]): ColumnCardTransitions => {
		const columnIds = Object.keys(columnIssueTypeTransitions);
		const comparisonFn = (columnTransition: CardTransition, issueTransition: IssueTransition) =>
			columnTransition.id === issueTransition.id &&
			columnTransition.destinationStatusId === issueTransition.destinationStatusId &&
			(issueProject === undefined || columnTransition.projectKey === issueProject.key);
		const availableColumnTransitions: Record<string, IssueTransition[]> = {};

		columnIds.forEach((columnId) => {
			const columnTransitions = columnIssueTypeTransitions[columnId][String(issue.typeId)] || [];

			// Match based on project KEY
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			availableColumnTransitions[columnId] = columnTransitions
				.map((columnTransition) =>
					// We retrieve the issue one because we grabbed it recently from SWAG
					issueTransitions.find((issueTransition) =>
						comparisonFn(columnTransition, issueTransition),
					),
				)
				.filter((columnTransition) => !isNil(columnTransition)) as IssueTransition[];
		});

		return availableColumnTransitions;
	};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable, store: MiddlewareAPI) =>
	action$.ofType(CARD_GET_ISSUE_TRANSITIONS).switchMap((action) => {
		const {
			meta: { analytics: analyticsEvent },
		} = action;
		const state = store.getState();

		const cardId = getDraggingCardId(state);
		if (isNil(cardId)) {
			log.safeErrorWithoutCustomerData(
				LOG_FAILURE_KEY,
				'No dragging issue data present in state to fetch transitions',
			);
			return Observable.empty<never>();
		}

		const contextPath = contextPathSelector(state);
		const boardId = rapidViewIdSelector(state);
		const issue = getIssueById(state, cardId);
		const cardTransitions = getCardTransitions(state);
		const projects = getIssueProjects(state);
		const issueProject = projects[issue?.projectId];

		fireOperationalAnalytics(analyticsEvent, 'boardIssueTransitions fetchRequested');

		return fetchCardIssueTransitionsService(contextPath, boardId, cardId)
			.switchMap((issueTransitions: IssueTransition[]) => {
				if (isEmpty(issueTransitions)) {
					log.safeInfoWithoutCustomerData(
						LOG_INFO_KEY,
						`No transitions available for issue ${cardId}`,
					);
					return Observable.empty<never>();
				}
				return Observable.of(issueTransitions);
			})
			.map(
				toColumnsTransitionMapWithProject(
					issue,
					issueProject,
					cardTransitions.columnIssueTypeTransitions,
				),
			)
			.switchMap((availableColumnTransitions: ColumnCardTransitions) => {
				fireTrackAnalytics(analyticsEvent, 'boardIssueTransitions fetchSucceeded');
				return Observable.of(cardSetIssueTransitions(cardId, availableColumnTransitions));
			})
			.catch((error: Error) => {
				log.safeErrorWithoutCustomerData(
					LOG_FAILURE_KEY,
					'Failed to get card transition data',
					error,
				);
				fireTrackAnalytics(analyticsEvent, 'boardIssueTransitions fetchFailed');

				return Observable.of(cardGetIssueTransitionsFailure(cardId));
			});
	});
