import { useEffect } from 'react';

import { useBoardSelector } from '../../state/index.tsx';
import {
	selectedIssueKeysSelector,
	selectedIssueIdsSelector,
} from '../../state/selectors/card/card-selectors.tsx';
import useSelectedIssuesSync from './sweet-state.tsx';

const SelectedIssuesSync = () => {
	const selectedIssueKeys = useBoardSelector(selectedIssueKeysSelector);
	const selectedIssueIds = useBoardSelector(selectedIssueIdsSelector);

	const [, { setSelectedIssueKeys, setSelectedIssueIds }] = useSelectedIssuesSync();
	useEffect(() => {
		setSelectedIssueKeys(selectedIssueKeys);
		setSelectedIssueIds(selectedIssueIds);
	}, [selectedIssueKeys, setSelectedIssueKeys, selectedIssueIds, setSelectedIssueIds]);
	return null;
};

export default SelectedIssuesSync;
