/**
 * @generated SignedSource<<5b69286dc3d574c4254b644a20345969>>
 * @relayHash 19dd9f9578703995725a17532527b663
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID f1f10befcdb537dd4ab4ac9c44e5edcb51d6b3f4306fd285cc82de529ebae8db

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { uiDesignDetailsPopupQuery } from './uiDesignDetailsPopupQuery.graphql';

const node: PreloadableConcreteRequest<uiDesignDetailsPopupQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "f1f10befcdb537dd4ab4ac9c44e5edcb51d6b3f4306fd285cc82de529ebae8db",
    "metadata": {},
    "name": "uiDesignDetailsPopupQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
