import React from 'react';
import { styled } from '@compiled/react';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { PriorityProps } from '../../types.tsx';
import messages from './messages.tsx';

export const PriorityIcon = ({ name, iconUrl }: PriorityProps) => {
	const { formatMessage } = useIntl();
	let priorityName = name;
	if (fg('sea-2830-improve-accessibility-of-priority-field')) {
		priorityName = formatMessage(messages.priorityTooltip, { name });
	}

	return (
		<Tooltip
			content={priorityName}
			testId="issue-field-priority-chip.common.ui.priority-icon.tooltip"
		>
			<IconWrapper>
				<Icon alt={priorityName} src={iconUrl} />
			</IconWrapper>
		</Tooltip>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IconWrapper = styled.div({
	alignItems: 'center',
	display: 'flex',
	justifyContent: 'center',
	height: '24px',
	width: '24px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Icon = styled.img({
	height: '18px',
	width: '18px',
	verticalAlign: 'text-bottom',
});
