import get from 'lodash/get';

export const NOT_PRESENT = 'NOT_PRESENT';

type AttributeValue = string | number | typeof NOT_PRESENT;

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
export const getBuildKey = () => window.BUILD_KEY;

export const extractStatusCode = (error: Error): AttributeValue =>
	get(error, ['statusCode'], NOT_PRESENT);

export const extractTraceId = (error: Error): AttributeValue =>
	get(error, ['traceId'], NOT_PRESENT);

export const extractErrorName = (error: Error): AttributeValue => get(error, ['name'], NOT_PRESENT);

export const extractErrorMessage = (error: Error): AttributeValue =>
	get(error, ['message'], NOT_PRESENT);
