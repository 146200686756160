import React, { useEffect } from 'react';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';

type ComponentSafetyOptions<Props> = {
	condition: (props: Props) => boolean;
	location: string;
	message?: (props: Props) => string;
	Fallback?: React.ComponentType<Props>;
};

/* condition must return true for component to render */
function withComponentSafety<Props>(
	Component: React.ComponentType<Props>,
	{
		condition,
		location,
		message = () => 'withComponentSafety blocked render',
		Fallback = () => null,
	}: ComponentSafetyOptions<Props>,
) {
	return (props: Props) => {
		const isSafe = condition(props);
		const formattedMessage = message(props);

		useEffect(() => {
			if (!isSafe) log.safeErrorWithoutCustomerData(location, formattedMessage);
		}, [isSafe, formattedMessage]);

		// @ts-expect-error - Type 'Props' is not assignable to type 'IntrinsicAttributes & Props'.
		return isSafe ? <Component {...props} /> : <Fallback {...props} />;
	};
}

export { withComponentSafety };
