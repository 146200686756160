import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { View } from '@atlassian/jira-software-view-settings/src/common/types/constant.tsx';
import { getViewSettings as getViewSettingsFromLocalStorage } from '@atlassian/jira-software-view-settings/src/services/local-storage/index.tsx';
import { fetchIssueLinksStatsService } from '../../services/issue-links-stats/index.tsx';
import {
	FETCH_ISSUE_LINKS_STATS,
	setIssueLinksStats,
} from '../../state/actions/issue-links-stats/index.tsx';
import { WORK_DATA_SET } from '../../state/actions/work/index.tsx';
import { boardOrderedIssueIdsSelector } from '../../state/selectors/issue/board-issue-selectors.tsx';
import {
	getIsIncrementPlanningBoard,
	getIsJSWBoard,
	rapidViewIdSelector,
} from '../../state/selectors/software/software-selectors.tsx';
import type { Action, ActionsObservable, MiddlewareAPI } from '../../state/types.tsx';

export const getIssueLinksStatsEpic = (
	action$: ActionsObservable,
	store: MiddlewareAPI,
): Observable<Action> =>
	action$.ofType(FETCH_ISSUE_LINKS_STATS, WORK_DATA_SET).switchMap((action) => {
		const state = store.getState();
		const isJSWBoard = getIsJSWBoard(state);
		const isIPBoard = getIsIncrementPlanningBoard(state);
		const showIssueLinksStatsViewSettings = getViewSettingsFromLocalStorage({
			boardId: Number(rapidViewIdSelector(state)),
			view: View.BOARD,
		})?.showIssueLinksStats;

		if (!isJSWBoard || isIPBoard || !showIssueLinksStatsViewSettings) return Observable.of();

		const payloadIssueIds = action.type === FETCH_ISSUE_LINKS_STATS && action.payload;

		const analyticsEvent = action.type === FETCH_ISSUE_LINKS_STATS && action.meta?.analyticsEvent;
		const issueIds = payloadIssueIds || boardOrderedIssueIdsSelector(state);

		if (!issueIds.length) {
			return Observable.of();
		}

		return fetchIssueLinksStatsService(issueIds)
			.mergeMap((linkedIssuesStatsResponse) => {
				if (analyticsEvent) {
					fireOperationalAnalytics(analyticsEvent, 'ui taskSuccess', {
						task: 'issueLinksStatsFetch',
					});
				}
				return Observable.of(setIssueLinksStats(linkedIssuesStatsResponse));
			})
			.catch((error) => {
				if (!isClientFetchError(error)) {
					analyticsEvent &&
						fireOperationalAnalytics(analyticsEvent, 'ui taskFail', {
							task: 'issueLinksStatsFetch',
							isClientFetchError: false,
						});
				}
				return Observable.of();
			});
	});
