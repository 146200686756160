import React from 'react';
import { useContainerContext } from '@atlassian/jira-providers-container-context/src/main.tsx';
import {
	isUserContext,
	isProjectContext,
} from '@atlassian/jira-providers-container-context/src/types.tsx';
import { useUrlState, type UrlState } from '../controllers/url-state/index.tsx';
import { ProjectBreadcrumbs } from './project/index.tsx';
import { UserBreadcrumbs } from './user/index.tsx';

export const Breadcrumbs = () => {
	const [{ data, error }] = useContainerContext();
	const { chartView, boardId, isBoardLocationless, isReportsView, accountId }: UrlState =
		useUrlState();

	if (!data || error) {
		return null;
	}

	const boardName = (data.scope && data.scope.name) || '';
	if (isUserContext(data) && accountId) {
		const { user } = data;
		return (
			<UserBreadcrumbs
				accountId={accountId}
				boardId={Number(boardId)}
				boardName={boardName}
				isReportsView={isReportsView}
				profileUrl={user.profileUrl}
				userName={user.name}
				chart={chartView}
				isLocationlessBoard={isBoardLocationless}
			/>
		);
	}

	if (isProjectContext(data)) {
		const { project } = data;
		const projectKey = project.key;
		const projectName = project.name;
		const projectId = project.id;
		const isSimplifiedProject = project.isSimplified;
		return (
			<ProjectBreadcrumbs
				baseUrl=""
				boardId={Number(boardId)}
				boardName={boardName}
				chart={chartView}
				projectKey={projectKey}
				projectName={projectName}
				projectId={projectId}
				isSimplifiedProject={isSimplifiedProject}
			/>
		);
	}
	return null;
};
