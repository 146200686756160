import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import { showErrorFlag } from '@atlassian/jira-software-swag/src/common/constants.tsx';
import { REFRESH_SOURCE_FAILURE } from '../../model/constants.tsx';
import { makeServiceContext } from '../../services/service-context.tsx';
import { setSwimlaneModeSetting } from '../../services/software/software-storage.tsx';
import mutateUserSwimlaneStrategyService from '../../services/user-swimlane-strategy-graphql/mutate/index.tsx';
import {
	swimlaneModeChangeFailure,
	swimlaneModeChangeSuccess,
	type SwimlaneModeChangeRequestAction,
	SWIMLANE_MODE_CHANGE_REQUEST,
} from '../../state/actions/swimlane/index.tsx';
import { workRefreshData } from '../../state/actions/work/index.tsx';
import {
	contextPathSelector,
	rapidViewIdSelector,
} from '../../state/selectors/software/software-selectors.tsx';
import type { MiddlewareAPI, ActionsObservable, Action } from '../../state/types.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable, store: MiddlewareAPI) =>
	action$
		.ofType(SWIMLANE_MODE_CHANGE_REQUEST)
		.mergeMap((action: SwimlaneModeChangeRequestAction) => {
			const state = store.getState();
			const {
				meta: {
					optimistic: { id: txId },
				},
				payload: swimlaneMode,
			} = action;

			const contextPath = contextPathSelector(state);
			const rapidViewId = rapidViewIdSelector(state);

			const ctx = makeServiceContext(state);
			if (ctx.isCMPBoard) {
				setSwimlaneModeSetting(rapidViewId, action.payload);
				return Observable.of(swimlaneModeChangeSuccess(txId));
			}

			return mutateUserSwimlaneStrategyService(contextPath, rapidViewId, swimlaneMode)
				.flatMap(() => Observable.of(swimlaneModeChangeSuccess(txId)))
				.catch((error) =>
					Observable.of(
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						swimlaneModeChangeFailure(txId) as Action,
						workRefreshData(REFRESH_SOURCE_FAILURE),
						showErrorFlag(error),
					),
				);
		});
