import { useRef } from 'react';
import useRenderState from '../../../../../common/hooks/use-render-state/index.tsx';

/**
 * We want to render the lite card in the following scenarios:
 * 1. User is scrolling and this is the first render
 * 2. User is scrolling and lite card was rendered on the previous render.
 *    This ensures we don't jump to the full card mid-scroll if there happens
 *    to be a re-render.
 */
export const useShouldRenderCardLite = (isScrolling: boolean) => {
	const { isInitialRender } = useRenderState();
	const previouslyRenderedLiteCard = useRef(false);

	if (isScrolling && (isInitialRender || previouslyRenderedLiteCard.current)) {
		previouslyRenderedLiteCard.current = true;
		return true;
	}

	previouslyRenderedLiteCard.current = false;
	return false;
};
