import { chain, map, splice } from 'icepick';
import difference from 'lodash/difference';
import intersection from 'lodash/intersection';
import { fg } from '@atlassian/jira-feature-gating';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import { CARD_DND_TYPE } from '../../../../../model/constants.tsx';
import {
	type BacklogIssueMoveExecuteAction,
	BACKLOG_ISSUE_MOVE_EXECUTE,
} from '../../../../actions/board/backlog-issue-move/index.tsx';
import {
	CARD_CLEAR,
	CARD_DELETE,
	type CardDeleteAction,
	type CardClearAction,
} from '../../../../actions/card/index.tsx';
import {
	COLUMN_DELETE_REQUEST,
	type ColumnDeleteRequestAction,
} from '../../../../actions/column/delete/index.tsx';
import type { Action } from '../../../../actions/index.tsx';
import {
	type IssueCreateRequestAction,
	type IssueCreateSuccessAction,
	ISSUE_CREATE_REQUEST,
	ISSUE_CREATE_SUCCESS,
} from '../../../../actions/issue/create/index.tsx';
import {
	type IssueDeleteRequestAction,
	ISSUE_DELETE_REQUEST,
} from '../../../../actions/issue/delete/index.tsx';
import {
	type IssueMoveToBacklogRequestAction,
	ISSUE_MOVE_TO_BACKLOG_REQUEST,
} from '../../../../actions/issue/move-to-backlog/index.tsx';
import {
	ISSUE_RANK_TEAM_DATE_REQUEST,
	type IssueRankTeamDateRequestAction,
} from '../../../../actions/issue/rank-team-date/index.tsx';
import {
	type BulkIssueRankOptimisticAction,
	type IssueRankTransitionUpdateOptimisticAction,
	type SetIssuesRankAction,
	SET_ISSUES_RANK,
	ISSUE_RANK_TRANSITION_UPDATE_OPTIMISTIC,
	BULK_ISSUE_RANK_OPTIMISTIC,
} from '../../../../actions/issue/rank-transition/index.tsx';
import {
	type AddToSprintSuccessAction,
	ADD_TO_SPRINT_SUCCESS,
} from '../../../../actions/sprints/add-to-sprint/index.tsx';
import {
	type RemoveFromSprintRequestAction,
	REMOVE_FROM_SPRINT_REQUEST,
} from '../../../../actions/sprints/remove-from-sprint/index.tsx';
import {
	type WorkDataSetAction,
	type WorkDataCriticalSetAction,
	WORK_DATA_SET,
	WORK_DATA_CRITICAL_SET,
} from '../../../../actions/work/index.tsx';
import type { IssuesState } from './types.tsx';

type RankIssuePayload = {
	issueIds: IssueId[];
	rankBeforeIssueId: IssueId | null | undefined;
	rankAfterIssueId: IssueId | null | undefined;
};

const rankIssues = (state: IssuesState, payload: RankIssuePayload): IssuesState => {
	const { issueIds, rankBeforeIssueId, rankAfterIssueId } = payload;
	const availableIssues = intersection(state, issueIds);
	if (availableIssues.length === 0) return state;

	const newState = difference(state, availableIssues);

	let destinationIndex: number;

	if (rankBeforeIssueId) {
		destinationIndex = newState.indexOf(rankBeforeIssueId);
	} else if (rankAfterIssueId) {
		destinationIndex = newState.indexOf(rankAfterIssueId) + 1;
	} else {
		return state;
	}

	return splice(newState, destinationIndex, 0, ...issueIds);
};

export const boardIssuesReducer = (state: IssuesState = [], action: Action): IssuesState => {
	if (action.type === WORK_DATA_SET || action.type === WORK_DATA_CRITICAL_SET) {
		const {
			payload: {
				issues,
				config: { boardIsRankable },
			},
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as WorkDataSetAction | WorkDataCriticalSetAction;

		/* For a CMP project, the ordered issues include in:
		 * - issues and orphan subtasks if the board is rankable
		 * - issues and subtasks if the board is not rankable
		 * */
		if (action.type === WORK_DATA_SET && action.payload.orderedIssueIds) {
			if (!boardIsRankable) {
				return action.payload.orderedIssueIds;
			}

			const issueChildrenIdsWithParent = action.payload.issueChildren
				.filter(({ parentId }) => !!parentId && action.payload.orderedIssueIds?.includes(parentId))
				.map(({ id }) => id);
			return action.payload.orderedIssueIds.filter(
				(id) => !issueChildrenIdsWithParent.includes(id),
			);
		}

		return issues.map((issue) => issue.id);
	}

	if (action.type === ISSUE_CREATE_REQUEST) {
		const {
			payload: { issues, type },
			meta: { insertBefore, isSubtaskMissingParent = false },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as IssueCreateRequestAction;

		if (type !== CARD_DND_TYPE && !isSubtaskMissingParent) return state;

		const issueIds = issues.map(({ id }) => id);
		const insertionPoint = insertBefore ? state.indexOf(insertBefore) : state.length;
		return splice(state, insertionPoint, 0, ...issueIds);
	}

	if (action.type === REMOVE_FROM_SPRINT_REQUEST) {
		const {
			payload: { issueId },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as RemoveFromSprintRequestAction;
		const index = state.indexOf(issueId);
		return index < 0 ? state : splice(state, index, 1);
	}

	if (fg('jira-removefromsprint-nodelay')) {
		if (action.type === ADD_TO_SPRINT_SUCCESS) {
			const {
				payload: { issueKey },
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			} = action as AddToSprintSuccessAction;
			return splice(state, state.length, 0, issueKey);
		}
	}

	if (action.type === ISSUE_DELETE_REQUEST) {
		const {
			payload: { issue },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as IssueDeleteRequestAction;
		const index = state.indexOf(issue.id);
		if (index < 0) {
			return state;
		}
		return splice(state, index, 1);
	}

	if (action.type === CARD_DELETE) {
		const {
			payload: { issueId },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as CardDeleteAction;
		const index = state.indexOf(issueId);
		if (index < 0) {
			return state;
		}
		return splice(state, index, 1);
	}

	if (action.type === CARD_CLEAR) {
		const {
			payload: { cardIds },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as CardClearAction;

		const newState = chain(state);
		cardIds.forEach((cardId) => {
			const index = newState.value().indexOf(cardId);
			if (index >= 0) {
				// @ts-expect-error - TS2339 - Property 'splice' does not exist on type 'IcepickWrapper<IssuesState>'.
				newState.splice(index, 1);
			}
		});
		return newState.value();
	}

	if (action.type === ISSUE_MOVE_TO_BACKLOG_REQUEST) {
		const {
			payload: { issueIds },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as IssueMoveToBacklogRequestAction;
		const newState = chain(state);
		issueIds.forEach((issueId) => {
			const index = newState.value().indexOf(issueId);
			if (index >= 0) {
				// @ts-expect-error - TS2339 - Property 'splice' does not exist on type 'IcepickWrapper<IssuesState>'.
				newState.splice(index, 1);
			}
		});
		return newState.value();
	}

	if (action.type === ISSUE_CREATE_SUCCESS) {
		const {
			payload: { temporaryIssueIds, issues },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as IssueCreateSuccessAction;
		const translation: { [key: string]: IssueId } = {};
		temporaryIssueIds.forEach((id, index) => {
			if (issues[index]) {
				translation[id] = issues[index].id;
			}
		});
		// Assume that no temporary id will ever be falsey (0).
		return Object.keys(translation).length > 0
			? map((oldId) => translation[oldId] || oldId, state)
			: state;
	}

	if (action.type === ISSUE_RANK_TEAM_DATE_REQUEST) {
		const {
			payload: { issueIds, rankBeforeIssueId, rankAfterIssueId },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as IssueRankTeamDateRequestAction;
		return rankIssues(state, {
			issueIds,
			rankBeforeIssueId,
			rankAfterIssueId,
		});
	}

	if (action.type === ISSUE_RANK_TRANSITION_UPDATE_OPTIMISTIC) {
		const {
			payload: { issueIds, rankBeforeIssueId, rankAfterIssueId, mode },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as IssueRankTransitionUpdateOptimisticAction;

		/**
		 * We won't update the ranking if the moving card is transition only
		 * */

		if (mode === 'TRANSITION_ONLY' || [rankBeforeIssueId, rankAfterIssueId].includes(issueIds[0])) {
			return state;
		}

		return rankIssues(state, {
			issueIds,
			rankBeforeIssueId,
			rankAfterIssueId,
		});
	}

	if (action.type === BULK_ISSUE_RANK_OPTIMISTIC) {
		const {
			payload: { issueIds, rankAfterId, rankBeforeId },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as BulkIssueRankOptimisticAction;

		return rankIssues(state, {
			issueIds,
			rankBeforeIssueId: rankBeforeId,
			rankAfterIssueId: rankAfterId,
		});
	}

	if (action.type === SET_ISSUES_RANK) {
		const {
			payload: { issueIds, rankId, isRankAfter },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as SetIssuesRankAction;

		const availableIssues = intersection(state, issueIds);

		if (!availableIssues.length) {
			return state;
		}

		const newState = difference(state, availableIssues);
		const rankItemIndex = newState.indexOf(rankId);
		const destinationIndex = isRankAfter ? rankItemIndex + 1 : rankItemIndex;

		return splice(newState, destinationIndex, 0, ...issueIds);
	}

	if (action.type === BACKLOG_ISSUE_MOVE_EXECUTE) {
		const {
			payload: { issueIds },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as BacklogIssueMoveExecuteAction;
		return difference(state, issueIds);
	}

	if (action.type === COLUMN_DELETE_REQUEST) {
		const {
			payload: { closestColumn, issuesInColumn },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as ColumnDeleteRequestAction;
		if (!closestColumn) {
			const issueIdsInColumn = new Set(issuesInColumn.map((issue) => issue.id));
			return state.filter((id) => !issueIdsInColumn.has(id));
		}
	}

	return state;
};
