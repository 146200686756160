import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	assigneeTooltip: {
		id: 'software.main.common.fields.assignee-field-static.assignee-tooltip',
		defaultMessage: 'Assignee: {name}',
		description: 'Tooltip of the assignee avatar when hovered over',
	},
	assigneeTooltipUnassigned: {
		id: 'software.main.common.fields.assignee-field-static.assignee-tooltip-unassigned',
		defaultMessage: 'Unassigned',
		description: 'Tooltip of the unassigned avatar when hovered over',
	},
});
