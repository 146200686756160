import { createStore, createHook } from '@atlassian/react-sweet-state';
import actions from './actions.tsx';
import { initialState } from './constants.tsx';
import type { State } from './types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { RetroTypes, type RetroType } from './types';

type Actions = typeof actions;

export const Store = createStore<State, Actions>({ initialState, actions, name: 'create-retros' });

export const useRetros = createHook(Store);
