import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	interactionLayerLabel: {
		id: 'platform-card.card.interaction-layer-label',
		defaultMessage: '{issueKey} {issueSummary}. Use the enter key to load the issue view.',
		description: 'aria-label for when a Jira board card is focused.',
	},
	interactionLayerLabelIssueTermRefresh: {
		id: 'platform-card.card.interaction-layer-label-issue-term-refresh',
		defaultMessage: '{issueKey} {issueSummary}. Use the enter key to load the issue view.',
		description: 'aria-label for when a Jira board card is focused.',
	},
});
