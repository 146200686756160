/**
 * @generated SignedSource<<418bda9720e122cfda1797cb611cf2dc>>
 * @relayHash b389da800a338761731ddd5b921c6b06
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 6bc51194f353e74e01f5f4c7ebe3e05caf5a8fd91087cf93d55195051e742ceb

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { CardCoverManagerQuery } from './CardCoverManagerQuery.graphql';

const node: PreloadableConcreteRequest<CardCoverManagerQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "6bc51194f353e74e01f5f4c7ebe3e05caf5a8fd91087cf93d55195051e742ceb",
    "metadata": {},
    "name": "CardCoverManagerQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
