import React from 'react';
import { EpicLozenge } from '@atlassian/jira-issue-field-parent-switcher/src/common/ui/epic-lozenge/index.tsx';
import type { IssueParentFieldStaticProps } from './types.tsx';

export const IssueParentFieldStatic = ({
	color,
	summary,
	maxWidth,
}: IssueParentFieldStaticProps) => {
	if (color === null || summary === null) return null;
	return <EpicLozenge color={color} summary={summary} maxWidth={maxWidth} />;
};
