import type { CoverMedia } from '@atlassian/jira-card-cover-manager/src/common/cover-media.types.tsx';
import type { IssueMediaCollection } from '../../../../../../../../model/issue/issue-media-types.tsx';
import { transformMediaDataForSingleIssue } from '../../../../../../../../services/card-covers/card-covers-transformer.tsx';

export const transformCardCoverPopoverData = (
	coverMedia: CoverMedia,
	issueId: string,
): IssueMediaCollection => {
	if (!coverMedia) {
		// Clear card cover data for the issue if coverMedia is null
		return {
			[issueId]: null,
		};
	}

	if (coverMedia.__typename !== 'JiraAttachmentBackground' || !coverMedia.mediaConfig) {
		/*
		Don't do anything if we've received invalid or incomplete data. This could be because:
			1. a ColorCover gets passed in here. This is unlikely, but there's no typecheck preventing
			this from happening because type CoverMedia = ColorCover | AttachmentCover | null and CoverMedia
			is the prescribed input type for the onUpdated function that triggers this function call.
			2. coverMedia.mediaConfig is null. This is possible if we land here after uploading a new
			card cover because the data required to populate the mediaConfig might be missing.
		*/
		return {};
	}

	return {
		[issueId]: transformMediaDataForSingleIssue(coverMedia.attachment, coverMedia.mediaConfig),
	};
};
