import type { IssueLinksModalEntrypointApi, IssueData, ModalContent } from '../types.tsx';

export const setIssueLinkModalEntryPointSubject =
	({
		issueData,
		modalContent,
		trackOriginSource,
	}: {
		issueData: IssueData | null;
		modalContent?: ModalContent;
		trackOriginSource?: string | null;
	}) =>
	({ setState }: IssueLinksModalEntrypointApi) => {
		setState({
			issueData,
			modalContent,
			trackOriginSource,
		});
	};

export const actions = {
	setIssueLinkModalEntryPointSubject,
} as const;

export type Actions = typeof actions;
