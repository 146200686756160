import { useRef, type MutableRefObject } from 'react';

// @ts-expect-error - TS2322 - Type 'Element | null' is not assignable to type 'HTMLElement | null'.

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
export const getActiveElement = (): HTMLElement | null => document.activeElement;

export const useIccRef = () => useRef<HTMLDivElement>(null);

export const isIccIntersectingWithHeader = (
	headerRefObject: MutableRefObject<HTMLDivElement | null>,
	iccRefObject: MutableRefObject<HTMLDivElement | null>,
	isFirstCard: boolean,
): boolean => {
	// We allow a small amount of margin if the ICC is above the first card because the header is overlapping it by default
	// we still want to allow the ICC to be rendered in this case.
	const FIRST_CARD_ICC_MARGIN = 5;

	if (headerRefObject.current && iccRefObject.current) {
		const headerRect = headerRefObject.current.getBoundingClientRect();
		const iccRect = iccRefObject.current.getBoundingClientRect();

		return !(
			(isFirstCard ? headerRect.bottom - FIRST_CARD_ICC_MARGIN : headerRect.bottom) < iccRect.top
		);
	}
	return false;
};
