import { createStore, createContainer, createHook } from '@atlassian/react-sweet-state';
import { STORE_NAME } from '../../common/constants.tsx';
import type { State } from './types.tsx';
import { type Actions, actions } from './utils/actions.tsx';
import { initialState } from './utils/initial-state.tsx';

const Store = createStore<State, Actions>({
	initialState,
	actions,
	name: STORE_NAME,
});

export const InsightsStoreContainer = createContainer(Store);

export const useInsightsStoreContainer = createHook(Store);
