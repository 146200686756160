import { metrics } from '@atlassian/browser-metrics';

export const branchDetailsPopupLoad = metrics.interaction({
	key: 'board.branch-details-popup-load',
});

export const commitDetailsPopupLoad = metrics.interaction({
	key: 'board.commit-details-popup-load',
});

export const deploymentDetailsPopupLoad = metrics.interaction({
	key: 'board.deployment-details-popup-load',
});

export const prDetailsPopupLoad = metrics.interaction({
	key: 'board.pr-details-popup-load',
});

export const designDetailsPopupLoad = metrics.interaction({
	key: 'board.design-details-popup-load',
});
