import { expVal } from '@atlassian/jira-feature-experiments';
import type { Flag } from '@atlassian/jira-flags';
import { FEEDBACK_GROUP_ID } from '@atlassian/jira-project-create-importer-common/src/common/constants/index.tsx';
import type { MigrationStatus } from '@atlassian/jira-project-create-importer-common/src/common/types/index.tsx';
import {
	CONTACT_SUPPORT_URL,
	IMPORT_PARTIAL_SUCCESS_FLAG_ID,
	IMPORT_IN_PROGRESS_FLAG_ID,
	IMPORT_SUCCESS_FLAG_ID,
	IMPORT_ADVANCED_ERRORS_FLAG_ID,
} from '../../constants/index.tsx';
import messages from './messages.tsx';

type OpenFeedbackCollector = (feedbackGroupId: string) => void;

export const getSuccessFlag = (
	migrationStatus: MigrationStatus,
	projectName: string,
	openFeedbackModal: OpenFeedbackCollector,
): Flag => ({
	id: IMPORT_SUCCESS_FLAG_ID,
	type: 'success',
	isAutoDismiss: false,
	title: [messages.importSuccessFlagTitle, { projectName }],
	description: [
		expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
			? messages.importSuccessDescriptionIssueTermRefresh
			: messages.importSuccessDescription,
		{ count: migrationStatus.createdIssues },
	],
	actions: [
		{
			content: messages.refreshPage,

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			onClick: () => window.location.reload(),
		},
		{
			content: messages.giveFeedback,
			onClick: () => openFeedbackModal(FEEDBACK_GROUP_ID),
		},
	],
});

export const getAdvancedErrorFlag = ({
	projectName,
	migrationStatus,
	onDownloadLogsClick,
}: {
	migrationStatus: MigrationStatus;
	projectName: string;
	onDownloadLogsClick: () => void;
}): Flag => ({
	id: IMPORT_ADVANCED_ERRORS_FLAG_ID,
	type: 'error',
	isAutoDismiss: false,
	title: messages.importAdvancedErrorTitle,
	description: [
		expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
			? messages.importAdvancedErrorDescriptionIssueTermRefresh
			: messages.importAdvancedErrorDescription,
		{
			failed: migrationStatus.totalIssues - migrationStatus.createdIssues,
			total: migrationStatus.totalIssues,
			projectName,
		},
	],
	actions: [
		{
			content: messages.downloadLogs,
			onClick: onDownloadLogsClick,
		},
		{
			content: messages.contactSupport,
			href: CONTACT_SUPPORT_URL,
			target: '_blank',
		},
	],
});

export const getPartialSuccessFlag = (
	migrationStatus: MigrationStatus,
	openFeedbackModal: OpenFeedbackCollector,
): Flag => ({
	id: IMPORT_PARTIAL_SUCCESS_FLAG_ID,
	type: 'info',
	isAutoDismiss: false,
	title: messages.importPartialSuccessTitle,
	description: messages.importPartialSuccessDescription,
	actions: [
		{
			content: messages.contactSupport,
			href: CONTACT_SUPPORT_URL,
			target: '_blank',
		},
		{
			content: messages.giveFeedback,
			onClick: () => openFeedbackModal(FEEDBACK_GROUP_ID),
		},
	],
});

export const getInProgressFlag = (): Flag => ({
	id: IMPORT_IN_PROGRESS_FLAG_ID,
	type: 'info',
	isAutoDismiss: false,
	title: messages.importingInProgressTitle,
	description: messages.importingInProgressDescription,
});
