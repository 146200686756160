import React, { useMemo, type ComponentType } from 'react';
import {
	CONTEXT_TOKEN_TYPE_JIRA,
	BOARD_ACTION_MODULE,
} from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
import type { BoardActionExtensionData } from '@atlassian/jira-forge-ui-types/src/common/types/extension-data.tsx';
import type {
	BoardAction,
	ExtensionNextProps,
} from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import type { BoardActionModule } from '@atlassian/jira-forge-ui-types/src/common/types/module.tsx';
import type { ForgeUIBoardActionExtensionProps } from '@atlassian/jira-forge-ui/src/common/ui/extension/components/board-view/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { LazyBoardViewForgeExtension } from '../../common/ui/extension/board-view/index.tsx';
import type { LazyComponentProps } from '../../types.tsx';

type Props = ExtensionNextProps<BoardActionModule, BoardAction, BoardActionExtensionData> &
	ForgeUIBoardActionExtensionProps;

export const AsyncBoardAction = (props: LazyComponentProps<ComponentType<Props>>) => {
	const { extensionData } = props;
	const extensionDataValue = useMemo(
		() => ({
			...extensionData,
			type: BOARD_ACTION_MODULE,
		}),
		[extensionData],
	);
	const extraProps = useMemo(
		() => ({
			module: BOARD_ACTION_MODULE,
			extensionData: extensionDataValue,
			contextToken: CONTEXT_TOKEN_TYPE_JIRA,
		}),
		[extensionDataValue],
	);

	return (
		<Placeholder name="board-action" fallback={null}>
			<LazyBoardViewForgeExtension {...props} {...extraProps} />
		</Placeholder>
	);
};
