import type { VersionData } from '../../../model/version/version-types.tsx';

export const FETCH_VERSION_DATA = 'state.actions.version.FETCH_VERSION_DATA';

export type FetchVersionDataAction = {
	type: typeof FETCH_VERSION_DATA;
};

export const fetchVersionData = (): FetchVersionDataAction => ({
	type: FETCH_VERSION_DATA,
});

export const SET_VERSION_DATA = 'state.actions.version.SET_VERSION_DATA';

export type SetVersionDataAction = {
	type: typeof SET_VERSION_DATA;
	payload: VersionData;
};

export const setVersionData = (payload: VersionData): SetVersionDataAction => ({
	type: SET_VERSION_DATA,
	payload,
});

export type Action = FetchVersionDataAction | SetVersionDataAction;
