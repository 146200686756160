import React, { useCallback, useMemo } from 'react';
import noop from 'lodash/noop';
import { useIntl } from '@atlassian/jira-intl';
import type { AnalyticsAttributes } from '@atlassian/jira-product-analytics-bridge';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { IssueTypeGroup, CheckboxSelectEntry } from '../../../common/types.tsx';
import ListFilter from '../../../common/ui/list/index.tsx';
import messages from './messages.tsx';

export type Props = {
	isOpen?: boolean;
	issueTypeGroups: IssueTypeGroup[];
	selectedIssueTypes?: IssueTypeId[];
	onChange: (ids: IssueTypeId[], attributes?: AnalyticsAttributes) => void;
	isNested?: boolean;
};

const IssueTypeGroupFilter = ({
	isOpen = false,
	issueTypeGroups = [],
	selectedIssueTypes = [],
	onChange = noop,
	isNested = false,
}: Props) => {
	const { formatMessage } = useIntl();

	const issueTypeGroupByIssueType = useMemo(
		() =>
			new Map(
				issueTypeGroups.flatMap((group) => group.issueTypes.map((issueType) => [issueType, group])),
			),
		[issueTypeGroups],
	);

	const issueTypeGroupByGroupKey = useMemo(
		() => new Map(issueTypeGroups.map((group) => [group.key, group])),
		[issueTypeGroups],
	);

	const values = useMemo(
		(): CheckboxSelectEntry[] =>
			issueTypeGroups.map((group) => ({
				label: group.name,
				value: group.key,
				iconUrl: group.iconUrl,
			})),
		[issueTypeGroups],
	);

	const selectGroupsByKeys = useCallback(
		(keys: string[]) => {
			const analyticAttributes = { isNested };
			onChange(
				keys.flatMap((key) => issueTypeGroupByGroupKey.get(key)?.issueTypes).filter(Boolean),
				analyticAttributes,
			);
		},
		[onChange, issueTypeGroupByGroupKey, isNested],
	);

	const selectedGroups = useMemo(
		() => Array.from(new Set(selectedIssueTypes.map((key) => issueTypeGroupByIssueType.get(key)))),
		[issueTypeGroupByIssueType, selectedIssueTypes],
	);

	const selectedGroupKeys = selectedGroups.map((group) => group?.key).filter(Boolean);

	const selectedGroupsAllIssueTypes = selectedGroups
		.flatMap((group) => group?.issueTypes)
		.filter(Boolean);

	const onChangeCallback = useCallback(
		(entries: CheckboxSelectEntry[]) => {
			const entryValues = entries.map(({ value }) => value);
			selectGroupsByKeys(entryValues);
		},
		[selectGroupsByKeys],
	);

	// early exit if we have only one issue type group
	if (issueTypeGroups.length <= 1) {
		return null;
	}

	// if there's mismatch between the filters in the url and the issue groups, e.g. when the url is manually edited or when an issue of a new type is created, we would apply the whole group
	if (selectedGroupsAllIssueTypes.length !== selectedIssueTypes.length) {
		const analyticAttributes = { isNested };
		onChange(selectedGroupsAllIssueTypes, analyticAttributes);
	}

	return (
		<ListFilter
			isOpen={isOpen}
			label={formatMessage(messages.label)}
			values={values}
			selectedValues={selectedGroupKeys}
			onChange={onChangeCallback}
			isNested={isNested}
		/>
	);
};

IssueTypeGroupFilter.displayName = 'IssueTypeGroupFilter';
IssueTypeGroupFilter.whyDidYouRender = true;

export type IssueTypeGroupFilterProps = Props;
export default IssueTypeGroupFilter;
