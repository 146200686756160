export const PRIORITY_ICON_TEST_ID =
	'software-board.board-container.board.card-container.card-lite.priority-icon.wrapper';

export const CMP_AVATAR_TEST_ID =
	'software-board.board-container.board.card-container.card-lite.cmp-avatar';

export const TMP_AVATAR_TEST_ID =
	'software-board.board-container.board.card-container.card-lite.tmp-avatar';

export const DEV_STATUS_PLACEHOLDER_TEST_ID =
	'software-board.board-container.board.card-container.card-lite.dev-status-placeholder';
