import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import type { Filters } from '@atlassian/jira-software-filters/src/common/types.tsx';

const localStorage = createLocalStorageProvider('software');

type Feature = 'board' | 'backlog';
const CACHED_FILTERS_KEY = 'filters';

const getKey = (feature: Feature, projectId: number | string) =>
	`${feature}-${CACHED_FILTERS_KEY}.${projectId}`;

export function getCachedFilters(
	feature: Feature,
	projectId: number | string,
): Filters | undefined {
	return localStorage.get(getKey(feature, projectId)) || undefined;
}

export function setCachedFilters(
	feature: Feature,
	projectId: number | string,
	filters: Filters | undefined | null,
) {
	if (filters && Object.entries(filters).length > 0) {
		localStorage.set(getKey(feature, projectId), filters);
	} else {
		localStorage.remove(getKey(feature, projectId));
	}
}
