import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	ariaLabel: {
		id: 'software.main.header.sprint-controls.sprint-details.ariaLabel',
		// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
		defaultMessage: 'Popup with details about the current active sprints',
		description:
			'aria label describing the sprint details popup. This may be read by assistive tools.',
	},
});
