import React, { type MouseEvent, type FocusEvent, type RefObject } from 'react';
import { Pressable, xcss } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { ExternalAction } from '@atlassian/jira-issue-view-store/src/actions/external-actions.tsx';
import messages from './messages.tsx';

type Props = {
	onClick?: (arg1: MouseEvent<HTMLElement>, arg2?: ExternalAction) => void;
	onMouseEnter?: (arg1: MouseEvent<HTMLElement>) => void;
	onMouseLeave?: (arg1: MouseEvent<HTMLElement>) => void;
	onFocus?: (arg1: FocusEvent<HTMLElement>) => void;
	onBlur?: (arg1: FocusEvent<HTMLElement>) => void;
	cardRef?: RefObject<HTMLButtonElement>;
	cardKey?: string | null;
	summary: string;
};

export const InteractionLayer = ({
	onClick,
	onMouseEnter,
	onMouseLeave,
	onFocus,
	onBlur,
	cardRef,
	cardKey,
	summary,
}: Props) => {
	const { formatMessage } = useIntl();

	const handleClick = (e: MouseEvent<HTMLElement>) => onClick && onClick(e);

	return (
		<Pressable
			// eslint-disable-next-line jira/integration/test-id-by-folder-structure
			testId="platform-card.ui.card.focus-container"
			aria-label={formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? messages.interactionLayerLabelIssueTermRefresh
					: messages.interactionLayerLabel,
				{
					issueKey: cardKey ?? '',
					issueSummary: summary,
				},
			)}
			onClick={handleClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			onFocus={onFocus}
			onBlur={onBlur}
			ref={cardRef}
			xcss={interactionLayerStyles}
		/>
	);
};

const interactionLayerStyles = xcss({
	all: 'unset',
	pointerEvents: 'auto',
	position: 'absolute',
	left: 'space.0',
	right: 'space.0',
	top: 'space.0',
	bottom: 'space.0',
	borderRadius: 'border.radius',
	':focus': {
		outlineOffset: 'space.0',
	},
	':focus-visible': {
		outlineOffset: 'space.0',
	},
	':focus-within': {
		outlineOffset: 'space.0',
	},
});
