import { Component, type ReactNode } from 'react';
import flow from 'lodash/flow';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type Client from '@atlaskit/pubsub';
import { AnalyticsEventToProps } from '@atlassian/jira-product-analytics-bridge';
import type { RealtimeEventType } from '../../common/types/events.tsx';
import type { EventPayload } from '../../common/types/payloads.tsx';
import { asClientOnly } from './as-client-only/index.tsx';
import { withPubSubClient } from './with-pubsub-client/index.tsx';

type Props = {
	events: string[];
	client: Client | null;
	children: ReactNode;
	onReceive: (arg1: RealtimeEventType, arg2: EventPayload, arg3: UIAnalyticsEvent) => void;
};

// eslint-disable-next-line jira/react/no-class-components
class RealtimeClientView extends Component<Props> {
	static defaultProps = {
		children: null,
	};

	componentDidMount() {
		this.subscribeToPropEvents();
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.client !== prevProps.client || this.props.events !== prevProps.events) {
			const removedEvents = prevProps.events.filter(
				(prevEvent) => !this.props.events.includes(prevEvent),
			);

			removedEvents.forEach(this.unSubscribeToEvent);

			this.subscribeToPropEvents();
		}
	}

	componentWillUnmount() {
		this.unsubscribeToPropEvents();
	}

	onReceiveEvent = (event: unknown, payload: unknown) => {
		// @ts-expect-error - TS2554 - Expected 3 arguments, but got 2.
		this.props.onReceive(event, payload);
	};

	subscribeToEvent = (eventName: string) => {
		this.props.client?.on(eventName, this.onReceiveEvent);
	};

	unSubscribeToEvent = (eventName: string) => {
		this.props.client?.off(eventName, this.onReceiveEvent);
	};

	subscribeToPropEvents = () => {
		if (this.props.client) {
			this.props.events.forEach(this.subscribeToEvent);
		}
	};

	unsubscribeToPropEvents = () => {
		if (this.props.client) {
			this.props.events.forEach(this.unSubscribeToEvent);
		}
	};

	render() {
		return this.props.children;
	}
}

const withAnalytics = AnalyticsEventToProps('realtimeClient', {
	onReceive: 'eventReceived',
});

export const RealtimeClient = flow(
	withAnalytics,
	withPubSubClient,
	asClientOnly,
)(RealtimeClientView);
export default RealtimeClient;
