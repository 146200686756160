import flow from 'lodash/flow';
import memoize from 'lodash/memoize';
import type { SwimlaneModeId } from '@atlassian/jira-platform-board-kit/src/ui/swimlane/types.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { CAN_EDIT_BOARD } from '../../../../model/permission/permission-types.tsx';
import { swimlaneModeChangeRequest } from '../../../../state/actions/swimlane/index.tsx';
import { getPermissionsSelector } from '../../../../state/selectors/board/board-permissions-selectors.tsx';
import {
	getIsCMPBoard,
	rapidViewIdSelector,
	projectIdSelector,
} from '../../../../state/selectors/software/software-selectors.tsx';
import {
	getSwimlaneModes,
	getSwimlaneMode,
} from '../../../../state/selectors/swimlane/swimlane-mode-selectors.tsx';
import type { State, Dispatch } from '../../../../state/types.tsx';
import SwimlaneSwitch from './view.tsx';

const getExternalValues = memoize(
	(boardId, projectId) => ({
		boardId,
		projectId,
	}),
	(boardId, projectId) => `${boardId}.${projectId}`,
);

const connector = connect(
	(state: State) => ({
		externalId: 'software.board.swimlane',
		externalValues: getExternalValues(rapidViewIdSelector(state), projectIdSelector(state)),
		swimlaneModes: getSwimlaneModes(state),
		currentSwimlaneMode: getSwimlaneMode(state),
		isUserProjectAdmin: getPermissionsSelector(state)[CAN_EDIT_BOARD] && !getIsCMPBoard(state),
	}),
	(dispatch: Dispatch) => ({
		setSwimlaneMode: (swimlaneModeId: SwimlaneModeId) => {
			dispatch(swimlaneModeChangeRequest(swimlaneModeId));
		},
	}),
	(stateProps, dispatchProps, ownProps) => {
		const { isUserProjectAdmin, ...restStateProps } = stateProps;
		return {
			...ownProps,
			...dispatchProps,
			...restStateProps,
		};
	},
);

export default flow(connector)(SwimlaneSwitch);
