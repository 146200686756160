import { useCallback } from 'react';
import uuid from 'uuid';
import { useExperienceFail } from '@atlassian/jira-experience-tracker/src/ui/experience-fail/index.tsx';
import { useExperienceStart } from '@atlassian/jira-experience-tracker/src/ui/experience-start/index.tsx';
import { useExperienceSuccess } from '@atlassian/jira-experience-tracker/src/ui/experience-success/index.tsx';
import {
	isClientFetchError,
	isHttpClientErrorResponse,
} from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import {
	extractErrorMessage,
	extractErrorName,
	extractStatusCode,
	extractTraceId,
	getBuildKey,
} from './utils.tsx';

const analyticsSource = 'ProjectCreateImporter';
type Attributes = {
	readonly [key: string]: string | number | boolean | null;
};

type UseExperienceTracker = [
	() => void,
	(extraAttributes?: Attributes) => void,
	(location: string, error: Error, extraAttributes?: Attributes) => void,
];

export const useExperienceTracker = (experience: string): UseExperienceTracker => {
	const onExperienceStart = useExperienceStart({
		experienceId: uuid.v4(),
		experience,
		analyticsSource,
	});

	const onExperienceSuccess = useExperienceSuccess({
		experience,
		attributes: {},
	});

	const onFail = useExperienceFail({
		experience,
		attributes: {},
	});

	const onExperienceFail = useCallback(
		(location: string, error: Error, extraAttributes?: Attributes) => {
			if (isClientFetchError(error) || isHttpClientErrorResponse(error)) {
				onExperienceSuccess();
				return;
			}

			onFail(location, error, {
				errorName: extractErrorName(error),
				errorMessage: extractErrorMessage(error),
				statusCode: extractStatusCode(error),
				traceId: extractTraceId(error),
				buildKey: getBuildKey(),
				...(extraAttributes || {}),
			});
		},
		[onExperienceSuccess, onFail],
	);

	return [onExperienceStart, onExperienceSuccess, onExperienceFail];
};
