import { WORK_DATA_SET, WORK_DATA_CRITICAL_SET } from '../../../../actions/work/index.tsx';
import type { Action } from '../../../../types.tsx';
import type { HasFilteredIssueState } from './types.tsx';

export const hasFilteredIssuesReducer = (
	state: HasFilteredIssueState = false,
	action: Action,
): HasFilteredIssueState => {
	switch (action.type) {
		case WORK_DATA_SET:
		case WORK_DATA_CRITICAL_SET:
			return action.payload.hasFilteredIssues;
		default:
			return state;
	}
};
