import React from 'react';
import flatMap from 'lodash/flatMap';
import memoizeOne from 'memoize-one';
import uuid from 'uuid';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { getNormalisedPerformanceFFs } from '@atlassian/jira-common-long-task-metrics/src/common/util/collectors.tsx';
import {
	AnalyticsEventToProps,
	fireTrackAnalytics,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import {
	type ClearCardsFunction,
	useClearCards,
} from '@atlassian/jira-software-board-clearing/src/controllers/clear/index.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import { scheduleAfterRender } from '@atlassian/jira-software-react-scheduler/src/index.tsx';
import type { IssueArray } from '../../../../../../../model/issue/issue-types.tsx';
import type { RapidViewId } from '../../../../../../../model/software/software-types.tsx';
import { SWIMLANE_BY_SUBTASK } from '../../../../../../../model/swimlane/swimlane-modes.tsx';
import {
	cardsClearInteraction,
	cardsUnclearInteraction,
} from '../../../../../../../services/utils/performance-analytics/index.tsx';
import { getResolvedIssuesUrl } from '../../../../../../../services/work/work-links.tsx';
import {
	cardClear,
	cardClearFailure,
	cardClearSuccess,
	cardClearUndo,
} from '../../../../../../../state/actions/card/index.tsx';
import {
	getColumnById,
	getColumnIndex,
	isDoneColumn,
} from '../../../../../../../state/selectors/column/column-selectors.tsx';
import { getChildrenOfParent } from '../../../../../../../state/selectors/issue-children/index.tsx';
import {
	getDoneIssuesInColumn,
	makeDoesColumnContainDoneCardsSelector,
} from '../../../../../../../state/selectors/issue/issue-selectors.tsx';
import {
	contextPathSelector,
	getIsCMPBoard,
	projectKeySelector,
	rapidViewIdSelector,
} from '../../../../../../../state/selectors/software/software-selectors.tsx';
import { isSprintsEnabled } from '../../../../../../../state/selectors/sprint/sprint-selectors.tsx';
import { getSwimlaneMode } from '../../../../../../../state/selectors/swimlane/swimlane-mode-selectors.tsx';
import { workColumnsSelector } from '../../../../../../../state/selectors/work/work-selectors.tsx';
import type { Dispatch, State } from '../../../../../../../state/types.tsx';
import ItemClearAll from './view.tsx';

type OwnProps = {
	isDisabled: boolean;
	columnId: number;
	clearCards: ClearCardsFunction;
	onClick: (event: MouseEvent, analyticsEvent: UIAnalyticsEvent) => void;
};

type StateProps = {
	isVisible: boolean;
	isDisabled: boolean;
	isLastColumn: boolean;
	doneCards: IssueArray;
	doneCardsChildren: IssueId[];
	boardId: RapidViewId;
	resolvedIssuesUrl: string;
	columnIndex: number;
	numColumnsOnBoard: number;
};

type Props = {
	isDisabled: boolean;
	isVisible: boolean;
	numDoneCards: number;
	onClick: (event: MouseEvent, analyticsEvent: UIAnalyticsEvent) => void;
};

export const mapStateToProps = () => {
	const doneCardsChildrenFactory = memoizeOne((doneCards: IssueArray, state: State) =>
		flatMap(doneCards, (issue) => getChildrenOfParent(state)(issue.id)),
	);
	return (state: State, ownProps: OwnProps): StateProps => {
		const { columnId } = ownProps;
		const doneCards: IssueArray = getDoneIssuesInColumn(state)(columnId);
		const boardId = rapidViewIdSelector(state);
		const resolvedIssuesUrl = getResolvedIssuesUrl(
			contextPathSelector(state),
			projectKeySelector(state),
		);
		const columnIndex = getColumnIndex(state, columnId);
		const column = getColumnById(state, columnId);
		const isLastColumn = isDoneColumn(column);
		const numColumnsOnBoard = workColumnsSelector(state).length;
		// Indirectly these sub-tasks will be cleared, so we'll collect them to perform the optimistic
		// update
		const doneCardsChildren: IssueId[] = doneCardsChildrenFactory(doneCards, state);

		const isVisible =
			!isSprintsEnabled(state) &&
			makeDoesColumnContainDoneCardsSelector(state)(columnId) &&
			!getIsCMPBoard(state);

		const isSubtaskSwimlaneModeSelected = getSwimlaneMode(state) === SWIMLANE_BY_SUBTASK.id;
		const isDisabled = ownProps.isDisabled || isSubtaskSwimlaneModeSelected;

		return {
			isVisible,
			isDisabled,
			isLastColumn,
			doneCards,
			doneCardsChildren,
			boardId,
			resolvedIssuesUrl,
			columnIndex,
			numColumnsOnBoard,
		};
	};
};
type DispatchProps = {
	dispatch: Dispatch;
};
export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	dispatch,
});

const onClickFactory = memoizeOne(
	(
		doneCards: StateProps['doneCards'],
		doneCardsChildren: StateProps['doneCardsChildren'],
		dispatch: Dispatch,
		onClick: OwnProps['onClick'],
		isLastColumn: StateProps['isLastColumn'],
		columnIndex: StateProps['columnIndex'],
		numColumnsOnBoard: StateProps['numColumnsOnBoard'],
		numDoneCards: number,
		numDoneCardsChildren: number,
		clearCards: OwnProps['clearCards'],
		boardId: StateProps['boardId'],
		resolvedIssuesUrl: StateProps['resolvedIssuesUrl'],
	) =>
		(event: MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
			const concurrentId = uuid();
			cardsClearInteraction(concurrentId).start();
			const doneCardIds = doneCards.map((card) => card.id);

			const action = cardClear(doneCardIds, doneCardsChildren);
			const {
				meta: {
					optimistic: { id: optimisticId },
				},
			} = action;

			dispatch(action);
			scheduleAfterRender(() => {
				cardsClearInteraction(concurrentId).mark('feedback');
			});

			onClick(event, analyticsEvent);
			fireUIAnalytics(analyticsEvent, 'clearIssueDropdownMenuItem', {
				isLastColumn,
				columnIndex,
				numColumnsOnBoard,
				numParentIssues: numDoneCards,
				numSubtasks: numDoneCardsChildren,
			});

			clearCards({
				boardId,
				cardIds: doneCardIds,
				resolvedIssuesUrl,
				onUndo: (undoPromise, retry) => {
					const undoConcurrentId = uuid();
					cardsUnclearInteraction(undoConcurrentId).start();
					dispatch(cardClearUndo(undoPromise, retry, optimisticId));
					scheduleAfterRender(() => {
						cardsUnclearInteraction(undoConcurrentId).mark('feedback');
					});
					undoPromise
						.then(() => {
							fireTrackAnalytics(analyticsEvent, 'issues uncleared', 'issuesUncleared', {
								numParentIssues: numDoneCards,
								numSubtasks: numDoneCardsChildren,
							});
							scheduleAfterRender(() => {
								cardsUnclearInteraction(undoConcurrentId).stop({
									customData: getNormalisedPerformanceFFs(),
								});
							});
						})
						.catch(() => {
							fireTrackAnalytics(analyticsEvent, 'issues unclearFailed');
						});
				},
				onUndoFlagDismissed: () => {
					dispatch(cardClearSuccess(optimisticId));
				},
			})
				.then(() => {
					fireTrackAnalytics(analyticsEvent, 'issues cleared', 'issuesCleared', {
						numParentIssues: numDoneCards,
						numSubtasks: numDoneCardsChildren,
					});
					scheduleAfterRender(() => {
						cardsClearInteraction(concurrentId).stop({
							customData: getNormalisedPerformanceFFs(),
						});
					});
				})
				.catch(() => {
					dispatch(cardClearFailure(optimisticId));
					fireTrackAnalytics(analyticsEvent, 'issues clearFailed');
				});
		},
);

export const mergeProps = (
	stateProps: StateProps,
	dispatchProps: DispatchProps,
	ownProps: OwnProps,
) => {
	const { onClick, clearCards } = ownProps;
	const { dispatch } = dispatchProps;
	const {
		isVisible,
		isDisabled,
		doneCards,
		boardId,
		doneCardsChildren,
		isLastColumn,
		columnIndex,
		numColumnsOnBoard,
		resolvedIssuesUrl,
	} = stateProps;
	const numDoneCards = doneCards.length;
	const numDoneCardsChildren = doneCardsChildren.length;

	return {
		isVisible,
		numDoneCards,
		isDisabled,
		onClick: onClickFactory(
			doneCards,
			doneCardsChildren,
			dispatch,
			onClick,
			isLastColumn,
			columnIndex,
			numColumnsOnBoard,
			numDoneCards,
			numDoneCardsChildren,
			clearCards,
			boardId,
			resolvedIssuesUrl,
		),
	};
};

const ItemClearAllWithAnalytics = AnalyticsEventToProps('dropdownMenuItem', {
	onClick: 'clicked',
})(ItemClearAll);

const HideableItem = ({ isVisible, onClick, numDoneCards, isDisabled }: Props) =>
	isVisible ? (
		<ItemClearAllWithAnalytics
			onClick={onClick}
			numDoneCards={numDoneCards}
			isDisabled={isDisabled}
		/>
	) : null;

const ConnectedClearMenuItem = connect(
	mapStateToProps,
	mapDispatchToProps,
	mergeProps,
)(HideableItem);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: { columnId: number; onClick: () => void; isDisabled: boolean }) => {
	const [clearCards] = useClearCards();

	return <ConnectedClearMenuItem {...props} clearCards={clearCards} />;
};
