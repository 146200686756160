import type { BoardGQLExtension } from '@atlassian/jira-software-board-fetch-scope-critical/src/types.tsx';
import type { Extension, Operation } from '../../model/extension/types.tsx';

export const transformOperation = (boardGQLExtension: BoardGQLExtension): Extension => {
	const operations: Operation[] = boardGQLExtension.extension
		? boardGQLExtension.extension.operations.map((op) => ({
				icon: { url: op.icon && op.icon.url },
				name: op.name,
				url: op.url,
				tooltip: op.tooltip,
				styleClass: op.styleClass,
			}))
		: [];

	return {
		operations,
	};
};
