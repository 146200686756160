import get from 'lodash/get';
import { INITIAL_MIGRATION_STATUS } from '../../common/constants/index.tsx';
import { type DetailedProgress, type MigrationStatus, Status } from '../../common/types/index.tsx';
import { type ContainerTransferStatus, type Transfer, TransferStatus } from './types.tsx';

const JIRA_IMPORT_OPERATION_KEY = 'jiraImport';
const JIRA_IMPORT_V4_OPERATION_KEY = 'jiraX2CProjectImport';
const JIRA_DATA_TRANSFORM_OPERATION_KEY = 'jiraDataTransform';
const ALL_USER_IMPORT_OPERATION_KEY = 'allUserImport';
const PROJECT_ENTITY_KEY = 'jira/classic:project';
const ISSUE_ENTITY_KEY = 'jira:issue';

const PROGRESS_WEIGHTING: { [operationKey: string]: number } = {
	jiraImport: 0.5,
	allUserImport: 0.3,
	jiraProjectFinalise: 0.1,
	jiraDataTransfer: 0.1,
};

const isFailedStatus = (status: TransferStatus) =>
	status === TransferStatus.FAILED ||
	status === TransferStatus.INCOMPLETE ||
	status === TransferStatus.CANCELLED ||
	status === TransferStatus.SKIPPED;

const getCreatedJiraImportCountForEntity = (transfer: Transfer, entityKey: string): number =>
	get(
		transfer,
		['progressProperties', 'completedEntityCounts', entityKey, 'completed', 'created'],
		0,
	);

const getFailedJiraImportCountForEntity = (transfer: Transfer, entityKey: string): number =>
	get(
		transfer,
		['progressProperties', 'completedEntityCounts', entityKey, 'completed', 'failed'],
		0,
	);

const getTotalJiraImportCountForEntity = (transfer: Transfer, entityKey: string): number =>
	get(transfer, ['progressProperties', 'completedEntityCounts', entityKey, 'totalEntities'], 0);

export const getIssueStats = (containerTransferStatus: ContainerTransferStatus) => {
	const jiraImportTransfer = containerTransferStatus.transfers?.find(
		(transfer) =>
			transfer.operationKey === JIRA_IMPORT_OPERATION_KEY ||
			transfer.operationKey === JIRA_IMPORT_V4_OPERATION_KEY,
	);

	if (!jiraImportTransfer) {
		return {
			createdIssues: 0,
			totalIssues: 0,
		};
	}

	return {
		createdIssues: getCreatedJiraImportCountForEntity(jiraImportTransfer, ISSUE_ENTITY_KEY),
		totalIssues: getTotalJiraImportCountForEntity(jiraImportTransfer, ISSUE_ENTITY_KEY),
	};
};

const transformTranferStatusToStatus = (transferStatus: TransferStatus): Status => {
	if (isFailedStatus(transferStatus)) return Status.FAILED;
	if (transferStatus === TransferStatus.SUCCESS) return Status.SUCCESS;
	if (transferStatus === TransferStatus.IN_PROGRESS) return Status.IN_PROGRESS;

	return Status.READY;
};

const getStatusForOperation = (
	containerTransferStatus: ContainerTransferStatus,
	operationKey: string,
): Status => {
	const transfer = containerTransferStatus.transfers?.find((t) => t.operationKey === operationKey);

	return transfer?.businessStatus
		? transformTranferStatusToStatus(transfer.businessStatus)
		: Status.READY;
};

const getProjectCreateStatus = (containerTransferStatus: ContainerTransferStatus): Status => {
	const jiraImportTransfer = containerTransferStatus.transfers?.find(
		(transfer) =>
			transfer.operationKey === JIRA_IMPORT_OPERATION_KEY ||
			transfer.operationKey === JIRA_IMPORT_V4_OPERATION_KEY,
	);

	if (!jiraImportTransfer) return Status.READY;

	const createdProjectEntities = getCreatedJiraImportCountForEntity(
		jiraImportTransfer,
		PROJECT_ENTITY_KEY,
	);
	const totalProjectEntities = getTotalJiraImportCountForEntity(
		jiraImportTransfer,
		PROJECT_ENTITY_KEY,
	);
	const failedProjectEntities = getFailedJiraImportCountForEntity(
		jiraImportTransfer,
		PROJECT_ENTITY_KEY,
	);

	if (failedProjectEntities !== 0) return Status.FAILED;
	if (createdProjectEntities === 0) return Status.READY;
	if (createdProjectEntities === totalProjectEntities) return Status.SUCCESS;

	return Status.IN_PROGRESS;
};

export const getDetailedProgress = (
	containerTransferStatus: ContainerTransferStatus,
): DetailedProgress => ({
	dataTransformStatus: getStatusForOperation(
		containerTransferStatus,
		JIRA_DATA_TRANSFORM_OPERATION_KEY,
	),
	userImportStatus: getStatusForOperation(containerTransferStatus, ALL_USER_IMPORT_OPERATION_KEY),
	projectCreateStatus: getProjectCreateStatus(containerTransferStatus),
});

const getProgressForTransfer = (transfer: Transfer) => {
	if (transfer.businessStatus === TransferStatus.SUCCESS) {
		return 1;
	}

	if (transfer.businessStatus === TransferStatus.READY) {
		return 0;
	}

	if (transfer.progressProperties === undefined || transfer.progressProperties === null) {
		return 0;
	}

	const { createdEntities, totalEntities } = transfer.progressProperties;

	if (createdEntities === undefined || totalEntities === undefined) {
		return 0;
	}

	return createdEntities / totalEntities;
};

const calculateProgress = (containerTransferStatus: ContainerTransferStatus) => {
	let progress = 0;

	containerTransferStatus.transfers?.forEach((transfer) => {
		const weighting = PROGRESS_WEIGHTING[transfer.operationKey] || 0;
		progress += getProgressForTransfer(transfer) * weighting;
	});

	return progress;
};

export const calculateMigrationStatus = (
	containerTransferStatus: ContainerTransferStatus,
): MigrationStatus => {
	if (containerTransferStatus.businessStatus === TransferStatus.READY) {
		return INITIAL_MIGRATION_STATUS;
	}

	const issueStats = getIssueStats(containerTransferStatus);
	const detailedProgress = getDetailedProgress(containerTransferStatus);

	if (containerTransferStatus.businessStatus === TransferStatus.SUCCESS) {
		return {
			overallProgress: 1,
			overallStatus: Status.SUCCESS,
			detailedProgress,
			...issueStats,
		};
	}

	const progress = calculateProgress(containerTransferStatus);

	if (isFailedStatus(containerTransferStatus.businessStatus)) {
		const migrationFailedStatus = {
			overallProgress: progress,
			overallStatus: Status.FAILED,
			detailedProgress,
			...issueStats,
		};

		return {
			...migrationFailedStatus,
			hasErrorLogs: containerTransferStatus.hasErrorLogs,
		};
	}

	return {
		overallProgress: progress,
		overallStatus: Status.IN_PROGRESS,
		detailedProgress,
		...issueStats,
	};
};

export const isRetryable = (error: { statusCode?: number; name?: string }): boolean =>
	error?.statusCode === 429 || error?.statusCode === 503 || error?.name === 'NetworkError';

const waitTimeMs = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const getPollingWaitTime = (
	baseIntervalMs: number,
	maxPollingIntervalMs: number,
	exponentialBase: number,
	retryCount: number,
) => {
	const time = baseIntervalMs * exponentialBase ** retryCount;
	return time > maxPollingIntervalMs ? maxPollingIntervalMs : time;
};

export type ShouldKeepPolling = boolean;

export const withExponentialBackoff = (
	callback: () => Promise<ShouldKeepPolling>,
	baseIntervalMs: number,
	maxPollingInterval: number,
	exponentialBase: number,
	retryCount = 0,
): Promise<boolean> =>
	callback().then(
		(shouldRetry) =>
			shouldRetry &&
			waitTimeMs(
				getPollingWaitTime(baseIntervalMs, maxPollingInterval, exponentialBase, retryCount),
			).then(() =>
				withExponentialBackoff(
					callback,
					baseIntervalMs,
					maxPollingInterval,
					exponentialBase,
					retryCount + 1,
				),
			),
	);
