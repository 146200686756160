// eslint-disable-next-line jira/restricted/react-component-props
import type { ComponentProps } from 'react';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { ApplicationEdition } from '@atlassian/jira-shared-types/src/edition.tsx';
import type { PanelType } from '../../../model/panels/types.tsx';
import { togglePanel, type TogglePanelAction } from '../../../state/actions/panels/index.tsx';
import { getIsHeaderCompactMode } from '../../../state/selectors/board/board-selectors.tsx';
import { getInsightsPanel } from '../../../state/selectors/panels/index.tsx';
import type { State } from '../../../state/types.tsx';
import { InsightsButton } from './view.tsx';

type OwnProps = {
	boardId: number;
	edition: ApplicationEdition | null;
	isSprintsEnabled: boolean;
};

type DispatchProps = {
	onToggleInsightsPanel: (arg1: PanelType) => TogglePanelAction;
};
type StateProps = Flow.Diff<ComponentProps<typeof InsightsButton>, DispatchProps>;

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
	isInsightsPanelOpen: getInsightsPanel(state),
	boardId: ownProps.boardId,
	edition: ownProps?.edition,
	isSprintsEnabled: ownProps.isSprintsEnabled,
	isHeaderCompactMode: getIsHeaderCompactMode(state),
});

const mapDispatchToProps = {
	onToggleInsightsPanel: togglePanel,
} as const;

export default connect(mapStateToProps, mapDispatchToProps)(InsightsButton);
