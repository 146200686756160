import { NO_SWIMLANE } from '../../../../../model/swimlane/swimlane-modes.tsx';
import type { Action as SoftwareActions } from '../../../../actions/index.tsx';
import {
	type SwimlaneModeChangeRequestAction,
	SWIMLANE_MODE_CHANGE_REQUEST,
} from '../../../../actions/swimlane/index.tsx';
import {
	type WorkDataSetAction,
	type WorkDataCriticalSetAction,
	WORK_DATA_SET,
	WORK_DATA_CRITICAL_SET,
} from '../../../../actions/work/index.tsx';
import type { ModeState } from './types.tsx';

export const swimlaneModeReducer = (
	state: ModeState = NO_SWIMLANE.id,
	action: SoftwareActions,
): ModeState => {
	if (action.type === SWIMLANE_MODE_CHANGE_REQUEST) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const { payload } = action as SwimlaneModeChangeRequestAction;
		return payload;
	}

	if (action.type === WORK_DATA_SET || action.type === WORK_DATA_CRITICAL_SET) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const { payload } = action as WorkDataSetAction | WorkDataCriticalSetAction;
		return payload.swimlaneModeId;
	}

	return state;
};
