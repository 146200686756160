export const initialState = {
	status: {
		hasFailed: false,
		hasCompleted: false,
		hasStarted: false,
		hasTimedOut: false,
	},
	timeoutId: null,
	eventsSuccess: [],
	eventsFail: [],
} as const;
