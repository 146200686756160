export const SET_JQL = 'state.actions.jql.SET_JQL' as const;
export type SetJql = {
	type: typeof SET_JQL;
	payload: {
		jql: string | undefined;
	};
};
export const setJql = (jql: string): SetJql => ({
	type: SET_JQL,
	payload: {
		jql,
	},
});

export type Action = SetJql;
