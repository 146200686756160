import React, { useState, useCallback } from 'react';
import Button from '@atlaskit/button/new';
import Popup from '@atlaskit/popup';
import VisuallyHidden from '@atlaskit/visually-hidden';
import { useIntl } from '@atlassian/jira-intl';
import disabledButtonContent from './disabled-button-content/index.tsx';
import { messages } from './messages.tsx';

type Props = {
	children: React.ReactNode;
};

const A11Y_HELPER_TEXT_ID =
	'software-backlog.card-list.common.sprint-actions.disabled-button-helper.disabled-button-trigger.a11y-helper-text';

export const DisabledButtonHelper = (props: Props) => {
	const { children } = props;
	const [isDisabledButtonPopupOpen, setIsDisabledButtonPopupOpen] = useState(false);
	const { formatMessage } = useIntl();

	const onDisabledButtonPopupClose = useCallback(() => {
		setIsDisabledButtonPopupOpen(false);
	}, []);

	const handleDisabledButtonClick = useCallback(() => {
		setIsDisabledButtonPopupOpen((prevState) => !prevState);
	}, []);

	const disabledButtonTrigger = ({ ...triggerProps }) => (
		<Button
			{...triggerProps}
			isSelected={isDisabledButtonPopupOpen}
			onClick={handleDisabledButtonClick}
			testId="software-board.header.common.sprint-actions.disabled-button-helper.disabled-button-trigger"
			aria-describedby={A11Y_HELPER_TEXT_ID}
			aria-disabled
		>
			{children}
		</Button>
	);

	return (
		<>
			<Popup
				isOpen={isDisabledButtonPopupOpen}
				onClose={onDisabledButtonPopupClose}
				content={disabledButtonContent}
				trigger={disabledButtonTrigger}
				placement="bottom-end"
				testId="software-board.header.common.sprint-actions.disabled-button-helper.disabled-button-popup"
			/>
			<VisuallyHidden
				id={A11Y_HELPER_TEXT_ID}
				testId="software-board.header.common.sprint-actions.disabled-button-helper.a-11-y-sr-text"
			>
				{formatMessage(messages.disabledButtonHelperHeading)}&nbsp;
				{formatMessage(messages.disabledButtonHelperBody)}
			</VisuallyHidden>
		</>
	);
};
