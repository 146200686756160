import { createSelector } from 'reselect';
import { format, parseISO } from 'date-fns';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { values } from '@atlassian/jira-shared-types/src/general.tsx';
import { CAN_MANAGE_SPRINT } from '../../../model/permission/permission-types.tsx';
import type { Sprint, SprintId, SprintKey } from '../../../model/sprint/sprint-types.tsx';
import type { SprintsState } from '../../reducers/entities/sprints/types.tsx';
import type { State } from '../../reducers/types.tsx';
import {
	type SprintsState as SprintsUiState,
	initialSprintsState,
} from '../../reducers/ui/sprint/types.tsx';
import { getPermissionsSelector } from '../board/board-permissions-selectors.tsx';
import { getEntities, getBoardEntity, getUi } from '../software/software-selectors.tsx';

export const getSprintEntities = (state: State): SprintsState => getEntities(state).sprints;

export const getSprintsUIState = (state: State): SprintsUiState =>
	getUi(state).sprints ?? initialSprintsState;

export const isSprintsEnabled = (state: State): boolean =>
	getBoardEntity(state).config.isSprintsEnabled || false;

// "selected" in the context of the board entity means "active"
// vs. "selected" in the context of the sprint UI state means "selected for display" (subset of active)
// WARNING: empirically (as of 2024/09) seen "null" when there are no active sprints
// instead of [] (empty array). This behavior is debatable but is important for "activeSprintsSelector" below.
// Be careful if you change this behavior.
const getActiveSprintsIds = (state: State): SprintId[] | null =>
	getBoardEntity(state).selectedSprints;

export const getSprintsSelector = createSelector(
	[getSprintEntities, isSprintsEnabled],
	(sprints: SprintsState, sprintsEnabled: boolean): Sprint[] =>
		sprintsEnabled ? values(sprints) : [],
);

// Warning: this selector currently (2024/09) return nulls if sprints are enabled but no active sprints
// instead of an empty array. This mirrors the behavior of "getActiveSprintsIds" above.
// This behavior is important for "hasActiveSprintsSelector" below.
// Be careful if you change this behavior.
export const activeSprintsSelector = createSelector(
	[isSprintsEnabled, getSprintEntities, getActiveSprintsIds],
	(sprintsEnabled: boolean, sprints: SprintsState, ids: SprintId[] | null): Sprint[] | null =>
		sprintsEnabled && ids !== null
			? Object.keys(sprints)
					.filter((key) => ids.includes(parseInt(key, 10)))
					.map((key) => sprints[key])
			: null,
);

// WARNING: this selector seems to not handle the case where sprints are enabled but active sprints = []
// HOWEVER it works because the "activeSprintsSelector" above returns null in this case (never returns [])
export const hasActiveSprintsSelector = createSelector(
	[activeSprintsSelector],
	(activeSprints: Sprint[] | null): boolean => activeSprints !== null,
);

export const hasNoActiveSprintStateSelector = createSelector(
	[isSprintsEnabled, hasActiveSprintsSelector],
	(sprintsEnabled: boolean, sprintActive: boolean): boolean => sprintsEnabled && !sprintActive,
);

export const getSelectedSprintsKeys = createSelector(
	[getSprintsUIState],
	(sprintUiState: SprintsUiState): SprintKey[] => sprintUiState.selected,
);

export const activeSprintGoalSelector = createSelector(
	[activeSprintsSelector, getSelectedSprintsKeys],
	(activeSprints: Sprint[] | null, selectedSprints?: SprintKey[]): string | null => {
		const goals =
			activeSprints &&
			activeSprints
				.filter((sprint) =>
					selectedSprints && selectedSprints.length > 0
						? selectedSprints.includes(sprint.id.toString())
						: true,
				)
				.map((sprint) => sprint.goal || '');

		return goals &&
			// We only want to display if there is 1 sprint or 1 sprint selected
			goals.length === 1 &&
			goals.filter((goal) => goal.trim() !== '').length > 0
			? goals[0]
			: null;
	},
);

// This returns the sprints being used to filter the board
// This will be either the selected sprint(s) or all sprints if no sprints are selected
export const getSelectedOrAllSprintsSelector = createSelector(
	[activeSprintsSelector, getSelectedSprintsKeys],
	(activeSprints: Sprint[] | null, selectedSprints: SprintKey[]): Sprint[] => {
		const hasSelectedSprints = selectedSprints && selectedSprints.length > 0;
		const sprints: Sprint[] = activeSprints ?? [];

		return hasSelectedSprints
			? sprints.filter((sprint) => selectedSprints.includes(sprint.id.toString()))
			: sprints;
	},
);

interface RemainingDays {
	remainingDays: Sprint['daysRemaining'];
	startDate: Sprint['startDate'];
	endDate: Sprint['endDate'];
}

const formatDate = (date: string) => format(parseISO(date), 'yyyy-MM-dd');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const identity = (x: any) => x;

function getValue<T extends keyof Sprint>(key: T, transform = identity) {
	return (selectedSprints: Sprint[]) => {
		const sprintsHaveEqual = (sprint: Sprint) => {
			// The transform function could potentially throw. So we need a try catch
			// eg: When the transform function uses parseISO, it will throw when an invalid date is used. i.e.: parseISO('foo') will throw an error
			try {
				// Try to minimise error throwing by checking for undefined scenarios first
				if (!sprint || !sprint[key] || !selectedSprints?.[0]?.[key]) {
					return false;
				}
				return transform(sprint[key]) === transform(selectedSprints[0][key]);
			} catch (e) {
				fireErrorAnalytics({
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					error: e as Error,
					meta: {
						id: 'remainingDaysSelector',
						packageName: 'jiraSoftwareBoard',
					},
				});
				return false;
			}
		};

		return selectedSprints.length === 1 ||
			(selectedSprints.length > 0 && selectedSprints.every(sprintsHaveEqual))
			? selectedSprints[0][key]
			: null;
	};
}

export const remainingDaysSelector = createSelector(
	[activeSprintsSelector, getSelectedSprintsKeys],
	(sprints: Sprint[] | null, selectedSprintKeys: SprintKey[]): RemainingDays => {
		if (sprints && sprints.length > 0) {
			const hasSelectedSprints = selectedSprintKeys && selectedSprintKeys.length > 0;
			const selectedSprints =
				sprints.length > 1
					? sprints.filter((sprint) =>
							hasSelectedSprints ? selectedSprintKeys.includes(sprint.id.toString()) : true,
						)
					: sprints;

			const remainingDays = getValue('daysRemaining')(selectedSprints);
			const startDate = getValue('startDate', formatDate)(selectedSprints) || null;
			const endDate = getValue('endDate', formatDate)(selectedSprints) || null;

			return {
				remainingDays,
				startDate,
				endDate,
			};
		}
		return {
			remainingDays: null,
			startDate: null,
			endDate: null,
		};
	},
);

export const canCompleteSprintSelector = createSelector(
	[getPermissionsSelector, hasActiveSprintsSelector],
	(permissions, isActiveSprint) => {
		const canManageSprint = !!permissions[CAN_MANAGE_SPRINT];

		return isActiveSprint && canManageSprint;
	},
);

export const hasManageSprintPermission = createSelector(
	[getPermissionsSelector],
	(permissions) => !!permissions[CAN_MANAGE_SPRINT],
);
