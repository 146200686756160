/**
 * @generated SignedSource<<2f031d559b6b4aa1484052708468df2c>>
 * @relayHash ef080d6ba522722031ef94e4887d4972
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 34d278c2e16727746e37236ed6b6c53c028ecbb07c23708312783363197eb20b

import type { ConcreteRequest, Query } from 'relay-runtime';
export type mainAsyncIssueLinksTypesQuery$variables = {
  cloudId: string;
};
export type mainAsyncIssueLinksTypesQuery$data = {
  readonly jira: {
    readonly issueLinkTypes: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly inwards: string;
          readonly outwards: string;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type mainAsyncIssueLinksTypesQuery = {
  response: mainAsyncIssueLinksTypesQuery$data;
  variables: mainAsyncIssueLinksTypesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "id"
},
v3 = {
  "kind": "ScalarField",
  "name": "inwards"
},
v4 = {
  "kind": "ScalarField",
  "name": "outwards"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "mainAsyncIssueLinksTypesQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraIssueLinkTypeConnection",
            "kind": "LinkedField",
            "name": "issueLinkTypes",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueLinkTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "JiraIssueLinkType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "RequiredField",
                        "field": (v2/*: any*/),
                        "action": "THROW",
                        "path": "jira.issueLinkTypes.edges.node.id"
                      },
                      {
                        "kind": "RequiredField",
                        "field": (v3/*: any*/),
                        "action": "THROW",
                        "path": "jira.issueLinkTypes.edges.node.inwards"
                      },
                      {
                        "kind": "RequiredField",
                        "field": (v4/*: any*/),
                        "action": "THROW",
                        "path": "jira.issueLinkTypes.edges.node.outwards"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mainAsyncIssueLinksTypesQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraIssueLinkTypeConnection",
            "kind": "LinkedField",
            "name": "issueLinkTypes",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueLinkTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "JiraIssueLinkType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "34d278c2e16727746e37236ed6b6c53c028ecbb07c23708312783363197eb20b",
    "metadata": {},
    "name": "mainAsyncIssueLinksTypesQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "ad0bf63495377e340f30a9f1531b3f3c";

export default node;
