import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/filter';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/take';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import type { IssueKey } from '../../model/issue/issue-types.tsx';
import { SWIMLANE_BY_JQL } from '../../model/swimlane/swimlane-modes.tsx';
import {
	issueCreateMovedBetweenJqlSwimlanes,
	type IssueCreateMovedBetweenJqlSwimlanesAction,
} from '../../state/actions/flags/index.tsx';
import { ISSUE_CREATE_REQUEST } from '../../state/actions/issue/create/index.tsx';
import { WORK_DATA_SET } from '../../state/actions/work/index.tsx';
import {
	getSwimlaneMode,
	getJqlSwimlanesData,
} from '../../state/selectors/swimlane/swimlane-mode-selectors.tsx';
import type { ActionsObservable, MiddlewareAPI } from '../../state/types.tsx';

type JqlSwimlanesMap = {
	[id: string]: {
		length: number;
		issueIds: Set<IssueId>;
	};
};

// If the backend fails to parse JQL from the jql swimlane where the issue was created,
// the newly created issue can disappear from the current swimlane and be created in a different one.
// In this case we show a flag to the user to let them know that the issue is in another swimlane.
export const jqlSwimlanesEpic = (
	action$: ActionsObservable,
	store: MiddlewareAPI,
): Observable<IssueCreateMovedBetweenJqlSwimlanesAction> =>
	action$
		.ofType(ISSUE_CREATE_REQUEST)
		.filter(() => getSwimlaneMode(store.getState()) === SWIMLANE_BY_JQL.id)
		.switchMap((issueCreateRequestAction) => {
			const {
				meta: { swimlaneId, isSwimlaneByJql },
			} = issueCreateRequestAction;

			const jqlSwimlanesData = getJqlSwimlanesData(store.getState());

			if (!isSwimlaneByJql || !jqlSwimlanesData) {
				return Observable.empty();
			}

			let originalSwimlaneId = swimlaneId;

			const jqlSwimlanesMap: JqlSwimlanesMap = {};
			jqlSwimlanesData.forEach((swimlane) => {
				// If swimlaneId is null in the create request, then it corresponds to the default swimlane
				if (swimlaneId === null && swimlane.isDefaultSwimlane) {
					originalSwimlaneId = swimlane.id;
				}
				Object.assign(jqlSwimlanesMap, {
					[swimlane.id]: { length: swimlane.issueIds.length, issueIds: new Set(swimlane.issueIds) },
				});
			});

			return action$
				.ofType(WORK_DATA_SET)
				.take(1)
				.mergeMap((workDataSetAction) => {
					if (!workDataSetAction.payload.jqlSwimlanes) {
						return Observable.empty();
					}

					let issuesWereRemovedFromOriginalSwimlane = false;
					const movedIssueKeys: IssueKey[] = [];

					// Here we compare the current jql swimlanes in state, with the new jql swimlanes after board refresh
					// to see if any issues were removed from the original swimlane and added to another swimlane
					workDataSetAction.payload.jqlSwimlanes.forEach(({ id, issueIds }) => {
						const jqlSwimlane = jqlSwimlanesMap[id];

						// exit if the swimlane does not exist in the original state
						if (!jqlSwimlane) {
							return;
						}

						const oldSwimlaneLength = jqlSwimlane.length;
						const newSwimlaneLength = issueIds.length;

						if (id === originalSwimlaneId) {
							// Check if the original swimlane has had issues removed
							if (oldSwimlaneLength > newSwimlaneLength) {
								issuesWereRemovedFromOriginalSwimlane = true;
							}
							// Check if any other swimlane has had issues added
						} else if (oldSwimlaneLength < newSwimlaneLength) {
							issueIds.forEach((issueId) => {
								if (!jqlSwimlane.issueIds.has(issueId)) {
									// Get the issue key of any issues that were added to a swimlane, as this will be needed
									// for the flag component
									const issueKey = workDataSetAction.payload.issues.find(
										(issue) => issue.id === issueId,
									)?.key;
									issueKey && movedIssueKeys.push(issueKey);
								}
							});
						}
					});

					// We show the flag if issues were removed from the original swimlane AND added to another swimlane
					if (issuesWereRemovedFromOriginalSwimlane && movedIssueKeys.length) {
						return Observable.of(issueCreateMovedBetweenJqlSwimlanes(movedIssueKeys));
					}

					return Observable.empty();
				});
		});
