import React, { type ReactNode, useCallback, useRef } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import FeedbackIcon from '@atlaskit/icon/core/migration/feedback';
import { N0, P50, P100, P200, P400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { EntryKeys } from '../../constants.tsx';
import { useFeedbackCollectorActions } from '../../controllers/index.tsx';
import { messages } from './messages.tsx';
import type { ButtonAppearance, FeedbackCollectorButtonProps } from './types.tsx';

type StyledButtonProps = {
	isHighlight: boolean;
};

const TooltipWrapper = ({
	children,
	appearance,
	customLabel,
}: {
	children: ReactNode;
	appearance: ButtonAppearance;
	customLabel?: string;
}) => {
	const { formatMessage } = useIntl();
	if (appearance === 'icon') {
		return (
			<Tooltip content={customLabel || formatMessage(messages.leaveFeedback)} position="top">
				{children}
			</Tooltip>
		);
	}
	return <>{children}</>;
};

export const FeedbackCollectorButton = ({
	buttonAppearance = 'normal',
	testId,
	icon: Icon = FeedbackIcon,
	feedbackButtonCustomLabel,
	...feedbackCollectorProps
}: FeedbackCollectorButtonProps) => {
	const { formatMessage } = useIntl();
	const cloudId = useCloudId();
	const { openFeedbackCollector } = useFeedbackCollectorActions();
	const buttonRef = useRef<HTMLButtonElement>(null);
	const onOpen = useCallback(() => {
		// Check if feedbackContext already contains the cloud ID entry
		const hasCloudId = feedbackCollectorProps?.feedbackContext?.some(
			(entry) => entry.id === EntryKeys.CLOUD_ID,
		);
		// If not, add cloud id to feedback context
		const updatedFeedbackContext = hasCloudId
			? feedbackCollectorProps.feedbackContext
			: [
					...(feedbackCollectorProps.feedbackContext || []),
					{
						id: EntryKeys.CLOUD_ID,
						value: cloudId,
					},
				];
		openFeedbackCollector({
			...feedbackCollectorProps,
			feedbackContext: updatedFeedbackContext,
			triggerRef: buttonRef,
		});
	}, [cloudId, feedbackCollectorProps, openFeedbackCollector]);

	const displayAsLink = buttonAppearance === 'link';
	return (
		<TooltipWrapper appearance={buttonAppearance} customLabel={feedbackButtonCustomLabel}>
			<StyledButton
				testId={testId}
				ref={buttonRef}
				appearance={displayAsLink ? 'link' : 'subtle'}
				isHighlight={buttonAppearance === 'highlight'}
				spacing={displayAsLink ? 'none' : 'default'}
				onClick={onOpen}
				iconBefore={
					displayAsLink ? undefined : (
						<Icon
							// typesupression below can be removed once icon migration completes
							// @ts-expect-error Property 'color' does not exist on type 'IntrinsicAttributes & IconProps'.ts(2322)
							color={
								buttonAppearance === 'highlight'
									? token('color.text.discovery')
									: token('color.text')
							}
							label=""
						/>
					)
				}
				aria-label={
					// Only add aria-label if this is an icon button
					(buttonAppearance === 'icon' &&
						(feedbackButtonCustomLabel || formatMessage(messages.leaveFeedback))) ||
					''
				}
			>
				{buttonAppearance !== 'icon' &&
					(feedbackButtonCustomLabel || formatMessage(messages.leaveFeedback))}
			</StyledButton>
		</TooltipWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const StyledButton = styled(Button)<StyledButtonProps>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isHighlight }) =>
		isHighlight && {
			'&&': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
				color: `${token('color.text.accent.purple', P400)} !important`,
				backgroundColor: `${token('color.background.accent.purple.subtlest', P50)}`,
			},
			'&&:hover': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
				color: `${token('color.text.accent.purple', P400)} !important`,
				backgroundColor: `${token('color.background.accent.purple.subtler', P100)}`,
			},
			'&&:active': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
				color: `${token('color.text.inverse', N0)} !important`,
				background: `${token('color.background.accent.purple.subtle', P200)}`,
			},
		},
);
