export const BOARD_DATA_SHADOW_REQUEST =
	'state.actions.board.shadow-request.BOARD_DATA_SHADOW_REQUEST' as const;

export type BoardDataShadowRequestAction = {
	type: typeof BOARD_DATA_SHADOW_REQUEST;
	payload: {
		issueCount: number;
	};
};

export const boardDataShadowRequest = (
	payload: BoardDataShadowRequestAction['payload'],
): BoardDataShadowRequestAction => ({
	type: BOARD_DATA_SHADOW_REQUEST,
	payload,
});

export type Action = BoardDataShadowRequestAction;
