import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@compiled/react';
import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useAutobatch } from '@atlassian/jira-software-react-scheduler/src/ui/autobatch/index.tsx';
import { useEvent } from '@atlassian/jira-software-react-use-event/src/index.tsx';
import { TRANSITION_ZONE_DND_TYPE } from '../../../../common/constants/drag-drop/index.tsx';
import { layout } from '../../../../common/constants/styles/index.tsx';
import type { DroppableTransitionZoneData } from '../../../../common/types.tsx';
import ColumnTransitionLabel from './label/index.tsx';
import type { ColumnTransitionZoneProps, Props } from './types.tsx';

export const ColumnTransitionZone = ({
	onDragOver: onDragOverProp,
	transitionId,
	isTransitionAvailable,
	isHidden,
	LabelComponent = ColumnTransitionLabel,
	targetStatusName,
	transitionName,
	targetStatusCategory,
	sourceStatus,
	isTransitionGlobal,
	notAvailableMessage,
	hideSourceStatusInLabel,
	onTransitionSelection,
	columnId,
	swimlaneId,
}: ColumnTransitionZoneProps) => {
	const zoneRef = useRef<HTMLDivElement | null>(null);
	const previousInputPosition = useRef<{ x: number; y: number } | null>(null);
	const autobatch = useAutobatch();
	const onDragOver = useEvent(onDragOverProp);
	const [isDraggingOver, setIsDraggingOver] = useState(false);

	useEffect(() => {
		if (!zoneRef.current) return undefined;

		return dropTargetForElements({
			element: zoneRef.current,
			getData: (): DroppableTransitionZoneData => ({
				type: TRANSITION_ZONE_DND_TYPE,
				columnId,
				swimlaneId,
				transitionId,
			}),
			onDragEnter: () => {
				onDragOver(transitionId, false);
				setIsDraggingOver(true);
			},
			onDrag: ({
				location: {
					current: { input },
				},
			}) => {
				if (
					previousInputPosition.current?.x !== input.clientX ||
					previousInputPosition.current?.y !== input.clientY
				) {
					onDragOver(transitionId, true);
					previousInputPosition.current = {
						x: input.clientX,
						y: input.clientY,
					};
				}
			},
			onDragLeave: (_args) => {
				onDragOver(null, false);
				setIsDraggingOver(false);
			},
			onDrop: () => {
				if (isHidden || !isTransitionAvailable) {
					return;
				}

				autobatch(() => onTransitionSelection(transitionId));
			},
		});
	}, [
		autobatch,
		columnId,
		isHidden,
		isTransitionAvailable,
		onTransitionSelection,
		swimlaneId,
		transitionId,
		onDragOver,
	]);

	const labelEl = (
		<LabelComponent
			source={hideSourceStatusInLabel ? null : transitionName}
			sourceStatus={hideSourceStatusInLabel ? null : sourceStatus}
			target={targetStatusName}
			targetStatusCategory={targetStatusCategory}
			isTransitionAvailable={isTransitionAvailable}
			isTransitionGlobal={isTransitionGlobal}
			showMessage={false}
			notAvailableMessage={notAvailableMessage}
		/>
	);
	const commonProps = {
		isDraggingOver,
		isTransitionAvailable,
	};

	return (
		<CompiledTransitionZonesContainer
			ref={zoneRef}
			{...commonProps}
			data-testid="platform-board-kit.ui.column.column-transition-zones-container.transition-zone.compiled-transition-zones-container"
			data-component-selector="platform-board-kit.ui.column.column-transition-zones-container.transition-zone.compiled-transition-zones-container"
		>
			{labelEl}
		</CompiledTransitionZonesContainer>
	);
};

const borderWidth = 2;
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const borderRadius = `${layout.cardListRadius}px`;

const getAvailableBorderColour = (props: Props) =>
	props.isDraggingOver
		? token('color.border.brand', colors.B300)
		: token('color.border.focused', colors.B200);
const getDraggingBackgroundColour = (props: Props) =>
	props.isDraggingOver
		? token('color.background.selected.hovered', colors.B75)
		: token('color.background.selected', colors.B50);

const getBorder = (props: Props) => ({
	border: `${borderWidth}px solid ${
		!props.isTransitionAvailable
			? token('color.background.accent.red.subtler', colors.R75)
			: getAvailableBorderColour(props)
	}`,
});

const getBackgroundColor = (props: Props) => ({
	backgroundColor: `${
		!props.isTransitionAvailable
			? token('color.background.danger', colors.R50)
			: getDraggingBackgroundColour(props)
	}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CompiledTransitionZonesContainer = styled.div<Props>({
	flex: '1 1 auto',
	flexDirection: 'column',
	minHeight: 0,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	padding: `${layout.smallGutter}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	border: (props) =>
		`${borderWidth}px solid ${
			!props.isTransitionAvailable
				? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					token('color.background.accent.red.subtler', colors.R75)
				: getAvailableBorderColour(props)
		}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: (props) =>
		!props.isTransitionAvailable
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				token('color.background.danger', colors.R50)
			: getDraggingBackgroundColour(props),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	pointerEvents: 'all !important',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:first-of-type': {
		borderRadius: `${borderRadius} ${borderRadius} 0 0`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:last-child': {
		borderRadius: `0 0 ${borderRadius} ${borderRadius}`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:only-child': {
		borderRadius,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'+ div': {
		borderTop: 'none',
	},
});

export const visibleForTesting = {
	getBackgroundColor,
	getBorder,
};
