import { styled } from '@compiled/react';
import { ColumnTheme } from '../../types.tsx';
import { columnThemes } from '../column/index.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Overlay = styled.div<{ appearance?: ColumnTheme }>({
	position: 'absolute',
	left: 0,
	right: 0,
	top: 0,
	bottom: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ appearance }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		appearance ? columnThemes[appearance].background : columnThemes[ColumnTheme.Default].background,
	opacity: 0.5,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export { Overlay };
