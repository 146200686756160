import { CARD_FOCUS, CARD_BLUR } from '../../../../actions/card/index.tsx';
import type { Action } from '../../../../types.tsx';
import type { FocusedState } from './types.tsx';

const cardFocusReducer = (state: FocusedState = null, action: Action) => {
	if (action.type === CARD_FOCUS) {
		return action.payload.issueId;
	}

	if (action.type === CARD_BLUR) {
		return null;
	}

	return state;
};

export default cardFocusReducer;
