import { entityArrayToMap } from '../../../../services/software/software-data-transformer.tsx';
import { WORK_DATA_SET, WORK_DATA_CRITICAL_SET } from '../../../actions/work/index.tsx';
import type { Action } from '../../../types.tsx';
import type { IssueProjectsState } from './types.tsx';

export const issueProjectsReducer = (
	state: IssueProjectsState = {},
	action: Action,
): IssueProjectsState => {
	if (action.type === WORK_DATA_SET || action.type === WORK_DATA_CRITICAL_SET) {
		return entityArrayToMap(action.payload.projects);
	}

	return state;
};
