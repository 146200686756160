import {
	type View,
	COMPACT_MODE,
	Panels,
	QUICK_FILTERS,
	IncrementPlanningSettings,
} from './constant.tsx';
import {
	type CardExtraFieldsState,
	type CardDetailsState,
	type PanelState,
	type State,
	type IssueId,
	CardDetails,
} from './state.tsx';

export const ViewSetting = {
	...Panels,
	...CardDetails,
	...IncrementPlanningSettings,
	QUICK_FILTERS,
	COMPACT_MODE,
} as const;

export type ConfigureViewSettingsProps = {
	boardId: number;
	view?: (typeof View)[keyof typeof View];
};
export type GetViewSettingsProps = ConfigureViewSettingsProps & {
	isDaysInColumnEnabled?: boolean;
	isDueDateEnabled?: boolean;
	isLabelsEnabled?: boolean;
	isEstimateEnabled?: boolean;
	isPriorityEnabled?: boolean;
};

export type SetViewSettingsProps = ConfigureViewSettingsProps & {
	state: State;
};

export type SetViewSettingsIsFullscreenProps = ConfigureViewSettingsProps & {
	isFullscreen: boolean;
};

export type SetViewSettingsPanelsProps = ConfigureViewSettingsProps & {
	panelState: PanelState;
};

export type SetViewSettingsPanelProps = ConfigureViewSettingsProps & {
	panel: (typeof Panels)[keyof typeof Panels];
	show: boolean;
};

export type SetViewSettingsQuickFiltersProps = ConfigureViewSettingsProps & {
	showQuickFilters: boolean;
};

export type SetViewSettingsCompactModeProps = ConfigureViewSettingsProps & {
	showCompactMode: boolean;
};

export type SetViewSettingsCardDetailsProps = ConfigureViewSettingsProps & {
	cardDetailsState: CardDetailsState;
};

export type SetViewSettingsCardExtraFieldsProps = ConfigureViewSettingsProps & {
	cardExtraFieldsState: CardExtraFieldsState;
};

export type SetShowEpicsProps = ConfigureViewSettingsProps & {
	showEpics: boolean;
};

export type SetShowVersionsProps = ConfigureViewSettingsProps & {
	showVersions: boolean;
};

export type SetShowStatusesProps = ConfigureViewSettingsProps & {
	showStatuses: boolean;
};

export type SetShowIssueLinksStatsProps = ConfigureViewSettingsProps & {
	showIssueLinksStats: boolean;
};

export type ToggleViewSettingProps = ConfigureViewSettingsProps & {
	setting: (typeof ViewSetting)[keyof typeof ViewSetting];
};

export type SetViewSettingsShowCardCoversProps = ConfigureViewSettingsProps & {
	showCardCovers: boolean;
};

export type SetShowUnscheduledColumnProps = ConfigureViewSettingsProps & {
	showUnscheduledColumn: boolean;
};

export type SetExtraFieldsProps = ConfigureViewSettingsProps & {
	cardExtraFields: CardExtraFieldsState;
};

export type SetShowDaysInColumnProps = ConfigureViewSettingsProps & {
	showDaysInColumn: boolean;
};

export type SetShowDueDateProps = ConfigureViewSettingsProps & {
	showDueDate: boolean;
};

export type SetShowLabelsProps = ConfigureViewSettingsProps & {
	showLabels: boolean;
};

export type SetShowCardCoversProps = ConfigureViewSettingsProps & {
	showCardCovers: boolean;
};

export type SetShowOfftrackDependencyLinesProps = ConfigureViewSettingsProps & {
	showOfftrackDependencyLines: boolean;
};

export type SetIssueIdsToShowDependenciesProps = ConfigureViewSettingsProps & {
	issueIds: IssueId[];
};
