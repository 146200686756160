import React from 'react';
import type { PreloadedQuery } from 'react-relay';
// eslint-disable-next-line jira/wrm/no-load-bridge
import { loadBridge } from '@atlassian/jira-common-bridge/src';
import type { ProjectType } from '@atlassian/jira-common-constants/src/index.tsx';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { ErrorFlagRenderer } from '@atlassian/jira-error-boundary-flag-renderer/src/ErrorBoundaryFlag.tsx';
import getXsrfToken from '@atlassian/jira-platform-xsrf-token/src/index.tsx';
import SetCustomMark from '@atlassian/jira-providers-spa-apdex-analytics/src/set-custom-mark/index.tsx';
import type { viewBoardCustomFiltersQuery } from '@atlassian/jira-relay/src/__generated__/viewBoardCustomFiltersQuery.graphql';
import type { boardFilterRefinementQuery } from '@atlassian/jira-relay/src/__generated__/boardFilterRefinementQuery.graphql';
import type { TenantContext } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import { NextGenBoardSkeleton } from '@atlassian/jira-skeletons/src/ui/next-gen-board/NextGenBoardSkeleton.tsx';
import { isBoardSizeLimitError } from '@atlassian/jira-software-board-size-limit-error-empty-state/src/common/utils.tsx';
import BoardSizeLimitErrorEmptyState from '@atlassian/jira-software-board-size-limit-error-empty-state/src/ui/index.tsx';
import BoardPage from '@atlassian/jira-software-board/src/index.tsx';
import { BoardSlaTracker } from '@atlassian/jira-software-board/src/view/board-sla-tracker/index.tsx';
import type { UIFBoardCachedDataResult } from '@atlassian/jira-software-uif-early-script/src/index.tsx';
import type { ReactRouterCompatibleHistory } from '@atlassian/jira-spa-router-adapters/src/common/types.tsx';
import { SpaStatePageReady } from '@atlassian/jira-spa-state-controller/src/components/main.tsx';
import type { RouteResourceResponse } from '@atlassian/react-resource-router';

// @ts-expect-error - TS7006 - Parameter 'callback' implicitly has an 'any' type.
const bindToOnJiraGlobalIssueCreateClose = (callback) => {
	// window.require is not available on initial script load.
	// eslint-disable-next-line jira/wrm/no-load-bridge
	loadBridge({
		name: 'jira/util/events',
		wrmKeys: 'wrc!jira.webresources:jira-events',
	}).then((Events) => {
		// @ts-expect-error - TS2571 - Object is of type 'unknown'.
		Events?.bind?.('QuickCreateIssue.sessionComplete', callback);
	});
};

// Replace with lodash/noop
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

type BoardAppParams = {
	rapidViewId: number;
	projectKey: string;
	projectType: ProjectType;
	context: {
		tenantContext: TenantContext;
	};
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	boardData: Partial<RouteResourceResponse<any>> | null;
	cmpBoardData?: RouteResourceResponse<UIFBoardCachedDataResult | null> | null;
	history: ReactRouterCompatibleHistory;
	isCacheHit: boolean;
	customFiltersQueryRef?: PreloadedQuery<viewBoardCustomFiltersQuery> | null;
	boardFilterRefinementQueryRef?: PreloadedQuery<boardFilterRefinementQuery> | null;
	isCMPBoard?: boolean;
	isUserBoard?: boolean;
};

export const renderBoardApp = ({
	rapidViewId,
	projectKey,
	projectType = SOFTWARE_PROJECT,
	context: { tenantContext },
	boardData,
	cmpBoardData,
	history,
	isCacheHit,
	customFiltersQueryRef,
	boardFilterRefinementQueryRef,
	isCMPBoard,
	isUserBoard,
}: BoardAppParams) => {
	const { error: prefetchError, loading: boardPrefetchIsLoading } = boardData || cmpBoardData || {};
	const { data } = boardData || {};
	const isCustomFiltersReady = customFiltersQueryRef !== null;

	// Terminate loading cases early
	if (!isCMPBoard && (boardPrefetchIsLoading || !isCustomFiltersReady)) {
		return <NextGenBoardSkeleton />;
	}

	// If we find any prefetch error or the data is null/invalid for any reason this is a failure
	if (!isCMPBoard && (prefetchError || !data)) {
		return (
			<>
				{/* @ts-expect-error - TS2322 - Type 'Partial<RouteResourceResponse<any>>' is not assignable to type 'RouteResourceResponse<any>'. */}
				<BoardSlaTracker boardData={boardData || cmpBoardData} />

				{/* show "Board-size-limit page" or "generic error flag" */}
				{isBoardSizeLimitError(prefetchError) ? (
					<BoardSizeLimitErrorEmptyState projectKey={projectKey} />
				) : (
					// @ts-expect-error - TS2322 - Type 'Error | Record<string, any> | (Error & Record<string, any>) | (Record<string, any> & Error) | null | undefined' is not assignable to type 'Error | null'.
					<ErrorFlagRenderer id="next-gen-board" error={prefetchError} />
				)}
			</>
		);
	}

	return (
		<React.Fragment key={rapidViewId}>
			<SetCustomMark name="configApiReady" />
			<BoardPage
				isCMPBoard={isCMPBoard === true}
				isUserBoard={isUserBoard === true}
				isCacheHit={isCacheHit}
				locale={tenantContext.locale}
				/* eslint-disable-next-line jira/deprecations/no-base-url */
				contextPath={tenantContext.baseUrl}
				cloudId={tenantContext.cloudId}
				activationId={tenantContext.activationId}
				rapidViewId={rapidViewId}
				projectKey={projectKey || ''}
				projectId={Number(data?.projectLocation.id || '')}
				projectType={projectType}
				userAccountId={tenantContext.atlassianAccountId || ''}
				xsrfToken={getXsrfToken() || ''}
				bindToOnJiraGlobalIssueCreateClose={bindToOnJiraGlobalIssueCreateClose}
				prefetchedData={data}
				cmpBoardData={cmpBoardData?.promise ?? null}
				siteAdminStatus={tenantContext.siteAdminStatus}
				licensedProducts={tenantContext.licensedProducts}
				productCrossSellConfig={tenantContext.productCrossSellConfig}
				onUnmount={noop}
				isServer={__SERVER__}
				history={history}
				customFiltersQueryRef={customFiltersQueryRef}
				boardFilterRefinementQueryRef={boardFilterRefinementQueryRef}
			/>

			<SpaStatePageReady />
		</React.Fragment>
	);
};
