import React, { type ReactNode, useEffect, useRef, useState } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import Tooltip, { type TooltipProps } from '@atlaskit/tooltip';

type TruncatedTooltipProps = Omit<TooltipProps, 'children'> & {
	children: (props: { ref: React.RefObject<HTMLDivElement> }) => ReactNode;
};

export const TruncatedTooltip = ({ children, ...props }: TruncatedTooltipProps) => {
	const ref = useRef<HTMLDivElement>(null);
	const [isOverflown, setIsOverflown] = useState(false);
	useEffect(() => {
		if (!ref.current) return;
		const element = ref.current;
		setIsOverflown(element.scrollWidth > element.clientWidth);
	}, [ref, children]);

	return (
		<Box xcss={tooltipOverflowStyles}>
			{isOverflown ? <Tooltip {...props}>{children({ ref })}</Tooltip> : <>{children({ ref })}</>}
		</Box>
	);
};

const tooltipOverflowStyles = xcss({ flex: 1, minWidth: '0' });
