/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { visuallyHiddenStyles } from '@atlassian/jira-accessibility/src/common/ui/screenreader-text/index.tsx';

type Props = {
	id: string;
	checked: boolean;
	onChange: () => void;
};

const HiddenCheckbox = (props: Props) => {
	const { id, checked, onChange } = props;

	return <input id={id} type="checkbox" checked={checked} onChange={onChange} css={styles} />;
};

export default HiddenCheckbox;
const styles = css({
	// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	...visuallyHiddenStyles,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&:focus + label': {
		outline: `2px solid ${token('color.border.focused', '#2684FF')}`,
		outlineOffset: token('space.025', '2px'),
	},
});
