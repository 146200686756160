import { createSelector } from 'reselect';
import { SPRINT } from '../../../model/filter/filter-types.tsx';
import { type Permissions, CAN_EDIT_BOARD } from '../../../model/permission/permission-types.tsx';
import type { Sprint } from '../../../model/sprint/sprint-types.tsx';
import type { ConfigState } from '../../reducers/entities/board/config/types.tsx';
import type { SprintsState } from '../../reducers/entities/sprints/types.tsx';
import { getBoardConfig, getIsCMPBoard } from '../software/software-selectors.tsx';
import { activeSprintsSelector, getSprintEntities } from '../sprint/sprint-selectors.tsx';
import { getWorkFilters } from '../work/work-selectors.tsx';
import { getPermissionsSelector } from './board-permissions-selectors.tsx';

export const boardNameSelector = createSelector(
	[getBoardConfig],
	(boardConfig) => boardConfig.name,
);

export const boardTitleSelector = createSelector(
	[getSprintEntities, getBoardConfig, getWorkFilters],
	(activeSprints: SprintsState, boardConfig: ConfigState, workFilters): string => {
		const numOfActiveSprints = Object.keys(activeSprints).length;

		if (boardConfig.isSprintsEnabled === false || numOfActiveSprints === 0) {
			return boardConfig.name;
		}

		const selectedSprints = workFilters.values[SPRINT] ?? [];
		const numOfSelectedSprints = selectedSprints?.length ?? 0;

		if (numOfActiveSprints === 1) {
			return activeSprints[Object.keys(activeSprints)[0]].name;
		}

		if (numOfSelectedSprints === 1 && activeSprints[selectedSprints[0]]) {
			return activeSprints[selectedSprints[0]].name;
		}

		if (numOfSelectedSprints > 1 && numOfSelectedSprints < numOfActiveSprints) {
			return `${numOfSelectedSprints} sprints`;
		}

		return 'All sprints';
	},
);

export const isEditableSelector = createSelector(
	[activeSprintsSelector, getPermissionsSelector, getIsCMPBoard],
	(activeSprints: Sprint[] | null, permissions: Permissions, isCMPBoard: boolean): boolean =>
		(activeSprints === null || activeSprints.length === 0) &&
		(permissions[CAN_EDIT_BOARD] || false) &&
		!isCMPBoard,
);
