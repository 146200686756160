import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { setAllFilters } from '../../state/actions/filter/index.tsx';
import type { Dispatch } from '../../state/types.tsx';
import type { DispatchProps } from './types.tsx';
import CardCreateUnmappedFlagInIpBoard from './view.tsx';

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	onClearAllButtonClicked: () => {
		dispatch(setAllFilters({}));
	},
});

export default connect(null, mapDispatchToProps)(CardCreateUnmappedFlagInIpBoard);
