import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import { CARD_REFRESH_REQUEST } from '../../../../actions/card/index.tsx';
import { ISSUE_RANK_TRANSITION_UPDATE_OPTIMISTIC } from '../../../../actions/issue/rank-transition/index.tsx';
import type { Action } from '../../../../types.tsx';
import type { CardTransactionsState, TransactionId } from './types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { CardTransactionsState };

export const initialState: CardTransactionsState = {};

export const startTransactionsWithId = (
	state: CardTransactionsState,
	issueIds: IssueId[],
	transactionId: TransactionId,
): CardTransactionsState => {
	const newTransactions: CardTransactionsState = {};
	issueIds.forEach((issueId) => {
		newTransactions[issueId] = transactionId;
	});

	return {
		// @ts-expect-error - TS2698 - Spread types may only be created from object types.
		...state.transactions,
		...newTransactions,
	};
};

export const cardTransactionsReducer = (
	state: CardTransactionsState = initialState,
	action: Action,
) => {
	switch (action.type) {
		case ISSUE_RANK_TRANSITION_UPDATE_OPTIMISTIC: {
			return startTransactionsWithId(
				state,
				action.payload.issueIds.map((id) => String(id)),
				action.meta.optimistic.id,
			);
		}

		case CARD_REFRESH_REQUEST: {
			if (action.payload.skipTransactionUpdate) {
				return state;
			}

			return startTransactionsWithId(
				state,
				action.payload.issueIds.map((id) => String(id)),
				action.payload.transactionId,
			);
		}

		default: {
			return state;
		}
	}
};
