import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorPopupTitle: {
		id: 'development-board-common.error-fallback.error-popup-title',
		defaultMessage: '{devInfoType}',
		description:
			'Heading title displayed in an error popup indicating the type of development data that failed to load.',
	},
	errorTitle: {
		id: 'development-board-common.error-fallback.error-title',
		defaultMessage: 'Something went wrong',
		description:
			'Title displayed in an error popup indicating that there was an issue loading development data.',
	},
	errorDescription: {
		id: 'development-board-common.error-fallback.error-description',
		defaultMessage: "For some reason we couldn't load this {devInfoType}.",
		description:
			'A detailed message explaining that there was an issue loading development data, displayed inside a popup.',
	},
	developmentErrorDescriptionCta: {
		id: 'development-board-common.error-fallback.development-error-description-cta',
		defaultMessage: 'View all development information.',
		description:
			'Call to action to trigger opening a modal to view a users development data for the selected issue.',
	},
	designErrorDescription: {
		id: 'development-board-common.error-fallback.design-error-description',
		defaultMessage: "For some reason we couldn't load this design. Refresh your page to try again.",
		description:
			'A detailed message explaining that there was an issue loading design data, displayed inside a popup.',
	},
});
