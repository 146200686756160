import { getYear, parse as parseDate } from 'date-fns';

type DueDateFormat = 'dd MMM' | 'dd MMM yyyy';

const DATE_FORMAT_CURRENT_YEAR: DueDateFormat = 'dd MMM';
const DATE_FORMAT_NOT_CURRENT_YEAR: DueDateFormat = 'dd MMM yyyy';

export const getDateFormat = (dueDate: string): DueDateFormat => {
	const currentYear = getYear(new Date());
	const parsedDateDueDateString = parseDate(dueDate, 'yyyy-mm-dd', new Date());
	const dueDateYear = getYear(parsedDateDueDateString);

	return dueDateYear === currentYear ? DATE_FORMAT_CURRENT_YEAR : DATE_FORMAT_NOT_CURRENT_YEAR;
};
