import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { defaultLocale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
import { chainUnsafe as chain } from '@atlassian/jira-common-icepick/src/utils/unsafe/index.tsx';
import { SET_CAN_USER_INVITE } from '../../actions/can-user-invite/index.tsx';
import type { Action } from '../../actions/index.tsx';
import { WORK_DATA_SET } from '../../actions/work/index.tsx';
import extensionReducer from './extension/reducer.tsx';
import type { ConfigurationState } from './types.tsx';

const initialState: ConfigurationState = {
	contextPath: '',
	rapidViewId: '',
	cloudId: '',
	activationId: '',
	userAccountId: '',
	locale: defaultLocale,
	projectKey: '',
	projectId: 0,
	projectName: '',
	projectType: SOFTWARE_PROJECT,
	siteAdminStatus: 'unknown',
	licensedProducts: {},
	productCrossSellConfig: 'unknown',
	extension: {
		operations: [],
	},
	sessionId: '',
	isCMPBoard: false,
	isUserBoard: false,
	isIncrementPlanningBoard: false,
	issueLimitExceeded: false,
	missingIssueType: '',
	canInviteOthersToProject: false,
};

export const configurationReducer = (
	state: ConfigurationState = initialState,
	action: Action,
): ConfigurationState => {
	const newState = chain(state).set('extension', extensionReducer(state.extension, action)).value();

	if (action.type === WORK_DATA_SET) {
		return {
			...newState,
			projectName: action.payload.projects[0]?.name ?? '',
			projectId: action.payload.projects[0]?.id ?? '',
			projectKey: action.payload.projects[0]?.key ?? '',
			boardType: action.payload.boardType,
			etag: action.payload.etag,
			hierarchyNomenclature: action.payload.hierarchyNomenclature,
			issueLimitExceeded: action.payload.issueLimitExceeded ?? false,
			savedFilterId: action.payload.savedFilterId,
		};
	}

	if (action.type === SET_CAN_USER_INVITE) {
		return {
			...newState,
			canInviteOthersToProject: action.payload.canInviteOthersToProject,
		};
	}

	return newState;
};
