import React, { forwardRef, type MutableRefObject } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { ButtonItem } from '@atlaskit/menu';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

type Props = {
	onClick: () => void;
};

export const EditSprintMenuItem = forwardRef(
	(
		props: Props,
		ref: MutableRefObject<HTMLElement | null> | ((instance: HTMLElement | null) => void) | null,
	) => {
		const { formatMessage } = useIntl();

		return fg('make_board_menu_popup') ? (
			<ButtonItem ref={ref} onClick={props.onClick}>
				{formatMessage(messages.displayText)}
			</ButtonItem>
		) : (
			<DropdownItem ref={ref} onClick={props.onClick}>
				{formatMessage(messages.displayText)}
			</DropdownItem>
		);
	},
);
