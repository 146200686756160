/**
 * @generated SignedSource<<6c7f0c26ed76fc88a07d0b6e2727631e>>
 * @relayHash fa42f3dae449dd75a7e68c391802ede8
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 7ac6e9aa13260fb57ab6309b16626fe198ec2f0135614da11dfbd6db4f09891f

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteCardInput = {
  cardId: string;
};
export type deleteCardMutation$variables = {
  input?: DeleteCardInput | null | undefined;
};
export type deleteCardMutation$data = {
  readonly jsw: {
    readonly deleteCard: {
      readonly clientMutationId: string | null | undefined;
      readonly message: string;
      readonly statusCode: number;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type deleteCardMutation = {
  response: deleteCardMutation$data;
  variables: deleteCardMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "concreteType": "JswMutation",
    "kind": "LinkedField",
    "name": "jsw",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "DeleteCardOutput",
        "kind": "LinkedField",
        "name": "deleteCard",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "clientMutationId"
          },
          {
            "kind": "ScalarField",
            "name": "statusCode"
          },
          {
            "kind": "ScalarField",
            "name": "success"
          },
          {
            "kind": "ScalarField",
            "name": "message"
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "deleteCardMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteCardMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "7ac6e9aa13260fb57ab6309b16626fe198ec2f0135614da11dfbd6db4f09891f",
    "metadata": {},
    "name": "deleteCardMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "1b3d8079ce3d693831c2e051a32abf3b";

export default node;
