import { createSelector } from 'reselect';
import { SWIMLANE_BY_SUBTASK } from '../../../model/swimlane/swimlane-modes.tsx';
import { getOrderedIssuesByColumn } from '../issue/issue-selectors.tsx';
import { getUi, getOrderedColumnIds } from '../software/software-selectors.tsx';
import { getSwimlaneMode } from '../swimlane/swimlane-mode-selectors.tsx';

export const getFirstColumnIssueIds = createSelector(
	[getOrderedColumnIds, getOrderedIssuesByColumn],
	(orderedColumnIds, orderedIssuesByColumn) => {
		const firstColumnId = orderedColumnIds[0];
		const firstColumnIssues = orderedIssuesByColumn[firstColumnId];
		return firstColumnIssues ? firstColumnIssues.map((i) => i.id) : [];
	},
);

export const isOpenSelector = createSelector([getUi], (ui) =>
	Boolean(ui.backlogIssueMoveDialog?.isOpen),
);

export const isDialogVisibleSelector = createSelector(
	[isOpenSelector, getFirstColumnIssueIds, getSwimlaneMode],
	(isOpen, firstColumnIssueIds, swimlaneMode) => {
		if (swimlaneMode === SWIMLANE_BY_SUBTASK.id) return false;

		const firstColumnNotEmpty = firstColumnIssueIds.length > 0;
		return isOpen && firstColumnNotEmpty;
	},
);
