/**
 * @generated SignedSource<<3911672bf59cd9c5cbbd5af9f5777951>>
 * @relayHash a08180161ec6ab9c7e11d8f628199d37
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID fb7810939eb3c247c90b6c590a41c138ba5abb1fafe8508efd4ed201487f6b41

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcAsyncIssueLinksDetailsContentQuery } from './srcAsyncIssueLinksDetailsContentQuery.graphql';

const node: PreloadableConcreteRequest<srcAsyncIssueLinksDetailsContentQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "fb7810939eb3c247c90b6c590a41c138ba5abb1fafe8508efd4ed201487f6b41",
    "metadata": {},
    "name": "srcAsyncIssueLinksDetailsContentQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
