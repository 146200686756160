import isNil from 'lodash/isNil';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/mergeMap';
import { ChangeEventTypes } from '@atlassian/jira-issue-view-model/src/change-type.tsx';
import { REFRESH_SOURCE_BENTO, REFRESH_SOURCE_STATUS_REMAPPED } from '../../model/constants.tsx';
import { mapBentoResponseToIssueParent } from '../../services/issue-parent/map-bento-response-to-issue-parent/index.tsx';
import {
	checkIssueStatusRemapped,
	checkIssueStatusUnmapped,
	mapBentoResponseToIssue,
} from '../../services/issue/issue-bento-response-transformer.tsx';
import { mapBentoResponseToMissingParent } from '../../services/missing-parent/map-bento-response-to-missing-parent/index.tsx';
import { CARD_DATA_SET } from '../../state/actions/card/index.tsx';
import { fetchIssueLinksStats } from '../../state/actions/issue-links-stats/index.tsx';
import { cardCreateUnmapped } from '../../state/actions/issue/create/index.tsx';
import {
	ISSUE_BENTO_UPDATE,
	type IssueBentoUpdateAction,
} from '../../state/actions/issue/update/index.tsx';
import { workRefreshData, WORK_REFRESH_DATA } from '../../state/actions/work/index.tsx';
import { getIssueChildren } from '../../state/selectors/issue-children/index.tsx';
import { getIssueParents } from '../../state/selectors/issue-parent/index.tsx';
import {
	getIsCMPBoard,
	getIssues,
	getMissingParents,
} from '../../state/selectors/software/software-selectors.tsx';
import type { Action, ActionsObservable, MiddlewareAPI, State } from '../../state/types.tsx';

const mapPayloadToActions = (
	state: State,
	action: IssueBentoUpdateAction,
	isCMPBoard: boolean,
): Action[] | null => {
	const { payload } = action;
	if (payload.type === ChangeEventTypes.CHILD_ISSUE_FIELD_UPDATED) {
		return isCMPBoard ? [workRefreshData(REFRESH_SOURCE_BENTO)] : null;
	}

	if (payload.type === ChangeEventTypes.ISSUE_LINKS_CREATED) {
		const issuesToUpdate = payload.meta?.links?.reduce(
			(acc, curr) => {
				acc.push(curr.linkedIssueId);
				return acc;
			},
			[payload.issueId],
		);
		return [fetchIssueLinksStats(issuesToUpdate)];
	}

	if (payload.type === ChangeEventTypes.ISSUE_LINK_DELETED) {
		const issuesToUpdate = [payload.issueId, payload.meta.linkedIssueId];
		return [fetchIssueLinksStats(issuesToUpdate)];
	}

	const missingParents = getMissingParents(state);
	if (isCMPBoard && missingParents[Number(payload.issueId)]) {
		const mapMissingParentResult = mapBentoResponseToMissingParent(
			missingParents[Number(payload.issueId)],
			payload,
		);
		if (!mapMissingParentResult) {
			return null;
		}
		return [mapMissingParentResult];
	}

	const issueParents = getIssueParents(state);

	if (issueParents[payload.issueId]) {
		const mapResult = mapBentoResponseToIssueParent(
			issueParents[payload.issueId],
			state,
			payload,
			action.meta.transactionId,
		);
		if (!mapResult) {
			return null;
		}
		return [mapResult];
	}

	const issue = getIssues(state)[payload.issueId] || getIssueChildren(state)[payload.issueId];

	if (!issue) {
		// if issue does not exist on board, check if its status is changed to status on board and refresh data
		return checkIssueStatusRemapped(state, payload)
			? [workRefreshData(REFRESH_SOURCE_STATUS_REMAPPED)]
			: null;
	}

	const mapResult = mapBentoResponseToIssue(issue, state, payload, action.meta.transactionId);

	if (!mapResult) {
		return null;
	}

	// If the status of the issue is unmapped trigger a flag
	if (checkIssueStatusUnmapped(state, payload)) {
		return [mapResult, cardCreateUnmapped([issue.key])];
	}

	return [mapResult];
};

const ACTIONS_THAT_DONT_TRIGGER_REFRESH = [CARD_DATA_SET, WORK_REFRESH_DATA];

export const issueBentoUpdateEpic = (action$: ActionsObservable, store: MiddlewareAPI) =>
	action$.ofType(ISSUE_BENTO_UPDATE).mergeMap((action: IssueBentoUpdateAction) => {
		const state = store.getState();
		const isCMPBoard = getIsCMPBoard(state);
		const actions = mapPayloadToActions(state, action, isCMPBoard);

		if (isCMPBoard && !isNil(actions)) {
			// If we get a card data set action it means we have interpretted it properly and handled it
			// internally and do NOT need to fire any APIs
			if (!actions.find(({ type }) => ACTIONS_THAT_DONT_TRIGGER_REFRESH.includes(type))) {
				// Otherwise we refresh everything.....
				// NOTE: Once we have relay we can simplify this OR we could look into extending the
				// existing query used in src/packages/software/board/src/ops/card/card-refresh-epic.tsx
				actions.push(workRefreshData(REFRESH_SOURCE_BENTO));
			}
		}

		return actions ? Observable.from(actions) : Observable.empty<never>();
	});
