export const getFieldLengthForAnalytics = (length?: number): string => {
	if (!length) {
		return '0';
	}

	if (length > 3) {
		return '>3';
	}

	return String(length);
};
