import type { Observable as ObservableType } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import type {
	CardIssueTransitions,
	// eslint-disable-next-line camelcase
	CardIssueTransitions_boardScope_cards_issue_transitions,
} from '../../rest/fetch-card-issue-transitions/__generated_apollo__/CardIssueTransitions';
import { fetchCardIssueTransitions } from '../../rest/fetch-card-issue-transitions/index.tsx';
import type { IssueTransition } from '../../state/reducers/entities/issue-transitions/types.tsx';

export const toIssueTransitionsMap = (
	response: Partial<CardIssueTransitions> = {},
): IssueTransition[] => {
	const transitions = response.boardScope?.cards[0].issue?.transitions;
	if (transitions === null || transitions === undefined) return [];

	// Filter out the nulls from the array here
	const filteredTransitions = transitions.filter(
		// eslint-disable-next-line camelcase
		(transition): transition is CardIssueTransitions_boardScope_cards_issue_transitions =>
			transition != null,
	);

	// Convert to new type
	return filteredTransitions.map((transition) => ({
		id: Number(transition.id),
		name: transition.name,
		destinationStatusId: Number(transition.toStatusId),
		isGlobal: transition.isGlobal,
		isInitial: transition.isInitial,
		isAvailable: transition.isAvailable,
	}));
};

export const fetchCardIssueTransitionsService = (
	contextPath: string,
	boardId: string,
	cardId: IssueId,
): ObservableType<IssueTransition[]> =>
	fetchCardIssueTransitions(contextPath, boardId, [cardId]).map(toIssueTransitionsMap);
