import 'rxjs/add/operator/map';
import type { Observable } from 'rxjs/Observable';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type {
	BulkIssueRankResponse,
	BulkIssuesRankRequestPayload,
	IssueTransitionAndRankRequestPayload,
	IssueTransitionAndRankResponse,
	IssueTransitionRequestPayload,
} from '../../../model/issue/issue-types.tsx';
import {
	getIssueTransitionWithoutColumnUrl,
	getIssueTransitionUrl,
	getBulkIssueRankUrl,
} from '../../software/software-api-urls.tsx';

const FAIL_TO_TRANSITION_MESSAGE = 'Either transition or rank operation has failed';
const FAIL_TO_BULK_RANK_MESSAGE = 'Bulk rank issue has failed';

/**
 * @throws {FetchError} Throw fetch error if `response.issuesWereTransitioned` is false.
 */
export function issueRankTransitionRest(
	request: IssueTransitionAndRankRequestPayload,
): Observable<IssueTransitionAndRankResponse> {
	const url = getIssueTransitionWithoutColumnUrl('');

	return fetchJson$<IssueTransitionAndRankResponse>(url, {
		method: 'PUT',
		body: JSON.stringify(request),
	}).map((response) => {
		if (!response.issuesWereTransitioned) {
			// The 'PUT /rest/greenhopper/1.0/board/issues/transition' may return HTTP 200 but either
			// transition or ranking may have failed.
			throw new FetchError(200, FAIL_TO_TRANSITION_MESSAGE);
		}
		return response;
	});
}

/**
 * @throws {FetchError} Throw fetch error if `response.issuesWereTransitioned` is false.
 */
export function issueTransitionRest(
	request: IssueTransitionRequestPayload,
): Observable<IssueTransitionAndRankResponse> {
	const url = getIssueTransitionUrl();

	return fetchJson$<IssueTransitionAndRankResponse>(url, {
		method: 'PUT',
		body: JSON.stringify(request),
	}).map((response) => {
		if (!response.issuesWereTransitioned) {
			throw new FetchError(200, FAIL_TO_TRANSITION_MESSAGE);
		}
		return response;
	});
}

export function bulkIssuesRankRest(
	request: BulkIssuesRankRequestPayload,
): Observable<BulkIssueRankResponse> {
	const url = getBulkIssueRankUrl();

	return fetchJson$<BulkIssueRankResponse>(url, {
		method: 'PUT',
		body: JSON.stringify(request),
	}).map((response) => {
		if (!response.rankableChanges.every((change) => change.wasChanged)) {
			throw new FetchError(200, FAIL_TO_BULK_RANK_MESSAGE);
		}
		return response;
	});
}
