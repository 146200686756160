import type { DocNode as ADF } from '@atlaskit/adf-schema';

export const ADD = 'ADD' as const;
export const REMOVE = 'REMOVE' as const;

export const FLAG_REMOVED_PREFIX: ADF = {
	content: [
		{
			type: 'paragraph',
			content: [
				{
					type: 'emoji',
					attrs: {
						shortName: ':flag_off:',
						id: 'atlassian-flag_off',
						text: ':flag_off:',
					},
				},
				{
					type: 'text',
					text: ' Flag removed ',
				},
			],
		},
	],
	type: 'doc',
	version: 1,
};

export const FLAG_ADDED_PREFIX: ADF = {
	content: [
		{
			type: 'paragraph',
			content: [
				{
					type: 'emoji',
					attrs: {
						shortName: ':flag_on:',
						id: 'atlassian-flag_on',
						text: ':flag_on:',
					},
				},
				{
					type: 'text',
					text: ' Flag added ',
				},
			],
		},
	],
	type: 'doc',
	version: 1,
};
