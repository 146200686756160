import type { Action } from '../../../actions/index.tsx';
import { WORK_DATA_SET } from '../../../actions/work/index.tsx';
import type { CustomFiltersState } from './types.tsx';

const initialState: CustomFiltersState = { filters: [] };

/**
 * Redux reducer for CMP custom filters only, these are read from the main data
 * request.
 */
export function customFiltersReducer(
	state: CustomFiltersState = initialState,
	action: Action,
): CustomFiltersState {
	if (action.type === WORK_DATA_SET && action.payload.customFilters) {
		return { filters: action.payload.customFilters };
	}

	return state;
}
