import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/switchMap';
import {
	type IssueDeleteRequestAction,
	ISSUE_DELETE_REQUEST,
} from '../../state/actions/issue/delete/index.tsx';
import { closeIssueModal } from '../../state/actions/issue/modal/index.tsx';
import {
	ISSUE_MOVE_TO_BACKLOG_REQUEST,
	type IssueMoveToBacklogRequestAction,
} from '../../state/actions/issue/move-to-backlog/index.tsx';
import {
	getIssueIdForModal,
	getIssueKeyForModal,
} from '../../state/selectors/issue/issue-modal-selectors.tsx';
import type { ActionsObservable, MiddlewareAPI } from '../../state/types.tsx';

export const detailViewCloseEpic = (action$: ActionsObservable, store: MiddlewareAPI) =>
	action$
		.ofType(ISSUE_DELETE_REQUEST, ISSUE_MOVE_TO_BACKLOG_REQUEST)
		.switchMap((action: IssueDeleteRequestAction | IssueMoveToBacklogRequestAction) => {
			const state = store.getState();
			const issueId = getIssueIdForModal(state);

			if (!issueId) {
				return Observable.empty<never>();
			}
			if (
				action.type === ISSUE_MOVE_TO_BACKLOG_REQUEST &&
				action.payload.issueIds.includes(issueId)
			) {
				return Observable.of(closeIssueModal(getIssueKeyForModal(state)));
			}
			if (action.type === ISSUE_DELETE_REQUEST && action.payload.issue.id === issueId) {
				return Observable.of(closeIssueModal(getIssueKeyForModal(state)));
			}
			return Observable.empty<never>();
		});
