import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	appErrorTitle: {
		id: 'forge-ui-controllers.action-apps-controller.app-error-title',
		defaultMessage: 'App Error',
		description: 'Title for the error flag when an app fails to load',
	},
	appErrorDescription: {
		id: 'forge-ui-controllers.action-apps-controller.app-error-description',
		defaultMessage: 'An error occurred while loading the app',
		description: 'Description for the error flag when an app fails to load',
	},
});
