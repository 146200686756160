/* eslint-disable jira/import-whitelist */
/**
 * This is a duplicate of ./index.js with a reduced surface area to limit bundle
 * size for testing a new approach to route-based critical data fetching.
 * See https://jdog.jira-dev.com/jira/software/projects/SPAF/boards/3052?selectedIssue=SPAF-22
 */

import { setMark } from '@atlassian/jira-common-performance/src/marks.tsx';
import { prefetchApiReadyMark } from '@atlassian/jira-providers-spa-apdex-analytics/src/marks.tsx';
import fetchBoardScopeCritical from '@atlassian/jira-software-board-fetch-scope-critical/src/index.tsx';

export const fetchBoardData = (baseUrl: string, rapidViewId: string) =>
	fetchBoardScopeCritical(baseUrl, rapidViewId)
		.toPromise()
		.then((data) => {
			setMark(prefetchApiReadyMark);
			return data;
		});
