import React, {
	type ComponentType,
	type PropsWithChildren,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { SpotlightPulse, SpotlightTarget } from '@atlaskit/onboarding';
import Tooltip from '@atlaskit/tooltip';
import { ChoreographerContextProvider } from '@atlassian/jira-choreographer-services/src/ChoreographerContextProvider.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { boardType } from '@atlassian/jira-insights-common-constants/src/common/constants/analytics.tsx';
import {
	INSIGHTS_PACKAGE,
	INSIGHTS_PACKAGE_BOUNDARY_ID,
} from '@atlassian/jira-insights-common-constants/src/common/constants/package.tsx';
import { permissionStatus } from '@atlassian/jira-insights-common-constants/src/common/constants/permissions.tsx';
import {
	BUTTON_LOCATION_SCRUM,
	BUTTON_LOCATION_SCRUM_VISUAL_REFRESH,
} from '@atlassian/jira-insights-common/src/common/constants.tsx';
import {
	isValidBoardId,
	useDueDatePulse,
} from '@atlassian/jira-insights-common/src/common/utils/utils.tsx';
import { useIsCurrentBrowserSupported } from '@atlassian/jira-insights-common/src/controllers/use-is-current-browser-supported/index.tsx';
import { useValueChangeEffect } from '@atlassian/jira-insights-common/src/controllers/use-value-change-effect/index.tsx';
import { buildBoardScopeName } from '@atlassian/jira-insights-common/src/services/container/index.tsx';
import {
	CheckClassicViewAggregatedDataPermission,
	CheckSimplifiedDataPermission,
} from '@atlassian/jira-insights-common/src/ui/check-view-aggregated-data-permission/index.tsx';
import { WithInsightsErrorBoundary } from '@atlassian/jira-insights-common/src/ui/insights-error-boundary/index.tsx';
import { InsightsSpotlight } from '@atlassian/jira-insights-common/src/ui/insights-spotlight/index.tsx';
import { withComponentSafety } from '@atlassian/jira-insights-common/src/ui/with-component-safety/index.tsx';
import { useInsightsContainer } from '@atlassian/jira-insights-container/src/controllers/use-insights-container/index.tsx';
import { getTextValue } from '@atlassian/jira-insights-container/src/services/get-value/index.tsx';
import { InsightsContainer } from '@atlassian/jira-insights-container/src/ui/index.tsx';
import { InsightsButton } from '@atlassian/jira-insights-show-insights-button/src/ui/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { getUserLocation } from '@atlassian/jira-platform-router-utils/src/common/utils/index.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { BOARD_BUTTON_ID } from '../common/constants.tsx';
import messages from './messages.tsx';
import type { BoardButtonContainerState, BoardButtonProps } from './types.tsx';

const BoardInsightsButtonComponent = ({
	isCompact = false,
	isSimplifiedProject,
	isOpen,
	boardId,
	onToggle,
	edition,
	projectId,
}: BoardButtonProps) => {
	const { formatMessage } = useIntl();

	const userLocation = getUserLocation();
	const isSupported = useIsCurrentBrowserSupported();

	const [isOnboardingApplicable, setIsOnboardingApplicable] = useState(false);
	const markOnboardingApplicable = useCallback(() => setIsOnboardingApplicable(true), []);
	const markOnboardingInapplicable = useCallback(() => setIsOnboardingApplicable(false), []);

	const [isDueDatePulseActive, setisDueDatePulseActive] = useState(false);
	const { coordinationClient, shouldStartPulse, stopPulse } = useDueDatePulse();
	const [messageFinished, setIsMessageFinished] = useState(false);

	const [
		{
			info: { isAnyLevelOfAdmin, isProjectType, uniqueId },
			data: { hasPermission },
		},
		{ onDataUpdatedCallback, refreshUniqueId, updateIsDeepLinking },
	] = useInsightsContainer<BoardButtonContainerState>();

	const [, { doUpdate }] = useInsightsContainer();

	const onDismissClickHandler = useCallback(() => {
		setIsMessageFinished(true);
		stopPulse(true);
	}, [stopPulse]);

	useEffect(() => {
		if (!isOpen) {
			updateIsDeepLinking(false);
		}
	}, [isOpen, updateIsDeepLinking]);

	useEffect(() => {
		if (!isOpen) {
			if (messageFinished) {
				doUpdate({ isPulsing: false });
			}
		}
		if (shouldStartPulse) {
			setisDueDatePulseActive(true);
			doUpdate({ isPulsing: true });
		} else if (messageFinished) {
			setisDueDatePulseActive(false);
		}
	}, [doUpdate, isOpen, messageFinished, shouldStartPulse]);

	// refresh experience when insights (panel) is closed
	useValueChangeEffect(
		{
			listener: isOpen,
			callback: (current, previous) => {
				if (current === false && previous === true) refreshUniqueId();
			},
		},
		[refreshUniqueId],
	);

	const currentPermissionStatus = getTextValue(hasPermission, permissionStatus.LOADING);

	const renderTooltip = useCallback(
		({ children }: PropsWithChildren) => {
			const message = expVal('jira_enable_insights_terminology_refresh', 'isEnabled', false)
				? formatMessage(messages.tooltipTerminologyRefresh)
				: formatMessage(messages.tooltip);
			return <Tooltip content={message}>{children}</Tooltip>;
		},
		[formatMessage],
	);

	const isPulseShowing = isDueDatePulseActive && !messageFinished;
	// eslint-disable-next-line no-nested-ternary
	const location = expVal('jira_enable_insights_terminology_refresh', 'isEnabled', false)
		? BUTTON_LOCATION_SCRUM
		: isVisualRefreshEnabled() && fg('visual-refresh_drop_3')
			? BUTTON_LOCATION_SCRUM_VISUAL_REFRESH
			: undefined;

	return isSupported ? (
		<ContextualAnalyticsData
			attributes={{
				boardId,
				frameworkType: boardType.SCRUM,
				edition,
				experienceId: uniqueId,
				projectId,
				userLocation,
			}}
		>
			<>
				<InsightsSpotlight
					onOpenInsightsPanel={() => !isOpen && onToggle()}
					onDismiss={markOnboardingInapplicable}
					isOnboardingApplicable={isOnboardingApplicable}
				/>
				{currentPermissionStatus === permissionStatus.LOADING &&
					(isSimplifiedProject ? (
						<CheckSimplifiedDataPermission />
					) : (
						<CheckClassicViewAggregatedDataPermission
							boardId={boardId}
							userLocation={userLocation}
							onPermissionLoaded={onDataUpdatedCallback('hasPermission')}
							onInsightsEnabledWithoutPermission={markOnboardingApplicable}
						/>
					))}
				{isPulseShowing && expVal('jira_enable_due_date_ifa', 'isEnabled', false) ? (
					<ChoreographerContextProvider isChoreographed={coordinationClient.enableChoreographer}>
						<SpotlightTarget name="jira-insights-button-due-date-tab">
							<SpotlightPulse
								radius={3}
								pulse={shouldStartPulse}
								onClick={() => onDismissClickHandler()}
							>
								<InsightsButton
									isInsightsPanelOpen={isOpen}
									isCompact={isCompact}
									boardId={boardId}
									onToggle={onToggle}
									isAnyLevelOfAdmin={!isSimplifiedProject && isAnyLevelOfAdmin}
									isHidden={!isProjectType}
									isOnboardingDisabled={
										!isSimplifiedProject
											? currentPermissionStatus !== permissionStatus.ALLOWED
											: undefined
									}
									renderTooltip={renderTooltip}
									location={location}
								/>
							</SpotlightPulse>
						</SpotlightTarget>
					</ChoreographerContextProvider>
				) : (
					<InsightsButton
						isInsightsPanelOpen={isOpen}
						isCompact={isCompact}
						boardId={boardId}
						onToggle={onToggle}
						isAnyLevelOfAdmin={!isSimplifiedProject && isAnyLevelOfAdmin}
						isHidden={!isProjectType}
						isOnboardingDisabled={
							!isSimplifiedProject
								? currentPermissionStatus !== permissionStatus.ALLOWED
								: undefined
						}
						renderTooltip={renderTooltip}
						location={location}
					/>
				)}
			</>
		</ContextualAnalyticsData>
	) : null;
};

const BoardInsightsButtonWithContainer = (props: BoardButtonProps) => {
	const scope = buildBoardScopeName({
		isSimplifiedProject: props.isSimplifiedProject,
		boardId: props.boardId,
	});

	return (
		<InsightsContainer scope={scope} packageName={INSIGHTS_PACKAGE}>
			<BoardInsightsButtonComponent {...props} />
		</InsightsContainer>
	);
};

const BoardInsightsButtonFinal = BoardInsightsButtonWithContainer;

const SafeBoardInsightsButton = withComponentSafety(BoardInsightsButtonFinal, {
	condition: ({ boardId }) => isValidBoardId({ boardId }),
	location: BOARD_BUTTON_ID,
	message: ({ boardId }) => `Board button received invalid boardId: ${boardId}`,
});

export const BoardInsightsButton: ComponentType<BoardButtonProps> = WithInsightsErrorBoundary(
	SafeBoardInsightsButton,
	INSIGHTS_PACKAGE_BOUNDARY_ID,
	INSIGHTS_PACKAGE,
);
