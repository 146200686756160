export const statusCodeGroups = {
	INFORMATIONAL_RESPONSES: '1xx',
	SUCCESS_RESPONSES: '2xx',
	REDIRECTS: '3xx',
	CLIENT_ERRORS: '4xx',
	SERVER_ERRORS: '5xx',
	UNKNOWN: 'unknown',
} as const;

export const analyticsStatuses = {
	SUCCEEDED: 'succeeded',
	FAILED: 'failed',
	ABORTED: 'aborted',
	STARTED: 'started',
} as const;
