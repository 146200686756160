import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noRuleForSelectedIssues: {
		id: 'automation-platform.no-rule-for-issues-empty-state.no-rule-for-selected-issues',
		defaultMessage: "Available automations can't be used with the issues you've selected",
		description: 'No rules is available that are appliable to the selected issues',
	},
	addRuleCta: {
		id: 'automation-platform.no-rule-for-issues-empty-state.add-rule-cta',
		defaultMessage: 'Add a manually triggered automation',
		description: 'Call to action when there are no rules to display',
	},
	learnMoreAboutAutomation: {
		id: 'automation-platform.no-rule-for-issues-empty-state.learn-more-about-automation',
		defaultMessage: 'Learn more about Automation',
		description: 'The link for more details about Automation',
	},
});
