import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	filterToolBarAriaLabel: {
		id: 'software-filters.filter-selection-bar.filter-tool-bar-aria-label',
		defaultMessage: 'filter tool bar',
		description:
			'Aria label for the filter toolbar. This is used to group the filter options together.',
	},
});
