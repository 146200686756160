import type { Duration } from 'date-fns';
import isValid from 'date-fns/fp/isValid';
import isWithinInterval from 'date-fns/fp/isWithinInterval';
import sub from 'date-fns/fp/sub';

export const isWithinLast = (duration: Duration) => (date: Date | number | null | undefined) => {
	if (!isValid(date)) return false;
	const end = Date.now();
	const start = sub(duration)(end);
	// `isValid` precludes a nullable date.
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	return isWithinInterval({ start, end })(date!);
};
