import {
	TOGGLE_BOARD_MENUS,
	TOGGLE_EPIC_LABELS,
	SET_SHOW_UNSCHEDULED_COLUMN,
	SET_SHOW_OFFTRACK_DEPENDENCY_LINES,
	SET_ISSUE_IDS_TO_SHOW_DEPENDENCIES,
} from '../../../../actions/board/menu/index.tsx';
import { PREVENT_INLINE_EDITING } from '../../../../actions/board/prevent-inline-editing/index.tsx';
import { WORK_DATA_SET } from '../../../../actions/work/index.tsx';
import type { Action } from '../../../../types.tsx';
import type { DisplayOptionsState } from './types.tsx';

const initialState: DisplayOptionsState = {
	areEpicLabelsHidden: false,
	isHeaderCompactMode: false,
	preventInlineEditing: false,
	// Display option for Increment planning board
	showUnscheduledColumn: true,
	showOfftrackDependencyLines: true, // Toggle on/off offtrack dependency lines
	issueIdsToShowDependencies: [], // Array of issue ids to show dependency lines for
};

export function displayOptionsReducer(
	state: DisplayOptionsState | undefined = initialState,
	action: Action,
): DisplayOptionsState {
	if (action.type === WORK_DATA_SET) {
		return {
			...state,
			areEpicLabelsHidden: Boolean(action.payload.boardConfig.areEpicLabelsHidden),
		};
	}

	if (action.type === TOGGLE_BOARD_MENUS) {
		return {
			...state,
			isHeaderCompactMode: !state.isHeaderCompactMode,
		};
	}

	if (action.type === TOGGLE_EPIC_LABELS) {
		return {
			...state,
			areEpicLabelsHidden: !state.areEpicLabelsHidden,
		};
	}

	if (action.type === PREVENT_INLINE_EDITING) {
		return {
			...state,
			preventInlineEditing: action.payload.preventInlineEditing,
		};
	}

	if (action.type === SET_SHOW_UNSCHEDULED_COLUMN) {
		return {
			...state,
			showUnscheduledColumn: action.payload,
		};
	}
	if (action.type === SET_SHOW_OFFTRACK_DEPENDENCY_LINES) {
		return {
			...state,
			showOfftrackDependencyLines: action.payload,
		};
	}

	if (action.type === SET_ISSUE_IDS_TO_SHOW_DEPENDENCIES) {
		return {
			...state,
			issueIdsToShowDependencies: action.payload,
		};
	}

	return state;
}
