import React, { Component, type ReactNode } from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import noop from 'lodash/noop';
import { token } from '@atlaskit/tokens';
import { styledComponentWithCondition } from '@atlassian/jira-compiled-migration/src/ui/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { layout } from '../../common/constants/styles/index.tsx';
import { TransitionZoneIndicatorContainerProvider } from '../../common/ui/transition-zone-indicator/index.tsx';
import { LeftBorder, RightBorder } from './border/index.tsx';
import BoardScroll, {
	type BoardScrollInfo,
	type BoardScrollInitData,
	type BoardScrollPosition,
} from './scroll/index.tsx';

type BoardContainerProps = {
	flexibleColumns?: boolean;
	noBottomPadding?: boolean;
	offsetRightPaddingByWidth?: boolean;
	isVisualRefresh?: boolean;
};

// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BoardContainerControl = styled.section<{
	flexibleColumns?: boolean;
	noBottomPadding?: boolean;
	offsetRightPaddingByWidth?: boolean;
	isVisualRefresh?: boolean;
}>`
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: stretch;
	user-select: none;
	padding: ${(props) => {
		if (props.isVisualRefresh) {
			return `0 ${props.offsetRightPaddingByWidth ? token('space.300') : '0'} ${token('space.300')} ${token('space.250')}`;
		}

		if (props.flexibleColumns) {
			return `0 ${
				props.offsetRightPaddingByWidth
					? token('space.300', '1.5rem')
					: `${layout.boardBorderWidth}px`
			} ${layout.boardBorderWidth}px ${layout.boardBorderWidth - layout.columnMargin}px`;
		}
		return `0 0 ${layout.boardBorderWidth}px ${layout.boardBorderWidth - layout.cardListRadius}px`;
	}};
	${(props) => (props.noBottomPadding ? 'padding-bottom: 0' : '')};
	min-width: fit-content;
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BoardContainerExperimentBase = styled2.section<BoardContainerProps>({
	display: 'flex',
	flexFlow: 'row nowrap',
	justifyContent: 'flex-start',
	alignItems: 'stretch',
	userSelect: 'none',
	paddingTop: token('space.0', '0px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingRight: (props: BoardContainerProps) => !props.flexibleColumns && token('space.0', '0px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingBottom: (props: BoardContainerProps) =>
		// eslint-disable-next-line no-nested-ternary -- Ignored via go/DSP-18766
		props.noBottomPadding
			? token('space.0', '0px')
			: props.isVisualRefresh
				? token('space.300')
				: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
					`${layout.boardBorderWidth}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingLeft: (props: BoardContainerProps) =>
		// eslint-disable-next-line no-nested-ternary
		props.isVisualRefresh
			? token('space.250')
			: props.flexibleColumns
				? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					`${layout.boardBorderWidth - layout.columnMargin}px`
				: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					`${layout.boardBorderWidth - layout.cardListRadius}px`,
	minWidth: 'fit-content',
});

const BoardContainerExperiment =
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
	styled2(BoardContainerExperimentBase)<BoardContainerProps>({
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		paddingRight: (props: BoardContainerProps) =>
			props.offsetRightPaddingByWidth || props.isVisualRefresh
				? token('space.300', '1.5rem')
				: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					`${layout.boardBorderWidth}px`,
	});

// Reduced left padding for the board if we have both the unscheduled work panel
// and the board because the unscheduled work panel already has the extra spacing to the left
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BoardContainerWithUnscheduledWorkPanel = styled2(BoardContainerExperiment)({
	paddingLeft: token('space.150', '12px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const UnscheduledWorkBoardContainer = styled2(BoardContainerExperiment)({
	paddingLeft: token('space.0', '0px'),
	paddingRight: token('space.0', '0px'),
});

const BoardContainer = styledComponentWithCondition(
	() => fg('jsw_compiled_migration_tanuki'),
	BoardContainerExperiment,
	BoardContainerControl,
);

export type BoardScrollEventInfo = BoardScrollPosition & {
	reachedLeftEdge: boolean;
	reachedRightEdge: boolean;
};

type Props = {
	dropZoneMode?: 'standard' | 'virtual';
	flexibleColumns?: boolean;
	children?: ReactNode;
	offsetRightPaddingByWidth?: boolean;
	offsetLeftPaddingByWidth?: boolean;
	noBottomPadding?: boolean;

	scrollRef?:
		| {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				current: null | any;
		  } // eslint-disable-next-line @typescript-eslint/no-explicit-any
		| ((arg: null | any) => unknown);
	onScroll: (scrollInfo: BoardScrollEventInfo) => void;
	onBoardView: (boardInfo: {
		hasHorizontalScrollbar?: boolean;
		isUnscheduledWorkColumnPanel?: boolean;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	}) => any;
	isDropDisabled?: boolean;
	isUnscheduledWorkColumnPanel?: boolean;
	isBoardWithVisibleUnscheduledWorkPanel?: boolean;
};

// eslint-disable-next-line jira/react/no-class-components
export class Board extends Component<Props> {
	static defaultProps = {
		offsetRightPaddingByWidth: false,
		offsetLeftPaddingByWidth: false,
		noBottomPadding: false,
		onScroll: noop,
		onBoardView: noop,
		flexibleColumns: false,
		isDropDisabled: false,
		isUnscheduledWorkColumnPanel: false,
		isBoardWithVisibleUnscheduledWorkPanel: false,
	};

	topEdge = true;

	leftEdge = true;

	rightEdge = true;

	onScrollInit = ({ hasHorizontalScrollbar }: BoardScrollInitData) => {
		this.props.onBoardView({
			hasHorizontalScrollbar,
			isUnscheduledWorkColumnPanel: this.props.isUnscheduledWorkColumnPanel,
		});
	};

	onScroll = (scrollInfo: BoardScrollInfo) => {
		const { topEdge, leftEdge, rightEdge } = this;
		if (scrollInfo.topEdge !== topEdge) {
			this.topEdge = scrollInfo.topEdge;
		}

		if (scrollInfo.leftEdge !== leftEdge) {
			this.leftEdge = scrollInfo.leftEdge;
		}

		if (scrollInfo.rightEdge !== rightEdge) {
			this.rightEdge = scrollInfo.rightEdge;
		}

		const boardScrollInfo: BoardScrollEventInfo = {
			scrollLeft: scrollInfo.scrollLeft,
			scrollTop: scrollInfo.scrollTop,
			scrollWidth: scrollInfo.scrollWidth,
			clientWidth: scrollInfo.clientWidth,
			reachedLeftEdge: !leftEdge && scrollInfo.leftEdge,
			reachedRightEdge: !rightEdge && scrollInfo.rightEdge,
		};

		this.props.onScroll(boardScrollInfo);
	};

	render() {
		const {
			flexibleColumns,
			noBottomPadding,
			isUnscheduledWorkColumnPanel,
			isBoardWithVisibleUnscheduledWorkPanel,
		} = this.props;

		const leftBorder = !flexibleColumns && (
			<LeftBorder offsetLeftPaddingByWidth={this.props.offsetLeftPaddingByWidth} />
		);
		const rightBorder = !flexibleColumns && (
			<RightBorder offsetRightPaddingByWidth={this.props.offsetRightPaddingByWidth} />
		);

		const boardContainerChild = flexibleColumns ? (
			<TransitionZoneIndicatorContainerProvider>
				{leftBorder}
				{this.props.children}
				{rightBorder}
			</TransitionZoneIndicatorContainerProvider>
		) : (
			<>
				{leftBorder}
				{this.props.children}
				{rightBorder}
			</>
		);

		const shouldUseBoardWithUnscheduledWorkPanelPadding =
			!isUnscheduledWorkColumnPanel && !!isBoardWithVisibleUnscheduledWorkPanel;

		const BoardContainerWithAdjustedPadding = shouldUseBoardWithUnscheduledWorkPanelPadding
			? BoardContainerWithUnscheduledWorkPanel
			: BoardContainer;

		const Container = isUnscheduledWorkColumnPanel
			? UnscheduledWorkBoardContainer
			: BoardContainerWithAdjustedPadding;

		const boardContainer = (
			<Container
				flexibleColumns={flexibleColumns}
				noBottomPadding={noBottomPadding}
				offsetRightPaddingByWidth={this.props.offsetRightPaddingByWidth}
				isVisualRefresh={isVisualRefreshEnabled()}
			>
				{boardContainerChild}
			</Container>
		);

		return (
			<BoardScroll
				innerRef={this.props.scrollRef}
				onScroll={this.onScroll}
				onInit={this.onScrollInit}
				flexibleColumns={flexibleColumns}
			>
				{boardContainer}
			</BoardScroll>
		);
	}
}

export default Board;
