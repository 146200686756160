import type { Observable } from 'rxjs/Observable';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';

export function removeFromSprint(
	rapidViewId: string,
	contextPath: string,
	issueKey: string,
): Observable<void> {
	return fetchJson$(`${contextPath}/rest/greenhopper/1.0/sprint/rank/${rapidViewId}/remove`, {
		method: 'PUT',
		body: JSON.stringify({
			rapidViewId,
			idOrKeys: [issueKey],
		}),
	});
}
