import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	label: {
		id: 'software-filters.filters.request-type.label',
		defaultMessage: 'Request type',
		description:
			'The label of a dropdown menu button used to choose request type(s) to filter issues by.',
	},
});
