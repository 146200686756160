import React from 'react';
import { SIMPLE_SOFTWARE_BOARD } from '@atlassian/jira-common-constants/src/analytics-sources.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { ForgeActionMenuItems as BaseForgeActionMenuItems } from '@atlassian/jira-forge-ui-action-apps/src/ui/action-menu/index.tsx';
import { BOARD_ACTION_MODULE } from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
import type { BoardActionExtensionData } from '@atlassian/jira-forge-ui-types/src/common/types/extension-data.tsx';
import { forgeBoardModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { useResource } from '@atlassian/react-resource-router';

type Props = {
	extensionData: BoardActionExtensionData;
};

const ForgeActionMenuItems = (props: Props) => {
	const { loading, data: extensions } = useResource(forgeBoardModuleResource);

	if (loading || !extensions || extensions.length === 0) {
		return null;
	}

	return (
		<BaseForgeActionMenuItems
			module={BOARD_ACTION_MODULE}
			analyticsSource={SIMPLE_SOFTWARE_BOARD}
			testId="software-board.header.menu.forge-items"
			extensions={extensions}
			extensionData={props.extensionData}
		/>
	);
};

export const BoardForgeActionMenuItems = (props: Props) => (
	<ErrorBoundary packageName="software" id="forge-items">
		<ForgeActionMenuItems extensionData={props.extensionData} />
	</ErrorBoundary>
);
