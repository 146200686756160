import type { Observable as ObservableType } from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import type { CardCoversRestApiResponse } from '@atlassian/jira-software-board-uif-types/src/index.tsx';

// A lightweight endpoint for retrieving only card cover data for a given set of issueIds
const CARD_COVERS_API_URL = '/rest/greenhopper/1.0/cardCovers';

export const getCardCovers = (issueIds: IssueId[]): ObservableType<CardCoversRestApiResponse> =>
	fetchJson$(CARD_COVERS_API_URL, {
		method: 'POST',
		body: JSON.stringify(issueIds),
		perf: {
			prefix: 'jsw.board',
			key: 'cardCovers.fetch',
		},
	});
