import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title404: {
		id: 'portfolio-3-plan-increment-common.ui.error-screens.empty-404.title-404',
		defaultMessage: 'Where’d it go?',
		description: 'Title for the 404 page',
	},
	description404: {
		id: 'portfolio-3-plan-increment-common.ui.error-screens.empty-404.description-404',
		defaultMessage:
			'We couldn’t find that program board. It might have been deleted by its owner or the URL might be wrong.',
		description: 'Description for the 404 page',
	},
	viewAllPlans: {
		id: 'portfolio-3-plan-increment-common.ui.error-screens.empty-404.view-all-plans',
		defaultMessage: 'View all plans',
		description: 'Link to view all plans',
	},
	viewCurrentBoard: {
		id: 'portfolio-3-plan-increment-common.ui.error-screens.empty-404.view-current-board',
		defaultMessage: 'View current board',
		description:
			'Label for button that will redirect the user to the most relevant program board when it is clicked',
	},
});
