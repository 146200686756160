// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isUndefinedOrNull = <T extends Record<any, any> | Record<any, any>>(
	value?: boolean | number | string | T,
): boolean => value === null || value === undefined;

export const getBooleanValue = (value: boolean, defaultValue: boolean): boolean =>
	isUndefinedOrNull(value) ? defaultValue : value;

export const getNumberValue = (value: number, defaultValue: number): number =>
	isUndefinedOrNull(value) ? defaultValue : value;

export const getTextValue = (value: string, defaultValue: string): string =>
	isUndefinedOrNull(value) ? defaultValue : value;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getObjectValue = <T extends Record<any, any> | Record<any, any>>(
	value: T,
	defaultValue: T,
): T => (isUndefinedOrNull(value) ? defaultValue : value);
