import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	more: {
		id: 'software-filters.common.overflow-handler.more',
		defaultMessage: 'More',
		description: 'Dropdown trigger label. Appears on "more" filters.',
	},
	moreFiltersTooltip: {
		id: 'software-filters.common.overflow-handler.more-filters-tooltip',
		defaultMessage: 'More filters',
		description: 'Tooltip for the "more" filters dropdown.',
	},
});
