import React, { type ComponentType, Component } from 'react';
import { EngagementSpotlight } from '@atlassiansox/engagekit-ts';
import { SpotlightTarget } from '@atlaskit/onboarding';

export interface WithEngagementProps {
	targetId?: string;
}

export default function withEngagement<Props>(
	BaseComponent: ComponentType<Omit<Props, 'targetId'>>,
): ComponentType<Props & WithEngagementProps> {
	// eslint-disable-next-line jira/react/no-class-components
	return class WithEngagement extends Component<Props & WithEngagementProps> {
		render() {
			const { targetId, ...componentProps } = this.props;
			if (!targetId) {
				return <BaseComponent {...componentProps} />;
			}

			return (
				<>
					<SpotlightTarget name={targetId}>
						<BaseComponent {...componentProps} />
					</SpotlightTarget>
					<EngagementSpotlight engagementId={targetId} />
				</>
			);
		}
	};
}
