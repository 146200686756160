import {
  JiraSprintAriResourceOwner,
  JiraSprintAriResourceType
} from "./chunk-FGZEBJS7.mjs";
import {
  RegisteredAri
} from "./chunk-YG2E6ZVD.mjs";
import {
  AriParser
} from "./chunk-Q7FABYST.mjs";

// src/jira/sprint/manifest.ts
var jiraSprintAriStaticOpts = {
  qualifier: "ari",
  platformQualifier: "cloud",
  cloudId: new RegExp("^[a-zA-Z0-9_\\-.]{1,255}$"),
  // eslint-disable-line no-useless-escape
  resourceOwner: JiraSprintAriResourceOwner,
  resourceType: JiraSprintAriResourceType,
  resourceIdSlug: "activation/{activationId}/{sprintId}",
  resourceIdSegmentFormats: {
    activationId: /[a-zA-Z0-9\-]+/,
    // eslint-disable-line no-useless-escape
    sprintId: /[!a-zA-Z0-9\-_.~@:{}=]+(\/[!a-zA-Z0-9\-_.~@:{}=]+)*/
    // eslint-disable-line no-useless-escape
  }
};

// src/jira/sprint/index.ts
var JiraSprintAri = class _JiraSprintAri extends RegisteredAri {
  constructor(opts) {
    super(opts);
    this._siteId = opts.cloudId || "", this._activationId = opts.resourceIdSegmentValues.activationId, this._sprintId = opts.resourceIdSegmentValues.sprintId;
  }
  get siteId() {
    return this._siteId;
  }
  get activationId() {
    return this._activationId;
  }
  get sprintId() {
    return this._sprintId;
  }
  static create(opts) {
    let derivedOpts = {
      qualifier: jiraSprintAriStaticOpts.qualifier,
      platformQualifier: jiraSprintAriStaticOpts.platformQualifier,
      cloudId: opts.siteId,
      resourceOwner: jiraSprintAriStaticOpts.resourceOwner,
      resourceType: jiraSprintAriStaticOpts.resourceType,
      resourceId: `activation/${opts.activationId}/${opts.sprintId}`,
      resourceIdSegmentValues: {
        activationId: opts.activationId,
        sprintId: opts.sprintId
      }
    }, ariOpts = AriParser.fromOpts(derivedOpts, jiraSprintAriStaticOpts);
    return new _JiraSprintAri(ariOpts);
  }
  static parse(maybeAri) {
    let opts = AriParser.fromString(maybeAri, jiraSprintAriStaticOpts);
    return new _JiraSprintAri(opts);
  }
  getVariables() {
    return {
      siteId: this.siteId,
      activationId: this.activationId,
      sprintId: this.sprintId
    };
  }
};

export {
  JiraSprintAri
};
