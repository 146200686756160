type MarkInterface = {
	mark: (name: string, startTime?: number) => void;
};

type Options = {
	marks: { [key: string]: string };
};

export const defaultMarks: { [key: string]: string } = {
	'jira.fe-spa.software.early-preload:start': 'EARLY_FETCH_START',
	'jira.fe-spa.software.early-preload:end': 'EARLY_FETCH_END',
};

/**
 * Push `performance.mark` into browser-metrics marks.
 */
export const pushEarlyMarksToBrowserMetrics = (metric: MarkInterface, options?: Options) => {
	const marks = {
		...defaultMarks,
		...(options?.marks ?? {}),
	};

	Object.keys(marks).forEach((mark) => {
		const startTime = performance.getEntriesByName(mark)[0]?.startTime;
		if (startTime != null) {
			metric.mark(marks[mark], startTime);
		}
	});
};
