import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { getColumnStatusMappingUrl } from '../../../common/urls/index.tsx';
import {
	projectKeySelector,
	rapidViewIdSelector,
} from '../../../state/selectors/software/software-selectors.tsx';
import type { State } from '../../../state/types.tsx';
import ShowColumnRequired from './view.tsx';

type OwnProps = {
	canUserConfigureBoard: boolean;
	id: string | number;
	onDismissed: undefined | ((arg1: string | number) => void);
};

type StateProps = {
	boardConfigUrl: string;
};

export type Props = OwnProps & StateProps;

const mapStateToProps = (state: State): StateProps => ({
	boardConfigUrl: getColumnStatusMappingUrl(projectKeySelector(state), rapidViewIdSelector(state)),
});

export default connect(mapStateToProps, {})(ShowColumnRequired);
