import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { ConfluenceWhiteboard } from '../types.tsx';

export const createWhiteboard = async (
	cloudId: string,
	spaceId: string,
	parentId?: string | null,
	templateKey?: string,
): Promise<ConfluenceWhiteboard> =>
	fetchJson<ConfluenceWhiteboard>(`/gateway/api/ex/confluence/${cloudId}/wiki/api/v2/whiteboards`, {
		method: 'POST',
		body: JSON.stringify({
			spaceId,
			parentId: parentId || null,
			templateKey,
		}),
	});
