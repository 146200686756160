import React, { memo, useMemo } from 'react';
import type { AnalyticsSource } from '@atlassian/jira-common-constants/src/analytics-sources.tsx';
import {
	ForgeAnalyticsWrapper,
	ForgeScreenEvent as BaseForgeScreenEvent,
} from '@atlassian/jira-forge-ui-analytics/src/common/ui/index.tsx';
import { fireUiEvent } from '@atlassian/jira-forge-ui-analytics/src/common/utils/consumers/index.tsx';
import { getAnalyticsAttributesFromExtension } from '@atlassian/jira-forge-ui-analytics/src/common/utils/get-analytics-attributes-from-extension/index.tsx';
import { getEventId } from '@atlassian/jira-forge-ui-analytics/src/common/utils/index.tsx';
import { forgeModulesConcurrentLoadTime } from '@atlassian/jira-forge-ui-analytics/src/common/utils/performance-analytics/main.tsx';
import {
	MODULE_TYPE_TRIGGER,
	UI_ACTIONS,
} from '@atlassian/jira-forge-ui-analytics/src/constants.tsx';
import type {
	ForgeUiAnalyticsAttributes,
	ModuleType,
	Source,
} from '@atlassian/jira-forge-ui-types/src/common/types/analytics.tsx';
import type { ActionExtension } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import type { ExtensionPointModule } from '@atlassian/jira-forge-ui-types/src/common/types/module.tsx';

type ActionMenuAnalyticsContext = {
	analyticsSource: AnalyticsSource;
	module: ExtensionPointModule;
	extension: ActionExtension;
};

type ExtensionAnalyticsAttributes = ForgeUiAnalyticsAttributes & {
	extensionId: string;
	module: ExtensionPointModule;
	moduleType?: ModuleType;
};

const getAnalyticsAttributes = (extension: ActionExtension) => {
	const { moduleType, ...attrs } = getAnalyticsAttributesFromExtension(extension);
	return attrs;
};

export const getActionMenuAnalyticsComponents = (attributes: ExtensionAnalyticsAttributes) => {
	const { module, extensionId, source } = attributes;
	return {
		fireUiTriggerClickedEvent: makeFireUiTriggerClickedEvent(module, extensionId, source),
		ViewAnalyticsWrapper: makeViewAnalyticsWrapper(attributes),
		TriggerAnalyticsWrapper: makeTriggerAnalyticsWrapper(attributes),
		ForgeScreenEvent: makeScreenEvent(attributes),
	};
};

export const useActionMenuAnalyticsComponents = ({
	module,
	analyticsSource,
	extension,
}: ActionMenuAnalyticsContext) => {
	const extensionAnalyticsAttributes: ExtensionAnalyticsAttributes = useMemo(
		() => ({
			...getAnalyticsAttributes(extension),
			source: analyticsSource,
			module,
			extensionId: extension.id,
		}),
		[extension, analyticsSource, module],
	);

	return useMemo(
		() => getActionMenuAnalyticsComponents(extensionAnalyticsAttributes),
		[extensionAnalyticsAttributes],
	);
};

const makeViewAnalyticsWrapper = (props: ExtensionAnalyticsAttributes) => {
	return memo(({ children }: { children: React.ReactNode }) => (
		<ForgeAnalyticsWrapper {...props}>{children}</ForgeAnalyticsWrapper>
	));
};

const makeTriggerAnalyticsWrapper = (props: ExtensionAnalyticsAttributes) => {
	return memo(({ children }: { children: React.ReactNode }) => (
		<ForgeAnalyticsWrapper {...props} moduleType={MODULE_TYPE_TRIGGER}>
			{children}
		</ForgeAnalyticsWrapper>
	));
};

const makeScreenEvent = (attributes: ExtensionAnalyticsAttributes) => {
	return memo(() => <BaseForgeScreenEvent attributes={attributes} />);
};

const makeFireUiTriggerClickedEvent = (
	module: ExtensionPointModule,
	extensionId: string,
	source?: Source,
) => {
	const triggerEventId = getEventId({
		module,
		moduleType: MODULE_TYPE_TRIGGER,
	});

	return () => {
		forgeModulesConcurrentLoadTime(extensionId).start({ startTime: performance.now() });

		return fireUiEvent(UI_ACTIONS.CLICKED, {
			id: triggerEventId,
			source,
		});
	};
};
