import get from 'lodash/get';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import type { IssueMedia } from '../../../model/issue/issue-media-types.tsx';
import type { IssueMediaState } from '../../reducers/entities/issue-media/types.tsx';
import type { State } from '../../reducers/types.tsx';
import { getEntities } from '../software/software-selectors.tsx';

export const getIssuesMedia = (state: State): IssueMediaState => getEntities(state).issueMedia;

export const getIssueMedia = (state: State, issueId: IssueId): IssueMedia | null | undefined =>
	getIssuesMedia(state)[String(issueId)];

export const isIssueMediaHiddenByUser = (state: State, issueId: IssueId): boolean =>
	get(getIssueMedia(state, issueId), ['isHiddenByUser'], false);
