// SVG with custom colours

import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const CardLayoutCompactIcon = (props: CustomGlyphProps) => (
	<svg
		width="58"
		height="28"
		viewBox="0 0 58 28"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		data-testid={props['data-testid']}
		aria-label={props['aria-label']}
	>
		<path
			d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H56C56.8284 0.5 57.5 1.17157 57.5 2V9.5H0.5V2Z"
			stroke="currentColor"
		/>
		<path
			d="M57 10V18H59V10H57ZM1 18V10H-1V18H1Z"
			fill="currentColor"
			mask="url(#path-2-inside-1_1311_59)"
		/>
		<path
			d="M0.5 18.5H57.5V26C57.5 26.8284 56.8284 27.5 56 27.5H2C1.17157 27.5 0.5 26.8284 0.5 26V18.5Z"
			stroke="currentColor"
		/>
		<rect x="5" y="3" width="4" height="4" rx="1" fill="currentColor" />
		<rect x="5" y="12" width="4" height="4" rx="1" fill="currentColor" />
		<rect x="5" y="21" width="4" height="4" rx="1" fill="currentColor" />
		<rect x="12" y="4" width="41" height="2" rx="1" fill="currentColor" />
		<rect x="12" y="13" width="41" height="2" rx="1" fill="currentColor" />
		<rect x="12" y="22" width="41" height="2" rx="1" fill="currentColor" />
	</svg>
);
