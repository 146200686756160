import { getPerformanceFFsString } from '../common/util/collectors.tsx';
import { isExpandedObservationData, type ObservationData } from '../types.tsx';
import { triggerAnalytics, generateLongTaskAnalyticsSummary } from './common.tsx';

export const bentoReporter = (
	observationData: ObservationData,
	extra: {
		type: string;
	} & {
		[key: string]: boolean | number;
	},
) => {
	if (isExpandedObservationData(observationData)) {
		const triggerSoftwareAnalytics = triggerAnalytics(`software.${observationData.appName}`);
		const startTime = observationData.events.map((event) => event.timestamp);
		const analyticsData = {
			...extra,
			...generateLongTaskAnalyticsSummary('bento', observationData.events),
			measureTimespan: performance.now() - Math.min(observationData.startTimestamp, ...startTime),
			type: extra.type || 'unknown',
			performanceFFs: getPerformanceFFsString(),
		};
		triggerSoftwareAnalytics('long-tasks-bento', analyticsData);
	}
};
