import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { FREE_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';

export const useShouldApplyInviteAndAssign = () => {
	const { data: project } = useProjectContext();
	const { software } = useAppEditions();
	const isSiteAdmin = useIsSiteAdmin();

	const isJSW = project?.projectType === SOFTWARE_PROJECT;
	const isTMP = !!project?.simplified;
	const isFreeInstance = software === FREE_EDITION;

	return isJSW && isFreeInstance && isTMP && isSiteAdmin;
};
