import React, { useEffect, useMemo } from 'react';
import noop from 'lodash/noop';
import throttle from 'lodash/throttle';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { useDetailPanelActions } from '../../controllers/layout-controller/store/index.tsx';
import type { Props } from './types.tsx';

const DetailPanel = ({ children, panelWidth }: Props) => {
	const { setWidth } = useDetailPanelActions();

	useEffect(() => {
		if (panelWidth !== undefined) {
			setWidth(panelWidth);
		}

		return () => setWidth(0);
	}, [panelWidth, setWidth]);

	const throttleSetWidth = useMemo(
		() => throttle(setWidth, 50, { leading: true, trailing: true }),
		[setWidth],
	);

	return typeof children === 'function' ? children(throttleSetWidth) : children;
};

export const JiraDetailPanel = (props: Props) => (
	<ErrorBoundary
		id="jira-detail-panel"
		packageName="jira-bottom-right-corner-controller"
		render={() => (typeof props.children === 'function' ? props.children(noop) : props.children)}
	>
		<DetailPanel {...props} />
	</ErrorBoundary>
);
