export const IMPORT_IN_PROGRESS_FLAG_ID = 'import-in-progress-flag';
export const IMPORT_SUCCESS_FLAG_ID = 'import-success-flag';
export const IMPORT_PARTIAL_SUCCESS_FLAG_ID = 'import-partial-success-flag';
export const IMPORT_ADVANCED_ERRORS_FLAG_ID = 'import-advanced-errors-error-flag';

export const BASE_POLLING_INTERVAL_MS = 5 * 1000;

export const APP_IDENTIFIER = 'jira-business.project-create-import-progress';

export const CONTACT_SUPPORT_URL =
	'https://support.atlassian.com/contact/#/?inquiry_category=technical_issues&is_cloud=true&product_key=jira-work-management&support_type=customer';
