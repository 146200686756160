import React, { useCallback, type SyntheticEvent, type ComponentPropsWithoutRef } from 'react';
import { css, styled } from '@compiled/react';
import isNil from 'lodash/isNil';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { useFieldConfigWithoutRefetch } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { READ_VIEW_CONTAINER_SELECTOR } from '@atlassian/jira-issue-field-inline-edit/src/styled.tsx';
import { AsyncOriginalEstimateInlineEdit } from '@atlassian/jira-issue-field-original-estimate-inline-edit/src/ui/async.tsx';
import { OriginalEstimateInlineEditErrorBoundary } from '@atlassian/jira-issue-field-original-estimate-inline-edit/src/ui/error-boundary/index.tsx';
import {
	READ_VIEW_CONTAINER_SELECTOR as originalReadViewContainer,
	HOVER_CONTAINER_SELECTOR,
} from '@atlassian/jira-issue-field-original-estimate/src/common/constants.tsx';
import { compactWidthPixels } from '@atlassian/jira-issue-field-original-estimate/src/ui/edit/index.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { TimeEstimateKey } from '@atlassian/jira-providers-issue/src/model/issue-system-fields.tsx';
import { toIssueId, toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { EstimateFieldStatic } from '../../../../../../common/fields/estimate-field/static/index.tsx';
import { EstimateWrapper } from '../../../../../../common/fields/estimate-field/wrapper/index.tsx';
import { useEditableField } from '../../../../../../common/fields/use-editable-field/index.tsx';
import { useFireInvalidFieldConfigError } from '../../../../../../common/fields/use-fire-invalid-field-config-error/index.tsx';
import { timeTrackingConfigTransformer } from '../../../../../../common/utils/time-tracking/index.tsx';
import { PACKAGE_NAME } from '../../../../../../model/constants.tsx';
import { useBoardSelector } from '../../../../../../state/index.tsx';
import { getPreventInlineEditing } from '../../../../../../state/selectors/board/board-selectors.tsx';
import { INLINE_EDITING_FIELD_ZINDEX } from '../constants.tsx';
import { ORIGINAL_TIME_ESTIMATE_WRAPPER_TEST_ID, PLACEHOLDER } from './constants.tsx';
import type {
	OriginalEstimateFieldProps,
	OriginalEstimateFieldInnerProps,
	OriginalEstimateWrapperProps,
} from './types.tsx';

const stopPropagation = (e: SyntheticEvent<HTMLElement>) => e.stopPropagation();

const fieldKey = 'timeoriginalestimate';

const OriginalEstimateFieldInner = ({
	issueId,
	issueKey,
	timeTrackingOptions,
	estimate,
	onFailure,
	...props
}: OriginalEstimateFieldInnerProps) => {
	const preventInlineEditing = useBoardSelector((state) => getPreventInlineEditing(state));

	const { fireInvalidFieldConfigError } = useFireInvalidFieldConfigError();
	const onFailureFireError = useCallback(
		(error: Error) => {
			onFailure?.();
			fireInvalidFieldConfigError(error);
		},
		[fireInvalidFieldConfigError, onFailure],
	);
	return (
		<ContextualAnalyticsData
			attributes={{
				isInlineEditing: true,
				fieldKey,
				fieldType: fieldKey,
			}}
		>
			<OriginalEstimateWrapper
				data-testid={ORIGINAL_TIME_ESTIMATE_WRAPPER_TEST_ID}
				hasValue={!isNil(estimate)}
				onClick={stopPropagation}
				onKeyDown={stopPropagation} // Prevent Enter from opening issue when cross or tick is focused
				disableClick={preventInlineEditing}
			>
				<AsyncOriginalEstimateInlineEdit
					{...props}
					actionSubject="inlineEdit"
					isMobile={false}
					issueId={toIssueId(String(issueId))}
					issueKey={toIssueKey(issueKey)}
					config={timeTrackingConfigTransformer(timeTrackingOptions)}
					fieldId={TimeEstimateKey}
					isEditable
					isFitContainerWidthReadView
					placeholder={PLACEHOLDER}
					onFailure={onFailureFireError}
					timeTrackingValue={{}} // NOTE: time tracking is irrelevant in the inline original estimate
				/>
			</OriginalEstimateWrapper>
		</ContextualAnalyticsData>
	);
};

export const OriginalEstimateField = ({
	shouldRenderRichField,
	...props
}: OriginalEstimateFieldProps) => {
	const [{ value: originalEstimateFieldConfig }] = useFieldConfigWithoutRefetch(
		props.issueKey,
		TimeEstimateKey,
	);
	const shouldRenderRich = Boolean(shouldRenderRichField && originalEstimateFieldConfig);
	const editableField = useEditableField({
		isExperienceAvailable: shouldRenderRich,
	});
	const fallback = props.estimate ? <EstimateFieldStatic value={props.estimate} /> : null;

	if (shouldRenderRich) {
		return (
			<OriginalEstimateInlineEditErrorBoundary
				packageName={PACKAGE_NAME}
				fallback={fallback}
				onError={editableField.onError}
			>
				<UFOSegment name="ng-board.inline-edit.original-estimate-field">
					<OriginalEstimateFieldInner {...props} {...editableField} />
				</UFOSegment>
			</OriginalEstimateInlineEditErrorBoundary>
		);
	}

	return fallback;
};

// prevents warning for usage of style composition - can be removed when https://product-fabric.atlassian.net/browse/APP-728 is done.
const CompiledPropsForwarder = ({
	disableClick,
	...rest
}: OriginalEstimateWrapperProps & ComponentPropsWithoutRef<typeof EstimateWrapper>) => (
	<EstimateWrapper {...rest} />
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OriginalEstimateWrapperOld = styled(CompiledPropsForwarder)<OriginalEstimateWrapperProps>(
	{
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		[`&:not(:has(${originalReadViewContainer}, ${READ_VIEW_CONTAINER_SELECTOR}))`]: {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			left: `calc(100% - ${compactWidthPixels + gridSize * 1.75}px)`,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			zIndex: INLINE_EDITING_FIELD_ZINDEX,
		},

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		[READ_VIEW_CONTAINER_SELECTOR]: {
			wordBreak: 'normal',
			marginLeft: 0,
			marginBottom: 0,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			marginTop: `${gridSize * 0.125}px`, // reverse the margin-top added from BacklogStylesWrapper
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			marginRight: `${gridSize * 0.25}px`,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			[HOVER_CONTAINER_SELECTOR]: {
				lineHeight: 1,
			},
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ disableClick }) =>
		disableClick &&
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		css({
			pointerEvents: 'none',
		}),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OriginalEstimateWrapperNew = styled(CompiledPropsForwarder)<OriginalEstimateWrapperProps>(
	{
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		[`&:not(:has(${originalReadViewContainer}, ${READ_VIEW_CONTAINER_SELECTOR}))`]: {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			left: `calc(100% - ${compactWidthPixels + gridSize}px)`,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			zIndex: INLINE_EDITING_FIELD_ZINDEX,
		},

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		[READ_VIEW_CONTAINER_SELECTOR]: {
			wordBreak: 'normal',
			marginLeft: 0,
			marginBottom: 0,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			marginTop: `${gridSize * 0.125}px`, // reverse the margin-top added from BacklogStylesWrapper
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			marginRight: `${gridSize * 0.25}px`,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			[HOVER_CONTAINER_SELECTOR]: {
				lineHeight: 1,
			},
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ disableClick }) =>
		disableClick &&
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		css({
			pointerEvents: 'none',
		}),
);

const OriginalEstimateWrapper = componentWithFG(
	'fix_jira_board_card_nested_interactive_elements',
	OriginalEstimateWrapperNew,
	OriginalEstimateWrapperOld,
);
