import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type {
	LastAction,
	MenuItem,
	MenuSection,
	OpenMenuParams,
	State,
} from '../../common/types.tsx';
import type { ContextMenuAction } from './types.tsx';
import { fireOpenMenuAnalyticsEvent } from './utils.tsx';

export const getInitialState = (): State => ({
	menus: {},
	openMenu: null,
	analytics: {
		selectedCardIds: [],
	},
	lastAction: { action: 'init', scope: undefined },
});

export const reset =
	(lastAction?: LastAction): ContextMenuAction =>
	({ setState }) => {
		const { analytics, ...initialState } = getInitialState();
		initialState.lastAction = lastAction || { action: 'reset' };
		setState(initialState);
	};

const setPersistentFocus =
	(persistentFocus: boolean): ContextMenuAction =>
	({ setState }) => {
		setState({
			persistentFocus,
		});
	};

const openMenu =
	({
		position,
		scope,
		analyticsEvent,
		persistentFocus = undefined,
		openedFrom,
	}: OpenMenuParams): ContextMenuAction =>
	({ setState }, { selectedCardIds, cardId }) => {
		fireOpenMenuAnalyticsEvent({ event: analyticsEvent, selectedCardIds, cardId, openedFrom });
		setState({
			openMenu: scope,
			persistentFocus,
			menus: {
				[scope]: {
					openPath: undefined,
					scope,
					position,
					forcedFocusPath: undefined,
				},
			},
			lastAction: { action: 'openMenu', scope },
		});
	};

export const openSubMenu =
	(scope: string, menuItem: MenuItem | MenuSection): ContextMenuAction =>
	({ setState, getState }) => {
		const { menus, lastAction } = getState();
		const menuState = menus[scope];

		if (!menuState) {
			if (lastAction?.action === 'closeMenu') {
				return;
			}

			const errorMessage = `Menu state is not defined - last action(${lastAction?.action}) scopes(${lastAction?.scope}-${scope})`;
			const error = new Error(errorMessage);

			fireErrorAnalytics({
				error,
				meta: {
					id: 'jira-software-context-menu-controller-open-sub-menu-error',
					packageName: 'jiraSoftwareContextMenu',
					teamName: 'deliveroo',
				},
			});

			return;
		}
		setState({
			menus: {
				[scope]: {
					...menuState,
					openPath: menuItem.id,
				},
			},
			lastAction: { action: 'openSubMenu', scope },
		});
	};

export const closeMenu =
	(scope: string): ContextMenuAction =>
	({ getState, dispatch }) => {
		const { menus, lastAction } = getState();
		const menuState = menus[scope];
		if (!menuState) {
			if (lastAction?.action === 'closeMenu') {
				return;
			}
			const errorMessage = `Menu state is not defined - last action(${lastAction?.action}) scopes(${lastAction?.scope}-${scope})`;
			const error = new Error(errorMessage);

			fireErrorAnalytics({
				error,
				meta: {
					id: 'jira-software-context-menu-controller-close-menu-error',
					packageName: 'jiraSoftwareContextMenu',
					teamName: 'deliveroo',
				},
			});

			return;
		}

		dispatch(reset({ action: 'closeMenu', scope }));
	};

const closeSubMenu =
	(scope: string): ContextMenuAction =>
	({ setState, getState }) => {
		const state = getState();
		const menuState = state.menus[scope];
		if (!menuState) {
			if (state.lastAction?.action === 'closeMenu') {
				return;
			}
			const errorMessage = `Menu state is not defined - last action(${state.lastAction?.action}) scopes(${state.lastAction?.scope}-${scope})`;
			const error = new Error(errorMessage);

			fireErrorAnalytics({
				error,
				meta: {
					id: 'jira-software-context-menu-controller-close-sub-menu-error',
					packageName: 'jiraSoftwareContextMenu',
					teamName: 'deliveroo',
				},
			});

			return;
		}

		setState({
			menus: {
				[scope]: {
					...menuState,
					openPath: undefined,
				},
			},
			lastAction: { action: 'closeSubMenu', scope },
		});
	};

const setForcedFocusPath =
	(scope: string, id: string | undefined): ContextMenuAction =>
	({ setState, getState }) => {
		const state = getState();
		const menuState = state.menus[scope];
		if (!menuState) {
			if (state.lastAction?.action === 'closeMenu') {
				return;
			}
			const errorMessage = `Menu state is not defined - last action(${state.lastAction?.action}) scopes(${state.lastAction?.scope}-${scope}`;
			const error = new Error(errorMessage);

			fireErrorAnalytics({
				error,
				meta: {
					id: 'jira-software-context-menu-controller-set-focused-path-error',
					packageName: 'jiraSoftwareContextMenu',
					teamName: 'deliveroo',
				},
			});

			return;
		}

		setState({
			menus: {
				[scope]: {
					...menuState,
					forcedFocusPath: id,
				},
			},
			lastAction: { action: 'setForcedFocusPath', scope },
		});
	};

export const actions = {
	openMenu,
	closeMenu,
	openSubMenu,
	closeSubMenu,
	setForcedFocusPath,
	reset,
	setPersistentFocus,
} as const;

export type Actions = typeof actions;
