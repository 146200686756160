import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { getLocale } from '../../../state/selectors/software/software-selectors.tsx';
import { remainingDaysSelector } from '../../../state/selectors/sprint/sprint-selectors.tsx';
import type { State } from '../../../state/types.tsx';
import SprintRemainingDays, { type Props } from './view.tsx';

export default connect((state: State): Props => {
	const { remainingDays, startDate, endDate } = remainingDaysSelector(state);
	const locale = getLocale(state);

	return {
		remainingDays,
		startDate,
		endDate,
		locale,
	};
}, {})(SprintRemainingDays);
