import type { GlobalIssueCreateInputs } from '../../../model/global-issue-create/types.tsx';

export const OPEN_GLOBAL_ISSUE_CREATE =
	'state.actions.global-issue-create.OPEN_GLOBAL_ISSUE_CREATE' as const;

export type OpenGlobalIssueCreateAction = {
	type: typeof OPEN_GLOBAL_ISSUE_CREATE;
	payload: GlobalIssueCreateInputs;
};

export const openGlobalIssueCreate = (
	inputs: GlobalIssueCreateInputs,
): OpenGlobalIssueCreateAction => ({
	type: OPEN_GLOBAL_ISSUE_CREATE,
	payload: inputs,
});

export const CLOSE_GLOBAL_ISSUE_CREATE =
	'state.actions.global-issue-create.CLOSE_GLOBAL_ISSUE_CREATE' as const;

export type CloseGlobalIssueCreateAction = {
	type: typeof CLOSE_GLOBAL_ISSUE_CREATE;
};

export const closeGlobalIssueCreate = (): CloseGlobalIssueCreateAction => ({
	type: CLOSE_GLOBAL_ISSUE_CREATE,
});

export type Action = OpenGlobalIssueCreateAction | CloseGlobalIssueCreateAction;
