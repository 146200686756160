import {
	ChangeEventTypes,
	type ChangeEvent,
} from '@atlassian/jira-issue-view-model/src/change-type.tsx';
import { REFRESH_SOURCE_BENTO } from '../../../model/constants.tsx';
import type { CoreIssue } from '../../../model/issue/issue-types.tsx';
import { workRefreshData } from '../../../state/actions/work/index.tsx';

export const mapBentoResponseToMissingParent = (missingParent: CoreIssue, payload: ChangeEvent) => {
	if (!payload.type || !missingParent) {
		return null;
	}

	/**
	 * we care about four fields change on the missing parent Bento
	 * 1.summary
	 * 2. status
	 * 3. assignee
	 * 4. epic
	 * Since the epic field is a custom field e.g. customfield_10014,
	 * we do backlisting instead of whitelisting
	 */
	const ignoreFields = [
		'fixVersions',
		'labels',
		'priority',
		'timeoriginalestimate',
		'description',
		'votes',
	];
	const { type, meta } = payload;

	const monitoringTypes = [
		ChangeEventTypes.ISSUE_TYPE_CHANGED, // change issue type
		ChangeEventTypes.SUBTASK_ADDED, // add new subtask
		ChangeEventTypes.CHILD_ISSUE_FIELD_UPDATED, // subtask issue field update e.g. assignee, status
	] as const;

	if (
		monitoringTypes.find((monitoringType) => monitoringType === type) ||
		(type === ChangeEventTypes.FIELD_CHANGED && !ignoreFields.includes(meta.fieldId))
	) {
		return workRefreshData(REFRESH_SOURCE_BENTO);
	}

	return null;
};
