export const BACKLOG = 'backlog';

export const BOARD = 'board';

export const UNKNOWN_LOCATION = 'unknown';

export const CLASSIC_BACKLOG_ROUTE_NAME = 'classic-software:rapidboard-backlog';

export const CLASSIC_BOARD_ROUTE_NAME = 'classic-software:rapidboard-board';

export const NEXT_GEN_BACKLOG_ROUTE_NAME = 'software:software-backlog';

export const NEXT_GEN_BOARD_ROUTE_NAME = 'software:software-boards';
