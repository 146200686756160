import type { ComponentPropsWithRef, ReactElement } from 'react';
import type { ColumnTheme } from '@atlassian/jira-platform-board-kit/src/common/types.tsx';
import type ColumnHeader from '@atlassian/jira-platform-board-kit/src/common/ui/column-header/main.tsx';
import SwimlaneColumnHeader from '@atlassian/jira-platform-board-kit/src/ui/swimlane-column-header/index.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { Capability } from '../../../../common/capability/index.tsx';
import { CAN_RANK_COLUMNS } from '../../../../model/permission/permission-types.tsx';
import { getPermissionsSelector } from '../../../../state/selectors/board/board-permissions-selectors.tsx';
import { getCapability } from '../../../../state/selectors/capability/capability-selectors.tsx';
import {
	getColumnTheme,
	isColumnUpdating,
	isInlineColumnEditEnabled,
} from '../../../../state/selectors/work/work-selectors.tsx';
import type { State } from '../../../../state/types.tsx';

type OwnProps = {
	id: number | string;
	draggableIndex: number | null;
	header?: ReactElement<ComponentPropsWithRef<typeof ColumnHeader>> | null | undefined;
};

type StateProps = {
	isUpdating: boolean;
	isDraggable: boolean;
	draggableIndex: number | null;
	flexibleColumns?: boolean | undefined;
	id: string | number;
	header?: ReactElement<ComponentPropsWithRef<typeof ColumnHeader>> | null | undefined;
	appearance?: ColumnTheme;
};

export const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	const { id } = ownProps;

	const isDraggable =
		(getPermissionsSelector(state)[CAN_RANK_COLUMNS] && isInlineColumnEditEnabled(state)) || false;

	return {
		isDraggable,
		flexibleColumns: getCapability(state)(Capability.FLEXIBLE_BOARD_COLUMNS),
		appearance: getColumnTheme(state, Number(id)),
		isUpdating: isColumnUpdating(state)(id),
		// passing ownProps
		id: ownProps.id,
		draggableIndex: ownProps.draggableIndex,
		header: ownProps.header || null,
	};
};

export default connect(mapStateToProps, {})(SwimlaneColumnHeader);
