import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'software-board-clearing.common.undo-error-flag.title',
		defaultMessage: 'We couldn’t restore your cleared issues.',
		description: 'Title of the message when the undo operation of a manual board clearing fails',
	},
	description: {
		id: 'software-board-clearing.common.undo-error-flag.description',
		defaultMessage: 'Please check your connection.',
		description: 'Message to try the undo operation again',
	},
	tryAgain: {
		id: 'software-board-clearing.common.undo-error-flag.try-again',
		defaultMessage: 'Try again',
		description: 'Link text that the user will need to click to undo the board clearing (on retry)',
	},
	titleIssueTermRefresh: {
		id: 'software-board-clearing.common.undo-error-flag.title-issue-term-refresh',
		defaultMessage: 'We couldn’t restore your cleared work items.',
		description: 'Title of the message when the undo operation of a manual board clearing fails',
	},
});
