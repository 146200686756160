import React from 'react';
import { ErrorFlag } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import flagMessages from '../messages.tsx';

type Props = {
	canUserConfigureBoard: boolean;
	id: string | number;
	boardConfigUrl: string;
	onDismissed: undefined | ((arg1: string | number) => void);
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ onDismissed, id, boardConfigUrl, canUserConfigureBoard }: Props) => {
	const { formatMessage } = useIntl();

	const actions = canUserConfigureBoard
		? [
				{
					content: formatMessage(flagMessages.manageStatusesLink),
					href: boardConfigUrl,
				},
			]
		: [];
	return (
		<ErrorFlag
			id={id}
			onDismissed={onDismissed}
			title={formatMessage(flagMessages.noColumnFailureTitle)}
			description={formatMessage(
				canUserConfigureBoard
					? flagMessages.noColumnFailureAdminDescription
					: flagMessages.noColumnFailureNonAdminDescription,
			)}
			actions={actions}
		/>
	);
};
