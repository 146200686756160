/**
 * @generated SignedSource<<3a7e7a486467a3013ee642ee950bfe70>>
 * @relayHash 1b45667561f9430ea4e961757485983b
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ab366f74068bb048193650cda464a839daec6f71dd9d1e7ee8874e9f9959ba67

import type { ConcreteRequest, Query } from 'relay-runtime';
export type BoardFeatureStatus = "COMING_SOON" | "DISABLED" | "ENABLED" | "%future added value";
export type BoardFeatureToggleStatus = "DISABLED" | "ENABLED" | "%future added value";
export type SwimlaneStrategy = "ASSIGNEE" | "ISSUECHILDREN" | "ISSUEPARENT" | "NONE" | "%future added value";
export type boardWithRouterRelayQuery$variables = {
  boardId: string;
};
export type boardWithRouterRelayQuery$data = {
  readonly boardScope: {
    readonly board: {
      readonly assignees: ReadonlyArray<{
        readonly accountId: string;
        readonly name: string;
        readonly picture: AGG$URL;
      } | null | undefined>;
      readonly cardChildren: ReadonlyArray<{
        readonly assignee: {
          readonly accountId: string;
          readonly name: string;
          readonly picture: AGG$URL;
        } | null | undefined;
        readonly done: boolean;
        readonly dueDate: string | null | undefined;
        readonly estimate: {
          readonly originalEstimate: {
            readonly value: number;
            readonly valueAsText: string;
          } | null | undefined;
          readonly storyPoints: number | null | undefined;
        } | null | undefined;
        readonly flagged: boolean;
        readonly id: string;
        readonly key: string;
        readonly labels: ReadonlyArray<string | null | undefined>;
        readonly parentId: string | null | undefined;
        readonly priority: {
          readonly iconUrl: string;
          readonly name: string;
        } | null | undefined;
        readonly status: {
          readonly id: string;
        };
        readonly summary: string;
        readonly type: {
          readonly iconUrl: string;
          readonly id: string;
          readonly name: string;
        };
      } | null | undefined>;
      readonly cardTypes: ReadonlyArray<{
        readonly hierarchyLevelType: AGG$CardTypeHierarchyLevelType;
        readonly iconUrl: string;
        readonly id: string;
        readonly name: string;
      } | null | undefined>;
      readonly cards: ReadonlyArray<{
        readonly id: string;
      } | null | undefined>;
      readonly columns: ReadonlyArray<{
        readonly cards: ReadonlyArray<{
          readonly activeSprint: {
            readonly id: string;
          } | null | undefined;
          readonly assignee: {
            readonly accountId: string;
          } | null | undefined;
          readonly childCardsMetadata: {
            readonly complete: number;
            readonly total: number;
          } | null | undefined;
          readonly done: boolean;
          readonly dueDate: string | null | undefined;
          readonly estimate: {
            readonly originalEstimate: {
              readonly value: number;
              readonly valueAsText: string;
            } | null | undefined;
            readonly storyPoints: number | null | undefined;
          } | null | undefined;
          readonly flagged: boolean;
          readonly id: string;
          readonly key: string;
          readonly labels: ReadonlyArray<string | null | undefined>;
          readonly parentId: string | null | undefined;
          readonly priority: {
            readonly iconUrl: string;
            readonly name: string;
          } | null | undefined;
          readonly status: {
            readonly id: string;
          };
          readonly summary: string;
          readonly type: {
            readonly iconUrl: string;
            readonly id: string;
            readonly name: string;
          };
        } | null | undefined>;
        readonly columnStatus: ReadonlyArray<{
          readonly cardTypeTransitions: ReadonlyArray<{
            readonly cardType: {
              readonly id: string;
            };
            readonly hasConditions: boolean;
            readonly isGlobal: boolean;
            readonly isInitial: boolean;
            readonly name: string;
            readonly originStatus: {
              readonly id: string;
            } | null | undefined;
            readonly status: {
              readonly id: string;
            };
            readonly transitionId: string;
          }>;
          readonly status: {
            readonly category: string;
            readonly id: string;
            readonly name: string;
          };
        }>;
        readonly id: string;
        readonly isDone: boolean;
        readonly isInitial: boolean;
        readonly maxCardCount: number | null | undefined;
        readonly name: string;
      } | null | undefined>;
      readonly editConfig: {
        readonly inlineCardCreate: {
          readonly enabled: boolean;
        };
        readonly inlineColumnEdit: {
          readonly enabled: boolean;
        };
      };
      readonly hasClearedCards: boolean;
      readonly inlineCardCreate: {
        readonly enabled: boolean;
      };
      readonly name: string;
      readonly rankCustomFieldId: string;
      readonly swimlaneStrategy: SwimlaneStrategy;
    };
    readonly cardParents: ReadonlyArray<{
      readonly cardType: {
        readonly iconUrl: string;
        readonly id: string;
        readonly name: string;
      };
      readonly color: AGG$CardPaletteColor | null | undefined;
      readonly id: string;
      readonly key: string;
      readonly status: {
        readonly category: string;
        readonly id: string;
        readonly name: string;
      } | null | undefined;
      readonly summary: string;
    } | null | undefined>;
    readonly currentUser: {
      readonly permissions: ReadonlyArray<AGG$SoftwareBoardPermission | null | undefined>;
    };
    readonly estimation: {
      readonly current: {
        readonly customFieldId: string | null | undefined;
      };
    } | null | undefined;
    readonly features: ReadonlyArray<{
      readonly category: string;
      readonly key: AGG$SoftwareBoardFeatureKey;
      readonly status: BoardFeatureStatus;
      readonly toggle: BoardFeatureToggleStatus;
    } | null | undefined>;
    readonly projectLocation: {
      readonly cardTypes: ReadonlyArray<{
        readonly hierarchyLevelType: AGG$CardTypeHierarchyLevelType;
        readonly iconUrl: string;
        readonly id: string;
        readonly name: string;
      } | null | undefined>;
      readonly id: string;
      readonly key: string;
      readonly name: string;
    };
    readonly sprints: ReadonlyArray<{
      readonly cards: ReadonlyArray<{
        readonly id: string;
      } | null | undefined>;
      readonly daysRemaining: number;
      readonly endDate: AGG$DateTime;
      readonly goal: string | null | undefined;
      readonly id: string;
      readonly name: string;
      readonly startDate: AGG$DateTime;
    } | null | undefined>;
    readonly userSwimlaneStrategy: SwimlaneStrategy | null | undefined;
  };
};
export type boardWithRouterRelayQuery = {
  response: boardWithRouterRelayQuery$data;
  variables: boardWithRouterRelayQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "boardId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "boardId",
    "variableName": "boardId"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "userSwimlaneStrategy"
},
v3 = {
  "kind": "ScalarField",
  "name": "name"
},
v4 = {
  "kind": "ScalarField",
  "name": "swimlaneStrategy"
},
v5 = {
  "kind": "ScalarField",
  "name": "hasClearedCards"
},
v6 = {
  "kind": "ScalarField",
  "name": "rankCustomFieldId"
},
v7 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v8 = {
  "kind": "ScalarField",
  "name": "picture"
},
v9 = {
  "kind": "ScalarField",
  "name": "id"
},
v10 = {
  "kind": "ScalarField",
  "name": "maxCardCount"
},
v11 = {
  "kind": "ScalarField",
  "name": "category"
},
v12 = {
  "kind": "ScalarField",
  "name": "transitionId"
},
v13 = {
  "kind": "ScalarField",
  "name": "isGlobal"
},
v14 = {
  "kind": "ScalarField",
  "name": "isInitial"
},
v15 = {
  "kind": "ScalarField",
  "name": "hasConditions"
},
v16 = {
  "kind": "ScalarField",
  "name": "isDone"
},
v17 = {
  "kind": "ScalarField",
  "name": "flagged"
},
v18 = {
  "kind": "ScalarField",
  "name": "done"
},
v19 = {
  "kind": "ScalarField",
  "name": "parentId"
},
v20 = {
  "kind": "ScalarField",
  "name": "storyPoints"
},
v21 = {
  "kind": "ScalarField",
  "name": "value"
},
v22 = {
  "kind": "ScalarField",
  "name": "valueAsText"
},
v23 = {
  "kind": "ScalarField",
  "name": "key"
},
v24 = {
  "kind": "ScalarField",
  "name": "summary"
},
v25 = {
  "kind": "ScalarField",
  "name": "labels"
},
v26 = {
  "kind": "ScalarField",
  "name": "iconUrl"
},
v27 = {
  "kind": "ScalarField",
  "name": "dueDate"
},
v28 = {
  "kind": "ScalarField",
  "name": "complete"
},
v29 = {
  "kind": "ScalarField",
  "name": "total"
},
v30 = {
  "kind": "ScalarField",
  "name": "hierarchyLevelType"
},
v31 = {
  "kind": "ScalarField",
  "name": "enabled"
},
v32 = [
  {
    "kind": "Literal",
    "name": "state",
    "value": [
      "ACTIVE"
    ]
  }
],
v33 = {
  "kind": "ScalarField",
  "name": "goal"
},
v34 = {
  "kind": "ScalarField",
  "name": "startDate"
},
v35 = {
  "kind": "ScalarField",
  "name": "endDate"
},
v36 = {
  "kind": "ScalarField",
  "name": "daysRemaining"
},
v37 = {
  "kind": "ScalarField",
  "name": "status"
},
v38 = {
  "kind": "ScalarField",
  "name": "toggle"
},
v39 = {
  "kind": "ScalarField",
  "name": "color"
},
v40 = {
  "kind": "ScalarField",
  "name": "permissions"
},
v41 = {
  "concreteType": "EstimationConfig",
  "kind": "LinkedField",
  "name": "estimation",
  "plural": false,
  "selections": [
    {
      "concreteType": "CurrentEstimation",
      "kind": "LinkedField",
      "name": "current",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "customFieldId"
        }
      ]
    }
  ]
},
v42 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v43 = [
  (v42/*: any*/),
  (v7/*: any*/),
  (v3/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/)
],
v44 = {
  "concreteType": "CardStatus",
  "kind": "LinkedField",
  "name": "status",
  "plural": false,
  "selections": [
    (v9/*: any*/),
    (v3/*: any*/),
    (v11/*: any*/)
  ]
},
v45 = [
  (v9/*: any*/)
],
v46 = {
  "concreteType": "CardStatus",
  "kind": "LinkedField",
  "name": "status",
  "plural": false,
  "selections": (v45/*: any*/)
},
v47 = {
  "concreteType": "Estimate",
  "kind": "LinkedField",
  "name": "estimate",
  "plural": false,
  "selections": [
    (v20/*: any*/),
    {
      "concreteType": "OriginalEstimate",
      "kind": "LinkedField",
      "name": "originalEstimate",
      "plural": false,
      "selections": [
        (v21/*: any*/),
        (v22/*: any*/)
      ]
    }
  ]
},
v48 = [
  (v9/*: any*/),
  (v3/*: any*/),
  (v26/*: any*/)
],
v49 = {
  "concreteType": "CardType",
  "kind": "LinkedField",
  "name": "type",
  "plural": false,
  "selections": (v48/*: any*/)
},
v50 = {
  "concreteType": "CardPriority",
  "kind": "LinkedField",
  "name": "priority",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v26/*: any*/)
  ]
},
v51 = {
  "concreteType": "CardType",
  "kind": "LinkedField",
  "name": "cardTypes",
  "plural": true,
  "selections": [
    (v9/*: any*/),
    (v3/*: any*/),
    (v26/*: any*/),
    (v30/*: any*/)
  ]
},
v52 = [
  (v31/*: any*/)
],
v53 = {
  "concreteType": "InlineCardCreateConfig",
  "kind": "LinkedField",
  "name": "inlineCardCreate",
  "plural": false,
  "selections": (v52/*: any*/)
},
v54 = {
  "concreteType": "SoftwareCard",
  "kind": "LinkedField",
  "name": "cards",
  "plural": true,
  "selections": (v45/*: any*/)
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "boardWithRouterRelayQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "args": (v1/*: any*/),
          "concreteType": "BoardScope",
          "kind": "LinkedField",
          "name": "boardScope",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "kind": "RequiredField",
              "field": {
                "concreteType": "SoftwareBoard",
                "kind": "LinkedField",
                "name": "board",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v3/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.board.name"
                  },
                  {
                    "kind": "RequiredField",
                    "field": (v4/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.board.swimlaneStrategy"
                  },
                  {
                    "kind": "RequiredField",
                    "field": (v5/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.board.hasClearedCards"
                  },
                  {
                    "kind": "RequiredField",
                    "field": (v6/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.board.rankCustomFieldId"
                  },
                  {
                    "kind": "RequiredField",
                    "field": {
                      "kind": "LinkedField",
                      "name": "assignees",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": (v7/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.board.assignees.accountId"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v3/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.board.assignees.name"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v8/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.board.assignees.picture"
                        }
                      ]
                    },
                    "action": "THROW",
                    "path": "boardScope.board.assignees"
                  },
                  {
                    "kind": "RequiredField",
                    "field": {
                      "concreteType": "Column",
                      "kind": "LinkedField",
                      "name": "columns",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": (v9/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.board.columns.id"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v3/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.board.columns.name"
                        },
                        (v10/*: any*/),
                        {
                          "kind": "RequiredField",
                          "field": {
                            "concreteType": "ColumnStatus",
                            "kind": "LinkedField",
                            "name": "columnStatus",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": {
                                  "concreteType": "CardStatus",
                                  "kind": "LinkedField",
                                  "name": "status",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "kind": "RequiredField",
                                      "field": (v9/*: any*/),
                                      "action": "THROW",
                                      "path": "boardScope.board.columns.columnStatus.status.id"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v3/*: any*/),
                                      "action": "THROW",
                                      "path": "boardScope.board.columns.columnStatus.status.name"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v11/*: any*/),
                                      "action": "THROW",
                                      "path": "boardScope.board.columns.columnStatus.status.category"
                                    }
                                  ]
                                },
                                "action": "THROW",
                                "path": "boardScope.board.columns.columnStatus.status"
                              },
                              {
                                "kind": "RequiredField",
                                "field": {
                                  "concreteType": "SoftwareCardTypeTransition",
                                  "kind": "LinkedField",
                                  "name": "cardTypeTransitions",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "kind": "RequiredField",
                                      "field": (v12/*: any*/),
                                      "action": "THROW",
                                      "path": "boardScope.board.columns.columnStatus.cardTypeTransitions.transitionId"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v3/*: any*/),
                                      "action": "THROW",
                                      "path": "boardScope.board.columns.columnStatus.cardTypeTransitions.name"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": {
                                        "concreteType": "CardStatus",
                                        "kind": "LinkedField",
                                        "name": "status",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "RequiredField",
                                            "field": (v9/*: any*/),
                                            "action": "THROW",
                                            "path": "boardScope.board.columns.columnStatus.cardTypeTransitions.status.id"
                                          }
                                        ]
                                      },
                                      "action": "THROW",
                                      "path": "boardScope.board.columns.columnStatus.cardTypeTransitions.status"
                                    },
                                    {
                                      "concreteType": "CardStatus",
                                      "kind": "LinkedField",
                                      "name": "originStatus",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "kind": "RequiredField",
                                          "field": (v9/*: any*/),
                                          "action": "THROW",
                                          "path": "boardScope.board.columns.columnStatus.cardTypeTransitions.originStatus.id"
                                        }
                                      ]
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": {
                                        "concreteType": "CardType",
                                        "kind": "LinkedField",
                                        "name": "cardType",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "RequiredField",
                                            "field": (v9/*: any*/),
                                            "action": "THROW",
                                            "path": "boardScope.board.columns.columnStatus.cardTypeTransitions.cardType.id"
                                          }
                                        ]
                                      },
                                      "action": "THROW",
                                      "path": "boardScope.board.columns.columnStatus.cardTypeTransitions.cardType"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v13/*: any*/),
                                      "action": "THROW",
                                      "path": "boardScope.board.columns.columnStatus.cardTypeTransitions.isGlobal"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v14/*: any*/),
                                      "action": "THROW",
                                      "path": "boardScope.board.columns.columnStatus.cardTypeTransitions.isInitial"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v15/*: any*/),
                                      "action": "THROW",
                                      "path": "boardScope.board.columns.columnStatus.cardTypeTransitions.hasConditions"
                                    }
                                  ]
                                },
                                "action": "THROW",
                                "path": "boardScope.board.columns.columnStatus.cardTypeTransitions"
                              }
                            ]
                          },
                          "action": "THROW",
                          "path": "boardScope.board.columns.columnStatus"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v16/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.board.columns.isDone"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v14/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.board.columns.isInitial"
                        },
                        {
                          "kind": "RequiredField",
                          "field": {
                            "concreteType": "SoftwareCard",
                            "kind": "LinkedField",
                            "name": "cards",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": (v9/*: any*/),
                                "action": "THROW",
                                "path": "boardScope.board.columns.cards.id"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v17/*: any*/),
                                "action": "THROW",
                                "path": "boardScope.board.columns.cards.flagged"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v18/*: any*/),
                                "action": "THROW",
                                "path": "boardScope.board.columns.cards.done"
                              },
                              (v19/*: any*/),
                              {
                                "concreteType": "Sprint",
                                "kind": "LinkedField",
                                "name": "activeSprint",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "RequiredField",
                                    "field": (v9/*: any*/),
                                    "action": "THROW",
                                    "path": "boardScope.board.columns.cards.activeSprint.id"
                                  }
                                ]
                              },
                              {
                                "concreteType": "Estimate",
                                "kind": "LinkedField",
                                "name": "estimate",
                                "plural": false,
                                "selections": [
                                  (v20/*: any*/),
                                  {
                                    "concreteType": "OriginalEstimate",
                                    "kind": "LinkedField",
                                    "name": "originalEstimate",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "RequiredField",
                                        "field": (v21/*: any*/),
                                        "action": "THROW",
                                        "path": "boardScope.board.columns.cards.estimate.originalEstimate.value"
                                      },
                                      {
                                        "kind": "RequiredField",
                                        "field": (v22/*: any*/),
                                        "action": "THROW",
                                        "path": "boardScope.board.columns.cards.estimate.originalEstimate.valueAsText"
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v23/*: any*/),
                                "action": "THROW",
                                "path": "boardScope.board.columns.cards.key"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v24/*: any*/),
                                "action": "THROW",
                                "path": "boardScope.board.columns.cards.summary"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v25/*: any*/),
                                "action": "THROW",
                                "path": "boardScope.board.columns.cards.labels"
                              },
                              {
                                "kind": "LinkedField",
                                "name": "assignee",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "RequiredField",
                                    "field": (v7/*: any*/),
                                    "action": "THROW",
                                    "path": "boardScope.board.columns.cards.assignee.accountId"
                                  }
                                ]
                              },
                              {
                                "kind": "RequiredField",
                                "field": {
                                  "concreteType": "CardType",
                                  "kind": "LinkedField",
                                  "name": "type",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "kind": "RequiredField",
                                      "field": (v9/*: any*/),
                                      "action": "THROW",
                                      "path": "boardScope.board.columns.cards.type.id"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v3/*: any*/),
                                      "action": "THROW",
                                      "path": "boardScope.board.columns.cards.type.name"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v26/*: any*/),
                                      "action": "THROW",
                                      "path": "boardScope.board.columns.cards.type.iconUrl"
                                    }
                                  ]
                                },
                                "action": "THROW",
                                "path": "boardScope.board.columns.cards.type"
                              },
                              {
                                "kind": "RequiredField",
                                "field": {
                                  "concreteType": "CardStatus",
                                  "kind": "LinkedField",
                                  "name": "status",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "kind": "RequiredField",
                                      "field": (v9/*: any*/),
                                      "action": "THROW",
                                      "path": "boardScope.board.columns.cards.status.id"
                                    }
                                  ]
                                },
                                "action": "THROW",
                                "path": "boardScope.board.columns.cards.status"
                              },
                              {
                                "concreteType": "CardPriority",
                                "kind": "LinkedField",
                                "name": "priority",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "RequiredField",
                                    "field": (v3/*: any*/),
                                    "action": "THROW",
                                    "path": "boardScope.board.columns.cards.priority.name"
                                  },
                                  {
                                    "kind": "RequiredField",
                                    "field": (v26/*: any*/),
                                    "action": "THROW",
                                    "path": "boardScope.board.columns.cards.priority.iconUrl"
                                  }
                                ]
                              },
                              (v27/*: any*/),
                              {
                                "concreteType": "ChildCardsMetadata",
                                "kind": "LinkedField",
                                "name": "childCardsMetadata",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "RequiredField",
                                    "field": (v28/*: any*/),
                                    "action": "THROW",
                                    "path": "boardScope.board.columns.cards.childCardsMetadata.complete"
                                  },
                                  {
                                    "kind": "RequiredField",
                                    "field": (v29/*: any*/),
                                    "action": "THROW",
                                    "path": "boardScope.board.columns.cards.childCardsMetadata.total"
                                  }
                                ]
                              }
                            ]
                          },
                          "action": "THROW",
                          "path": "boardScope.board.columns.cards"
                        }
                      ]
                    },
                    "action": "THROW",
                    "path": "boardScope.board.columns"
                  },
                  {
                    "kind": "RequiredField",
                    "field": {
                      "concreteType": "CardType",
                      "kind": "LinkedField",
                      "name": "cardTypes",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": (v9/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.board.cardTypes.id"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v3/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.board.cardTypes.name"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v26/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.board.cardTypes.iconUrl"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v30/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.board.cardTypes.hierarchyLevelType"
                        }
                      ]
                    },
                    "action": "THROW",
                    "path": "boardScope.board.cardTypes"
                  },
                  {
                    "kind": "RequiredField",
                    "field": {
                      "concreteType": "InlineCardCreateConfig",
                      "kind": "LinkedField",
                      "name": "inlineCardCreate",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": (v31/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.board.inlineCardCreate.enabled"
                        }
                      ]
                    },
                    "action": "THROW",
                    "path": "boardScope.board.inlineCardCreate"
                  },
                  {
                    "kind": "RequiredField",
                    "field": {
                      "concreteType": "SoftwareCard",
                      "kind": "LinkedField",
                      "name": "cardChildren",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": (v9/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.board.cardChildren.id"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v17/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.board.cardChildren.flagged"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v18/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.board.cardChildren.done"
                        },
                        (v19/*: any*/),
                        {
                          "concreteType": "Estimate",
                          "kind": "LinkedField",
                          "name": "estimate",
                          "plural": false,
                          "selections": [
                            (v20/*: any*/),
                            {
                              "concreteType": "OriginalEstimate",
                              "kind": "LinkedField",
                              "name": "originalEstimate",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "RequiredField",
                                  "field": (v21/*: any*/),
                                  "action": "THROW",
                                  "path": "boardScope.board.cardChildren.estimate.originalEstimate.value"
                                },
                                {
                                  "kind": "RequiredField",
                                  "field": (v22/*: any*/),
                                  "action": "THROW",
                                  "path": "boardScope.board.cardChildren.estimate.originalEstimate.valueAsText"
                                }
                              ]
                            }
                          ]
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v23/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.board.cardChildren.key"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v24/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.board.cardChildren.summary"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v25/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.board.cardChildren.labels"
                        },
                        {
                          "kind": "LinkedField",
                          "name": "assignee",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "RequiredField",
                              "field": (v7/*: any*/),
                              "action": "THROW",
                              "path": "boardScope.board.cardChildren.assignee.accountId"
                            },
                            {
                              "kind": "RequiredField",
                              "field": (v3/*: any*/),
                              "action": "THROW",
                              "path": "boardScope.board.cardChildren.assignee.name"
                            },
                            {
                              "kind": "RequiredField",
                              "field": (v8/*: any*/),
                              "action": "THROW",
                              "path": "boardScope.board.cardChildren.assignee.picture"
                            }
                          ]
                        },
                        {
                          "kind": "RequiredField",
                          "field": {
                            "concreteType": "CardType",
                            "kind": "LinkedField",
                            "name": "type",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": (v9/*: any*/),
                                "action": "THROW",
                                "path": "boardScope.board.cardChildren.type.id"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v3/*: any*/),
                                "action": "THROW",
                                "path": "boardScope.board.cardChildren.type.name"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v26/*: any*/),
                                "action": "THROW",
                                "path": "boardScope.board.cardChildren.type.iconUrl"
                              }
                            ]
                          },
                          "action": "THROW",
                          "path": "boardScope.board.cardChildren.type"
                        },
                        {
                          "kind": "RequiredField",
                          "field": {
                            "concreteType": "CardStatus",
                            "kind": "LinkedField",
                            "name": "status",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": (v9/*: any*/),
                                "action": "THROW",
                                "path": "boardScope.board.cardChildren.status.id"
                              }
                            ]
                          },
                          "action": "THROW",
                          "path": "boardScope.board.cardChildren.status"
                        },
                        {
                          "concreteType": "CardPriority",
                          "kind": "LinkedField",
                          "name": "priority",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "RequiredField",
                              "field": (v3/*: any*/),
                              "action": "THROW",
                              "path": "boardScope.board.cardChildren.priority.name"
                            },
                            {
                              "kind": "RequiredField",
                              "field": (v26/*: any*/),
                              "action": "THROW",
                              "path": "boardScope.board.cardChildren.priority.iconUrl"
                            }
                          ]
                        },
                        (v27/*: any*/)
                      ]
                    },
                    "action": "THROW",
                    "path": "boardScope.board.cardChildren"
                  },
                  {
                    "kind": "RequiredField",
                    "field": {
                      "concreteType": "SoftwareCard",
                      "kind": "LinkedField",
                      "name": "cards",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": (v9/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.board.cards.id"
                        }
                      ]
                    },
                    "action": "THROW",
                    "path": "boardScope.board.cards"
                  },
                  {
                    "kind": "RequiredField",
                    "field": {
                      "concreteType": "BoardEditConfig",
                      "kind": "LinkedField",
                      "name": "editConfig",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": {
                            "concreteType": "InlineColumnEditConfig",
                            "kind": "LinkedField",
                            "name": "inlineColumnEdit",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": (v31/*: any*/),
                                "action": "THROW",
                                "path": "boardScope.board.editConfig.inlineColumnEdit.enabled"
                              }
                            ]
                          },
                          "action": "THROW",
                          "path": "boardScope.board.editConfig.inlineColumnEdit"
                        },
                        {
                          "kind": "RequiredField",
                          "field": {
                            "concreteType": "InlineCardCreateConfig",
                            "kind": "LinkedField",
                            "name": "inlineCardCreate",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": (v31/*: any*/),
                                "action": "THROW",
                                "path": "boardScope.board.editConfig.inlineCardCreate.enabled"
                              }
                            ]
                          },
                          "action": "THROW",
                          "path": "boardScope.board.editConfig.inlineCardCreate"
                        }
                      ]
                    },
                    "action": "THROW",
                    "path": "boardScope.board.editConfig"
                  }
                ]
              },
              "action": "THROW",
              "path": "boardScope.board"
            },
            {
              "kind": "RequiredField",
              "field": {
                "args": (v32/*: any*/),
                "concreteType": "Sprint",
                "kind": "LinkedField",
                "name": "sprints",
                "plural": true,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v9/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.sprints.id"
                  },
                  {
                    "kind": "RequiredField",
                    "field": (v3/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.sprints.name"
                  },
                  (v33/*: any*/),
                  {
                    "kind": "RequiredField",
                    "field": (v34/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.sprints.startDate"
                  },
                  {
                    "kind": "RequiredField",
                    "field": (v35/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.sprints.endDate"
                  },
                  {
                    "kind": "RequiredField",
                    "field": (v36/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.sprints.daysRemaining"
                  },
                  {
                    "kind": "RequiredField",
                    "field": {
                      "concreteType": "SoftwareCard",
                      "kind": "LinkedField",
                      "name": "cards",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": (v9/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.sprints.cards.id"
                        }
                      ]
                    },
                    "action": "THROW",
                    "path": "boardScope.sprints.cards"
                  }
                ],
                "storageKey": "sprints(state:[\"ACTIVE\"])"
              },
              "action": "THROW",
              "path": "boardScope.sprints"
            },
            {
              "kind": "RequiredField",
              "field": {
                "concreteType": "BoardFeature",
                "kind": "LinkedField",
                "name": "features",
                "plural": true,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v23/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.features.key"
                  },
                  {
                    "kind": "RequiredField",
                    "field": (v37/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.features.status"
                  },
                  {
                    "kind": "RequiredField",
                    "field": (v38/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.features.toggle"
                  },
                  {
                    "kind": "RequiredField",
                    "field": (v11/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.features.category"
                  }
                ]
              },
              "action": "THROW",
              "path": "boardScope.features"
            },
            {
              "kind": "RequiredField",
              "field": {
                "concreteType": "SoftwareProject",
                "kind": "LinkedField",
                "name": "projectLocation",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v9/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.projectLocation.id"
                  },
                  {
                    "kind": "RequiredField",
                    "field": (v23/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.projectLocation.key"
                  },
                  {
                    "kind": "RequiredField",
                    "field": (v3/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.projectLocation.name"
                  },
                  {
                    "kind": "RequiredField",
                    "field": {
                      "concreteType": "CardType",
                      "kind": "LinkedField",
                      "name": "cardTypes",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": (v9/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.projectLocation.cardTypes.id"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v3/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.projectLocation.cardTypes.name"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v26/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.projectLocation.cardTypes.iconUrl"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v30/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.projectLocation.cardTypes.hierarchyLevelType"
                        }
                      ]
                    },
                    "action": "THROW",
                    "path": "boardScope.projectLocation.cardTypes"
                  }
                ]
              },
              "action": "THROW",
              "path": "boardScope.projectLocation"
            },
            {
              "kind": "RequiredField",
              "field": {
                "concreteType": "CardParent",
                "kind": "LinkedField",
                "name": "cardParents",
                "plural": true,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v9/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.cardParents.id"
                  },
                  {
                    "kind": "RequiredField",
                    "field": (v23/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.cardParents.key"
                  },
                  {
                    "kind": "RequiredField",
                    "field": (v24/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.cardParents.summary"
                  },
                  {
                    "concreteType": "CardStatus",
                    "kind": "LinkedField",
                    "name": "status",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "RequiredField",
                        "field": (v9/*: any*/),
                        "action": "THROW",
                        "path": "boardScope.cardParents.status.id"
                      },
                      {
                        "kind": "RequiredField",
                        "field": (v3/*: any*/),
                        "action": "THROW",
                        "path": "boardScope.cardParents.status.name"
                      },
                      {
                        "kind": "RequiredField",
                        "field": (v11/*: any*/),
                        "action": "THROW",
                        "path": "boardScope.cardParents.status.category"
                      }
                    ]
                  },
                  {
                    "kind": "RequiredField",
                    "field": {
                      "concreteType": "CardType",
                      "kind": "LinkedField",
                      "name": "cardType",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": (v9/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.cardParents.cardType.id"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v3/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.cardParents.cardType.name"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v26/*: any*/),
                          "action": "THROW",
                          "path": "boardScope.cardParents.cardType.iconUrl"
                        }
                      ]
                    },
                    "action": "THROW",
                    "path": "boardScope.cardParents.cardType"
                  },
                  (v39/*: any*/)
                ]
              },
              "action": "THROW",
              "path": "boardScope.cardParents"
            },
            {
              "kind": "RequiredField",
              "field": {
                "concreteType": "CurrentUser",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v40/*: any*/),
                    "action": "THROW",
                    "path": "boardScope.currentUser.permissions"
                  }
                ]
              },
              "action": "THROW",
              "path": "boardScope.currentUser"
            },
            (v41/*: any*/)
          ]
        },
        "action": "THROW",
        "path": "boardScope"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "boardWithRouterRelayQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "BoardScope",
        "kind": "LinkedField",
        "name": "boardScope",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "concreteType": "SoftwareBoard",
            "kind": "LinkedField",
            "name": "board",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "LinkedField",
                "name": "assignees",
                "plural": true,
                "selections": (v43/*: any*/)
              },
              {
                "concreteType": "Column",
                "kind": "LinkedField",
                "name": "columns",
                "plural": true,
                "selections": [
                  (v9/*: any*/),
                  (v3/*: any*/),
                  (v10/*: any*/),
                  {
                    "concreteType": "ColumnStatus",
                    "kind": "LinkedField",
                    "name": "columnStatus",
                    "plural": true,
                    "selections": [
                      (v44/*: any*/),
                      {
                        "concreteType": "SoftwareCardTypeTransition",
                        "kind": "LinkedField",
                        "name": "cardTypeTransitions",
                        "plural": true,
                        "selections": [
                          (v12/*: any*/),
                          (v3/*: any*/),
                          (v46/*: any*/),
                          {
                            "concreteType": "CardStatus",
                            "kind": "LinkedField",
                            "name": "originStatus",
                            "plural": false,
                            "selections": (v45/*: any*/)
                          },
                          {
                            "concreteType": "CardType",
                            "kind": "LinkedField",
                            "name": "cardType",
                            "plural": false,
                            "selections": (v45/*: any*/)
                          },
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v15/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v16/*: any*/),
                  (v14/*: any*/),
                  {
                    "concreteType": "SoftwareCard",
                    "kind": "LinkedField",
                    "name": "cards",
                    "plural": true,
                    "selections": [
                      (v9/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/),
                      {
                        "concreteType": "Sprint",
                        "kind": "LinkedField",
                        "name": "activeSprint",
                        "plural": false,
                        "selections": (v45/*: any*/)
                      },
                      (v47/*: any*/),
                      (v23/*: any*/),
                      (v24/*: any*/),
                      (v25/*: any*/),
                      {
                        "kind": "LinkedField",
                        "name": "assignee",
                        "plural": false,
                        "selections": [
                          (v42/*: any*/),
                          (v7/*: any*/),
                          (v9/*: any*/)
                        ]
                      },
                      (v49/*: any*/),
                      (v46/*: any*/),
                      (v50/*: any*/),
                      (v27/*: any*/),
                      {
                        "concreteType": "ChildCardsMetadata",
                        "kind": "LinkedField",
                        "name": "childCardsMetadata",
                        "plural": false,
                        "selections": [
                          (v28/*: any*/),
                          (v29/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              },
              (v51/*: any*/),
              (v53/*: any*/),
              {
                "concreteType": "SoftwareCard",
                "kind": "LinkedField",
                "name": "cardChildren",
                "plural": true,
                "selections": [
                  (v9/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v47/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v25/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "assignee",
                    "plural": false,
                    "selections": (v43/*: any*/)
                  },
                  (v49/*: any*/),
                  (v46/*: any*/),
                  (v50/*: any*/),
                  (v27/*: any*/)
                ]
              },
              (v54/*: any*/),
              {
                "concreteType": "BoardEditConfig",
                "kind": "LinkedField",
                "name": "editConfig",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "InlineColumnEditConfig",
                    "kind": "LinkedField",
                    "name": "inlineColumnEdit",
                    "plural": false,
                    "selections": (v52/*: any*/)
                  },
                  (v53/*: any*/)
                ]
              },
              (v9/*: any*/)
            ]
          },
          {
            "args": (v32/*: any*/),
            "concreteType": "Sprint",
            "kind": "LinkedField",
            "name": "sprints",
            "plural": true,
            "selections": [
              (v9/*: any*/),
              (v3/*: any*/),
              (v33/*: any*/),
              (v34/*: any*/),
              (v35/*: any*/),
              (v36/*: any*/),
              (v54/*: any*/)
            ],
            "storageKey": "sprints(state:[\"ACTIVE\"])"
          },
          {
            "concreteType": "BoardFeature",
            "kind": "LinkedField",
            "name": "features",
            "plural": true,
            "selections": [
              (v23/*: any*/),
              (v37/*: any*/),
              (v38/*: any*/),
              (v11/*: any*/)
            ]
          },
          {
            "concreteType": "SoftwareProject",
            "kind": "LinkedField",
            "name": "projectLocation",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v23/*: any*/),
              (v3/*: any*/),
              (v51/*: any*/)
            ]
          },
          {
            "concreteType": "CardParent",
            "kind": "LinkedField",
            "name": "cardParents",
            "plural": true,
            "selections": [
              (v9/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v44/*: any*/),
              {
                "concreteType": "CardType",
                "kind": "LinkedField",
                "name": "cardType",
                "plural": false,
                "selections": (v48/*: any*/)
              },
              (v39/*: any*/)
            ]
          },
          {
            "concreteType": "CurrentUser",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v40/*: any*/)
            ]
          },
          (v41/*: any*/),
          (v9/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "id": "ab366f74068bb048193650cda464a839daec6f71dd9d1e7ee8874e9f9959ba67",
    "metadata": {},
    "name": "boardWithRouterRelayQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "72f5b0ae535a7d894e92c5d09606d166";

import { PreloadableQueryRegistry } from 'relay-runtime';
PreloadableQueryRegistry.set(node.params.id, node);

export default node;
