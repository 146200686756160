import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { BoardToolsView } from '../../common/ui/tool-sections/index.tsx';
import { useFetchedToolSections } from '../../controllers/index.tsx';

export interface BoardConnectToolbarProps {
	boardId: string;
	projectKey: string;
	mode: 'work' | 'plan';
}

function BoardConnectToolbarInner({ boardId, projectKey, mode }: BoardConnectToolbarProps) {
	const toolSections = useFetchedToolSections({ boardId, projectKey, mode });

	if (!toolSections) {
		return null;
	}

	return <BoardToolsView toolSections={toolSections} />;
}

export function BoardConnectToolbar(props: BoardConnectToolbarProps) {
	return (
		<JSErrorBoundary
			id="board-connect-toolbar"
			packageName="@atlassian/jira-software-board-connect-toolbar"
			teamName="a4t-tanuki"
			fallback="flag"
		>
			<BoardConnectToolbarInner {...props} />
		</JSErrorBoundary>
	);
}
