import { Component, type ReactElement } from 'react';
// @ts-expect-error - TS2614 - Module '"history/createBrowserHistory"' has no exported member 'BrowserHistory'. Did you mean to use 'import BrowserHistory from "history/createBrowserHistory"' instead?
import type { BrowserHistory } from 'history/createBrowserHistory';
import { withRouter } from '@atlassian/react-resource-router';
import type { ReactRouterCompatibleHistory } from '../../common/types.tsx';
import { createReactRouterCompatibleHistory } from '../../common/utils/create-react-router-history/index.tsx';

type Props = {
	children: (history: ReactRouterCompatibleHistory) => ReactElement;
};

type WithRouterProps = {
	history: BrowserHistory;
};

// eslint-disable-next-line jira/react/no-class-components
class HistoryAdapterComponent extends Component<Props & WithRouterProps> {
	constructor(props: Props & WithRouterProps) {
		super(props);
		this.compatibleHistory = createReactRouterCompatibleHistory(props.history);
	}

	shouldComponentUpdate(nextProps: Props & WithRouterProps) {
		return nextProps.children !== this.props.children;
	}

	componentWillUnmount() {
		this.compatibleHistory.destroy && this.compatibleHistory.destroy();
	}

	compatibleHistory: ReactRouterCompatibleHistory;

	render() {
		const { children } = this.props;

		return children(this.compatibleHistory);
	}
}

export const HistoryAdapter = withRouter(HistoryAdapterComponent);
