import { gqlTagSwag } from '@atlassian/jira-software-swag/src/index.tsx';
import { mutation$ } from '@atlassian/jira-software-swag/src/services/mutation/index.tsx';
import { columnSetLimitAnalyticsOptions } from '../../common/graphql/metrics/index.tsx';

export const softwareColumnSetLimitOperationName = 'SoftwareColumnSetLimit';

export const columnSetLimitMutation = gqlTagSwag`mutation SoftwareColumnSetLimit($boardId:ID!, $columnId: ID!, $limit:Int, $minimum:Int){
    setColumnLimit(input: {boardId: $boardId, columnId: $columnId, limit: $limit, minimum: $minimum}) {
        success
    }
}`;

export default function columnSetLimit(
	contextPath: string,
	boardId: string,
	columnId: number,
	limit: number | null,
	minimum: number | null,
) {
	return mutation$(
		contextPath,
		softwareColumnSetLimitOperationName,
		columnSetLimitMutation,
		{
			boardId,
			columnId,
			limit,
			minimum,
		},
		columnSetLimitAnalyticsOptions,
	);
}
