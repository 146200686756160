import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { CardLabels } from '../../../labels/index.tsx';
import type { TextCustomField } from '../types.tsx';

type Props = {
	field: TextCustomField;
};

export const CUSTOM_FIELD_LABELS = 'labels';

export const TextLabelCustomFieldContent = ({ field }: Props) => {
	if (!field?.text) {
		return null;
	}
	const labels = field.text.split(',');

	return (
		<Box xcss={overflowOuterStyles} data-testId="text-label-custom-field-content-card-labels">
			<Box
				xcss={[
					overflowInnerStyles,
					isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_2') && overflowInnerExtraStyles,
				]}
			>
				{/* TMP displays labels outside of custom fields */}
				<CardLabels isCMPBoard labels={labels} />
			</Box>
		</Box>
	);
};

const overflowOuterStyles = xcss({
	display: 'flex',
	flexDirection: 'row',
	overflow: 'hidden',
});

const overflowInnerStyles = xcss({
	boxAlign: 'center',
	flexFlow: 'row wrap',
	alignItems: 'center',
	maxWidth: '100%',
	gap: 'space.100',
});

// Combine styles when clean up fg jira_nav4_beta_drop_2
const overflowInnerExtraStyles = xcss({
	flexGrow: '1',
});
