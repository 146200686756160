import React, { createContext, useCallback, useContext, useState } from 'react';
import { useAutobatch } from '@atlassian/jira-software-react-scheduler/src/ui/autobatch/index.tsx';

interface ColumnEdgeContextType {
	isOverColumn: boolean;
	setIsOverColumn: (edge: boolean) => void;
}

const ColumnEdgeContext = createContext<ColumnEdgeContextType>({
	isOverColumn: false,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setIsOverColumn: () => {},
});

export const useIsCardOverColumn = () => useContext(ColumnEdgeContext);

export const ColumnEdgeContextProvider = ({ children }: { children: React.ReactNode }) => {
	const autobatch = useAutobatch();
	const [isOverColumn, setIsOverColumn] = useState(false);
	const batchSetIsOverColumn = useCallback(
		(edge: boolean) => autobatch(() => setIsOverColumn(edge)),
		[autobatch],
	);
	return (
		<ColumnEdgeContext.Provider
			value={{
				isOverColumn,
				setIsOverColumn: batchSetIsOverColumn,
			}}
		>
			{children}
		</ColumnEdgeContext.Provider>
	);
};
