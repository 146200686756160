import { combineReducers } from 'redux';
import type { Action } from '../../../types.tsx';
import { columnDeleteModalReducer } from './delete-modal/reducer.tsx';
import { issuesNotOnBoardReducer } from './issues-not-on-board/reducer.tsx';
import type { ColumnState } from './types.tsx';

export default combineReducers<ColumnState, Action>({
	deleteModal: columnDeleteModalReducer,
	issuesNotOnBoard: issuesNotOnBoardReducer,
});
