import React, {
	useContext,
	type FocusEventHandler,
	type FocusEvent,
	useMemo,
	useCallback,
	useEffect,
	useRef,
} from 'react';
import debounce from 'lodash/debounce';
import isNil from 'lodash/fp/isNil';
import Avatar from '@atlaskit/avatar';
import { ErrorMessage, Field, useFormState } from '@atlaskit/form';
import { Box, Inline, xcss, Text } from '@atlaskit/primitives';
import Select, {
	type ValueType,
	type OptionProps,
	type SingleValueProps,
	type ControlProps,
	type ActionMeta,
	type InputActionMeta,
	components,
} from '@atlaskit/select';
import Tooltip from '@atlaskit/tooltip';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { Option } from '../../../common/types.tsx';
import Ipcontext from '../../../services/context/index.tsx';
import { useFetchCustomFieldValues } from '../../../services/fetch-custom-field-values/index.tsx';
import { useFetchCustomFields } from '../../../services/fetch-custom-fields/index.tsx';
import messages from './messages.tsx';
import type { Project, BoardScopeFilterOptionType } from './types.tsx';

const OptionComponent = ({ project, label }: { project?: Project; label: string }) => {
	const projectElements = !isNil(project) && (
		<>
			<Text as="span">&nbsp;(</Text>
			<Avatar appearance="square" size="xsmall" name={project.key} src={project.avatar} />
			<Text as="span">&nbsp;{project.key})</Text>
		</>
	);
	return (
		<Inline alignBlock="center">
			<Box xcss={OptionStyles}>{label}</Box>
			{projectElements}
		</Inline>
	);
};

const BoardScopeFilterControl = ({
	children,
	...props
}: ControlProps<BoardScopeFilterOptionType>) => {
	const selectedValue = props.getValue();
	return (
		<Tooltip content={selectedValue.length > 0 && selectedValue[0].label}>
			<components.Control {...props}>{children}</components.Control>
		</Tooltip>
	);
};

const BoardScopeFilterSingleValue = (props: SingleValueProps<BoardScopeFilterOptionType>) => (
	<components.SingleValue {...props}>
		<OptionComponent label={props.data.label} project={props.data.project} />
	</components.SingleValue>
);

const BoardScopeFilterOption = (props: OptionProps<BoardScopeFilterOptionType>) => (
	<Tooltip content={props.data.label}>
		<components.Option {...props}>
			<OptionComponent label={props.data.label} project={props.data.project} />
		</components.Option>
	</Tooltip>
);

export const ScopeFilterValue = ({
	defaultValue,
	onCustomFieldValuesFetched,
}: {
	defaultValue?: string;
	onCustomFieldValuesFetched?: () => void;
}) => {
	const { formatMessage } = useIntl();
	const { planId, scenarioId } = useContext(Ipcontext);
	const formState = useFormState();
	const previousFieldId = useRef<string | undefined>(undefined);
	const fieldId: string | undefined = formState?.values?.filterFieldId?.value;
	const {
		values: customFieldValues,
		isLoading,
		fetchCustomFieldValues,
	} = useFetchCustomFieldValues({
		planId,
		scenarioId,
		fieldId,
	});
	const currentFieldValue = formState?.values.filterFieldValue ?? defaultValue;

	useEffect(() => {
		if (
			customFieldValues &&
			onCustomFieldValuesFetched &&
			fg('program_board_scope_filter_editing')
		) {
			onCustomFieldValuesFetched();
		}
	}, [customFieldValues, onCustomFieldValuesFetched]);

	const debouncedLoadOptions = useMemo(
		() =>
			debounce((query: string) => {
				fetchCustomFieldValues(query);
			}, 200),
		[fetchCustomFieldValues],
	);

	const customFieldValueOptions = useMemo(() => {
		const returnedValues = (customFieldValues || []).map((v) => ({
			label: v.value,
			value: v.value,
		}));

		if (defaultValue && fg('program_board_scope_filter_editing')) {
			const isDefaultOptionIncluded = (customFieldValues || []).find(
				(v) => v.value === defaultValue,
			);

			if (!isDefaultOptionIncluded) {
				returnedValues.push({ label: defaultValue, value: defaultValue });
			}
		}

		return returnedValues;
	}, [customFieldValues, defaultValue]);

	const valueOfSelect = useMemo(
		() => customFieldValueOptions.find((v) => v.value === currentFieldValue?.value),
		[customFieldValueOptions, currentFieldValue],
	);

	const onInputChangeHandler = useCallback(
		(query: string, actionMeta: InputActionMeta) => {
			if (actionMeta.action !== 'input-change') {
				return;
			}
			debouncedLoadOptions(query);
		},
		[debouncedLoadOptions],
	);

	const onChangeHandler = useCallback(
		(
			newValue: Option | null,
			actionMeta: ActionMeta,
			onChange: (newValue: Option | null, actionMeta: ActionMeta) => void,
		) => {
			if (actionMeta.action === 'clear') {
				debouncedLoadOptions('');
			}

			onChange(newValue, actionMeta);
		},
		[debouncedLoadOptions],
	);

	const onFocusHandler = useCallback(
		(event: FocusEvent, onFocus: FocusEventHandler) => {
			if (isNil(valueOfSelect)) {
				debouncedLoadOptions('');
				onFocus(event);
			}
		},
		[debouncedLoadOptions, valueOfSelect],
	);

	useEffect(() => {
		if (previousFieldId.current !== fieldId) {
			if (!isNil(fieldId)) {
				debouncedLoadOptions('');
			}
			previousFieldId.current = fieldId;
		}
	}, [fieldId, debouncedLoadOptions]);

	return (
		<Box xcss={FieldBoxStyles}>
			<Field<ValueType<Option>>
				aria-required
				name="filterFieldValue"
				testId="portfolio-3-plan-increment-common.ui.increment-forms.board-scope-filter.custom-field-value"
				label={formatMessage(messages.scopeFilterFieldValueLabel)}
				isDisabled={isNil(fieldId)}
				defaultValue={
					fg('program_board_scope_filter_editing')
						? customFieldValueOptions.find((v) => v.label === defaultValue)
						: undefined
				}
			>
				{({ fieldProps: { id, value, onChange, onFocus, ...rest }, error }) => (
					<>
						<Select<Option>
							isLoading={isLoading}
							isClearable
							inputId={id}
							closeMenuOnScroll
							openMenuOnFocus
							options={customFieldValueOptions}
							onInputChange={onInputChangeHandler}
							value={valueOfSelect}
							styles={{
								menuPortal: (base) => ({
									...base,

									zIndex: layers.modal,
								}),
							}}
							// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
							menuPortalTarget={document.body}
							placeholder={formatMessage(messages.scopeFilterFieldValuePlaceholder)}
							controlShouldRenderValue={!isNil(valueOfSelect)}
							components={{
								...(isNil(valueOfSelect) && {
									ClearIndicator: () => null,
								}),
							}}
							aria-label={formatMessage(messages.scopeFilterFieldValueLabel)}
							onChange={(newValue, actionMeta) => onChangeHandler(newValue, actionMeta, onChange)}
							onFocus={(event) => onFocusHandler(event, onFocus)}
							filterOption={() => true}
							{...rest}
						/>
						{error && <ErrorMessage>{error}</ErrorMessage>}
					</>
				)}
			</Field>
		</Box>
	);
};

export const ScopeFilterField = ({
	shouldFetchCustomFields,
	defaultValue,
	onCustomFieldsFetched,
}: {
	shouldFetchCustomFields: boolean;
	defaultValue?: string;
	onCustomFieldsFetched?: () => void;
}) => {
	const { planId } = useContext(Ipcontext);
	const {
		customFields,
		fetchCustomFields,
		isLoading: isLoadingCustomFields,
	} = useFetchCustomFields({ planId });

	const customFieldOptions = useMemo(() => {
		if (isLoadingCustomFields) {
			return [];
		}
		return (customFields || []).map((f) => ({
			label: f.title,
			value: f.id,
			project: f.project,
		}));
	}, [customFields, isLoadingCustomFields]);

	useEffect(() => {
		if (onCustomFieldsFetched && customFields && fg('program_board_scope_filter_editing')) {
			onCustomFieldsFetched();
		}
	}, [customFields, onCustomFieldsFetched]);

	const { formatMessage } = useIntl();

	useEffect(() => {
		if (shouldFetchCustomFields) {
			fetchCustomFields();
		}
	}, [fetchCustomFields, shouldFetchCustomFields]);

	return (
		<Box xcss={FieldBoxStyles}>
			<Field<ValueType<Option>>
				aria-required
				name="filterFieldId"
				testId="portfolio-3-plan-increment-common.ui.increment-forms.board-scope-filter.custom-field"
				label={formatMessage(messages.scopeFilterFieldLabel)}
				defaultValue={
					fg('program_board_scope_filter_editing')
						? customFieldOptions.find((v) => v.value === defaultValue)
						: undefined
				}
			>
				{({
					fieldProps: { id, ...rest },

					error,
				}) => (
					<>
						<Select<Option>
							inputId={id}
							isLoading={isLoadingCustomFields}
							isClearable
							isSearchable
							closeMenuOnScroll
							styles={{
								menuPortal: (base) => ({
									...base,
									zIndex: layers.modal,
								}),
							}}
							// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
							menuPortalTarget={document.body}
							placeholder={formatMessage(messages.scopeFilterFieldPlaceholder)}
							options={customFieldOptions}
							components={{
								Option: BoardScopeFilterOption,
								SingleValue: BoardScopeFilterSingleValue,
								Control: BoardScopeFilterControl,
							}}
							{...rest}
						/>
						{error && <ErrorMessage>{error}</ErrorMessage>}
					</>
				)}
			</Field>
		</Box>
	);
};

const FieldBoxStyles = xcss({
	width: '50%',
});

const OptionStyles = xcss({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});
