import { setUserProperties as originalSetUserProperties } from '@atlassian/jira-common-rest/src/api/latest/user-properties/index.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { PropertyValue } from '../../types.tsx';

// Note: setUserProperties does not throw on failure, wrapping this method to add this logic
export const setUserProperties = async <T,>(
	accountId: AccountId,
	propertyKey: string,
	value: PropertyValue<T>, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
	const res = await originalSetUserProperties(accountId, propertyKey, JSON.stringify(value) || '');

	if (res.status >= 400 && res.status <= 599) {
		const errResp = await res.text();
		throw new FetchError(res.status, errResp);
	}

	return res;
};
