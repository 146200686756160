import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	estimatedEndDate: {
		id: 'portfolio-3-plan-increment-common.ui.increment-forms.calculated-end-date.estimated-end-date',
		defaultMessage: 'Estimated board end date:',
		description: 'Title of the create increment modal.',
	},
});

export default messages;
