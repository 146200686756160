import * as scheduler from 'scheduler';

/**
 * Tries to schedule "callback" after pending React.js renders have finished with the React
 * scheduler. Falls back to setTimeout in case the scheduler API throws or isn't available.
 *
 * @param callback
 */
export const scheduleAfterRender = (callback: () => void): void => {
	if (scheduler && scheduler.unstable_scheduleCallback) {
		// Wait a tick for renders that will still be scheduled on this event-loop tick.
		process.nextTick(() => {
			try {
				// Schedule an idle priority callback with react's scheduler.
				scheduler.unstable_scheduleCallback(scheduler.unstable_IdlePriority, () => {
					callback();
				});
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				// React.js' lower priority is at minimum 0ms or a postMessage tick.
				// Waiting 1ms seems to be a good enough approximation to run after that.
				setTimeout(() => {
					callback();
				}, 1);
			}
		});
	} else {
		setTimeout(() => {
			callback();
		}, 1);
	}
};
