import get from 'lodash/get';
import { values } from '@atlassian/jira-shared-types/src/general.tsx';

export type JiraErrorCollection = {
	errorMessages: string[];
	errors: {
		[key: string]: string;
	};
};

export type FetchError = {
	message: string;
	name: string;
	statusCode: number;
	stack: string;
};

export const flatErrorCollection = ({ errorMessages, errors }: JiraErrorCollection): string[] => [
	...errorMessages,
	...values(errors).map((error) => String(error)),
];

export const parseErrorCollection = (value: string): JiraErrorCollection | null | undefined => {
	try {
		return JSON.parse(value);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		return null;
	}
};

const parseMessage = (error: unknown): unknown | null => {
	if (error === null || typeof error !== 'object') {
		return null;
	}
	try {
		const message = get(error, ['message'], null);
		return JSON.parse(message);
	} catch {
		return null;
	}
};

const hasErrorMessagesProperty = (response: unknown): response is JiraErrorCollection =>
	response !== null &&
	typeof response === 'object' &&
	'errorMessages' in response &&
	Array.isArray(response.errorMessages);

export function hasJiraErrorMessages(error: unknown): error is JiraErrorCollection {
	const response = parseMessage(error);
	return hasErrorMessagesProperty(response) && response.errorMessages.length > 0;
}

export function parseJiraErrorMessages(error: unknown): string[] {
	const response = parseMessage(error);
	if (hasErrorMessagesProperty(response)) {
		return response.errorMessages;
	}
	return [];
}
