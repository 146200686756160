import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	showInsights: {
		id: 'insights-show-insights-button.show-insights',
		defaultMessage: 'Insights',
		description: 'label for button to show insights panel',
	},
	hideInsights: {
		id: 'insights-show-insights-button.hide-insights',
		defaultMessage: 'Insights',
		description: 'label for button to hide insights panel',
	},
	showInsightsBacklogTerminologyRefresh: {
		id: 'insights-show-insights-button.show-insights-backlog-terminology-refresh',
		defaultMessage: 'Planning health',
		description: 'The label for the insights button on Backlog view to show the insights panel.',
	},
	hideInsightsBacklogTerminologyRefresh: {
		id: 'insights-show-insights-button.hide-insights-backlog-terminology-refresh',
		defaultMessage: 'Planning health',
		description: 'The label for the insights button on Backlog view to hide the insights panel.',
	},
	showInsightsKanbanTerminologyRefresh: {
		id: 'insights-show-insights-button.show-insights-kanban-terminology-refresh',
		defaultMessage: 'Work health',
		description:
			'The label for the insights button on Kanban board view to show the insights panel.',
	},
	hideInsightsKanbanTerminologyRefresh: {
		id: 'insights-show-insights-button.hide-insights-kanban-terminology-refresh',
		defaultMessage: 'Work health',
		description:
			'The label for the insights button on Kanban board view to hide the insights panel.',
	},
	showInsightsScrumTerminologyRefresh: {
		id: 'insights-show-insights-button.show-insights-scrum-terminology-refresh',
		defaultMessage: 'Sprint health',
		description:
			'The label for the insights button on Scrum board view to show the insights panel.',
	},
	hideInsightsScrumTerminologyRefresh: {
		id: 'insights-show-insights-button.hide-insights-scrum-terminology-refresh',
		defaultMessage: 'Sprint health',
		description:
			'The label for the insights button on Scrum board view to hide the insights panel.',
	},
	scrumInsightsLabel: {
		id: 'insights-show-insights-button.scrum-insights-label',
		defaultMessage: 'Sprint insights',
		description: 'label for insights button in scrum board',
	},
	kanbanInsightsLabel: {
		id: 'insights-show-insights-button.kanban-insights-label',
		defaultMessage: 'Board insights',
		description: 'label for insights button in kanban board',
	},
	backlogInsightsLabel: {
		id: 'insights-show-insights-button.insights-label',
		defaultMessage: 'Backlog insights',
		description: 'label for insights button in backlog board',
	},
});
