import { Observable } from 'rxjs/Observable';
import type { IScheduler } from 'rxjs/Scheduler';
import 'rxjs/add/observable/interval';
import 'rxjs/add/observable/never';
import 'rxjs/add/operator/throttleTime';
import 'rxjs/add/operator/startWith';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/switchMap';
import getVisibility$ from '@atlassian/jira-common-page-visiblity-stream/src/page-visibility-stream.tsx';
import { TMP_BOARD_POLLING_INTERVAL } from '../../common/constants.tsx';
import {
	REFRESH_SOURCE_POLLING,
	REFRESH_SOURCE_TAB_BECAME_ACTIVE,
} from '../../model/constants.tsx';
import { workRefreshData } from '../../state/actions/work/index.tsx';
import { isPollingEnabled } from '../../state/selectors/board/board-selectors.tsx';
import { hasAnyActiveOptimistic } from '../../state/selectors/work/work-selectors.tsx';
import type { Action, ActionsObservable, MiddlewareAPI } from '../../state/types.tsx';

/**
 * Start with marker, so that we guarantee there are no
 * polls or visibility changed refresh actions emitted
 * without throttling.
 */
const STREAM_START_MARKER = Symbol('STREAM_START_MARKER');

// This stream controls whether the board will poll the server for data.
// The stream will create BOARD_GET_DATA actions:
// 1. On start up to populate the board with initial data.
const workRefreshDataEpic =
	(scheduler?: IScheduler, debounceScheduler?: IScheduler) =>
	(action$: ActionsObservable, store: MiddlewareAPI): Observable<Action> => {
		const refresh$ = getVisibility$()
			.distinctUntilChanged()
			.debounceTime(500, debounceScheduler) // prevent multiple requests when ppl play around the tabs
			.switchMap(
				(
					shouldPoll: boolean | null,
					idx: number,
				): Observable<Action | typeof STREAM_START_MARKER> => {
					if (!shouldPoll) {
						return Observable.never();
					}

					const refreshStream: Observable<Action | typeof STREAM_START_MARKER> =
						Observable.interval(TMP_BOARD_POLLING_INTERVAL, scheduler)
							.mapTo(workRefreshData(REFRESH_SOURCE_POLLING))
							.filter(() => !hasAnyActiveOptimistic(store.getState()))
							.filter(() => isPollingEnabled(store.getState()));

					if (idx === 0) {
						return refreshStream.startWith(STREAM_START_MARKER);
					}
					return refreshStream.startWith(workRefreshData(REFRESH_SOURCE_TAB_BECAME_ACTIVE));
				},
			);

		return refresh$
			.throttleTime(TMP_BOARD_POLLING_INTERVAL, scheduler)
			.filter(
				(action: Action | typeof STREAM_START_MARKER): action is Action =>
					action !== STREAM_START_MARKER,
			);
	};

export default workRefreshDataEpic;
