import { di } from 'react-magnetic-di';

export const isEmbedSmartLinkView = () => {
	di(window);
	return (
		window &&
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window?.location.pathname.includes('plans') &&
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window?.location.pathname.includes('scenarios') &&
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window?.location.pathname.includes('/embed')
	);
};
