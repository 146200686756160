import { chain, splice } from 'icepick';
import { BOARD_DEFERRED_DATA_LOAD_SUCCESS } from '../../../actions/board/board-deferred-data/index.tsx';
import { ISSUE_CREATE_REQUEST } from '../../../actions/issue/create/index.tsx';
import { SWIMLANE_JQL_SET_DATA } from '../../../actions/swimlane/index.tsx';
import { WORK_DATA_SET } from '../../../actions/work/index.tsx';
import type { Action } from '../../../types.tsx';
import type { JQLSwimlanesState } from './types.tsx';

const initialState: JQLSwimlanesState = { swimlanes: [] };

export function jqlSwimlanesReducer(
	// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	state: JQLSwimlanesState | void | null = initialState,
	action: Action,
): JQLSwimlanesState {
	if (action.type === WORK_DATA_SET && action.payload.jqlSwimlanes) {
		const { jqlSwimlanes } = action.payload;
		return { swimlanes: jqlSwimlanes };
	}

	if (action.type === SWIMLANE_JQL_SET_DATA && action.payload != null) {
		return { swimlanes: action.payload };
	}

	if (action.type === BOARD_DEFERRED_DATA_LOAD_SUCCESS && action.payload.jqlSwimlanes) {
		const { jqlSwimlanes } = action.payload;
		return { swimlanes: jqlSwimlanes };
	}

	if (action.type === ISSUE_CREATE_REQUEST) {
		const {
			payload: { issues },
			meta: { insertBefore, swimlaneId, isSwimlaneByJql },
		} = action;

		if (!isSwimlaneByJql || !state) {
			return state || initialState;
		}

		const swimlaneWithNewIssuesIndex = state.swimlanes.findIndex((sw) => {
			// If swimlaneId is null in the create request, then it corresponds to the default swimlane
			if (swimlaneId === null) {
				return sw.isDefaultSwimlane;
			}

			return String(sw.id) === String(swimlaneId);
		});

		if (swimlaneWithNewIssuesIndex === -1) {
			return state || initialState;
		}

		const swimlaneIssueIds = state.swimlanes[swimlaneWithNewIssuesIndex].issueIds;

		const insertionPoint = insertBefore
			? swimlaneIssueIds.indexOf(insertBefore)
			: swimlaneIssueIds.length;

		const issueIds = issues.map(({ id }) => id);

		const chainState = chain(state);
		chainState.setIn(
			['swimlanes', swimlaneWithNewIssuesIndex, 'issueIds'],
			splice(swimlaneIssueIds, insertionPoint, 0, ...issueIds),
		);

		return chainState.value();
	}

	return state || initialState;
}
