import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/merge';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import { REFRESH_SOURCE_BOARD_ADD_TO_SPRINT } from '../../../model/constants.tsx';
import type { Sprint } from '../../../model/sprint/sprint-types.tsx';
import { addToSprint } from '../../../rest/sprint/add-to-sprint/index.tsx';
import { makeServiceContext } from '../../../services/service-context.tsx';
import {
	type AddToSprintRequestAction,
	ADD_TO_SPRINT_REQUEST,
	addToSprintSuccess,
	addToSprintFailure,
} from '../../../state/actions/sprints/add-to-sprint/index.tsx';
import { workRefreshData } from '../../../state/actions/work/index.tsx';
import { activeSprintsSelector } from '../../../state/selectors/sprint/sprint-selectors.tsx';
import type { Action, ActionsObservable, MiddlewareAPI } from '../../../state/types.tsx';

export function addToSprintEpic(
	action$: ActionsObservable,
	store: MiddlewareAPI,
): Observable<Action> {
	return action$.ofType(ADD_TO_SPRINT_REQUEST).flatMap((action: AddToSprintRequestAction) => {
		const state = store.getState();
		const context = makeServiceContext(state);

		const optimisticId = action?.meta?.optimistic.id;
		const activeSprints = activeSprintsSelector(state);

		const sprint =
			activeSprints &&
			activeSprints.length &&
			activeSprints.find((sprintItem: Sprint) => sprintItem.id === action?.payload?.sprintId);

		if (!action?.payload?.issueId || !sprint) {
			return Observable.empty<Action>();
		}

		return addToSprint(action.payload.issueId, sprint.id, Number(context.boardId))
			.mergeMap(() =>
				Observable.merge(
					Observable.of(addToSprintSuccess(action.payload.issueId, sprint.id, optimisticId ?? '')),
					Observable.of(workRefreshData(REFRESH_SOURCE_BOARD_ADD_TO_SPRINT)),
				),
			)
			.catch(() =>
				Observable.of(addToSprintFailure(action.payload.issueId, sprint.id, optimisticId ?? '')),
			);
	});
}
