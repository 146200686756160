// eslint-disable-next-line jira/restricted/react
import React, { PureComponent, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import AddIcon from '@atlaskit/icon/core/add';
import AddIconOld from '@atlaskit/icon/glyph/add';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import ToolTip from '@atlaskit/tooltip';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage } from '@atlassian/jira-intl';
import { DRAGGABLE_COLUMN_SELECTOR_NAME } from '../../../common/constants/index.tsx';
import { layout } from '../../../common/constants/styles/index.tsx';

export type Props = {
	appearance: 'button' | 'in-between-cards' | 'hidden-button';
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onClick: () => any;
};
type HiddenButtonWrapperProps = { isICCFormOpen?: boolean };

const withToolTip = (component: ReactNode, message: string) => (
	<StyledButton>
		<ToolTip content={message} position="bottom">
			{component}
		</ToolTip>
	</StyledButton>
);

// eslint-disable-next-line jira/react/no-class-components
export class InlineCardCreateButton extends PureComponent<Props> {
	static defaultProps = {
		appearance: 'button',
	};

	render() {
		const { appearance } = this.props;

		return (
			<FormattedMessage
				id="platform-board.create-button-text.icon-label-v2"
				defaultMessage="Create issue"
			>
				{(formattedMessage: ReactNode[]) => {
					const message: string =
						typeof formattedMessage[0] === 'string' ? formattedMessage[0] : 'Create issue';

					const button = (
						<CreateButton onClick={this.props.onClick}>
							{fg('tnk-1614-visual-refresh-boardkit-icons') ? (
								<AddIcon
									label={message}
									color={token('color.text.subtlest', colors.N500)}
									LEGACY_fallbackIcon={AddIconOld}
									LEGACY_size="small"
									LEGACY_primaryColor={token('color.text.subtlest', colors.N500)}
								/>
							) : (
								<AddIconOld
									label={message}
									size="small"
									primaryColor={token('color.text.subtlest', colors.N500)}
								/>
							)}
						</CreateButton>
					);

					if (appearance === 'hidden-button') {
						return (
							<HiddenButtonWrapper>
								<ButtonWrapper>{button}</ButtonWrapper>
							</HiddenButtonWrapper>
						);
					}

					return withToolTip(button, String(message));
				}}
			</FormattedMessage>
		);
	}
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled,@atlaskit/design-system/no-html-button -- To migrate as part of go/ui-styling-standard
const CreateButton = styled.button<{
	onClick: () => void;
}>({
	backgroundColor: 'transparent',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${gridSize / 2}px`,
	border: 'none',
	width: '100%',
	padding: `${token('space.050', '4px')} 0`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 3}px`,
	cursor: 'pointer',
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.background.neutral.hovered', colors.N40),
	},
	'&:focus': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		boxShadow: `0 0 0 2px ${token('color.border.focused', colors.B200)}`,
		outline: 'none',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledButton = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	marginTop: `${layout.smallGutter}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	marginTop: `${layout.smallGutter}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled,@atlaskit/ui-styling-standard/no-exported-styles -- To migrate as part of go/ui-styling-standard
export const HiddenButtonWrapper = styled.div<HiddenButtonWrapperProps>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	opacity: (props: HiddenButtonWrapperProps) => (props.isICCFormOpen ? 1 : 0),

	'&:hover, &:focus, &:focus-within': {
		opacity: 1,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	[`[data-component-selector="${DRAGGABLE_COLUMN_SELECTOR_NAME}"] :hover &`]: {
		opacity: 1,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	[`[data-component-selector="${DRAGGABLE_COLUMN_SELECTOR_NAME}"] :focus &`]: {
		opacity: 1,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	[`[data-component-selector="${DRAGGABLE_COLUMN_SELECTOR_NAME}"] :focus-within &`]: {
		opacity: 1,
	},
});
