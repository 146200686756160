import type {
	BoardScopeFilter,
	PlanningUnit,
} from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/types.tsx';

export const FieldType = {
	BuiltIn: 'BuiltIn',
	Custom: 'Custom',
} as const;
export type FieldType = (typeof FieldType)[keyof typeof FieldType];
export type DateFieldType = {
	id: string;
	type: FieldType | string;
	key: string;
};

export type IncrementConfig = {
	startDateField: DateFieldType;
	endDateField: DateFieldType;
	planningUnit: PlanningUnit;
	scenarioColor: string;
	boardScopeFilter?: BoardScopeFilter;
	// when clean the fg dependency_visualisation_program_board_fe_and_be, remove the optional of isConcurrentScheduling and make it is required
	isConcurrentScheduling?: boolean;
	// when clean the fg program_board_flexible_cadence, remove the optional of isConcurrentScheduling and make it is required
	iterationLength?: number;
	iterationsCount?: number;
};
