import {
  JiraIssueAriResourceOwner,
  JiraIssueAriResourceType
} from "./chunk-6MDCCCXR.mjs";
import {
  RegisteredAri
} from "./chunk-YG2E6ZVD.mjs";
import {
  AriParser
} from "./chunk-Q7FABYST.mjs";

// src/jira/issue/manifest.ts
var jiraIssueAriStaticOpts = {
  qualifier: "ari",
  platformQualifier: "cloud",
  cloudId: new RegExp("^[a-zA-Z0-9_\\-.]{1,255}$"),
  // eslint-disable-line no-useless-escape
  resourceOwner: JiraIssueAriResourceOwner,
  resourceType: JiraIssueAriResourceType,
  resourceIdSlug: "{issueId}",
  resourceIdSegmentFormats: {
    issueId: /[0-9]+/
    // eslint-disable-line no-useless-escape
  }
};

// src/jira/issue/index.ts
var JiraIssueAri = class _JiraIssueAri extends RegisteredAri {
  constructor(opts) {
    super(opts);
    this._siteId = opts.cloudId || "", this._issueId = opts.resourceIdSegmentValues.issueId;
  }
  get siteId() {
    return this._siteId;
  }
  get issueId() {
    return this._issueId;
  }
  static create(opts) {
    let derivedOpts = {
      qualifier: jiraIssueAriStaticOpts.qualifier,
      platformQualifier: jiraIssueAriStaticOpts.platformQualifier,
      cloudId: opts.siteId,
      resourceOwner: jiraIssueAriStaticOpts.resourceOwner,
      resourceType: jiraIssueAriStaticOpts.resourceType,
      resourceId: `${opts.issueId}`,
      resourceIdSegmentValues: {
        issueId: opts.issueId
      }
    }, ariOpts = AriParser.fromOpts(derivedOpts, jiraIssueAriStaticOpts);
    return new _JiraIssueAri(ariOpts);
  }
  static parse(maybeAri) {
    let opts = AriParser.fromString(maybeAri, jiraIssueAriStaticOpts);
    return new _JiraIssueAri(opts);
  }
  getVariables() {
    return {
      siteId: this.siteId,
      issueId: this.issueId
    };
  }
};

export {
  JiraIssueAri
};
