import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	unassignedOption: {
		id: 'issue-field-assignee.unassigned-option',
		defaultMessage: 'Unassigned',
		description:
			'Text used in the dropdown item that, when select, will leave the issue unassigned.',
	},
	automaticOption: {
		id: 'issue-field-assignee.automatic-option',
		defaultMessage: 'Automatic',
		description:
			'Text used in the dropdown, when selected, will choose the default user set by the project admin',
	},
	assignToMe: {
		id: 'issue-field-assignee.assign-to-me',
		defaultMessage: 'Assign to me',
		description:
			'Text used in the dropdown item that, when selected, will assign the issue to the current user.',
	},
	searchForAssignee: {
		id: 'issue-field-assignee.search-for-assignee',
		defaultMessage: 'Search for assignee',
		description: 'Input placeholder that prompts the user to type a query to search for assignees.',
	},
	invitePeopleOption: {
		id: 'issue-field-assignee.invite-people-option',
		defaultMessage: 'Add teammate to Jira',
		description:
			'Text used in the dropdown item that, when select, open invite poeple drawer and allow user to invite new people. It will leave the issue unassigned.',
	},
	invitePeopleOptionAdmin: {
		id: 'issue-field-assignee.invite-people-option-admin',
		defaultMessage: 'Invite teammate to Jira',
		description:
			'Text used in the dropdown item that, when select, open invite poeple drawer and allow admin user to add new people. It will leave the issue unassigned.',
	},
});
