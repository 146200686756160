import { token } from '@atlaskit/tokens';

import { contentHorizontalSpacing, gridSize } from '@atlassian/jira-common-styles/src/main.tsx';

/**
 * Use "import { gridSize } from '@atlaskit/theme';" instead
 * @deprecated
 */
export const layout = {
	gutter: 10,
	smallGutter: 5,
	cardRadius: 3,
	cardListRadius: 6,
	adg3smallGutter: 8,
	columnMargin: 6,
	boardBorderWidth: contentHorizontalSpacing,
} as const;

// Non-ADG3 colors
export const colors = {
	shadow: token('color.border', 'rgba(0, 0, 0, 0.2)'),
} as const;

export const zIndex = {
	// Inside board
	boardBorders: 90,
	minimap: 6,
	cardDoneRipple: 5,
	transitionZoneIndicator: 5,
	stickyHeaders: 4,
	swimlaneTickMask: 3,
	stickySwimlaneBar: 2,
	icc: 1,
	transitionZones: 1,
	fullColumnHeightTransitionZones: 3,
} as const;

// We want 32px for inline edit + 8px gutter top and bottom = 48px.
export const columnHeaderHeight = 32 + 2 * layout.adg3smallGutter;
export const columnFixedWidth = 270;
export const columnMinWidth = 150;
export const columnWithMarginWidth = columnFixedWidth + layout.columnMargin * 2;
export const columnMinWithMarginWidth = columnMinWidth + layout.columnMargin * 2;
export const columnHeaderFontSize = 12;

export const swimlaneHeaderHeight = 24 + 2 * layout.adg3smallGutter;

export const minCardHeight = gridSize * 10;

export const miniMapMapHeight = 36;

export const collapsedColumnFixedWidth = 40;
export const collapsedColumnWithMarginWidth = collapsedColumnFixedWidth + layout.columnMargin * 2;

export const scrollbarWidth = '10px';
export const unscheduledColumnWidth = '270px';
const unscheduledColumnTitleHeight = 20;
export const unscheduledColumnHeaderHeight = swimlaneHeaderHeight + unscheduledColumnTitleHeight;
