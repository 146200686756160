import { useState, useEffect } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { ERROR_REPORTING_TEAM, PACKAGE_NAME, SERVICES_ERROR_ID } from '../../common/constants.tsx';
import type { BoardScopeFilter, Increment } from './types.tsx';

export const useFetchIncrements = ({ planId }: { planId: string | undefined }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [increments, setIncrements] = useState<Increment[] | null>(null);

	const removeIncrement = (incrementId: number) => {
		if (!increments) {
			return;
		}
		const index = increments?.findIndex((increment) => increment.id === incrementId);
		if (index !== undefined && index > -1) {
			const newIncrements = [...increments];
			newIncrements.splice(index, 1);
			setIncrements(newIncrements);
		}
	};

	const updateIncrement = (
		incrementId: number,
		updates: {
			name?: Increment['name'];
			boardScopeFilter?: Pick<
				BoardScopeFilter,
				'filterFieldId' | 'filterFieldValue' | 'filterFieldName'
			>;
		},
	) => {
		if (!increments) {
			return;
		}

		const index = increments?.findIndex((increment) => increment.id === incrementId);
		if (index !== undefined && index > -1) {
			const newIncrements = [...increments];
			newIncrements[index] = { ...newIncrements[index], ...updates };
			setIncrements(newIncrements);
		}
	};

	useEffect(() => {
		(async () => {
			try {
				if (!planId) {
					throw new Error('planId is empty in the fetch increments request');
				}
				const res = await performGetRequest(`/rest/jpo/2.0/plans/${planId}/increments`);
				if (res && Array.isArray(res)) {
					setIncrements(res);
				}

				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				fireErrorAnalytics({
					meta: {
						id: SERVICES_ERROR_ID,
						packageName: PACKAGE_NAME,
						teamName: ERROR_REPORTING_TEAM,
					},
					attributes: {
						serviceName: 'useFetchIncrements',
					},
					error,
					sendToPrivacyUnsafeSplunk: true,
				});
			} finally {
				setIsLoading(false);
			}
		})();
	}, [setIncrements, setIsLoading, planId]);

	return {
		isLoading,
		increments,
		removeIncrement,
		updateIncrement,
	};
};
