import React from 'react';
import { DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { Section } from '@atlaskit/menu';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { useHasToolSections } from '@atlassian/jira-software-connect-toolbar/src/controllers/index.tsx';
import { BoardConnectMenuItems } from '@atlassian/jira-software-connect-toolbar/src/ui/board-connect-menu-items/index.tsx';
import type { BoardMenuItemLocation } from '@atlassian/jira-software-connect-toolbar/src/ui/board-connect-menu-items/types.tsx';
import { useIsCMPBoard, useIsJSMBoard } from '../../../../state/state-hooks/capabilities/index.tsx';
import Operations from '../../extension/index.tsx';

type BoardConnectItemsProps = {
	projectKey: string;
	boardId: string;
	onClickWebItem?: () => void;
	isTabNavigation?: boolean;
};

const BoardConnectItemsOld = ({ projectKey, boardId, onClickWebItem }: BoardConnectItemsProps) => {
	const itemLocations: BoardMenuItemLocation[] = [
		'board-tools-1',
		'view-actions-work',
		'view-actions-print',
	];

	const hasToolSections = useHasToolSections({
		boardId,
		projectKey,
		mode: 'work',
		itemLocations,
	});

	const onClick = fg('make_board_menu_popup') ? onClickWebItem : undefined;

	return (
		hasToolSections && (
			<DropdownItemGroup hasSeparator>
				<BoardConnectMenuItems
					boardId={boardId}
					projectKey={projectKey}
					location="board-tools-1"
					onClickWebItem={onClickWebItem}
					mode="work"
				/>
				<BoardConnectMenuItems
					boardId={boardId}
					projectKey={projectKey}
					location="view-actions-work"
					onClickWebItem={onClick}
					mode="work"
				/>
				<BoardConnectMenuItems
					boardId={boardId}
					projectKey={projectKey}
					location="view-actions-print"
					onClickWebItem={onClickWebItem}
					mode="work"
				/>
				<BoardConnectMenuItems
					boardId={boardId}
					projectKey={projectKey}
					mode="work"
					onClickWebItem={onClick}
				/>
			</DropdownItemGroup>
		)
	);
};

const BoardConnectItemsNew = ({
	projectKey,
	boardId,
	onClickWebItem,
	isTabNavigation = false,
}: BoardConnectItemsProps) => {
	const isCMPBoard = useIsCMPBoard();
	const isJSMBoard = useIsJSMBoard();

	const onClick = fg('make_board_menu_popup') ? onClickWebItem : undefined;

	return (
		<Section
			hasSeparator={
				(!isJSMBoard && fg('jsm_views_connect_apps_bugfix')) || !fg('jsm_views_connect_apps_bugfix')
			}
		>
			{isCMPBoard && (
				<>
					<BoardConnectMenuItems
						boardId={boardId}
						projectKey={projectKey}
						location="board-tools-1"
						onClickWebItem={onClickWebItem}
						mode="work"
						isTabNavigation={isTabNavigation}
					/>
					<BoardConnectMenuItems
						boardId={boardId}
						projectKey={projectKey}
						location="view-actions-work"
						onClickWebItem={onClick}
						mode="work"
						isTabNavigation={isTabNavigation}
					/>
					<BoardConnectMenuItems
						boardId={boardId}
						projectKey={projectKey}
						location="view-actions-print"
						onClickWebItem={onClickWebItem}
						mode="work"
						isTabNavigation={isTabNavigation}
					/>
					<BoardConnectMenuItems
						boardId={boardId}
						projectKey={projectKey}
						mode="work"
						onClickWebItem={onClick}
						isTabNavigation={isTabNavigation}
					/>
				</>
			)}
			<Operations isTabNavigation={isTabNavigation} />
		</Section>
	);
};

export const BoardConnectItems = componentWithCondition(
	() => fg('update_connect_apps_in_board_backlog'),
	BoardConnectItemsNew,
	BoardConnectItemsOld,
);
