import { freeze } from 'icepick';
import {
	BACKLOG_ENABLE_CANCEL,
	BACKLOG_ENABLE_CONFIRM,
} from '../../../actions/board/backlog-enable/index.tsx';
import type { Action } from '../../../types.tsx';
import type { BacklogEnableDialogState } from './types.tsx';

const closedState: BacklogEnableDialogState = freeze({ isOpen: false });
const openState: BacklogEnableDialogState = freeze({ isOpen: true });

export const backlogEnableDialogReducer = (
	state: BacklogEnableDialogState = openState,
	action: Action,
): BacklogEnableDialogState => {
	switch (action.type) {
		case BACKLOG_ENABLE_CANCEL:
			return closedState;
		case BACKLOG_ENABLE_CONFIRM:
			return closedState;
		default:
			return state;
	}
};
