import memoizeOne from 'memoize-one';
import { createIndexedDBStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/indexed-db/index.tsx';
import type { AsyncStorage } from '@atlassian/jira-browser-storage-providers/src/types.tsx';

const EXPIRATION_TIME = 72 * 60 * 60 * 1000; // 3 days

/**
 * Returns true if the cached data entry is valid.
 */
export function isValid(cachedDataEntry: { timestamp: number }): boolean {
	const timeDelta = Date.now() - cachedDataEntry.timestamp;
	const expirationTime = EXPIRATION_TIME;
	return timeDelta < expirationTime;
}

/**
 * Reads the account id from the AJS meta-tag.
 */
export const getAccountId = memoizeOne((): string | null => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const el = document.querySelector('meta[name="ajs-atlassian-account-id"]');
	return el?.getAttribute('content') ?? null;
});

export function buildCachedEntryKey(boardId: number | string) {
	const accountId = getAccountId() ?? 'no-account-id';
	return `local-device-cache::${accountId}::${boardId}`;
}

export const preferences: AsyncStorage = createIndexedDBStorageProvider('labs-preferences');
