import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import type { ResponseSuccess as UserFetched } from '@atlassian/jira-user-services/src/types.tsx';
import messages from '../messages.tsx';
import addIcon from './assets/add.png';
import { UNASSIGNED_ID, INVITE_PEOPLE_ID, AUTOMATIC_ID } from './constants.tsx';
import type { User, UserOption } from './types.tsx';

export const getUnassignedUser = ({ formatMessage }: IntlShape): User => ({
	accountId: UNASSIGNED_ID,
	displayName: formatMessage(messages.unassignedOption),
	avatarUrls: {},
});

export const getAutomaticUserOption = ({ formatMessage }: IntlShape): User => ({
	accountId: AUTOMATIC_ID,
	displayName: formatMessage(messages.automaticOption),
	avatarUrls: {},
});

export const getInvitePeopleOption = ({ formatMessage }: IntlShape, isAdmin: boolean): User => ({
	accountId: INVITE_PEOPLE_ID,
	displayName: formatMessage(
		isAdmin ? messages.invitePeopleOptionAdmin : messages.invitePeopleOption,
	),
	avatarUrls: {
		'48x48': addIcon,
	},
});

export const getUserOptionFromUser = ({
	accountId,
	displayName,
	avatarUrls,
	emailAddress,
}: User | UserFetched): UserOption => ({
	id: accountId || '',
	name: displayName,
	avatarUrl: avatarUrls['48x48'],
	// @ts-expect-error Will be part of JIV-13488; `user.emailAddress` returned by the REST API can be null depending on their privacy settings
	byline: emailAddress,
});

export const getUserFromUserOption = ({ id, name, avatarUrl }: UserOption): User => ({
	accountId: id,
	displayName: name,
	avatarUrls: {
		'48x48': avatarUrl,
	},
});

export const getUserAvatarUrl = ({ avatarUrls }: User): string | undefined => avatarUrls['48x48'];

export const getUserShape = (accountId: string, displayName: string, avatarUrl?: string): User => ({
	accountId,
	displayName,
	avatarUrls: {
		'48x48': avatarUrl,
	},
});
