import type { JSONDocNode } from '@atlaskit/editor-json-transformer';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { getTraceId } from '@atlassian/jira-fetch/src/utils/trace-id.tsx';
import type { IssueFlagWithCommentResponse } from './types.tsx';

const noContentStatus = 204;

export const FLAG_WITH_COMMENT_URL = '/rest/greenhopper/3/xboard/issue/flag/flag';

export const makeFlagWithCommentRequest = (
	issueKeys: string[],
	flag: boolean,
	comment: JSONDocNode,
	internal = false,
): Promise<IssueFlagWithCommentResponse> =>
	fetch(FLAG_WITH_COMMENT_URL, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			issueKeys,
			flag,
			comment,
			commentProperty: internal ? '{"key": "sd.public.comment", "value": {"internal": true}}' : '',
			commentVisibility: '',
		}),
	}).then((response) => {
		if (!response.ok) {
			const { status } = response;
			const traceId = getTraceId(response);
			if (traceId) {
				throw new FetchError(status, `Fetch call failed with status code: ${status}`, traceId);
			} else {
				throw new FetchError(status);
			}
		}

		if (response.status === noContentStatus) {
			return null;
		}
		return response.text().then((body) => (body ? JSON.parse(body) : null));
	});

export default makeFlagWithCommentRequest;
