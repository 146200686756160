import type { ThemeAppearance } from '@atlaskit/lozenge';

// We do this to enforce the type safety of this object and keep it in line with
// ThemeAppearance so developers cannot add something that doesn't already
// exist in ThemeAppearance enum
export const LOZENGE_APPEARANCE: {
	default: ThemeAppearance;
	inprogress: ThemeAppearance;
	moved: ThemeAppearance;
	new: ThemeAppearance;
	removed: ThemeAppearance;
	success: ThemeAppearance;
} = {
	default: 'default',
	inprogress: 'inprogress',
	moved: 'moved',
	new: 'default',
	removed: 'removed',
	success: 'success',
};

export const CARD_CONTAINER_SELECTOR_NAME = 'platform-board-kit.ui.card-container';
export const CARD_CONTAINER_SELECTOR = `[data-component-selector="${CARD_CONTAINER_SELECTOR_NAME}"]`;
export const COLUMN_TITLE_SELECTOR_NAME = 'platform-board-kit.ui.column-title';
export const COLUMN_TITLE_SELECTOR = `[data-component-selector="${COLUMN_TITLE_SELECTOR_NAME}"]`;
export const COLUMN_TITLE_LOZENGES_SELECTOR_NAME = 'platform-board-kit.ui.column-title.lozenges';
export const COLUMN_TITLE_LOZENGES_SELECTOR = `[data-component-selector="${COLUMN_TITLE_LOZENGES_SELECTOR_NAME}"]`;
export const COLUMN_WRAPPER_FORM_SELECTOR_NAME =
	'platform-board-kit.ui.column.column-create.form.column-wrapper';

export const DRAGGABLE_COLUMN_SELECTOR_NAME = 'platform-board-kit.ui.column.draggable-column';
export const DRAGGABLE_COLUMN_SELECTOR = `[data-component-selector="${DRAGGABLE_COLUMN_SELECTOR_NAME}"]`;
export const UPDATE_BOARD_SCROLL_POSITION = 'update-board-scroll-position';

export const DATA_ANNOUNCED_IGNORE_DND_TYPE = 'data-announced-ignore-dnd-type';
export const DATA_ANNOUNCED_COLUMN_HEADER = 'data-announced-column-header';
export const DATA_ANNOUNCED_CARD_HEADER = 'data-announced-card-header';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { COLUMN_DND_TYPE, CARD_DND_TYPE, TRANSITION_ZONE_DND_TYPE } from './drag-drop';

export const BoardView = {
	JSW_BOARD: 'jsw-board',
	INCREMENT_PLANNING_BOARD: 'increment-planning-board',
	JSM_BOARD: 'jsm-board',
};
