import type { Locale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
import { getLocale } from '@atlassian/jira-platform-utils-date-fns/src/main.tsx';

export const getWeekStartDay = (locale: Locale) => getLocale(locale)?.options?.weekStartsOn || 0;

export const getNextQuarterStartDate = (date: Date) => {
	const today = date ?? new Date();
	const QUARTER_STARTS = [1, 4, 7, 10];
	let month: number;
	let isNextYear = false;
	// if today is the start of a quarter, return today, otherwise return the start of the next quarter
	if (today.getDate() === 1 && QUARTER_STARTS.includes(today.getMonth() + 1)) {
		month = today.getMonth() + 1;
	} else {
		const nextStart = QUARTER_STARTS.find((q) => q > today.getMonth());
		month = nextStart ?? QUARTER_STARTS[0];
		isNextYear = month === QUARTER_STARTS[0];
	}
	const year = today.getFullYear() + (isNextYear ? 1 : 0);
	return `${year}-${`0${month}`.slice(-2)}-01T12:00:00`;
};
