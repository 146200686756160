import React, { type ComponentType, type ReactNode } from 'react';

export function withRuntimeProvider<
	Props extends {
		children: ReactNode;
	},
>(WrappedComponent: ComponentType<Props>): ComponentType<Props> {
	return (props: Props) => {
		if (__SERVER__) {
			return <>{props.children}</>;
		}
		return <WrappedComponent {...props} />;
	};
}

export default withRuntimeProvider;
