import { useCallback, useEffect, useRef } from 'react';
import type { RowState } from '../../common/types.tsx';
import { useScrollState } from '../use-scroll-state/index.tsx';
import type { ScrollOptions } from './types.tsx';
import { getScrollToPosition, hasScrolledIntoView } from './utils.tsx';

export const DEFAULT_HEIGHT_OFFSET = 80;
const SCROLLING_INTERVAL = 50;
const SCROLLING_ATTEMPT = 5;

export const useScrollTo = (rowStates: RowState[]) => {
	// scrollState CONSTANTLY changes
	// Just grabbing out the scrollElement will hardly change
	const { scrollElement } = useScrollState();
	// We do NOT want to regenerate the return function everytime so we should use REFs
	// here instead
	const scrollElementRef = useRef<HTMLElement | null>(null);
	const rowStatesRef = useRef<RowState[]>([]);

	useEffect(() => {
		// Only bind to the scrollElementRef once. We don't need it anymore once we have it
		if (scrollElementRef.current === null && scrollElement) {
			scrollElementRef.current = scrollElement;
		}

		// Always ensure our rowStates ref is up to date
		rowStatesRef.current = rowStates ?? rowStatesRef.current;
	}, [rowStates, scrollElement]);

	const scrollTo = useCallback(
		(index: number, options?: ScrollOptions, attempt = 1) => {
			let timeoutId: number | null = null;
			// Early termination for invalid data
			if (index === -1 || attempt > SCROLLING_ATTEMPT) {
				options?.onComplete?.();
				return;
			}

			if (
				rowStatesRef?.current == null || // Can be null on first pass
				scrollElementRef?.current == null || // Can be null on first pass
				index >= rowStatesRef.current.length
			) {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				timeoutId = window.setTimeout(() => {
					scrollTo(index, options, attempt + 1);
				}, SCROLLING_INTERVAL);
				return () => {
					timeoutId && clearTimeout(timeoutId);
				};
			}

			const scrollElementEl = scrollElementRef.current;
			const rowState = rowStatesRef.current;

			// Complete scrolling when the target is on screen.
			if (hasScrolledIntoView(scrollElementRef.current, rowStatesRef.current[index])) {
				options?.onComplete?.();
				return;
			}

			const scrollToPosition = getScrollToPosition(scrollElementEl, rowState[index], options);

			if (options?.immediate) {
				scrollElementEl.scrollTop = scrollToPosition;
			} else {
				scrollElementEl.scrollTo({
					top: scrollToPosition,
					behavior: 'smooth', // Use smooth scroll or its too janky
				});
			}

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			timeoutId = window.setTimeout(
				() => scrollTo(index, options, attempt + 1),
				SCROLLING_INTERVAL,
			);
			return () => {
				timeoutId && clearTimeout(timeoutId);
			};
		},
		[scrollElementRef, rowStatesRef],
	);

	return scrollTo;
};
