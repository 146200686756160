import mapValues from 'lodash/mapValues';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { HaveISeenItFlags, GetFlagsResponse } from './types.tsx';

export const HAVE_I_SEEN_IT_URL = 'https://have-i-seen-it.us-east-1.prod.public.atl-paas.net';

export const setFlag = (userKey: string, flagKey: string): Promise<void> => {
	const data = { userKey, flagKey };
	return fetch(`${HAVE_I_SEEN_IT_URL}/flag`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data),
	}).then((response) => {
		if (!response.ok) {
			throw new FetchError(response.status, response.statusText);
		}
		return undefined;
	});
};

const convertToDates = (response: GetFlagsResponse): HaveISeenItFlags =>
	mapValues(response, ({ status, lastSeenDate }) => ({
		status,
		lastSeenDate: lastSeenDate ? new Date(lastSeenDate) : null,
	}));

export const getFlags = (userKey: string, flagKeys: string[]): Promise<HaveISeenItFlags> => {
	const params = new URLSearchParams();
	flagKeys.forEach((flagKey) => params.append('flagKey', flagKey));
	params.append('userKey', userKey);
	return fetch(`${HAVE_I_SEEN_IT_URL}/flags?${params.toString()}`)
		.then((response) => {
			if (!response.ok) {
				throw new FetchError(response.status, response.statusText);
			}
			return response.json();
		})
		.then((response) => convertToDates(response));
};
