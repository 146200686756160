import React, { forwardRef } from 'react';
import {
	ContextualAnalyticsData,
	AnalyticsEventToProps,
} from '@atlassian/jira-product-analytics-bridge';
import { CONTEXT_MENU_CHILDREN_WRAPPER_TESTID } from './constants.tsx';
import type { ChildrenWrapperProps } from './types.tsx';

const WrapperWithAnalytics = AnalyticsEventToProps('contextMenu', {
	onContextMenu: 'opened',
})(forwardRef(({ createAnalyticsEvent, ...props }, ref) => <div {...props} ref={ref} />));

export const ChildrenWrapperWithAnalytics = forwardRef(
	({ children, onChildrenContextMenu }: ChildrenWrapperProps, ref) => {
		const WrapperWithAnalyticsComponent = WrapperWithAnalytics;

		return (
			<ContextualAnalyticsData attributes={{ inputSource: 'rightClick' }}>
				<WrapperWithAnalyticsComponent
					ref={ref}
					onContextMenu={onChildrenContextMenu}
					data-testid={CONTEXT_MENU_CHILDREN_WRAPPER_TESTID}
				>
					{children}
				</WrapperWithAnalyticsComponent>
			</ContextualAnalyticsData>
		);
	},
);
