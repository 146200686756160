import type { RealtimeEvent } from '@atlassian/jira-realtime/src/common/types/events.tsx';

export const REALTIME_DISPATCH_EVENT = 'state.actions.realtime.REALTIME_DISPATCH_EVENT' as const;

export type RealtimeEventAction = {
	type: typeof REALTIME_DISPATCH_EVENT;
	payload: {
		event: RealtimeEvent;
	};
};

export const dispatchRealtimeEvent = (event: RealtimeEvent): RealtimeEventAction => ({
	type: REALTIME_DISPATCH_EVENT,
	payload: { event },
});

export type Action = RealtimeEventAction;
