/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { AppSkeletonImage } from '../../common/ui/AppSkeletonImage.tsx';
import branchDetailsPopupSkeleton from './assets/branch-details-popup.tpl.svg';
import commitDetailsPopupSkeleton from './assets/commit-details-popup.tpl.svg';
import deploymentDetailsPopupSkeleton from './assets/deployment-details-popup.tpl.svg';
import designDetailsPopupSkeleton from './assets/design-details-popup.tpl.svg';
import prDetailsPopupSkeleton from './assets/pr-details-popup.tpl.svg';

// Try to keep SPA JS bundle as small as possible, skeletons should not have dependencies, so here we use hard coding instead of import gridSize

const customCssStyles = css({
	// increase self specificity to ensure deterministic override
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		border: 'none',
		marginTop: token('space.negative.200', '-16px'),
	},
});
const skeletonStyles = css({
	width: '320px',
	height: '160px',
	padding: `${token('space.0', '0')} ${token('space.200', '16px')}`,
	boxSizing: 'border-box',
});
export const SkeletonDevInfoTypes = {
	BRANCH: 'branch',
	COMMIT: 'commit',
	PULLREQUEST: 'pullrequest',
	DEPLOYMENT: 'deployment',
	DESIGN: 'design',
};
export type SkeletonDevInfoType = (typeof SkeletonDevInfoTypes)[keyof typeof SkeletonDevInfoTypes];
const skeletonAsset = {
	[SkeletonDevInfoTypes.BRANCH]: branchDetailsPopupSkeleton,
	[SkeletonDevInfoTypes.COMMIT]: commitDetailsPopupSkeleton,
	[SkeletonDevInfoTypes.PULLREQUEST]: prDetailsPopupSkeleton,
	[SkeletonDevInfoTypes.DEPLOYMENT]: deploymentDetailsPopupSkeleton,
	[SkeletonDevInfoTypes.DESIGN]: designDetailsPopupSkeleton,
} as const;
type Props = {
	devinfoType: SkeletonDevInfoType;
};
/**
 * Provides a visual placeholder for various development information types, enhancing user experience by indicating upcoming content.
 */
export const DevInfoDetailsPopupSkeleton = ({ devinfoType }: Props) => (
	<div
		css={[
			devinfoType === SkeletonDevInfoTypes.BRANCH && skeletonStyles,
			devinfoType === SkeletonDevInfoTypes.COMMIT && skeletonStyles,
			devinfoType === SkeletonDevInfoTypes.PULLREQUEST &&
				// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				css({
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
					...skeletonStyles,
					height: '180px',
				}),
			devinfoType === SkeletonDevInfoTypes.DEPLOYMENT &&
				// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				css({
					width: '320px',
					height: '160px',
				}),
			devinfoType === SkeletonDevInfoTypes.DESIGN && skeletonStyles,
		]}
	>
		<AppSkeletonImage src={skeletonAsset[devinfoType]} css={customCssStyles} />
	</div>
);
