import React from 'react';
import { styled } from '@compiled/react';
import { PopupMenuGroup } from '@atlaskit/menu';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import type {
	ManualRule,
	ManualRuleExecutor,
} from '@atlassian/jira-automation-platform/src/common/types.tsx';
import { useAutomationRecommendations } from '@atlassian/jira-automation-platform/src/controllers/use-automation-recommendations/index.tsx';
import Footer from '@atlassian/jira-automation-platform/src/ui/footer/main.tsx';
import { NoIssueSelectedEmptyState } from '@atlassian/jira-automation-platform/src/ui/no-issue-selected-empty-state/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	fireOperationalAnalyticsDeferred,
	MountEvent,
} from '@atlassian/jira-product-analytics-bridge';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { ConditionalRecommendation } from '../../../common/ui/conditional-recommendation/index.tsx';
import { useBaseAutomationUrl, useMenuContext } from '../../../controllers/menu-context/index.tsx';
import ManualRules from './manual-rules/main.tsx';
import messages from './messages.tsx';

type RunRuleProps = {
	initialised: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	error: any;
	rules: ManualRule[];
	executingRuleId: number | null;
	selectedIssueIds?: number[];
	executeRule: ManualRuleExecutor;
	// eslint-disable-next-line jira/react/handler-naming
	triggerFetch: () => Promise<void>;
	setClosed: () => void;
};

const RunRule = (props: RunRuleProps) => {
	const {
		initialised,
		error: rulesError,
		rules,
		executingRuleId,
		executeRule,
		triggerFetch,
		selectedIssueIds,
		setClosed,
	} = props;
	const { formatMessage } = useIntl();
	const [{ canManageAutomations, projectAri }] = useMenuContext();
	const { environment, cloudId } = useTenantContext();
	const [baseAutomationUrl] = useBaseAutomationUrl();

	const [{ data: recommendations, error: recommendationsError, loading, fetched }] =
		useAutomationRecommendations({
			env: environment,
			cloudId,
			target: projectAri,
			shouldFetch: canManageAutomations,
		});

	// Note: fetched is false if the request errors
	const initialisedRecommendations =
		(!loading && fetched) || recommendationsError != null || !canManageAutomations;
	const hasRecommendation = !!(recommendations && recommendations?.data.length > 0);

	const error: Error = rulesError || recommendationsError;

	return (
		<Container
			role="dialog"
			aria-modal="false"
			aria-labelledby="automation-menu.ui.popup"
			data-testid="automation-menu.ui.popup.run-rule.container"
		>
			<MountEvent
				onMount={(event) =>
					fireOperationalAnalyticsDeferred(event, 'automationMenuContent mounted')
				}
			/>
			<Header data-testid="automation-menu.ui.popup.run-rule.menu-header">
				{formatMessage(messages.automationHeader)}
			</Header>
			<PopupMenuGroup>
				{(selectedIssueIds && selectedIssueIds.length > 0) || error ? (
					<ManualRules
						initialised={initialised && initialisedRecommendations}
						executingRuleId={executingRuleId}
						error={error}
						rules={rules}
						executeRule={executeRule}
						triggerFetch={triggerFetch}
						selectedIssueIds={selectedIssueIds}
						recommendations={recommendations?.data}
						setClosed={setClosed}
					/>
				) : (
					<ConditionalRecommendation
						initialised={initialisedRecommendations}
						canManageAutomations={canManageAutomations}
						baseAutomationUrl={baseAutomationUrl}
						recommendations={recommendations?.data}
						fallback={<NoIssueSelectedEmptyState />}
						setClosed={setClosed}
					/>
				)}
				{canManageAutomations && (
					<Footer baseAutomationUrl={baseAutomationUrl} hasSeparator={!hasRecommendation} />
				)}
			</PopupMenuGroup>
		</Container>
	);
};

export default RunRule;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	paddingTop: token('space.250', '20px'),
	width: '348px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Header = styled.h5({
	margin: `0 ${token('space.250', '20px')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: `${token('color.text.subtlest', colors.N700)}`,
	textTransform: 'uppercase',
});
