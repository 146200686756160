import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	pageTitle: {
		id: 'software.main.document-title.page-title',
		defaultMessage: '{boardName} - Agile board',
		description: '',
	},
	pageTitleWithSprint: {
		id: 'software.main.document-title.page-title-with-sprint',
		defaultMessage:
			'{sprintCount, plural, =0 {{boardName} - Agile Board} one {{sprintName} - {boardName} - Agile Board} other {# sprints - {boardName} - Agile Board}}',
		description: '',
	},
});
