import { freeze } from 'icepick';
import { createSelector } from 'reselect';
import {
	ADD,
	REMOVE,
} from '@atlassian/jira-software-add-comment-on-flag-action/src/ui/constants.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import type { State } from '../../reducers/types.tsx';
import { isCardSelected, getCardSelection } from '../card/card-selectors.tsx';
import { getUi } from '../software/software-selectors.tsx';
import { boardIssuesSelector } from './board-issue-selectors.tsx';
import { isIssueFlagged } from './issue-selectors.tsx';

const getUiFlagWithCommentModal = (state: State) => getUi(state).issueFlagWithCommentModal;

export const isIssueFlagWithCommentModalOpen = (state: State): boolean =>
	Boolean(getUiFlagWithCommentModal(state)?.isOpen);

const getIssueId = (state: State): IssueId | undefined => getUiFlagWithCommentModal(state)?.issueId;

const getSelectedIssueIds = (state: State): IssueId[] | undefined => {
	const issueId = getIssueId(state);
	if (issueId) {
		return isCardSelected(state)(issueId) ? getCardSelection(state) : [issueId];
	}
	return undefined;
};

export const getFlagForFlagWithCommentModal = (state: State): typeof ADD | typeof REMOVE => {
	const issueId = getIssueId(state);
	if (issueId) {
		return isIssueFlagged(state, issueId) ? REMOVE : ADD;
	}
	return ADD;
};

type CardType = {
	id: string;
	name: string;
	iconUrl: string;
};

export type PartialIssue = {
	id: IssueId;
	key: string;
	summary: string;
	type: CardType;
};

const noSelectedIssues = freeze([]);
export const getIssuesForFlagWithCommentModal = createSelector(
	[boardIssuesSelector, getSelectedIssueIds],
	(allIssues, issueIds) => {
		if (issueIds) {
			return issueIds.map((id) => ({
				id: allIssues[id].id,
				key: allIssues[id].key,
				summary: allIssues[id].summary,
				type: {
					id: String(allIssues[id].typeId),
					name: allIssues[id].typeName ?? '',
					iconUrl: allIssues[id].typeUrl ?? '',
				},
			}));
		}

		return noSelectedIssues;
	},
);
