/** @jsx jsx */
import React, { useCallback, useMemo } from 'react';
import { css, jsx } from '@compiled/react';
import isNil from 'lodash/isNil';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import type { PriorityValue } from '@atlassian/jira-issue-field-priority-chip/src/common/types.tsx';
import { PriorityChipFieldBoundary } from '@atlassian/jira-issue-field-priority-chip/src/ui/async.tsx';
import type { PriorityChipField as PriorityChipFieldType } from '@atlassian/jira-issue-field-priority-chip/src/ui/main.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { PRIORITY_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { PriorityFieldStatic } from '../../../../../../common/fields/priority-field-static/index.tsx';
import { useEditableField } from '../../../../../../common/fields/use-editable-field/index.tsx';
import type { EditableFieldProps } from '../../../../../../common/fields/use-editable-field/types.tsx';
import { PACKAGE_NAME } from '../../../../../../model/constants.tsx';
import type { Priority } from '../../../../../../model/issue/issue-types.tsx';
import { setInlineEditing } from '../../../../../../state/actions/card/index.tsx';
import { issueIncrementPlanningUpdate } from '../../../../../../state/actions/issue/update/index.tsx';
import { useBoardDispatch, useBoardSelector } from '../../../../../../state/index.tsx';
import { getPreventInlineEditing } from '../../../../../../state/selectors/board/board-selectors.tsx';

import { INLINE_EDITING_FIELD_ZINDEX } from '../constants.tsx';
import { PRIORITY_BUTTON_TEST_ID } from './constants.tsx';
import type { PriorityFieldProps } from './types.tsx';

export const PriorityChipFieldLazy = lazyForPaint<typeof PriorityChipFieldType>(() =>
	import(
		/* webpackChunkName: "async-issue-field-priority" */ '@atlassian/jira-issue-field-priority-chip'
	).then(({ PriorityChipField }) => PriorityChipField),
);

const stopClickPropagation = (e: React.MouseEvent | React.KeyboardEvent) => {
	e.stopPropagation();
};

const fieldKey = 'priority';
const PriorityFieldInner = ({
	issueKey,
	priority,
	onSaveField,
}: {
	issueKey: string;
	priority: Priority;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onSaveField: (issueKey: IssueKey, fieldId: string, value: PriorityValue) => Promise<any>;
} & Partial<EditableFieldProps>) => {
	const preventInlineEditing = useBoardSelector((state) => getPreventInlineEditing(state));
	const hasPriority = !isNil(priority) && !isNil(priority.name) && !isNil(priority.iconUrl);
	return (
		<ContextualAnalyticsData
			attributes={{
				isInlineEditing: true,
				fieldKey,
				fieldType: 'priority',
			}}
		>
			<PriorityFieldWrapper
				role="button"
				onClick={stopClickPropagation}
				onKeyDown={stopClickPropagation}
				testId={PRIORITY_BUTTON_TEST_ID}
				disableClick={preventInlineEditing}
			>
				{hasPriority && <PriorityChipFieldLazy issueKey={issueKey} onSaveField={onSaveField} />}
			</PriorityFieldWrapper>
		</ContextualAnalyticsData>
	);
};
export const PriorityField = ({
	shouldRenderRichField,
	priority,
	isCMPBoard,
	issueKey,
	...props
}: PriorityFieldProps) => {
	const [{ value: priorityFieldConfig }] = useFieldConfig(issueKey, PRIORITY_TYPE, {
		skipRefresh: false,
	});

	const shouldRenderRich = Boolean(shouldRenderRichField && priorityFieldConfig);

	const editableField = useEditableField({
		isExperienceAvailable: shouldRenderRich,
	});

	const fallback = useMemo(
		() => <PriorityFieldStatic name={priority.name} iconUrl={priority.iconUrl} />,
		[priority],
	);
	const dispatch = useBoardDispatch();
	const onOpen = useCallback(() => {
		dispatch(setInlineEditing('priority', props.issueId, true));
	}, [dispatch, props.issueId]);
	const onClose = useCallback(() => {
		dispatch(setInlineEditing('priority', props.issueId, false));
	}, [dispatch, props.issueId]);
	const onSaveField = useCallback(
		async (_: string, fieldId: string, fieldValue: PriorityValue) => {
			dispatch(
				issueIncrementPlanningUpdate({
					issueId: props.issueId,
					fieldId,
					fieldValue: fieldValue ? fieldValue.id : null,
				}),
			);
		},
		[dispatch, props.issueId],
	);

	if (shouldRenderRich) {
		return (
			<PriorityChipFieldBoundary packageName={PACKAGE_NAME} fallback={fallback}>
				<UFOSegment name="ng-board.inline-edit.priority-field">
					<Placeholder name="priority-field-inner" fallback={fallback}>
						<PriorityFieldInner
							{...props}
							{...editableField}
							issueKey={issueKey}
							onOpen={onOpen}
							onClose={onClose}
							priority={priority}
							onSaveField={onSaveField}
						/>
					</Placeholder>
				</UFOSegment>
			</PriorityChipFieldBoundary>
		);
	}

	return fallback;
};

const PriorityFieldWrapper = ({
	disableClick,
	onClick,
	onKeyDown,
	testId,
	children,
	role,
}: {
	role: string;
	children: React.ReactNode;
	disableClick?: boolean;
	onClick: (e: React.MouseEvent | React.KeyboardEvent) => void;
	onKeyDown: (e: React.MouseEvent | React.KeyboardEvent) => void;
	testId: string;
}) => {
	return (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div
			role={role}
			data-testid={testId}
			onClick={onClick}
			onKeyDown={onKeyDown}
			css={[priorityFieldWrapperStyles, disableClick && priorityFieldWrapperDisableClickStyles]}
		>
			{children}
		</div>
	);
};

const priorityFieldWrapperStyles = css({
	cursor: 'pointer',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > [id$="popup-select"]': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		position: 'absolute !important',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		zIndex: INLINE_EDITING_FIELD_ZINDEX,
	},
});

const priorityFieldWrapperDisableClickStyles = css({
	pointerEvents: 'none',
});
