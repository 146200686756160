import React from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import type { LaunchToolchainSpotlightProps } from './types.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const ContainerLinking = lazy(
	() => import(/* webpackChunkName: "async-devops-launch-toolchain-spotlight" */ './ui/main.tsx'),
);

export const AsyncLaunchToolchainSpotlight = ({
	projectId,
	projectKey,
}: LaunchToolchainSpotlightProps) => (
	<Placeholder name="cotainer-linking" fallback={null}>
		<ContainerLinking projectId={projectId} projectKey={projectKey} />
	</Placeholder>
);
