import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { JSONDocNode } from '@atlaskit/editor-json-transformer';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import type { FlagMeta } from '../../meta/flag-meta.tsx';
import {
	beginOptimisticUiMeta,
	commitOptimisticUiMeta,
	type OptimisticUiMeta,
	revertOptimisticUiWithFlagMeta,
} from '../../meta/optimistic-ui-meta.tsx';

export const ISSUE_FLAG_WITH_COMMENT_REQUEST =
	'state.actions.issue.flag-with-comment.ISSUE_FLAG_WITH_COMMENT_REQUEST' as const;

export type IssueFlagWithCommentRequestAction = {
	type: typeof ISSUE_FLAG_WITH_COMMENT_REQUEST;
	payload: {
		issueIds: IssueId[];
		flag: boolean;
		comment: JSONDocNode;
	};
	meta: OptimisticUiMeta & {
		analyticsEvent: UIAnalyticsEvent;
	};
};

export const issueFlagWithCommentRequest = (
	issueIds: IssueId[],
	flag: boolean,
	comment: JSONDocNode,
	analyticsEvent: UIAnalyticsEvent,
): IssueFlagWithCommentRequestAction => ({
	type: ISSUE_FLAG_WITH_COMMENT_REQUEST,
	payload: {
		issueIds,
		flag,
		comment,
	},
	meta: {
		...beginOptimisticUiMeta(),
		analyticsEvent,
	},
});

export const ISSUE_FLAG_WITH_COMMENT_SUCCESS =
	'state.actions.issue.flag-with-comment.ISSUE_FLAG_WITH_COMMENT_SUCCESS' as const;

export type IssueFlagWithCommentSuccessAction = {
	type: typeof ISSUE_FLAG_WITH_COMMENT_SUCCESS;
	payload: {
		issueIds: IssueId[];
	};
	meta: OptimisticUiMeta;
};

export const issueFlagWithCommentSuccess = (
	optimisticId: string,
	issueIds: IssueId[],
): IssueFlagWithCommentSuccessAction => ({
	type: ISSUE_FLAG_WITH_COMMENT_SUCCESS,
	payload: {
		issueIds,
	},
	meta: commitOptimisticUiMeta(optimisticId),
});

export const ISSUE_FLAG_WITH_COMMENT_FAILURE =
	'state.actions.issue.flag-with-comment.ISSUE_FLAG_WITH_COMMENT_FAILURE' as const;

export type IssueFlagWithCommentFailureAction = {
	type: typeof ISSUE_FLAG_WITH_COMMENT_FAILURE;
	payload: {
		issueIds: IssueId[];
	};
	meta: OptimisticUiMeta & FlagMeta;
};

export const issueFlagWithCommentFailure = (
	optimisticId: string,
	issueIds: IssueId[],
): IssueFlagWithCommentFailureAction => ({
	type: ISSUE_FLAG_WITH_COMMENT_FAILURE,
	payload: {
		issueIds,
	},
	meta: revertOptimisticUiWithFlagMeta(optimisticId),
});

export type Action =
	| IssueFlagWithCommentRequestAction
	| IssueFlagWithCommentSuccessAction
	| IssueFlagWithCommentFailureAction;
