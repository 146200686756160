import React from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import { SuccessAutoDismissFlag } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import flagMessages from './messages.tsx';
import type { Props } from './types.tsx';

const CardCreateUnmappedFlagInIpBoard = ({ onDismissed, id, onClearAllButtonClicked }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<SuccessAutoDismissFlag
			id={id}
			onDismissed={onDismissed}
			title={formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? flagMessages.updatedIssueHiddenTitleIssueTermRefresh
					: flagMessages.updatedIssueHiddenTitle,
			)}
			description={formatMessage(flagMessages.updatedIssueHiddenDescription)}
			actions={[
				{
					content: formatMessage(flagMessages.clearFiltersActionText),
					onClick: () => {
						onClearAllButtonClicked();
						onDismissed && onDismissed(id);
					},
				},
			]}
		/>
	);
};

export default CardCreateUnmappedFlagInIpBoard;
