import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noIssueSourceWarning: {
		defaultMessage: 'Associate this team with an issue source to view its iterations',
		id: 'software.main.board-container.board.swimlane.increment-planning-buttons.sprint-association-btn.no-issue-source-warning',
		description: 'Missing team/sprint association message',
	},
	scenarioTeamWarning: {
		defaultMessage:
			'Save this team using the <strong>Review changes</strong> button to view their capacity',
		id: 'software.main.board-container.board.swimlane.increment-planning-buttons.sprint-association-btn.scenario-team-warning',
		description: 'Scenario-only team warning message, meaning the team is not saved in Jira',
	},
	nonBoardTeamWarning: {
		defaultMessage:
			"To plan with this team's sprints, <link>make sure your plan is setup for capacity planning.</link>",
		id: 'software.main.board-container.board.swimlane.increment-planning-buttons.sprint-association-btn.non-board-team-warning',
		description: 'Warning message for teams with non-board issue sources, eg. filter or project',
	},
	changeSprints: {
		defaultMessage: 'Change sprints',
		id: 'software.main.board-container.board.swimlane.increment-planning-buttons.sprint-association-btn.change-sprints',
		description: 'Change sprints button label',
	},
	addSprints: {
		defaultMessage: 'Add sprints',
		id: 'software.main.board-container.board.swimlane.increment-planning-buttons.sprint-association-btn.add-sprints',
		description: 'Add sprints button label',
	},
	noIssueSourceWarningIssueTermRefresh: {
		defaultMessage: 'Associate this team with a work item source to view its iterations',
		id: 'software.main.board-container.board.swimlane.increment-planning-buttons.sprint-association-btn.no-issue-source-warning-issue-term-refresh',
		description: 'Missing team/sprint association message',
	},
});
