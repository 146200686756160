import React from 'react';
import { OnboardingNudgeErrorBoundary } from '@atlassian/jira-onboarding-quickstart-core/src/common/ui/onboarding-nudge-error-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import {
	BOARD_CREATE_ISSUE_NUDGE_ID,
	ERROR_BOUNDARY_ID_KANBAN_TOUR_NUDGE,
} from '../../../common/constants.tsx';
import type { WrapperProps } from '../../../common/types.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const BoardAccordionNudgeLazy = lazyAfterPaint(
	() =>
		import(
			/* webpackChunkName: "async-software-kanban-onboarding-create-issues-nudge" */ './index'
		).then((exportedModule) => exportedModule.CreateIssueAccordionNudge),
	{
		ssr: false,
	},
);

export const BoardKanbanCreateIssueNudgeAsync = ({ children, ...restProps }: WrapperProps) => (
	<OnboardingNudgeErrorBoundary
		id={ERROR_BOUNDARY_ID_KANBAN_TOUR_NUDGE}
		fallback={children}
		attributes={{
			nudgeId: BOARD_CREATE_ISSUE_NUDGE_ID,
		}}
	>
		<Placeholder name="software-kanban-onboarding-create-issues-nudge" fallback={children}>
			<BoardAccordionNudgeLazy {...restProps}>{children}</BoardAccordionNudgeLazy>
		</Placeholder>
	</OnboardingNudgeErrorBoundary>
);

BoardKanbanCreateIssueNudgeAsync.displayName = 'BoardKanbanCreateIssueNudgeAsync';
