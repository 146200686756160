import { createSelector } from 'reselect';
import isNil from 'lodash/isNil';
import memoize from 'lodash/memoize';
import reduce from 'lodash/reduce';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import type { Issue } from '../../../model/issue/issue-types.tsx';
import type { IssueChildrenState } from '../../reducers/entities/issue-children/types.tsx';
import type { State } from '../../reducers/types.tsx';
import { getEntities, getIssues, getMissingParents } from '../software/software-selectors.tsx';

export const getIssueChildren = (state: State): IssueChildrenState =>
	getEntities(state).issueChildren;

export const issueChildrenSelector = createSelector(getIssueChildren, (issueChildren) =>
	Object.keys(issueChildren).map((issueId) => ({
		...issueChildren[issueId],
	})),
);

export const numTotalIssueChildren = createSelector(getIssues, (issues) =>
	reduce(
		Object.keys(issues).map((key) => issues[key]),
		(num: number, issue: Issue): number => num + issue.numTotalChildren,
		0,
	),
);

export const numCompleteIssueChildren = createSelector(getIssues, (issues) =>
	reduce(
		Object.keys(issues).map((key) => issues[key]),
		(num: number, issue: Issue): number => num + issue.numCompleteChildren,
		0,
	),
);

export const getChildrenOfParent = createSelector([issueChildrenSelector], (issueChildren) =>
	memoize((parentIssueId) =>
		issueChildren
			.filter((issue: Issue) => issue.parentId === parentIssueId)
			.map((issue: Issue): IssueId => issue.id),
	),
);

export const getParentIdByIssueChildSelector = createSelector(
	[getIssueChildren, getIssues, getMissingParents],
	(issueChildren, issues, missingParents) => (issueChildId: IssueId) => {
		const parentId = issueChildren[issueChildId]?.parentId;

		// make sure the parent of the child issue is available in redux context (issues or missing parents)
		if (isNil(parentId) || isNil(issues[parentId] ?? missingParents[parentId])) return null;

		return parentId;
	},
);
