import { freeze, setIn, unset } from 'icepick';
import isEmpty from 'lodash/isEmpty';
import {
	COLUMN_CREATE_FAILURE,
	COLUMN_CREATE_REQUEST,
	COLUMN_CREATE_SUCCESS,
} from '../../../actions/column/create/index.tsx';
import {
	COLUMN_DELETE_REQUEST,
	COLUMN_DELETE_SUCCESS,
} from '../../../actions/column/delete/index.tsx';
import {
	COLUMN_RANK_REQUEST,
	COLUMN_RANK_FAILURE,
	COLUMN_RANK_SUCCESS,
} from '../../../actions/column/rank/index.tsx';
import {
	COLUMN_RENAME_FAILURE,
	COLUMN_RENAME_REQUEST,
	COLUMN_RENAME_SUCCESS,
} from '../../../actions/column/rename/index.tsx';
import type { Action } from '../../../types.tsx';
import type { ColumnsState } from './types.tsx';

export const columnsUIReducer = (
	state: ColumnsState = freeze({}),
	action: Action,
): ColumnsState => {
	if (action.type === COLUMN_RENAME_REQUEST) {
		const { columnId } = action.payload;
		return setIn(state, [String(columnId), 'isUpdating'], true);
	}

	if (action.type === COLUMN_RENAME_SUCCESS || action.type === COLUMN_RENAME_FAILURE) {
		const { columnId } = action.payload;
		return setIn(state, [String(columnId), 'isUpdating'], false);
	}

	if (action.type === COLUMN_RANK_REQUEST) {
		const { columnId } = action.payload;
		return setIn(state, [String(columnId), 'isUpdating'], true);
	}

	if (action.type === COLUMN_RANK_SUCCESS || action.type === COLUMN_RANK_FAILURE) {
		const { columnId } = action.payload;
		return setIn(state, [String(columnId), 'isUpdating'], false);
	}

	if (action.type === COLUMN_CREATE_REQUEST) {
		const { temporaryColumnId } = action.payload;
		return setIn(state, [String(temporaryColumnId), 'isUpdating'], true);
	}

	if (action.type === COLUMN_DELETE_REQUEST) {
		const { closestColumn } = action.payload;
		if (closestColumn) {
			return setIn(state, [String(closestColumn.id), 'isUpdating'], true);
		}
	}

	if (action.type === COLUMN_DELETE_SUCCESS) {
		const { closestColumn } = action.payload;
		if (closestColumn) {
			return unset(state, String(closestColumn.id));
		}
	}

	if (action.type === COLUMN_CREATE_SUCCESS || action.type === COLUMN_CREATE_FAILURE) {
		const { temporaryColumnId } = action.payload;
		const columnStates = unset(state[String(temporaryColumnId)], 'isUpdating');
		if (isEmpty(columnStates)) {
			return unset(state, String(temporaryColumnId));
		}

		return setIn(state, [String(temporaryColumnId)], columnStates);
	}

	return state;
};
