import type { MessageDescriptor } from '@atlassian/jira-intl';
import type { TemplateRecommendationId } from '../../../common/types.tsx';
import messages from './messages.tsx';

type MessageMapping = Record<TemplateRecommendationId, MessageDescriptor>;

export const headingMessagesMapping: MessageMapping = {
	devops_template_10: messages.failedDeploymentsHeading, // deploymentFailsTemplate
	security_template_1: messages.criticalVulnerabilitiesHeading, // criticalVulnerabilityTemplate
	devops_template_8: messages.successfulDeploymentsHeading, // deploymentSuccessfulTemplate
	devops_template_6: messages.versionsCreatedHeading, // versionCreatedTemplate
};

export const descriptionsMessagesMapping: MessageMapping = {
	devops_template_10: messages.failedDeploymentsDescription, // deploymentFailsTemplate
	security_template_1: messages.criticalVulnerabilitiesDescription, // criticalVulnerabilityTemplate
	devops_template_8: messages.successfulDeploymentsDescription, // deploymentSuccessfulTemplate
	devops_template_6: messages.versionsCreatedDescription, // versionCreatedTemplate
};
