import type { PlanningUnit } from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/types.tsx';
import type { ActionPromise } from '../../../../model/issue/issue-increment-planning-types.tsx';

export type PlannedCapacityRequestPayload = {
	capacity: string;
	planningUnit: PlanningUnit;
	workingHoursPerDay: number;
	iterationId: number;
	teamId: string;
	totalCapacity: number | null;
	defaultCapacity: number;
};

export type PlannedCapacityCompletePayload = {
	capacity: number | null;
	iterationId: string;
	teamId: string;
};

export const UPDATE_PLANNED_CAPACITY_REQUEST =
	'state.actions.sprint.update-planned-capacity.UPDATE_PLANNED_CAPACITY_REQUEST' as const;

export type UpdatePlannedCapacityRequestAction = {
	type: typeof UPDATE_PLANNED_CAPACITY_REQUEST;
	payload: PlannedCapacityRequestPayload;
	promise?: ActionPromise;
};

export const updatePlannedCapacityRequest = (
	payload: PlannedCapacityRequestPayload,
): UpdatePlannedCapacityRequestAction => ({
	type: UPDATE_PLANNED_CAPACITY_REQUEST,
	payload,
});

export const UPDATE_PLANNED_CAPACITY_SUCCESS =
	'state.actions.sprint.update-planned-capacity.UPDATE_PLANNED_CAPACITY_SUCCESS' as const;

export type UpdatePlannedCapacitySuccessAction = {
	type: typeof UPDATE_PLANNED_CAPACITY_SUCCESS;
	payload: PlannedCapacityCompletePayload;
};

export const updatePlannedCapacitySuccess = (
	payload: PlannedCapacityCompletePayload,
): UpdatePlannedCapacitySuccessAction => ({
	type: UPDATE_PLANNED_CAPACITY_SUCCESS,
	payload,
});

export const UPDATE_PLANNED_CAPACITY_FAILED =
	'state.actions.sprint.update-planned-capacity.UPDATE_PLANNED_CAPACITY_FAILED' as const;

export type UpdatePlannedCapacityFailedAction = {
	type: typeof UPDATE_PLANNED_CAPACITY_FAILED;
};

export const updatePlannedCapacityFailed = (): UpdatePlannedCapacityFailedAction => ({
	type: UPDATE_PLANNED_CAPACITY_FAILED,
});

export type Action =
	| UpdatePlannedCapacityRequestAction
	| UpdatePlannedCapacitySuccessAction
	| UpdatePlannedCapacityFailedAction;
