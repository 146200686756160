// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentProps } from 'react';

export const AutomationGlyph = (props: ComponentProps<'svg'>) => (
	<svg
		{...props}
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
	>
		<path d="M14 5L10 19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
		<path
			d="M14 5L7 12H17L10 19"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
