import type { IssueStatusCategoryId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { FilterableIssue, MatcherCreator } from '../types.tsx';

export const statusCategoryMatcherCreator: MatcherCreator<
	IssueStatusCategoryId[],
	FilterableIssue
> = (statusCategoryFilter) => {
	const statusCategorySet = new Set(statusCategoryFilter);

	return ({ statusCategoryId }) =>
		statusCategoryId !== undefined && statusCategorySet.has(statusCategoryId);
};
