import type { Observable as ObservableType } from 'rxjs/Observable';
import { gqlTagSwag } from '@atlassian/jira-software-swag/src/index.tsx';
import { mutation$ } from '@atlassian/jira-software-swag/src/services/mutation/index.tsx';
import { boardRenameAnalyticsOptions } from '../../common/graphql/metrics/index.tsx';
import type { SetBoardNameMutationGQLData } from './types.tsx';

export const softwareBoardRenameOperationName = 'SoftwareBoardRename';

export const boardRenameMutation = gqlTagSwag`
    mutation SoftwareBoardRename($boardId: ID!, $boardName: String!) {
        setBoardName(input: {boardId: $boardId, boardName: $boardName}) {
            boardName
        }
    }
`;

export const boardRename = (
	contextPath: string,
	boardId: string,
	boardName: string,
): ObservableType<SetBoardNameMutationGQLData> =>
	mutation$(
		contextPath,
		softwareBoardRenameOperationName,
		boardRenameMutation,
		{
			boardId,
			boardName,
		},
		boardRenameAnalyticsOptions,
	);
