import React from 'react';
import { showFlag, dismissFlag } from '../../services/index.tsx';
import type { State } from '../../services/store/index.tsx';
import type { Action, FlagsMapper, Store } from '../../services/types.tsx';
import { Dispatcher } from './main.tsx';

export type FlagsDispatcherProps<TAction extends Action, TState> = {
	store?: Store<TState>;
	mapper: FlagsMapper<TAction, TState>;
};

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { Dispatcher };

export const FlagsDispatcher = <TAction extends Action, TState extends State>({
	store,
	mapper,
}: FlagsDispatcherProps<TAction, TState>) => (
	<Dispatcher store={store} mapper={mapper} showFlag={showFlag} dismissFlag={dismissFlag} />
);
