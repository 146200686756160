import React, { memo, useRef, useMemo } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { generateMediaImageUrl } from '@atlassian/jira-custom-theme-constants/src/utils.tsx';

const CARD_COVER_IMAGE_HEIGHT = 140;

/**
 * A card in Software TMP & Business boards has fixed with (260px) at the moment,
 * but we want to load the image at a higher resolution and then scale it down to avoid blurry images.
 * If you use 260px, there will be a gap between the image and the card border.
 */
const COLUMN_FIXED_WIDTH = 270;

export interface CardImageMediaProps {
	fileName?: string;
	mediaApiFileId: string;
	mediaClientId: string;
	mediaExternalEndpointUrl: string;
	mediaReadToken: string;
	// Null will skip default value and request full size image. Software CMP board does not have a set column/card width at the moment.
	width?: number | null;
}

export const CardCoverBackgroundImg = ({
	fileName,
	mediaApiFileId,
	mediaClientId,
	mediaExternalEndpointUrl,
	mediaReadToken,
	width = COLUMN_FIXED_WIDTH,
}: CardImageMediaProps) => {
	const imageRef = useRef<HTMLImageElement>(null);

	const imgUrl = useMemo(() => {
		if (!mediaApiFileId || !mediaClientId || !mediaExternalEndpointUrl || !token) {
			return null;
		}

		return generateMediaImageUrl({
			mediaApiFileId,
			mediaClientId,
			mediaExternalEndpointUrl,
			token: mediaReadToken,
			mediaEndpoint: 'image',
			...(width && { width }),
			'max-age': Number.MAX_SAFE_INTEGER,
		});
	}, [mediaApiFileId, mediaClientId, mediaExternalEndpointUrl, mediaReadToken, width]);

	if (!imgUrl) {
		return null;
	}

	return (
		<MemoizedCardImageCover
			ref={imageRef}
			data-testid="platform-board-kit.ui.card.card-cover.background-img.card-cover-image"
			src={imgUrl}
			title={fileName}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardImageCoverImg = styled.div<{ src: string }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundImage: (props) => `url(${props.src})`,
	backgroundColor: token('color.background.neutral', 'white'),
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center',
	height: `${CARD_COVER_IMAGE_HEIGHT}px`,
	width: '100%',
	borderTopLeftRadius: token('border.radius', '4px'),
	borderTopRightRadius: token('border.radius', '4px'),
});

const urlWithoutToken = (url: string) => {
	const parsedUrl = new URL(url);
	parsedUrl.searchParams.delete('token');
	return parsedUrl.toString();
};

/**
 * Since the board items are refetched after any board changes, we receive a new token
 * every time. As a result, the image is refetched and causes a flicker. We don't need
 * to rerender the image in that case. The cases we need to are when the image is removed
 * or added, in which case we will retrieve a token separately and rerender.
 */
const MemoizedCardImageCover = memo(
	CardImageCoverImg,
	(prevProps, nextProps) => urlWithoutToken(prevProps.src) === urlWithoutToken(nextProps.src),
);
