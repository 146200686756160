import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import type { ProjectType } from '@atlassian/jira-common-constants/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { CloudId, ProjectId, ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Environment } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import type { RecommendationsPathProps } from './types.tsx';

interface EnvironmentDetails {
	failedStack: boolean;
	releasedSuccessfully: boolean;
	rolledBackStack: boolean;
	stack: string;
}
interface EnvironmentResponse {
	liveStack: EnvironmentDetails;
	otherStacks: EnvironmentDetails[];
}

type StacksResponse = Record<Environment, EnvironmentResponse>;

const localStorage = createLocalStorageProvider('automation-menu');

const getLatestStack = async (cloudId: CloudId): Promise<string | null> =>
	fetch(`/gateway/api/automation/internal-api/jira/${cloudId}/pro/rest/environments`)
		.then((response) => response.json())
		.then((response: StacksResponse) => {
			const stacks = response.prod.otherStacks
				.filter(({ releasedSuccessfully }) => !releasedSuccessfully)
				.map(({ stack }) => stack);

			return stacks.length > 0 ? stacks[0] : null;
		})
		.catch(() => null);

export const getAutomationStack = async (
	env: Environment | null,
	cloudId: CloudId,
): Promise<string> => {
	if (env === 'prod') {
		if (fg('automation-platform-pre-prod-instance')) {
			const stack = await getLatestStack(cloudId);
			return stack ?? 'pro';
		}

		return 'pro';
	}

	// Set this manually to your Automation dev stack e.g. devesukmajaya. The full key is: __storejs_automation-menu_automationStack
	const stackOverride = localStorage.get('automationStack');
	return stackOverride || 'staging';
};

export const getFetchManualRulesUrl = async (
	env: Environment | null,
	cloudId: CloudId,
	projectId: ProjectId,
): Promise<string> => {
	const automationStack = await getAutomationStack(env, cloudId);
	return `/gateway/api/automation/internal-api/jira/${cloudId}/${automationStack}/rest/rules/invocation/${projectId}`;
};

export const getExecuteManualRuleUrl = async (
	env: Environment | null,
	cloudId: CloudId,
	ruleId: number,
): Promise<string> => {
	const automationStack = await getAutomationStack(env, cloudId);
	return `/gateway/api/automation/internal-api/jira/${cloudId}/${automationStack}/rest/rules/invocation/${ruleId}`;
};

export const getAutomationRecommendationsUrl = async ({
	env,
	cloudId,
	target,
}: RecommendationsPathProps): Promise<string> => {
	const automationStack = await getAutomationStack(env, cloudId);
	return `/gateway/api/automation/internal-api/jira/${cloudId}/${automationStack}/rest/v1/recommendation/templates?target=${target}`;
};

export const getBaseAutomationUrl = ({
	projectKey,
	projectType,
	isSimplified,
}: {
	isSimplified: boolean;
	projectType: ProjectType | undefined;
	projectKey: ProjectKey;
}): string => {
	/**
	 * Confusing mapping of urls here.
	 * Classic Projects - always under /automate
	 * Next Gen Projects - under /automation for all project types except JSD
	 */
	switch (projectType) {
		case 'service_desk':
			return `/jira/servicedesk/projects/${projectKey}/settings/automate`;
		case 'business':
			return `/jira/core/projects/${projectKey}/settings/${
				isSimplified ? 'automation' : 'automate'
			}`;
		default:
			return `/jira/software/${isSimplified ? '' : 'c/'}projects/${projectKey}/settings/${
				isSimplified ? 'automation' : 'automate'
			}`;
	}
};
