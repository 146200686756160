import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	tooltip: {
		id: 'insights-board-button.kanban-button.tooltip',
		defaultMessage: 'Board insights',
		description: 'Tooltip describing the content that the button toggles',
	},
	tooltipTerminologyRefresh: {
		id: 'insights-board-button.kanban-button.tooltip-terminology-refresh',
		defaultMessage: 'Work health',
		description: 'Tooltip describing the content that the button toggles',
	},
});
