export const HTTP_INFORMATION_CODE_GROUP = '1xx' as const;
export const HTTP_SUCCESSFUL_CODE_GROUP = '2xx' as const;
export const HTTP_REDIRECTION_CODE_GROUP = '3xx' as const;
export const HTTP_CLIENT_CODE_GROUP = '4xx' as const;
export const HTTP_SERVER_CODE_GROUP = '5xx' as const;
const HTTP_UNKNOWN_CODE_GROUP = 'unknown' as const;
export type HttpStatusCodeGroups =
	| typeof HTTP_INFORMATION_CODE_GROUP
	| typeof HTTP_SUCCESSFUL_CODE_GROUP
	| typeof HTTP_REDIRECTION_CODE_GROUP
	| typeof HTTP_CLIENT_CODE_GROUP
	| typeof HTTP_SERVER_CODE_GROUP
	| typeof HTTP_UNKNOWN_CODE_GROUP;
/**
 * Categorizes a given HTTP status code into one of several predefined groups.
 * These groups represent the nature of the response: informational, successful,
 * redirection, client error, or server error. This classification aids in
 * simplifying response handling. In cases where the status code is absent,
 * it assigns an 'unknown' category, accommodating scenarios where the response
 * status might be indeterminate or not standard.
 */
export const getHttpStatusCodeGroup = (statusCode?: number): HttpStatusCodeGroups => {
	if (statusCode === null || statusCode === undefined) {
		return HTTP_UNKNOWN_CODE_GROUP;
	}
	if (statusCode >= 100 && statusCode < 200) {
		return HTTP_INFORMATION_CODE_GROUP;
	}
	if (statusCode >= 200 && statusCode < 300) {
		return HTTP_SUCCESSFUL_CODE_GROUP;
	}
	if (statusCode >= 300 && statusCode < 400) {
		return HTTP_REDIRECTION_CODE_GROUP;
	}
	if (statusCode >= 400 && statusCode < 500) {
		return HTTP_CLIENT_CODE_GROUP;
	}
	if (statusCode >= 500 && statusCode < 600) {
		return HTTP_SERVER_CODE_GROUP;
	}
	return HTTP_UNKNOWN_CODE_GROUP;
};
