import React, { useState, useCallback, useMemo, useRef } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import DropdownMenu, {
	DropdownItem,
	DropdownItemGroup,
	type CustomTriggerProps,
} from '@atlaskit/dropdown-menu';
import AddIconNew from '@atlaskit/icon/core/add';
import AddIconOld from '@atlaskit/icon/glyph/add';
import ExpandIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { mergeRefs } from '@atlassian/jira-merge-refs/src/index.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useModalDialogActions } from '@atlassian/jira-software-modal-dialog/src/controllers/index.tsx';
import {
	type VersionMenuItem,
	ENABLED,
	NO_AVAILABLE_ISSUE_OR_VERSION,
	NO_PERMISSION,
} from '../../../model/version/version-types.tsx';
import { useBoardSelector } from '../../../state/index.tsx';
import { hasMultipleProjects } from '../../../state/selectors/software/software-selectors.tsx';
import { getFilteredVersionsSelector } from '../../../state/selectors/version/version-selectors.tsx';
import { NewVersionReleaseModal } from '../../modals/new-version-release-modal/index.tsx';
import { ReleaseVersionModal } from '../../modals/release-version-modal/index.tsx';
import messages from './messages.tsx';

type Props = {
	rapidViewId: string;
};

const DROPDOWN_MENU_ITEM_TEST_ID = 'software-board.header.release-version.menu-item';
const ReleaseVersion = ({ rapidViewId }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [isNewVersionModalOpen, setIsNewVersionModalOpen] = useState(false);
	const closeNewVersionReleaseModal = useCallback(
		() => setIsNewVersionModalOpen(false),
		[setIsNewVersionModalOpen],
	);

	const isMultiProjectBoard = useBoardSelector(hasMultipleProjects);
	const releaseButtonRef: React.Ref<HTMLButtonElement> = useRef(null);
	const { setReturnFocusTo } = useModalDialogActions();
	const [selectedVersion, setSelectedVersion] = useState<VersionMenuItem | null>(null);
	const closeReleaseModal = () => setSelectedVersion(null);
	const { releaseState, versionMenuItems, hasIssuesWithoutVersion, lastColumnName } =
		useBoardSelector(getFilteredVersionsSelector);

	const dropdownMenuTrigger = useCallback(
		({ triggerRef, ...triggerProps }: CustomTriggerProps) => {
			const isDisabled = releaseState === NO_PERMISSION;
			setReturnFocusTo(releaseButtonRef);
			const tooltipContent =
				!isDropdownOpen && releaseState === ENABLED
					? formatMessage(
							expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
								? messages.tooltipReleaseIssueTermRefresh
								: messages.tooltipRelease,
							{
								columnName: lastColumnName,
							},
						)
					: null;

			return (
				<Tooltip
					content={
						isDisabled
							? formatMessage(
									expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
										? messages.tooltipDisabledIssueTermRefresh
										: messages.tooltipDisabled,
								)
							: tooltipContent
					}
				>
					<Button
						{...triggerProps}
						aria-label={formatMessage(messages.dropdownAriaLabel)}
						ref={mergeRefs(triggerRef, releaseButtonRef)}
						iconAfter={
							fg('tnk-1613-jira-visual-refresh-jsw-board-icons-2') ? (
								<ChevronDownIcon
									label=""
									LEGACY_fallbackIcon={ExpandIcon}
									LEGACY_size="medium"
									color={token('color.icon')}
								/>
							) : (
								<ExpandIcon size="medium" label="" />
							)
						}
						isDisabled={isDisabled}
						testId="software-board.header.release-version.trigger"
					>
						{formatMessage(messages.title)}
					</Button>
				</Tooltip>
			);
		},
		[formatMessage, isDropdownOpen, lastColumnName, releaseState, setReturnFocusTo],
	);
	const onDropdownOpenChange = useCallback(
		(attrs: { isOpen: boolean }) => {
			if (attrs.isOpen) {
				fireUIAnalytics(
					createAnalyticsEvent({
						actionSubject: 'board.release.dropdown-open',
						action: 'button clicked',
					}),
				);
			}

			setIsDropdownOpen(attrs.isOpen);
		},
		[createAnalyticsEvent],
	);

	const handleNewVersionClick = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				actionSubject: 'board.release.create-new-version',
				action: 'menu item clicked',
			}),
		);
		setIsNewVersionModalOpen(true);
		setIsDropdownOpen(false);
	}, [setIsDropdownOpen, setIsNewVersionModalOpen, createAnalyticsEvent]);

	const versionMenuItemElems = useMemo(
		() =>
			versionMenuItems.map((versionItem) => {
				const handleClick = () => {
					fireUIAnalytics(
						createAnalyticsEvent({
							actionSubject: 'board.release.version',
							action: 'menu item clicked',
						}),
					);
					setSelectedVersion(versionItem);
					setIsDropdownOpen(false);
				};
				return (
					<DropdownItem
						key={`${versionItem.id} ${versionItem.projectKey}`}
						onClick={handleClick}
						testId={DROPDOWN_MENU_ITEM_TEST_ID}
					>
						<Tooltip content={versionItem.name}>
							<VersionMenuItemContainer>
								<VersionNameContainer>{versionItem.name}</VersionNameContainer>
								<ProjectKeyContainer>
									{isMultiProjectBoard && versionItem.projectKey}
								</ProjectKeyContainer>
							</VersionMenuItemContainer>
						</Tooltip>
					</DropdownItem>
				);
			}),
		[createAnalyticsEvent, isMultiProjectBoard, versionMenuItems],
	);

	return (
		<>
			{selectedVersion != null && (
				<ReleaseVersionModal
					setModalClosed={closeReleaseModal}
					versionId={selectedVersion.id}
					versionName={selectedVersion.name}
					projectKey={selectedVersion.projectKey}
				/>
			)}
			{isNewVersionModalOpen && (
				<NewVersionReleaseModal
					boardId={rapidViewId}
					setModalClosed={closeNewVersionReleaseModal}
				/>
			)}
			<DropdownMenu
				placement="bottom-end"
				isOpen={isDropdownOpen}
				onOpenChange={onDropdownOpenChange}
				trigger={dropdownMenuTrigger}
				shouldRenderToParent
			>
				{versionMenuItemElems.length ? (
					<DropdownItemGroup
						testId="software-board.header.release-version.menu-group"
						title={formatMessage(messages.version)}
					>
						{versionMenuItemElems}
					</DropdownItemGroup>
				) : null}

				{releaseState === NO_AVAILABLE_ISSUE_OR_VERSION ? (
					<DropdownItem isDisabled testId={DROPDOWN_MENU_ITEM_TEST_ID}>
						{formatMessage(
							expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
								? messages.nothingToReleaseIssueTermRefresh
								: messages.nothingToRelease,
							{
								columnName: lastColumnName,
							},
						)}
					</DropdownItem>
				) : null}

				{hasIssuesWithoutVersion && (
					<DropdownItemGroup hasSeparator={versionMenuItems.length > 0}>
						<DropdownItem
							elemBefore={
								fg('tnk-1613-jira-visual-refresh-jsw-board-icons-2') ? (
									<AddIconNew
										label=""
										LEGACY_fallbackIcon={AddIconOld}
										LEGACY_size="small"
										color={token('color.icon')}
									/>
								) : (
									<AddIconOld size="small" label="" />
								)
							}
							onClick={handleNewVersionClick}
							testId={DROPDOWN_MENU_ITEM_TEST_ID}
						>
							{formatMessage(messages.menuItemCreateVersion)}
						</DropdownItem>
					</DropdownItemGroup>
				)}
			</DropdownMenu>
		</>
	);
};

export default ReleaseVersion;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const VersionMenuItemContainer = styled.div({
	display: 'inline-flex',
	overflow: 'hidden',
	justifyContent: 'space-between',
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const VersionNameContainer = styled.div({
	flexGrow: 1,
	marginRight: token('space.100', '8px'),
	textOverflow: 'ellipsis',
	overflowX: 'hidden',
	whiteSpace: 'nowrap',
	width: '200px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProjectKeyContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N200),
});
