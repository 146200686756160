import { getCachedQuickFilters } from '../utils/index.tsx';
import type { ParsedQueryParameters } from '../utils/types.tsx';

export const FETCH_BACKLOG_DATA_OPERATION_NAME = 'fetchBacklogData';

export interface FetchPlanOptions {
	rapidViewId: string | number;
	forceConsistency?: boolean;
	activeQuickFilters?: Array<string>;
	operation?: string;
}

const parseActiveQuickFilters = (
	queryParameters: ParsedQueryParameters,
	boardId: number,
): Array<string> | undefined => {
	if (queryParameters?.customFilter && typeof queryParameters?.customFilter === 'string') {
		return queryParameters.customFilter.split(',');
	}

	return getCachedQuickFilters('backlog', boardId)?.split(',');
};

export const buildPlanDataFetchOptions = (
	boardId: number,
	queryParameters: ParsedQueryParameters,
): FetchPlanOptions => ({
	rapidViewId: boardId,
	forceConsistency: queryParameters?.forceConsistency
		? /true/i.test(String(queryParameters.forceConsistency))
		: true,
	activeQuickFilters: parseActiveQuickFilters(queryParameters, boardId),
	operation: FETCH_BACKLOG_DATA_OPERATION_NAME,
});
