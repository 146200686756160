/* eslint-disable max-classes-per-file */

class ExtensibleError extends Error {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	constructor(message: string, options?: any) {
		super(message, options);
		this.message = message;
		this.name = this.constructor.name;
		if (typeof Error.captureStackTrace === 'function') {
			Error.captureStackTrace(this, this.constructor);
		} else {
			this.stack = new Error(message).stack;
		}
	}
}

type GraphqlError = {
	message: string;
	path: string[];
	extensions: {
		statusCode: number | null;
		userMessage?: string;
		errorType?: string;
	};
};

/**
 * Generic HTTP error for calling Fetch.
 * <p>
 * This would be used if there isn't a specific HTTP error that you are expecting to receive from
 * the server. For example, a Bad Gateway error (502).
 */
export class FetchError extends ExtensibleError {
	statusCode: number;

	traceId: string | null | undefined;

	skipSentry?: boolean;

	networkError: {
		statusCode: number;
	} | null;

	graphQLErrors: GraphqlError[] = [];

	constructor(statusCode: number, message?: string, traceId?: string | null) {
		super(message == null ? `Fetch call failed with status code: ${statusCode}` : message);
		this.name = 'FetchError';
		this.statusCode = statusCode;
		this.networkError = { statusCode };
		this.skipSentry = true;

		if (traceId != null) {
			this.traceId = traceId;
		}
	}
}

export class ResponseNullError extends ExtensibleError {
	skipSentry?: boolean;

	constructor(message: string) {
		super(message);
		this.name = 'ResponseNullError';
		this.skipSentry = true;
	}
}
