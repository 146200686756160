import React from 'react';
import { styled } from '@compiled/react';
import isEmpty from 'lodash/isEmpty';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { CardLabel } from './label/index.tsx';
import type { Props, CardLabelsProps } from './types.tsx';

const CmpCardLabels = ({ labels, highlight = [] }: Props) =>
	!isEmpty(labels) ? (
		<Overflow>
			<Tooltip content={labels.join(', ')} position="bottom-start">
				<Gradient>
					{isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_2') ? (
						<Box xcss={CmpContainerStyles}>
							{labels.map((label, index) => (
								<CardLabel key={index} text={label} highlight={highlight} />
							))}
						</Box>
					) : (
						<CmpContainer>
							{labels.map((label, index) => (
								<CardLabel key={index} text={label} highlight={highlight} />
							))}
						</CmpContainer>
					)}
				</Gradient>
			</Tooltip>
		</Overflow>
	) : null;

const TmpCardLabels = ({ labels, highlight = [] }: Props) =>
	// eslint-disable-next-line no-nested-ternary
	!isEmpty(labels) ? (
		isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_2') ? (
			<Box xcss={ContainerStyles}>
				{labels.map((label, index) => (
					<CardLabel key={index} text={label} highlight={highlight} />
				))}
			</Box>
		) : (
			<Container>
				{labels.map((label, index) => (
					<CardLabel key={index} text={label} highlight={highlight} />
				))}
			</Container>
		)
	) : null;

export const CardLabels = ({ labels, isCMPBoard = false, highlight = [] }: CardLabelsProps) =>
	isCMPBoard ? (
		<CmpCardLabels labels={labels} highlight={highlight} /> // now renders within custom fields
	) : (
		<TmpCardLabels labels={labels} highlight={highlight} />
	);

export default CardLabels;

const ContainerStyles = xcss({
	pointerEvents: 'none',
	display: 'flex',
	flexFlow: 'row wrap',
	alignItems: 'center',
	margin: 'space.negative.050',
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	pointerEvents: 'none',
	display: 'flex',
	flexFlow: 'row wrap',
	alignItems: 'center',
	margin: token('space.negative.050', '-4px'),
});

const CmpContainerStyles = xcss({
	alignItems: 'center',
	display: 'inline-flex',
	margin: 'space.negative.050',
	width: '100%',
	flexWrap: 'wrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CmpContainer = styled.div({
	alignItems: 'center',
	display: 'inline-flex',
	margin: token('space.negative.050', '-4px'),
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Gradient = styled.div({
	width: '100%',
	position: 'relative',
	overflow: 'hidden',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'::after': {
		content: '',
		top: 0,
		left: 0,
		display: 'block',
		height: '100%',
		width: '100%',
		position: 'absolute',
		transition: 'background-color 140ms ease-in-out',
		background: 'linear-gradient(to right, transparent 90%, var(--jsw-card-background-color) 100%)',
		backgroundAttachment: 'local',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Overflow = styled.div<{ isOverflowing?: boolean }>({
	overflow: 'hidden',
	width: '100%',
});
