import { entityArrayToMap } from '../../../../services/software/software-data-transformer.tsx';
import { WORK_DATA_SET, WORK_DATA_CRITICAL_SET } from '../../../actions/work/index.tsx';
import type { Action } from '../../../types.tsx';
import type { IssueTypesState } from '../issue-types/types.tsx';

export const projectIssueTypesReducer = (
	state: IssueTypesState = {},
	action: Action,
): IssueTypesState => {
	if (action.type === WORK_DATA_SET || action.type === WORK_DATA_CRITICAL_SET) {
		const {
			payload: { projectIssueTypes },
		} = action;

		return entityArrayToMap(projectIssueTypes);
	}

	return state;
};
