import React, { useCallback, useEffect, useMemo } from 'react';
import { useIsAnyAppLoading } from '@atlassian/jira-forge-action-apps-controller/src/index.tsx';
import {
	ForgeAppsRuntimeContainer,
	type ForgeAppRenderer,
} from '@atlassian/jira-forge-action-apps-controller/src/runtime-container.tsx';
import { ForgeAnalyticsWrapper } from '@atlassian/jira-forge-ui-analytics/src/common/ui/index.tsx';
import { getAnalyticsAttributesFromExtension } from '@atlassian/jira-forge-ui-analytics/src/common/utils/get-analytics-attributes-from-extension/index.tsx';
import type { Source } from '@atlassian/jira-forge-ui-types/src/common/types/analytics.tsx';
import type { Extension } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import type { ExtensionPointModule } from '@atlassian/jira-forge-ui-types/src/common/types/module.tsx';
import type { Attributes } from '@atlassian/jira-product-analytics-bridge';

export type ActionMenuRendererProps<E extends Extension, D extends Record<string, unknown>> = {
	localId?: string;
	extension: E;
	extensionData: D;
	onClose: () => void;
	onLoad?: () => void;
	onInitialRender?: () => void;
	onError?: () => void;
	analyticsAttributes: Attributes;
};

export type ActionMenuRenderer<
	E extends Extension,
	D extends Record<string, unknown>,
> = React.ComponentType<ActionMenuRendererProps<E, D>>;

type ActionMenuForgeRuntimeContainerProps<
	E extends Extension,
	D extends Record<string, unknown>,
> = {
	onAppsFinishLoading?: () => void;
	forgeAppRenderer: ActionMenuRenderer<E, D>;
	analyticsSource: Source;
	module: ExtensionPointModule;
};

export const ActionMenuForgeRuntimeContainer = <
	E extends Extension,
	D extends Record<string, unknown>,
>({
	onAppsFinishLoading,
	forgeAppRenderer: ForgeAppRenderer,
	analyticsSource,
	module,
}: ActionMenuForgeRuntimeContainerProps<E, D>) => {
	const [isAnyForgeAppLoading] = useIsAnyAppLoading();

	useEffect(() => {
		// this is to close the dropdown when all forge apps are loaded
		if (onAppsFinishLoading && !isAnyForgeAppLoading) {
			onAppsFinishLoading();
		}
	}, [isAnyForgeAppLoading, onAppsFinishLoading]);

	const AdaptedForgeAppRenderer = useMemo(() => {
		return makeForgeAppRenderer(ForgeAppRenderer, module, analyticsSource);
	}, [ForgeAppRenderer, analyticsSource, module]);

	return <ForgeAppsRuntimeContainer forgeAppRenderer={AdaptedForgeAppRenderer} />;
};

const makeForgeAppRenderer = <E extends Extension, D extends Record<string, unknown>>(
	Renderer: ActionMenuRenderer<E, D>,
	module: ExtensionPointModule,
	analyticsSource: Source,
): ForgeAppRenderer<E, D> => {
	return ({ handleError: baseHandleError, app, localId, closeApp, setLoaded }) => {
		const handleError = useCallback(() => {
			baseHandleError && baseHandleError();
		}, [baseHandleError]);

		const analyticsAttributes = useMemo(() => {
			return {
				source: analyticsSource,
				...getAnalyticsAttributesFromExtension(app.extension),
			};
		}, [app.extension]);

		const rendererProps = useMemo(
			() => ({
				localId,
				extension: app.extension,
				extensionData: app.extensionData,
				onClose: closeApp,
				analyticsAttributes,
				onLoad: setLoaded,
				onInitialRender: setLoaded,
				onError: handleError,
			}),
			[
				analyticsAttributes,
				app.extension,
				app.extensionData,
				closeApp,
				handleError,
				localId,
				setLoaded,
			],
		);

		return (
			<ForgeAnalyticsWrapper
				module={module}
				attributes={analyticsAttributes}
				shouldFireInitializedEvent
			>
				<Renderer {...rendererProps} />
			</ForgeAnalyticsWrapper>
		);
	};
};
