import { metrics } from '@atlassian/browser-metrics';
import {
	TTI_RAF_MARK,
	TTI_RAF_KEY,
} from '@atlassian/jira-providers-spa-apdex-analytics/src/constants.tsx';

export const SLO_TTR = 4000;
export const SLO_TTI = 8000;

const SLO_FMP = 4000;

export const marks = {
	EARLY_FETCH_START: 'EARLY_FETCH_START',
	EARLY_FETCH_END: 'EARLY_FETCH_END',
} as const;

const NEXT_GEN_BOARD = 'next-gen-board';
const UIF_BOARD_NEW_UI = 'classic-board';
const INCREMENT_PLANNING_BOARD = 'increment-planning-board';

type BOARD_TYPE = typeof NEXT_GEN_BOARD | typeof UIF_BOARD_NEW_UI | typeof INCREMENT_PLANNING_BOARD;

const boardPageLoad = (key: BOARD_TYPE) =>
	metrics.pageLoad({
		key,
		featureFlags: [],
		ssr: {
			doneAsFmp: true,
		},
		slo: {
			fmp: {
				initial: { threshold: SLO_FMP },
				transition: { threshold: SLO_FMP },
			},
			tti: {
				initial: { threshold: SLO_TTI },
				transition: { threshold: SLO_TTI },
			},
		},
		timings: [
			{
				key: 'early-fetch',
				startMark: marks.EARLY_FETCH_START,
				endMark: marks.EARLY_FETCH_END,
			},
			{ key: TTI_RAF_KEY, endMark: TTI_RAF_MARK },
		],
	});

export const nextGenBoardPageLoad = boardPageLoad(NEXT_GEN_BOARD);
export const uifBoardNewUiPageLoad = boardPageLoad(UIF_BOARD_NEW_UI);
export const incrementPlanningBoardPageLoad = boardPageLoad(INCREMENT_PLANNING_BOARD);

export const issueViewOpenInteraction = metrics.interaction({
	key: 'board.issue-view-open',
	histogram: {
		result: '1000_2000_3000_4000_5000_6000',
		response: '50_100_150_200_250_300',
	},
	timings: [{ key: 'feedbackPreMount', endMark: 'feedbackPreMountEnd' }],
});

export const issueCreateInteraction = metrics.concurrent.interaction({
	key: 'board.issue-create',
	histogram: {
		result: '1000_2000_3000_4000_5000_6000',
		response: '50_100_150_200_300_500',
	},
});

export const issueDeleteInteraction = metrics.concurrent.interaction({
	key: 'board.issue-delete',
});

export const issueRankAndTransitionInteraction = metrics.concurrent.interaction({
	key: 'board.issue-rank-and-transition',
	histogram: {
		result: '500_1000_1100_1200_1300_1400_1500_2000',
		response: '50_100_150_200_300_500',
	},
});

export const flagToggleInteraction = metrics.concurrent.interaction({
	key: 'board.flag-toggle',
	histogram: {
		result: '1000_2000_3000_4000_5000_6000',
		response: '50_100_150_200_300_500',
	},
});

export const assignToMeInteraction = metrics.concurrent.interaction({
	key: 'board.assign-to-me',
	histogram: {
		result: '500_1000_2000_3000_4000',
		response: '200_300_400_500_1000_2000',
	},
});

export const cardsClearInteraction = metrics.concurrent.interaction({
	key: 'board.cards-clear',
	// @ts-expect-error - TS2741 - Property '[BMInteractionMetrics.result]' is missing in type '{ response: string; }' but required in type 'InteractionHistogramConfig'.
	histogram: {
		response: '50_100_150_200_300_400_500',
	},
});

export const cardsUnclearInteraction = metrics.concurrent.interaction({
	key: 'board.cards-unclear',
	// @ts-expect-error - TS2741 - Property '[BMInteractionMetrics.result]' is missing in type '{ response: string; }' but required in type 'InteractionHistogramConfig'.
	histogram: {
		response: '50_100_150_200_300_400_500',
	},
});

export const filterInteraction = metrics.interaction({
	key: 'board.filter',
});

export const editSprintModalLoad = metrics.interaction({
	key: 'edit-sprint-modal-load',
});

export const customFilterInteraction = metrics.interaction({
	key: 'board.custom-filter',
	// @ts-expect-error - TS2741 - Property '[BMInteractionMetrics.response]' is missing in type '{ result: string; }' but required in type 'InteractionHistogramConfig'.
	histogram: {
		result: '500_1000_1500_2000_2500_3000_3500_4000',
	},
});

export const devStatusLoadInteraction = metrics.concurrent.interaction({
	key: 'board.dev-status-load',
	// @ts-expect-error - TS2741 - Property '[BMInteractionMetrics.response]' is missing in type '{ result: string; }' but required in type 'InteractionHistogramConfig'.
	histogram: {
		result: '500_1000_2000_3000_4000_5000_6000_7000_8000',
	},
});
