import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	shareTitle: {
		id: 'software.main.header.share-button.share-title',
		defaultMessage: 'Board',
		description:
			'Name of the entity type that is going to be shared to others, this will be used as email title for the share recipients',
	},
	shareFormTitle: {
		id: 'software.main.header.share-button.share-form-title',
		defaultMessage: 'Share',
		description: 'Title of a form to share the board',
	},
	shareTooltipText: {
		id: 'software.main.header.share-button.share-tooltip-text',
		defaultMessage: 'Embed this link in other Atlassian products such as Confluence',
		description: 'Message shown in copy link tooltip describing how the share link can be used',
	},
	shareHelperMessage: {
		id: 'software.main.header.share-button.share-helper-message',
		defaultMessage: 'This view, along with any applied groups and filters are shared',
		description:
			'Message indicating the recipients of the email share will be able to see the board and backlog',
	},
});
