import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import { Observable } from 'rxjs/Observable';
import { getMark } from '@atlassian/jira-common-performance/src/marks.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { UFOExperience, ExperienceTypes, ExperiencePerformanceTypes } from '@atlassian/ufo';
import { fetchCMPBoardScopeData } from '../../services/board-scope-graphql/non-critical/mobile-rest/index.tsx';
import { makeServiceContext } from '../../services/service-context.tsx';
import {
	BOARD_DATA_SHADOW_REQUEST,
	type BoardDataShadowRequestAction,
} from '../../state/actions/board/shadow-request/index.tsx';
import { getActiveCustomFilterIds } from '../../state/selectors/filter/custom-filter-selectors.tsx';
import type { Action, ActionsObservable, MiddlewareAPI } from '../../state/types.tsx';

const fetchBoardDataShadowRequestExperience = new UFOExperience('fetch-board-data-shadow-request', {
	type: ExperienceTypes.Operation,
	performanceType: ExperiencePerformanceTypes.Custom,
});

export function boardDataShadowRequestEpic(
	action$: ActionsObservable,
	store: MiddlewareAPI,
): Observable<Action> {
	return action$
		.ofType(BOARD_DATA_SHADOW_REQUEST)
		.mergeMap((action: BoardDataShadowRequestAction) => {
			const state = store.getState();
			const { boardId, isCMPBoard, projectType } = makeServiceContext(state);

			if (!isCMPBoard) return Observable.empty<Action>();

			const customFilterIds = getActiveCustomFilterIds(state);
			fetchBoardDataShadowRequestExperience.start();

			const issueLimit =
				expVal('jsw_cmp_board_pagination_spike_issue_limit', 'issueLimit', 0) || undefined;
			return fetchCMPBoardScopeData(boardId, {
				projectType,
				includeHidden: true,
				skipExtraFields: true,
				activeQuickFilters: customFilterIds,
				issueLimit,
			})
				.flatMap((data) => {
					fetchBoardDataShadowRequestExperience.success({
						metadata: {
							originalRequestIssueCount: action.payload.issueCount,
							originalRequestDuration: getMark('uif-board-fetch/request')?.duration ?? 0,
							shadowRequestIssueCount: data.orderedIssueIds?.length ?? 0,
						},
					});

					return Observable.empty<Action>();
				})
				.catch(() => {
					fetchBoardDataShadowRequestExperience.failure();
					return Observable.empty<Action>();
				});
		});
}
