import type { Observable as ObservableType } from 'rxjs/Observable';
import type { canInviteOthersResponse } from '../../model/can-user-invite/can-user-invite-types.tsx';
import { getUserInviteCapability } from '../../rest/user-invite/index.tsx';

/**
 * Checks whether current user can invite others to a TMP project based on user role and project access settings
 * This endpoint does NOT work with CMP project
 */
export const fetchCanUserInviteService = (
	projectId: number,
): ObservableType<canInviteOthersResponse> => getUserInviteCapability(projectId);
