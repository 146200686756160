import { createContext } from 'react';

export type IpBoardContextType = {
	planId: string | undefined;
	scenarioId: string | undefined;
};
const Ipcontext = createContext<IpBoardContextType>({
	planId: undefined,
	scenarioId: undefined,
});

export default Ipcontext;
