import { defineMessages } from '@atlassian/jira-intl';

export const DUE_DATE_TOOLTIP_PREFIX = 'Due Date: ' as const;

export const messages = defineMessages({
	text: {
		id: 'due-date.text',
		defaultMessage: 'Due Date: {date}',
		description: 'Accessible text for due date',
	},
});
