import { createSelector } from 'reselect';
import memoize from 'lodash/memoize';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import type { ColumnId, ColumnIdKey } from '../../../model/column/column-types.tsx';
import { NO_SWIMLANE } from '../../../model/swimlane/swimlane-modes.tsx';
import type { IssueIdsBySwimlanes, Swimlane } from '../../../model/swimlane/swimlane-types.tsx';
import { getSwimlaneId } from '../../../services/issue/issue-data-transformer.tsx';
import { getCardActive } from '../card/card-selectors.tsx';
import { getOrderedColumnIds } from '../software/software-selectors.tsx';
import { getSwimlaneMode } from '../swimlane/swimlane-mode-selectors.tsx';
import { getSwimlanes, makeIsSwimlaneCollapsed } from '../swimlane/swimlane-selectors.tsx';
import {
	platformIssueIdsBySwimlaneSelector,
	getFilteredIssueIdsByColumn,
	isIssueVisible,
	type IssueIdsByColumnMap,
} from '../work/work-selectors.tsx';
import { boardIssuesSelector } from './board-issue-selectors.tsx';
import { makeGetColumnIdForIssue } from './issue-selectors.tsx';

const getIsSwimlaneModeSet = createSelector(
	getSwimlaneMode,
	(swimlaneMode) => swimlaneMode !== NO_SWIMLANE.id,
);

export const getSelectableIssuesInFirstPopulatedColumn = createSelector(
	[
		getIsSwimlaneModeSet,
		platformIssueIdsBySwimlaneSelector,
		getFilteredIssueIdsByColumn,
		getOrderedColumnIds,
		getSwimlanes,
		makeIsSwimlaneCollapsed,
	],
	(
		isSwimlaneModeSet,
		issuesBySwimlane: IssueIdsBySwimlanes,
		issuesByColumn: IssueIdsByColumnMap,
		orderedColumnIds: ColumnId[],
		swimlanes: Swimlane[],
		isSwimlaneCollapsed,
	) => {
		// Ids of all columns that contain issues
		const columndIds: ColumnIdKey[] = orderedColumnIds
			.filter((columnId) => issuesByColumn[String(columnId)].length)
			.map(String);

		if (!columndIds.length) {
			return [];
		}

		if (isSwimlaneModeSet) {
			// Sort swimlanes in issuesBySwimlane into correct order
			const orderedSwimlanes = swimlanes
				.filter(({ id }) => !isSwimlaneCollapsed(id))
				.map(({ id }) => issuesBySwimlane[id])
				.filter(Boolean);

			// Find the first column that contains issues in one of the swimlanes
			const populatedColumnId = columndIds.find((id) =>
				orderedSwimlanes.some((swimlane) => !!swimlane[id]),
			);

			// Return issue ids for all issues in the given column, respecting swimlanes
			return populatedColumnId !== undefined
				? orderedSwimlanes
						.map((swimlane) => swimlane[populatedColumnId])
						.filter(Boolean)
						.flat()
				: [];
		}

		// No swimlane mode is selected, return issues in the first ordered column
		return issuesByColumn[String(columndIds[0])];
	},
);

// Get the first issue in the first populated column
export const getFirstSelectableIssueAscending = createSelector(
	getSelectableIssuesInFirstPopulatedColumn,
	(issues) => issues[0],
);

// Get the last issue in the first populated column
export const getFirstSelectableIssueDescending = createSelector(
	getSelectableIssuesInFirstPopulatedColumn,
	(issues) => issues[issues.length - 1],
);

export const makeGetSelectableIssuesInColumn = createSelector(
	[
		getIsSwimlaneModeSet,
		platformIssueIdsBySwimlaneSelector,
		getFilteredIssueIdsByColumn,
		getSwimlanes,
		makeIsSwimlaneCollapsed,
	],
	(
		isSwimlaneModeSet,
		issuesBySwimlane: IssueIdsBySwimlanes,
		issuesByColumn: IssueIdsByColumnMap,
		swimlanes: Swimlane[],
		isSwimlaneCollapsed,
	) => {
		const selectableIssuesInColumn = (columnId: ColumnId) => {
			if (isSwimlaneModeSet) {
				return swimlanes
					.filter(({ id }) => !isSwimlaneCollapsed(id))
					.map(({ id }) => issuesBySwimlane[id])
					.map((swimlane) => swimlane && swimlane[String(columnId)])
					.filter(Boolean)
					.flat();
			}

			// No swimlane mode is selected
			return issuesByColumn[String(columnId)] || [];
		};
		return memoize(selectableIssuesInColumn);
	},
);

export const getNextSelectableIssue = createSelector(
	[makeGetSelectableIssuesInColumn, makeGetColumnIdForIssue],
	(getSelectableIssuesInColumn, getColumnIdForIssue) =>
		memoize((issueId) => {
			const columnId = getColumnIdForIssue(issueId);
			const column = getSelectableIssuesInColumn(columnId);
			const issueIndex = column.indexOf(issueId);
			return column[issueIndex + 1];
		}),
);

export const getPrevSelectableIssue = createSelector(
	[makeGetSelectableIssuesInColumn, makeGetColumnIdForIssue],
	(getSelectableIssuesInColumn, getColumnIdForIssue) =>
		memoize((issueId) => {
			const columnId = getColumnIdForIssue(issueId);
			const column = getSelectableIssuesInColumn(columnId);
			const issueIndex = column.indexOf(issueId);
			return column[issueIndex - 1];
		}),
);

// Returns the columns for the first populated swimlane,
// or all populated columns if no swimlane mode is set
export const getColumnsWithSelectableIssues = createSelector(
	[
		getIsSwimlaneModeSet,
		platformIssueIdsBySwimlaneSelector,
		getFilteredIssueIdsByColumn,
		getSwimlanes,
		getOrderedColumnIds,
		makeIsSwimlaneCollapsed,
	],
	(
		isSwimlaneModeSet,
		issuesBySwimlane: IssueIdsBySwimlanes,
		issuesByColumn: IssueIdsByColumnMap,
		swimlanes: Swimlane[],
		orderedColumnIds: ColumnId[],
		isSwimlaneCollapsed,
	) => {
		if (isSwimlaneModeSet) {
			const firstSwimlane = swimlanes
				.filter(({ id }) => !isSwimlaneCollapsed(id))
				.map(({ id }) => issuesBySwimlane[id])
				.filter(Boolean)
				.shift();

			// All swimlanes are collapsed or there is no populated swimlane
			// based on the current filters
			if (!firstSwimlane) {
				return undefined;
			}

			return orderedColumnIds
				.filter((id) => firstSwimlane[String(id)])
				.map((id) => firstSwimlane[String(id)]);
		}

		// No swimlane mode is selected
		return orderedColumnIds
			.filter((id) => issuesByColumn[String(id)].length)
			.map((id) => issuesByColumn[String(id)]);
	},
);

export const getFirstSelectableIssueInFirstColumn = createSelector(
	getColumnsWithSelectableIssues,
	(columns) => {
		if (!columns || !columns.length) {
			return undefined;
		}
		return columns[0][0];
	},
);

export const getFirstSelectableIssueInLastColumn = createSelector(
	getColumnsWithSelectableIssues,
	(columns) => {
		if (!columns || !columns.length) {
			return undefined;
		}
		return columns[columns.length - 1][0];
	},
);

const makeGetSwimlaneForIssue = createSelector(
	[makeGetColumnIdForIssue, getSwimlanes, platformIssueIdsBySwimlaneSelector],
	(getColumnIdForIssue, swimlanes, issuesBySwimlane) =>
		memoize((issueId) => {
			const issueColumnId = getColumnIdForIssue(issueId);
			const swimlane = swimlanes.find(
				({ id }) =>
					issuesBySwimlane[id] &&
					issuesBySwimlane[id][String(issueColumnId)] &&
					issuesBySwimlane[id][String(issueColumnId)].indexOf(issueId) > -1,
			);
			return swimlane && issuesBySwimlane[swimlane.id];
		}),
);

// Returns the column the given issue is in, and the first populated
// columns immediately before and after this column
export const makeGetNeighbouringColumnsForIssue = createSelector(
	[
		getIsSwimlaneModeSet,
		makeGetColumnIdForIssue,
		getOrderedColumnIds,
		getFilteredIssueIdsByColumn,
		makeGetSwimlaneForIssue,
	],
	(isSwimlaneModeSet, getColumnIdForIssue, orderedColumnIds, issuesByColumn, getSwimlaneForIssue) =>
		memoize((issueId) => {
			const issueColumnId = getColumnIdForIssue(issueId);
			// If we're using a swimlane mode, get all issues by column for this swimlane,
			// Otherwise use all issues by column
			const issues = isSwimlaneModeSet ? getSwimlaneForIssue(issueId) : issuesByColumn;

			if (!issues) {
				return undefined;
			}

			// Get rid of columns that are empty
			const orderedPopulatedColumnIds = orderedColumnIds.filter(
				(id) => issues[String(id)] && issues[String(id)].length,
			);
			const orderedPopulatedColumns = orderedPopulatedColumnIds.map((id) => issues[String(id)]);

			// Find the column the issue resides in, and return the columns before and after it
			const issueColumnIndex = orderedPopulatedColumnIds.indexOf(issueColumnId);
			const currColumn = orderedPopulatedColumns[issueColumnIndex];
			const nextColumn = orderedPopulatedColumns[issueColumnIndex + 1];
			const prevColumn = orderedPopulatedColumns[issueColumnIndex - 1];

			return {
				current: currColumn,
				next: nextColumn,
				prev: prevColumn,
			};
		}),
);

// Based on the rank of the issue in the current column,
// return an issue in the new column
const getIssueInNewColumn = (
	currentIssue: IssueId,
	currentColumn: IssueId[],
	newColumn: IssueId[],
): IssueId => {
	const issueColumnRank = currentColumn.indexOf(currentIssue);
	// Return an issue in the new column that is in the same rank as the current issue.
	// If the new column is shorter, then we return the last issue in that column.
	return newColumn[issueColumnRank] || newColumn[newColumn.length - 1];
};

export const makeGetNeighbouringIssueInNextColumn = createSelector(
	[makeGetNeighbouringColumnsForIssue],
	(getNeighbouringColumnsForIssue) =>
		memoize((issueId) => {
			const neighbouringColumns = getNeighbouringColumnsForIssue(issueId);
			if (!neighbouringColumns || !neighbouringColumns.next) {
				return undefined;
			}
			return getIssueInNewColumn(issueId, neighbouringColumns.current, neighbouringColumns.next);
		}),
);

export const makeGetNeighbouringIssueInPrevColumn = createSelector(
	[makeGetNeighbouringColumnsForIssue],
	(getNeighbouringColumnsForIssue) =>
		memoize((issueId) => {
			const neighbouringColumns = getNeighbouringColumnsForIssue(issueId);
			if (!neighbouringColumns || !neighbouringColumns.prev) {
				return undefined;
			}
			return getIssueInNewColumn(issueId, neighbouringColumns.current, neighbouringColumns.prev);
		}),
);

export const isActiveCardVisible = createSelector(
	[
		getSwimlaneMode,
		getSwimlanes,
		boardIssuesSelector,
		getCardActive,
		isIssueVisible,
		makeIsSwimlaneCollapsed,
	],
	(swimlaneMode, swimlanes, boardIssues, activeCardId, getIsIssueVisible, isSwimlaneCollapsed) => {
		if (activeCardId === null || activeCardId === undefined) {
			return false;
		}

		const isVisible = getIsIssueVisible(activeCardId);
		const issue = boardIssues[String(activeCardId)];
		const issueSwimlaneId = getSwimlaneId(swimlaneMode, swimlanes, issue);
		const isCollapsed = issueSwimlaneId !== null ? isSwimlaneCollapsed(issueSwimlaneId) : false;

		return isVisible && !isCollapsed;
	},
);
