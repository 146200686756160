import type { TimeTrackingOptions } from '@atlassian/jira-time-tracking-formatter/src/types.tsx';

export const timeTrackingConfigTransformer = ({
	workingHoursPerDay,
	workingDaysPerWeek,
	defaultUnit,
	timeFormat,
}: TimeTrackingOptions) => ({
	isTimeTrackingEnabled: true,
	daysPerWeek: workingDaysPerWeek,
	hoursPerDay: workingHoursPerDay,
	defaultUnit,
	format: timeFormat,
});
