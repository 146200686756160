import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { BoardFeatureResponse, BoardFeature } from '../../common/types.tsx';
import { getBoardFeaturesUrl } from '../utils.tsx';
import type { UpdateBoardFeatureRequest } from './types.tsx';

export const updateBoardFeature = async (
	request: UpdateBoardFeatureRequest,
): Promise<BoardFeature['state']> => {
	// it returns the board features array, but we don't use it for optimistic update
	// feature state is used instead to indicate if the updates successful or not
	const data = await performPutRequest<BoardFeatureResponse>(getBoardFeaturesUrl(request.boardId), {
		body: JSON.stringify(request),
	});
	const newState = data.features.find((f) => f.feature === request.feature)?.state;

	return newState ?? (request.enabling ? 'ENABLED' : 'DISABLED');
};
