export const AssigneeKey = 'assignee' as const;
export const ReporterKey = 'reporter' as const;
export const DescriptionKey = 'description' as const;
export const FixVersionsKey = 'fixVersions' as const;
export const LabelsKey = 'labels' as const;
export const StatusKey = 'status' as const;
export const SummaryKey = 'summary' as const;
export const TimeEstimateKey = 'timeoriginalestimate' as const;
export const IssueLinksKey = 'issuelinks' as const;
export const IssuesInEpicKey = 'epic-issues' as const;
export const ChildIssuesKey = 'children-issues' as const;
export const ComponentsKey = 'components' as const;
export const FlagKey = 'flag' as const;
