import type { PreloadedState, Store } from 'redux';
import clone from 'lodash/clone';

/**
 * A function to create the store and all its middleware, based on the
 * preloaded initial state and a set of options (open type)
 *
 * This is `createStore` or `initialize` on the apps.
 */
type StoreFactory<State, Options> = (
	state: PreloadedState<State>,
	options: Options,
) => Store<State>;

/**
 * A function to merge state that had been cached in memory with preloaded
 * state.
 */
type MergeCachedStateFn<State> = (
	cachedState: State,
	preloadedState: PreloadedState<State>,
) => PreloadedState<State>;

interface CachedStoreManagerOptions<State, Options> {
	/**
	 * A function to create the store and all its middleware, based on the
	 * preloaded initial state and a set of options (open type)
	 */
	storeFactory: StoreFactory<State, Options>;
	/**
	 * A function to merge state cached in-memory with the preloaded state.
	 */
	mergeCachedState: MergeCachedStateFn<State>;
}

/**
 * This module provides a facility to keep the last previously seen redux store
 * state in memory.
 *
 * When we transition to the app, we check if it's the same board ID as the last
 * If it is, we re-use the previous redux state.
 *
 * @example
 *
 *      const mergeCachedState = (
 *          cachedState: State,
 *          preloadedState: PreloadedState<State>
 *      ): PreloadedState<State> => {
 *          return {
 *              ...preloadedState,
 *              ui: preloadedState.ui,
 *              entities: merge(cachedState.entities, preloadedState.entities),
 *              // etc. this is to pick and choose what parts of the previous
 *              // store state we will discard on transition
 *          };
 *      };
 *      const manager = new CachedStoreManager({
 *         storeFactory: createStore,
 *         mergeCachedState,
 *      })
 */
export class CachedStoreManager<State, Options> {
	constructor({ storeFactory, mergeCachedState }: CachedStoreManagerOptions<State, Options>) {
		this.storeFactory = storeFactory;
		this.mergeCachedState = mergeCachedState;
	}

	private storeFactory: StoreFactory<State, Options>;

	private mergeCachedState: MergeCachedStateFn<State>;

	private cache: null | {
		boardId: string;
		state: State;
		unsubscribe: () => void;
	} = null;

	/**
	 * Return true if the redux state was in cache. In this case, we won't consider
	 * the resources' data.
	 */
	hasCachedState(boardId: string): boolean {
		return Boolean(this.cache && this.cache.boardId === boardId);
	}

	private getCachedState(boardId: string): State | null {
		if (this.cache && this.cache.boardId === boardId) {
			return this.cache.state;
		}
		return null;
	}

	/**
	 * Create a new store, merging a preloadedState with any cached state that was
	 * previously held in memory.
	 */
	createStore(
		boardId: string,
		preloadedStateParam: PreloadedState<State>,
		options: Options & { disableStoreCaches: boolean },
	): Store<State> {
		// Cast to get rid of Omit<...>. Both types are partial so this is valid
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		let preloadedState = clone(preloadedStateParam) as PreloadedState<State>;
		const cachedState = this.getCachedState(boardId);

		if (!options.disableStoreCaches && cachedState) {
			preloadedState = this.mergeCachedState(cachedState, preloadedState);
		}

		const store = this.storeFactory(preloadedState, options);
		if (options.disableStoreCaches) {
			return store;
		}

		// On every change copy a reference to the redux state to this
		// `cachedStoreState` object.
		this.unmount();
		this.cache = {
			boardId,
			state: store.getState(),
			// Replace with lodash/noop
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			unsubscribe: () => {},
		};
		this.cache.unsubscribe = store.subscribe(() => {
			if (this.cache) {
				this.cache.state = store.getState();
			}
		});

		return store;
	}

	/**
	 * Clean-up redux store subscriptions.
	 */
	unmount() {
		this.cache?.unsubscribe();
	}

	/**
	 * FOR TESTING ONLY
	 */
	getCacheForTesting(): null | {
		boardId: string;
		state: State;
		unsubscribe: () => void;
	} {
		return this.cache;
	}
}
