import isNil from 'lodash/isNil';
import { Observable } from 'rxjs/Observable';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import type { UpdateValues, Operation } from '../../model/issue/issue-increment-planning-types.tsx';
import type { IssueKey } from '../../model/issue/issue-types.tsx';
import { issueUpdateRest, issueUpdateJPORest } from '../../rest/issue/issue-update-rest.tsx';
import type { ServiceContext } from '../service-context.tsx';
import type { getIssueCreateOrUpdateFields } from './issue-data-transformer.tsx';

interface IssueUpdateServiceParams {
	issueKey: IssueKey;
	values: ReturnType<typeof getIssueCreateOrUpdateFields>;
}

/**
 * Update an issue's fields through the issues REST api.
 */
export function issueUpdateService(
	_ctx: ServiceContext,
	{ issueKey, values }: IssueUpdateServiceParams,
) {
	return issueUpdateRest(issueKey, values);
}

export function issueUpdateJPOService({
	issueIds,
	planId,
	scenarioId,
	values,
}: {
	issueIds: string[];
	planId: string;
	scenarioId: string;
	values: UpdateValues;
}) {
	return issueUpdateJPORest({ issueIds, planId, scenarioId, values });
}

export function issueRankJPOService({
	issueIds,
	rankBeforeIssueId,
	rankAfterIssueId,
	isLastPosition,
	requestHandler,
}: {
	issueIds: IssueId[];
	rankBeforeIssueId?: IssueId | null;
	rankAfterIssueId?: IssueId | null;
	isLastPosition?: boolean;
	requestHandler: (operation: Operation) => Promise<unknown>;
}) {
	let relation = 'BEFORE';
	let anchor = rankBeforeIssueId;
	if (isNil(rankBeforeIssueId) && !isNil(rankAfterIssueId)) {
		relation = 'AFTER';
		anchor = rankAfterIssueId;
	}
	let operation: Operation = {
		anchor: `${anchor}`,
		itemKeys: issueIds.filter((id) => !isNil(id)).map((id) => `${id}`),
		relation,
	};
	if (isLastPosition) {
		operation = {
			itemKeys: issueIds.filter((id) => !isNil(id)).map((id) => `${id}`),
			relation: 'LAST',
		};
	}

	return Observable.from(requestHandler(operation));
}
