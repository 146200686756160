import React, { useEffect, useRef, type MouseEvent } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import SvgOverlay from '@atlassian/jira-aais-dependency-lines-overlay/src/common/ui/index.tsx';
import type {
	DependencyClickParams,
	SelectedDependency,
	OnDependencyClick,
} from '@atlassian/jira-aais-dependency-lines-overlay/src/common/ui/types.tsx';
import DependencyModal from '@atlassian/jira-portfolio-3-dependency-line-detail/src/common/ui/modal/index.tsx';
import {
	useDependencyLinks,
	useIPDependencyLines,
	type DependencyLinesProps,
	type IssueData,
} from '@atlassian/jira-portfolio-3-plan-increment-common/src/hooks/use-dependency-links/index.tsx';
import { useResizeObserver } from '@atlassian/jira-react-use-resize-observer/src/index.tsx';
import type { IssueLink } from '@atlassian/jira-software-board-common/src/index.tsx';
import { useScrollState } from '@atlassian/jira-software-fast-virtual/src/services/use-scroll-state/index.tsx';
import type { DependencyModalIssueLink } from './types.tsx';

export const DependencyLines = ({
	issuesWithLinksById,
	issueLinkTypes,
	onUnlink,
	canDelete,
	showOffTrackDependencies,
	issueIdsToShowDependencies,
	getOrderedIssueIdsForColumn,
}: DependencyLinesProps) => {
	const [dependencyLinesData] = useIPDependencyLines({
		issuesWithLinksById,
		showOffTrackDependencies,
		issueIdsToShowDependencies,
		getOrderedIssueIdsForColumn,
	});

	const [{ activeLine }, { updateDependencyLinesContainerPosition, setActiveLine }] =
		useDependencyLinks();

	const { scrollLeft } = useScrollState();
	// Hold a ref to the scrollLeft value because we're interested in the value being up to date, but
	// not interested in re-firing the useEffect every time the user scrolls horizontally on the board
	const scrollLeftRef = useRef(scrollLeft);

	useEffect(() => {
		scrollLeftRef.current = scrollLeft;
	}, [scrollLeft]);

	// Hold the ref to the container of the dependency lines so we can account for panels pushing in from the left
	// E.g unscheduled work panel, nav sidebar being present/collapsed.
	const dependencyLinesContainerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (dependencyLinesContainerRef.current) {
			const { x } = dependencyLinesContainerRef.current.getBoundingClientRect();
			updateDependencyLinesContainerPosition({
				x: x + scrollLeftRef.current,
			});
		}
	}, [updateDependencyLinesContainerPosition, dependencyLinesContainerRef]);

	useResizeObserver({
		ref: dependencyLinesContainerRef,
		onResize: () => {
			if (dependencyLinesContainerRef.current) {
				const { x } = dependencyLinesContainerRef.current.getBoundingClientRect();

				updateDependencyLinesContainerPosition({
					x: x + scrollLeftRef.current,
				});
			}
		},
	});

	const onDependencyClick: OnDependencyClick = (
		event: MouseEvent<SVGPathElement>,
		params: DependencyClickParams,
	) => {
		const clickedLine = {
			fromId: params.fromId,
			toId: params.toId,
			x: event.clientX,
			y: event.clientY,
		};

		setActiveLine(clickedLine);
	};

	const formatLinksForModal = (links: IssueLink[]): DependencyModalIssueLink[] => {
		return links
			.filter(
				(link) => link.sourceId === activeLine?.fromId && link.destinationId === activeLine?.toId,
			)
			.map((link) => {
				const { id: itemKey, sourceId, destinationId, isOfftrack, linkTypeId } = link;
				const getIssueMeta = (issue: IssueData) => {
					const { id, typeName, typeUrl: iconUrl, key: projectKey, summary: title } = issue;
					return { id, typeName, iconUrl, projectKey, title };
				};
				const issueLinkType = issueLinkTypes.find((type) => type.id === linkTypeId);
				let outwardDescription = '';
				if (issueLinkType) {
					const { isOutward, inward, outward } = issueLinkType;
					outwardDescription = isOutward ? outward : inward;
				}
				return {
					fromIssue: getIssueMeta(issuesWithLinksById[sourceId]),
					toIssue: getIssueMeta(issuesWithLinksById[destinationId]),
					issueLinkType: {
						id: linkTypeId,
						outward: outwardDescription,
					},
					itemKey,
					isOfftrack,
				};
			});
	};

	const currentLineLinks = activeLine
		? formatLinksForModal(issuesWithLinksById[activeLine.fromId]?.issueLinks || [])
		: [];

	const selectedDependency: SelectedDependency | undefined = activeLine
		? { fromId: activeLine.fromId, toId: activeLine.toId }
		: undefined;

	return (
		<>
			<SvgOverlay
				dependencies={dependencyLinesData}
				selectedDependency={selectedDependency}
				onDependencyClick={onDependencyClick}
			/>
			{activeLine && currentLineLinks.length && (
				<DependencyModal
					position={{
						left: activeLine.x,
						top: activeLine.y,
					}}
					links={currentLineLinks}
					canDelete={canDelete}
					onUnlink={onUnlink}
					onClose={() => setActiveLine(null)}
					simpleOverlap
				/>
			)}
			<Box ref={dependencyLinesContainerRef} xcss={measureDependencyLinesContainerStyles} />
		</>
	);
};

const measureDependencyLinesContainerStyles = xcss({
	height: '100%',
	width: '100%',
	position: 'absolute',
	top: '0',
	left: '0',
	pointerEvents: 'none',
});
