import React, { useCallback, useState } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button/custom-theme-button';
import type { Appearance } from '@atlaskit/button/types';
import InlineDialog from '@atlaskit/inline-dialog'; // ignore-for-ENGHEALTH-17759
import { Box, xcss } from '@atlaskit/primitives';
import { getProductEdition } from '@atlassian/jira-common-editions/src/get-product-edition/index.tsx';
import getExplicitlyLicensedProducts from '@atlassian/jira-common-get-explicitly-licensed-products/src/index.tsx';
import { withFlagService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	fireUIAnalytics,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import { SOFTWARE } from '@atlassian/jira-shared-types/src/application-key.tsx';
import { STANDARD_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import {
	LEARN_MORE,
	mapActionIdFromType,
	UPGRADE,
	type UpflowFlow,
} from '@atlassian/jira-up-flow-common/src/types.tsx';
import { UpFlowIFrame } from '@atlassian/jira-up-flow-iframe/src/async.tsx';
import type { UpFlowApplicationEdition } from '@atlassian/jira-up-flow-iframe/src/common/types.tsx';
import { applicationEditionToUpFlowEdition } from '@atlassian/jira-up-flow-iframe/src/common/utils.tsx';
import {
	LEARN_MORE_LINK,
	UPGRADE_LINK,
} from '@atlassian/jira-up-flow-touchpoint/src/ui/dialog-content/button/constants.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const fireCTAButtonAnalytics = (
	analyticsEvent: UIAnalyticsEvent,
	actionSubjectId: string,
	touchpointId: string,
	currentEdition: UpFlowApplicationEdition,
) => {
	fireUIAnalytics(analyticsEvent, actionSubjectId, {
		currentEdition,
		touchpointId,
		targetEdition: applicationEditionToUpFlowEdition(STANDARD_EDITION),
		isGrandfathered: false,
		isSiteAdmin: true,
		upFlowVariateFlag: 'experiment',
	});
};

const showTryNowButton = (onClick: () => void, label: string, href?: string, target?: string) => (
	<Button appearance="primary" onClick={onClick} href={href} target={target} rel="noopener">
		{label}
	</Button>
);

const showLearnMoreButton = (
	appearance: Appearance,
	onClick: () => void,
	label: string,
	href?: string,
	target?: string,
) => (
	<Button appearance={appearance} onClick={onClick} href={href} target={target} rel="noopener">
		{label}
	</Button>
);

// TODO split this file in to proper tangerine app structure, it's getting too big
const UpgradeToAddUsersDialog = (props: Props) => {
	const { children, isOpen, onClose, intl, touchpointId, onUpFlowDismiss } = props;
	const tenantContext = useTenantContext();
	const explicitlyLicensedProducts = getExplicitlyLicensedProducts(tenantContext);
	const hasOnlyJSW =
		explicitlyLicensedProducts.length === 1 && explicitlyLicensedProducts[0] === SOFTWARE;
	const [upFlowIframeState, setUpFlowIframeState] = useState<UpflowFlow | null>(null);
	const currentEdition = applicationEditionToUpFlowEdition(
		getProductEdition(explicitlyLicensedProducts[0], tenantContext),
	);
	const upgradeButtonOnClick = useCallback(
		// @ts-expect-error - TS7006 - Parameter '_' implicitly has an 'any' type.
		(_, analyticsEvent: UIAnalyticsEvent) => {
			const actionSubjectId = mapActionIdFromType[UPGRADE];
			fireCTAButtonAnalytics(analyticsEvent, actionSubjectId, touchpointId, currentEdition);
			hasOnlyJSW && setUpFlowIframeState(UPGRADE);
		},
		[currentEdition, hasOnlyJSW, touchpointId],
	);
	const learnMoreButtonOnClick = useCallback(
		// @ts-expect-error - TS7006 - Parameter '_' implicitly has an 'any' type.
		(_, analyticsEvent: UIAnalyticsEvent) => {
			const actionSubjectId = mapActionIdFromType[LEARN_MORE];
			fireCTAButtonAnalytics(analyticsEvent, actionSubjectId, touchpointId, currentEdition);
			hasOnlyJSW && setUpFlowIframeState(LEARN_MORE);
		},
		[currentEdition, hasOnlyJSW, touchpointId],
	);

	const onUpFlowIframeClose = useCallback(() => {
		setUpFlowIframeState(null);
		onUpFlowDismiss();
	}, [onUpFlowDismiss]);

	const learnMoreButtonAppearance = tenantContext.isSiteAdmin ? 'link' : 'primary';

	return (
		<ContextualAnalyticsData sourceName={touchpointId} sourceType={SCREEN}>
			<InlineDialog
				content={
					<>
						<ContextualAnalyticsData sourceName="changeEditionGateway" sourceType={SCREEN}>
							<FireScreenAnalytics
								attributes={{
									isSiteAdmin: tenantContext.isSiteAdmin,
									source: touchpointId,
								}}
							/>
						</ContextualAnalyticsData>
						<b>{intl.formatMessage(messages.upgradeToAddUsersHeader)}</b>
						<MultiLineParagraph>
							{intl.formatMessage(messages.upgradeToAddUsersContent)}
						</MultiLineParagraph>
						<Box xcss={buttonBarStyles}>
							{tenantContext.isSiteAdmin
								? showTryNowButton(
										// @ts-expect-error - TS2345 - Argument of type '(_: any, analyticsEvent: UIAnalyticsEventInterface) => void' is not assignable to parameter of type '() => void'.
										upgradeButtonOnClick,
										intl.formatMessage(messages.upgradeButtonLabel),
										hasOnlyJSW ? undefined : UPGRADE_LINK,
										hasOnlyJSW ? undefined : '_blank',
									)
								: null}
							{showLearnMoreButton(
								learnMoreButtonAppearance,
								// @ts-expect-error - TS2345 - Argument of type '(_: any, analyticsEvent: UIAnalyticsEventInterface) => void' is not assignable to parameter of type '() => void'.
								learnMoreButtonOnClick,
								intl.formatMessage(messages.learnMoreButtonLabel),
								hasOnlyJSW ? undefined : LEARN_MORE_LINK,
								hasOnlyJSW ? undefined : '_blank',
							)}
						</Box>
						{upFlowIframeState && (
							<UpFlowIFrame
								targetEdition={STANDARD_EDITION}
								product={explicitlyLicensedProducts[0]}
								flow={upFlowIframeState}
								touchpointId={touchpointId}
								experienceTrackingEnabled
								onClose={onUpFlowIframeClose}
							/>
						)}
					</>
				}
				isOpen={isOpen}
				onClose={onClose}
				placement="bottom-start"
			>
				{children}
			</InlineDialog>
		</ContextualAnalyticsData>
	);
};

export default injectIntl(withFlagService(UpgradeToAddUsersDialog));

const buttonBarStyles = xcss({
	marginTop: 'space.100',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MultiLineParagraph = styled.p({
	whiteSpace: 'pre-line',
});
