import type { VersionId } from '@atlassian/jira-shared-types/src/general.tsx';
import { UNASSIGNED_VERSION_ID } from '../../common/constants.tsx';
import type { FilterableIssue, MatcherCreator } from '../types.tsx';

export const versionMatcherCreator: MatcherCreator<VersionId[], FilterableIssue> = (
	versionFilter,
) => {
	const versionSet = new Set(versionFilter);

	return ({ versionIds }) =>
		versionIds !== undefined &&
		(versionIds.some((versionId) => versionSet.has(versionId)) ||
			(versionIds.length === 0 && versionSet.has(UNASSIGNED_VERSION_ID)));
};
