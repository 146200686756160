import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	addPeople: {
		id: 'software-filters.filters.assignee.stateless.add-button.add-people',
		defaultMessage: 'Add people',
		description:
			'Button adding new people into a project. Appears on the right side of assignee filter and shows add people dialog',
	},
});
