import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types.tsx';
import type { ColumnId } from '../../../../model/column/column-types.tsx';
import type { OptimisticId } from '../../../../model/software/software-types.tsx';
import type { FlagMeta } from '../../meta/flag-meta.tsx';
import {
	beginOptimisticUiMeta,
	commitOptimisticUiMeta,
	revertOptimisticUiWithFlagMeta,
	type OptimisticUiMeta,
} from '../../meta/optimistic-ui-meta.tsx';

export const COLUMN_RANK_REQUEST = 'state.actions.column.rank.COLUMN_RANK_REQUEST' as const;

export type ColumnRankRequestAction = {
	type: typeof COLUMN_RANK_REQUEST;
	payload: {
		columnId: ColumnId;
		targetColumnId: ColumnId;
		sourceColumnIndex?: number;
	};
	meta: {
		analyticsEvent?: AnalyticsEvent;
	} & OptimisticUiMeta;
};

export const columnRankRequest = (
	columnId: ColumnId,
	targetColumnId: ColumnId,
	sourceColumnIndex?: number,
	analyticsEvent?: AnalyticsEvent,
): ColumnRankRequestAction => ({
	type: COLUMN_RANK_REQUEST,
	payload: {
		columnId,
		targetColumnId,
		sourceColumnIndex,
	},
	meta: {
		...beginOptimisticUiMeta(),
		analyticsEvent,
	},
});

export const COLUMN_RANK_SUCCESS = 'state.actions.column.rank.COLUMN_RANK_SUCCESS';

export type ColumnRankSuccessAction = {
	type: typeof COLUMN_RANK_SUCCESS;
	payload: {
		columnId: ColumnId;
	};
	meta: OptimisticUiMeta;
};

export const columnRankSuccess = (
	optimisticId: OptimisticId,
	columnId: ColumnId,
): ColumnRankSuccessAction => ({
	type: COLUMN_RANK_SUCCESS,
	payload: {
		columnId,
	},
	meta: commitOptimisticUiMeta(optimisticId),
});

export const COLUMN_RANK_FAILURE = 'state.actions.column.rank.COLUMN_RANK_FAILURE' as const;

export type ColumnRankFailureAction = {
	type: typeof COLUMN_RANK_FAILURE;
	payload: {
		columnId: ColumnId;
	};
	error: string;
	meta: OptimisticUiMeta & FlagMeta;
};

export const columnRankFailure = (
	optimisticId: OptimisticId,
	columnId: ColumnId,
	error: string,
): ColumnRankFailureAction => ({
	type: COLUMN_RANK_FAILURE,
	payload: {
		columnId,
	},
	error,
	meta: revertOptimisticUiWithFlagMeta(optimisticId),
});

export type Action = ColumnRankRequestAction | ColumnRankFailureAction | ColumnRankSuccessAction;
