import type { IntlShape } from '@atlassian/jira-intl';
import {
	SECONDS_PER_MINUTE,
	SECONDS_PER_HOUR,
	TimeFormats,
	TimeUnits,
	defaultTimeTrackingOptions,
} from './constants.tsx';
import messages from './messages.tsx';
import type {
	TimeTrackingOptions,
	TimeTrackingOptionsWithDefaults,
	TimeFormat,
	TimeUnit,
	CreateUnit,
	Unit,
} from './types.tsx';
import { createUnit } from './utils.tsx';

type TimeObj = {
	secondsRemaining: number;
	time: string;
};

const validate: (condition: boolean) => CreateUnit['transform'] = (condition) => (distance) =>
	condition ? distance : 0;

// Format time to be 2 decimal places without trailing zeros
const timeFormatter = (time: number, unit: string): string =>
	time > 0 ? `${time.toFixed(2).replace(/\.?0+$/, '')}${unit}` : `0${unit}`;

const timePrettyFormatter = (
	timeInSeconds: number,
	units: Unit[],
	formatMessage: IntlShape['formatMessage'],
): string => {
	const timeForZero = `0${formatMessage(units[3].suffix)}`;

	if (timeInSeconds <= 0) {
		return timeForZero;
	}

	const initialTimeObj: TimeObj = {
		secondsRemaining: timeInSeconds,
		time: '',
	};

	const result = units.reduce((timeObj: TimeObj, unit: Unit): TimeObj => {
		if (unit.seconds <= 0) {
			return timeObj;
		}

		const currentTimeValue = Math.floor(timeObj.secondsRemaining / unit.seconds);

		const secondsRemaining =
			currentTimeValue > 0 ? timeObj.secondsRemaining % unit.seconds : timeObj.secondsRemaining;

		const time =
			currentTimeValue > 0
				? `${timeObj.time} ${currentTimeValue}${formatMessage(unit.suffix)}`
				: timeObj.time;

		return { secondsRemaining, time };
	}, initialTimeObj);

	return result.time.trim() || timeForZero;
};

const timePrettyFormatterLongForm = (
	timeInSeconds: number,
	units: Unit[],
	formatMessage: IntlShape['formatMessage'],
): string => {
	const timeForZero = `0 ${formatMessage(units[3].suffix, { [units[3].type]: 0 })}`;

	if (timeInSeconds <= 0) {
		return timeForZero;
	}

	const initialTimeObj: TimeObj = {
		secondsRemaining: timeInSeconds,
		time: '',
	};

	const result = units.reduce((timeObj: TimeObj, unit: Unit): TimeObj => {
		if (unit.seconds <= 0) {
			return timeObj;
		}

		const { suffix, type } = unit;

		const currentTimeValue = Math.floor(timeObj.secondsRemaining / unit.seconds);

		if (currentTimeValue === 1) {
			return {
				secondsRemaining: timeObj.secondsRemaining % unit.seconds,
				time: `${timeObj.time} ${currentTimeValue} ${formatMessage(suffix, { [type]: currentTimeValue })}`,
			};
		}

		const secondsRemaining =
			currentTimeValue > 1 ? timeObj.secondsRemaining % unit.seconds : timeObj.secondsRemaining;

		const time =
			currentTimeValue > 1
				? `${timeObj.time} ${currentTimeValue} ${formatMessage(suffix, { [type]: currentTimeValue })}`
				: timeObj.time;

		return { secondsRemaining, time };
	}, initialTimeObj);

	return result.time.trim() || timeForZero;
};

export const timeTrackingFormatter = (
	timeInSeconds: number,
	{
		workingHoursPerDay,
		workingDaysPerWeek,
		timeFormat,
	}: TimeTrackingOptions = defaultTimeTrackingOptions,
	{ formatMessage }: Pick<IntlShape, 'formatMessage'>,
): string => {
	const isTimeValid = timeInSeconds > 0;
	const isTimeInDaysValid = isTimeValid && workingHoursPerDay > 0;

	const unit = createUnit(timeInSeconds);

	const units: Unit[] = [
		unit({
			type: 'week',
			suffix: messages.timeUnitWeek,
			seconds: Math.max(workingDaysPerWeek, 0) * Math.max(workingHoursPerDay, 0) * SECONDS_PER_HOUR,
		}),
		unit({
			type: 'day',
			suffix: messages.timeUnitDay,
			transform: validate(isTimeInDaysValid),
			seconds: Math.max(workingHoursPerDay, 0) * SECONDS_PER_HOUR,
		}),
		unit({
			type: 'hour',
			suffix: messages.timeUnitHour,
			transform: validate(isTimeValid),
			seconds: SECONDS_PER_HOUR,
		}),
		unit({ type: 'minute', suffix: messages.timeUnitMinute, seconds: SECONDS_PER_MINUTE }),
	];

	switch (timeFormat) {
		case TimeFormats.DAYS:
			return timeFormatter(units[1].time, formatMessage(units[1].suffix));

		case TimeFormats.HOURS:
			return timeFormatter(units[2].time, formatMessage(units[2].suffix));

		case TimeFormats.PRETTY:
		default:
			return timePrettyFormatter(timeInSeconds, units, formatMessage);
	}
};

export const timeTrackingFormatterWithDefaults = (
	timeInSeconds: number | null,
	{ workingHoursPerDay, workingDaysPerWeek, timeFormat }: TimeTrackingOptionsWithDefaults,
	intl: IntlShape,
) => {
	const timeTrackingSettings = {
		workingHoursPerDay: workingHoursPerDay || defaultTimeTrackingOptions.workingHoursPerDay,
		workingDaysPerWeek: workingDaysPerWeek || defaultTimeTrackingOptions.workingDaysPerWeek,
		timeFormat: timeFormat || defaultTimeTrackingOptions.timeFormat,
		defaultUnit: defaultTimeTrackingOptions.defaultUnit,
	};

	return timeTrackingFormatter(timeInSeconds || 0, timeTrackingSettings, intl);
};

export const timeTrackingFormatterLongForm = (
	timeInSeconds: number,
	{
		workingHoursPerDay,
		workingDaysPerWeek,
		timeFormat,
	}: TimeTrackingOptions = defaultTimeTrackingOptions,
	{ formatMessage }: IntlShape,
): string => {
	const isTimeValid = timeInSeconds > 0;
	const isTimeInDaysValid = isTimeValid && workingHoursPerDay > 0;

	const unit = createUnit(timeInSeconds);

	const units: Unit[] = [
		unit({
			type: 'week',
			suffix: messages.timeUnitLongWeek,
			seconds: Math.max(workingDaysPerWeek, 0) * Math.max(workingHoursPerDay, 0) * SECONDS_PER_HOUR,
		}),
		unit({
			type: 'day',
			suffix: messages.timeUnitLongDay,
			transform: validate(isTimeInDaysValid),
			seconds: Math.max(workingHoursPerDay, 0) * SECONDS_PER_HOUR,
		}),
		unit({
			type: 'hour',
			suffix: messages.timeUnitLongHour,
			transform: validate(isTimeValid),
			seconds: SECONDS_PER_HOUR,
		}),
		unit({
			type: 'minute',
			suffix: messages.timeUnitLongMinute,
			seconds: SECONDS_PER_MINUTE,
		}),
	];

	switch (timeFormat) {
		case TimeFormats.DAYS:
			return timeFormatter(
				units[1].time,
				` ${formatMessage(units[1].suffix, { day: units[1].time })}`,
			);
		case TimeFormats.HOURS:
			return timeFormatter(
				units[2].time,
				` ${formatMessage(units[2].suffix, { hour: units[2].time })}`,
			);
		case TimeFormats.PRETTY:
		default:
			return timePrettyFormatterLongForm(timeInSeconds, units, formatMessage);
	}
};

export const transformAGGToJiraTimeFormat = <JiraTimeFormat,>(
	format: JiraTimeFormat,
): TimeFormat => {
	switch (String(format).toLowerCase()) {
		case TimeFormats.DAYS: {
			return TimeFormats.DAYS;
		}
		case TimeFormats.HOURS: {
			return TimeFormats.HOURS;
		}
		case TimeFormats.PRETTY: {
			return TimeFormats.PRETTY;
		}
		default: {
			return TimeFormats.DAYS;
		}
	}
};

export const transformAGGToJiraTimeUnit = <JiraTimeUnit,>(defaultUnit: JiraTimeUnit): TimeUnit => {
	switch (String(defaultUnit).toLowerCase()) {
		case TimeUnits.DAY: {
			return TimeUnits.DAY;
		}
		case TimeUnits.HOUR: {
			return TimeUnits.HOUR;
		}
		case TimeUnits.MINUTE: {
			return TimeUnits.MINUTE;
		}
		case TimeUnits.WEEK: {
			return TimeUnits.WEEK;
		}
		default: {
			return TimeUnits.DAY;
		}
	}
};
