export const ACTIVE_SPRINT_STATE = 'ACTIVE' as const;
export const FUTURE_SPRINT_STATE = 'FUTURE' as const;
export const CLOSED_SPRINT_STATE = 'CLOSED' as const;

export const SPRINT_STATES = {
	ACTIVE: ACTIVE_SPRINT_STATE,
	CLOSED: CLOSED_SPRINT_STATE,
	FUTURE: FUTURE_SPRINT_STATE,
} as const;

export type SprintState = 'ACTIVE' | 'CLOSED' | 'FUTURE';

export type SprintSuggestion = {
	id: number;
	name: string;
	stateKey: SprintState;
	date: string;
	projectKeys?: string[];
	boardName?: string;
	fromCache?: boolean;
};

export type SprintPayload = {
	suggestions?: SprintSuggestion[];
	allMatches?: SprintSuggestion[];
};
