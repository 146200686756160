import { UNASSIGNED_LABEL_ID } from '../../common/constants.tsx';
import type { FilterableIssue, MatcherCreator } from '../types.tsx';

export const labelMatcherCreator: MatcherCreator<string[], FilterableIssue> = (labelFilter) => {
	const labelSet = new Set(labelFilter);

	return ({ labels }) =>
		labels !== undefined &&
		(labels.some((label) => labelSet.has(label)) ||
			(labels.length === 0 && labelSet.has(UNASSIGNED_LABEL_ID)));
};
