import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import type { GoalStateValue } from '@atlassian/jira-shared-types/src/goal.tsx';

export const mapStateToBgColor = (state: GoalStateValue): string => {
	switch (state) {
		case 'off_track':
			return token('color.background.accent.red.subtlest', colors.R50);
		case 'at_risk':
			return token('color.background.accent.yellow.subtlest', colors.Y50);
		case 'on_track':
			return token('color.background.accent.green.subtlest', colors.G50);
		case 'archived':
		case 'cancelled':
		case 'done':
		case 'paused':
		case 'pending':
		default:
			return token('color.background.accent.gray.subtlest', colors.N20);
	}
};
export const mapStateToColor = (state: GoalStateValue): string => {
	switch (state) {
		case 'off_track':
			return token('color.text.accent.red', colors.R500);
		case 'at_risk':
			return token('color.text.accent.orange', colors.Y500);
		case 'on_track':
			return token('color.text.accent.green', colors.G500);
		case 'archived':
		case 'cancelled':
		case 'done':
		case 'paused':
		case 'pending':
		default:
			return token('color.text.accent.gray', colors.N500);
	}
};

export const mapScoreToBgColor = (score: number): string => {
	if (score < 0.4) {
		return token('color.background.danger.bold', colors.R500);
	}
	if (score < 0.7) {
		return token('color.background.accent.orange.bolder', colors.Y500);
	}
	return token('color.background.success.bold', colors.G500);
};

export const extractStatusLabelWithoutScore = (fullLabel: string): string =>
	fullLabel.split(' - ')[0];
