import { useMemo } from 'react';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { useContainerContext } from '@atlassian/jira-providers-container-context/src/main.tsx';
import { isUserContext } from '@atlassian/jira-providers-container-context/src/types.tsx';
import { useBoardSelector } from '../../../state/index.tsx';
import {
	contextPathSelector,
	getIsCMPBoard,
	rapidViewIdSelector,
} from '../../../state/selectors/software/software-selectors.tsx';

export const useBacklogUrl = (additionalParams: string[] = []): string | null => {
	const boardId = useBoardSelector(rapidViewIdSelector);
	const isCMPBoard = useBoardSelector(getIsCMPBoard);
	const contextPath = useBoardSelector(contextPathSelector);
	const [{ data, error }] = useContainerContext();

	return useMemo(() => {
		if (!data || error) {
			log.safeErrorWithoutCustomerData('useBacklogUrl', 'Failed getting container context');
			return null;
		}
		const url = isUserContext(data)
			? `${contextPath}/jira/people/${data.user.accountId}`
			: `${contextPath}/jira/software${isCMPBoard ? '/c' : ''}/projects/${data.project.key}`;
		return `${url}/boards/${boardId}/backlog${
			additionalParams.length ? `?${additionalParams.join('&')}` : ''
		}`;
	}, [additionalParams, boardId, contextPath, data, error, isCMPBoard]);
};
