import CardCoverManagerQueryParameters from '@atlassian/jira-relay/src/__generated__/CardCoverManagerQuery$parameters';
import type { CardCoverManagerQuery$variables } from '@atlassian/jira-relay/src/__generated__/CardCoverManagerQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const cardCoverManagerEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "async-card-cover-manager" */ './src/ui/CardCoverManager').then(
			(module) => module.CardCoverManagerWithAnalyticsContext,
		),
	),
	getPreloadProps: ({
		cloudId,
		issueId,
		durationInSeconds,
		maxTokenLength,
	}: CardCoverManagerQuery$variables) => ({
		queries: {
			cardCoverManagerQuery: {
				options: {
					fetchPolicy: 'store-and-network' as const,
				},
				parameters: CardCoverManagerQueryParameters,
				variables: {
					cloudId,
					issueId,
					durationInSeconds,
					maxTokenLength,
				},
			},
		},
	}),
});
