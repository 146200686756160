export const ARCHIVE_ISSUE_MODAL_WIDTH_CATEGORY = 'small' as const;

export const ESC_KEY = 'Escape' as const;

export const ISSUE_VIEW_URL_REGEX = /.*\/browse\/[^/]+/;

export const ISSUE_ARCHIVE_API = '/rest/api/2/issue/archive' as const;

// Trigger points for issue-archival
export const TRIGGER_POINT_KEY_ISSUE_VIEW = 'issue-view' as const;
export const TRIGGER_POINT_KEY_ISSUE_NAVIGATOR = 'issue-navigator' as const;
export const TRIGGER_POINT_KEY_BOARD_VIEW = 'board-view' as const;
