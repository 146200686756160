import React from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { TaskPanel } from './index.tsx';
import type { Props } from './types.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyNBTPanel = lazyForPaint<typeof TaskPanel>(
	() =>
		import(/* webpackChunkName: "async-nbt-panel" */ './index').then(({ TaskPanel }) => TaskPanel),
	{ ssr: false },
);

export const AsyncNBTPanel = (props: Props) => (
	<UFOSegment name="next-best-task-panel">
		<Placeholder name="async-nbt-panel" fallback={<></>}>
			<LazyNBTPanel {...props} />
		</Placeholder>
	</UFOSegment>
);
