import { createStore, createContainer, createStateHook } from '@atlassian/react-sweet-state';
import type { ContainerProps, IssueLinksHandlerApi, State } from './types.tsx';

export const initialState: State = {
	// Replace with lodash/noop
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onIssueOpen: () => {},
};

const actions = {};
export type Actions = typeof actions;

export const store = createStore<State, Actions>({
	initialState,
	actions,
	name: 'issue-links-handler-store',
});

const onPropsChange = ({ setState }: IssueLinksHandlerApi, { onIssueOpen }: ContainerProps) => {
	setState({
		onIssueOpen,
	});
};

export const IssueLinksHandlerContainer = createContainer<State, Actions, ContainerProps>(store, {
	onInit: () => onPropsChange,
	onUpdate: () => onPropsChange,
});

export const useOnIssueOpen = createStateHook(store, {
	selector: (state) => state.onIssueOpen,
});
