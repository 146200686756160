import React, { useRef, useCallback, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { colors as akColors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { DRAGGABLE_COLUMN_SELECTOR_NAME } from '../../../common/constants/index.tsx';
import {
	layout,
	columnFixedWidth,
	collapsedColumnFixedWidth,
	columnWithMarginWidth,
	collapsedColumnWithMarginWidth,
	columnMinWidth,
	columnMinWithMarginWidth,
} from '../../../common/constants/styles/index.tsx';
import { ColumnTheme } from '../../../common/types.tsx';
import { ColumnDropIndicator } from '../../../common/ui/column-drop-indicator/index.tsx';
import { useDraggableColumn } from '../../../common/ui/use-draggable-column/index.tsx';
import Column from './column/index.tsx';
import type { OwnProps } from './types.tsx';

export const DraggableColumn = (props: OwnProps) => {
	const {
		id,
		isOutlined,
		swimlaneId,
		TransitionZones,
		isFlexible,
		isDraggable = false,
		isDropDisabled = false,
		isUpdating = false,
		isCollapsed = false,
		header,
		draggableIndex,
		appearance = ColumnTheme.Default,
		footer = null,
		onDragLeave,
		isCardDroppable,
		children,
	} = props;

	const wrapperRef = useRef<HTMLDivElement | null>(null);

	const { dragPreview, closestEdge } = useDraggableColumn({
		isDraggable,
		elementRef: wrapperRef,
		columnId: id,
		swimlaneId,
		columnIndex: draggableIndex,
		renderPreview: () => header,
		onDragLeave,
		isCardDroppable,
	});

	const handleOnMouseDown = useCallback(() => {
		// Clear any text selection to prevent unexpected dragging behaviour

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.getSelection()?.removeAllRanges();
	}, []);

	return (
		<StyledWrapper
			ref={wrapperRef}
			isCollapsed={isCollapsed}
			isFlexible={isFlexible}
			data-component-selector={DRAGGABLE_COLUMN_SELECTOR_NAME}
			onMouseDown={handleOnMouseDown}
			// TODO: change to data-test-id to make integration tests work
			data-testid="platform-board-kit.ui.column.draggable-column.styled-wrapper"
		>
			<StyledDraggableColumn isCollapsed={isCollapsed} isFlexible={isFlexible}>
				<Column
					id={id}
					swimlaneId={swimlaneId}
					isDraggable={isDraggable}
					isDragging={false}
					isDropDisabled={isDropDisabled}
					isOutlined={isOutlined}
					isUpdating={isUpdating}
					appearance={appearance}
					header={header}
					footer={footer}
					TransitionZones={TransitionZones}
					isCollapsed={isCollapsed}
					isFlexible={isFlexible}
				>
					{children}
				</Column>
			</StyledDraggableColumn>

			{closestEdge && <ColumnDropIndicator edge={closestEdge} />}
			{dragPreview}
		</StyledWrapper>
	);
};

type StyledWrapperProps = {
	isCollapsed?: boolean;
	isFlexible?: boolean;
	children?: ReactNode;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled,@atlaskit/ui-styling-standard/no-exported-styles -- To migrate as part of go/ui-styling-standard
export const StyledWrapper = styled.div<StyledWrapperProps>(
	{
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignSelf: 'auto',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		flex: (props) => (props.isFlexible ? '1 1' : '1 1 auto'),
		transition: 'min-width 300ms, max-width 300ms ease-in',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		minWidth: `${columnWithMarginWidth}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		maxWidth: `${columnWithMarginWidth}px`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isCollapsed, isFlexible }: StyledWrapperProps) => ({
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		...(isCollapsed &&
			!isFlexible && {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				minWidth: `${collapsedColumnWithMarginWidth}px`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				maxWidth: `${collapsedColumnWithMarginWidth}px`,
			}),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		...(isFlexible && {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			minWidth: `${columnMinWithMarginWidth}px`,
			maxWidth: '100%',
		}),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		...(isCollapsed &&
			isFlexible && {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				minWidth: `${collapsedColumnWithMarginWidth}px`,
				maxWidth: '100%',
			}),
	}),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled,@atlaskit/ui-styling-standard/no-exported-styles -- To migrate as part of go/ui-styling-standard
export const StyledDraggableColumn = styled.div<{
	isCollapsed?: boolean;
	isDragging?: boolean;
	isFlexible?: boolean;
}>(
	{
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		borderRadius: `${layout.cardListRadius}px`,
		display: 'flex',
		flexDirection: 'column',
		alignSelf: 'auto',
		contain: "'style'",
		flex: '1 1 auto',

		marginLeft: token('space.050', '0.25rem'),

		marginRight: token('space.050', '0.25rem'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		minWidth: `${columnFixedWidth}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		maxWidth: `${columnFixedWidth}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		boxShadow: (props) =>
			props.isDragging
				? token(
						'elevation.shadow.overlay',
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						`0px 8px 12px ${akColors.N50A}, 0 0 1px ${akColors.N60A}`,
					)
				: 'initial',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		backgroundColor: (props) =>
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			props.isCollapsed ? 'transparent' : token('color.background.neutral', akColors.N20),
		transition: 'min-width 300ms, max-width 300ms ease-in',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isCollapsed, isFlexible }: StyledWrapperProps) => ({
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		...(isCollapsed &&
			!isFlexible && {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				minWidth: `${collapsedColumnFixedWidth}px`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				maxWidth: `${collapsedColumnFixedWidth}px`,
			}),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		...(isFlexible && {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			minWidth: `${columnMinWidth}px`,
			maxWidth: 'none',
		}),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		...(isCollapsed &&
			isFlexible && {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				minWidth: `${collapsedColumnFixedWidth}px`,
				maxWidth: 'none',
			}),
	}),
);
