import React, { useCallback, useState, useMemo } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import Avatar, { AVATAR_SIZES } from '@atlaskit/avatar';
import DropdownMenu, { DropdownItemCheckbox } from '@atlaskit/dropdown-menu';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { VARIATION } from '@atlassian/jira-common-util-get-experiment-group/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useRealtimePresenceExperiment } from '@atlassian/jira-real-time-presence/src/controllers/use-realtime-presence-experiment/index.tsx';
import { type AccountId, toAccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import type { Person } from '../../../../../common/types.tsx';
import {
	OVERLAP_PIXELS,
	FILTER_PREFIX,
	Z_INDEX,
} from '../../../../../common/ui/assignee/constants.tsx';
import {
	AvatarWrapper,
	ActionAvatar,
	ActionAvatarSpacer,
	AvatarBorder,
	hiddenCheckboxStyles,
} from '../../../../../common/ui/assignee/index.tsx';
import { onBlur } from '../../../../../common/ui/assignee/utils.tsx';
import PresenceTooltip from '../../../../../common/ui/presence-tooltip/index.tsx';
import messages from '../messages.tsx';

export type Props = {
	users: Person[];
	selectedUsers: AccountId[];
	onItemActivated: (userId: AccountId) => void;
	onOpen: () => void;
};

const ShowMoreButton = (props: Props) => {
	const { users, selectedUsers, onOpen, onItemActivated: onItemActivatedProps } = props;
	const { formatMessage } = useIntl();

	const [isOpen, setOpenState] = useState(false);

	const _onOpenChange = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(attrs: any) => {
			setOpenState(attrs.isOpen);
			if (attrs.isOpen) {
				onOpen();
			}
		},
		[setOpenState, onOpen],
	);

	const isSelected = useMemo(
		() => users.some((user) => selectedUsers.includes(user.id)),
		[users, selectedUsers],
	);

	const { experimentCohort } = useRealtimePresenceExperiment();

	const isRealtimePresenceEnabled = experimentCohort === VARIATION;

	const dropdownItems = useMemo(
		() =>
			users.map(({ id, displayName, avatarUrl, presence }) => (
				<ItemWrapper key={id}>
					<DropdownItemCheckbox
						id={id}
						isSelected={selectedUsers.includes(id)}
						onClick={() => {
							onItemActivatedProps(toAccountId(String(id)));
						}}
					>
						<ItemContent>
							{isRealtimePresenceEnabled ? (
								<PresenceTooltip content={displayName} presence={presence}>
									<DropdownAvatarWrapper>
										<Avatar size="small" src={avatarUrl} name={displayName} presence={presence} />
									</DropdownAvatarWrapper>
								</PresenceTooltip>
							) : (
								<Tooltip content={displayName}>
									<DropdownAvatarWrapper>
										<Avatar size="small" src={avatarUrl} name={displayName} presence={presence} />
									</DropdownAvatarWrapper>
								</Tooltip>
							)}
							<TextContentWrapper>
								<TextContent>{displayName}</TextContent>
							</TextContentWrapper>
						</ItemContent>
					</DropdownItemCheckbox>
				</ItemWrapper>
			)),
		[users, selectedUsers, onItemActivatedProps, isRealtimePresenceEnabled],
	);

	// On cleanup of 'jsw-filters-avatar-show-more-aria-fix' move these to the avatar wrapper styles
	const avatarWrapperStyle = {
		margin: 0,
		padding: 0,
		border: 0,
		borderRadius: '50%',
		background: 'none',
		outline: 0,
	};

	return (
		<DropdownMenuWrapper>
			<DropdownMenu<HTMLDivElement>
				trigger={({ triggerRef, 'aria-expanded': ariaExpanded, ...triggerProps }) => (
					<AvatarWrapper
						{...triggerProps}
						as={fg('jsw-filters-avatar-show-more-aria-fix') ? 'button' : undefined}
						aria-expanded={ariaExpanded}
						ref={triggerRef}
						zIndex={Z_INDEX.MENU}
						// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop
						style={fg('jsw-filters-avatar-show-more-aria-fix') ? avatarWrapperStyle : undefined}
						data-testid={
							fg('jsw-filters-avatar-show-more-aria-fix')
								? 'filters.ui.filters.assignee.stateless.show-more-button.assignee-filter-show-more'
								: undefined
						}
					>
						{!fg('jsw-filters-avatar-show-more-aria-fix') ? (
							<ActionInputCheckbox
								data-test-id="filters.ui.filters.assignee.stateless.show-more-button.assignee-filter-show-more"
								data-testid="filters.ui.filters.assignee.stateless.show-more-button.assignee-filter-show-more"
								type="checkbox"
								id={`${FILTER_PREFIX}-show-more`}
								name={FILTER_PREFIX}
								value={`${FILTER_PREFIX}-show-more`}
								defaultChecked={isOpen}
								aria-expanded={ariaExpanded}
								aria-label={formatMessage(messages.showMoreButton)}
								onClick={onBlur}
							/>
						) : null}
						<AvatarBorder selected={isSelected}>
							<ActionAvatar
								as={fg('jsw-filters-avatar-show-more-aria-fix') ? 'div' : undefined}
								tabIndex={-1}
							>
								{isVisualRefreshEnabled() ? (
									<ShowMoreButtonAvatarSpacer>{`+${users.length}`}</ShowMoreButtonAvatarSpacer>
								) : (
									<ActionAvatarSpacer>{`+${users.length}`}</ActionAvatarSpacer>
								)}
							</ActionAvatar>
						</AvatarBorder>
					</AvatarWrapper>
				)}
			>
				<DropdownItemsWrapper>{dropdownItems}</DropdownItemsWrapper>
			</DropdownMenu>
		</DropdownMenuWrapper>
	);
};

ShowMoreButton.defaultProps = {
	onItemActivated: noop,
	onOpen: noop,
};

export default ShowMoreButton;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DropdownMenuWrapper = styled.div({
	display: 'inline-flex',
	alignItems: 'center',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	marginLeft: `-${OVERLAP_PIXELS}px`,
	zIndex: 0,
});

// The old menu dropdown has a max-width of 300px but the new one has 800px.
// We have to set the max-width in the wrapper to keep the same style.
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DropdownItemsWrapper = styled.div({
	maxWidth: '300px',
	padding: `${token('space.050', '4px')} 0px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ItemWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > button': {
		padding: `${token('space.0', '0px')} ${token('space.150', '12px')}`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& span': {
		margin: token('space.0', '0px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ItemContent = styled.div({
	display: 'flex',
	alignItems: 'center',
	textOverflow: 'ellipsis',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DropdownAvatarWrapper = styled.div({
	display: 'flex',
	marginLeft: token('space.025', '2px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TextContentWrapper = styled.div({
	display: 'flex',
	margin: `${token('space.0', '0px')} ${token('space.100', '8px')}`,
	padding: `${token('space.100', '8px')} ${token('space.0', '0px')}`,
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TextContent = styled.div({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const ActionInputCheckbox = styled.input(hiddenCheckboxStyles, {
	margin: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&:hover + button': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.background.neutral.hovered', colors.N60),
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:focus:not(:hover) + button': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		borderColor: token('color.border.focused', colors.B200),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const ShowMoreButtonAvatarSpacer = styled(ActionAvatarSpacer)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${AVATAR_SIZES.small}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${AVATAR_SIZES.small}px`,
	fontSize: '10px',
	transform: 'translateX(1px)',
});
