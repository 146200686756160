import { WORK_DATA_SET } from '../../../../actions/work/index.tsx';
import type { Action } from '../../../../types.tsx';
import type { IsConfigLoadedState } from './types.tsx';

export const isConfigLoadedReducer = (
	state: IsConfigLoadedState = false,
	action: Action,
): IsConfigLoadedState => {
	switch (action.type) {
		case WORK_DATA_SET:
			return true;
		default:
			return state;
	}
};
