import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	genericErrorDescription: {
		id: 'software-swag.common.generic-error-description',
		defaultMessage: '{userMessage}',
		description: 'SWAG error message',
	},
	xsrfCheckFailedTitle: {
		id: 'software-swag.common.xsrf-check-failed-title',
		defaultMessage: 'Our session has expired',
		description: 'SWAG and Front-end XSRF token mismatch error-flag title',
	},
	xsrfCheckFailedDescription: {
		id: 'software-swag.common.xsrf-check-failed-description',
		defaultMessage: 'Refresh the page and try again',
		description: 'SWAG and Front-end XSRF token mismatch error-flag description',
	},
});
