import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	ok: {
		id: 'software.main.header.menu.onboarding-spotlight.ok',
		defaultMessage: 'OK',
		description: 'JSM board meatball menu changeboarding spotlight button text.',
	},
	heading: {
		id: 'software.main.header.menu.onboarding-spotlight.heading',
		defaultMessage: 'Customize your board',
		description: 'JSM board meatball menu changeboarding spotlight heading',
	},
	description: {
		id: 'software.main.header.menu.onboarding-spotlight.description',
		defaultMessage:
			"Add, rename, or remove columns on this board to match your team's preferred process. Create Quick filters or enable swimlanes.",
		description: 'JSM board meatball menu changeboarding spotlight text',
	},
});

export default messages;
