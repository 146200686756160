import { useCallback, type MouseEvent, type KeyboardEvent } from 'react';

export const onBlur = (event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLInputElement>) => {
	event.currentTarget.blur();
};

export const useOnKeyPress = (callback: (event: KeyboardEvent<HTMLInputElement>) => void) =>
	useCallback(
		(event: KeyboardEvent<HTMLInputElement>) => {
			if (event.key === 'Enter') {
				callback(event);
			}
		},
		[callback],
	);
