import React from 'react';
import { TenantContextSubscriber } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import BoardSizeLimitErrorEmptyState from './main.tsx';

export type Props = {
	projectKey: string;
	isCMPBoard?: boolean;
	savedFilterId?: number;
};

/**
 * @deprecated use generic version of SizeLimitErrorEmptyStateComponent component @atlassian/jira-software-size-limit-error-empty-state
 */
const BoardSizeLimitErrorEmptyStateWithBaseUrl = ({
	projectKey,
	savedFilterId,
	isCMPBoard = false,
}: Props) => (
	<TenantContextSubscriber>
		{({ tenantContext }) => {
			const { baseUrl } = tenantContext;
			return (
				<BoardSizeLimitErrorEmptyState
					projectKey={projectKey}
					baseUrl={baseUrl}
					isCMPBoard={isCMPBoard}
					savedFilterId={savedFilterId}
				/>
			);
		}}
	</TenantContextSubscriber>
);

export default BoardSizeLimitErrorEmptyStateWithBaseUrl;
