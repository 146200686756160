import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const syncBoardSwitcher = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "async-program-board-switcher" */ './index').then(
			(module) => module.AsyncBoardSwitcher,
		),
	),
	getPreloadProps: () => ({}),
});
