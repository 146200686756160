import { createHook } from '@atlassian/react-sweet-state';
import { Store } from '../index.tsx';
import type { State } from '../types.tsx';

const getCardMediaHeightById = (
	state: State,
	{
		mediaId,
	}: {
		mediaId: string;
	},
) => {
	if (!state[mediaId]) {
		return null;
	}

	return state[mediaId].height;
};

export const useCardMediaHeightById = createHook(Store, {
	selector: getCardMediaHeightById,
});
