import { chain } from 'icepick';
import { createSelector } from 'reselect';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import last from 'lodash/last';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import type { ColumnId } from '../../../model/column/column-types.tsx';
import type { Issue } from '../../../model/issue/issue-types.tsx';
import type { SwimlaneId } from '../../../model/swimlane/swimlane-types.tsx';
import { getSwimlaneId } from '../../../services/issue/issue-data-transformer.tsx';
import type { State } from '../../reducers/types.tsx';
import { getSwimlaneMode } from '../swimlane/swimlane-mode-selectors.tsx';
import { getSwimlanes } from '../swimlane/swimlane-selectors.tsx';
import { boardIssuesSelector } from './board-issue-selectors.tsx';
import { getOrderedIssuesByColumn } from './issue-selectors.tsx';

export const orderedIssuesBySwimlaneSelector = createSelector(
	[getSwimlanes, getSwimlaneMode, getOrderedIssuesByColumn],
	(
		swimlanes,
		swimlaneMode,
		issues,
	): {
		[swimlaneId: string]: {
			[columnId: string]: Issue[];
		};
	} => {
		const swimlaneIssuesMap: {
			[swimlaneId: string]: {
				[columnId: string]: Issue[];
			};
		} = {};
		swimlanes.forEach((swimlane) => {
			swimlaneIssuesMap[swimlane.id] = Object.keys(issues)
				.reduce(
					(groupedIssues, columnId) => {
						groupedIssues.setIn(
							[String(columnId)],
							issues[Number(columnId)].filter(
								(issue) => getSwimlaneId(swimlaneMode, swimlanes, issue) === swimlane.id,
							),
						);
						return groupedIssues;
					},
					chain(
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						{} as {
							[key: string]: Issue[];
						},
					),
				)
				.value();
		});
		return swimlaneIssuesMap;
	},
);

/* This selector is used by the Increment Planning Board to determine if all columns
in a swimlane are empty */
export const getIsSwimlaneEmpty = (state: State, swimlaneId: SwimlaneId): boolean => {
	let issueCount = 0;
	const swimlane = get(orderedIssuesBySwimlaneSelector(state), [swimlaneId], null);
	if (swimlane) {
		Object.values(swimlane).forEach((column) => {
			issueCount += column.length;
		});
	}
	return issueCount === 0;
};

export const getFirstIssueIdInSwimlane = (
	state: State,
	columnId: ColumnId,
	swimlaneId: SwimlaneId,
): IssueId | null | undefined =>
	get(orderedIssuesBySwimlaneSelector(state), [swimlaneId, columnId, 0, 'id'], null);

export const getLastIssueIdInSwimlane = (
	state: State,
	columnId: ColumnId,
	swimlaneId: SwimlaneId,
): IssueId | null | undefined =>
	get(last(get(orderedIssuesBySwimlaneSelector(state), [swimlaneId, columnId], [])), ['id'], null);

export const isFirstIssueInSwimlane = (state: State, issueId: IssueId): boolean => {
	const issue = boardIssuesSelector(state)[String(issueId)];
	if (isNil(issue)) return false;
	const swimlaneId = getSwimlaneId(getSwimlaneMode(state), getSwimlanes(state), issue);
	return (
		issue !== undefined &&
		swimlaneId !== null &&
		getFirstIssueIdInSwimlane(state, issue.columnId, swimlaneId) === issueId
	);
};

export const isLastIssueInSwimlane = (state: State, issueId: IssueId): boolean => {
	const issue = boardIssuesSelector(state)[String(issueId)];
	if (isNil(issue)) return false;
	const swimlaneId = getSwimlaneId(getSwimlaneMode(state), getSwimlanes(state), issue);
	return (
		issue !== undefined &&
		swimlaneId !== null &&
		getLastIssueIdInSwimlane(state, issue.columnId, swimlaneId) === issueId
	);
};
