import 'rxjs/add/observable/from';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/zip';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import { Observable } from 'rxjs/Observable';
import {
	CARD_AUTO_SCROLL_REQUEST_ON_FILTER,
	CARD_AUTO_SCROLL_REQUEST_ON_LOAD,
	cardAutoScroll,
} from '../../state/actions/card/card-auto-scroll/index.tsx';
import { cardSingleSelect } from '../../state/actions/card/index.tsx';
import { WORK_DATA_SET } from '../../state/actions/work/index.tsx';
import { getCardSelection } from '../../state/selectors/card/card-selectors.tsx';
import { getIssueByKey } from '../../state/selectors/issue/issue-selectors.tsx';
import type { Action, ActionsObservable, MiddlewareAPI } from '../../state/types.tsx';

export const cardAutoScrollOnLoadEpic = (action$: ActionsObservable, store: MiddlewareAPI) =>
	Observable.zip(
		action$.ofType(CARD_AUTO_SCROLL_REQUEST_ON_LOAD),
		action$.ofType(WORK_DATA_SET),
	).flatMap(([action]) => {
		const state = store.getState();
		const issueId = getIssueByKey(state, action.payload.issueKey)?.id;
		return issueId
			? Observable.from([cardSingleSelect(issueId), cardAutoScroll(issueId)])
			: Observable.empty<Action>();
	});

export const cardAutoScrollOnFilterEpic = (action$: ActionsObservable, store: MiddlewareAPI) =>
	action$.ofType(CARD_AUTO_SCROLL_REQUEST_ON_FILTER).mergeMap(() => {
		const state = store.getState();
		const selectedIssueId = getCardSelection(state)[0];
		return selectedIssueId
			? Observable.from([cardAutoScroll(selectedIssueId)])
			: Observable.empty<Action>();
	});
