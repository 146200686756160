import { useRef, useEffect } from 'react';
import type { DiffValue } from './types.tsx';

export const useDiffCheck = (nextValues: DiffValue[] = []) => {
	const ref = useRef<DiffValue[]>([]);
	const prevValues = ref.current;

	useEffect(() => {
		ref.current = nextValues;
	}, [nextValues]);

	return prevValues.length
		? prevValues.some((value: DiffValue, i: number) => nextValues[i] !== value)
		: false;
};
