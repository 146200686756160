import traceUFOPress from '@atlaskit/react-ufo/trace-press';
import { issueViewOpenInteraction } from '../../../../services/utils/performance-analytics/index.tsx';
import type { CardClickAction } from '../../../actions/card/index.tsx';
import type { State } from '../../../reducers/types.tsx';

export const issueViewOpenPerformance =
	() => (prevState: State, state: State, action: CardClickAction) => {
		if (!action.payload.withCmd && !action.payload.withShift) {
			traceUFOPress('board.issue-view-opened');
			issueViewOpenInteraction.start();
		}
		return null;
	};
