import { chain, map, splice } from 'icepick';
import difference from 'lodash/difference';
import intersection from 'lodash/intersection';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import { SUBTASK_DND_TYPE } from '../../../../../model/constants.tsx';
import {
	CARD_CLEAR,
	CARD_DELETE,
	type CardDeleteAction,
	type CardClearAction,
} from '../../../../actions/card/index.tsx';
import {
	COLUMN_DELETE_REQUEST,
	type ColumnDeleteRequestAction,
} from '../../../../actions/column/delete/index.tsx';
import type { Action } from '../../../../actions/index.tsx';
import {
	SET_ISSUE_CHILDREN,
	SET_ISSUE_CHILDREN_RANK,
	type SetIssueChildrenAction,
	type SetIssueChildrenRankAction,
} from '../../../../actions/issue-children/index.tsx';
import {
	ISSUE_CREATE_REQUEST,
	ISSUE_CREATE_SUCCESS,
	type IssueCreateRequestAction,
	type IssueCreateSuccessAction,
} from '../../../../actions/issue/create/index.tsx';
import {
	ISSUE_DELETE_REQUEST,
	type IssueDeleteRequestAction,
} from '../../../../actions/issue/delete/index.tsx';
import {
	type BulkIssueRankOptimisticAction,
	type IssueRankTransitionUpdateOptimisticAction,
	ISSUE_RANK_TRANSITION_UPDATE_OPTIMISTIC,
	BULK_ISSUE_RANK_OPTIMISTIC,
} from '../../../../actions/issue/rank-transition/index.tsx';
import {
	WORK_DATA_CRITICAL_SET,
	WORK_DATA_SET,
	type WorkDataCriticalSetAction,
	type WorkDataSetAction,
} from '../../../../actions/work/index.tsx';
import type { IssueChildrenState } from './types.tsx';

const rankChildIssues = ({
	state,
	issueIds,
	rankBeforeIssueId,
	rankAfterIssueId,
}: {
	state: IssueId[];
	issueIds: IssueId[];
	rankBeforeIssueId: IssueId | null | undefined;
	rankAfterIssueId: IssueId | null | undefined;
}) => {
	const availableIssues = intersection(state, issueIds);
	if (availableIssues.length === 0) return state;

	const newState = difference(state, availableIssues);

	let destinationIndex;

	if (rankBeforeIssueId) {
		destinationIndex = newState.indexOf(rankBeforeIssueId);
	} else if (rankAfterIssueId) {
		destinationIndex = newState.indexOf(rankAfterIssueId) + 1;
	} else {
		return state;
	}

	return splice(newState, destinationIndex, 0, ...issueIds);
};

export const boardIssueChildrenReducer = (
	state: IssueChildrenState = [],
	action: Action,
): IssueChildrenState => {
	if (action.type === WORK_DATA_SET || action.type === WORK_DATA_CRITICAL_SET) {
		const {
			payload: { issueChildren },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as WorkDataSetAction | WorkDataCriticalSetAction;
		return issueChildren.map((issue) => issue.id);
	}

	if (action.type === SET_ISSUE_CHILDREN) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const { payload: issueChildren } = action as SetIssueChildrenAction;
		return issueChildren.map((issue) => issue.id);
	}

	if (action.type === ISSUE_DELETE_REQUEST) {
		const {
			payload: { issue, childIssueIds },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as IssueDeleteRequestAction;

		const childIssueToDelete = issue.id;
		const childIssueOfDeletedParent = childIssueIds;
		return state.filter(
			(childIssueId) =>
				childIssueId !== childIssueToDelete && childIssueOfDeletedParent.indexOf(childIssueId) < 0,
		);
	}

	if (action.type === CARD_CLEAR) {
		const {
			payload: { childCardIds },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as CardClearAction;

		const newState = chain(state);
		childCardIds.forEach((cardId) => {
			const index = newState.value().indexOf(cardId);
			if (index >= 0) {
				// @ts-expect-error - TS2339 - Property 'splice' does not exist on type 'IcepickWrapper<IssueChildrenState>'.
				newState.splice(index, 1);
			}
		});
		return newState.value();
	}

	if (action.type === CARD_DELETE) {
		const {
			payload: { issueId },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as CardDeleteAction;
		const index = state.indexOf(issueId);
		if (index < 0) {
			return state;
		}
		return splice(state, index, 1);
	}

	if (action.type === ISSUE_CREATE_REQUEST) {
		const {
			payload: { issues, type },
			meta: { insertBefore },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as IssueCreateRequestAction;

		if (type !== SUBTASK_DND_TYPE) return state;

		const issueIds = issues.map(({ id }) => id);
		const insertionPoint = insertBefore ? state.indexOf(insertBefore) : state.length;
		return splice(state, insertionPoint, 0, ...issueIds);
	}

	if (action.type === ISSUE_CREATE_SUCCESS) {
		const {
			payload: { temporaryIssueIds, issues },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as IssueCreateSuccessAction;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const translation: Record<string, any> = {};
		temporaryIssueIds.forEach((id, index) => {
			if (issues[index]) {
				translation[id] = issues[index].id;
			}
		});
		// Assume that no temporary id will ever be falsey (0).
		return Object.keys(translation).length > 0
			? map((oldId) => translation[oldId] || oldId, state)
			: state;
	}

	if (action.type === ISSUE_RANK_TRANSITION_UPDATE_OPTIMISTIC) {
		const {
			payload: { issueIds, rankBeforeIssueId, rankAfterIssueId, mode },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as IssueRankTransitionUpdateOptimisticAction;

		/**
		 * We won't update the ranking if the moving card is transition only
		 * */

		if (mode === 'TRANSITION_ONLY') {
			return state;
		}

		return rankChildIssues({
			state,
			issueIds,
			rankBeforeIssueId,
			rankAfterIssueId,
		});
	}

	if (action.type === BULK_ISSUE_RANK_OPTIMISTIC) {
		const {
			payload: { issueIds, rankAfterId, rankBeforeId },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as BulkIssueRankOptimisticAction;

		return rankChildIssues({
			state,
			issueIds,
			rankBeforeIssueId: rankBeforeId,
			rankAfterIssueId: rankAfterId,
		});
	}

	if (action.type === SET_ISSUE_CHILDREN_RANK) {
		const {
			payload: { issueIds, rankId, isRankAfter },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as SetIssueChildrenRankAction;

		const availableIssues = intersection(state, issueIds);

		if (!availableIssues.length) {
			return state;
		}

		const newState = difference(state, availableIssues);
		const rankItemIndex = newState.indexOf(rankId);
		const destinationIndex = isRankAfter ? rankItemIndex + 1 : rankItemIndex;

		return splice(newState, destinationIndex, 0, ...issueIds);
	}

	if (action.type === COLUMN_DELETE_REQUEST) {
		const {
			payload: { closestColumn, issuesInColumn },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as ColumnDeleteRequestAction;
		if (!closestColumn) {
			const issueIdsInColumn = new Set(issuesInColumn.map((issue) => issue.id));
			return state.filter((id) => !issueIdsInColumn.has(id));
		}
	}

	return state;
};
