/**
 * @generated SignedSource<<f26e8c3b24c528914919a2150996cd25>>
 * @relayHash f48f330c176a0bc6c4d76bc83cbee746
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ac4dc58065040cf5a8f44c73a0e311e3ddca81d9d89c27266d24353f848557fe

import type { ConcreteRequest, Query } from 'relay-runtime';
export type issueLinksPermissionsData_PermissionsAndIssueLinkTypesQuery$variables = {
  boardIdAri: string;
  cloudId: string;
  isCMP?: boolean | null | undefined;
};
export type issueLinksPermissionsData_PermissionsAndIssueLinkTypesQuery$data = {
  readonly boardScope: {
    readonly currentUser: {
      readonly permissions: ReadonlyArray<AGG$SoftwareBoardPermission | null | undefined>;
    };
  } | null | undefined;
  readonly jira: {
    readonly issueLinkTypes: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly inwards: string;
          readonly outwards: string;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type issueLinksPermissionsData_PermissionsAndIssueLinkTypesQuery = {
  response: issueLinksPermissionsData_PermissionsAndIssueLinkTypesQuery$data;
  variables: issueLinksPermissionsData_PermissionsAndIssueLinkTypesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "boardIdAri"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isCMP"
},
v3 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = {
  "kind": "ScalarField",
  "name": "inwards"
},
v6 = {
  "kind": "ScalarField",
  "name": "outwards"
},
v7 = [
  {
    "kind": "Variable",
    "name": "boardId",
    "variableName": "boardIdAri"
  },
  {
    "kind": "Variable",
    "name": "isCMP",
    "variableName": "isCMP"
  }
],
v8 = {
  "concreteType": "CurrentUser",
  "kind": "LinkedField",
  "name": "currentUser",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "permissions"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "name": "issueLinksPermissionsData_PermissionsAndIssueLinkTypesQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "concreteType": "JiraIssueLinkTypeConnection",
            "kind": "LinkedField",
            "name": "issueLinkTypes",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueLinkTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "JiraIssueLinkType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "RequiredField",
                        "field": (v4/*: any*/),
                        "action": "THROW",
                        "path": "jira.issueLinkTypes.edges.node.id"
                      },
                      {
                        "kind": "RequiredField",
                        "field": (v5/*: any*/),
                        "action": "THROW",
                        "path": "jira.issueLinkTypes.edges.node.inwards"
                      },
                      {
                        "kind": "RequiredField",
                        "field": (v6/*: any*/),
                        "action": "THROW",
                        "path": "jira.issueLinkTypes.edges.node.outwards"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "args": (v7/*: any*/),
        "concreteType": "BoardScope",
        "kind": "LinkedField",
        "name": "boardScope",
        "plural": false,
        "selections": [
          (v8/*: any*/)
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "issueLinksPermissionsData_PermissionsAndIssueLinkTypesQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "concreteType": "JiraIssueLinkTypeConnection",
            "kind": "LinkedField",
            "name": "issueLinkTypes",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueLinkTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "JiraIssueLinkType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "args": (v7/*: any*/),
        "concreteType": "BoardScope",
        "kind": "LinkedField",
        "name": "boardScope",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          (v4/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "id": "ac4dc58065040cf5a8f44c73a0e311e3ddca81d9d89c27266d24353f848557fe",
    "metadata": {},
    "name": "issueLinksPermissionsData_PermissionsAndIssueLinkTypesQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "2b9e58e9f316f1a984e9838714d0ff74";

export default node;
