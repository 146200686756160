import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'portfolio-3-plan-increment-common.ui.error-screens.custom-field.option-not-found.title',
		defaultMessage: 'The custom field "{fieldName}" has changed.',
		description: 'Title for the custom field option not found error screen',
	},
	description: {
		id: 'portfolio-3-plan-increment-common.ui.error-screens.custom-field.option-not-found.description',
		defaultMessage:
			'The custom field "{fieldName}" was used to create your board, but it’s since been edited. In order to use your board, your site administrator needs to update the "{fieldName}" field configuration.',
		description: 'Description for custom field not found error screen',
	},
	imgAlt: {
		id: 'portfolio-3-plan-increment-common.ui.error-screens.custom-field.option-not-found.img-alt',
		defaultMessage: 'Drawing of a magnifying glass with a cross mark',
		description: 'Alt text for the image illustration',
	},
});
