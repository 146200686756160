import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { Box, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

type Props = {
	title?: string;
	hasSeparator?: boolean;
	testId?: string;
	children: ReactNode;
};
export const PanelSection = ({ title, hasSeparator, children, testId }: Props) => (
	<Box paddingBlockEnd={title ? 'space.0' : 'space.050'} testId={testId}>
		{hasSeparator && <Separator />}
		{title && (
			<Box xcss={textStyles}>
				<Text as="strong">{title}</Text>
			</Box>
		)}
		{children}
	</Box>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Separator = styled.div({
	borderTop: `1px solid ${token('color.border', '#091E42')}`,
	marginTop: token('space.075', '6px'),
	marginBottom: token('space.075', '6px'),
});

const textStyles = xcss({ paddingTop: 'space.100', paddingBottom: 'space.100' });
