import 'rxjs/add/observable/throw';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import get from 'lodash/get';
import { type Observable as ObservableType, Observable } from 'rxjs/Observable';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { CardTransitionEntities } from '../../../model/card-transition/card-transition-types.tsx';
import type { ColumnsGQLData, Column } from '../../../model/column/column-types.tsx';
import columnDelete from '../../../rest/column-delete-graphql/index.tsx';
import {
	getCardTransitions,
	transformColumn,
} from '../../board-scope-graphql/transformer/index.tsx';

export type ColumnDeleteData = {
	columns: Column[];
	cardTransitions: CardTransitionEntities | null;
};

export const transform = (projectKey: string, response: ColumnsGQLData): ColumnDeleteData => ({
	columns: response.columns.map(transformColumn),
	cardTransitions: getCardTransitions(projectKey, response.columns),
});

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	contextPath: string,
	boardId: string,
	columnId: number,
	replacementColumnId: number | null,
	projectKey: string,
): ObservableType<ColumnDeleteData> =>
	columnDelete(contextPath, boardId, columnId, replacementColumnId)
		.flatMap((response: ColumnsGQLData) => Observable.of(transform(projectKey, response)))
		.catch((error) => {
			log.safeErrorWithoutCustomerData(
				'board.column.delete.graphql.failure',
				'Failed to delete column via GraphQl',
				{ message: get(error, ['message'], '') || get(error, [0, 'message'], '') },
			);
			return Observable.throw(error);
		});
