import React, { cloneElement, useRef, useEffect, useContext } from 'react';
import { styled as styled2 } from '@compiled/react';
// Remove ThemeProvider on jsw_compiled_migration_tanuki FF cleanup
// eslint-disable-next-line jira/restricted/styled-components, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled, { ThemeProvider } from 'styled-components';
import { token } from '@atlaskit/tokens';
import {
	layout,
	zIndex,
	columnFixedWidth,
	columnMinWidth,
	colors,
	columnWithMarginWidth,
	columnMinWithMarginWidth,
	columnHeaderHeight,
} from '../../common/constants/styles/index.tsx';
import { ColumnTheme } from '../../common/types.tsx';
import { ColumnDropIndicator } from '../../common/ui/column-drop-indicator/index.tsx';
import { columnThemes } from '../../common/ui/column/index.tsx';
import { TransitionZoneIndicatorContainerContext } from '../../common/ui/transition-zone-indicator/index.tsx';
import { useDraggableColumn } from '../../common/ui/use-draggable-column/index.tsx';
import type { SwimlaneColumnHeaderProps } from './types.tsx';

const SwimlaneColumnHeader = (props: SwimlaneColumnHeaderProps) => {
	const {
		id,
		header,
		isDraggable,
		appearance = ColumnTheme.Default,
		draggableIndex,
		flexibleColumns,
	} = props;
	const context = useContext(TransitionZoneIndicatorContainerContext);
	const wrapperRef = useRef<HTMLDivElement | null>(null);

	const transitionZoneIndicatorContainerRef = useRef<HTMLDivElement | null>(null);
	useEffect(() => {
		if (context) {
			context.setRef(id, transitionZoneIndicatorContainerRef);
		}

		return () => {
			if (context) {
				// remove the ref from the context to prevent memory leak
				context.removeRef(id);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { dragPreview, closestEdge } = useDraggableColumn({
		isDraggable,
		elementRef: wrapperRef,
		columnId: id,
		columnIndex: draggableIndex,
		renderPreview: () => header,
	});

	const getTheme = (theme?: ColumnTheme | null) => {
		if (theme && columnThemes[theme]) {
			return columnThemes[theme];
		}

		return columnThemes.default;
	};

	const columnTheme = getTheme(appearance);

	const handleOnMouseDown = () => {
		// Clear any text selection to prevent unexpected dragging behaviour

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.getSelection()?.removeAllRanges();
	};

	return (
		<StyledWrapper
			ref={wrapperRef}
			flexibleColumns={flexibleColumns}
			onMouseDown={handleOnMouseDown}
		>
			<StyledDraggable flexibleColumns={flexibleColumns}>
				<ThemeProvider theme={columnTheme}>
					{header && cloneElement(header, { appearance })}
				</ThemeProvider>
				<TransitionZoneIndicatorContainer ref={transitionZoneIndicatorContainerRef} />
			</StyledDraggable>

			{closestEdge && <ColumnDropIndicator edge={closestEdge} />}
			{dragPreview}
		</StyledWrapper>
	);
};

export default SwimlaneColumnHeader;

type StyledWrapperProps = { flexibleColumns?: boolean };

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled,@atlaskit/ui-styling-standard/no-exported-styles -- To migrate as part of go/ui-styling-standard
export const StyledWrapper = styled2.div<StyledWrapperProps>({
	position: 'relative',
	display: 'flex',
	flexDirection: 'column',
	alignSelf: 'auto',
	flex: '1 1 auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	minWidth: `${({ flexibleColumns }: StyledWrapperProps) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		flexibleColumns ? columnMinWithMarginWidth : columnWithMarginWidth}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	maxWidth: ({ flexibleColumns }: StyledWrapperProps) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		flexibleColumns ? '100%' : `${columnWithMarginWidth}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledDraggable = styled2.div<{ isDragging?: boolean; flexibleColumns?: boolean }>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) => ({
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		borderRadius: `${layout.cardListRadius}px`,

		marginLeft: token('space.050', '0.25rem'),

		marginRight: token('space.050', '0.25rem'),
		display: 'flex',
		flexDirection: 'column',
		alignSelf: 'auto',
		flex: '1 1 auto',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		minWidth: `${props.flexibleColumns ? columnMinWidth : columnFixedWidth}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		maxWidth: props.flexibleColumns ? '100%' : `${columnFixedWidth}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		boxShadow: props.isDragging ? `0px 3px 3px 0px ${colors.shadow}` : '',
	}),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TransitionZoneIndicatorContainer = styled2.div({
	position: 'absolute',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.transitionZoneIndicator,

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	top: `${columnHeaderHeight + 3}px`,
	left: 0,
	right: 0,
});

// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line  @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SwimlaneColumnHeaderContainer = styled.div<{ flexibleColumns?: boolean }>`
	display: ${({ flexibleColumns }) => (flexibleColumns ? 'grid' : 'flex')};
	grid-template-columns: ${({ flexibleColumns }) => {
		if (!flexibleColumns) {
			return 'initial';
		}
		return `repeat(auto-fit,minmax(${columnMinWidth}px,1fr))`;
	}};
	grid-auto-flow: column;
	flex-flow: row nowrap;
	position: sticky;
	top: 0;
	z-index: ${zIndex.stickyHeaders};
	width: ${({ flexibleColumns }) => (flexibleColumns ? '100%' : 'auto')};
`;
