import React from 'react';
import { styled } from '@compiled/react';
import DOMPurify from 'dompurify';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

export type Props = { field: { html?: string; id: string; label: string } };
type PropsCustomCardFieldContainer = {
	isNone?: boolean;
	disablePointerEvents?: boolean;
};

export const HtmlCardCustomFieldContent = ({ field }: Props) => {
	const stopIssueModalOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (field.label === 'Development') event.stopPropagation();
	};

	// filter out jQuery LiveStamp hooks which replace desired time html with a relative date in CMP UIF Kanban
	const htmlWithJQueryLiveStampClassesRemoved =
		field?.html && field.html.replace('livestamp allow-future', '');

	return field.html ? (
		<CustomCardFieldContainer
			data-testid="platform-card.common.ui.custom-fields.card-custom-field.html-card-custom-field-content.html-field"
			data-issuefieldid={field.id}
			dangerouslySetInnerHTML={{
				__html: DOMPurify.sanitize(htmlWithJQueryLiveStampClassesRemoved ?? ''),
			}}
			disablePointerEvents={field.id === 'thumbnail'}
			onClick={stopIssueModalOpen}
		/>
	) : null;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
export const CustomCardFieldContainer = styled.div<PropsCustomCardFieldContainer>({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	maxWidth: '100%',
	marginRight: 'auto', // avoid taking up full width so card's empty area is clickable
	textOverflow: 'ellipsis',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N100),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	fontStyle: (props) => (props.isNone ? 'italic' : 'inherit'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	opacity: (props) => (props.isNone ? 0.8 : 1),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	pointerEvents: (props) => (props.disablePointerEvents ? 'none' : 'auto'),

	// development field custom HTML overrides to make sure it looks good
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'div.fusion-devsummary-cf': {
		// ensure the status text takes up the right amount of space
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'.fusion-development': {
			maxWidth: '100%',
			minWidth: 'auto',
		},

		// ensure the icon spacing is correct
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'div.fusion-builds-deployments': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'span.builds, span.deployments': {
				marginLeft: token('space.050', '4px'),
				width: 'auto', // have it take the same amount of space as the actual icon
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'span.deployments': {
				float: 'none', // so it doesn't collapse to the next line for compact cards
			},
		},

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'.aui-icon-small, .aui-icon-large': {
			verticalAlign: 'middle',
		},
	},

	// In case custom HTML fields render <p> tags, in order for them to
	// render ellipsis we need to get them to `overflow: hidden`. If the
	// container is what clips the text no ellipsis is rendered.`
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	p: {
		maxWidth: '100%',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'img[data-issuefieldid="thumbnail"]': {
		width: 'auto',
		height: 'auto',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	img: {
		maxWidth: 200,
		maxHeight: 100,
	},

	// For Sum progress field
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'table[id^="tt_apb_graph_outer_"]': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		td: {
			paddingBlock: 0,
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		tbody: {
			border: 0,
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		img: {
			display: 'block',
			height: '4px',
			width: '100%',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'td.tt_spacer': {
			padding: 0,
			width: '1px',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'td.tt_graph_percentage': {
			minWidth: 'auto',
			// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
			paddingRight: '3px',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'tr.tt_graph': {
			height: '4px',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'[id^="tt_apb_graph_inner"]': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			td: {
				padding: 0,
			},
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'table.tt_graph td.tt_spacer img': {
			width: '1px',
		},
	},
});
