import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	disabledButtonHelperHeading: {
		id: 'software.main.header.complete-sprint-button.complete-sprint-button-disabled-info-heading',
		defaultMessage: "You don't have 'Manage sprints' permission",
		description:
			'Popup heading for the disabled button helper when the sprint action button is "disabled" due to lack of permissions',
	},
	disabledButtonHelperBody: {
		id: 'software.main.header.complete-sprint-button.complete-sprint-button-disabled-info-body',
		defaultMessage:
			'Ask an administrator to grant you the permission for all projects in the board filter.',
		description:
			'Popup body text to display when the sprint action button is "disabled" due to lack of permissions',
	},
});
