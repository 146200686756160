import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { InvitePeopleModalProps } from './src/ui/types.tsx';

const LazyComponent = lazyForPaint(
	() => import(/* webpackChunkName: "async-project-invite-people-modal" */ './src/index'),
);

export const AsyncCoreInvitesProjectDialogComponent = (props: InvitePeopleModalProps) => (
	<JSErrorBoundary
		id="async-project-invite-people-modal"
		packageName="jira-project-invite-people-modal"
		teamName="groot-growth"
	>
		<Placeholder name="project-invite-people-modal" fallback={null}>
			<LazyComponent {...props} />
		</Placeholder>
	</JSErrorBoundary>
);

export default AsyncCoreInvitesProjectDialogComponent;
