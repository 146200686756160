import React, { useEffect } from 'react';
import { LinkButton } from '@atlaskit/button/new';
import EmptyState from '@atlaskit/empty-state';
import { Flex } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import MapIllustration from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/objects/components/map/index.tsx';
import { getAkEmptyStateRenderImageFn } from '@atlassian/jira-illustrations/src/ui/helpers/ak-empty-state/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { isEmbedSmartLinkView } from '@atlassian/jira-portfolio-3-common/src/window/index.tsx';
import { advancedRoadmapsLastVisitedProgramBoardIdResource } from '@atlassian/jira-router-resources-advanced-roadmaps-plan/src/services/index.tsx';
import { useResource } from '@atlassian/react-resource-router';
import { getPlanId, urls } from '../../../common/utils.tsx';
import { useIncrementsLink } from '../../../hooks/use-increments-link/index.tsx';
import messages from './messages.tsx';

const ProgramBoard404 = () => {
	const { formatMessage } = useIntl();
	const planId = getPlanId();
	// If the user lands on the 404 page we should invalidate the cache of the last visited
	// program board and refetch a valid one from the server so they are routed to the appropriate
	// program board when next accessing /program route
	const { refresh: refetchLastViewedProgramBoardId } = useResource(
		advancedRoadmapsLastVisitedProgramBoardIdResource,
	);

	const incrementsLink = useIncrementsLink();

	useEffect(() => {
		refetchLastViewedProgramBoardId();
	}, [refetchLastViewedProgramBoardId]);

	const renderPrimaryAction = () => {
		if (isEmbedSmartLinkView() && fg('smart_links_for_plans')) {
			return null;
		}
		if (planId) {
			return (
				<LinkButton href={incrementsLink} appearance="primary">
					{formatMessage(messages.viewCurrentBoard)}
				</LinkButton>
			);
		}

		return (
			<LinkButton href={urls.managePortfolio} appearance="primary">
				{formatMessage(messages.viewAllPlans)}
			</LinkButton>
		);
	};

	return (
		<Flex alignItems="center" justifyContent="center">
			<EmptyState
				renderImage={getAkEmptyStateRenderImageFn(MapIllustration)}
				header={formatMessage(messages.title404)}
				description={formatMessage(messages.description404)}
				primaryAction={renderPrimaryAction()}
			/>
		</Flex>
	);
};

export default ProgramBoard404;
