export type PartialIssue = {
	id: number;
	key: string;
	parentId: number | null;
};

export type ProjectId = string;

export type Version = {
	id: number;
	name: string;
	description: string;
	sequence: number;
	released: boolean;
};

export type VersionId = string;

export type VersionMenuItem = {
	id: VersionId;
	name: string;
	projectKey: string;
};

export type VersionMenuItemEntities = Record<VersionId, VersionMenuItem>;

export type VersionsPerProject = Record<ProjectId, Version[]>;

export type VersionData = {
	versionsPerProject: VersionsPerProject;
	canCreateVersion: boolean;
};

export type VersionResponse = {
	versionData: VersionData;
};

export const ENABLED = 'ENABLED' as const;
export const NO_PERMISSION = 'NO_PERMISSION' as const;
export const NO_AVAILABLE_ISSUE_OR_VERSION = 'NO_AVAILABLE_ISSUE_OR_VERSION' as const;

export type FilteredVersionsType = {
	releaseState: typeof ENABLED | typeof NO_PERMISSION | typeof NO_AVAILABLE_ISSUE_OR_VERSION;
	versionMenuItems: VersionMenuItem[];
	hasIssuesWithoutVersion: boolean;
	lastColumnName: string;
};
