import type { CloneStatusType } from './types.tsx';

export const CloneStatus = {
	ENQUEUED: 'ENQUEUED',
	RUNNING: 'RUNNING',
	COMPLETE: 'COMPLETE',
	FAILED: 'FAILED',
	CANCEL_REQUESTED: 'CANCEL_REQUESTED',
	CANCELLED: 'CANCELLED',
	DEAD: 'DEAD',
} as const;

export const cloneStatusIsFailed = (status: CloneStatusType): boolean => {
	switch (status) {
		case CloneStatus.ENQUEUED:
		case CloneStatus.RUNNING:
		case CloneStatus.COMPLETE:
		case CloneStatus.CANCEL_REQUESTED:
		case CloneStatus.CANCELLED:
			return false;
		case CloneStatus.FAILED:
		case CloneStatus.DEAD:
		default:
			return true;
	}
};

export const cloneStatusIsCancelled = (status: CloneStatusType): boolean =>
	status === CloneStatus.CANCELLED;

export const cloneStatusIsComplete = (status: CloneStatusType): boolean =>
	status === CloneStatus.COMPLETE;

export const cloneStatusIsInProgress = (status: CloneStatusType): boolean => {
	switch (status) {
		case CloneStatus.ENQUEUED:
		case CloneStatus.CANCEL_REQUESTED:
		case CloneStatus.RUNNING:
			return true;
		default:
			return false;
	}
};
