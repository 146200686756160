export const DependencyFilterOption = {
	OFF_TRACK: 'off-track',
	ON_TRACK: 'on-track',
};

type DependencyFilterOptionType =
	(typeof DependencyFilterOption)[keyof typeof DependencyFilterOption];

export type Props = {
	isOpen?: boolean;
	onChange?: (options: DependencyFilterOptionType[]) => void;
	selectedValues?: DependencyFilterOptionType[];
};
