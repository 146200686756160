import { useEffect } from 'react';

// needed to create a new ResizeObserver instance as the @atlassian/jira-react-use-resize-observer does not update the ref, due to the fade-in or that we don't render the component immediately, the ResizeObserver is not observing the correct element until we read from local storage
// copied from src/packages/business/shared/utils/resize-observer/src/index.tsx
export const useResizeObserver = (element: HTMLElement | null, callback: () => void) => {
	useEffect(() => {
		let resizeObserver: ResizeObserver;
		if (element && typeof ResizeObserver !== 'undefined') {
			resizeObserver = new ResizeObserver(callback);
			resizeObserver.observe(element);
		}

		return () => {
			if (resizeObserver) {
				resizeObserver.disconnect();
			}
		};
	}, [element, callback]);

	return null;
};
