import { useEffect, useMemo } from 'react';
import {
	useFieldValue,
	useFieldsValuesActions,
} from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import {
	getParentShape,
	getHierarchyShape,
} from '@atlassian/jira-issue-field-parent-switcher/src/common/utils.tsx';
import {
	PARENT_TYPE,
	HIERARCHY_LEVEL_ABOVE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { toIssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import { useDiffCheck } from '../../../../common/hooks/use-diff-check/index.tsx';
import type { IssueParentId } from '../../../../model/issue/issue-types.tsx';
import { setIssueParent } from '../../../../state/actions/issue/update/index.tsx';
import { useBoardActionCreator, useBoardSelector } from '../../../../state/index.tsx';
import { makeIssueParentByIdSelector } from '../../../../state/selectors/issue-parent/index.tsx';
import { useIsIncrementPlanningBoard } from '../../../../state/state-hooks/capabilities/index.tsx';
import type { FieldSyncParentProps } from './types.tsx';

export const FieldSyncParent = ({
	issue: { id, key, parentId },
	projectKey,
}: FieldSyncParentProps) => {
	const parentIdFromBoard = useMemo(() => (parentId ? String(parentId) : null), [parentId]);

	const issueParentFromBoard = useBoardSelector((state) =>
		makeIssueParentByIdSelector(state)(parentIdFromBoard),
	);
	const setParentInBoard = useBoardActionCreator(
		(issueId: IssueId, issueParentId: IssueParentId | null) =>
			setIssueParent(issueId, issueParentId),
	);
	const isIPBoard = useIsIncrementPlanningBoard();
	// In the increment planning board, scenario issue keys do not yet have a numeric identifier as
	// they have not been created in Jira yet. In this case, the key is equal to the projectKey,
	// and thus not unique. Instead, we pass the issue id to the field config service (as it
	// requires a unique identifier for each issue).
	const uniqueKey = isIPBoard && key === projectKey ? `${id}` : key;
	const [parentFromIssueField, { setFieldValue: setParentInExtractedFields }] = useFieldValue({
		issueKey: uniqueKey,
		fieldKey: PARENT_TYPE,
	});

	const [, { setFieldValue: setValueInExtractedField }] = useFieldsValuesActions();

	const parentIdFromIssueField = useMemo(
		() => parentFromIssueField?.id || null,
		[parentFromIssueField?.id],
	);

	const parentFromBoardChanged = useDiffCheck([parentIdFromBoard]);
	const parentFromIssueFieldChanged = useDiffCheck([parentIdFromIssueField]);

	// parent field should only sync on increment planning board for story level issues
	const issueIsParentInIPBoard =
		(parentIdFromBoard === id || parentIdFromIssueField === id) && isIPBoard;

	const storesNeedToSync = parentIdFromBoard !== parentIdFromIssueField && !issueIsParentInIPBoard;

	// PARENT SYNC: BOARD -> EXTRACTED FIELDS
	useEffect(() => {
		if (parentFromBoardChanged && storesNeedToSync) {
			setParentInExtractedFields(
				uniqueKey,
				PARENT_TYPE,
				issueParentFromBoard
					? getParentShape(
							toIssueId(String(issueParentFromBoard.id)),
							issueParentFromBoard.key,
							issueParentFromBoard.summary,
							{
								id: issueParentFromBoard.issueType.id,
								name: issueParentFromBoard.issueType.name,
								iconUrl: issueParentFromBoard.issueType.iconUrl,
							},
							issueParentFromBoard.color,
						)
					: null,
			);

			if (issueParentFromBoard) {
				setValueInExtractedField(
					uniqueKey,
					HIERARCHY_LEVEL_ABOVE,
					getHierarchyShape({
						id: issueParentFromBoard.issueType.id,
						name: issueParentFromBoard.issueType.name,
						iconUrl: issueParentFromBoard.issueType.iconUrl,
					}),
				);
			}
		}
	}, [
		issueParentFromBoard,
		parentFromBoardChanged,
		setValueInExtractedField,
		setParentInExtractedFields,
		storesNeedToSync,
		uniqueKey,
	]);

	// PARENT SYNC: EXTRACTED FIELDS -> BOARD
	useEffect(() => {
		if (!parentFromBoardChanged && parentFromIssueFieldChanged && storesNeedToSync) {
			setParentInBoard(id, parentFromIssueField?.id || null);
		}
	}, [
		id,
		parentFromBoardChanged,
		parentFromIssueField?.id,
		parentFromIssueFieldChanged,
		setParentInBoard,
		storesNeedToSync,
	]);

	return null;
};
