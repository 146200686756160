import PullRequestIcon from '@atlaskit/icon/glyph/bitbucket/pullrequests';
import CalendarIcon from '@atlaskit/icon/glyph/calendar';
import IssueIcon from '@atlaskit/icon/glyph/issue';
import UploadIcon from '@atlaskit/icon/glyph/upload';
import PlayIcon from '@atlaskit/icon/glyph/vid-play';
import { WebhookIcon } from './assets/webhook.tsx';
import messages from './messages.tsx';
import { filters, type MenuItem } from './types.tsx';

export const boardMenuItems: MenuItem[] = [
	{
		// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
		Icon: IssueIcon,
		title: messages.issueTitle,
		description: messages.issueDescription,
		filter: filters.CATEGORY_ISSUE,
	},
	{
		// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
		Icon: PullRequestIcon,
		title: messages.devopsTitle,
		description: messages.devopsDescription,
		filter: filters.CATEGORY_DEVOPS,
	},
	{
		// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
		Icon: UploadIcon,
		title: messages.devopsBuildAndDeployTitle,
		description: messages.devopsBuildAndDeployDescription,
		filter: filters.CATEGORY_DEVOPS,
	},
	{
		Icon: WebhookIcon,
		title: messages.integrationTitle,
		description: messages.integrationDescription,
		filter: filters.CATEGORY_INTEGRATION,
	},
	{
		// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
		Icon: CalendarIcon,
		title: messages.scheduleTitle,
		description: messages.scheduleDescription,
		filter: filters.CATEGORY_SCHEDULE,
	},
	{
		// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
		Icon: PlayIcon,
		title: messages.allTriggersTitle,
		description: messages.allTriggersDescription,
		filter: filters.CATEGORY_ALL,
	},
];
