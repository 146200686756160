import isNil from 'lodash/isNil';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/from';
import 'rxjs/add/operator/catch';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { IP_BOARD_STATUS_FIELD_ID } from '../../../common/constants.tsx';
import {
	type IssueIncrementPlanningUpdateAction,
	ISSUE_INCREMENT_PLANNING_UPDATE,
	issueIncrementPlanningUpdateFailed,
	issueIncrementPlanningUpdateSuccess,
} from '../../../state/actions/issue/update/index.tsx';
import { getIsIncrementPlanningBoard } from '../../../state/selectors/software/software-selectors.tsx';
import type { ActionsObservable, BoardDependencies, MiddlewareAPI } from '../../../state/types.tsx';

/**
 * this epic is used for inline updating the issue's parent, story point, status and summary in the IP board.
 * @param actions
 * @param store
 * @param customRequestHandlers
 * @returns
 */
export function issueIncrementPlanningUpdateEpic(
	actions: ActionsObservable,
	store: MiddlewareAPI,
	{ customRequestHandlers }: BoardDependencies,
) {
	return actions
		.ofType(ISSUE_INCREMENT_PLANNING_UPDATE)
		.mergeMap((action: IssueIncrementPlanningUpdateAction) => {
			const isIncrementPlanningBoard = getIsIncrementPlanningBoard(store.getState());
			if (isIncrementPlanningBoard) {
				const {
					payload: { issueId, fieldValue, fieldId },
				} = action;

				const success = () => Observable.of(issueIncrementPlanningUpdateSuccess());

				const error = (err?: Error) => {
					log.safeErrorWithoutCustomerData(
						'issue.increment.planning.update',
						'Failed to update IP board issue',
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						err as Error,
					);
					return Observable.of(issueIncrementPlanningUpdateFailed());
				};

				if (!customRequestHandlers || !customRequestHandlers?.updateIssue) {
					return error(new Error('no request handler found for update issue'));
				}
				if (fg('issue_cards_in_program_board')) {
					let props: Record<string, unknown> = {};
					if (fieldId === IP_BOARD_STATUS_FIELD_ID) {
						if (
							typeof fieldValue === 'object' &&
							!isNil(fieldValue) &&
							!isNil(fieldValue.statusId) &&
							!isNil(fieldValue.statusTransitionId)
						) {
							props = {
								status: `${fieldValue.statusId}`,
								statusTransition: `${fieldValue.statusTransitionId}`,
							};
						} else {
							return error(
								new Error(
									'must set the correct fieldValue when change the issue status in IP board',
								),
							);
						}
					} else {
						props = {
							[fieldId]: fieldValue,
						};
					}
					return Observable.from(
						customRequestHandlers.updateIssue({ ...props, id: String(issueId) }),
					)
						.flatMap(() => success())
						.catch((err) => error(err));
				}
				// when clean up the issue_cards_in_program_board, remove this part until to the end
				return Observable.from(
					customRequestHandlers.updateIssue({
						id: String(issueId),
						[fieldId]: fieldValue,
					}),
				)
					.flatMap(() => success())
					.catch((err) => error(err));
			}
			return Observable.empty<never>();
		});
}
