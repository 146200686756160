import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	showCover: {
		id: 'software.main.header.menu.card-media-item.show-cover',
		defaultMessage: 'Show cover images',
		description: '',
	},
	hideCover: {
		id: 'software.main.header.menu.card-media-item.hide-cover',
		defaultMessage: 'Hide cover images',
		description: '',
	},
});
