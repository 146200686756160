export const SET_IS_FAVOURITE = 'state.actions.board.board-favourite.SET_IS_FAVOURITE' as const;

export type SetIsFavouriteAction = {
	type: typeof SET_IS_FAVOURITE;
	payload: {
		isFavourite: boolean;
	};
};

export const setIsFavourite = (isFavourite: boolean): SetIsFavouriteAction => ({
	type: SET_IS_FAVOURITE,
	payload: {
		isFavourite,
	},
});

export type Action = SetIsFavouriteAction;
