import React, { useRef } from 'react';
import type { OpenContextMenuRef } from './common/types.tsx';
import type { PublicContextMenuProps } from './types.tsx';
import { ChildrenWrapperWithAnalytics } from './ui/children-wrapper/analytics-wrapper/index.tsx';
import { useOnContextMenu } from './ui/children-wrapper/use-on-context-menu/index.tsx';
import { ContextMenu } from './ui/ContextMenu.tsx';

export const SoftwareContextMenu = (props: PublicContextMenuProps) => {
	const openContextMenuRef = useRef<OpenContextMenuRef>(null);
	const childrenRef = useRef<HTMLDivElement>(null);
	const { onContextMenu } = useOnContextMenu({ childrenRef, openContextMenuRef });

	return (
		<>
			<ChildrenWrapperWithAnalytics onChildrenContextMenu={onContextMenu} ref={childrenRef}>
				{props.children}
			</ChildrenWrapperWithAnalytics>
			<ContextMenu {...props} openContextMenuRef={openContextMenuRef} />
		</>
	);
};
