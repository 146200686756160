import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/mergeMap';
import {
	openIssueModal,
	OPEN_ISSUE_MODAL_DEBOUNCED,
	type OpenIssueModalDebouncedAction,
} from '../../state/actions/issue/modal/index.tsx';
import type { ActionsObservable } from '../../state/types.tsx';

export const detailViewOpenDebouncedEpic = (action$: ActionsObservable) =>
	action$
		.ofType(OPEN_ISSUE_MODAL_DEBOUNCED)
		.debounceTime(500)
		.mergeMap((action: OpenIssueModalDebouncedAction) =>
			Observable.of(openIssueModal(action.payload.issueKey, action.payload.externalAction)),
		);
