import memoize from 'lodash/memoize';
import {
	type MenuNode,
	MenuNodeType,
} from '@atlassian/jira-software-context-menu/src/common/types.tsx';
import { SEPARATOR_MENU_ID } from '../../constants.tsx';

export const createSeparator = memoize(
	(id: string): MenuNode => ({
		id: `${SEPARATOR_MENU_ID}/${id}`,
		type: MenuNodeType.Separator,
	}),
);
