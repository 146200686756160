import { refreshIssueRequest } from '../common/actions/issue-fetch-actions.tsx';
import { addCommentFormExpand } from './comment-actions.tsx';

// REFRESH_ISSUE_ACTION
export const REFRESH_ISSUE = 'REFRESH_ISSUE' as const;

export type ExternalRefreshType = 'hard';
export type ExternalRefreshIssueAction = {
	type: typeof REFRESH_ISSUE;
	payload: {
		type?: ExternalRefreshType;
	};
};

export const externalRefreshIssueAction = (
	type?: ExternalRefreshType,
): ExternalRefreshIssueAction => ({
	type: REFRESH_ISSUE,
	payload: {
		type,
	},
});

// EDIT_FIELD
export const EDIT_FIELD = 'EDIT_FIELD' as const;

export type ExternalEditFieldAction = {
	type: typeof EDIT_FIELD;
	payload: {
		fieldId: string;
	};
};

export const externalEditFieldAction = (fieldId: string): ExternalEditFieldAction => ({
	type: EDIT_FIELD,
	payload: { fieldId },
});

// ADD_COMMENT
export const ADD_COMMENT = 'ADD_COMMENT' as const;

export type ExternalAddCommentAction = {
	type: typeof ADD_COMMENT;
};

export const externalAddCommentAction = (): ExternalAddCommentAction => ({
	type: ADD_COMMENT,
});

// ASSIGN_TO_ME
export const ASSIGN_TO_ME = 'ASSIGN_TO_ME' as const;

export type ExternalAssignToMeAction = {
	type: typeof ASSIGN_TO_ME;
};

export const externalAssignToMeAction = (): ExternalAssignToMeAction => ({
	type: ASSIGN_TO_ME,
});

// FIELD_EDIT_REQUEST_EXTERNAL
export const FIELD_EDIT_REQUEST_EXTERNAL = 'FIELD_EDIT_REQUEST_EXTERNAL' as const;

export const fieldEditRequestFromExternal = (fieldId: string) => ({
	type: FIELD_EDIT_REQUEST_EXTERNAL,
	payload: {
		fieldId,
	},
});

export type FieldEditRequestFromExternal = ReturnType<typeof fieldEditRequestFromExternal>;

// ASSIGN_TO_ME_EXTERNAL
export const ASSIGN_TO_ME_EXTERNAL = 'ASSIGN_TO_ME_EXTERNAL' as const;

export const assignToMeFromExternal = () => ({
	type: ASSIGN_TO_ME_EXTERNAL,
});

export type AssignToMeFromExternal = ReturnType<typeof assignToMeFromExternal>;

export type ExternalAction =
	| ExternalRefreshIssueAction
	| ExternalAddCommentAction
	| ExternalAssignToMeAction
	| ExternalEditFieldAction;

export const transformExternalAction = (action: ExternalAction) => {
	switch (action.type) {
		case ADD_COMMENT:
			return addCommentFormExpand();

		case ASSIGN_TO_ME:
			return assignToMeFromExternal();

		case EDIT_FIELD:
			return fieldEditRequestFromExternal(action.payload.fieldId);

		case REFRESH_ISSUE:
			return refreshIssueRequest({
				// todo: swap between soft reload and hard (aka on-demand)
				reason: 'ON_DEMAND',
			});

		default:
			// @ts-expect-error - TS2339 - Property 'type' does not exist on type 'never'.
			throw new Error(`Unhandled external action type: ${action.type}`);
	}
};

export type TransformExternalAction = ReturnType<typeof transformExternalAction>;

export type DispatchExternalAction = (arg1: ExternalAction) => void;

export type Action =
	| ExternalAction
	| FieldEditRequestFromExternal
	| AssignToMeFromExternal
	| TransformExternalAction;
