import type { ApolloError } from 'apollo-client';
import type { GraphQLError } from 'graphql';

export const isExpectedGraphQLError = (error: ApolloError): boolean =>
	Boolean(
		error.graphQLErrors?.length > 0 &&
			error.graphQLErrors?.every((graphQLError: GraphQLError) => {
				const statusCode = graphQLError?.extensions?.statusCode;
				return Boolean(statusCode);
			}),
	);
