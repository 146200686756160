import React, { useMemo, useState } from 'react';
import InlineEdit from '@atlaskit/inline-edit';
import { Inline, Box, xcss, Bleed, Text } from '@atlaskit/primitives';
import Textfield from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { UNSCHEDULED_COLUMN_ID } from '@atlassian/jira-common-constants/src/column-types.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { IP_BOARD_DAYS_PLANNING_UNIT } from '../../common/constants.tsx';
import commonMessages from '../../common/messages.tsx';
import { convertCapacity, getCapacityUnitMessage } from '../../common/utils.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const IterationColumnHeader = ({
	columnId,
	totalCapacity,
	usedCapacity,
	defaultCapacity,
	planningConfig: { planningUnit, workingHoursPerDay },
	onChangeCapacity,
	isReadOnly,
	hideTotalCapacity,
	visibleIssues,
	totalIssues,
}: Props) => {
	const { formatMessage } = useIntl();
	const [isEditViewMode, setIsEditViewMode] = useState(false);
	const total = convertCapacity(totalCapacity ?? defaultCapacity, planningUnit, workingHoursPerDay);
	const unitLabel = getCapacityUnitMessage(planningUnit, formatMessage, total);
	const showOverCapacityStyle = usedCapacity > total;
	const visibleIssuesMsg = useMemo(() => {
		if (visibleIssues !== totalIssues) {
			return formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.visibleIssueCountIssueTermRefresh
					: messages.visibleIssueCount,
				{ visibleIssues, totalIssues },
			);
		}
		return formatMessage(
			expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? messages.totalIssueCountIssueTermRefresh
				: messages.totalIssueCount,
			{ totalIssues },
		);
	}, [visibleIssues, totalIssues, formatMessage]);
	let innerContent;
	let tooltipContent = (
		<Inline alignInline="center" shouldWrap space="space.025">
			<Box>{usedCapacity}</Box>
			<Box>/</Box>
			<Box>{total}</Box>
			<Box>{unitLabel}</Box>
			<Box>({visibleIssuesMsg})</Box>
		</Inline>
	);
	const unitContent = (
		<Box>
			<Text
				color="inherit"
				testId="portfolio-3-plan-increment-common.ui.iteration-column-header.capacity-unit"
			>
				{unitLabel}
			</Text>
		</Box>
	);
	const usedCapacityContent = (
		<Box xcss={[capacityWrapperStyle, usedCapacityWrapperStyle]}>
			<Text
				color="inherit"
				testId="portfolio-3-plan-increment-common.ui.iteration-column-header.allocated-capacity"
			>
				{usedCapacity}
			</Text>
		</Box>
	);
	if (columnId === UNSCHEDULED_COLUMN_ID || hideTotalCapacity) {
		tooltipContent = (
			<Inline alignInline="center" shouldWrap space="space.025">
				<Box>{usedCapacity}</Box>
				<Box>{unitLabel}</Box>
				<Box>({visibleIssuesMsg})</Box>
			</Inline>
		);
		innerContent = (
			<Inline alignBlock="center" xcss={capacityContainerStyles}>
				<Box xcss={capacityWrapperStyle}>
					<Text
						color="inherit"
						testId="portfolio-3-plan-increment-common.ui.iteration-column-header.allocated-capacity"
					>
						{usedCapacity}
					</Text>
				</Box>
				{unitContent}
			</Inline>
		);
	} else if (isReadOnly) {
		innerContent = (
			<Inline
				alignBlock="center"
				xcss={
					showOverCapacityStyle
						? [overCapacityStyles, capacityContainerStyles]
						: capacityContainerStyles
				}
			>
				{usedCapacityContent}
				<Box>/</Box>
				<Box xcss={[capacityWrapperStyle]}>
					<Text
						color="inherit"
						testId="portfolio-3-plan-increment-common.ui.iteration-column-header.total-capacity-read-only"
					>
						{total}
					</Text>
				</Box>
				{unitContent}
			</Inline>
		);
	} else {
		innerContent = (
			<>
				<Inline
					alignBlock="center"
					xcss={
						showOverCapacityStyle
							? [overCapacityStyles, capacityContainerStyles]
							: capacityContainerStyles
					}
				>
					{usedCapacityContent}
					<Box>/</Box>
					<Bleed xcss={inlineEditStyles}>
						<InlineEdit
							defaultValue={total}
							keepEditViewOpenOnBlur
							editView={({ errorMessage, ...fieldProps }) => (
								<Textfield
									{...fieldProps}
									testId="portfolio-3-plan-increment-common.ui.iteration-column-header.total-capacity-edit"
									min={0}
									max={99999}
									width="60"
									aria-label={formatMessage(commonMessages.editCapacityLabel)}
									isCompact
									autoFocus
									type="number"
									{...(planningUnit === IP_BOARD_DAYS_PLANNING_UNIT && { step: 0.1 })}
								/>
							)}
							readView={() => (
								<Box
									xcss={
										showOverCapacityStyle
											? [readViewContainerStyles, overCapacityStyles, capacityWrapperStyle]
											: [readViewContainerStyles, capacityWrapperStyle]
									}
								>
									<Text
										color="inherit"
										testId="portfolio-3-plan-increment-common.ui.iteration-column-header.total-capacity-read-only"
									>
										{total}
									</Text>
								</Box>
							)}
							onEdit={() => {
								setIsEditViewMode(true);
							}}
							onCancel={() => {
								setIsEditViewMode(false);
							}}
							onConfirm={(value: string) => {
								setIsEditViewMode(false);
								onChangeCapacity(value);
							}}
							editButtonLabel={formatMessage(commonMessages.editCapacityLabel)}
							confirmButtonLabel={formatMessage(commonMessages.confirmButtonLabel)}
							cancelButtonLabel={formatMessage(commonMessages.cancelButtonLabel)}
						/>
					</Bleed>
					{unitContent}
				</Inline>
			</>
		);
	}

	return (
		<Tooltip content={isEditViewMode ? null : tooltipContent} position="top">
			<Inline
				alignBlock="center"
				space="space.100"
				xcss={[columnHeaderWrapper, storyPointCapacityAndIssueCountStyles]}
			>
				{innerContent}
				<Box xcss={issueCountStyles}>({visibleIssuesMsg})</Box>
			</Inline>
		</Tooltip>
	);
};

const columnHeaderWrapper = xcss({
	backgroundColor: 'elevation.surface.sunken',
	borderTopLeftRadius: 'border.radius.100',
	borderTopRightRadius: 'border.radius.100',
	paddingBlock: 'space.050',
});

const storyPointCapacityAndIssueCountStyles = xcss({
	paddingInline: 'space.200',
});

const capacityContainerStyles = xcss({
	whiteSpace: 'nowrap',
	flexShrink: 0,
	maxWidth: '100%',
	display: 'flex',
	gap: 'space.050',
	lineHeight: '20px',
});

const readViewContainerStyles = xcss({
	lineHeight: '20px',
});

const capacityWrapperStyle = xcss({
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
});

const usedCapacityWrapperStyle = xcss({
	border: `${token('space.025')} solid transparent`,
});

const overCapacityStyles = xcss({
	color: 'color.text.warning',
});

const inlineEditStyles = xcss({
	marginBlockStart: 'space.negative.100',
	flexShrink: 0,
});

const issueCountStyles = xcss({
	color: 'color.text.subtlest',
	paddingTop: 'space.075',
	paddingBottom: 'space.075',
	height: token('space.400', '32px'),
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

export default IterationColumnHeader;
