import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import {
	fireOperationalAnalytics,
	fireTrackAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';
import { scheduleAfterRender } from '@atlassian/jira-software-react-scheduler/src/index.tsx';
import {
	stopCustomFilterInteraction,
	cancelCustomFilterInteraction,
} from '../../../../services/performance/interaction-metric-custom-filter.tsx';
import type {
	FilteredCardFailureAction,
	FilteredCardSuccessAction,
	RefilterFailureAction,
	RefilterSuccessAction,
} from '../../../actions/card/filtered-cards/index.tsx';
import type { State } from '../../../reducers/types.tsx';
import { getIsCMPBoard } from '../../../selectors/software/software-selectors.tsx';

export const setCustomFiltersPerformance =
	() => (preState: State, state: State, action: FilteredCardSuccessAction) => {
		const {
			meta: { numCustomFiltersApplied, numIssueIds },
		} = action;
		const isCompanyManaged = getIsCMPBoard(state);
		scheduleAfterRender(() => {
			stopCustomFilterInteraction(numCustomFiltersApplied, numIssueIds, isCompanyManaged);
		});
		return null;
	};

export const setCustomFiltersFailure =
	() => (preState: State, state: State, action: FilteredCardFailureAction) => {
		const {
			payload: { error },
			meta: { analyticsEvent },
		} = action;
		if (analyticsEvent) {
			fireOperationalAnalytics(analyticsEvent, 'customFilters failed', {
				isClientFetchError: isClientFetchError(error),
				errorMessage: error?.message,
			});
		}
		cancelCustomFilterInteraction();
		return null;
	};

export const setCustomFiltersSuccess =
	() => (preState: State, state: State, action: FilteredCardSuccessAction) => {
		const {
			meta: { analyticsEvent, numCustomFiltersApplied, numIssueIds },
		} = action;
		if (analyticsEvent) {
			fireTrackAnalytics(analyticsEvent, 'customFilters applied', {
				numCustomFiltersApplied,
				numIssueIds,
			});
		}
		return null;
	};

export const setCustomFiltersRefilterSuccess =
	() => (preState: State, state: State, action: RefilterSuccessAction) => {
		const { excludedCardIds, filteredCardIds } = action.payload;

		getAnalyticsWebClientPromise().then((client) => {
			const payload = {
				source: 'boardScreen',
				actionSubject: 'customFiltersRefilter',
				action: 'applied',
				attributes: {
					excludedCards: excludedCardIds.length,
					filteredCards: filteredCardIds.length,
				},
			};
			client.getInstance().sendTrackEvent(payload);
		});

		return null;
	};

export const setCustomFiltersRefilterFailure =
	() => (preState: State, state: State, action: RefilterFailureAction) => {
		const { error } = action.payload;

		getAnalyticsWebClientPromise().then((client) => {
			const payload = {
				source: 'boardScreen',
				actionSubject: 'customFiltersRefilter',
				action: 'failed',
				attributes: {
					isClientFetchError: isClientFetchError(error),
					errorMessage: error?.message,
				},
			};
			client.getInstance().sendOperationalEvent(payload);
		});

		return null;
	};
