import type { ItemType } from '@atlassian/jira-favourite-change-provider/src/model/types.tsx';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';

type Request = {
	id: string;
	type: ItemType;
};

export const getFavoriteUrl = (baseUrl: string, request: Request): string =>
	`${baseUrl}/rest/internal/2/favourites/${request.type}/${request.id}`;

export const getFavorite = async (baseUrl: string, request: Request): Promise<boolean> =>
	performGetRequest(getFavoriteUrl(baseUrl, request));
