import memoize from 'lodash/memoize';
import type { AnalyticsMapping, AnalyticsPayload } from '../../../common/types.tsx';

export const getAnalyticsMapping = (
	id: string,
	analyticsMapping: AnalyticsMapping,
): string | AnalyticsPayload | undefined => {
	let result = analyticsMapping[id];

	if (!result) {
		const matchedId = Object.keys(analyticsMapping).find(
			(key) => key.startsWith(id) || id.startsWith(key),
		);

		if (matchedId) {
			result = analyticsMapping[matchedId];
		}
	}

	return result;
};

export const createGetAnalyticsMapping = (analyticsMapping?: AnalyticsMapping) => {
	if (!analyticsMapping) return (_id: string) => undefined;

	// Sorting the analytics mapping by key length ensures that the shortest key is matched first - shortest key means best match
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const sortedAnalyticsMapping = Object.fromEntries(
		Object.entries(analyticsMapping).sort(([keyA], [keyB]) => keyA.length - keyB.length),
	) as AnalyticsMapping;

	const memoizedGetterFunction = memoize(getAnalyticsMapping);

	return (id: string) => memoizedGetterFunction(id, sortedAnalyticsMapping);
};
