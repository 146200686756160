import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/concat';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/delay';
import { truncateUnscheduledWorkSwimlaneId } from '../../../common/utils/increment-planning/index.tsx';
import { cardAutoScrollUnscheduledColumnIcc } from '../../../state/actions/card/card-auto-scroll/index.tsx';
import {
	INITIATE_ICC_IN_UNSCHEDULED_COLUMN,
	openIccInUnscheduledColumn,
} from '../../../state/actions/card/index.tsx';
import { swimlaneToggle } from '../../../state/actions/swimlane/index.tsx';
import type { ActionsObservable } from '../../../state/types.tsx';

// Initiate inline card create in program board/increment planning board unscheduled column
export function initiateIccInUnscheduledColumnEpic(action$: ActionsObservable) {
	return action$.ofType(INITIATE_ICC_IN_UNSCHEDULED_COLUMN).mergeMap((action) => {
		const { swimlaneId, shouldToggleSwimlane } = action.payload;
		const truncatedSwimlaneId = truncateUnscheduledWorkSwimlaneId(swimlaneId, true);
		if (shouldToggleSwimlane) {
			return Observable.concat(
				Observable.of(swimlaneToggle(swimlaneId)),
				// Delay autoscroll after swimlane toggle so it scrolls to the right position
				Observable.of(cardAutoScrollUnscheduledColumnIcc(truncatedSwimlaneId, true)).delay(100),
				Observable.of(openIccInUnscheduledColumn(truncatedSwimlaneId)),
			);
		}

		// isImmediate true works better when swimlane is expanded
		return Observable.concat(
			Observable.of(cardAutoScrollUnscheduledColumnIcc(truncatedSwimlaneId, true)),
			Observable.of(openIccInUnscheduledColumn(truncatedSwimlaneId)),
		);
	});
}
