import { createContext } from 'react';
import type Client from '@atlaskit/pubsub';

type RealtimeContext = {
	client: Client | null;
};

const defaultContext: RealtimeContext = {
	client: null,
};

export const { Provider, Consumer } = createContext<RealtimeContext>(defaultContext);
