import { fg } from '@atlassian/jira-feature-gating';
import type { IssueId, IssueLink } from '@atlassian/jira-software-board-common/src/index.tsx';
import type { ActionPromise } from '../../../../model/issue/issue-increment-planning-types.tsx';
import flagMessages from '../../../../view/flags/messages.tsx';
import { errorFlagMeta, type FlagMeta, infoFlagMeta } from '../../meta/flag-meta.tsx';

export const ISSUE_LINKS_CREATE = 'state.actions.issue.issue-link.ISSUE_LINK_CREATE' as const;

type IssueLinksCreatePayload = {
	newIssueLinks: Record<IssueId, IssueLink[]>;
};

export type IssueLinkCreateAction = {
	type: typeof ISSUE_LINKS_CREATE;
	payload: IssueLinksCreatePayload;
};
export const issueLinksCreate = (payload: IssueLinksCreatePayload): IssueLinkCreateAction => ({
	type: ISSUE_LINKS_CREATE,
	payload,
});

export const ISSUE_LINK_CREATE_REQUEST =
	'state.actions.issue.issue-link.ISSUE_LINK_CREATE_REQUEST' as const;

type IssueLinkCreateRequestPayload = {
	sourceItemKey: string;
	targetItemKey: string;
	type: number;
};

export type IssueLinkCreateRequestAction = {
	type: typeof ISSUE_LINK_CREATE_REQUEST;
	payload: IssueLinkCreateRequestPayload;
	promise?: ActionPromise;
};

export const issueLinkCreateRequest = (
	payload: IssueLinkCreateRequestPayload,
): IssueLinkCreateRequestAction => ({
	type: ISSUE_LINK_CREATE_REQUEST,
	payload,
});

export const ISSUE_LINK_CREATE_SUCCESS =
	'state.actions.issue.issue-link.ISSUE_LINK_CREATE_SUCCESS' as const;

export type IssueLinkCreateSuccessAction = {
	type: typeof ISSUE_LINK_CREATE_SUCCESS;
};

export const issueLinkCreateSuccess = (): IssueLinkCreateSuccessAction => ({
	type: ISSUE_LINK_CREATE_SUCCESS,
});

export const ISSUE_LINK_CREATE_FAILED =
	'state.actions.issue.issue-link.ISSUE_LINK_CREATE_FAILED' as const;

export type IssueLinkCreateFailedAction = {
	type: typeof ISSUE_LINK_CREATE_FAILED;
};

export const issueLinkCreateFailed = (): IssueLinkCreateFailedAction => ({
	type: ISSUE_LINK_CREATE_FAILED,
});

export const ISSUE_LINKS_REMOVE_REQUEST =
	'state.actions.issue.issue-link.ISSUE_LINKS_REMOVE_REQUEST' as const;

type IssueLinksRemoveRequestPayload = {
	issueId: IssueId;
	issueLink: IssueLink;
};

export type IssueLinkRemoveRequestAction = {
	type: typeof ISSUE_LINKS_REMOVE_REQUEST;
	payload: IssueLinksRemoveRequestPayload;
};

export const issueLinksRemoveRequest = (
	payload: IssueLinksRemoveRequestPayload,
): IssueLinkRemoveRequestAction => ({
	type: ISSUE_LINKS_REMOVE_REQUEST,
	payload,
});

export const ISSUE_LINKS_REMOVE_SUCCESS =
	'state.actions.issue.issue-link.ISSUE_LINKS_REMOVE_SUCCESS' as const;

type IssueLinksRemoveSuccessPayload = {
	issueLinksToRemove: Record<IssueId, string>;
};

export type IssueLinkRemoveSuccessAction = {
	type: typeof ISSUE_LINKS_REMOVE_SUCCESS;
	payload: IssueLinksRemoveSuccessPayload;
};

export const issueLinksRemoveSuccess = (
	payload: IssueLinksRemoveSuccessPayload,
): IssueLinkRemoveSuccessAction => ({
	type: ISSUE_LINKS_REMOVE_SUCCESS,
	payload,
});

export const ISSUE_LINKS_REMOVE_FAILED =
	'state.actions.issue.issue-link.ISSUE_LINKS_REMOVE_FAILED' as const;

export type IssueLinkRemoveFailedAction = {
	type: typeof ISSUE_LINKS_REMOVE_FAILED;
	meta: FlagMeta;
};

export const issueLinksRemoveFailed = (): IssueLinkRemoveFailedAction => ({
	type: ISSUE_LINKS_REMOVE_FAILED,
	meta: errorFlagMeta({
		titleMessage: flagMessages.genericTitle,
		descriptionMessage: flagMessages.genericDescription,
	}),
});

export const ISSUE_LINKS_ADD_UPDATE_SUCCESS =
	'state.actions.issue.issue-link.ISSUE_LINKS_ADD_UPDATE_SUCCESS' as const;

type IssueLinksAddAndUpdateSuccessPayload = {
	newIssueLinks: Record<IssueId, IssueLink[]>;
};

export type IssueLinkAddAndUpdateSuccessAction = {
	type: typeof ISSUE_LINKS_ADD_UPDATE_SUCCESS;
	payload: IssueLinksAddAndUpdateSuccessPayload;
};

export const issueLinksAddAndUpdateSuccess = (
	payload: IssueLinksAddAndUpdateSuccessPayload,
): IssueLinkAddAndUpdateSuccessAction => ({
	type: ISSUE_LINKS_ADD_UPDATE_SUCCESS,
	payload,
});

export const ISSUE_LINKS_ADD_UPDATE_FAILED =
	'state.actions.issue.issue-link.ISSUE_LINKS_ADD_UPDATE_FAILED' as const;

export type IssueLinkAddAndUpdateFailedAction = {
	type: typeof ISSUE_LINKS_ADD_UPDATE_FAILED;
	meta: FlagMeta;
};

export const issueLinksAddAndUpdateFailed = (): IssueLinkAddAndUpdateFailedAction => ({
	type: ISSUE_LINKS_ADD_UPDATE_FAILED,
	meta: errorFlagMeta({
		titleMessage: flagMessages.genericTitle,
		descriptionMessage: flagMessages.genericDescription,
	}),
});

export const REACH_DEPENDENCY_LINES_LIMIT_FLAG_IP_BOARD =
	'state.actions.flags.REACH_DEPENDENCY_LINES_LIMIT_FLAG_IP_BOARD';

export type ReachDependencyLinesLimitInIPBoardAction = {
	type: typeof REACH_DEPENDENCY_LINES_LIMIT_FLAG_IP_BOARD;
	meta: FlagMeta;
};

export const reachDependencyLinesLimitInIPBoard = (
	dependencyLinesLimit: number,
): ReachDependencyLinesLimitInIPBoardAction => ({
	type: REACH_DEPENDENCY_LINES_LIMIT_FLAG_IP_BOARD,
	meta: infoFlagMeta({
		titleMessage: fg('jira-issue-terminology-refresh-m3')
			? flagMessages.reachDependencyLineLimitTitleIssueTermRefresh
			: flagMessages.reachDependencyLineLimitTitle,
		context: {
			limit: dependencyLinesLimit,
		},
	}),
});

export type Action =
	| IssueLinkCreateAction
	| IssueLinkCreateRequestAction
	| IssueLinkCreateSuccessAction
	| IssueLinkCreateFailedAction
	| IssueLinkRemoveRequestAction
	| IssueLinkRemoveSuccessAction
	| IssueLinkRemoveFailedAction
	| IssueLinkAddAndUpdateSuccessAction
	| IssueLinkAddAndUpdateFailedAction
	| ReachDependencyLinesLimitInIPBoardAction;
