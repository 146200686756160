/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
// @ts-expect-error - TS2614 - Module '"@atlaskit/button"' has no exported member 'ButtonTheme'. Did you mean to use 'import ButtonTheme from "@atlaskit/button"' instead?
import type { ButtonTheme, ThemeProps, ThemeTokens } from '@atlaskit/button';
import { colors } from '@atlaskit/theme';

export const helpAppearanceTheme: ButtonTheme = (
	currentTheme: (arg1: ThemeProps) => ThemeTokens,
	props: ThemeProps,
) => {
	const { buttonStyles, ...rest } = currentTheme(props);
	const theme: ThemeTokens = {
		...rest,
		buttonStyles: {
			...buttonStyles,
			backgroundColor: colors.P400,
			'> span': {
				color: colors.N0,
			},
		},
	};
	return theme;
};
type T = {
	[key: string]:
		| {
				[key: string]: string | number;
		  }
		| T;
};
/**
 * Dynamically builds and returns theme tokens relevant to the current theme settings, or undefined if no matching tokens are found.
 * It navigates the theme structure using specified properties to locate and aggregate the appropriate tokens.
 */
export const extract = (newTheme: T, themeProps: ThemeProps): ThemeTokens | undefined => {
	const { appearance, mode, state } = themeProps;
	if (!appearance || !newTheme[appearance]) {
		return undefined;
	}
	const root = newTheme[appearance];

	// @ts-expect-error - TS2739 - Type 'Record<string, any>' is missing the following properties from type 'ThemeTokens': buttonStyles, spinnerStyles
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return Object.keys(root).reduce<Record<string, any>>((acc, val) => {
		let node = root;
		[val, state, mode].forEach((item) => {
			if (!item) return;
			if (!node[item] && node[item] !== 0) return;
			if (typeof node[item] !== 'object') {
				acc[val] = node[item];
				return;
			}
			// @ts-expect-error - TS2322 - Type 'string | number | { [key: string]: string | number; } | T' is not assignable to type '{ [key: string]: string | number; } | T'.
			node = node[item];
		});
		return acc;
	}, {});
};
export const getTheme =
	(overrides?: Partial<ThemeTokens>): ButtonTheme =>
	(currentTheme: (arg1: ThemeProps) => ThemeTokens, themeProps: ThemeProps) => {
		const theme = currentTheme(themeProps);
		// @ts-expect-error - TS2322 - Type 'Record<string, any>' is not assignable to type 'ThemeTokens'.
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const styles: ThemeTokens = Object.entries(theme).reduce<Record<string, any>>(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(acc, [key, value]: [any, any]) => {
				// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Partial<ThemeTokens>'.
				if (!overrides?.[key]) {
					acc[key] = value;
					return acc;
				}

				// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Partial<ThemeTokens>'.
				const overriddenStyles = extract(overrides[key], themeProps);
				acc[key] = {
					...value,
					...overriddenStyles,
				};
				return acc;
			},
			{},
		);
		return styles;
	};
