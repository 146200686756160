import get from 'lodash/get';
import { getNormalisedPerformanceFFs } from '@atlassian/jira-common-long-task-metrics/src/common/util/collectors.tsx';
import { scheduleAfterRender } from '@atlassian/jira-software-react-scheduler/src/index.tsx';
import { MAX_AVATARS } from '../../../../common/constants.tsx';
import {
	ASSIGNEE,
	ISSUE_PARENT,
	ISSUE_TYPE,
	LABEL,
	TEXT,
	SPRINT,
} from '../../../../model/filter/filter-types.tsx';
import { filterInteraction } from '../../../../services/utils/performance-analytics/index.tsx';
import type { SetAllFiltersAction } from '../../../actions/filter/index.tsx';
import type { State } from '../../../reducers/types.tsx';
import { currentUserAccountIdSelector } from '../../../selectors/software/software-selectors.tsx';
import {
	getWorkFilters,
	orderedWorkAssigneesByAccountIdSelector,
	workFilteredIssuesSelector,
} from '../../../selectors/work/work-selectors.tsx';

export const filterPanelMounted = (eventPrefix: string) => (preState: State, state: State) => {
	const totalAssigneeCount = orderedWorkAssigneesByAccountIdSelector(state).length;
	return {
		name: `${eventPrefix}.filters.show`,
		data: {
			numAvatarsDisplayed: totalAssigneeCount > MAX_AVATARS ? MAX_AVATARS : totalAssigneeCount,
		},
	};
};

export const searchFocus = (eventPrefix: string) => () => ({
	name: `${eventPrefix}.filters.search-field.focus`,
	data: {},
});

export const clearAllFiltersClicked = (eventPrefix: string) => (preState: State) => {
	const selectedFilters = get(getWorkFilters(preState), ['values']);
	return {
		name: `${eventPrefix}.filters.clear-button.click`,
		data: {
			numSelectedAvatars: (selectedFilters[ASSIGNEE] || []).length,
		},
	};
};

const getFilterAnalyticsData = (state: State) => {
	const filters = getWorkFilters(state).values;
	const assigneeFilters = filters[ASSIGNEE] || [];
	const labelFilters = filters[LABEL] || [];
	const issueParentFilters = filters[ISSUE_PARENT] || [];
	const issueTypeFilters = filters[ISSUE_TYPE] || [];
	const sprintFilters = filters[SPRINT] || [];

	return {
		numSelectedAvatars: assigneeFilters.length,
		isAssignedToMeSelected: assigneeFilters.indexOf(currentUserAccountIdSelector(state)) > -1,
		numSelectedLabels: labelFilters.length,
		numVisibleIssues: workFilteredIssuesSelector(state).length,
		numSelectedIssueParents: issueParentFilters.length,
		numSelectedIssueTypes: issueTypeFilters.length,
		numSelectedSprints: sprintFilters.length,
	};
};

export const setAllFilters =
	(eventPrefix: string) => (preState: State, state: State, action: SetAllFiltersAction) => {
		const { filterType } = action.meta;
		if (filterType) {
			let actionName = 'select';
			const filters = getWorkFilters(state).values;
			if (filterType === TEXT && !filters[TEXT]) {
				actionName = 'deselect';
			} else {
				const preFilters = getWorkFilters(preState).values;
				actionName =
					get(filters, [filterType], []).length < get(preFilters, [filterType], []).length
						? 'deselect'
						: actionName;
			}

			return {
				name: `${eventPrefix}.filters.${filterType}.${actionName}`,
				data: getFilterAnalyticsData(state),
			};
		}
		return null;
	};

export const setAllFiltersPerformance = () => () => {
	scheduleAfterRender(() => {
		filterInteraction.stop({
			customData: getNormalisedPerformanceFFs(),
		});
	});
	return null;
};
