import type { ComponentType } from 'react';
import { withAnalyticsEvents } from '@atlaskit/analytics-next';

export type Callbacks<Props> = Record<keyof Props, string>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const map = (props: Callbacks<any>, componentName: string) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Object.keys(props).reduce<Record<string, any>>(
		(result, key) => ({
			// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
			...result,
			[key]: { action: props[key], actionSubject: componentName },
		}),
		{},
	);

export const AnalyticsEventToProps =
	(
		componentName: string,
		eventActions: Callbacks<any>, // eslint-disable-line @typescript-eslint/no-explicit-any
	) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(WrappedComponent: ComponentType<any>): ComponentType<any> =>
		withAnalyticsEvents(map(eventActions, componentName))(WrappedComponent);
