import { DRAG_START, DRAG_END } from '../../../../actions/board/drag/index.tsx';
import type { Action } from '../../../../types.tsx';

export const isDraggingReducer = (state = false, action: Action): boolean => {
	if (action.type === DRAG_START) {
		return true;
	}

	if (action.type === DRAG_END) {
		return false;
	}

	return state;
};
