import React from 'react';
import { removeAccents } from '@atlassian/jira-software-highlight-text/src/common/utils/index.tsx';
import GenericHighlightText from '@atlassian/jira-software-highlight-text/src/ui/index.tsx';

type Props = {
	content: string;
	phrases: string[];
};

export const shouldHighlightText = (text = '', keywords: string[] = []) =>
	text &&
	text.length > 0 &&
	keywords.filter((keyword) => keyword !== '').length > 0 &&
	keywords.some(
		(keyword) =>
			removeAccents(text).toLowerCase().includes(keyword.toLowerCase()) ||
			text.toLowerCase().includes(keyword.toLowerCase()),
	);

export const HighlightText = ({ content, phrases }: Props) => {
	if (!phrases || !phrases.length) {
		return <span>{content}</span>;
	}

	return <GenericHighlightText highlight={phrases} text={content} />;
};

export default HighlightText;
