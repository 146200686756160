import type { Observable as ObservableType } from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import type {
	IssuesLinksResponse,
	IssueIds,
} from '../../model/issue-links-stats/issue-links-stats-types.tsx';

const LINKED_ISSUES_STATS_API_URL = '/rest/api/2/issues/linkedissues/stats';

export const getIssueLinksStats = (issueIds: IssueIds): ObservableType<IssuesLinksResponse> =>
	fetchJson$(LINKED_ISSUES_STATS_API_URL, {
		method: 'POST',
		body: JSON.stringify({
			issueIds,
		}),
		perf: {
			prefix: 'jsw.board',
			key: 'issueLinksStats.fetch',
		},
	});
