/*!
 * ATTENTION
 *
 * This module is used on both the main board app and its vendorless early-script entry.
 *
 * Because of this, it can't have dependencies. Feature-flagging logic should be done outside this
 * module and values should be passed in. For now changes must happen on both calls.
 */

export const softwareBoardScopeCriticalDataOperationName = 'SoftwareBoardScopeCriticalData';

export const editConfig = `
        editConfig {
            inlineColumnEdit {
                enabled
            }
            inlineIssueCreate @skip(if: $useBackendIccConfig){
                enabled
            }
        }
    `;

export const columnStatus = `columnStatus {
            status {
                id
                name
                category
            }
            transitions {
                id
                name
                status {
                    id
                }
                originStatus { id }
                cardType { id }
                isGlobal
                isInitial
                hasConditions
				hasScreen @include(if: $showIssueTransition)
            }
        }`;

export const getBoardScopeGraphqlQuery = () => {
	const issueChildren = `issueChildren {
                id
                flagged
                done
                parentId
                estimate {
                    storyPoints
                    originalEstimate {
                        value
                        valueAsText
                    }
                }
                issue {
                    id
                    key
                    summary
                    labels
                    assignee {
                        accountId
                        displayName
                        avatarUrl
                    }
                    type { id, name, iconUrl }
                    status { id }
                    activeSprint { id }
                }
                priority {
                    name
                    iconUrl
                }
                dueDate
            }`;

	return `query ${softwareBoardScopeCriticalDataOperationName} ($boardId: ID!, $useBackendIccConfig: Boolean!, $showIssueTransition: Boolean!) {
            boardScope(boardId: $boardId) {
                userSwimlaneStrategy
                board {
                    name
                    swimlaneStrategy
                    hasClearedIssues
                    rankCustomFieldId
                    assignees {
                        accountId
                        displayName
                        avatarUrl
                    }
                    columns {
                        id
                        name
                        maxIssueCount
                        status {
                            id
                            name
                        }
                        ${columnStatus}
                        isDone
                        isInitial
                        cards {
                            id
                            flagged
                            done
                            parentId
                            estimate {
                                storyPoints
                                originalEstimate {
                                    value
                                    valueAsText
                                }
                            }
                            issue {
                                id
                                key
                                summary
                                labels
                                assignee {
                                    accountId
                                }
                                type { id, name, iconUrl }
                                status { id }
                                activeSprint { id }
                            }
                            priority {
                                name
                                iconUrl
                            }
                            dueDate
                            childIssuesMetadata { complete, total }
                        }
                    }
                    issueTypes {
                        id
                        name
                        iconUrl
                        hierarchyLevelType
                    }
                    inlineIssueCreate @skip(if: $useBackendIccConfig) { enabled }
                    ${issueChildren}
                    cards { id }
                    ${editConfig}
                    inlineCardCreateConfig @include(if: $useBackendIccConfig)
                }
                sprints(state: [ACTIVE]) {
                    id
                    name
                    goal
                    startDate
                    endDate
                    daysRemaining
                }
                features { key, status, toggle, category }
                projectLocation {
                    id
                    key
                    name
                    isSimplifiedProject
                    issueTypes {
                        id
                        name
                        iconUrl
                        hierarchyLevelType
                    }
                }
                issueParents {
                    id
                    key
                    summary
                    issue {
                        status {
                            id
                            name
                            category
                        }
                    }
                    issueType {
                        id
                        name
                        iconUrl
                    }
                    color
                }
                currentUser { permissions }
                estimation {
                    current {
                        customFieldId
                    }
                }
            }
        }`;
};
