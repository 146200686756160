import { type Observable as ObservableType, Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import { gqlTagSwag } from '@atlassian/jira-software-swag/src/index.tsx';
import { mutation$ } from '@atlassian/jira-software-swag/src/services/mutation/index.tsx';
import { ColumnStatusFragment } from '../../common/graphql/fragments/column-status.tsx';
import { columnRenameAnalyticsOptions } from '../../common/graphql/metrics/index.tsx';
import type { ColumnGQLData } from '../../model/column/column-types.tsx';

export const softwareColumnRenameOperationName = 'SoftwareColumnRename';

export const columnRenameMutation = gqlTagSwag`
    mutation SoftwareColumnRename($boardId: ID!, $columnId: ID!, $columnName: String!) {
        setColumnName(input: {boardId: $boardId, columnId: $columnId, columnName: $columnName}) {
            column {
                id
                status {
                    id
                    name
                }
                columnStatus {
                    ...ColumnStatusFragment
                }
                isDone
                isInitial
                transitionId
            }
        }
    }

    ${ColumnStatusFragment}
`;

type Response = {
	setColumnName: ColumnGQLData;
};

export const parseResponse = (data: Response): ObservableType<ColumnGQLData> =>
	Observable.of(data.setColumnName);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	contextPath: string,
	boardId: string,
	columnId: number,
	columnName: string,
): ObservableType<ColumnGQLData> =>
	mutation$<Response>(
		contextPath,
		softwareColumnRenameOperationName,
		columnRenameMutation,
		{
			boardId,
			columnId,
			columnName,
		},
		columnRenameAnalyticsOptions,
	).flatMap(parseResponse);
