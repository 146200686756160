import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	label: {
		id: 'software-filters.filters.issue-type-group.label',
		defaultMessage: 'Type',
		description:
			'The label of a dropdown menu button used to choose issue type(s) to filter issues by.',
	},
});
