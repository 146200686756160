import React from 'react';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { createUseEntryPointContext } from '@atlassian/jira-entry-point/src/controllers/utils/create-use-entry-point-context/index.tsx';
import { planIssueStatus } from './entrypoint.tsx';

export const PlanIssueStatusEntrypointContext =
	createUseEntryPointContext<typeof planIssueStatus>();

export const PlanIssueStatusEntrypointContextProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const entryPointResult = useEntryPoint(planIssueStatus, {});
	return (
		<PlanIssueStatusEntrypointContext.Provider value={entryPointResult}>
			{children}
		</PlanIssueStatusEntrypointContext.Provider>
	);
};
