import type { State } from '../../../reducers/types.tsx';
import { isCardMediaEnabled } from '../../../selectors/work/work-selectors.tsx';

export const cardMediaToggled = (eventPrefix: string) => (preState: State, state: State) => {
	const enabled = isCardMediaEnabled(state);
	return {
		name: `${eventPrefix}.card.media.toggled.global`,
		data: {
			enabled,
		},
	};
};
