import {
	setViewSettings,
	setIsFullscreen,
	setViewSettingsPanels,
	setViewSettingsQuickFilters,
	setViewSettingsCardDetails,
	setViewSettingsCardExtraFields,
	setViewSettingsCompactMode,
	setShowUnscheduledColumn,
	setShowOfftrackDependencyLines,
	setIssueIdsToShowDependencies,
	setViewSettingsShowCardCovers,
	toggleViewSetting,
	toggleShowEpics,
	toggleShowVersions,
	toggleShowStatuses,
	toggleShowLinkedIssuesStats,
	toggleExtraField,
	toggleShowDaysInColumn,
	toggleShowDueDate,
	toggleShowLabels,
	initState,
} from './main.tsx';

export const actions = {
	setViewSettings,
	setIsFullscreen,
	setViewSettingsPanels,
	setViewSettingsQuickFilters,
	setViewSettingsCardDetails,
	setViewSettingsCardExtraFields,
	setViewSettingsCompactMode,
	setShowUnscheduledColumn,
	setShowOfftrackDependencyLines,
	setIssueIdsToShowDependencies,
	setViewSettingsShowCardCovers,
	toggleViewSetting,
	toggleShowEpics,
	toggleShowVersions,
	toggleShowStatuses,
	toggleShowLinkedIssuesStats,
	toggleExtraField,
	toggleShowDaysInColumn,
	toggleShowDueDate,
	toggleShowLabels,
	initState,
} as const;

export type Actions = typeof actions;
