import { useEffect, useRef } from 'react';

const useRenderState = () => {
	const isInitialRender = useRef(true);

	useEffect(() => {
		isInitialRender.current = false;
	}, []);

	return {
		isInitialRender: isInitialRender.current,
	};
};

export default useRenderState;
