import uuid from 'uuid';

const info = {
	uniqueId: uuid.v4(),
	isDeepLinking: true,
};

export const initialState = {
	info,
	data: {},
} as const;
