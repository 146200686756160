import get from 'lodash/get';
import memoizeOne from 'memoize-one';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { OnSubmit } from '@atlassian/jira-software-edit-sprint-modal-relay/src/common/types.tsx';
import {
	editSprintRequest,
	editSprintSuccess,
	editSprintFailure,
} from '../../../../state/actions/sprints/edit/index.tsx';
import { rapidViewIdSelector } from '../../../../state/selectors/software/software-selectors.tsx';
import { activeSprintsSelector } from '../../../../state/selectors/sprint/sprint-selectors.tsx';
import type { State, Dispatch } from '../../../../state/types.tsx';
import { EditSprintModalEntryPoint } from './view.tsx';

type OwnProps = {};

type StateProps = {
	boardId: number;
	sprintId: number;
};

const mapStateToProps = (state: State): StateProps => ({
	boardId: Number(rapidViewIdSelector(state)),
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	sprintId: get(activeSprintsSelector(state), [0, 'id'])!,
});

type DispatchProps = {
	dispatch: Dispatch;
};

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	dispatch,
});

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};
const onSubmitFactory = memoizeOne(
	(dispatch: Dispatch) =>
		(data: Parameters<OnSubmit>[0], submitPromise: Parameters<OnSubmit>[1]) => {
			const { name, goal, startDate, endDate, sprintId } = data;
			const action = editSprintRequest(sprintId, name, goal, startDate, endDate);
			const {
				meta: {
					optimistic: { id: optimisticId },
				},
			} = action;
			dispatch(action);

			submitPromise
				.then((response) => {
					const daysRemaining = response.editSprint?.sprint?.daysRemaining;

					// @ts-expect-error TS2345: Argument of type 'number | null | undefined' is not assignable to parameter of type 'number'.
					dispatch(editSprintSuccess(optimisticId, sprintId, daysRemaining));
				})
				.catch(() => {
					dispatch(editSprintFailure(optimisticId));
				});
		},
);

export const mergeProps = (
	stateProps: StateProps,
	dispatchProps: DispatchProps,
	ownProps: OwnProps,
) => {
	const { dispatch } = dispatchProps;
	return {
		...stateProps,
		...ownProps,
		onClose: noop,
		onSubmit: onSubmitFactory(dispatch),
	};
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditSprintModalEntryPoint);
