/**
 * @generated SignedSource<<1775dc6f451f5aa08f2de993d8e993cc>>
 * @relayHash f75963656ac83b6e4f5b855b41942d4f
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 5455aa42cc5ba971a154b4555e76f70cb6faf70a0f41a193d62856efb789841b

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { ui_DesignModalQuery } from './ui_DesignModalQuery.graphql';

const node: PreloadableConcreteRequest<ui_DesignModalQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "5455aa42cc5ba971a154b4555e76f70cb6faf70a0f41a193d62856efb789841b",
    "metadata": {},
    "name": "ui_DesignModalQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
