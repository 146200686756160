import { fg } from '@atlassian/jira-feature-gating';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { CAN_EDIT_BOARD } from '../../../../../model/permission/permission-types.tsx';
import { IS_SPRINTS_ENABLED } from '../../../../../model/work/work-features-types.tsx';
import type { State } from '../../../../../state/reducers/types.tsx';
import { getPermissionsSelector } from '../../../../../state/selectors/board/board-permissions-selectors.tsx';
import {
	isBoardEmptySelector,
	isUserAtLeastMemberOfProject,
} from '../../../../../state/selectors/board/board-selectors.tsx';
import { makeShowPlaceHolderSelector } from '../../../../../state/selectors/column/column-inline-card-create-selectors.tsx';
import { isIccEnabledForColumnOrSwimlane } from '../../../../../state/selectors/inline-create/inline-create-selectors.tsx';
import {
	getIsCMPBoard,
	getBoardType,
	getIsIncrementPlanningBoard,
	getIsJSWBoard,
} from '../../../../../state/selectors/software/software-selectors.tsx';
import { hasSwimlanes } from '../../../../../state/selectors/swimlane/swimlane-mode-selectors.tsx';
import { getPlatformFeatures } from '../../../../../state/selectors/work/work-features-selectors.tsx';
import {
	getBoardHasFilteredIssues,
	makeGetIssueEntriesForColumnSelector,
} from '../../../../../state/selectors/work/work-selectors.tsx';
import type { OwnProps, StateProps } from './types.tsx';
import Column from './view.tsx';

export const mapStateToProps = (): ((state: State, ownProps: OwnProps) => StateProps) => {
	const getIssueEntries = makeGetIssueEntriesForColumnSelector();

	return (
		state: State,
		{ swimlaneId, column, columnIndex, isUnscheduledWorkColumnPanel, isFirstSwimlane }: OwnProps,
	) => {
		const features = getPlatformFeatures(state);
		const permissions = getPermissionsSelector(state);
		const issueEntries = getIssueEntries(state, {
			swimlaneId,
			columnId: column.id,
			isUnscheduledWorkColumn: isUnscheduledWorkColumnPanel,
		});
		const isIncrementPlanningBoard = getIsIncrementPlanningBoard(state);
		const isJSWBoard = getIsJSWBoard(state);
		const isCMPBoard = getIsCMPBoard(state);
		const isDropCardInstantlyBounceBackEnabled = isCMPBoard && hasSwimlanes(state);

		const shouldRenderUnassignedCTA =
			columnIndex === 0 &&
			(!swimlaneId || !!isFirstSwimlane) &&
			!isUnscheduledWorkColumnPanel &&
			!isIncrementPlanningBoard &&
			isJSWBoard &&
			fg('jira-collab-unassigned-filter-cta-gate');

		return {
			isBoardEmpty: isBoardEmptySelector(state),
			isDoneCardsButtonVisible: getBoardHasFilteredIssues(state),
			isSprintsEnabled: features[IS_SPRINTS_ENABLED] || false,
			isUserAtLeastMember: isUserAtLeastMemberOfProject(state),
			canEditBoard: (permissions[CAN_EDIT_BOARD] || false) && !isCMPBoard,
			issueEntries,
			isCMPKanbanBoard: isCMPBoard && getBoardType(state) === 'KANBAN',
			isCMPBoard,
			isColumnIccEnabled: isIccEnabledForColumnOrSwimlane(state, swimlaneId, column.id),
			isDropCardInstantlyBounceBackEnabled,
			placeholderType: makeShowPlaceHolderSelector(state)(columnIndex),
			shouldRenderUnassignedCTA,
		};
	};
};

const ColumnConnected = connect(mapStateToProps, {})(Column);

export default ColumnConnected;
