import { IP_BOARD_404_VIEW } from '../../../../actions/work/index.tsx';
import type { Action } from '../../../../types.tsx';

export const isIpBoardFailedReducer = (state = false, action: Action): boolean => {
	if (action.type === IP_BOARD_404_VIEW) {
		return true;
	}

	return state;
};
