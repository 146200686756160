export const createFailureReason = (reason: string) => ({
	failureReason: reason,
	errorMessage: reason,
});

export const createFailureReasonWithStatusCode = (reason: string, statusCode: number | null) => {
	if (statusCode === undefined || statusCode === null) {
		return {
			failureReason: reason,
			statusCode: '[unknown]',
			errorMessage: `${reason}-unknown`,
		};
	}
	return {
		failureReason: reason,
		statusCode: `${statusCode}`,
		errorMessage: `${reason}-${statusCode}`,
	};
};
