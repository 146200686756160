import React, { type ReactNode, useCallback } from 'react';
import {
	isIssueEntryGroup,
	getIssueEntryGroupKey,
	type IssueEntry,
} from '@atlassian/jira-software-board-common/src/index.tsx';
import type { RenderCardCallback } from '../../types.tsx';
import { VIRTUAL_LIST_FOOTER_KEY, VIRTUAL_LIST_DND_KEY } from '../types.tsx';
import RowRenderer from './view.tsx';

type UseRowRendererParams = {
	cardEntries: IssueEntry[];
	footer: ReactNode;
	renderCard: RenderCardCallback;
	footerIndex: number;
	placeholder: ReactNode | null;
	showFooter: boolean;
};

const getKey = (index: number, footerIndex: number, cardEntry: IssueEntry | undefined) => {
	if (index === footerIndex) return VIRTUAL_LIST_FOOTER_KEY;

	if (cardEntry) {
		return isIssueEntryGroup(cardEntry) ? getIssueEntryGroupKey(cardEntry) : cardEntry.issueId;
	}

	return VIRTUAL_LIST_DND_KEY;
};

type RowProps = {
	index: number;
	isScrolling: boolean;
	style: React.CSSProperties;
	// eslint-disable-next-line jira/react/handler-naming
	forceRemeasure: (el?: Element | null) => void;
	// eslint-disable-next-line jira/react/handler-naming
	measure: (el: Element | null) => void;
	offsetTop: number;
};

export const useRowRenderer = ({
	cardEntries,
	footer,
	renderCard,
	footerIndex,
	placeholder,
	showFooter,
}: UseRowRendererParams) =>
	useCallback(
		({ index, isScrolling, style, measure, forceRemeasure, offsetTop }: RowProps) => (
			<RowRenderer
				rowStyle={style}
				index={index}
				footerIndex={footerIndex}
				cardEntries={cardEntries}
				footer={footer}
				measure={measure}
				forceRemeasure={forceRemeasure}
				key={getKey(index, footerIndex, cardEntries[index])}
				renderCard={renderCard}
				isScrolling={isScrolling}
				placeholder={placeholder}
				showFooter={showFooter}
				offsetTop={offsetTop}
			/>
		),
		[footerIndex, cardEntries, footer, renderCard, placeholder, showFooter],
	);
