export const NBT_MY_PREFERENCES_URL = '/rest/api/3/mypreferences?key=next-best-task-feature';

export const PACKAGE_NAME = 'jiraPersonalSettingsNextBestTask';

export const NBT_OPT_IN_PREF_ANALYTICS_ID = 'updateNextBestTaskOptInPreference';

export const ARTICLE_ID = '2HtqtVTfYoilHIuYJIGD1T';

export const FEEDBACK_GROUP_ID = 'jsw.labs.next-best-task-opt-in';

export const ACTION_SUBJECT_ID = 'jira.insights.nextBestTask.optInToggle';
