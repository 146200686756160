import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { styled } from '@compiled/react';
import { fg } from '@atlassian/jira-feature-gating';
import { columnHeaderHeight } from '@atlassian/jira-platform-board-kit/src/common/constants/styles/index.tsx';
import { SwimlaneColumnHeaderContainer } from '@atlassian/jira-platform-board-kit/src/ui/swimlane-column-header/index.tsx';
import SwimlaneWrapper from '@atlassian/jira-platform-board-kit/src/ui/swimlane/wrapper/index.tsx';
import type { IssueLink as DependencyModalIssueLink } from '@atlassian/jira-portfolio-3-dependency-line-detail/src/common/types.tsx';
import { useDependencyLinks } from '@atlassian/jira-portfolio-3-plan-increment-common/src/hooks/use-dependency-links/index.tsx';
import { DependencyLines } from '@atlassian/jira-portfolio-3-plan-increment-common/src/ui/dependency-lines/index.tsx';
import type { IssueLink, IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import {
	type MeasurementCacheEntry,
	useVirtual,
} from '@atlassian/jira-software-fast-virtual/src/services/use-virtual/index.tsx';
import { Capability } from '../../../../common/capability/index.tsx';
import { issueLinksRemoveRequest } from '../../../../state/actions/issue/issue-link/index.tsx';
import {
	useBoardSelector,
	useBoardActionCreator,
	useBoardStore,
} from '../../../../state/index.tsx';
import { isIncrementPlanningReadOnly } from '../../../../state/selectors/board/board-permissions-selectors.tsx';
import {
	getIssueIdsToShowDependencies,
	getShowOfftrackDependencyLines,
} from '../../../../state/selectors/issue-link/issue-link-selectors.tsx';
import { getIssueLinkTypes } from '../../../../state/selectors/software/software-selectors.tsx';
import { getCollapsedSwimlanes } from '../../../../state/selectors/swimlane/swimlane-selectors.tsx';
import {
	columnsWithResolutionSelector,
	platformSwimlaneIdsSelector,
	swimlaneHeightsSelector,
	getIssuesEntriesWithIssueLinksInBoard,
	getIssuesIdsForColumn,
} from '../../../../state/selectors/work/work-selectors.tsx';
import {
	useCapability,
	useIsIncrementPlanningBoard,
} from '../../../../state/state-hooks/capabilities/index.tsx';
import ColumnHeader from '../column/header/index.tsx';
import ColumnMenu from '../column/header/menu/index.tsx';
import SwimlaneColumnHeader from '../swimlane-header/index.tsx';
import VirtualizedSwimlane from '../virtualized-swimlane/index.tsx';
import { useAutoScrollSwimlane } from './use-auto-scroll-swimlane/index.tsx';

const SwimlanesContainer = () => {
	const boardStore = useBoardStore();
	const columns = useBoardSelector(columnsWithResolutionSelector);
	const swimlaneIds = useBoardSelector(platformSwimlaneIdsSelector);
	const collapsedSwimlaneIds = useBoardSelector(getCollapsedSwimlanes);
	const isIpBoard = useIsIncrementPlanningBoard();

	const issuesWithLinksById = useBoardSelector(getIssuesEntriesWithIssueLinksInBoard);
	const issueLinkTypes = useBoardSelector(getIssueLinkTypes);
	const showOffTrackDependencies = useBoardSelector(getShowOfftrackDependencyLines);
	const issueIdsToShowDependencies = useBoardSelector(getIssueIdsToShowDependencies);

	const hasDependenciesToShow = issueIdsToShowDependencies.length > 0 || showOffTrackDependencies;

	const [menusShown, setMenusShowns] = useState<Record<number, boolean>>({});
	const isFlexible = useCapability(Capability.FLEXIBLE_BOARD_COLUMNS);
	const setMenuVisibility = (columnId: number) => (visible: boolean) => {
		setMenusShowns({ [columnId]: visible });
	};

	const swimlaneHeights = useBoardSelector(swimlaneHeightsSelector);
	const cacheOptions = useMemo(
		() => ({
			cache: new Map<string, MeasurementCacheEntry>(),
			getCacheKey: (index: number) => swimlaneIds[index],
		}),
		[swimlaneIds],
	);

	const { rows, totalSize, scrollTo, rowStates } = useVirtual({
		rowCount: swimlaneIds.length,
		getDefaultRowSize: (index) => swimlaneHeights[swimlaneIds[index]],
		offsetTop: columnHeaderHeight,
		cacheOptions,
	});

	const [_, { updateSwimlanePositions }] = useDependencyLinks();

	useEffect(() => {
		if (isIpBoard && rowStates && fg('dependency_visualisation_program_board_fe_and_be')) {
			updateSwimlanePositions(rowStates, rows, swimlaneIds, collapsedSwimlaneIds);
		}
	}, [isIpBoard, updateSwimlanePositions, rows, rowStates, swimlaneIds, collapsedSwimlaneIds]);

	useAutoScrollSwimlane(swimlaneIds, scrollTo);

	const renderSwimlanes = () => (
		<>
			{rows.map(({ index, top, forceRemeasure, measure }) => (
				<VirtualizedSwimlane
					key={swimlaneIds[index]}
					id={swimlaneIds[index]}
					index={index}
					top={top}
					measure={measure}
					forceRemeasure={forceRemeasure}
					isLastSwimlane={index === swimlaneIds.length - 1}
					isUnscheduledWorkColumnPanel={false}
				/>
			))}
		</>
	);

	const isIPBoardReadOnly = useBoardSelector(isIncrementPlanningReadOnly);

	const onUnlinkIssueAction = useBoardActionCreator(
		(issueLink: IssueLink, issueId: string | number) =>
			issueLinksRemoveRequest({
				issueId,
				issueLink,
			}),
	);

	const onUnlinkIssue = useCallback(
		async (dependencyModalIssueLink: DependencyModalIssueLink) => {
			if (dependencyModalIssueLink) {
				const issueLink: IssueLink = {
					id: dependencyModalIssueLink.itemKey,
					sourceId: dependencyModalIssueLink.sourceItemKey,
					destinationId: dependencyModalIssueLink.targetItemKey,
					linkTypeId: dependencyModalIssueLink.type,
				};

				return onUnlinkIssueAction(issueLink, issueLink.sourceId);
			}
		},
		[onUnlinkIssueAction],
	);

	const getOrderedIssueIdsForColumn = (columnId: number, swimlaneId: string): IssueId[] => {
		return (
			getIssuesIdsForColumn(boardStore.getState(), {
				columnId,
				swimlaneId,
			}) || []
		);
	};

	return (
		<SwimlanesContainerWrapper isFlexible={isFlexible}>
			<SwimlaneColumnHeaderContainer flexibleColumns={isFlexible}>
				{columns.map(({ id }, columnIndex) => (
					<SwimlaneColumnHeader
						id={id}
						key={id}
						header={
							<ColumnHeader
								id={id}
								isMenuShown={menusShown[id] || false}
								menu={<ColumnMenu columnId={id} onVisibilityChange={setMenuVisibility(id)} />}
							/>
						}
						draggableIndex={columnIndex}
					/>
				))}
			</SwimlaneColumnHeaderContainer>
			<SwimlaneWrapper
				flexibleColumns={isFlexible}
				minHeight={totalSize + columnHeaderHeight}
				isVirtualised
			>
				{renderSwimlanes()}
			</SwimlaneWrapper>
			{isIpBoard &&
				hasDependenciesToShow &&
				fg('dependency_visualisation_program_board_fe_and_be') && (
					<DependencyLines
						issuesWithLinksById={issuesWithLinksById}
						showOffTrackDependencies={showOffTrackDependencies}
						issueIdsToShowDependencies={issueIdsToShowDependencies}
						issueLinkTypes={issueLinkTypes}
						onUnlink={onUnlinkIssue}
						canDelete={!isIPBoardReadOnly}
						getOrderedIssueIdsForColumn={getOrderedIssueIdsForColumn}
					/>
				)}
		</SwimlanesContainerWrapper>
	);
};

export default SwimlanesContainer;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SwimlanesContainerWrapper = styled.div<{ isFlexible?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ isFlexible }) => (isFlexible ? '100%' : 'initial'),
});
