export const CHILD_ISSUES_ICON = 'child-issues' as const;
export const FLAG_ICON = 'flag' as const;
export const CHECK_ICON = 'tick' as const;
export const UPLOAD_ICON = 'upload' as const;
export const BITBUCKET_BRANCHES_ICON = 'bitbucket-branches' as const;
export const BITBUCKET_COMMITS_ICON = 'bitbucket-commits' as const;
export const BITBUCKET_PULLREQUESTS_ICON = 'bitbucket-pullrequests' as const;
export const EMOJI_FREQUENT_ICON = 'emoji-frequent' as const;
export const MORE_ICON = 'more' as const;
export const PERSON_ICON = 'person' as const;
export const DUE_DATE_ICON = 'due-date' as const;

export type IconTypeEnum =
	| typeof CHILD_ISSUES_ICON
	| typeof FLAG_ICON
	| typeof CHECK_ICON
	| typeof UPLOAD_ICON
	| typeof BITBUCKET_BRANCHES_ICON
	| typeof BITBUCKET_COMMITS_ICON
	| typeof BITBUCKET_PULLREQUESTS_ICON
	| typeof EMOJI_FREQUENT_ICON
	| typeof MORE_ICON
	| typeof PERSON_ICON
	| typeof DUE_DATE_ICON;

export type Props = {
	type: IconTypeEnum;
	label?: string;
	primaryColor?: string;
};

export type ContainerProps = {
	primaryColor?: string;
};
