import React from 'react';
import { styled } from '@compiled/react';
import CreateRuleIcon from '@atlaskit/icon/glyph/add';
import { Section } from '@atlaskit/menu';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import type {
	ManualRule,
	ManualRuleExecutor,
	TemplateRecommendation,
} from '@atlassian/jira-automation-platform/src/common/types.tsx';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	fireOperationalAnalyticsDeferred,
	MountEvent,
} from '@atlassian/jira-product-analytics-bridge';
import { ConditionalRecommendation } from '../../../../../common/ui/conditional-recommendation/index.tsx';
import MenuIcon from '../../../../../common/ui/menu-icon/index.tsx';
import MenuLink from '../../../../../common/ui/menu-link/main.tsx';
import {
	useCanManageAutomations,
	useBaseAutomationUrl,
} from '../../../../../controllers/menu-context/index.tsx';
import EmptyState from './empty-state/index.tsx';
import messages from './messages.tsx';
import Rule from './rule/index.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const RulesContainer = styled(Section)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxHeight: `${gridSize * 46}px`,
	overflow: 'auto',
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const AddIcon = styled(MenuIcon)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.accent.gray.subtlest', colors.N20),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.icon', colors.N600),
});

export type RuleListProps = {
	rules: ManualRule[];
	executingRuleId: number | null;
	executeRule: ManualRuleExecutor;
	setClosed: () => void;
	selectedIssueIds?: number[];
	recommendations?: TemplateRecommendation[];
};

const RuleList = (props: RuleListProps) => {
	const { formatMessage } = useIntl();
	const { rules, executingRuleId, executeRule, setClosed, selectedIssueIds, recommendations } =
		props;
	const [canManageAutomations] = useCanManageAutomations();
	const [baseAutomationUrl] = useBaseAutomationUrl();

	if (rules.length === 0) {
		return (
			<ConditionalRecommendation
				canManageAutomations={canManageAutomations}
				baseAutomationUrl={baseAutomationUrl}
				recommendations={recommendations}
				fallback={<EmptyState />}
				setClosed={setClosed}
			/>
		);
	}

	return (
		<>
			<RulesContainer>
				<MountEvent
					onMount={(event) => fireOperationalAnalyticsDeferred(event, 'ruleList mounted')}
				/>
				{rules.map((rule: ManualRule) => (
					<Rule
						key={`manual-rule-${rule.id}`}
						rule={rule}
						isExecuting={rule.id === executingRuleId}
						executeRule={executeRule}
						selectedIssueIds={selectedIssueIds}
					/>
				))}
				{canManageAutomations && (
					<MenuLink
						itemKey="CREATE_MANUAL_RULE"
						href={`${baseAutomationUrl}#rule/new?componentFilterCriteria=QUICK_ACTIONS`}
						iconBefore={
							<AddIcon>
								<CreateRuleIcon size="small" label="" />
							</AddIcon>
						}
					>
						{formatMessage(messages.createManualRule)}
					</MenuLink>
				)}
			</RulesContainer>
			<ConditionalRecommendation
				canManageAutomations={canManageAutomations}
				baseAutomationUrl={baseAutomationUrl}
				recommendations={recommendations}
				setClosed={setClosed}
			/>
		</>
	);
};

export default RuleList;
