import 'rxjs/add/observable/zip';
import isNil from 'lodash/isNil';
import set from 'lodash/set';
import type { Observable } from 'rxjs/Observable';
import { UNSCHEDULED_COLUMN_ID } from '@atlassian/jira-common-constants/src/column-types.tsx';
import { statusCategoryForId } from '@atlassian/jira-common-constants/src/status-categories.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import { SWIMLANE_TEAMLESS } from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/constants.tsx';
import { CLOSED_SPRINT_STATE } from '@atlassian/jira-shared-types/src/rest/jira/sprint.tsx';
import type { BoardPermissions } from '../../model/board/board-types.tsx';
import type { ColumnId } from '../../model/column/column-types.tsx';
import {
	FULL,
	MIXED,
	NONE,
	type InlineCardCreateConfig,
} from '../../model/inline-create/inline-card-create-types.tsx';
import type { Team } from '../../model/team/team-types.tsx';
import type { WorkData } from '../../model/work/work-types.tsx';
import { getIsIncrementPlanningBoardEmbed } from '../../state/selectors/software/software-selectors.tsx';
import type { ApiStatusForIp, ApiWorkDataForIp } from './types.tsx';

// This function is just temporary ICC availability config solution on the FE side.
// in the near future, we will move it to BE. which will be addressed in the ticket https://hello.jira.atlassian.cloud/browse/JPO-26858
export const getInlineCardCreate = (
	teams: Team[] | undefined,
	createIssuePermission?: boolean,
): InlineCardCreateConfig | undefined => {
	if (!createIssuePermission) {
		return { mode: NONE };
	}

	const hasExternalTeams = teams?.some((team) => team.isPlanTeam === false);
	let isFullMode = !hasExternalTeams;
	const initalConfig: InlineCardCreateConfig = {
		mode: MIXED,
		swimlanes: { [SWIMLANE_TEAMLESS]: FULL },
	};

	const inlineCardCreateConfig: InlineCardCreateConfig | undefined = teams?.reduce((acc, team) => {
		if (acc.swimlanes !== undefined && team.isPlanTeam) {
			const sprintByIterationId = team.sprintByIterationId;
			if (!isNil(sprintByIterationId)) {
				const columnsIcc = Object.keys(sprintByIterationId).reduce(
					(columnsIccAcc: Record<ColumnId, { mode: typeof FULL }>, columnId: string) => {
						const sprint = sprintByIterationId[Number(columnId)];
						if (sprint.state === CLOSED_SPRINT_STATE) {
							isFullMode = false;
						} else {
							set(columnsIccAcc, [columnId], {
								mode: FULL,
							});
						}
						return columnsIccAcc;
					},
					{
						[UNSCHEDULED_COLUMN_ID]: {
							mode: FULL,
						},
					},
				);
				acc.swimlanes[team.id] = columnsIcc;
			} else {
				acc.swimlanes[team.id] = FULL;
			}
		}
		return acc;
	}, initalConfig);
	return isFullMode ? { mode: FULL } : inlineCardCreateConfig;
};

function transformStatuses(response: ApiWorkDataForIp): WorkData['statuses'] {
	return response.statuses.map((s: ApiStatusForIp) => ({
		id: s.id,
		name: s.name,
		category: statusCategoryForId(s.categoryId),
	}));
}

export function getBoardPermissions(
	boardPermissions: BoardPermissions,
	isEmbed: boolean,
): BoardPermissions {
	return isEmbed
		? {
				createIssue: false,
				editIssue: false,
				deleteIssue: false,
				manageSprint: false,
				editBoardConfig: false,
				manageAutomation: false,
				canRelease: false,
				createSprint: false,
				archiveIssue: false,
			}
		: boardPermissions;
}

export const fetchPlanDataRest = (
	planId: number,
	scenarioId: number,
	incrementId: number,
): Observable<WorkData> =>
	fetchJson$<ApiWorkDataForIp>(
		`/rest/jpo/2.0/plans/${planId}/increment/board/${incrementId}?scenarioId=${scenarioId}`,
		{
			method: 'GET',
		},
	).map((json: ApiWorkDataForIp): WorkData => {
		if (fg('smart_links_for_plans')) {
			const isEmbed = getIsIncrementPlanningBoardEmbed();
			return {
				...json,
				statuses: transformStatuses(json),
				inlineCardCreate: getInlineCardCreate(
					json?.teams,
					!isEmbed && json.boardPermissions.createIssue,
				),
				boardPermissions: getBoardPermissions(json.boardPermissions, isEmbed),
			};
		}
		return {
			...json,
			statuses: transformStatuses(json),
			inlineCardCreate: getInlineCardCreate(json?.teams, json.boardPermissions.createIssue),
		};
	});
