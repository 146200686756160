import React, { type ReactNode } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';

type IssueNumberInlineEditErrorBoundaryProps = {
	fallback: ReactNode;
	children?: ReactNode;
	packageName: string;
	onError?: () => void;
};

export const NumberInlineEditErrorBoundary = ({
	fallback,
	children,
	packageName,
	onError,
}: IssueNumberInlineEditErrorBoundaryProps) => (
	<JSErrorBoundary
		id="number-inline-edit"
		packageName={packageName}
		teamName="bento"
		fallback="flag"
		onError={onError}
	>
		<Placeholder name="number-inline-edit-error-boundary" fallback={fallback}>
			{children}
		</Placeholder>
	</JSErrorBoundary>
);
