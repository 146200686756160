.compactLozenge.compactLozenge {
	display: none;
}

@container column-header (width < 275px) {
	.compactLozenge.compactLozenge {
		display: inline;
	}

	.defaultLozenge.defaultLozenge {
		display: none;
	}

	.defaultLozengeContainer.defaultLozengeContainer {
		display: none;
	}

	.compactColumnTitle.compactColumnTitle {
		/* Remove flex: 1 on jsw_compiled_migration_tanuki FF cleanup */
		flex: 1;
		margin: 0;
	}
}
