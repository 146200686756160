import {
	createStore,
	createHook,
	createActionsHook,
	type Action,
} from '@atlassian/react-sweet-state';

type State = {
	nudgeId: string | undefined;
	projectKey: string;
};

const initialState: State = {
	nudgeId: undefined,
	projectKey: '',
};

const actions = {
	setNudgeActive:
		(projectKey: string, nudgeId: string): Action<State> =>
		({ setState }) =>
			setState({ projectKey, nudgeId }),
	dismissNudge:
		(): Action<State> =>
		({ setState }) =>
			setState({ nudgeId: undefined, projectKey: '' }),
};

const Store = createStore<State, typeof actions>({
	initialState,
	actions,
	name: 'GetStartedSingleNudge',
});

export const useOnboardingGetStartedSingleNudgeActions = createActionsHook(Store);

export const useOnboardingGetStartedSingleNudge = createHook(Store);
