import React from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';

import Tooltip from '@atlaskit/tooltip';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { UNASSIGNED_ID } from '../../../constants.tsx';
import type { UserValue } from '../../../types.tsx';
import { getUnassignedUser, getUserAvatarUrl } from '../../../utils.tsx';
import messages from './messages.tsx';

export type Props = {
	readonly value?: UserValue;
};

export const AssigneePickerView = ({ value }: Props) => {
	const intl = useIntl();
	const unassigned = getUnassignedUser(intl);
	const user = value || unassigned;
	const assigneeName =
		user.accountId === UNASSIGNED_ID
			? user.displayName
			: intl.formatMessage(messages.assigneeTooltip, {
					name: user.displayName,
				});
	const avatarUrl = getUserAvatarUrl(user);

	return (
		<AvatarWrapper>
			<Tooltip content={assigneeName}>
				<Avatar
					testId="issue-field-assignee.common.ui.read-view.popover.avatar"
					size="small"
					src={avatarUrl}
					name={assigneeName}
					borderColor="transparent"
				/>
			</Tooltip>
		</AvatarWrapper>
	);
};

export default AssigneePickerView;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AvatarWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 3.5}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 3.5}px`,
	padding: 0,
	boxSizing: 'border-box',
	cursor: 'default',
});
