import { freeze } from 'icepick';
import { createSelector } from 'reselect';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import memoize from 'lodash/memoize';
import uniqBy from 'lodash/uniqBy';
import { ISSUE_HIERARCHY_LEVEL_EPIC } from '@atlassian/jira-issue-type-hierarchies/src/index.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Issue } from '../../../model/issue/issue-types.tsx';
import {
	NO_SWIMLANE,
	SWIMLANE_BY_ASSIGNEE,
	SWIMLANE_BY_ASSIGNEE_UNASSIGNED_FIRST,
	SWIMLANE_BY_JQL,
	SWIMLANE_BY_PARENT_ISSUE,
	SWIMLANE_BY_PROJECT,
	SWIMLANE_BY_REQUEST_TYPE,
	SWIMLANE_BY_SUBTASK,
	SWIMLANE_BY_TEAM,
} from '../../../model/swimlane/swimlane-modes.tsx';
import {
	ASSIGNEE,
	ChildlessSwimlane,
	JQL,
	PARENT,
	ParentlessSwimlane,
	PROJECT,
	REQUEST_TYPE,
	STORY,
	type Swimlane,
	type SwimlaneByTeamValues,
	type SwimlaneId,
	type SwimlaneValues,
	TEAM,
	TeamlessSwimlane,
	UnassignedRequestTypeSwimlane,
	UnassignedSwimlane,
} from '../../../model/swimlane/swimlane-types.tsx';
import type { IssuesState } from '../../reducers/entities/issues/types.tsx';
import type { RequestTypesState } from '../../reducers/entities/request-types/types.tsx';
import type { State } from '../../reducers/types.tsx';
import {
	getChildrenOfParent as getChildrenOfParentSelector,
	getIssueChildren as getIssueChildrenSelector,
} from '../issue-children/index.tsx';
import { issueParentsSelector } from '../issue-parent/index.tsx';
import {
	boardIssuesSelector,
	orderedIssuesWithSubtasksSelector,
} from '../issue/board-issue-selectors.tsx';
import { getIssueStatusSelector, isDoneIssueSelector } from '../issue/issue-selectors.tsx';
import { getCurrentUserAccountId, getPeople } from '../people/people-selectors.tsx';
import {
	getEntities,
	getIsCMPBoard,
	getIsIncrementPlanningBoard,
	getIssueProjects,
	getMissingParents,
	getRequestTypes,
	getTeams,
	getUi,
} from '../software/software-selectors.tsx';
import { getSwimlaneMode } from './swimlane-mode-selectors.tsx';

const assigneeWithoutUnassignedSwimlanesSelector = createSelector(
	[getPeople, getCurrentUserAccountId],
	(people, currentUserAccountId) =>
		Object.entries(people)
			.map(([id, person]): Swimlane => {
				const { id: assigneeId } = person;
				return {
					id,
					name: people[id].displayName,
					imageUrl: people[id].avatarUrl,
					values: {
						type: ASSIGNEE,
						assigneeAccountId: assigneeId,
					},
					issueKey: null,
				};
			})
			.sort((a, b) => {
				// Current user first and the rest of the users in alphabetical order
				if (a.id === currentUserAccountId) return -1;
				if (b.id === currentUserAccountId) return 1;
				return (a.name ?? '').localeCompare(b.name ?? '');
			}),
);

export const swimlanesAssigneeSelector = createSelector(
	[assigneeWithoutUnassignedSwimlanesSelector],
	(assigneeSwimlanes) => [...assigneeSwimlanes, { ...UnassignedSwimlane }],
);

export const swimlanesAssigneeUnassignedFirstSelector = createSelector(
	[assigneeWithoutUnassignedSwimlanesSelector],
	(assigneeSwimlanes) => [{ ...UnassignedSwimlane }, ...assigneeSwimlanes],
);

const swimlanesParentIssueSelector = createSelector(
	[issueParentsSelector, getIsCMPBoard],
	(issueParents, isCMPBoard) =>
		issueParents
			.filter((issue) =>
				issue.issueType.hierarchyLevel
					? issue.issueType.hierarchyLevel === ISSUE_HIERARCHY_LEVEL_EPIC
					: true,
			)
			.map(
				(issue): Swimlane => ({
					id: String(issue.id),
					name: issue.summary,
					imageUrl: issue.issueType.iconUrl,
					values: {
						type: PARENT,
						parentId: issue.id,
					},
					issueKey: issue.key,

					isFlagged: isCMPBoard ? issue.flagged : undefined,
				}),
			)
			.concat([
				{
					...ParentlessSwimlane,
				},
			]),
);

const swimlanesIssueChildrenSelector = createSelector(
	[orderedIssuesWithSubtasksSelector],
	(issuesWithSubtasks) =>
		issuesWithSubtasks
			.map(
				(issue): Swimlane => ({
					id: String(issue.id),
					name: issue.summary,
					imageUrl: issue.typeUrl,
					values: {
						type: STORY,
						parentId: issue.id,
					},
					issueKey: issue.key,
					parentId: issue.parentId,
					assigneeAccountId: issue.assigneeAccountId,
					isFlagged: issue.isFlagged,
				}),
			)
			.concat([{ ...ChildlessSwimlane }]),
);

export const swimlaneByJqlSelector = (state: State): Swimlane[] =>
	getEntities(state).jqlSwimlanes?.swimlanes.map((swimlane) => ({
		id: swimlane.id,
		name: swimlane.name,
		imageUrl: '',
		issueKey: null,
		values: {
			type: JQL,
			issueIds: swimlane.issueIds,
		},
	})) ?? [];

export const swimlaneByProjectSelector = createSelector([getIssueProjects], (issueProjects) =>
	Object.values(issueProjects)
		.map<Omit<Swimlane, 'projectKey'> & { projectKey: string }>(({ id, name, key, avatar }) => {
			const url = avatar ? new URL(avatar) : undefined;
			url?.searchParams.set('size', 'xsmall');
			return {
				id: String(id),
				name,
				imageUrl: url?.toString(),
				issueKey: null,
				projectKey: key,
				values: {
					type: PROJECT,
					projectId: id,
				},
			};
		})
		.sort((a, b) => a.projectKey.localeCompare(b.projectKey)),
);

export const swimlaneByTeamSelector = createSelector([getTeams], (teams): Swimlane[] => {
	const swimlanes = Object.values(teams)
		.map(
			({
				id,
				name,
				isPlanTeam,
				externalId,
				avatarUrl,
				planningStyle,
			}): Omit<Swimlane, 'name'> & { name: string } => ({
				id,
				name,
				imageUrl: avatarUrl || undefined,
				issueKey: null,
				values: {
					type: TEAM,
					externalId,
					isPlanTeam,
					planningStyle,
				},
			}),
		)
		.sort((a, b) => a.name.localeCompare(b.name));
	return [...swimlanes, TeamlessSwimlane];
});

export const requestTypeWithoutUnassignedSwimlanesSelector = createSelector(
	[boardIssuesSelector, getRequestTypes],
	(issues: IssuesState, requestTypes: RequestTypesState) => {
		if (isEmpty(requestTypes)) {
			return [];
		}

		return uniqBy(
			Object.keys(issues)
				.filter((key: string) => {
					const issue: Issue = issues[key];
					return !!(issue.requestTypeId && requestTypes[issue.requestTypeId]);
				})
				.map((key: string): Swimlane => {
					const issue: Issue = issues[key];
					// requestTypeId will always have a value, because we are filtering out issues without a request type
					const { id, name, iconUrl } = requestTypes[issue.requestTypeId ?? ''];
					return {
						id,
						name,
						imageUrl: iconUrl,
						values: {
							type: REQUEST_TYPE,
							id,
						},
						issueKey: null,
					};
				}),
			'id',
		);
	},
);

export const swimlaneByRequestTypeSelector = createSelector(
	[requestTypeWithoutUnassignedSwimlanesSelector],
	(requestTypeSwimlanes) => [...requestTypeSwimlanes, { ...UnassignedRequestTypeSwimlane }],
);

const noSwimlanes = freeze([]);

export const getSwimlanes = (state: State): Swimlane[] => {
	const swimlaneMode = getSwimlaneMode(state);
	const isIncrementalPlanningBoard = getIsIncrementPlanningBoard(state);

	if (swimlaneMode === NO_SWIMLANE.id) {
		return noSwimlanes;
	}
	if (swimlaneMode === SWIMLANE_BY_ASSIGNEE.id) {
		return swimlanesAssigneeSelector(state);
	}
	if (swimlaneMode === SWIMLANE_BY_ASSIGNEE_UNASSIGNED_FIRST.id) {
		return swimlanesAssigneeUnassignedFirstSelector(state);
	}
	if (swimlaneMode === SWIMLANE_BY_PARENT_ISSUE.id) {
		return swimlanesParentIssueSelector(state);
	}
	if (swimlaneMode === SWIMLANE_BY_SUBTASK.id) {
		return swimlanesIssueChildrenSelector(state);
	}
	if (swimlaneMode === SWIMLANE_BY_JQL.id) {
		return swimlaneByJqlSelector(state);
	}
	if (getIsCMPBoard(state) && swimlaneMode === SWIMLANE_BY_PROJECT.id) {
		return swimlaneByProjectSelector(state);
	}
	if (isIncrementalPlanningBoard && swimlaneMode === SWIMLANE_BY_TEAM.id) {
		return swimlaneByTeamSelector(state);
	}
	if (swimlaneMode === SWIMLANE_BY_REQUEST_TYPE.id) {
		return swimlaneByRequestTypeSelector(state);
	}
	return noSwimlanes;
};

export const makeSwimlaneValuesSelector = createSelector(getSwimlanes, (swimlanes: Swimlane[]) => {
	const makeSwimlaneValues = (swimlaneId: SwimlaneId) =>
		get(
			swimlanes.find((swimlane) => swimlane.id === swimlaneId),
			['values'],
			null,
		);
	return memoize(makeSwimlaneValues);
});

export const isDefaultSwimlane = (swimlaneId: SwimlaneId): boolean =>
	swimlaneId === UnassignedSwimlane.id ||
	swimlaneId === ParentlessSwimlane.id ||
	swimlaneId === ChildlessSwimlane.id;

export const isInDefaultSwimlane = (state: State) => {
	const inDefaultSwimlaneCheck = (swimlaneId?: SwimlaneId | null): boolean => {
		if (!swimlaneId) return false;
		const swimlaneMode = getSwimlaneMode(state);
		if (
			swimlaneMode === SWIMLANE_BY_ASSIGNEE.id ||
			swimlaneMode === SWIMLANE_BY_ASSIGNEE_UNASSIGNED_FIRST.id
		) {
			return swimlaneId === UnassignedSwimlane.id;
		}
		if (swimlaneMode === SWIMLANE_BY_PARENT_ISSUE.id) {
			return swimlaneId === ParentlessSwimlane.id;
		}
		if (swimlaneMode === SWIMLANE_BY_SUBTASK.id) {
			return swimlaneId === ChildlessSwimlane.id;
		}
		return false;
	};
	return inDefaultSwimlaneCheck;
};

export const getCollapsedSwimlanes = (state: State) => getUi(state).swimlane.collapsed;

export const isSwimlaneCollapsed = (state: State, swimlaneId: SwimlaneId) =>
	getUi(state).swimlane.collapsed.includes(swimlaneId);

export const getIsSwimlaneCollapsed = (state: State) => (swimlaneId: SwimlaneId) =>
	getUi(state).swimlane.collapsed.includes(swimlaneId);

export const makeIsSwimlaneCollapsed = createSelector(getCollapsedSwimlanes, (collapsedSwimlanes) =>
	memoize((swimlaneId) => collapsedSwimlanes.includes(swimlaneId)),
);

export const isSwimlaneBySubtaskParentDoneSelector = createSelector(
	[isDoneIssueSelector, getIssueStatusSelector, getSwimlaneMode, getIsCMPBoard, getMissingParents],
	(isDoneIssue, getIssueStatus, swimlaneMode, isCMPBoard, missingParents) => {
		const isSwimlaneBySubtaskParentDone = (issueId: IssueId) => {
			const missingParentIssue = missingParents[issueId];

			// In certain situations, an issue's `isDone` key isn't `true` even when the issue's status' category is done.
			// But the `/transitionAndRank` endpoint (which the swimlane "Move to Done" button calls) doesn't return enough info
			// to update issue state to the new status, so only the issue's `isDone` key is set to `true` when using that functionality.
			// Using a combination of both allows us to ensures the button is always hidden correctly when the issue might be done.
			const isDone = isNil(missingParentIssue)
				? isDoneIssue(issueId) || getIssueStatus(issueId)?.category === 'DONE'
				: missingParentIssue.status.category === 'DONE';

			return swimlaneMode === SWIMLANE_BY_SUBTASK.id && isCMPBoard && isDone;
		};

		return memoize(isSwimlaneBySubtaskParentDone);
	},
);

export const areSwimlaneBySubtaskParentChildrenDoneSelector = createSelector(
	[getSwimlaneMode, getIsCMPBoard, getChildrenOfParentSelector, getIssueChildrenSelector],
	(swimlaneMode, isCMPBoard, getChildrenOfParent, issueChildren) => {
		const areSwimlaneBySubtaskParentChildrenDone = (swimlaneValues: SwimlaneValues | undefined) => {
			const parentChildren =
				swimlaneValues?.type === STORY && swimlaneValues?.parentId
					? getChildrenOfParent(swimlaneValues?.parentId)
					: [];
			return (
				swimlaneMode === SWIMLANE_BY_SUBTASK.id &&
				isCMPBoard &&
				parentChildren.length > 0 &&
				parentChildren.every((childId) => issueChildren[childId]?.isDone)
			);
		};
		return memoize(areSwimlaneBySubtaskParentChildrenDone);
	},
);

export const getIsExternalTeamSwimlane = createSelector(
	[swimlaneByTeamSelector],
	(swimlanesByTeam) => (swimlaneId: string) => {
		const swimlane = swimlanesByTeam.filter((s: Swimlane) => s.id === swimlaneId);
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return swimlane.length > 0 && !(swimlane[0].values as SwimlaneByTeamValues)?.isPlanTeam;
	},
);
