import { useEffect } from 'react';
import {
	useIssueContextActions,
	useViewMode,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	getBoardDetailViewMode,
	setBoardDetailViewMode,
} from '@atlassian/jira-software-board-common/src/index.tsx';

// When navigating to the Board, this hook will set the View Mode (Modal/Sidebar) of the Issue View based on a localStorage value.
// This is useful when navigating from the Backlog (where the Issue View is always set to Sidebar) to the Board and we don't want to lose the user's preferences set on the Board.
export const useSetViewModeFromLocalStorage = () => {
	const [, { setDetailViewAsModal }] = useIssueContextActions();
	const localStorageViewMode = getBoardDetailViewMode();
	const viewMode = useViewMode();

	useEffect(() => {
		if (localStorageViewMode === 'MODAL') {
			setDetailViewAsModal();
		}
	}, [localStorageViewMode, setDetailViewAsModal]);

	// Sync the localStorage value with viewMode from jira-issue-context-service (the source of truth).
	useEffect(() => {
		if (viewMode && viewMode !== localStorageViewMode) {
			setBoardDetailViewMode(viewMode);
		}
	}, [localStorageViewMode, viewMode]);
};
