import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import mapKeys from 'lodash/mapKeys';
import { fireTrackAnalytics } from '@atlassian/jira-analytics-web-react/src/utils/fire-track-event.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';
import { getFeatureAnalyticsAttributes } from '@atlassian/jira-software-feature-analytics/src/common/utils.tsx';
import { View } from '@atlassian/jira-software-view-settings/src/common/types/constant.tsx';
import { getViewSettings as getViewSettingsFromLocalStorage } from '@atlassian/jira-software-view-settings/src/services/local-storage/index.tsx';
import {
	ISSUE_COUNT,
	ORIGINAL_ESTIMATE,
	ORIGINAL_ESTIMATE_FIELD_ID,
	STORY_POINTS,
} from '../../../../model/constants.tsx';
import { NO_SWIMLANE } from '../../../../model/swimlane/swimlane-modes.tsx';
import type {
	EstimationStatisticFieldId,
	EstimationType,
} from '../../../../model/work/work-types.tsx';
import type {
	BoardViewAction,
	WorkRefreshDataAction,
	BoardNonCriticalViewAction,
} from '../../../actions/work/index.tsx';
import type { State } from '../../../reducers/types.tsx';
import { boardPermissionsSelector } from '../../../selectors/board/board-permissions-selectors.tsx';
import {
	hasConditionsOnTransitions,
	hasNonGlobalTransitions,
} from '../../../selectors/card/card-selectors.tsx';
import {
	doneColumnIdSelector,
	getDoneColumnsSelector,
	hasColumnLimits,
	hasDoneColumnInLastPositionSelector,
	hasMoreThanFiveColumn,
	hasMultipleDoneColumnSelector,
	initialColumnIdSelector,
} from '../../../selectors/column/column-selectors.tsx';
import {
	getInlineCardCreateConfig,
	hasInlineCardCreateConfig,
	getIsCustomBoardWithIcc,
} from '../../../selectors/inline-create/inline-create-selectors.tsx';
import {
	numCompleteIssueChildren,
	numTotalIssueChildren,
} from '../../../selectors/issue-children/index.tsx';
import { issueParentIdsSelector } from '../../../selectors/issue-parent/index.tsx';
import { issueTypeIdsSelector } from '../../../selectors/issue-type/issue-type-selectors.tsx';
import { issueLabelsSelector } from '../../../selectors/issue/issue-selectors.tsx';
import { getPeople } from '../../../selectors/people/people-selectors.tsx';
import {
	getBoardTypeForAnalytics,
	getEntities,
	getIsCMPBoard,
	getIsIncrementPlanningBoard,
	getIncrementConfig,
	getIssueProjectIds,
	projectIdSelector,
	rapidViewIdSelector,
	getIsDueDateEnabled,
} from '../../../selectors/software/software-selectors.tsx';
import { getSwimlaneMode } from '../../../selectors/swimlane/swimlane-mode-selectors.tsx';
import { getSwimlanes } from '../../../selectors/swimlane/swimlane-selectors.tsx';
import {
	getBoardHasFilteredIssues,
	getColumnTotalIssuesCount,
	getEstimationStatistic,
	isInlineColumnEditEnabled,
	workColumnsSelector,
	workIssuesSelector,
	isCardMediaEnabled,
	getNumberOfCardsWithCovers,
	getNumberOfCardsWithDueDates,
	getCustomFilters,
} from '../../../selectors/work/work-selectors.tsx';

export const mapEstimationCustomFieldIdToEstimationType = (
	estimationStatisticFieldId: EstimationStatisticFieldId,
): EstimationType => {
	if (isEmpty(estimationStatisticFieldId)) {
		return ISSUE_COUNT;
	}
	if (estimationStatisticFieldId === ORIGINAL_ESTIMATE_FIELD_ID) {
		return ORIGINAL_ESTIMATE;
	}

	if (!estimationStatisticFieldId.startsWith('customfield_')) {
		log.safeErrorWithoutCustomerData(
			'board.critical.load.boardscope.transform.failure',
			`Could not map estimation with field id ${estimationStatisticFieldId} to supported estimation types`,
		);
	}
	return STORY_POINTS;
};

export const boardNonCriticalView =
	(eventPrefix: string) => (preState: State, state: State, action: BoardNonCriticalViewAction) => {
		const isCMP = getIsCMPBoard(state);
		const isClassicCardCoverViewSettingEnabled = getViewSettingsFromLocalStorage({
			boardId: Number(rapidViewIdSelector(state)),
			view: View.BOARD,
		})?.showCardCovers;

		const hasCovers =
			isCMP && fg('jsw_cmp_card_cover_images')
				? isClassicCardCoverViewSettingEnabled
				: isCardMediaEnabled(state);

		const issueLinkStatsViewSettings = {
			showIssueLinksStatsViewSettings: Boolean(
				getViewSettingsFromLocalStorage({
					boardId: Number(rapidViewIdSelector(state)),
					view: View.BOARD,
				})?.showIssueLinksStats,
			),
		};

		const dueDateStats = fg('jsw_due_date_metrics')
			? {
					showDueDateViewSettings:
						getIsDueDateEnabled(state) &&
						Boolean(
							getViewSettingsFromLocalStorage({
								boardId: Number(rapidViewIdSelector(state)),
								view: View.BOARD,
							})?.showDueDate,
						),
					numCardsWithDueDates: getNumberOfCardsWithDueDates(state),
				}
			: {};

		const attributes = {
			numCards: workIssuesSelector(state).length,
			numCardsWithCovers: hasCovers ? getNumberOfCardsWithCovers(state) : 0,
			hasCovers,
			...issueLinkStatsViewSettings,
			...dueDateStats,
		};

		const cardCoverAnalyticsAttributes = {
			action: 'viewed',
			containerType: 'project',
			containerId: String(projectIdSelector(state)),
			objectType: 'board',
			objectId: String(rapidViewIdSelector(state)),
			attributes,
		};

		fireTrackAnalytics(action.meta.analytics, cardCoverAnalyticsAttributes);

		return {
			name: `${eventPrefix}.view`,
			data: {},
		};
	};

export const boardView =
	(eventPrefix: string) => (preState: State, state: State, action: BoardViewAction) => {
		// Performance based marks on board view
		const issueNo = workIssuesSelector(state).length;
		const people = getPeople(state);
		const doneColumnId = doneColumnIdSelector(state);
		const numIssuesInDoneColumn = {
			numIssuesInDoneColumn: getColumnTotalIssuesCount(state, doneColumnId),
		};
		const toDoColumnId = initialColumnIdSelector(state);
		const numIssuesInToDoColumn = {
			numIssuesInToDoColumn: getColumnTotalIssuesCount(state, toDoColumnId),
		};
		const featureAttributes: {
			[key: string]: boolean;
		} = getFeatureAnalyticsAttributes(
			get(getEntities(state), ['board', 'config', 'allFeatures'], []),
		);
		const permissions: {
			[key: string]: boolean | undefined;
		} = mapKeys(boardPermissionsSelector(state), (permission, key) => `has_${key}_permission`);

		const hasSwimlanes = getSwimlaneMode(state) !== NO_SWIMLANE.id;
		const swimlanesPayload = {
			hasSwimlanes,
			numSwimlanes: hasSwimlanes ? getSwimlanes(state).length : 0,
			numVisibleSwimlanes: hasSwimlanes ? getSwimlanes(state).length : 0,
		};
		const estimationType: EstimationType = mapEstimationCustomFieldIdToEstimationType(
			getEstimationStatistic(state),
		);

		const isCompanyManaged = getIsCMPBoard(state);
		const isQuickFiltersBarToggled = getViewSettingsFromLocalStorage({
			boardId: Number(rapidViewIdSelector(state)),
			view: View.BOARD,
		})?.showQuickFilters;
		const numQuickFilters = getCustomFilters(state).length;

		const cmpAttributes = isCompanyManaged && {
			numQuickFilters,
			isQuickFiltersBarToggled,
		};

		const isIPBoard = getIsIncrementPlanningBoard(state);
		let ipAttributes;
		if (isIPBoard && fg('program_board_flexible_cadence')) {
			const { iterationLength, iterationsCount } = getIncrementConfig(state) ?? {};
			ipAttributes = {
				iterationLength,
				iterationsCount,
			};
		}

		const isUnscheduledWorkColumnPanel = action.payload.isUnscheduledWorkColumnPanel;

		const boardViewAnalyticsAttributes = {
			action: 'viewed',
			containerType: 'project',
			containerId: String(projectIdSelector(state)),
			objectType: 'board',
			objectId: String(rapidViewIdSelector(state)),
			attributes: {
				...featureAttributes,
				...permissions,
				boardId: rapidViewIdSelector(state),
				boardType: getBoardTypeForAnalytics(state),
				numIssues: issueNo,
				numSubtasks: numTotalIssueChildren(state),
				numCompleteSubtasks: numCompleteIssueChildren(state),
				numIssueParents: issueParentIdsSelector(state).length,
				numIssueTypes: issueTypeIdsSelector(state).length,
				numColumns: workColumnsSelector(state).length,
				numLabels: issueLabelsSelector(state).length,
				hasHorizontalScrollbar: action.payload.hasHorizontalScrollbar,
				hasFilteredIssues: getBoardHasFilteredIssues(state),
				hasColumnLimits: hasColumnLimits(state),
				hasNonGlobalTransitions: hasNonGlobalTransitions(state),
				hasConditionsOnTransitions: hasConditionsOnTransitions(state),
				hasDoneColumnInLastPosition: hasDoneColumnInLastPositionSelector(state),
				hasMoreThanFiveColumn: hasMoreThanFiveColumn(state),
				hasMultipleDoneColumns: hasMultipleDoneColumnSelector(state),
				swimlaneMode: getSwimlaneMode(state),
				numberOfAssigneesOnBoard: Object.keys(people).length,
				numDoneColumns: getDoneColumnsSelector(state).length,
				hasInlineColumnEdit: isInlineColumnEditEnabled(state),
				hasInlineCardCreate: hasInlineCardCreateConfig(state),
				isCustomBoardWithIcc: getIsCustomBoardWithIcc(state),
				inlineCardCreateMode: getInlineCardCreateConfig(state)?.mode,
				...numIssuesInDoneColumn,
				...numIssuesInToDoColumn,
				...swimlanesPayload,
				estimationStatistic: estimationType,
				issueResultProjectIds: getIssueProjectIds(state),
				...cmpAttributes,
				...ipAttributes,
			},
		};

		if (!isUnscheduledWorkColumnPanel) {
			// we don't want to fire analytics if the board is the unscheduled work panel (increment planning)
			// as this is unneccessarily doubling the analytics events on the page
			fireTrackAnalytics(action.meta.analytics, boardViewAnalyticsAttributes);
		}

		return {
			name: `${eventPrefix}.view`,
			data: {
				hasHorizontalScrollbar: action.payload.hasHorizontalScrollbar,
				hasFilteredIssues: getBoardHasFilteredIssues(state),
				hasColumnLimits: hasColumnLimits(state),
				numIssues: issueNo,
				numIssueParents: issueParentIdsSelector(state).length,
				numIssueTypes: issueTypeIdsSelector(state).length,
				numColumns: workColumnsSelector(state).length,
				numLabels: issueLabelsSelector(state).length,
				...swimlanesPayload,
			},
		};
	};

export const boardLoadFailure = (eventPrefix: string) => () => ({
	name: `${eventPrefix}.load.failure`,
	data: {},
});

export const boardLoadNoColumnFailure = (eventPrefix: string) => () => ({
	name: `${eventPrefix}.load.failure.no-column`,
	data: {},
});

export const workRefreshData =
	(eventPrefix: string) => (preState: State, state: State, action: WorkRefreshDataAction) => {
		getAnalyticsWebClientPromise().then((client) => {
			const payload = {
				action: 'triggered',
				actionSubject: 'workRefreshData',
				actionSubjectId: `${eventPrefix}.refresh`,
				source: 'boardScreen',
				attributes: {
					source: action.payload.source,
				},
			};
			client.getInstance().sendTrackEvent(payload);
		});

		return {
			name: `${eventPrefix}.refresh`,
			data: {
				source: action.payload.source,
			},
		};
	};
