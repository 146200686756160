import memoize from 'lodash/memoize';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { ColumnType } from '@atlassian/jira-common-constants/src/column-types.tsx';
import type { ColumnMenu } from '@atlassian/jira-platform-board-kit/src/common/ui/column-header/main.tsx';
import { fireUIAnalytics, AnalyticsEventToProps } from '@atlassian/jira-product-analytics-bridge';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { Capability } from '../../../../../common/capability/index.tsx';
import type { ColumnId } from '../../../../../model/column/column-types.tsx';
import { CAN_RENAME_COLUMNS } from '../../../../../model/permission/permission-types.tsx';
import { columnRenameRequest } from '../../../../../state/actions/column/rename/index.tsx';
import { getPermissionsSelector } from '../../../../../state/selectors/board/board-permissions-selectors.tsx';
import { getCapability } from '../../../../../state/selectors/capability/capability-selectors.tsx';
import {
	draggingCardTargetColumnIdSelector,
	draggingCardHoverIntentSelector,
} from '../../../../../state/selectors/card/card-selectors.tsx';
import {
	getColumnById,
	getColumnIndex,
	getColumnLozenges,
	isDoneColumn,
	isLastColumn,
	makeValidateColumnSelector,
} from '../../../../../state/selectors/column/column-selectors.tsx';
import { getIsIncrementPlanningBoard } from '../../../../../state/selectors/software/software-selectors.tsx';
import {
	getColumnIssuesCount,
	getColumnIssuesCountWithLimit,
	getColumnTotalIssuesCount,
	hasWorkFiltersSelector,
	isAnyColumnUpdating,
	isInlineColumnEditEnabled,
} from '../../../../../state/selectors/work/work-selectors.tsx';
import type { State, Dispatch } from '../../../../../state/types.tsx';
import ColumnHeaderTransitionOverlay from './transition-overlay/index.tsx';
import Header from './view.tsx';

type OwnProps = {
	id: number;
	showShadowOnScroll?: boolean;
	menu: ColumnMenu | null;
	isMenuShown?: boolean;
};

export const HeaderWithAnalytics = AnalyticsEventToProps('columnHeader', {
	onEditStart: 'editStarted',
	onEditCancel: 'editCanceled',
	onHeaderClick: 'clicked',
})(Header);

const getExternalValues = memoize((columnIndex) => ({
	columnIndex: columnIndex + 1,
}));

const EMPTY_LIST: ReturnType<typeof getColumnLozenges> = [];
const mapStateToProps = (state: State, ownProps: OwnProps) => {
	const column = getColumnById(state, ownProps.id);
	const columnIndex = getColumnIndex(state, ownProps.id);

	const getCardsCount = () => {
		/**
		 * We do NOT display cards count in column headers of increment planning boards
		 * Therefore we set cardsCount={undefined} which eventually hides the issue count
		 * in board-kit/src/common/ui/column-header/editable-title/column-title ({!!visibleCount && <CountWrapper />})
		 * */
		const isIncrementPlanningBoard = getIsIncrementPlanningBoard(state);
		if (isIncrementPlanningBoard) {
			return undefined;
		}
		// in non-incremental planning boards, the issue count is displayed in column headers
		return __SERVER__
			? getColumnIssuesCountWithLimit(state, ownProps.id)
			: getColumnIssuesCount(state, ownProps.id);
	};
	const totalCardsCount = getColumnTotalIssuesCount(state, ownProps.id);
	const showTotalCardsCount = __SERVER__ || hasWorkFiltersSelector(state);
	const lozenges =
		column?.type === ColumnType.STATUS
			? getColumnLozenges(totalCardsCount, column?.maxIssueCount, column?.minIssueCount)
			: EMPTY_LIST;
	const canRenameColumns =
		getPermissionsSelector(state)[CAN_RENAME_COLUMNS] && isInlineColumnEditEnabled(state);

	const OverlayContent =
		!getCapability(state)(Capability.HIDE_COLUMN_HEADER_OVERLAY) &&
		draggingCardTargetColumnIdSelector(state) === ownProps.id &&
		draggingCardHoverIntentSelector(state)
			? ColumnHeaderTransitionOverlay
			: undefined;

	return {
		title: column?.name ?? '',
		lozenges,
		index: columnIndex,
		cardsCount: getCardsCount(),
		totalCardsCount,
		showTotalCardsCount,
		// disable "column edit" feature (for all columns) during column update.
		// JIRA API does not support multi columns updates at the same time
		isDone: isDoneColumn(column),
		isRenamable: canRenameColumns && !isAnyColumnUpdating(state),
		isDisabled: isAnyColumnUpdating(state),
		// copy ownProps or set default values
		showShadowOnScroll: ownProps.showShadowOnScroll || false,
		menu: ownProps.menu || null,
		validateRename: makeValidateColumnSelector(state),
		// analytics
		externalId: 'board.column-header',
		externalValues: getExternalValues(columnIndex),
		onEditStart: noop,
		onEditCancel: noop,
		onHeaderClick: noop,
		OverlayContent,
		isLastColumn: isLastColumn(state)(ownProps.id),
	};
};

const ConnectedHeaderWithAnalytics = connect(mapStateToProps, (dispatch: Dispatch) => ({
	onRename: (columnId: ColumnId, title: string, analyticsEvent: UIAnalyticsEvent) => {
		dispatch(columnRenameRequest(columnId, title, analyticsEvent));
	},
	onEditStart: (analyticsEvent: UIAnalyticsEvent) => fireUIAnalytics(analyticsEvent),
	onEditCancel: (columnId: number, type: string, analyticsEvent: UIAnalyticsEvent) =>
		fireUIAnalytics(analyticsEvent, { columnId, type }),
	onHeaderClick: (analyticsEvent: UIAnalyticsEvent) => fireUIAnalytics(analyticsEvent),
}))(HeaderWithAnalytics);

export default ConnectedHeaderWithAnalytics;
