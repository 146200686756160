import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	unlink: {
		id: 'portfolio-3-dependency-line-detail.common.ui.content.common.components.unlink-button.unlink',
		defaultMessage: 'Remove dependency',
		description:
			'Tooltip label for when you are trying to remove a dependency after clicking on a dependency line.',
	},
});
