import get from 'lodash/get';
import max from 'lodash/max';
import type { PayloadError } from 'relay-runtime';
import type { GraphqlError } from '@atlassian/jira-software-swag/src/common/types.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PartialError = Partial<Error> & Record<PropertyKey, any>;
type BackendError = Readonly<GraphqlError | PayloadError>;

export const getStatusCodeFromGraphQLErrors = (
	graphQLErrors: ReadonlyArray<BackendError>,
): number | null => {
	const statusCodes = graphQLErrors
		.map((e) => get(e, ['extensions', 'statusCode']))
		.filter((statusCode) => !!statusCode);
	const statusCode = max(statusCodes);
	return statusCode || null;
};

export const extractStatusCode = (error: PartialError): number | null => {
	const errorStatusCode = get(error, ['statusCode'], null);
	const graphQLErrors = get(error, ['graphQLErrors'], null);
	const networkError = get(error, ['networkError'], null);
	const statusCode = get(networkError, ['statusCode'], null);

	if (typeof errorStatusCode === 'number') {
		return errorStatusCode;
	}

	if (typeof statusCode === 'number') {
		return statusCode;
	}

	if (Array.isArray(graphQLErrors) && graphQLErrors.length > 0) {
		return getStatusCodeFromGraphQLErrors(graphQLErrors);
	}

	return null;
};
