import React from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { AUTOMATION_MORE_DETAILS_URL } from '../../common/constants.tsx';
import { emptyState } from './icons/main.tsx';
import messages from './messages.tsx';

interface NoRuleForSelectedIssuesEmptyStateProps {
	baseAutomationUrl: string;
	canManageAutomations: boolean;
}

export const NoRuleForSelectedIssuesEmptyState = (
	props: NoRuleForSelectedIssuesEmptyStateProps,
) => {
	const { baseAutomationUrl, canManageAutomations } = props;
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();

	return (
		<Container>
			<EmptyState>{emptyState}</EmptyState>
			<Message>
				<FormattedMessage {...messages.noRuleForSelectedIssues} />
			</Message>
			<Box xcss={buttonContainerStyles}>
				{canManageAutomations ? (
					<Button
						appearance="default"
						href={`${baseAutomationUrl}#rule/new?componentFilterCriteria=QUICK_ACTIONS`}
						onClick={() => {
							const analyticsEvent = createAnalyticsEvent({
								actionSubject: 'button',
								action: 'clicked',
							});
							fireUIAnalytics(analyticsEvent, 'emptyStateAddRuleButton');
						}}
					>
						{formatMessage(messages.addRuleCta)}
					</Button>
				) : (
					<Button
						appearance="link"
						onClick={() => {
							const analyticsEvent = createAnalyticsEvent({
								actionSubject: 'link',
								action: 'clicked',
							});
							fireUIAnalytics(
								analyticsEvent,
								'manualRulesActionsMenu.emptyState.contantYourAdmin',
								{
									userType: 'endUser',
								},
							);
						}}
						href={AUTOMATION_MORE_DETAILS_URL}
					>
						{formatMessage(messages.learnMoreAboutAutomation)}
					</Button>
				)}
			</Box>
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${gridSize * 43.5}px`,
	display: 'block',
	textAlign: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyState = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 9}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 10}px`,
	display: 'inline-block',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Message = styled.div({
	padding: token('space.150', '12px'),
	textAlign: 'center',
});

const buttonContainerStyles = xcss({
	marginBottom: 'space.200',
});
