import { useEffect, useCallback } from 'react';
import keycode from 'keycode';
import {
	fireTrackAnalytics,
	useAnalyticsEvents,
	type Attributes,
} from '@atlassian/jira-product-analytics-bridge';

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
export const isMacOS = () => Boolean(navigator && /^Mac/.test(navigator.platform));

export const useGlobalSearch = (
	/** Callback to be fired when global search is used */
	callback: (arg1: { isMacOs: boolean; event: KeyboardEvent }) => void,
	getGlobalDocument: () => typeof document = () => document,
) => {
	useEffect(() => {
		const trackGlobalSearch = (event: KeyboardEvent) => {
			const isMacOs = isMacOS();
			if (
				(isMacOs && event.keyCode === keycode('f') && event.metaKey) ||
				(!isMacOs && event.keyCode === keycode('f') && event.ctrlKey)
			) {
				callback({ isMacOs, event });
			}
		};
		getGlobalDocument().addEventListener('keydown', trackGlobalSearch);
		return () => getGlobalDocument().removeEventListener('keydown', trackGlobalSearch);
	}, [callback, getGlobalDocument]);
};

type Props = {
	getGlobalDocument?: () => typeof document;
	fireAnalytics?: typeof fireTrackAnalytics;
	analyticsAttributes?: Attributes;
};

const GlobalSearchTracker = ({
	getGlobalDocument = () => document,
	fireAnalytics = fireTrackAnalytics,
	analyticsAttributes = {},
}: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const onGlobalSearch = useCallback(
		// @ts-expect-error - TS7031 - Binding element 'isMacOs' implicitly has an 'any' type.
		({ isMacOs }) => {
			const analyticsEvent = createAnalyticsEvent({
				action: 'triggered',
				actionSubject: 'globalSearch',
			});
			const attributes: Attributes = {
				...analyticsAttributes,
				isMacOs,
			};
			fireAnalytics(analyticsEvent, 'globalSearch triggered', attributes);
		},
		[analyticsAttributes, createAnalyticsEvent, fireAnalytics],
	);

	useGlobalSearch(onGlobalSearch, getGlobalDocument);

	return null;
};

export default GlobalSearchTracker;
