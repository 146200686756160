import { useCallback, useEffect } from 'react';
import { useFeedbackCollector } from '@atlassian/jira-business-feedback-collector/src/index.tsx';
import { useFlagsService } from '@atlassian/jira-flags';
import { useProject } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/project/index.tsx';
import type {
	MigrationId,
	MigrationStatus,
} from '@atlassian/jira-project-create-importer-common/src/common/types/index.tsx';
import {
	IMPORT_ADVANCED_ERRORS_FLAG_ID,
	IMPORT_IN_PROGRESS_FLAG_ID,
	IMPORT_PARTIAL_SUCCESS_FLAG_ID,
	IMPORT_SUCCESS_FLAG_ID,
} from '../../constants/index.tsx';
import { useDownloadLogs } from '../use-download-logs/index.tsx';
import {
	getAdvancedErrorFlag,
	getInProgressFlag,
	getPartialSuccessFlag,
	getSuccessFlag,
} from './utils.tsx';

type Props = {
	projectName: string;
	projectKey: string;
	migrationId?: MigrationId;
	// eslint-disable-next-line jira/react/handler-naming
	removeMigrationId: (projectKey: string) => void;
};

export const useFlags = ({ projectKey, projectName, migrationId, removeMigrationId }: Props) => {
	const { showFlag, dismissFlag, hasFlag } = useFlagsService();
	const openFeedbackModal = useFeedbackCollector();
	const { data } = useProject();
	const name = data?.name || '';
	const handleDownloadLogsClick = useDownloadLogs(migrationId, projectName || name);

	const dismissInProgressFlag = useCallback(() => {
		dismissFlag(IMPORT_IN_PROGRESS_FLAG_ID);
	}, [dismissFlag]);

	const onSuccess = useCallback(
		(migrationStatus: MigrationStatus) => {
			if (!hasFlag?.(IMPORT_SUCCESS_FLAG_ID)) {
				dismissInProgressFlag();
				removeMigrationId(projectKey || projectName);
				showFlag(getSuccessFlag(migrationStatus, projectName || name, openFeedbackModal));
			}
		},
		// omit openFeedbackModal
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dismissInProgressFlag, hasFlag, migrationId, projectKey, removeMigrationId, showFlag],
	);

	const onFail = useCallback(
		(migrationStatus: MigrationStatus) => {
			if (migrationStatus.hasErrorLogs) {
				if (!hasFlag?.(IMPORT_ADVANCED_ERRORS_FLAG_ID) && migrationId) {
					dismissInProgressFlag();
					removeMigrationId(projectKey);
					showFlag(
						getAdvancedErrorFlag({
							migrationStatus,
							projectName: projectName || name,
							onDownloadLogsClick: handleDownloadLogsClick,
						}),
					);
				}
				return;
			}

			if (!hasFlag?.(IMPORT_PARTIAL_SUCCESS_FLAG_ID)) {
				dismissInProgressFlag();
				removeMigrationId(projectKey);

				showFlag(getPartialSuccessFlag(migrationStatus, openFeedbackModal));
			}
		},
		// omit openFeedbackModal
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			handleDownloadLogsClick,
			dismissInProgressFlag,
			hasFlag,
			migrationId,
			projectKey,
			removeMigrationId,
			showFlag,
		],
	);

	useEffect(
		() => () => {
			dismissFlag(IMPORT_IN_PROGRESS_FLAG_ID);
			dismissFlag(IMPORT_SUCCESS_FLAG_ID);
			dismissFlag(IMPORT_PARTIAL_SUCCESS_FLAG_ID);
		},
		[dismissFlag],
	);

	useEffect(() => {
		if (!migrationId || hasFlag?.(IMPORT_IN_PROGRESS_FLAG_ID)) {
			return;
		}

		showFlag(getInProgressFlag());
	}, [hasFlag, migrationId, showFlag]);

	return {
		onSuccess,
		onFail,
	};
};
