import type { Observable as ObservableType } from 'rxjs/Observable';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';

export type Payload = {
	issueIds: IssueId[];
};

export const getIssueDeleteParentUrl = (contextPath: string, projectId: number) =>
	`${contextPath}/rest/internal/simplified/1.0/projects/${projectId}/issues/delete-parents`;

// Remove with TOTEM-2726 ff
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (contextPath: string, projectId: number, payload: Payload): ObservableType<void> =>
	fetchJson$(getIssueDeleteParentUrl(contextPath, projectId), {
		method: 'POST',
		body: JSON.stringify(payload),
	});
