import memoizeOne from 'memoize-one';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { BENTO } from '../../model/issue/issue-update-origin.tsx';
import { cardDelete, cardDeleteOptimistic } from '../../state/actions/card/index.tsx';
import { setIssueChildrenRank } from '../../state/actions/issue-children/index.tsx';
import { closeIssueModal, resetExternalAction } from '../../state/actions/issue/modal/index.tsx';
import { setIssueParentSummary } from '../../state/actions/issue/parent/index.tsx';
import { setIssuesRank } from '../../state/actions/issue/rank-transition/index.tsx';
import { issueBentoUpdate, issueUpdateRequest } from '../../state/actions/issue/update/index.tsx';
import { togglePanel, closeInsightsPanel } from '../../state/actions/panels/index.tsx';
import {
	getIssueIdForModal,
	getIssueKeyForModal,
	getTimestampForModal,
	getExternalActionForModal,
} from '../../state/selectors/issue/issue-modal-selectors.tsx';
import { sessionIdSelector } from '../../state/selectors/software/software-selectors.tsx';
import {
	activeSprintsSelector,
	getSelectedSprintsKeys,
	isSprintsEnabled,
} from '../../state/selectors/sprint/sprint-selectors.tsx';
import type { State } from '../../state/types.tsx';
import IssueWrapperDI from './issue-wrapper/index.tsx';
import DetailView, { type Props, type StateProps, type OwnProps } from './view.tsx';

const mapStateToProps = (state: State): StateProps => ({
	// @ts-expect-error - TS2322 - Type 'string | null | undefined' is not assignable to type 'string'.
	cardKey: getIssueKeyForModal(state),
	cardId: getIssueIdForModal(state),
	timestamp: getTimestampForModal(state),
	externalAction: getExternalActionForModal(state),
	boardSessionId: sessionIdSelector(state),
	activeSprints: activeSprintsSelector(state),
	selectedSprints: getSelectedSprintsKeys(state),
	isSprintsEnabled: isSprintsEnabled(state),
	selectedIssueKey: getIssueKeyForModal(state),
});

const mapDispatchToProps = {
	closeIssueModal,
	issueUpdateRequest,
	setIssueParentSummary,
	cardDelete,
	cardDeleteOptimistic,
	onTogglePanel: togglePanel,
	onCloseInsightsPanel: closeInsightsPanel,
	issueBentoUpdate,
	setIssueChildrenRank,
	setIssuesRank,
	onResetExternalAction: resetExternalAction,
};

type DispatchProps = typeof mapDispatchToProps;
const onDeleteFactory = memoizeOne(
	(
		dispatchPropsCardDeleteOptimistic: DispatchProps['cardDeleteOptimistic'],
		cardId: StateProps['cardId'],
		dispatchPropsIssueUpdateRequest: DispatchProps['issueUpdateRequest'],
	) =>
		({ issueId }: { issueId: string }) => {
			dispatchPropsCardDeleteOptimistic(Number(issueId));

			if (issueId && Number(issueId) !== cardId) {
				dispatchPropsIssueUpdateRequest([cardId || 0], BENTO);
			}
		},
);
const onCloseFactory = memoizeOne(
	(
		selectedIssueKey: StateProps['selectedIssueKey'],
		dispatchPropsCloseIssueModal: DispatchProps['closeIssueModal'],
	) =>
		() => {
			selectedIssueKey && dispatchPropsCloseIssueModal(selectedIssueKey);
		},
);

const mergeProps = (
	stateProps: StateProps,
	dispatchProps: typeof mapDispatchToProps,
	ownProps: OwnProps,
): Props => ({
	...ownProps,
	cardKey: stateProps.cardKey,
	timestamp: stateProps.timestamp,
	externalAction: stateProps.externalAction,
	boardSessionId: stateProps.boardSessionId,
	selectedIssueKey: stateProps.selectedIssueKey,
	cardId: stateProps.cardId,
	onDelete: onDeleteFactory(
		dispatchProps.cardDeleteOptimistic,
		stateProps.cardId,
		dispatchProps.issueUpdateRequest,
	),
	onChange: dispatchProps.issueBentoUpdate,
	onClose: onCloseFactory(stateProps.selectedIssueKey, dispatchProps.closeIssueModal),
	onCloseInsightsClick: dispatchProps.onCloseInsightsPanel,
	onResetExternalAction: dispatchProps.onResetExternalAction,
	activeSprints: stateProps.activeSprints,
	selectedSprints: stateProps.selectedSprints,
	isSprintsEnabled: stateProps.isSprintsEnabled,
	IssueWrapper: IssueWrapperDI,
});

const ConnectedDetailView = connect(mapStateToProps, mapDispatchToProps, mergeProps)(DetailView);

export default ConnectedDetailView;
