import { createSelectorCreator, defaultMemoize } from 'reselect';
import isEqual from 'lodash/isEqual';

/**
    By default reselect#createSelector uses refential equality to
    determine whether to recompute a value, eg: objectA === objectB.

    createDeepEqualSelector deeply compares two objects/arrays
 */
export const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);
