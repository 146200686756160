import React, { useState } from 'react';
import { styled as styled2 } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import Tooltip from '@atlaskit/tooltip';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from '@atlassian/jira-software-board-css-module/src/card-compact.module.css';
import CardIcon from '../icon/main.tsx';
import { PERSON_ICON } from '../icon/types.tsx';
import type { Props } from './types.tsx';

const hasKeywords = (name: string) => (keyword: string) =>
	name && keyword && name.toLowerCase().includes(keyword.toLowerCase());

export const CardAvatar = (props: Props) => {
	const { name, uri, highlight } = props;
	const [hasImageErrored, setHasImageErrored] = useState(false);
	const isImageValid = !!uri && !hasImageErrored;

	return (
		<Container isHighlighted={highlight && highlight.some(hasKeywords(name))}>
			<Tooltip content={name}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<AvatarContainer className={styles.avatarContainer}>
					{isImageValid ? (
						<Image loading="lazy" alt={name} src={uri} onError={() => setHasImageErrored(true)} />
					) : (
						<CardIcon
							type={PERSON_ICON}
							label={name}
							primaryColor={token('color.icon.subtle', colors.N90)}
						/>
					)}
				</AvatarContainer>
			</Tooltip>
		</Container>
	);
};

export default CardAvatar;

const size = gridSize * 3;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarContainer = styled2.span({
	display: 'inline-flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${size}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${size}px`,
	margin: token('space.025', '2px'),
	padding: 0,
	position: 'relative',
	cursor: 'default',
	lineHeight: 1,
	overflow: 'hidden',
	borderRadius: '3px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Image = styled2.img({
	verticalAlign: 'middle',
	width: '100%',
	height: '100%',
	borderRadius: '50%',
});

type ContainerProps = { isHighlighted?: boolean };

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled2.div<ContainerProps>({
	borderRadius: '50%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	boxShadow: (props: ContainerProps) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		props.isHighlighted ? `0px 0px 0px 3px ${token('color.border.focused', colors.B75)}` : 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > *': {
		display: 'flex',
		flex: '0 1 auto',
		alignSelf: 'center',
		minWidth: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- for testing
export const visibleForTesting = {
	Container,
};
