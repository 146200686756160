import {
	ISSUE_ASSIGNED_EVENT,
	ISSUE_CREATED_EVENT,
	ISSUE_DELETED_EVENT,
	ISSUE_TRANSITIONED_EVENT,
	ISSUE_UPDATED_EVENT,
	BOARD_SCOPE_ISSUES_UPDATED,
} from '@atlassian/jira-realtime/src/common/constants/events.tsx';
import type { RealtimeEventType } from '@atlassian/jira-realtime/src/common/types/events.tsx';

export const REALTIME_EVENTS: RealtimeEventType[] = [
	ISSUE_ASSIGNED_EVENT,
	ISSUE_CREATED_EVENT,
	ISSUE_DELETED_EVENT,
	ISSUE_TRANSITIONED_EVENT,
	ISSUE_UPDATED_EVENT,
];

// Board scope issues updated will replace everything
export const REALTIME_EVENTS_WITH_BSI: RealtimeEventType[] = [
	ISSUE_CREATED_EVENT,
	ISSUE_UPDATED_EVENT,
	BOARD_SCOPE_ISSUES_UPDATED,
];

// Board scope issues updated will replace everything
export const BOARD_SCOPE_ISSUES_UPDATED_EVENT: RealtimeEventType[] = [BOARD_SCOPE_ISSUES_UPDATED];
