import type { BoardScopeGQL } from '@atlassian/jira-software-board-fetch-scope-critical/src/types.tsx';
import type { UIFBoardCachedDataResult } from '@atlassian/jira-software-uif-early-script/src/index.tsx';
import flagMessages from '../../../view/flags/messages.tsx';
import { warningFlagMeta, type FlagMeta } from '../meta/flag-meta.tsx';

export const SOFTWARE_APP_LOADED = 'state.actions.software.SOFTWARE_APP_LOADED' as const;

export type SoftwareAppLoadedAction = {
	type: typeof SOFTWARE_APP_LOADED;
	payload: {
		cmpBoardData: Promise<UIFBoardCachedDataResult | null> | null;
		prefetchData: BoardScopeGQL | null;
	};
};

export const softwareAppLoaded = (
	prefetchData: BoardScopeGQL | null = null,
	cmpBoardData: Promise<UIFBoardCachedDataResult | null> | null = null,
): SoftwareAppLoadedAction => ({
	type: SOFTWARE_APP_LOADED,
	payload: { prefetchData, cmpBoardData },
});

export const SOFTWARE_APP_INITIAL_STATE_LOADED =
	'state.actions.software.SOFTWARE_APP_INITIAL_STATE_LOADED' as const;

export type SoftwareInitialStateLoadedAction = {
	type: typeof SOFTWARE_APP_INITIAL_STATE_LOADED;
};

export const softwareInitialStateLoaded = (): SoftwareInitialStateLoadedAction => ({
	type: SOFTWARE_APP_INITIAL_STATE_LOADED,
});

export const SOFTWARE_DISPLAY_WARNING = 'state.actions.software.SOFTWARE_DISPLAY_WARNING' as const;

export type DisplayWarningAction = {
	type: typeof SOFTWARE_DISPLAY_WARNING;
	meta: FlagMeta;
};

export const displayWarning = (error?: string | string[] | null): DisplayWarningAction => ({
	type: SOFTWARE_DISPLAY_WARNING,
	meta: warningFlagMeta({
		titleMessage: flagMessages.genericTitle,
		descriptionMessage: error ? flagMessages.failureDescription : flagMessages.genericDescription,
		context: {
			error: Array.isArray(error) ? error.join('. ') : error,
		},
	}),
});

export type Action =
	| SoftwareAppLoadedAction
	| SoftwareInitialStateLoadedAction
	| DisplayWarningAction;
