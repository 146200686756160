import React, {
	type ComponentType,
	type ReactNode,
	useLayoutEffect,
	forwardRef,
	memo,
} from 'react';
import type { UIAnalyticsEvent, AnalyticsEventPayload } from '@atlaskit/analytics-next';
import type { EventTypes, Screens } from './constants.tsx';
import type { AnalyticsAttributesUntyped as Attributes, AnalyticsEvent } from './types.tsx';
import {
	type AnalyticsContextData,
	extractContextualData,
} from './utils/analytics-context-wrapper.tsx';
import type { Callbacks } from './utils/analytics-event-to-props.tsx';
import type { AnalyticsEventOverrides, RestArguments } from './utils/extract-data-from-event.tsx';
import type { AnalyticsProps } from './utils/fire-analytics-component.tsx';
import type { ActionSubjectAndAction } from './utils/fire-analytics.tsx';

const AnalyticsContext = (props: { data: AnalyticsContextData; children: ReactNode }) => (
	<>{props.children}</>
);
export const ContextualAnalyticsData = memo<AnalyticsContextData>((props: AnalyticsContextData) => {
	const data = extractContextualData(props);
	// @ts-expect-error - TS2741 - Property 'children' is missing in type 'Dictionary<string | string[] | { [containerName: string]: Container; } | { [key: string]: any; } | undefined>' but required in type 'AnalyticsContextData'.
	return <AnalyticsContext data={data}>{props.children}</AnalyticsContext>;
});

export const WithAnalytics = () => null;
/**
 * @deprecated - use Screen event for "viewed" events, or fireUiAnalytics() for "clicked" etc. events
 * UI events are usually fired on user interaction, not on render
 * Read https://hello.atlassian.net/wiki/spaces/AAPT/pages/134329336/UI+Events and https://hello.atlassian.net/wiki/spaces/AAPT/pages/134329341/Screen+Events
 */
export const FireUiAnalytics = (_props: AnalyticsProps) => null;
/**
 * @deprecated - use Screen event for "viewed" events, or fireTrackAnalytics() on action completion
 * Track events are usually fired on user action competion, not on render
 * Read https://hello.atlassian.net/wiki/spaces/AAPT/pages/134329319/Track+Events and https://hello.atlassian.net/wiki/spaces/AAPT/pages/134329341/Screen+Events
 */
export const FireTrackAnalytics = (_props: AnalyticsProps) => null;
export const FireOperationalAnalytics = (_props: AnalyticsProps) => null;
export const FireScreenAnalytics = (_props: AnalyticsProps) => null;
export const fireAnalytics = (
	_event: AnalyticsEvent,
	_type: EventTypes,
	_payload:
		| AnalyticsEventOverrides
		| { attributes: AnalyticsEventOverrides['attributes'] }
		| {} = {},
) => undefined;
export const fireOperationalAnalytics = (_event: AnalyticsEvent, ..._rest: RestArguments) =>
	undefined;
export const fireOperationalAnalyticsDeferred = (_event: AnalyticsEvent, ..._rest: RestArguments) =>
	undefined;
export const fireScreenAnalytics = (_event: AnalyticsEvent, _attributes?: Attributes) => undefined;
export const fireScreenAnalyticsDeferred = (_event: AnalyticsEvent, _attributes?: Attributes) =>
	undefined;
export const fireTrackAnalytics = (_event: AnalyticsEvent, ..._rest: RestArguments) => undefined;
export const fireTrackAnalyticsDeferred = (_event: AnalyticsEvent, ..._rest: RestArguments) =>
	undefined;
export const fireUIAnalytics = (_event: AnalyticsEvent, ..._rest: RestArguments) => undefined;
export const getEvent = (_event: AnalyticsEvent) => ({
	type: undefined,
	payload: {},
});

class UIAnalyticsEventServer implements UIAnalyticsEvent {
	payload = {};

	context = [];

	handlers = [];

	hasFired = false;

	// @ts-expect-error TS2554: Expected 1 arguments, but got 0.
	clone = (): UIAnalyticsEvent | null => new UIAnalyticsEventServer();

	// Replace with lodash/noop
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	fire = (_channel?: string) => {};

	// @ts-expect-error TS2416: Property 'update' in type 'UIAnalyticsEventServer' is not assignable to the same property in base type 'UIAnalyticsEvent'. Type 'UIAnalyticsEventServer' is not assignable to type 'this'. 'UIAnalyticsEventServer' is assignable to the constraint of type 'this', but 'this' could be instantiated with a different subtype of constraint 'UIAnalyticsEventServer'.
	update = () => new UIAnalyticsEventServer();
}

const createAnalyticsEvent = (_payload: AnalyticsEventPayload): UIAnalyticsEvent =>
	// @ts-expect-error - Type 'UIAnalyticsEventServer' is not assignable to type 'UIAnalyticsEvent'.
	new UIAnalyticsEventServer();

export const useAnalyticsEvents = () => ({
	createAnalyticsEvent,
});

const withAnalyticsEvents =
	() =>
	<Config,>(WrappedComponent: Flow.AbstractComponent<Config>): Flow.AbstractComponent<Config> => {
		const WithAnalyticsEvents = forwardRef((props: Config, ref) => (
			// @ts-expect-error Spread types may only be created from object types.ts(2698)
			<WrappedComponent {...props} ref={ref} createAnalyticsEvent={createAnalyticsEvent} />
		));

		WithAnalyticsEvents.displayName = 'WithAnalyticsEvents('.concat(
			WrappedComponent.displayName != null
				? WrappedComponent.displayName
				: WrappedComponent.name || '',
			')',
		);
		return WithAnalyticsEvents;
	};

export const AnalyticsEventToProps =
	(
		_componentName: string,
		_eventActions: Callbacks<any>, // eslint-disable-line @typescript-eslint/no-explicit-any
	) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(component: ComponentType<any>): ComponentType<any> =>
		withAnalyticsEvents()(component);

type MountEventProps = {
	onMount: (arg1: AnalyticsEvent) => void;
};
const MountEventComponent = ({ onMount }: MountEventProps) => {
	// @ts-expect-error TS2554: Expected 1 arguments, but got 0.
	useLayoutEffect(() => onMount(new UIAnalyticsEventServer()), [onMount]);
	return null;
};
export const MountEvent = withAnalyticsEvents()<MountEventProps>(MountEventComponent);

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	SCREEN_EVENT_TYPE,
	TRACK_EVENT_TYPE,
	OPERATIONAL_EVENT_TYPE,
	UI_EVENT_TYPE,
	DRAWER,
	DROPDOWN,
	INLINE_DIALOG,
	MODAL,
	SCREEN,
	BANNER,
} from './constants';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports
export type {
	EventTypes,
	AnalyticsProps,
	Screens,
	AnalyticsContextData,
	AnalyticsEvent,
	UIAnalyticsEvent,
	ActionSubjectAndAction,
};

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { AnalyticsAttributes } from './types';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { RestArguments, Attributes };
