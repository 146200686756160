import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import type { ColumnId } from '../../../../model/column/column-types.tsx';
import type { SwimlaneId } from '../../../../model/swimlane/swimlane-types.tsx';

export const ISSUE_CHANGE_TEAM_REQUEST =
	'state.actions.issue.change-team.ISSUE_CHANGE_TEAM_REQUEST' as const;

type IssueChangeTeamRequestPayload = {
	issueIds: IssueId[];
	sourceColumnId: ColumnId;
	destinationColumnId: ColumnId;
	rankBeforeIssueId: IssueId | null | undefined;
	rankAfterIssueId: IssueId | null | undefined;
	sourceSwimlaneId: SwimlaneId | null | undefined;
	destinationSwimlaneId: SwimlaneId | null | undefined;
};

export type IssueChangeTeamRequestAction = {
	type: typeof ISSUE_CHANGE_TEAM_REQUEST;
	payload: IssueChangeTeamRequestPayload;
};

export const issueChangeTeamRequest = ({
	issueIds,
	sourceColumnId,
	destinationColumnId,
	rankBeforeIssueId,
	rankAfterIssueId,
	sourceSwimlaneId,
	destinationSwimlaneId,
}: IssueChangeTeamRequestPayload): IssueChangeTeamRequestAction => ({
	type: ISSUE_CHANGE_TEAM_REQUEST,
	payload: {
		issueIds,
		sourceColumnId,
		destinationColumnId,
		rankBeforeIssueId,
		rankAfterIssueId,
		sourceSwimlaneId,
		destinationSwimlaneId,
	},
});

export type Action = IssueChangeTeamRequestAction;
