import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import {
	type StoreActionApi,
	createStore,
	createHook,
	createActionsHook,
	createSelector,
} from '@atlassian/react-sweet-state';
import { TRAIT_ENDPOINT_STARTING_FRAGMENT } from './constants.tsx';
import type { Trait, TraitName, TraitType, TraitsState } from './types.tsx';
import {
	getTraitByName,
	hasTrait,
	getTraitValue,
	buildTraitEndpointEndingFragment,
	userCreatedAfter,
	userCreatedBefore,
} from './utils.tsx';

type StoreAPI = {
	setState: (state: TraitsState) => void;
	getState: () => TraitsState;
};

const addTrait =
	(traitType: TraitType, trait: Trait) =>
	({ getState, setState }: StoreAPI) => {
		const currentUserTraits = getState();
		const state = currentUserTraits[traitType];

		if (!state.attributes) {
			setState({
				...currentUserTraits,
				[traitType]: {
					attributes: [trait],
				},
			});
			return;
		}

		if (state.attributes.some((existingTrait) => existingTrait.name === trait.name)) {
			setState({
				...currentUserTraits,
				[traitType]: {
					attributes: state.attributes.map((existingTrait) =>
						existingTrait.name === trait.name ? trait : existingTrait,
					),
				},
			});
		} else {
			setState({
				...currentUserTraits,
				[traitType]: {
					attributes: [...state.attributes, trait],
				},
			});
		}
	};

const fetchTraits =
	(
		traitTypes: TraitType[],
		{
			cloudId,
			accountId,
			orgId,
		}: {
			cloudId?: string | null;
			accountId?: string | null;
			orgId?: string | null;
		},
	) =>
	async ({ getState, setState }: StoreActionApi<TraitsState>) => {
		const state = getState();

		await Promise.all(
			traitTypes.map(async (traitType) => {
				if (state[traitType].loaded || state[traitType].loading) {
					return;
				}

				setState({
					...state,
					[traitType]: {
						...state[traitType],
						loading: true,
					},
				});

				const traitEndpointEndingFragment = buildTraitEndpointEndingFragment({
					traitType,
					accountId,
					cloudId,
					orgId,
				});
				if (!traitEndpointEndingFragment) {
					return;
				}

				const url = TRAIT_ENDPOINT_STARTING_FRAGMENT + traitEndpointEndingFragment;

				try {
					const fetchedTraits = await performGetRequest(url);

					setState({
						...getState(),
						[traitType]: {
							attributes: fetchedTraits?.attributes?.length ? fetchedTraits.attributes : null,
							loading: false,
							loaded: true,
						},
					});
				} catch {
					setState({
						...getState(),
						[traitType]: {
							attributes: null,
							loading: false,
							loaded: true,
						},
					});
				}
			}),
		);
	};

const actions = { fetchTraits, addTrait };

const initialState: TraitsState = {
	ATLASSIAN_ACCOUNT: {
		attributes: null,
		loading: false,
		loaded: false,
	},
	SITE: {
		attributes: null,
		loading: false,
		loaded: false,
	},
	SITE_USER: {
		attributes: null,
		loading: false,
		loaded: false,
	},
	ORG: {
		attributes: null,
		loading: false,
		loaded: false,
	},
	ORG_USER: {
		attributes: null,
		loading: false,
		loaded: false,
	},
};

export const TraitsStore = createStore({
	initialState,
	actions,
	name: 'TraitsStore',
});

export const useTraits = createHook(TraitsStore);
export const useTraitsActions = createActionsHook(TraitsStore);

type SelectorData<T extends TraitType> = {
	loading: boolean;
	loaded: boolean;
	attributes: TraitsState[T]['attributes'];
	userCreatedAfter: (date: Date) => boolean;
	userCreatedBefore: (date: Date) => boolean;
	getTraitByName: (name: TraitName) => Trait | null;
	hasTrait: (name: TraitName) => boolean;
	getTraitValue: (name: TraitName) => string | null;
};

const defaultSelector = <T extends TraitType>(
	traitsType: TraitType,
	selector: (data: SelectorData<T>) => boolean,
) =>
	createSelector<TraitsState, undefined, boolean, SelectorData<T>>(
		(state) => ({
			loading: !!state[traitsType]?.loading,
			loaded: state[traitsType]?.loaded || false,
			attributes: state[traitsType]?.attributes,
			userCreatedAfter: (date: Date) =>
				state[traitsType].loaded ? userCreatedAfter(state, date) : false,
			userCreatedBefore: (date: Date) =>
				state[traitsType].loaded ? userCreatedBefore(state, date) : false,
			getTraitByName: (name: TraitName) =>
				state[traitsType].loaded ? getTraitByName(state, name) : null,
			hasTrait: (name: TraitName) => (state[traitsType].loaded ? hasTrait(state, name) : false),
			getTraitValue: (name: TraitName) =>
				state[traitsType].loaded ? getTraitValue(state, name) : null,
		}),
		selector,
	);

/**
 * Create a selector hook for a specific trait type
 * This prevents excessive re-renders when using multiple selectors
 *
 *
 * You can compose these in your package like so:
 *
 *  const useIsMyFeatureNew = createTraitsSelector<'SITE_USER'>(
 *  	'SITE_USER',
 *  	({ userCreatedAfter, getTraitByName }) => {
 *  		const releaseDate = new Date('2024-06-17');
 *  		const hasOpenedFeature = getTraitByName('jira-core_ui_cardcovereditor_clicked_first_time');
 *  		const accountCreationDate = getTraitByName('jira_first_active')?.value;
 *  			typeof accountCreationDate === 'string' && new Date(accountCreationDate) > releaseDate;
 *  		return !hasOpenedFeature && !userCreatedAfter(new Date(releaseDate.getTime() + 7 * 24 * 60 * 60 * 1000));
 *  	},
 *  );
 *
 *  const useIsOrgSomething = createTraitsSelector<'ORG'>('ORG', ({ getTraitByName }) => {
 * 		const hasOpenedOrgFeature = getTraitByName('org_feature_opened');
 * 		return !!hasOpenedOrgFeature;
 *  });
 *
 * ** Avoid adding selectors here to prevent increase in bundle sizes for other components using this code. **
 *
 * Then in your component:
 * const isMyFeatureNew = useIsMyFeatureNew();
 * const isOrgSomething = useIsOrgSomething();
 *
 * @param traitsType Array<ATLASSIAN_ACCOUNT, SITE, SITE_USER, ORG, ORG_USER>
 * @param selector Function that takes in attributes, isNewUser, and getTraitByName and returns a boolean
 * @returns Hook that returns the result of the selector
 */
export const createTraitsSelector = <T extends TraitType>(
	traitsType: TraitType,
	selector: (data: SelectorData<T>) => boolean,
) =>
	createHook(TraitsStore, {
		selector: defaultSelector<T>(traitsType, selector),
	});

/**
 * Function to compose multiple selectors for use in TraitsConditionalRenderer.
 * This is used to prevent excessive re-renders when using multiple selectors.
 * @param traitTypes Array<ATLASSIAN_ACCOUNT, SITE, SITE_USER, ORG, ORG_USER>
 * @param hooks Array<ReturnType of createTraitsSelector>
 * @returns {
 * 		traitTypes: Array<ATLASSIAN_ACCOUNT, SITE, SITE_USER, ORG, ORG_USER>,
 * 		hooks: Array<ReturnType of createTraitsSelector>,
 * }
 */
export const composeTraitsSelectors = (
	traitTypes: TraitType[],
	hooks: ReturnType<typeof createTraitsSelector>[],
) => ({
	traitTypes,
	hooks,
});
