import React from 'react';
import { styled } from '@compiled/react';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import Lozenge from '@atlaskit/lozenge';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { TextCustomField } from '../types.tsx';
import { messages } from './messages.tsx';

type Props = {
	field: TextCustomField;
	fixVersions?: number[];
};

export const CUSTOM_FIELD_FIX_VERSIONS = 'fixVersions';

export const TextFixVersionsCustomFieldContent = ({ field, fixVersions = [] }: Props) => {
	const { formatMessage } = useIntl();
	if (isNil(field) || isNil(field.text) || isEmpty(field.text)) {
		return null;
	}

	const versionsCount = fixVersions.length;

	return (
		<Container>
			<Lozenge>
				{versionsCount > 1
					? formatMessage(messages.multipleVersionsContent, { versionsCount })
					: field.text}
			</Lozenge>
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const Container = styled.span({
	maxWidth: '100%',
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& span': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		lineHeight: `${gridSize * 2}px`,
	},
});
