import type { Action } from '@atlassian/react-sweet-state';
import type { State, TriggerReason } from '../../types.tsx';

export const setTriggerReason =
	(triggerReason: TriggerReason): Action<State> =>
	({ getState, setState }) => {
		const { triggerReason: currentTriggerReason } = getState();

		if (triggerReason === currentTriggerReason) {
			return;
		}
		setState({
			triggerReason,
		});
	};
