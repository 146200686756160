import { freeze } from 'icepick';
import { createSelector } from 'reselect';
import type { State } from '../../reducers/types.tsx';
import { getUi } from '../software/software-selectors.tsx';
import { boardIssuesSelector } from './board-issue-selectors.tsx';

const getUiAddLabelModal = (state: State) => getUi(state).issueAddLabelModal;

export const isAddLabelModalOpen = (state: State): boolean =>
	Boolean(getUiAddLabelModal(state)?.isOpen);

// @ts-expect-error - TS2339 - Property 'issueIds' does not exist on type 'AddLabelModalState'.
const getSelectedIssueIds = (state: State): number[] => getUiAddLabelModal(state).issueIds;

export type PartialIssue = {
	id: number;
	key: string;
};

const noSelectedIssues = freeze([]);
export const getIssuesForAddLabelModal = createSelector(
	[boardIssuesSelector, getSelectedIssueIds],
	(allIssues, issueIds) => {
		if (issueIds) {
			return issueIds.map((id) => ({
				// Cast issueId here as number for now, until we need the add label feature in ARJ Increment planning board
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				id: allIssues[id].id as number,
				key: allIssues[id].key,
			}));
		}

		return noSelectedIssues;
	},
);
