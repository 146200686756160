import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	skipButtonLabel: {
		id: 'insights-common.insights-spotlight.skip-button-label',
		defaultMessage: 'Skip',
		description: 'Button to dismiss the insights spotlight',
	},
	viewButtonLabel: {
		id: 'insights-common.insights-spotlight.view-button-label',
		defaultMessage: 'View insights',
		description: 'Button in spotlight to open the insights panel',
	},
	spotlightContent: {
		id: 'insights-common.insights-spotlight.spotlight-content',
		defaultMessage:
			'Insights have made it to your board and backlog, so you can monitor work and make informed decisions using real-time data.',
		description:
			'Introduction to insights feature displayed in spotlight for user first time see it',
	},
});
