import { createSelector } from 'reselect';
import find from 'lodash/find';
import findKey from 'lodash/findKey';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import memoize from 'lodash/memoize';
import nthArg from 'lodash/nthArg';
import { ColumnType } from '@atlassian/jira-common-constants/src/column-types.tsx';
import type { StatusCategory as StatusCategoryCommon } from '@atlassian/jira-common-constants/src/status-categories.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import type {
	Column,
	ColumnEntities,
	ColumnId,
	ColumnValidation,
	StatusColumn,
} from '../../../model/column/column-types.tsx';
import type { Issue } from '../../../model/issue/issue-types.tsx';
import type { Status, StatusId } from '../../../model/software/software-types.tsx';
import { entityArrayToMap } from '../../../services/software/software-data-transformer.tsx';
import type { State } from '../../reducers/types.tsx';
import {
	getCardTransitions,
	getEntities,
	getIssueProjects,
	getOrderedColumnIds,
	getUi,
} from '../software/software-selectors.tsx';
import messages from './messages.tsx';

export const isInitialColumn = (column: Column | undefined): boolean =>
	column?.type === ColumnType.STATUS && column.statuses.some(({ isInitial }) => isInitial);

export const isDoneColumn = (column: Column | undefined): boolean =>
	column?.type === ColumnType.STATUS &&
	column?.statuses.length >= 1 &&
	column?.statuses.every(({ isDone }) => isDone);

export const isBustedColumn = (column: Column, totalOfIssues: number): boolean =>
	column.type === ColumnType.STATUS &&
	column.maxIssueCount !== null &&
	totalOfIssues > column.maxIssueCount;

export const getColumnLozenges = memoize(
	(issueCount: number, maxIssueCount: number | null, minIssueCount?: number | null) => {
		const lozenges = [];
		if (minIssueCount != null) {
			lozenges.push({
				descriptor: messages.minIssueCount,
				values: {
					count: String(minIssueCount),
				},
				type: 'minimum',
				busted: issueCount < minIssueCount,
				tooltip: expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.minIssueCountTooltipIssueTermRefresh
					: messages.minIssueCountTooltip,
			});
		}
		if (maxIssueCount != null) {
			lozenges.push({
				descriptor: messages.maxIssueCount,
				values: {
					count: String(maxIssueCount),
				},
				type: 'maximum',
				busted: issueCount > maxIssueCount,
				tooltip: expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.maxIssueCountTooltipIssueTermRefresh
					: messages.maxIssueCountTooltip,
			});
		}

		return lozenges;
	},
	(issueCount, maxIssueCount, minIssueCount) => `${maxIssueCount}::${minIssueCount}::${issueCount}`,
);

export const getStatusColumn = (column: Column): StatusColumn | null =>
	column.type === ColumnType.STATUS ? column : null;

export const getColumnIndex = (state: State, columnId: ColumnId): number =>
	getOrderedColumnIds(state).indexOf(columnId);

export const isLastColumn = createSelector([getOrderedColumnIds], (columnIds) =>
	memoize((columnId) => columnIds.indexOf(columnId) === columnIds.length - 1),
);

export const getColumns = (state: State): ColumnEntities => getEntities(state).columns;

export const getColumnById = (state: State, columnId: ColumnId): Column | undefined =>
	getColumns(state)[String(columnId)];

export const initialColumnIdSelector = createSelector([getColumns], (columns) =>
	Number(findKey(columns, isInitialColumn)),
);

export const initialColumnSelector = createSelector(
	[getColumns, initialColumnIdSelector],
	(columns, initialColumId) => columns[String(initialColumId)],
);

export const doneColumnIdSelector = createSelector([getColumns], (columns) =>
	Number(findKey(columns, isDoneColumn)),
);

export const getStatusesByColumnId =
	(state: State) =>
	(columnId: ColumnId): Status[] =>
		getStatusColumn(getColumns(state)[String(columnId)])?.statuses ?? [];

export const getStatusCategoryByStatusInColumnId =
	(state: State) =>
	(columnId: ColumnId, statusInColumnId: StatusId): StatusCategoryCommon =>
		getStatusesByColumnId(state)(columnId).find((status) => status.id === statusInColumnId)
			?.category ?? 'UNDEFINED';

export const getStatusIdsByColumnId =
	(state: State) =>
	(columnId: ColumnId): StatusId[] =>
		getStatusesByColumnId(state)(columnId).map(({ id }) => id);

export const getNameByColumnId = (state: State, columnId: ColumnId): string =>
	getColumns(state)[String(columnId)].name;

// DEPRECATED. Avoid reusing this selector since with WF col, status mapping it will also require an issue type
export const columnByStatusIdSelector = createSelector(
	[getColumns, nthArg(1)],
	(columns: ColumnEntities, statusId: StatusId) =>
		find(
			columns,
			(column) =>
				getStatusColumn(column)?.statuses.some((status: Status) => status.id === statusId) ?? false,
		),
);

export const orderedColumnsSelector = createSelector(
	[getColumns, getOrderedColumnIds],
	(columns, columnIds) => columnIds.map((columnId) => columns[String(columnId)]),
);

export type ColumnStatusMapping = Record<
	ColumnId,
	{
		isInitial: boolean;
		isDone: boolean;
	}
>;
export const columnStatusMappingSelector = createSelector(
	[orderedColumnsSelector],
	(columns): ColumnStatusMapping => {
		const mapping: ColumnStatusMapping = {};
		columns.forEach((column) => {
			mapping[column.id] = {
				isInitial: isInitialColumn(column),
				isDone: isDoneColumn(column),
			};
		});
		return mapping;
	},
);

const isNotItSelfAndHasValidId = (entity: Column, id: number) => entity.id !== id && entity.id > 0;
const hasSameName = (entity: Column | Status, name: string) =>
	entity.name.toLowerCase() === name.toLowerCase();

export const isDoneColumnPresentSelector = createSelector(
	[getOrderedColumnIds, columnStatusMappingSelector],
	(columnIds, statusMapping) => columnIds.some((id) => statusMapping[id].isDone),
);

export const isDoneColumnSelector = createSelector(
	columnStatusMappingSelector,
	(columnStatusMapping) => {
		const getIsDoneColumn = (columnId: ColumnId) =>
			get(columnStatusMapping, [String(columnId), 'isDone'], false);
		return getIsDoneColumn;
	},
);

export const doesColumnOrStatusNameExist = (columns: Column[], id: number, name: string) =>
	columns
		.filter((column) => isNotItSelfAndHasValidId(column, id))
		.some(
			(column) =>
				hasSameName(column, name) ||
				getStatusColumn(column)?.statuses.some((status) => hasSameName(status, name)),
		);

export const makeValidateColumnSelector = createSelector([orderedColumnsSelector], (columns) => {
	const makeValidateColumn = (columnId: ColumnId, columnName: string): ColumnValidation => {
		if (doesColumnOrStatusNameExist(columns, columnId, columnName)) {
			return {
				isValid: false,
				message: {
					title: messages.nameIsTakenTitle,
					description: messages.nameIsTakenDescription,
				},
			};
		}
		return { isValid: true, message: null };
	};
	return memoize(makeValidateColumn, (columnId, columnName) => `${columnId}:${columnName}`);
});

export const hasColumnLimits = createSelector(orderedColumnsSelector, (columns) =>
	columns.some(
		(column) =>
			column.type === ColumnType.STATUS &&
			column.maxIssueCount !== null &&
			column.maxIssueCount >= 0,
	),
);

export const makeClosestColumnSelector = createSelector([orderedColumnsSelector], (columns) => {
	const makeClosestColumn = (columnId: ColumnId) => {
		const index = columns.findIndex((column) => column.id === columnId);

		if (index === -1 || columns.length === 1) {
			return null;
		}

		const isFirstColumn = index === 0;
		if (isFirstColumn) {
			const nextColumn = columns[index + 1];
			return nextColumn;
		}
		const prevColumn = columns[index - 1];
		return prevColumn;
	};
	return memoize(makeClosestColumn);
});

export const getUiColumnLimits = (state: State) => getUi(state).columnLimits ?? { isOpen: false };
export const getUiColumnLimitsModalOpen = createSelector(
	[getUiColumnLimits],
	(columnLimits) => columnLimits.isOpen,
);

export const makeGetAllOtherColumnsSelector = createSelector(
	[getColumns, getOrderedColumnIds],
	(columns, columnIds) => {
		const makeGetAllOtherColumns = (columnId: ColumnId) =>
			columnIds.filter((id: ColumnId) => id !== columnId).map((id) => columns[String(id)]);
		return memoize(makeGetAllOtherColumns);
	},
);

export const hasDoneColumnInLastPositionSelector = createSelector(
	orderedColumnsSelector,
	(columns) => (columns[columns.length - 1] ? isDoneColumn(columns[columns.length - 1]) : false),
);

export const getDoneColumnsSelector = createSelector([orderedColumnsSelector], (columns) =>
	columns.filter((column) => isDoneColumn(column)),
);

export const hasMultipleStatusesPerColumn = createSelector([getColumns], (columns) =>
	Object.keys(columns).some((columnId) => {
		const column = columns[columnId];
		return column.type === ColumnType.STATUS && column.statuses.length > 1;
	}),
);

export const hasMoreThanFiveColumn = createSelector(
	[getColumns],
	(columns) => Object.keys(columns).length > 5,
);

export const hasMultipleDoneColumnSelector = createSelector(
	[getDoneColumnsSelector],
	(columns) => columns.length > 1,
);

/**
 * Added project Id/key check based on the issues being dragged. There is a potential problem if the user does bulk drag and drop,
 * and there are different projects keys for each of those issues.
 * Right now the BE will just fail because all the keys don't match. At some point we will want to show an error flag if the transition IDs don't match
 * for all issues being dragged - https://hello.jira.atlassian.cloud/browse/TNK-1416
 */
export const firstGlobalTransitionIdForColumnWithProjectFilteringSelector = createSelector(
	[getCardTransitions, getIssueProjects],
	(cardTransitions, issueProjects) => (columnId: ColumnId, issue: Issue) => {
		const transitionsForColumn = cardTransitions.columnIssueTypeTransitions[String(columnId)];

		if (
			isNil(issue) ||
			isNil(transitionsForColumn) ||
			isNil(transitionsForColumn[String(issue?.typeId)])
		) {
			return null;
		}

		// TMP issues don't have a project id/key so we just get whatever
		const issueMissingProjectId = !issue.projectId;

		const projectKey = issue
			? Object.values(issueProjects).find((project) => project.id === issue.projectId)?.key
			: null;

		// TMP transitions have project keys populated dynamically but we can't show TMP issues on a
		// CMP board so if its a TMP issue, just grab ANY of the global transitions because we
		// know that TMP can't have duplicates like CMP.
		const transition = transitionsForColumn[String(issue.typeId)].find(
			(t) => (issueMissingProjectId || t.projectKey === projectKey) && t.isGlobal && !t.isInitial,
		);

		return !isNil(transition) ? transition.id : null;
	},
);

export const getStatusIdsOfColumns = createSelector([getColumns], (columns) =>
	Object.keys(columns)
		.flatMap((columnId) => getStatusColumn(columns[columnId])?.statuses ?? [])
		.map((status) => status.id),
);

/**
 * Returns columns that have statuses with "DONE" category.
 * checking status.isDone is deprecated
 */
export const getDoneColumns = createSelector([getColumns], (columns) =>
	Object.values(columns).filter((column) =>
		getStatusColumn(column)?.statuses.some(
			(status) => status.category && status.category.toUpperCase() === 'DONE',
		),
	),
);

export const getStatusMapSelector = createSelector([getColumns], (columns: ColumnEntities) =>
	entityArrayToMap(
		Object.keys(columns).flatMap((columnId) => getStatusColumn(columns[columnId])?.statuses ?? []),
	),
);
