import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/concatMap';
import {
	fireTrackAnalytics,
	fireOperationalAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { getStatusColumn } from '../../common/utils/column/index.tsx';
import columnRenameService, {
	type ColumnRenameResponse,
} from '../../services/column-graphql/rename/index.tsx';
import {
	columnRenameSuccess,
	columnRenameFailure,
	COLUMN_RENAME_REQUEST,
} from '../../state/actions/column/rename/index.tsx';
import { getColumnIndex } from '../../state/selectors/column/column-selectors.tsx';
import {
	contextPathSelector,
	projectKeySelector,
	rapidViewIdSelector,
} from '../../state/selectors/software/software-selectors.tsx';
import type { ActionsObservable, MiddlewareAPI } from '../../state/types.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable, store: MiddlewareAPI) =>
	action$.ofType(COLUMN_RENAME_REQUEST).concatMap((action) => {
		const state = store.getState();
		const contextPath = contextPathSelector(state);
		const boardId = rapidViewIdSelector(state);
		const projectKey = projectKeySelector(state);

		const {
			payload: { columnId, name },
			meta: { optimistic, analyticsEvent },
		} = action;
		const position = getColumnIndex(state, columnId);
		return columnRenameService(contextPath, boardId, columnId, name, projectKey)
			.flatMap((columnRenameResponse: ColumnRenameResponse) => {
				const { column, cardTransitions } = columnRenameResponse;

				const statuses = getStatusColumn(column)?.statuses ?? [];

				if (analyticsEvent) {
					fireTrackAnalytics(analyticsEvent, 'column renamed', {
						position: position + 1,
					});
				}

				return Observable.of(
					columnRenameSuccess(optimistic.id, columnId, statuses, cardTransitions),
				);
			})
			.catch((error) => {
				if (analyticsEvent) {
					fireOperationalAnalytics(analyticsEvent, 'column renameFailed', {
						error,
						position: position + 1,
					});
				}
				return Observable.of(columnRenameFailure(optimistic.id, columnId, error));
			});
	});
