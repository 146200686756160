import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { FilterableIssue, MatcherCreator } from '../types.tsx';

export const issueTypeMatcherCreator: MatcherCreator<IssueTypeId[], FilterableIssue> = (
	issueTypeFilter,
) => {
	const issueTypeSet = new Set(issueTypeFilter);

	return ({ type }) => issueTypeSet.has(type.id);
};
