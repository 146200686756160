import React, { useMemo, useCallback, useRef, useEffect } from 'react';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { useBulkOpsSessionContext } from '@atlassian/jira-bulk-operations-analytics/src/controllers/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import {
	type Attributes,
	ContextualAnalyticsData,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { useAnalyticsAttributes, useAnalyticsMapping } from '../../context-menu/index.tsx';
import type {
	ContextMenuAnalyticsProps,
	ContextualAnalyticsDataFromNodeProps,
	UseContextMenuAnalyticsReturn,
} from './types.tsx';
import { createGetAnalyticsMapping } from './utils.tsx';

export const useContextMenuAnalytics = (): UseContextMenuAnalyticsReturn => {
	const analyticsMapping = useAnalyticsMapping();

	const getAnalyticsMapping = useMemo(
		() => createGetAnalyticsMapping(analyticsMapping),
		[analyticsMapping],
	);

	const addMappedAnalyticsToEvent = useCallback<
		UseContextMenuAnalyticsReturn['addMappedAnalyticsToEvent']
	>(
		(event, id, updater) => {
			const mappedAnalytics = getAnalyticsMapping(id);

			if (mappedAnalytics && typeof mappedAnalytics === 'object') {
				const { actionSubjectId, attributes, ...analytics } = mappedAnalytics;
				event.update(analytics);
			}

			if (updater) {
				if (typeof updater === 'function') {
					event.update(updater);
				} else {
					const { actionSubjectId, attributes, ...analytics } = updater;
					event.update(analytics);
				}
			}

			return event;
		},
		[getAnalyticsMapping],
	);

	const getAttributesForId = useCallback<UseContextMenuAnalyticsReturn['getAttributesForId']>(
		(id, updater) => {
			const mappedAnalytics = getAnalyticsMapping(id);

			const mappedAttributes =
				typeof mappedAnalytics === 'object' ? mappedAnalytics.attributes : undefined;

			return typeof updater === 'object'
				? { ...mappedAttributes, ...updater?.attributes }
				: mappedAttributes;
		},
		[getAnalyticsMapping],
	);

	const fireUIAnalyticsForNode = useCallback<
		UseContextMenuAnalyticsReturn['fireUIAnalyticsForNode']
	>(
		(event, id, updater, attributes) => {
			const mappedAnalytics = getAnalyticsMapping(id);

			let subjectId: string | undefined =
				typeof updater === 'function'
					? updater(event.payload).actionSubjectId
					: updater?.actionSubjectId;

			if (!subjectId && mappedAnalytics) {
				subjectId =
					typeof mappedAnalytics === 'string' ? mappedAnalytics : mappedAnalytics.actionSubjectId;
			}

			if (attributes) {
				subjectId
					? fireUIAnalytics(event, subjectId, attributes)
					: fireUIAnalytics(event, attributes);
			} else {
				subjectId ? fireUIAnalytics(event, subjectId) : fireUIAnalytics(event);
			}
		},
		[getAnalyticsMapping],
	);

	const values = useMemo(
		() => ({ fireUIAnalyticsForNode, getAttributesForId, addMappedAnalyticsToEvent }),
		[fireUIAnalyticsForNode, getAttributesForId, addMappedAnalyticsToEvent],
	);

	return values;
};

export const ContextMenuAnalytics = ({ scope, children, cardId }: ContextMenuAnalyticsProps) => {
	const attributes = useAnalyticsAttributes({ scope, cardId });
	return <ContextualAnalyticsData attributes={attributes}>{children}</ContextualAnalyticsData>;
};

export const ContextualAnalyticsDataFromNode = ({
	id,
	updater,
	children,
}: ContextualAnalyticsDataFromNodeProps) => {
	const { getAttributesForId } = useContextMenuAnalytics();

	const attributes = useMemo(
		() => getAttributesForId(id, updater),
		[getAttributesForId, id, updater],
	);

	if (!attributes) {
		return <>{children}</>;
	}

	return <ContextualAnalyticsData attributes={attributes}>{children}</ContextualAnalyticsData>;
};

export const useFireCheckboxAnalytics = () => {
	const bulkOpsFlow = useBulkOpsSessionContext();
	const subscriptionHandle = useRef<() => void>(noop);

	const fireCheckboxAnalytics = useCallback(
		(event: UIAnalyticsEvent, actionSubjectId: string, attributes: Attributes) => {
			if (!bulkOpsFlow) {
				fireErrorAnalytics({
					error: new Error('Trying to fire checkbox analytics when bulkOpsFlow is undefined'),
					meta: {
						id: 'jira-software-context-menu-controller-analytics-use-fire-checkbox-analytics',
						packageName: 'jiraSoftwareContextMenu',
						teamName: 'deliveroo',
					},
					attributes: {
						actionSubjectId,
						attributes,
					},
				});
				fireUIAnalytics(event, actionSubjectId, {
					...attributes,
					bulkOpsSessionID: 'unknown',
				});
				return;
			}
			if (bulkOpsFlow.isInFlow()) {
				fireUIAnalytics(event, actionSubjectId, attributes);
			} else {
				subscriptionHandle.current = bulkOpsFlow.registerAnalyticsEventPublisher((sessionID) => {
					const attributesWithSessionId = { ...attributes, bulkOpsSessionID: sessionID };
					fireUIAnalytics(event, actionSubjectId, attributesWithSessionId);
				});
			}
		},
		[bulkOpsFlow, subscriptionHandle],
	);

	useEffect(
		() => () => {
			return subscriptionHandle.current();
		},
		[],
	);

	return fireCheckboxAnalytics;
};
