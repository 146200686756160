import React from 'react';
import { styled } from '@compiled/react';
import isNil from 'lodash/isNil';
import Badge from '@atlaskit/badge';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import type { Props } from './types.tsx';

export const CardEstimate = (props: Props) => {
	const { estimate, units } = props;
	const { formatMessage } = useIntl();

	let tooltipContent: string | number | null = null;

	if (isNil(units)) {
		tooltipContent = estimate;
	} else if (typeof units === 'string') {
		tooltipContent = `${estimate} ${units}`;
	} else {
		tooltipContent = formatMessage(units, { value: estimate });
	}

	return (
		<Container>
			<Tooltip content={tooltipContent} testId="platform-card.common.ui.estimate.tooltip">
				<Badge appearance="default" testId="platform-card.common.ui.estimate.badge">
					{estimate.toString()}
				</Badge>
			</Tooltip>
		</Container>
	);
};

export default CardEstimate;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Container = styled.div({
	display: 'flex',
	alignItems: 'center',
});
