import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	editFilterCta: {
		id: 'software-issue-limit-flags.common.edit-filter-cta',
		defaultMessage: 'Edit filter',
		description: 'Label for CTA to navigate user to edit their board filter',
	},
	viewMoreInfoCta: {
		id: 'software-issue-limit-flags.common.view-more-info-cta',
		defaultMessage: 'More about issue limits',
		description: 'CTA link to view support article about issue limits',
	},
	seeIssuesInSearchCta: {
		id: 'software-issue-limit-flags.common.see-issues-in-search-cta',
		defaultMessage: 'See issues in search',
		description: 'CTA link to navigate to issues search page',
	},
	viewMoreInfoCtaIssueTermRefresh: {
		id: 'software-issue-limit-flags.common.view-more-info-cta-issue-term-refresh',
		defaultMessage: 'More about work item limits ',
		description: 'CTA link to view support article about issue limits',
	},
	seeIssuesInSearchCtaIssueTermRefresh: {
		id: 'software-issue-limit-flags.common.see-issues-in-search-cta-issue-term-refresh',
		defaultMessage: 'See everything in search',
		description: 'CTA link to navigate to issues search page',
	},
});
