import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/mergeMap';
import { setCollapsedSwimlanes } from '../../services/software/software-storage.tsx';
import { SWIMLANES_TOGGLE } from '../../state/actions/swimlane/index.tsx';
import { rapidViewIdSelector } from '../../state/selectors/software/software-selectors.tsx';
import { getCollapsedSwimlanes } from '../../state/selectors/swimlane/swimlane-selectors.tsx';
import type { MiddlewareAPI, ActionsObservable } from '../../state/types.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable, store: MiddlewareAPI) =>
	action$.ofType(SWIMLANES_TOGGLE).mergeMap(() => {
		const state = store.getState();
		setCollapsedSwimlanes(rapidViewIdSelector(state), getCollapsedSwimlanes(state));

		return Observable.empty<never>();
	});
