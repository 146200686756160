import noop from 'lodash/noop';
import memoizeOne from 'memoize-one';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { CAN_EDIT_BOARD } from '../../../../model/permission/permission-types.tsx';
import { cardMediaToggleRequest } from '../../../../state/actions/board/menu/index.tsx';
import { getPermissionsSelector } from '../../../../state/selectors/board/board-permissions-selectors.tsx';
import { getIsCMPBoard } from '../../../../state/selectors/software/software-selectors.tsx';
import { isCardMediaEnabled } from '../../../../state/selectors/work/work-selectors.tsx';
import type { Dispatch, State } from '../../../../state/types.tsx';
import CardMediaItem from './view.tsx';

type OwnProps = {
	isDisabled: boolean;
	onClick: () => void;
};

export const mapStateToProps = (
	state: State,
	props: OwnProps = { isDisabled: false, onClick: noop },
) => ({
	isMediaEnabled: isCardMediaEnabled(state),
	isDisabled:
		props.isDisabled || !getPermissionsSelector(state)[CAN_EDIT_BOARD] || getIsCMPBoard(state),
});

export const mapDispatchToProps = (dispatch: Dispatch) => ({
	dispatch,
});

type StateProps = ReturnType<typeof mapStateToProps>;
const onClickFactory = memoizeOne(
	(
		dispatch: Dispatch,
		isMediaEnabled: StateProps['isMediaEnabled'],
		onClick: OwnProps['onClick'],
	) =>
		() => {
			dispatch(cardMediaToggleRequest(!isMediaEnabled));
			onClick();
		},
);

export const mergeProps = (
	stateProps: ReturnType<typeof mapStateToProps>,
	dispatchProps: ReturnType<typeof mapDispatchToProps>,
	ownProps: OwnProps,
) => {
	const { isMediaEnabled, isDisabled } = stateProps;
	const { dispatch } = dispatchProps;
	const { onClick } = ownProps;

	return {
		isMediaEnabled,
		isDisabled,
		onClick: onClickFactory(dispatch, isMediaEnabled, onClick),
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);

export default connector(CardMediaItem);
