import React, { useEffect, useMemo, type FunctionComponent } from 'react';
// eslint-disable-next-line jira/restricted/react-redux
import { useStore, Provider } from 'react-redux--next';
import type { Store as ReduxStore } from 'redux';
import { isDismissFlag, isFlagComponent, isBatchAction } from '../../../services/index.tsx';
import type { State } from '../../../services/store/index.tsx';
import type {
	FlagsMapper,
	Store,
	Action,
	DismissFlag,
	ShowFlagFn,
	Flag,
} from '../../../services/types.tsx';

export type Props<TState, TAction extends Action> = {
	mapper: FlagsMapper<TAction, TState>;
	showFlag: ShowFlagFn;
	// eslint-disable-next-line jira/react/handler-naming
	dismissFlag: (arg1: DismissFlag) => void;
};

export type PropsWithStore<TState, TAction extends Action> = Props<TState, TAction> & {
	store: Store<TState> | ReduxStore;
};

type Result = Flag | DismissFlag | undefined;

export const checkStore = <TState,>(store: Store<TState> | ReduxStore): Store<TState> => {
	if (!store) {
		throw new Error('Store not available through direct injection or Provider context');
	}

	if (
		!('addActionListener' in store) ||
		!('removeActionListener' in store) ||
		typeof store.addActionListener !== 'function' ||
		typeof store.removeActionListener !== 'function'
	) {
		throw new Error(
			"Your store doesn't support action listeners; please use the Tangerine store initialiser.",
		);
	}

	return store;
};

export const actionHandler = <TState,>(
	store: Store<TState>,
	result: Result,
	showFlag: ShowFlagFn,
	dismissFlag: (arg1: DismissFlag) => void,
) => {
	if (!result) {
		return;
	}

	if (isDismissFlag(result)) {
		dismissFlag(result);
	} else if (isFlagComponent(result)) {
		const FlagComponent = result;

		const FlagComponentWithProvider = (props: {
			id: string | number;
			onDismissed: (id: number | string) => void;
		}) => (
			<Provider store={store}>
				<FlagComponent {...props} />
			</Provider>
		);
		showFlag(FlagComponentWithProvider);
	} else {
		showFlag(result);
	}
};

export const performHandler = <TState, TAction extends Action>(
	action: TAction,
	state: TState,
	store: Store<TState>,
	mapper: FlagsMapper<TAction, TState>,
	showFlag: ShowFlagFn,
	dismissFlag: (arg1: DismissFlag) => void,
) => {
	// handle batch middleware
	if (isBatchAction(action)) {
		action.payload.forEach((childAction: TAction) => {
			if (childAction.type && typeof childAction.type === 'string') {
				const childResult: Result = mapper(childAction, state);
				actionHandler(store, childResult, showFlag, dismissFlag);
			}
		});
	} else {
		const result: Result = mapper(action, state);
		actionHandler(store, result, showFlag, dismissFlag);
	}
};

const Dispatcher8 = <TAction extends Action, TState extends State>(
	props: PropsWithStore<TState, TAction>,
) => {
	const { store: storeUnchecked, mapper, showFlag, dismissFlag } = props;
	const store = useMemo(() => checkStore(storeUnchecked), [storeUnchecked]);

	useEffect(() => {
		const handleAction = (action: TAction, state: TState) => {
			performHandler(action, state, store, mapper, showFlag, dismissFlag);
		};

		store.addActionListener(handleAction);
		return () => store.removeActionListener(handleAction);
	}, [store, mapper, showFlag, dismissFlag]);

	return null;
};

const Dispatcher8DefaultStore = <TAction extends Action, TState extends State>(
	props: Props<TState, TAction>,
) => {
	const store = useStore<TState>();
	return <Dispatcher8 {...props} store={store} />;
};

export const createDispatcher = <TAction extends Action, TState extends State>(
	customStore?: Store<TState>,
): FunctionComponent<Props<TState, TAction>> => {
	const Dispatcher8CustomStore = (props: Props<TState, TAction>) =>
		customStore ? <Dispatcher8 {...props} store={customStore} /> : null;

	return customStore ? Dispatcher8CustomStore : Dispatcher8DefaultStore;
};
