import type { State } from '../../state/reducers/types.tsx';
import { boardOrderedIssueIdsSelector } from '../../state/selectors/issue/board-issue-selectors.tsx';
import { workFilteredIssuesSelector } from '../../state/selectors/work/work-selectors.tsx';

export type BoardCardCount = {
	cardsCount: number;
	visibleCardsCount: number;
};

export const getBoardCardCount = (state: State): BoardCardCount => ({
	cardsCount: boardOrderedIssueIdsSelector(state).length,
	visibleCardsCount: workFilteredIssuesSelector(state).length,
});
