import React, { useRef } from 'react';
import type { PreloadedQuery } from 'react-relay';
import ProjectBreadcrumb from '@atlassian/jira-breadcrumbs/src/common-breadcrumbs/project/index.tsx';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import RedirectIfProjectArchived from '@atlassian/jira-redirect-archived-project/src/ui/index.tsx';
import { useRelayResource } from '@atlassian/jira-relay-utils/src/services/resources/index.tsx';
import type { viewBoardCustomFiltersQuery } from '@atlassian/jira-relay/src/__generated__/viewBoardCustomFiltersQuery.graphql';
import type { boardFilterRefinementQuery } from '@atlassian/jira-relay/src/__generated__/boardFilterRefinementQuery.graphql';
import { boardResource } from '@atlassian/jira-router-resources-board-resource/src/controllers/sync/index.tsx';
import {
	customFiltersResource,
	boardFilterRefinementResource,
} from '@atlassian/jira-router-resources-board-resource/src/index.tsx';
import { SubProductUpdater } from '@atlassian/jira-spa-apps-common/src/analytics-sub-product/sub-product-updater/index.tsx';
import { HistoryAdapter } from '@atlassian/jira-spa-router-adapters/src/controllers/history/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';

import { useResource, usePathParam } from '@atlassian/react-resource-router';

import { useIsCMPBoardRoute, useIsUserBoardRoute } from '../../../common/util.tsx';
import { renderBoardApp } from '../board-app/index.tsx';
import { NoSoftwareLicense } from '../no-software-license/index.tsx';

export const BoardWithRouter = ({ hasSoftwareLicense }: { hasSoftwareLicense: boolean }) => {
	const isCMPBoard = useIsCMPBoardRoute();
	const isUserBoard = useIsUserBoardRoute();

	const [paramBoardId] = usePathParam('boardId');
	const [projectKey] = usePathParam('projectKey');
	const boardState = useResource(boardResource);
	const tenantContext = useTenantContext();
	const boardId = Number(paramBoardId);

	const isCacheHitRef = useRef(boardState.data != null);
	const isCacheHit = isCacheHitRef.current;

	const boardData = {
		data: boardState.data,
		loading: boardState.loading,
		error: boardState.error,
	};

	const { key, id, name } = boardData.data?.projectLocation || {};

	const projectDetails = { key, id, name };
	const projectKeyFromContext = projectDetails.key || projectKey || '';

	const response = useRelayResource<viewBoardCustomFiltersQuery>(customFiltersResource);
	const customFiltersQueryRef = response.queryReference;

	let boardFilterRefinementQueryRef: PreloadedQuery<boardFilterRefinementQuery> | null | undefined;
	if (expVal('filter_refinement_in_tmp_board', 'isEnabled', false)) {
		({ queryReference: boardFilterRefinementQueryRef } =
			// eslint-disable-next-line react-hooks/rules-of-hooks
			useRelayResource<boardFilterRefinementQuery>(boardFilterRefinementResource));
	}

	return hasSoftwareLicense ? (
		// @ts-expect-error - TS2739 - Type '{ children: (history: any) => Element; }' is missing the following properties from type 'RouteContext': location, query, route, match, action
		<HistoryAdapter>
			{(history) => (
				<ProjectBreadcrumb
					/* eslint-disable-next-line jira/deprecations/no-base-url */
					baseUrl={tenantContext.baseUrl}
					projectKey={projectKeyFromContext}
					projectName={projectDetails.name ?? projectKey}
					projectId={projectDetails.id}
					isSimplifiedProject
				>
					<>
						<SubProductUpdater subProduct="software" />

						{renderBoardApp({
							rapidViewId: boardId,
							projectKey: projectKeyFromContext,
							projectType: SOFTWARE_PROJECT,
							context: { tenantContext },
							boardData: isCMPBoard ? null : boardData,
							history,
							isCacheHit,
							customFiltersQueryRef,
							boardFilterRefinementQueryRef,
							isCMPBoard,
							isUserBoard,
						})}
						<RedirectIfProjectArchived projectKey={projectKey || projectKeyFromContext || null} />
					</>
				</ProjectBreadcrumb>
			)}
		</HistoryAdapter>
	) : (
		<NoSoftwareLicense />
	);
};
