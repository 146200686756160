import React, { useCallback, forwardRef } from 'react';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';

import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { usePopupController } from '@atlassian/jira-servicedesk-common/src/use-popup-controller/index.tsx';

import type { Sprint } from '../../../../model/sprint/sprint-types.tsx';
import messages from './messages.tsx';
import PopupContent from './popup-content/index.tsx';
import PopupTrigger, { type Props as PopupTriggerProps } from './trigger-button/index.tsx';

export interface Props {
	activeSprints: Array<Sprint>; // = started but not completed
	selectedSprints: Array<Sprint>; // = explicitly selected by the user in the UI (same content as activeSprints if no selection)
}

function SprintDetailsV1(props: Props) {
	const { activeSprints, selectedSprints } = props;
	const [isOpen, togglePopup, closePopup] = usePopupController();

	const renderPopupTrigger = useCallback(
		(triggerProps: TriggerProps) => {
			function onClick() {
				togglePopup();
			}

			// the function is coming from useCallback() so it's not less stable than the caller
			// eslint-disable-next-line react/jsx-no-bind
			return <PopupTrigger {...triggerProps} isSelected={isOpen} onClick={onClick} />;
		},
		[isOpen, togglePopup],
	);

	const renderPopupContent = useCallback(() => {
		return (
			<PopupContent selectedSprints={selectedSprints} activeSprintCount={activeSprints.length} />
		);
	}, [selectedSprints, activeSprints]);

	return (
		<Popup
			isOpen={isOpen}
			onClose={closePopup}
			placement="bottom-end"
			content={renderPopupContent}
			trigger={renderPopupTrigger}
			role="dialog"
		/>
	);
}

function SprintDetailsV2(props: Props) {
	const { activeSprints, selectedSprints } = props;
	const [isOpen, togglePopup, closePopup] = usePopupController();
	const { formatMessage } = useIntl();

	const renderPopupTrigger = useCallback(
		(triggerProps: TriggerProps) => (
			<ConfiguredPopupTrigger
				{...{
					isOpen,
					togglePopup,
					selectedSprintsCount: selectedSprints.length,
					activeSprintsCount: activeSprints.length,
					...triggerProps,
				}}
			/>
		),
		[isOpen, togglePopup, selectedSprints, activeSprints],
	);

	const renderPopupContent = useCallback(() => {
		return (
			<PopupContent selectedSprints={selectedSprints} activeSprintCount={activeSprints.length} />
		);
	}, [selectedSprints, activeSprints]);

	return (
		<Popup
			isOpen={isOpen}
			onClose={closePopup}
			placement="bottom-end"
			content={renderPopupContent}
			trigger={renderPopupTrigger}
			role="dialog"
			label={formatMessage(messages.ariaLabel)}
		/>
	);
}

type ConfiguredTriggerProps = PopupTriggerProps & {
	isOpen: boolean;
	togglePopup: () => void;
	selectedSprintsCount: number;
	activeSprintsCount: number;
};

const ConfiguredPopupTrigger = forwardRef(
	(props: ConfiguredTriggerProps, ref: React.Ref<HTMLButtonElement>) => {
		const { isOpen, togglePopup, selectedSprintsCount, activeSprintsCount, ...triggerProps } =
			props;

		const onClick: PopupTriggerProps['onClick'] = (_, analyticsEvent) => {
			togglePopup();
			fireUIAnalytics(analyticsEvent, 'sprintDetailsButton');
		};

		return (
			<PopupTrigger
				ref={ref}
				{...triggerProps}
				isSelected={isOpen}
				// by the spec, the "sprint details" control
				// only appear when there are active sprints
				// this "isDisabled" check is just a safety / defensive programming
				isDisabled={Math.max(selectedSprintsCount, activeSprintsCount) === 0}
				// the `onClick` function is coming from useCallback() so it's not less stable than the caller
				onClick={onClick}
				analyticsContext={{
					attributes: {
						intent: isOpen ? 'close' : 'open',
						activeSprintsCount,
						selectedSprintsCount,
					},
				}}
			/>
		);
	},
);

const SprintDetails = componentWithFG('jira_nav4_beta_drop_2', SprintDetailsV2, SprintDetailsV1);

export default SprintDetails;
