import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	visibleIssueCount: {
		id: 'portfolio-3-plan-increment-common.ui.sprint-column-header.visible-issue-count',
		defaultMessage: '{count} / {total} {total, plural, one {issue} other {issues}}',
		description: 'Number of visible issues in swimlane column',
	},
	totalIssueCount: {
		id: 'portfolio-3-plan-increment-common.ui.sprint-column-header.total-issue-count',
		defaultMessage: '{total} {total, plural, one {issue} other {issues}}',
		description: 'Number of total issues in swimlane column',
	},
	completedSprint: {
		id: 'portfolio-3-plan-increment-common.ui.sprint-column-header.completed-sprint',
		defaultMessage: 'Completed sprint',
		description: 'Label for past sprints in the program board swimlane column',
	},
	activeSprint: {
		id: 'portfolio-3-plan-increment-common.ui.sprint-column-header.active-sprint',
		defaultMessage: 'Active sprint',
		description: 'Label for current sprints in the program board swimlane column',
	},
	futureSprint: {
		id: 'portfolio-3-plan-increment-common.ui.sprint-column-header.future-sprint',
		defaultMessage: 'Future sprint',
		description: 'Label for future sprints in the program board swimlane column',
	},
	overlappingDatesWarningTitle: {
		id: 'portfolio-3-plan-increment-common.ui.sprint-column-header.overlapping-dates-warning-title',
		defaultMessage: 'Overlapping sprint dates',
		description: 'Title for the warning when the sprint dates are overlapped',
	},
	overlappingDatesWarningLabel: {
		id: 'portfolio-3-plan-increment-common.ui.sprint-column-header.overlapping-dates-warning-label',
		defaultMessage: 'Warning icon for the overlapping sprint dates',
		description: 'Label for the warning when the sprint dates are overlapped',
	},
	warningForSprintDatesOverlappedWithOneSprint: {
		id: 'portfolio-3-plan-increment-common.ui.sprint-column-header.warning-for-sprint-dates-overlapped-with-one-sprint',
		defaultMessage:
			'{sprintName} overlaps with {anotherSprintName} by about {days} {days, plural, one {day} other {days}}',
		description: 'Warning for the sprint dates are overlapped with another one sprint dates',
	},
	warningForSprintDatesOverlappedWithMoreThanOneSprint: {
		id: 'portfolio-3-plan-increment-common.ui.sprint-column-header.warning-for-sprint-dates-overlapped-with-more-than-one-sprint',
		defaultMessage: '{sprintName} overlaps with {otherSprints} and {lastSprint}',
		description: 'Warning for the sprint dates are overlapped with other sprints dates',
	},
	capacityAllocatedLabel: {
		id: 'portfolio-3-plan-increment-common.ui.sprint-column-header.capacity-allocated-label',
		defaultMessage: '{usedCapacity}/{totalCapacity} {unit} allocated',
		description: 'Label for the allocated capacity',
	},
	visibleIssueCountIssueTermRefresh: {
		id: 'portfolio-3-plan-increment-common.ui.sprint-column-header.visible-issue-count-issue-term-refresh',
		defaultMessage: '{count} / {total} {total, plural, one {work item} other {work items}}',
		description: 'Number of visible issues in swimlane column',
	},
	totalIssueCountIssueTermRefresh: {
		id: 'portfolio-3-plan-increment-common.ui.sprint-column-header.total-issue-count-issue-term-refresh',
		defaultMessage: '{total} {total, plural, one {work item} other {work items}}',
		description: 'Number of total issues in swimlane column',
	},
});
