import React from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { styledComponentWithCondition } from '@atlassian/jira-compiled-migration/src/ui/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import RestrictedImage from '../../../../common/assets/restricted.svg';
import { columnHeaderHeight } from '../../../../common/constants/styles/index.tsx';

type NoTransitionContainerProps = {
	noTransitionMessage: MessageDescriptor;
};

export function NoTransitionContainer({ noTransitionMessage }: NoTransitionContainerProps) {
	const { formatMessage } = useIntl();
	const message = formatMessage(noTransitionMessage);

	return (
		<Wrapper>
			<Img src={RestrictedImage} role="presentation" alt={message} />
			{message}
		</Wrapper>
	);
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WrapperOld = styled.div({
	position: 'sticky',
	display: 'flex',
	flexDirection: 'column',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${columnHeaderHeight * 2}px`,
	alignItems: 'center',
	justifyContent: 'center',
	textAlign: 'center',
	font: token('font.body.small'),
	color: token('color.text.subtlest', '#626F86'),
	padding: token('space.300', '24px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WrapperNew = styled.div({
	position: 'sticky',
	display: 'flex',
	flexDirection: 'column',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${columnHeaderHeight * 2}px`,
	alignItems: 'center',
	justifyContent: 'center',
	textAlign: 'center',
	color: token('color.text.subtlest', '#626F86'),
	font: token('font.body.small'),
	padding: token('space.300', '24px'),
});

const Wrapper = styledComponentWithCondition(
	() => fg('tnk-1614-visual-refresh-boardkit'),
	WrapperNew,
	WrapperOld,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Img = styled.img({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 3}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 3}px`,
	marginBottom: token('space.100', '8px'),
});
