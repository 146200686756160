import React, { useCallback, useMemo } from 'react';
import get from 'lodash/get';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { Filters } from '@atlassian/jira-software-filters/src/common/types.tsx';
import FilterSelectionBarView from '@atlassian/jira-software-filters/src/ui/filter-selection-bar/index.tsx';
import { CUSTOM_FILTER } from '../../../../model/filter/filter-types.tsx';
import { setAllFilters } from '../../../../state/actions/filter/index.tsx';
import { useBoardDispatch, useBoardSelector } from '../../../../state/index.tsx';
import {
	getCustomFilters,
	getWorkFilters,
} from '../../../../state/selectors/work/work-selectors.tsx';

const FilterSelectionBar = () => {
	const dispatch = useBoardDispatch();
	const allSelectedWorkFilters = get(useBoardSelector(getWorkFilters), 'values');
	const selectedCustomFilters = get(allSelectedWorkFilters, CUSTOM_FILTER);
	const selectedCustomFiltersSet = useMemo(
		() => new Set(selectedCustomFilters),
		[selectedCustomFilters],
	);
	const allCustomFilters = useBoardSelector(getCustomFilters);

	const onChange = useCallback(
		(filters: Filters, analyticsEvent: UIAnalyticsEvent) => {
			dispatch(
				setAllFilters({ ...allSelectedWorkFilters, ...filters }, CUSTOM_FILTER, analyticsEvent),
			);
		},
		[allSelectedWorkFilters, dispatch],
	);

	return (
		<FilterSelectionBarView
			filters={allCustomFilters}
			selectedFilters={selectedCustomFiltersSet}
			onChange={onChange}
		/>
	);
};

export default FilterSelectionBar;
