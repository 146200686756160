import React from 'react';
import { useBulkOpsSessionContext } from '@atlassian/jira-bulk-operations-analytics/src/controllers/index.tsx';
import { ContextMenuAnalytics } from '../controllers/analytics/main/main.tsx';
import type { WithAnalyticsProps } from '../controllers/analytics/main/types.tsx';
import { ContextMenuContainer } from '../controllers/context-menu/index.tsx';
import { ContextMenu as ContextMenuInternal } from './context-menu/index.tsx';
import type { ContextMenuProps } from './context-menu/types.tsx';
import { makeScope } from './utils.tsx';

export const ContextMenu = ({
	cardId,
	selectedCardIds,
	analyticsMapping,
	scope,
	...props
}: WithAnalyticsProps<ContextMenuProps>) => {
	const fullScope = makeScope(scope, 'context');
	const bulkOpsFlow = useBulkOpsSessionContext();

	return (
		<ContextMenuContainer
			isGlobal
			selectedCardIds={selectedCardIds}
			analyticsMapping={analyticsMapping}
			cardId={cardId}
			bulkOpsFlow={bulkOpsFlow}
		>
			<ContextMenuAnalytics scope={fullScope} cardId={cardId}>
				<ContextMenuInternal scope={fullScope} {...props} />
			</ContextMenuAnalytics>
		</ContextMenuContainer>
	);
};
