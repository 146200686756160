import type { CreateUnit, Unit } from './types.tsx';

/** Wrapper for applying transforms to time and returning a unit.
 */
export const createUnit =
	(timeInSeconds: number) =>
	({ suffix, seconds, type, transform }: CreateUnit): Unit => {
		const distance = timeInSeconds / seconds;
		const time = transform ? transform(distance) : distance;

		return {
			seconds,
			suffix,
			time,
			type,
		};
	};
