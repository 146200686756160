import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import Tooltip from '@atlaskit/tooltip';
import { ADDBUTTON_HEIGHT } from '../../../../../../common/ui/assignee/constants.tsx';

export const AddPeopleTooltip = ({
	content,
	children,
}: {
	content: string;
	children: ReactNode;
}) => (
	<Tooltip content={content}>
		<TooltipWrapper>{children}</TooltipWrapper>
	</Tooltip>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TooltipWrapper = styled.div({
	display: 'inline-block',
	position: 'relative',
	outline: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${ADDBUTTON_HEIGHT}px`,
});
