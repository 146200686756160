import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'software.main.board-container.board.backlog-feature-dialog.title',
		defaultMessage: 'Free up your board',
		description:
			"Title of the modal shown to users when they don't have the backlog enabled on an Agility board offering to enable the backlog",
	},
	description: {
		id: 'software.main.board-container.board.backlog-feature-dialog.description',
		defaultMessage:
			'Your {initialColumnName} column is getting quite full. Would you like to try out the ',
		description:
			"Text of the modal shown to users when they don't have the backlog enabled on an Agility board offering to enable the backlog",
	},
	boldDescription: {
		id: 'software.main.board-container.board.backlog-feature-dialog.bold-description',
		defaultMessage: 'Backlog?',
		description: 'Part of the text on the modal that shown in bold font style',
	},
	confirm: {
		id: 'software.main.board-container.board.backlog-feature-dialog.confirm',
		defaultMessage: 'Enable the backlog',
		description: 'Primary action, button text confirming user wants to enable the backlog',
	},
	cancel: {
		id: 'software.main.board-container.board.backlog-feature-dialog.cancel',
		defaultMessage: 'Ignore',
		description: 'Secondary action, button text cancelling to enable the backlog',
	},
});
