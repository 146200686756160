import React, { type ComponentType } from 'react';
import { ExperienceTracker as ViewExperienceTracker } from '@atlassian/jira-common-experience-tracking-viewing/src/view/experience-tracker-consumer/tracker-base/index.tsx';
import { EXPERIENCE_TRACKER_LOCATION_BOARD } from '../../model/constants.tsx';

type ExperienceProps = {
	onExperienceSuccess: () => void;
};

export const withViewExperienceTracker = <T extends {}>(
	ComponentBeingTracked: ComponentType<T & ExperienceProps>,
) => {
	const WithViewExperienceTracker: ComponentType<Omit<T, 'onExperienceSuccess'>> = (props) => (
		<ViewExperienceTracker location={EXPERIENCE_TRACKER_LOCATION_BOARD}>
			{({ onExperienceSuccess }) => (
				// @ts-expect-error - Type '{ onExperienceSuccess: () => void; } & Omit<T, "onExperienceSuccess">' is not assignable to type 'T'.
				<ComponentBeingTracked onExperienceSuccess={onExperienceSuccess} {...props} />
			)}
		</ViewExperienceTracker>
	);

	return WithViewExperienceTracker;
};
