import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	childIssuesMetadata: {
		id: 'card.common.child-issues.child-issues-metadata',
		defaultMessage: '{complete} of {total} child issues complete',
		description:
			'Tooltip around an icon indicating how many issues are complete out of total child issues',
	},
	childIssuesMetadataIssueTermRefresh: {
		id: 'card.common.child-issues.child-issues-metadata-issue-term-refresh',
		defaultMessage: '{complete} of {total} child work items complete',
		description:
			'Tooltip around an icon indicating how many issues are complete out of total child issues',
	},
});
