import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'portfolio-3-plan-increment-common.ui.error-screens.custom-field.not-in-plan.title',
		defaultMessage: 'The custom field "{fieldName}" was removed from your plan.',
		description: 'Title for the custom field not in plan error screen',
	},
	description: {
		id: 'portfolio-3-plan-increment-common.ui.error-screens.custom-field.not-in-plan.description',
		defaultMessage: "Without this field, your program board can't load.",
		description: 'Description for custom field not in plan screen',
	},
	restoreField: {
		id: 'portfolio-3-plan-increment-common.ui.error-screens.custom-field.not-in-plan.restore-field',
		defaultMessage: 'Restore this field',
		description: 'Call to action button text',
	},
	imgAlt: {
		id: 'portfolio-3-plan-increment-common.ui.error-screens.custom-field.not-in-plan.img-alt',
		defaultMessage: 'Drawing of two people connecting plugs',
		description: 'Alt text for the image illustration',
	},
	errorFlagTitle: {
		id: 'portfolio-3-plan-increment-common.ui.error-screens.custom-field.not-in-plan.error-flag-title',
		defaultMessage: 'Something went wrong',
		description: 'Title of error flag.',
	},
	errorFlagDescription: {
		id: 'portfolio-3-plan-increment-common.ui.error-screens.custom-field.not-in-plan.error-flag-description',
		defaultMessage:
			'There was an error when restoring custom field in program board. Please try refreshing the page.',
		description: 'Description of error flag.',
	},
});
