import React, { type ReactNode, useCallback, useEffect, useState } from 'react';
import noop from 'lodash/noop';
import Button from '@atlaskit/button';
import { IconButton } from '@atlaskit/button/new';
import CustomizeIcon from '@atlaskit/icon/core/customize';
import PreferencesIcon from '@atlaskit/icon/glyph/preferences';
import { Box, xcss } from '@atlaskit/primitives';
import { Show } from '@atlaskit/primitives/responsive';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useBoardWorkSuggestionsSpotlightActions } from '@atlassian/jira-insights-next-best-task/src/services/spotlight-container/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { ResponsiveThemedButton } from '@atlassian/jira-project-theme-components/src/ui/responsive-themed-button/ResponsiveThemedButton.tsx';
import { ThemedButton } from '@atlassian/jira-project-theme-components/src/ui/themed-button/ThemedButton.tsx';
import { useGlobalRefStoreActions } from '@atlassian/jira-refs-store/src/index.tsx';
import { ResponsiveButton } from '@atlassian/jira-responsive-button/src/ui/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import {
	VIEW_SETTINGS_BUTTON_LG,
	VIEW_SETTINGS_BUTTON_SM,
	VIEW_SETTINGS_BUTTON_RESPONSIVE,
} from '../../common/constants.tsx';
import type { View } from '../../common/types/constant.tsx';
import type { ExtraField } from '../../common/types/menu.tsx';
import { useCardDetailsInitialState } from '../../controllers/hooks/use-card-details-initial-state/index.tsx';
import { useOnBacklog } from '../../controllers/hooks/use-on-backlog/index.tsx';
import { useSyncFromLocalStorage } from '../../controllers/hooks/use-sync-from-local-storage/index.tsx';
import messages from './messages.tsx';

type Props = {
	isSelected: boolean;
	/**
	 * All spacing should come from flex container `gap:` or primitive `space=`.
	 */
	removeMargins?: boolean;
	onClick: (isPanelOpen: boolean) => void;
	ShowDI?: typeof Show;
	ariaControls?: string;
	ariaExpanded?: boolean;
	ariaHasPopup?: boolean | 'dialog';
	/* Indicates whether the button should be rendered with a themed appearance,
	specifically for views used in Business projects with background styling */
	isThemedButton?: boolean;
};

export const ViewSettingsSyncFromLocalStorageWrapper = ({
	boardId,
	view,
	cardExtraFields = [],
	isDaysInColumnEnabled = false,
	isDueDateEnabled = false,
	isLabelsEnabled = false,
	isEstimateEnabled = false,
	children,
	showEpicAsPanel = false,
	isVersionsEnabled = false,
	isPriorityEnabled = false,
}: {
	boardId: number;
	view: (typeof View)[keyof typeof View];
	children: ReactNode;
	cardExtraFields?: ExtraField[];
	isDaysInColumnEnabled?: boolean;
	isDueDateEnabled?: boolean;
	isLabelsEnabled?: boolean;
	showEpicAsPanel?: boolean;
	isVersionsEnabled?: boolean;
	isEstimateEnabled?: boolean;
	isPriorityEnabled?: boolean;
}) => {
	useOnBacklog(boardId, view, showEpicAsPanel, isVersionsEnabled);
	useSyncFromLocalStorage(
		boardId,
		view,
		cardExtraFields,
		isDaysInColumnEnabled,
		isDueDateEnabled,
		isLabelsEnabled,
		isEstimateEnabled,
		isPriorityEnabled,
	);
	useCardDetailsInitialState(
		boardId,
		view,
		isDaysInColumnEnabled,
		isDueDateEnabled,
		isLabelsEnabled,
		isEstimateEnabled,
		isPriorityEnabled,
	);

	return <>{children}</>;
};

export const ViewSettingsButton = ({
	isSelected,
	onClick = noop,
	removeMargins,
	ShowDI = Show,
	isThemedButton = false,
	...restProps
}: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState(isSelected);
	const [, { register }] = useGlobalRefStoreActions();

	const handleClick = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			}),
			'viewSettingsButton',
		);

		setIsOpen(!isOpen);
		onClick(!isOpen);
	}, [createAnalyticsEvent, isOpen, onClick]);

	const openViewSettings = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			}),
			'viewSettingsButton',
		);

		setIsOpen(true);
		setTimeout(() => onClick(true));
	}, [createAnalyticsEvent, onClick]);

	const { setOpenViewSettings } = useBoardWorkSuggestionsSpotlightActions();

	useEffect(() => {
		if (setOpenViewSettings) {
			setOpenViewSettings(openViewSettings);
		}
	}, [openViewSettings, setOpenViewSettings]);

	const onRefButtonLarge = useCallback(
		(ref: HTMLDivElement | null) => {
			register(VIEW_SETTINGS_BUTTON_LG, ref);
		},
		[register],
	);

	const onRefButtonSmall = useCallback(
		(ref: HTMLDivElement | null) => {
			register(VIEW_SETTINGS_BUTTON_SM, ref);
		},
		[register],
	);

	const onRefButtonResponsive = useCallback(
		(ref: HTMLButtonElement | null) => {
			register(VIEW_SETTINGS_BUTTON_RESPONSIVE, ref);
		},
		[register],
	);

	useEffect(() => {
		setIsOpen(isSelected);
	}, [isSelected]);

	const iconColor = isSelected
		? token('color.icon.selected', colors.B400)
		: token('color.icon', colors.N300);

	if (isVisualRefreshEnabled()) {
		return isThemedButton ? (
			<ThemedButton
				testId="software-view-settings.ui.view-settings-button.responsive-button"
				appearance="default"
				iconBefore={<CustomizeIcon label="" color="currentColor" spacing="spacious" />}
				label={formatMessage(messages.buttonLabel)}
				isSelected={isOpen}
				onClick={handleClick}
				ref={onRefButtonResponsive}
				aria-label={formatMessage(messages.buttonLabel)}
				aria-expanded={restProps.ariaExpanded ?? isSelected}
				aria-controls={restProps.ariaControls}
				aria-haspopup={restProps.ariaHasPopup ?? true}
				title={fg('visual-refresh_drop_1') ? formatMessage(messages.buttonLabel) : undefined}
			/>
		) : (
			<IconButton
				testId="software-view-settings.ui.view-settings-button.icon-button"
				icon={CustomizeIcon}
				label={formatMessage(messages.buttonLabel)}
				isSelected={isOpen}
				onClick={handleClick}
				ref={onRefButtonResponsive}
				aria-expanded={restProps.ariaExpanded ?? isSelected}
				aria-controls={restProps.ariaControls}
				aria-haspopup={restProps.ariaHasPopup ?? true}
				{...(fg('jira_nav4_beta_drop_1') ? { isTooltipDisabled: false } : {})}
			/>
		);
	}

	if (fg('jira-calendar-business-theme')) {
		return (
			<ResponsiveThemedButton
				testId="software-view-settings.ui.view-settings-button.responsive-button"
				appearance="default"
				icon={<CustomizeIcon label="" color="currentColor" spacing="spacious" />}
				label={formatMessage(messages.buttonLabel)}
				isSelected={isOpen}
				onClick={handleClick}
				ref={onRefButtonResponsive}
				aria-label={formatMessage(messages.buttonLabel)}
				aria-expanded={restProps.ariaExpanded ?? isSelected}
				aria-controls={restProps.ariaControls}
				aria-haspopup={restProps.ariaHasPopup ?? true}
			/>
		);
	}

	return (
		<>
			{getWillShowNav4() ? (
				<ResponsiveButton
					testId="software-view-settings.ui.view-settings-button.responsive-button"
					icon={CustomizeIcon}
					label={formatMessage(messages.buttonLabel)}
					isSelected={isOpen}
					onClick={handleClick}
					ref={onRefButtonResponsive}
					aria-expanded={restProps.ariaExpanded ?? isSelected}
					aria-controls={restProps.ariaControls}
					aria-haspopup={restProps.ariaHasPopup ?? true}
				/>
			) : (
				<Box xcss={removeMargins ? undefined : buttonWrapperStyles}>
					<ShowDI above="lg">
						<Button
							testId="software-view-settings.ui.view-settings-button.large-button"
							appearance="default"
							isSelected={isOpen}
							onClick={handleClick}
							ref={onRefButtonLarge}
							iconBefore={
								<CustomizeIcon
									label={formatMessage(messages.buttonLabel)}
									color={iconColor}
									LEGACY_fallbackIcon={PreferencesIcon}
									LEGACY_primaryColor={iconColor}
								/>
							}
							aria-label={formatMessage(messages.buttonLabel)}
							aria-expanded={restProps.ariaExpanded ?? isSelected}
							aria-controls={restProps.ariaControls}
							aria-haspopup={restProps.ariaHasPopup ?? true}
						>
							{formatMessage(messages.buttonLabel)}
						</Button>
					</ShowDI>
					<ShowDI below="lg">
						<Tooltip content={formatMessage(messages.buttonLabel)}>
							<Button
								testId="software-view-settings.ui.view-settings-button.small-button"
								appearance="default"
								isSelected={isOpen}
								onClick={handleClick}
								spacing="none"
								ref={onRefButtonSmall}
								aria-expanded={restProps.ariaExpanded ?? isSelected}
								aria-controls={restProps.ariaControls}
								aria-haspopup={restProps.ariaHasPopup ?? true}
							>
								<Box xcss={iconWrapperStyles}>
									<CustomizeIcon
										label={formatMessage(messages.buttonLabel)}
										color={iconColor}
										LEGACY_fallbackIcon={PreferencesIcon}
										LEGACY_primaryColor={iconColor}
									/>
								</Box>
							</Button>
						</Tooltip>
					</ShowDI>
				</Box>
			)}
		</>
	);
};

const iconWrapperStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: '32px',
	height: '32px',
});

const buttonWrapperStyles = xcss({
	marginLeft: 'space.100',
});
