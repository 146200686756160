import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { openIssueModal, closeIssueModal } from '../../state/actions/issue/modal/index.tsx';
import SyncWithRouting from './view.tsx';

const mapDispatchToProps = {
	onOpenIssueModal: openIssueModal,
	onCloseIssueModal: closeIssueModal,
} as const;

export default connect(null, mapDispatchToProps)(SyncWithRouting);
