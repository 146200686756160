import map from 'lodash/map';
import { Observable } from 'rxjs/Observable';
import { filteredCardsSuccess } from '../../state/actions/card/filtered-cards/index.tsx';
import { devStatusLoad } from '../../state/actions/issue/dev-status/index.tsx';
import type { WorkDataSetAction } from '../../state/actions/work/index.tsx';
import type { Action } from '../../state/types.tsx';

export function handleCMPBoardDataSet(
	response: WorkDataSetAction,
	isCustomFilterOn = false,
): Observable<Action> {
	const { payload: workModeData } = response;
	// TODO: We can implement resources loading and then this would not need to be here.
	const issueIds = map(workModeData.issues, (issue) => issue.id);
	const actions: Action[] = [response, devStatusLoad(issueIds)];

	const allIssues = [...(workModeData.issues ?? []), ...(workModeData.issueChildren ?? [])];
	const visibleIssueIds = allIssues
		.filter((issue) => issue.isVisible === undefined || issue.isVisible) // IF not set, then visible. Otherwise check
		.map((issue) => String(issue.id));

	// Only fire the filtered cards success (set the filtered card Ids) if there is any difference
	// or when custom filters are on
	if (isCustomFilterOn || visibleIssueIds.length !== allIssues.length) {
		actions.push(filteredCardsSuccess(visibleIssueIds));
	}

	return Observable.from(actions);
}
