import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import type { StatusCategory } from '../../model/software/software-types.tsx';
import { STATUS_CATEGORY_URL } from '../../rest/software/software-api-urls.tsx';
import {
	SOFTWARE_APP_LOADED,
	SOFTWARE_APP_INITIAL_STATE_LOADED,
} from '../../state/actions/software/index.tsx';
import {
	statusCategoryLoadSuccess,
	statusCategoryLoadFailure,
} from '../../state/actions/status-category/index.tsx';
import { contextPathSelector } from '../../state/selectors/software/software-selectors.tsx';
import type { Action, MiddlewareAPI, ActionsObservable, State } from '../../state/types.tsx';

const getUrl = (contextPath: string) => `${contextPath}${STATUS_CATEGORY_URL}`;

const loadStatusCategories = (state: State): Observable<StatusCategory[] & unknown> => {
	const url = getUrl(contextPathSelector(state));
	return fetchJson$(url);
};

const handleSuccess = (result: StatusCategory[] & unknown) => {
	const statusCategories: StatusCategory[] = result.map((statusCategory) => ({
		id: statusCategory.id,
		key: statusCategory.key,
		name: statusCategory.name,
	}));

	return Observable.of(statusCategoryLoadSuccess(statusCategories));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleFailure = (error: any) => Observable.of(statusCategoryLoadFailure(error));

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable, store: MiddlewareAPI): Observable<Action> =>
	action$
		.ofType(SOFTWARE_APP_LOADED, SOFTWARE_APP_INITIAL_STATE_LOADED)
		.switchMap(() => loadStatusCategories(store.getState()))
		.flatMap((result) => handleSuccess(result))
		.catch((error) => handleFailure(error));
