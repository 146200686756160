import React from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import type { MenuRendererProps } from './common/types.tsx';
import { ContextMenuErrorBoundary } from './common/ui/error-boundary/index.tsx';
import type { WithAnalyticsProps } from './controllers/analytics/main/types.tsx';
import type { MeatballMenuProps } from './ui/meatball-menu/types.tsx';
import { MenuRendererPlaceholder } from './ui/menu-renderer-placeholder/index.tsx';

/* istanbul ignore next */
// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const MeatballMenuLazy = lazy(() =>
	import(
		/* webpackChunkName: "async-software-context-menu-meatball-menu" */ './ui/MeatballMenu.tsx'
	).then((module) => module.MeatballMenu),
);

/* istanbul ignore next */
// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const AsyncMenuRendererLazy = lazy(() =>
	import(
		/* webpackChunkName: "async-software-context-menu-menu-renderer" */ './ui/menu-renderer'
	).then((module) => module.MenuRenderer),
);

const InternalAsyncMeatballMenu = (props: WithAnalyticsProps<MeatballMenuProps>) => (
	<Placeholder name="async-software-context-menu-meatball-menu" fallback={<></>}>
		<MeatballMenuLazy {...props} />
	</Placeholder>
);

export const AsyncMeatballMenu = (props: WithAnalyticsProps<MeatballMenuProps>) => (
	<ContextMenuErrorBoundary id="jira-software-context-menu-meatball-menu" fallback="unmount">
		<InternalAsyncMeatballMenu {...props} />
	</ContextMenuErrorBoundary>
);

export const AsyncMenuRenderer = (props: MenuRendererProps) => (
	<Placeholder
		name="async-software-context-menu-menu-renderer"
		fallback={<MenuRendererPlaceholder menuItems={props.menuItems} />}
	>
		<AsyncMenuRendererLazy {...props} />
	</Placeholder>
);
