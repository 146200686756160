import { useCallback, useEffect, useRef } from 'react';
/* eslint-disable-next-line camelcase */
import { unstable_batchedUpdates } from 'react-dom';
import { fg } from '@atlassian/jira-feature-gating';

type Task = () => void;
const queue = new Set<Task>();
let hasQueuedFlush = false;

function flush() {
	const tasks: Task[] = Array.from(queue);
	queue.clear();
	unstable_batchedUpdates(() => {
		tasks.forEach((task) => {
			task();
		});
	});
}

/**
 * On React 16, updates triggered from events that aren't React event listeners
 * are not batched. This causes performance issues. This method will perform batching
 * of updates on React 16.
 */
export function autobatch(task: Task): () => void {
	queue.add(task);

	if (!hasQueuedFlush) {
		hasQueuedFlush = true;

		queueMicrotask(() => {
			hasQueuedFlush = false;
			flush();
		});
	}

	return () => {
		queue.delete(task);
	};
}

export function useAutobatch(): (task: Task) => void {
	if (!fg('jsw_backlog_pragmatic_dnd_automatic_batching')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		return useCallback((task) => task(), []);
	}

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const cancelFunctions = useRef<Task[]>([]);
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const batchingFunction = useCallback((task: Task) => {
		const cancel = autobatch(task);
		cancelFunctions.current.push(cancel);
	}, []);
	// eslint-disable-next-line react-hooks/rules-of-hooks
	useEffect(
		() => () => {
			cancelFunctions.current.forEach((cancel) => {
				cancel();
			});
		},
		[],
	);

	return batchingFunction;
}
