import React, { useCallback, useMemo, memo } from 'react';
import noop from 'lodash/noop';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import type { AnalyticsAttributes } from '@atlassian/jira-product-analytics-bridge';
import type {
	QuickFilter as QuickFilterType,
	QuickFilterId as QuickFilterTypeId,
	CheckboxSelectEntry,
} from '../../../common/types.tsx';
import ListFilter from '../../../common/ui/list/index.tsx';
import messages from './messages.tsx';

type Props = {
	filters: QuickFilterType[];
	selectedFilters?: QuickFilterTypeId[];
	onChange: (ids: QuickFilterTypeId[], attributes?: AnalyticsAttributes) => void;
	isNested?: boolean;
};

const MAX_SELECTION_ALLOWED = 10;

const QuickFilter = ({
	filters = [],
	selectedFilters = [],
	onChange = noop,
	isNested = false,
}: Props) => {
	const { formatMessage } = useIntl();

	const values = useMemo(
		(): CheckboxSelectEntry[] =>
			filters.map((filter) => ({
				label: filter.name,
				value: filter.id,
			})),
		[filters],
	);

	const onChangeMapValues = useCallback(
		(entries: CheckboxSelectEntry[]) => {
			const analyticAttributes = { isNested };
			onChange(
				entries.map((entry) => entry.value),
				analyticAttributes,
			);
		},
		[onChange, isNested],
	);

	const maxSelectionReachedTooltip = formatMessage(messages.maxSelectionReachedTooltip, {
		maxSelectionAllowed: MAX_SELECTION_ALLOWED,
	});

	return (
		<ListFilter
			label={formatMessage(messages.label)}
			values={values}
			selectedValues={selectedFilters}
			onChange={onChangeMapValues}
			maxSelectionAllowed={MAX_SELECTION_ALLOWED}
			maxSelectionReachedTooltip={maxSelectionReachedTooltip}
			isNested={isNested}
		/>
	);
};

const QuickFilterComponent = memo<Props>(QuickFilter);

QuickFilterComponent.displayName = 'QuickFilter';
// @ts-expect-error - TS2339 - Property 'whyDidYouRender' does not exist on type 'NamedExoticComponent<Props>'.
QuickFilterComponent.whyDidYouRender = true;

export default QuickFilterComponent;
