import React, { type MouseEvent, useCallback } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { LinkItem } from '@atlaskit/menu';
import { AnalyticsEventToProps, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { Props } from './types.tsx';

const LinkItemWithAnalytics = AnalyticsEventToProps('link', {
	onClick: 'clicked',
})(({ createAnalyticsEvent, ...otherProps }) => <LinkItem {...otherProps} />);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ itemKey, onClick, ...otherProps }: Props) => {
	const onClickWithAnalytics = useCallback(
		(event: MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
			onClick && onClick();
			fireUIAnalytics(analyticsEvent, 'automationMenuLinkItem', { itemKey });
		},
		[itemKey, onClick],
	);

	return <LinkItemWithAnalytics {...otherProps} onClick={onClickWithAnalytics} />;
};
