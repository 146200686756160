import clearMarksWithPrefix from '@atlassian/jira-common-performance/src/clear-marks-with-prefix.tsx';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';
import { getPerformanceFFsString } from '../common/util/collectors.tsx';
import {
	type ObservationData,
	type LongTasksSloSettings,
	isExpandedObservationData,
} from '../types.tsx';
import { triggerAnalytics, generateLongTaskAnalyticsSummaryWithSlo } from './common.tsx';

export const dndReporterWithSlo =
	(sloSettings?: LongTasksSloSettings) =>
	(
		observationData: ObservationData,
		extraProps: {
			[key: string]: boolean;
		},
		stopTimestamp: DOMHighResTimeStamp,
	) => {
		if (isExpandedObservationData(observationData)) {
			const { eventStartPerformanceMark } = observationData;
			if (eventStartPerformanceMark) {
				clearMarksWithPrefix(eventStartPerformanceMark);
			}

			const startTimes = observationData.events.map((event) => event.timestamp);

			const startLongTaskEndTimestamp = observationData.dragStartEndTimestamp;
			const startLongTasks = observationData.events.filter(
				(event) => event.timestamp < startLongTaskEndTimestamp,
			);
			const movingLongTasks = observationData.events.filter(
				(event) => event.timestamp >= startLongTaskEndTimestamp && event.timestamp < stopTimestamp,
			);
			const dropLongTasks = observationData.events.filter(
				(event) => event.timestamp >= stopTimestamp,
			);

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const analyticsData: any = {
				...extraProps,
				...generateLongTaskAnalyticsSummaryWithSlo('start', startLongTasks, sloSettings),
				...generateLongTaskAnalyticsSummaryWithSlo('moving', movingLongTasks, sloSettings),
				...generateLongTaskAnalyticsSummaryWithSlo('drop', dropLongTasks, sloSettings),
				type: observationData.type || 'unknown',
				measureTimespan:
					performance.now() - Math.min(observationData.startTimestamp, ...startTimes),
				performanceFFs: getPerformanceFFsString(),
			};

			const triggerSoftwareAnalytics = triggerAnalytics(`software.${observationData.appName}`);
			triggerSoftwareAnalytics(`long-tasks-${observationData.type || 'dnd'}`, analyticsData);

			if (sloSettings) {
				const attributes = {
					...extraProps,
					type: observationData.type || 'unknown',
					startLongTaskSloIsSuccess: analyticsData.startLongTaskSloIsSuccess,
					movingLongTaskSloIsSuccess: analyticsData.movingLongTaskSloIsSuccess,
					dropLongTaskSloIsSuccess: analyticsData.dropLongTaskSloIsSuccess,
					...sloSettings,
				};

				getAnalyticsWebClientPromise().then((client) => {
					const payload = {
						source: observationData.appName,
						action: 'complete',
						actionSubject: 'dnd operation',
						attributes,
					};

					client.getInstance().sendOperationalEvent(payload);
				});
			}
		}
	};

export const dndReporter = dndReporterWithSlo();
