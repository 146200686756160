import { useEffect } from 'react';
import type {
	MeasurementCache,
	MeasurementCacheEntry,
} from '@atlassian/jira-software-fast-virtual/src/services/use-virtual/index.tsx';

const CARD_HEIGHTS_CACHE = new Map<number, MeasurementCacheEntry>();

export const useCardHeightCache = (): MeasurementCache<number> =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	CARD_HEIGHTS_CACHE as MeasurementCache<number>;

export const useCardHeightCacheCleanup = () => {
	useEffect(
		() => () => {
			// Only run the cleanup if the FF is enabled.
			CARD_HEIGHTS_CACHE.clear();
		},
		[],
	);
};
