import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { getIssueTypesUrl } from '../../common/urls/index.tsx';
import { CAN_EDIT_BOARD } from '../../model/permission/permission-types.tsx';
import { getPermissionsSelector } from '../../state/selectors/board/board-permissions-selectors.tsx';
import {
	contextPathSelector,
	getIsCMPBoard,
	projectKeySelector,
} from '../../state/selectors/software/software-selectors.tsx';
import type { State } from '../../state/types.tsx';
import type { StateProps } from './types.tsx';
import MissingIssueTypeFlag from './view.tsx';

const mapStateToProps = (state: State): StateProps => {
	const permissions = getPermissionsSelector(state);
	return {
		contextPath: contextPathSelector(state),
		canEditBoard: permissions[CAN_EDIT_BOARD] === true && !getIsCMPBoard(state),
		wantedUrl: getIssueTypesUrl(projectKeySelector(state)),
	};
};

export default connect(mapStateToProps, {})(MissingIssueTypeFlag);
