import React, { type ComponentType } from 'react';
import { getDefaultPubSubHost } from '../../../common/utils/pubsub.utils.tsx';

export function withHost<Props>(
	WrappedComponent: ComponentType<
		Props & {
			host: string | undefined;
		}
	>,
): ComponentType<Props> {
	return (props: Props) => <WrappedComponent {...props} host={getDefaultPubSubHost()} />;
}

export default withHost;
