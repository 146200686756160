import { createIndexedDBStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/indexed-db/index.tsx';
import type { AsyncStorage } from '@atlassian/jira-browser-storage-providers/src/types.tsx';
import { setMark } from '@atlassian/jira-common-performance/src/marks.tsx';
import type { MobileRestApiResponse } from '@atlassian/jira-software-board-uif-types/src/index.tsx';
import { getAccountId, isValid } from '../common/index.tsx';
import type { BoardQueryParameters } from '../utils/types.tsx';

export const uifBoardCache: AsyncStorage = createIndexedDBStorageProvider('cmp-board');

export interface BoardCachedEntry {
	timestamp: number;
	// This is going to be obsolete.
	etag: string;
	data: MobileRestApiResponse;
}

export function buildBoardCachedEntryKey({
	boardId,
	accountId,
	queryParameters,
}: {
	boardId: number | string;
	accountId: string;
	queryParameters?: BoardQueryParameters;
}): string {
	const params: (string | number)[] = [accountId, boardId];

	if (queryParameters) {
		params.push(JSON.stringify(queryParameters));
	}

	const paramString = params.join('::');
	return `local-device-cache::${paramString}`;
}

interface BoardCachedEntryParams {
	boardId: number | string;
	queryParameters?: BoardQueryParameters;
}

export async function setBoardCachedEntry(
	{ boardId, queryParameters }: BoardCachedEntryParams,
	entry: BoardCachedEntry,
): Promise<void> {
	if (!entry.data.localDeviceCacheEnabled) {
		uifBoardCache.clearAll();
		return Promise.resolve();
	}

	const accountId = getAccountId() ?? 'no-account-id';
	const key = buildBoardCachedEntryKey({ boardId, accountId, queryParameters });

	return uifBoardCache.set(key, entry);
}

export async function getBoardCachedEntry({
	boardId,
	queryParameters,
}: BoardCachedEntryParams): Promise<BoardCachedEntry | null> {
	const accountId = getAccountId() ?? 'no-account-id';
	const cacheKey = buildBoardCachedEntryKey({ boardId, accountId, queryParameters });

	try {
		setMark('uif-board-fetch-read-cache-start');
		const entry = await uifBoardCache.get(cacheKey);
		if (entry) {
			setMark('uif-board-fetch-read-cache-end');
		} else {
			return null;
		}
		if (!isValid(entry)) {
			await uifBoardCache.remove(cacheKey);
			return null;
		}
		return entry;
	} catch (err) {
		return null;
	}
}
