import React from 'react';
import GlobalSearchTracker from '@atlassian/jira-software-global-search-tracker/src/index.tsx';

export type StateProps = {
	numIssues: number;
};

export type Props = StateProps;

export default function View({ numIssues }: Props) {
	return (
		<GlobalSearchTracker
			analyticsAttributes={{
				numIssues,
			}}
		/>
	);
}
