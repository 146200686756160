export class UseFetchHandledError extends Error {
	originalError: Error;

	componentMounted: boolean;

	constructor(componentMounted: boolean, e: Error) {
		super(e?.message || 'Unknown');

		this.originalError = e;

		this.componentMounted = !!componentMounted;
	}
}
