import React, { Component, type ReactNode } from 'react';
import get from 'lodash/get';
import { ApolloProvider } from '@apollo/react-hooks';
import type { ApolloClient } from 'apollo-client';
import { TenantContextSubscriber } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import type { ApolloClientConfig, CacheShape, ApolloClientId } from './common/types.tsx';

const CLIENTS_CACHE: {
	[key: string]: ApolloClient<CacheShape>;
} = {};

export const resetCache = (clientId: ApolloClientId) => {
	// we need to `clearStore` directly to the InMemoryCache instance
	CLIENTS_CACHE[clientId]?.clearStore();
	delete CLIENTS_CACHE[clientId];
};
/**
 * Serialise all Apollo caches to strings for rehydration
 */
export const getSerialisedCaches = (): {
	[key: string]: CacheShape;
} =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Object.keys(CLIENTS_CACHE).reduce<Record<string, any>>(
		(acc, key) =>
			Object.assign(acc, {
				[`apollo-cache/${key}`]: CLIENTS_CACHE[key].extract(),
			}),
		{},
	);

type Props = {
	client: ApolloClientConfig;
	children: ReactNode;
};

export const getClient = (
	baseUrl: string,
	{ clientId, clientCreator }: ApolloClientConfig,
	xsrfToken?: string,
): ApolloClient<CacheShape> => {
	let client = CLIENTS_CACHE[clientId];
	if (!client) {
		client = clientCreator(baseUrl, xsrfToken);
		CLIENTS_CACHE[clientId] = client;
	}
	return client;
};

// eslint-disable-next-line jira/react/no-class-components
export class ApolloClientProvider extends Component<Props> {
	getClient(baseUrl: string, xsrfToken?: string | null) {
		return getClient(baseUrl, this.props.client, xsrfToken != null ? xsrfToken : undefined);
	}

	render() {
		const { children } = this.props;

		if (!get(this.props, 'client.clientId')) {
			throw new Error('Please provide a client configuration');
		}

		return (
			<TenantContextSubscriber>
				{({ tenantContext }) => {
					const client = this.getClient(tenantContext.baseUrl, tenantContext.xsrfToken);

					return <ApolloProvider client={client}>{children}</ApolloProvider>;
				}}
			</TenantContextSubscriber>
		);
	}
}
