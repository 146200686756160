/**
 * This file is to define tags for GraphQL queries to be identified
 * by https://github.com/apollographql/eslint-plugin-graphql
 * It returns the full query string for template literals
 */
export const gqlTagRest = <T extends string | number>(
	literals: string[] | TemplateStringsArray,
	...substitutions: T[]
) => {
	let result = '';

	// run the loop only for the substitution count
	substitutions.forEach((key, i) => {
		result += literals[i];
		result += key.toString();
	});

	// add the last literal
	result += literals[literals.length - 1];

	return result;
};
