import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	buttonLabel: {
		id: 'software.main.header.menu.button-label',
		defaultMessage: 'More',
		description: 'Label for the overflow menu button',
	},
	dropdownAriaLabel: {
		id: 'software.main.header.menu.dropdown-aria-label',
		defaultMessage: 'Board',
		description: 'Message for screen readers',
	},
	iconButtonLabelOld: {
		id: 'software.main.header.menu.icon-button-label-old',
		defaultMessage: 'Board menu',
		description: 'Label for the overflow menu button and message for screen readers',
	},
	iconButtonLabel: {
		id: 'software.main.header.menu.icon-button-label',
		defaultMessage: 'More actions',
		description: 'Label for the overflow menu button and message for screen readers',
	},
	enterFullScreen: {
		id: 'software.main.header.menu.enter-full-screen',
		defaultMessage: 'Enter full screen',
		description: 'Tooltip content for enter full screen button',
	},
	exitFullScreen: {
		id: 'software.main.header.menu.exit-full-screen',
		defaultMessage: 'Exit full screen',
		description: 'Tooltip content for exit full screen button',
	},
});
