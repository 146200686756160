import { useCallback, useEffect, useRef, type RefObject } from 'react';

const DEFAULT_SCROLL_STOP_INTERVAL_MS = 500;

export type UseOnScrollParams = {
	onScrollStart: () => void;
	/**
	 * Called with the timestamp for the last scroll event. That timestamp represents the end
	 * of the scrolling interaction.
	 */
	onScrollEnd: (lastScrollTime: number) => void;
	scrollStopIntervalMs: number;
};

export type UseOnScrollResult = {
	onScroll: () => () => void;
	isScrollingRef: {
		current: boolean;
	};
};

/**
 * Returns a function that can be called whenever there's a scroll event.
 *
 * When this function is called, it'll update the current scrolling state to
 * true (if it was false) and schedule a 500ms timer to update the state to
 * false.
 *
 * If there're subsequent calls to the function in this delay, the false update
 * will be pushed back. This is a debounced tracker of the scrolling state.
 *
 * The function will call `onScrollStart` and `onScrollEnd` when the state
 * changes.
 */
export const useOnScroll = ({
	onScrollStart,
	onScrollEnd,
	scrollStopIntervalMs,
}: UseOnScrollParams): UseOnScrollResult => {
	const isScrollingRef = useRef<boolean>(false);
	const scrollStopTimerRef = useRef<unknown>(null);

	const onScroll = useCallback(() => {
		if (!isScrollingRef.current) {
			isScrollingRef.current = true;
			onScrollStart();
		}

		const lastScrollTime = performance.now();

		// @ts-expect-error - TS2769 - No overload matches this call.
		clearTimeout(scrollStopTimerRef.current);
		scrollStopTimerRef.current = setTimeout(() => {
			isScrollingRef.current = false;
			onScrollEnd(lastScrollTime);
		}, scrollStopIntervalMs);

		return () => {
			if (isScrollingRef.current) {
				onScrollEnd(lastScrollTime);
			}

			// @ts-expect-error - TS2769 - No overload matches this call.
			clearTimeout(scrollStopTimerRef.current);
		};
	}, [isScrollingRef, scrollStopTimerRef, scrollStopIntervalMs, onScrollStart, onScrollEnd]);

	return {
		onScroll,
		isScrollingRef,
	};
};

export type UseScrollStateObserverParams = {
	scrollStopIntervalMs?: number | null;
	scrollElRef?: RefObject<HTMLElement>;
	scrollEl?: HTMLElement | null;
	onScrollStart: () => void;
	onScrollEnd: (lastScrollTime: number) => void;
};

/**
 * Tracks scrolling state (true/false) on a certain 'scrollEl' element, fires
 * start/end callbacks when the state changes.
 *
 * @example
 *
 *     useScrollStateObserver({
 *         scrollStopIntervalMs: 1000,
 *         scrollElRef: { current: window },
 *         onScrollStart: () => console.log('scroll has started'),
 *         onScrollEnd: (lastScrollTime: number) => console.log('scroll has ended at', lastScrollTime),
 *     })
 *
 */
export const useScrollStateObserver = ({
	scrollStopIntervalMs,
	scrollElRef,
	scrollEl,
	onScrollStart,
	onScrollEnd,
}: UseScrollStateObserverParams) => {
	const { onScroll, isScrollingRef } = useOnScroll({
		onScrollStart,
		onScrollEnd,
		scrollStopIntervalMs: scrollStopIntervalMs ?? DEFAULT_SCROLL_STOP_INTERVAL_MS,
	});

	useEffect(() => {
		const el = scrollEl ?? scrollElRef?.current;

		if (!el) {
			return undefined;
		}

		el.addEventListener('scroll', onScroll);
		return () => {
			el.removeEventListener('scroll', onScroll);
		};
	}, [scrollEl, scrollElRef, isScrollingRef, onScroll, onScrollEnd]);
};
