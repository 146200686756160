import {
	createContainer,
	createActionsHook,
	createHook,
	createStore,
	createStateHook,
} from '@atlassian/react-sweet-state';
import type { ContextMenuContainerProps, MenuItem, State } from '../../common/types.tsx';
import { getCardSelectionAnalytics } from '../analytics/utils/index.tsx';
import { actions, getInitialState, type Actions } from './actions.tsx';
import { onContainerInit, onContainerUpdate } from './utils.tsx';

const ContextMenuStore = createStore<State, Actions>({
	name: 'software-context-menu',
	initialState: getInitialState(),
	actions,
});

export const useContextMenuStore = createHook(ContextMenuStore);

export const useContextMenuActions = createActionsHook(ContextMenuStore);

export const ContextMenuContainer = createContainer<State, Actions, ContextMenuContainerProps>(
	ContextMenuStore,
	{
		onInit: onContainerInit,
		onUpdate: onContainerUpdate,
	},
);

const getMenuItemOpen = (
	state: State,
	{ menuItem, scope }: { menuItem: MenuItem; scope: string },
): boolean | undefined => state.menus[scope]?.openPath?.startsWith(menuItem.id);

export const useMenuItemCheckOpen = createStateHook(ContextMenuStore, {
	selector: getMenuItemOpen,
});

const getIsFocusForced = (
	state: State,
	{ menuItem, scope }: { menuItem: MenuItem; scope: string },
): boolean => state.menus[scope]?.forcedFocusPath === menuItem.id;

export const useShouldForceFocus = createStateHook(ContextMenuStore, {
	selector: getIsFocusForced,
});

const getIsMenuOpen = (state: State, { scope }: { scope: string }) => state.openMenu === scope;

export const useIsMenuOpen = createStateHook(ContextMenuStore, {
	selector: getIsMenuOpen,
});

const getAnalyticsAttributes = (
	state: State,
	{ scope, cardId }: { scope: string; cardId: number | string },
) => {
	const isMenuOpen = getIsMenuOpen(state, { scope });

	const {
		analytics: { selectedCardIds },
	} = state;

	return getCardSelectionAnalytics(selectedCardIds, cardId, isMenuOpen);
};

export const useAnalyticsAttributes = createStateHook(ContextMenuStore, {
	selector: getAnalyticsAttributes,
});

const getAnalyticsMapping = (state: State) => state.analytics.analyticsMapping;

export const useAnalyticsMapping = createStateHook(ContextMenuStore, {
	selector: getAnalyticsMapping,
});

export const getIsAnyMenuOpen = (state: State) => !!state.openMenu;

export const useIsAnyMenuOpen = createStateHook(ContextMenuStore, {
	selector: getIsAnyMenuOpen,
});

const getIsPersistentFocus = (state: State) => !!state?.persistentFocus;

export const useIsPersistentFocus = createStateHook(ContextMenuStore, {
	selector: getIsPersistentFocus,
});
