export const BRANCH_PANEL_DATA_TYPE = 'branch';
export const BUILD_PANEL_DATA_TYPE = 'build';
export const COMMIT_PANEL_DATA_TYPE = 'repository';
export const REVIEW_PANEL_DATA_TYPE = 'review';
export const DEPLOYMENT_PANEL_DATA_TYPE = 'deployment-environment';
export const PULLREQUEST_PANEL_DATA_TYPE = 'pullrequest';
export const FEATURE_FLAGS_PANEL_DATA_TYPE = 'feature-flags';
export const REMOTE_LINKS_PANEL_DATA_TYPE = 'remote-links';

// Metrics constants
export const METRIC_KEY_DEV_DETAILS_TIME_TO_LOAD = 'jira.fe.issue.dev-details.time-to.load';

// Feedback collector constants for details-header
export const FEEDBACK_HEADER_COLLECTOR_LOCATION = 'development.details.details-header';

// Feedback collector constants for remote-links
export const FEEDBACK_COLLECTOR_LOCATION = 'development.details.remote-links';
