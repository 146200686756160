import { combineEpics } from 'redux-observable';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/ignoreElements';
import 'rxjs/add/operator/switchMap';
import get from 'lodash/get';
import { Observable } from 'rxjs/Observable';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { executeBacklogIssueMove } from '../../rest/board/backlog-issue-move-server.tsx';
import { dismissMigrationModal } from '../../rest/column-migration-graphql/index.tsx';
import {
	backlogIssueMoveShow,
	backlogIssueMoveExecute,
	backlogIssueMoveExecuteSuccess,
	backlogIssueMoveExecuteFailure,
	type BacklogIssueMoveExecuteAction,
	BACKLOG_ISSUE_MOVE_EXECUTE,
	BACKLOG_ISSUE_MOVE_CANCEL,
	BACKLOG_ISSUE_MOVE_CONFIRM,
} from '../../state/actions/board/backlog-issue-move/index.tsx';
import { WORK_DATA_SET, type WorkDataSetAction } from '../../state/actions/work/index.tsx';
import {
	getFirstColumnIssueIds,
	isOpenSelector,
} from '../../state/selectors/board/backlog-issue-move-selectors.tsx';
import {
	contextPathSelector,
	getIsCMPBoard,
	rapidViewIdSelector,
} from '../../state/selectors/software/software-selectors.tsx';
import { activeSprintsSelector } from '../../state/selectors/sprint/sprint-selectors.tsx';
import type { Action, ActionsObservable, MiddlewareAPI, State } from '../../state/types.tsx';

const shouldCheckForDialogOnRefresh = (action: WorkDataSetAction, state: State): boolean => {
	if (action?.payload?.boardConfig == null) {
		log.safeErrorWithoutCustomerData(
			'backlog.move.issue.no.board.config',
			'Board config is null on enabled Swag Extended feature flag',
			{
				error: JSON.stringify(action, Object.getOwnPropertyNames(action)),
			},
		);
		return false;
	}
	const { boardIssueListKey, requestColumnMigration } = action.payload.boardConfig;
	const canEdit =
		get(action, ['payload', 'boardPermissions', 'editBoardConfig'], false) && !getIsCMPBoard(state);
	const isIssueListBoard = Boolean(boardIssueListKey);
	const isActiveSprint = Boolean(activeSprintsSelector(state));
	const isAlreadyOpen = isOpenSelector(state);
	return (
		(requestColumnMigration || false) &&
		isIssueListBoard &&
		canEdit &&
		!isActiveSprint &&
		!isAlreadyOpen
	);
};

const checkDismissEpicOnRefresh = (action$: ActionsObservable, store: MiddlewareAPI) =>
	action$
		.ofType(WORK_DATA_SET)
		.filter((action: WorkDataSetAction) => shouldCheckForDialogOnRefresh(action, store.getState()))
		.switchMap(() => Observable.of(backlogIssueMoveShow()));

const setDismissEpic = (action$: ActionsObservable, store: MiddlewareAPI): Observable<Action> =>
	action$.ofType(BACKLOG_ISSUE_MOVE_CANCEL, BACKLOG_ISSUE_MOVE_CONFIRM).switchMap(() => {
		const state = store.getState();
		const contextPath = contextPathSelector(state);
		const rapidViewId = rapidViewIdSelector(state);
		return (
			dismissMigrationModal(contextPath, rapidViewId)
				.ignoreElements()
				// impossible:
				.map(() => ({ type: 'UNSUPPORTED_ACTION_TYPE' }))
		);
	});

const confirmEpic = (action$: ActionsObservable, store: MiddlewareAPI) =>
	action$
		.ofType(BACKLOG_ISSUE_MOVE_CONFIRM)
		.switchMap(() =>
			Observable.of(backlogIssueMoveExecute(getFirstColumnIssueIds(store.getState()))),
		);

const executeMoveEpic = (action$: ActionsObservable, store: MiddlewareAPI) =>
	action$.ofType(BACKLOG_ISSUE_MOVE_EXECUTE).mergeMap((action: BacklogIssueMoveExecuteAction) =>
		executeBacklogIssueMove(store.getState())
			.switchMap(() => Observable.of(backlogIssueMoveExecuteSuccess(action.meta.optimistic.id)))
			.catch((error: FetchError) => {
				log.safeErrorWithoutCustomerData(
					'backlog.move.issue.failure',
					'Failed to move issues to backlog',
					error,
				);
				return Observable.of(backlogIssueMoveExecuteFailure(action.meta.optimistic.id));
			}),
	);

export default combineEpics<Action, State>(
	checkDismissEpicOnRefresh,
	setDismissEpic,
	confirmEpic,
	executeMoveEpic,
);
