import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';

import { Observable } from 'rxjs/Observable';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fetchCardIssueTransitionsService } from '../../services/card-issue-transitions/index.tsx';
import { issueChildrenInFinalColumnService } from '../../services/issue/issue-children-in-final-column-service.tsx';
import { makeServiceContext } from '../../services/service-context.tsx';
import {
	type IssueChildrenInFinalColumnRequestAction,
	ISSUE_CHILDREN_IN_FINAL_COLUMN_REQUEST,
} from '../../state/actions/issue-children/index.tsx';
import { showUpdateIssueParentModal } from '../../state/actions/issue/update-issue-parent-modal/index.tsx';
import { transitionsByColumnIdSelector } from '../../state/selectors/card-transitions/card-transitions-selectors.tsx';
import { orderedColumnsSelector } from '../../state/selectors/column/column-selectors.tsx';
import {
	contextPathSelector,
	getIssues,
	getMissingParents,
	rapidViewIdSelector,
} from '../../state/selectors/software/software-selectors.tsx';
import type { Action, ActionsObservable, MiddlewareAPI } from '../../state/types.tsx';

export function issueChildrenInFinalColumnEpic(
	action$: ActionsObservable,
	store: MiddlewareAPI,
): Observable<Action> {
	return action$
		.ofType(ISSUE_CHILDREN_IN_FINAL_COLUMN_REQUEST)
		.mergeMap((action: IssueChildrenInFinalColumnRequestAction) => {
			const ctx = makeServiceContext(store.getState());

			return issueChildrenInFinalColumnService(ctx, action.payload.parentId)
				.flatMap(({ subtasksInFinalColumn }) => {
					if (subtasksInFinalColumn) {
						const { getState } = store;
						const state = getState();

						const issueParent = getIssues(state)[action.payload.parentId] ?? null;
						const missingParent = getMissingParents(state)[action.payload.parentId] ?? null;

						const parentIssue = issueParent || missingParent;

						if (!parentIssue) {
							return Observable.empty<never>();
						}

						const columns = orderedColumnsSelector(state);
						const lastColumn = columns[columns.length - 1];

						const transitions = transitionsByColumnIdSelector(state)(lastColumn.id, parentIssue);
						if (!transitions || transitions.length === 0) {
							return Observable.empty<never>();
						}

						const transitionsHaveConditions = transitions.some(
							(transition) => transition.hasConditions,
						);

						if (!transitionsHaveConditions) {
							return Observable.of(
								showUpdateIssueParentModal(action.payload.parentId, transitions),
							);
						}

						const contextPath = contextPathSelector(state);
						const boardId = rapidViewIdSelector(state);

						return fetchCardIssueTransitionsService(contextPath, boardId, parentIssue.id)
							.flatMap((issueTransitions) => {
								const lastColumnStatuses =
									'statuses' in lastColumn ? lastColumn.statuses.map((status) => status.id) : [];
								const validTransitions = issueTransitions.filter(
									(transition) =>
										transition.isAvailable &&
										lastColumnStatuses.includes(transition.destinationStatusId),
								);

								if (validTransitions.length > 0) {
									return Observable.of(
										showUpdateIssueParentModal(action.payload.parentId, validTransitions),
									);
								}

								return Observable.empty<never>();
							})
							.catch((err) => {
								log.safeErrorWithoutCustomerData(
									'issue.issue-children-in-final-column.failure',
									'Failed to check available transitions',
									err,
								);
								return Observable.empty<never>();
							});
					}

					return Observable.empty<never>();
				})
				.catch((err) => {
					log.safeErrorWithoutCustomerData(
						'issue.issue-children-in-final-column.failure',
						'Failed to check if all issue children are in final column',
						err,
					);
					return Observable.empty<never>();
				});
		});
}
