// eslint-disable-next-line jira/restricted/react
import React, { PureComponent } from 'react';
import debounce from 'lodash/debounce';
import { useBoardScrollLeft, useBoardScrollTop } from '../../controllers/board-scroll/index.tsx';

type Props = {
	scrollLeft: number;
	scrollTop: number;
	onScrollStart: () => void;
	onScrollStop: () => void;
};

const MAX_GAP_BETWEEN_SCROLL_EVENTS = 500;

// eslint-disable-next-line jira/react/no-class-components
class Notifier extends PureComponent<Props> {
	componentDidUpdate(prevProps: Props) {
		if (
			this.props.scrollTop === prevProps.scrollTop &&
			this.props.scrollLeft === prevProps.scrollLeft
		) {
			return;
		}
		if (!this.scrollStarted) {
			this.scrollStarted = true;
			this.props.onScrollStart();
		}

		this.scrollStop();
	}

	scrollStarted = false;

	scrollStop = debounce(() => {
		this.scrollStarted = false;
		this.props.onScrollStop();
	}, MAX_GAP_BETWEEN_SCROLL_EVENTS);

	render() {
		return null;
	}
}

type OwnProps = {
	onScrollStart: () => void;
	onScrollStop: () => void;
};

const BoardScrollNotifier = (props: OwnProps) => {
	const [scrollLeft] = useBoardScrollLeft();
	const [scrollTop] = useBoardScrollTop();

	return <Notifier scrollLeft={scrollLeft} scrollTop={scrollTop} {...props} />;
};

export default BoardScrollNotifier;
