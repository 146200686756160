import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { getColumnStatusMappingUrl } from '../../../../common/urls/index.tsx';
import {
	projectKeySelector,
	rapidViewIdSelector,
} from '../../../../state/selectors/software/software-selectors.tsx';
import type { State } from '../../../../state/types.tsx';
import ConfigureBoardItem from './view.tsx';

type StateProps = {
	url: string;
};

const mapStateToProps = (state: State): StateProps => ({
	url: getColumnStatusMappingUrl(projectKeySelector(state), rapidViewIdSelector(state)),
});

export default connect(mapStateToProps, {})(ConfigureBoardItem);
