import columnSetLimit from '../../../rest/column-set-limit-graphql/index.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	contextPath: string,
	boardId: string,
	columnId: number,
	limit: number | null,
	minimum: number | null,
) => columnSetLimit(contextPath, boardId, columnId, limit, minimum);
