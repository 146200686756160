import { useEffect } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { INSIGHTS_PACKAGE } from '@atlassian/jira-insights-common-constants/src/common/constants/package.tsx';
import { permissionStatus } from '@atlassian/jira-insights-common-constants/src/common/constants/permissions.tsx';
import { getBoardOrBacklogFromLocation } from '@atlassian/jira-insights-common-constants/src/services/location/index.tsx';
import { useFetch } from '@atlassian/jira-insights-fetch/src/controllers/use-fetch/main.tsx';
import { fetchInsightPermission } from '../../services/insight-permission/index.tsx';
import { useLocalStorage } from '../../services/use-local-storage/main.tsx';
import { INSIGHT_PERMISSION_ACTION_SUBJECT_ID } from './constants.tsx';
import type { CheckClassicViewAggregatedDataPermissionProp } from './types.tsx';

export const CheckClassicViewAggregatedDataPermission = ({
	boardId,
	userLocation,
	onPermissionLoaded,
	onInsightsEnabledWithoutPermission,
}: CheckClassicViewAggregatedDataPermissionProp) => {
	const eventFrom = getBoardOrBacklogFromLocation(userLocation);
	const actionSubject = `${INSIGHTS_PACKAGE}.${eventFrom}`;

	const [{ data: hasPermission, loading, fetched, aborted, hasError }] = useFetch(
		{
			onFetch: (options) => fetchInsightPermission({ boardId }, options),
			browserMetricsKey: `${actionSubject}.${INSIGHT_PERMISSION_ACTION_SUBJECT_ID}`,
			analyticsTracking: {
				actionSubject: `${actionSubject}.${INSIGHT_PERMISSION_ACTION_SUBJECT_ID}`,
				packageName: INSIGHTS_PACKAGE,
			},
			loggingKey: INSIGHT_PERMISSION_ACTION_SUBJECT_ID,
		},
		[],
	);

	const [_, setShouldUseJQLData] = useLocalStorage<boolean>(
		'jira-insights-should-use-jql',
		'shouldUseJQLQuery',
		{ defaultValue: false },
	);

	useEffect(() => {
		if (loading) {
			onPermissionLoaded(permissionStatus.LOADING);
		}

		if (fetched && !hasError && !aborted) {
			if (hasPermission) {
				onPermissionLoaded(permissionStatus.ALLOWED);
				setShouldUseJQLData(fg('insights-use_jql_query_for_enabled_users'));
			} else {
				onPermissionLoaded(permissionStatus.ALLOWED);
				onInsightsEnabledWithoutPermission?.();
				setShouldUseJQLData(true);
			}
		}

		if (hasError || aborted) {
			onPermissionLoaded(permissionStatus.DISALLOWED);
		}
	}, [
		fetched,
		loading,
		aborted,
		hasPermission,
		hasError,
		onPermissionLoaded,
		setShouldUseJQLData,
		onInsightsEnabledWithoutPermission,
	]);

	return null;
};

export const CheckSimplifiedDataPermission = () => {
	const [_, setShouldUseJQLData] = useLocalStorage<boolean>(
		'jira-insights-should-use-jql',
		'shouldUseJQLQuery',
		{ defaultValue: false },
	);

	useEffect(() => {
		setShouldUseJQLData(fg('insights-use_jql_query_for_enabled_users'));
	}, [setShouldUseJQLData]);

	return null;
};
