/**
 * Wrap resize observer for mocking on tests
 */
export class RemeasureObserver {
	resizeObserver: ResizeObserver | null = null;

	constructor(callback: () => void) {
		if (typeof ResizeObserver !== 'undefined') {
			this.resizeObserver = new ResizeObserver(callback);
		}
	}

	observe(element: HTMLElement) {
		this.resizeObserver?.observe(element);
	}

	unobserve(element: HTMLElement) {
		this.resizeObserver?.unobserve(element);
	}
}
