import { usePathParam } from '@atlassian/react-resource-router';

export const useIncrementsLink = () => {
	const [planId] = usePathParam('planId');
	const [scenarioId] = usePathParam('scenarioId');

	return `/jira/plans/${planId}/scenarios/${scenarioId}/program`;
};

export const usePlanPathParams = () => {
	const [planId] = usePathParam('planId');
	const [scenarioId] = usePathParam('scenarioId');
	const [incrementId] = usePathParam('incrementId');

	return {
		planId,
		scenarioId,
		incrementId,
	};
};
