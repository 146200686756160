import React, { type MutableRefObject, useRef } from 'react';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import { CAN_EDIT_ISSUE } from '../../model/permission/permission-types.tsx';
import { useBoardSelector } from '../../state/index.tsx';
import { getPermissionsSelector } from '../../state/selectors/board/board-permissions-selectors.tsx';
import { boardOrderedIssueIdsSelector } from '../../state/selectors/issue/board-issue-selectors.tsx';
import { getActiveIssueWithIcc } from '../../state/selectors/work/work-selectors.tsx';
import { useIsCMPBoard } from '../../state/state-hooks/capabilities/index.tsx';
import { type SyncSettings, FieldSync } from './field-sync/index.tsx';

const CMP_SYNC_SETTINGS: SyncSettings = {
	syncAssignee: true,
	syncEstimate: false,
	syncParent: false,
	syncSummary: false,
};

// This is used to sync states for the purpose of inline-editing
export const AllFieldsSync = () => {
	const isCMPBoard = useIsCMPBoard();
	const canEditCards = useBoardSelector(getPermissionsSelector)[CAN_EDIT_ISSUE] === true;
	const allIssueIds = useBoardSelector((state) => boardOrderedIssueIdsSelector(state));
	const activeCardId = useBoardSelector(getActiveIssueWithIcc);
	const richCards: MutableRefObject<Set<IssueId>> = useRef(new Set());

	if (!canEditCards) {
		return null;
	}

	if (activeCardId != null) {
		richCards.current?.add(activeCardId);
	}

	// to exclude deleted rich cards
	const ids = allIssueIds.filter((id) => richCards.current.has(id));

	/**
	 * For TMP all fields will be synced by default
	 * Note - CMP will abort above when jsw.uif.board.cmp-inline-editable-assignee is disabled
	 * */
	const syncSettings: SyncSettings | undefined = isCMPBoard ? CMP_SYNC_SETTINGS : undefined;

	return (
		<>
			{ids.map((id) => (
				<FieldSync key={id} issueId={id} {...syncSettings} />
			))}
		</>
	);
};
