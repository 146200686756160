import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	visibleIssueCount: {
		id: 'portfolio-3-plan-increment-common.ui.iteration-column-header.visible-issue-count',
		defaultMessage:
			'{visibleIssues} / {totalIssues} {totalIssues, plural, one {issue} other {issues}}',
		description: 'Number of visible issues in swimlane column',
	},
	totalIssueCount: {
		id: 'portfolio-3-plan-increment-common.ui.iteration-column-header.total-issue-count',
		defaultMessage: '{totalIssues} {totalIssues, plural, one {issue} other {issues}}',
		description: 'Number of total issues in swimlane column',
	},
	visibleIssueCountIssueTermRefresh: {
		id: 'portfolio-3-plan-increment-common.ui.iteration-column-header.visible-issue-count-issue-term-refresh',
		defaultMessage:
			'{visibleIssues} / {totalIssues} {totalIssues, plural, one {work item} other {work items}}',
		description: 'Number of visible issues in swimlane column',
	},
	totalIssueCountIssueTermRefresh: {
		id: 'portfolio-3-plan-increment-common.ui.iteration-column-header.total-issue-count-issue-term-refresh',
		defaultMessage: '{totalIssues} {totalIssues, plural, one {work item} other {work items}}',
		description: 'Number of total issues in swimlane column',
	},
});
