import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	scenarioChangeTooltip: {
		id: 'platform-card.card.scenario-change-tooltip',
		defaultMessage:
			'This change is only saved in your plan. Review this change, then save it in Jira.',
		description: 'Tooltip displayed when hovering over a card color in increment planning boards.',
	},
});
