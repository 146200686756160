import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import get from 'lodash/get';
import { Observable } from 'rxjs/Observable';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import columnRank from '../../../rest/column-rank-graphql/index.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (contextPath: string, boardId: string, columnId: number, position: number) =>
	columnRank(contextPath, boardId, columnId, position).catch((error) => {
		log.safeErrorWithoutCustomerData(
			'board.column.rank.graphql.failure',
			'Failed to rank column via GraphQl',
			{ message: get(error, ['message'], '') || get(error, [0, 'message'], '') },
		);
		return Observable.throw(error);
	});
