import {
	type CacheInvalidationType,
	BACKLOG,
	BOARD,
	ROADMAPS,
	UNKNOWN,
} from '../../../common/types.tsx';

/**
 * Tracks reasons for resources cache being invalidated.
 *
 * When a page is loaded, these reasons are read. The reasons are submitted to browser metrics.
 */
export class CacheInvalidationTracker {
	/**
	 * Holds reasons cache has been invalidated as a set of strings, for example:
	 *
	 * * BOARD:state.ISSUE_MOVE
	 * * BACKLOG:CREATE_SPRINT
	 *
	 * Reasons are kept separately for each page because they'll be cleared when the page is loaded.
	 *
	 * The reasoning can be exemplified by:
	 *
	 * * Visit board
	 * * Visit backlog
	 * * Visit roadmaps
	 * * Visit backlog - Load from cache
	 * * Make a BACKLOG:CARD_MOVE invalidation reason in backlog
	 *   * All 3 caches are now cleared
	 * * Visit board - reason is BACKLOG:CARD_MOVE - reasons are cleared;
	 * * Visit roadmaps - reason is BACKLOG:CARD_MOVE - reasons are cleared;
	 * * Visit backlog - reason is BACKLOG:CARD_MOVE - reasons are cleared;
	 * * Make a BACKLOG:SPRINT_COMPLETE invalidation reason in backlog
	 * * Visit board - reason is BACKLOG:SPRINT_COMPLETE **only**
	 */
	reasons: Record<CacheInvalidationType, Set<string>> = {
		[BOARD]: new Set<string>(),
		[BACKLOG]: new Set<string>(),
		[ROADMAPS]: new Set<string>(),
		[UNKNOWN]: new Set<string>(),
	};

	/**
	 * Pushes an invalidation reason to be tracked on the next cache-miss.
	 */
	addReason(origin: CacheInvalidationType, reason: string = UNKNOWN) {
		const reasonString = `${origin}:${reason}`;
		Object.keys(this.reasons).forEach((key) => {
			// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Partial<Record<CacheInvalidationType, Set<string>>>'.
			if (this.reasons[key].size >= 10) {
				return;
			}
			// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Partial<Record<CacheInvalidationType, Set<string>>>'.
			this.reasons[key].add(reasonString);
		});
	}

	/**
	 * Fetch invalidation reasons from the reasons set.
	 *
	 * If an experience does not read / clear reasons there's still only a bounded number of strings
	 * (10) that will be added into the Set; e.g. it's not a memory concern.
	 *
	 * The reasons are sorted.
	 */
	getAndClearReasons(origin: CacheInvalidationType): string[] {
		const reasonsSet = this.reasons[origin];
		const reasons = Array.from(reasonsSet);
		reasons.sort();
		reasonsSet.clear();
		return reasons;
	}
}
