import React from 'react';
import { styled as styled2 } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import type { CardLabelProps, ContainerProps } from './types.tsx';

export const CardLabel = (props: CardLabelProps) => {
	const { text, highlight } = props;
	const isSelected = !!highlight && highlight.includes(text);

	if (isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_2')) {
		return (
			<Box
				as="span"
				xcss={[ContainerStyles, ContainStylesExtra, isSelected && ContainerHighlightStyles]}
			>
				{text}
			</Box>
		);
	}

	if (isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_1')) {
		return (
			<Box as="span" xcss={[ContainerStyles, isSelected && ContainerHighlightStyles]}>
				<Box as="span" xcss={ContentStyles}>
					{text}
				</Box>
			</Box>
		);
	}

	return (
		<Container isSelected={isSelected}>
			<Content>{text}</Content>
		</Container>
	);
};

CardLabel.defaultProps = {
	highlight: [],
};

export default CardLabel;

const ContainerStyles = xcss({
	margin: 'space.050',
	cursor: 'default',
	font: 'font.body',
	overflow: 'hidden',
	borderRadius: '3px',
	border: '1px solid',
	borderColor: 'color.border',
});

// Combine 2 styles when clean up FG jira_nav4_beta_drop_2
const ContainStylesExtra = xcss({
	paddingBlock: 'space.025',
	paddingInline: 'space.050',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

const ContainerHighlightStyles = xcss({
	backgroundColor: 'color.background.neutral',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled2.span<ContainerProps>({
	display: 'inline-flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 2.5}px`,
	margin: token('space.050', '4px'),
	padding: 0,
	position: 'relative',
	cursor: 'default',
	lineHeight: 1,
	overflow: 'hidden',
	borderRadius: '3px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: (props: ContainerProps) =>
		props.isSelected
			? token('color.background.accent.blue.subtler', '#B3D4FF')
			: token('color.background.neutral', '#F4F5F7'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: (props) =>
		props.isSelected ? token('color.text.accent.blue', '#0747A6') : token('color.text', '#172B4D'),
});

const ContentStyles = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${gridSize * 22.5}px`,
	paddingBlock: 'space.025',
	paddingInline: 'space.050',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Content = styled2.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${gridSize * 22.5}px`,
	padding: `${token('space.025', '2px')} ${token('space.050', '4px')}`,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});
