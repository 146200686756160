import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	label: {
		defaultMessage: 'Create issue',
		id: 'software.main.board-container.board.swimlane.increment-planning-buttons.create-unscheduled-issue-button.label',
		description: 'Create issue button label',
	},
	labelIssueTermRefresh: {
		defaultMessage: 'Create work item',
		id: 'software.main.board-container.board.swimlane.increment-planning-buttons.create-unscheduled-issue-button.label-issue-term-refresh',
		description: 'Create issue button label',
	},
});
