import type { canInviteOthersResponse } from '../../../model/can-user-invite/can-user-invite-types.tsx';

export const SET_CAN_USER_INVITE = 'state.actions.can-user-invite.SET_CAN_USER_INVITE' as const;

export type SetCanUserInviteAction = {
	type: typeof SET_CAN_USER_INVITE;
	payload: canInviteOthersResponse;
};

export const setCanUserInvite = (payload: canInviteOthersResponse): SetCanUserInviteAction => ({
	type: SET_CAN_USER_INVITE,
	payload,
});

export type Action = SetCanUserInviteAction;
