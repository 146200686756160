export const BRANCH = 'branch' as const;
export const COMMIT = 'commit' as const;
export const PULLREQUEST = 'pullrequest' as const;
export const DECLINED = 'declined' as const;
export const MERGED = 'merged' as const;
export const DEPLOYMENT = 'deployment' as const;
export const DESIGN = 'design' as const;

export const SUPPORTED_DEV_STATUSES = [
	BRANCH,
	COMMIT,
	PULLREQUEST,
	DECLINED,
	MERGED,
	DEPLOYMENT,
	DESIGN,
] as const;
