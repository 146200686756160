import React, { type ReactElement, type ReactNode } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import type { ParentSwitcherField as ParentSwitcherFieldType } from './main.tsx';

type ParentSwitcherBoundaryProps = {
	children: ReactNode;
	fallback: ReactElement | null;
	packageName: string;
	onError?: () => void;
};

export const ParentSwitcherBoundary = ({
	children,
	fallback,
	packageName,
	onError,
}: ParentSwitcherBoundaryProps) => (
	<JSErrorBoundary
		id="issue-parent-switcher"
		packageName={packageName}
		fallback={fallback ? () => fallback : undefined}
		onError={onError}
	>
		<Placeholder name="issue-parent-switcher-boundary" fallback={fallback}>
			{children}
		</Placeholder>
	</JSErrorBoundary>
);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const AsyncLazyParentSwitcherField = lazy<typeof ParentSwitcherFieldType>(() =>
	import(/* webpackChunkName: "async-issue-field-parent-switcher" */ './main').then(
		({ ParentSwitcherField }) => ParentSwitcherField,
	),
);
