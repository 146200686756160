import React, { type ReactNode } from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled, { keyframes } from 'styled-components';
import { zIndex } from '../../../common/constants/styles/index.tsx';

type Props = {
	children: ReactNode;
	onAnimationEnd: () => void;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ onAnimationEnd, children }: Props) => (
	<RippleContainer onAnimationEnd={onAnimationEnd}>{children}</RippleContainer>
);

const RippleAnimation = keyframes({
	from: {
		boxShadow: '0 0 0 0 #14892c',
	},
	to: {
		boxShadow: '0 0 0 15px rgba(0, 0, 0, 0)',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RippleContainer = styled.div({
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.cardDoneRipple ? zIndex.cardDoneRipple : 'auto',
	borderRadius: '3px',
	animationName: RippleAnimation,
	animationDuration: '1.45s',
	animationIterationCount: 1,
	animationTimingFunction: 'cubic-bezier(0.14, 1.17, 0.95, 0.99)',
});
