import type { Action } from '@atlassian/react-sweet-state';
import type { State } from '../../types.tsx';

export const closeICC =
	(): Action<State> =>
	({ setState }) => {
		setState({
			keepIccOpen: false,
		});
	};
