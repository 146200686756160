import type { Observable as ObservableType } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import type { DeploymentDetails } from '@atlassian/jira-development-board-dev-info-icon/src/types.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import type { DevStatusMap, DevStatusActivity } from '../../../model/issue/issue-types.tsx';
import {
	type RestDevStatusResponse,
	fetchDevStatusFromSwag,
	fetchDevStatusFromRest,
} from '../../../rest/issue/dev-status/index.tsx';
import type { Response } from '../../../rest/issue/dev-status/types.tsx';
import type { ServiceContext } from '../../service-context.tsx';

export function transformActivity(gqlActivity: string): DevStatusActivity | null {
	switch (gqlActivity) {
		case 'PR_OPEN':
			return 'pullrequest';
		case 'PR_MERGED':
			return 'merged';
		case 'PR_DECLINED':
			return 'declined';
		case 'BRANCH_OPEN':
			return 'branch';
		case 'COMMIT':
			return 'commit';
		case 'DEPLOYMENT':
			return 'deployment';
		case 'DESIGN':
			return 'design';
		default:
			return null;
	}
}

export function transformSwagResponse(response: Response): DevStatusMap {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const devStatusMap: Record<string, any> = {};

	response.agile.board.columns.forEach((column) => {
		column.cards.forEach((card) => {
			const activity = card.devStatus && transformActivity(card.devStatus.activity);

			// skip when activity is unknown as we would not be able to show it anyway
			if (card.devStatus && activity) {
				const deploymentDetails =
					card.devStatus.properties?.state && card.devStatus.properties?.environment?.type
						? {
								deploymentDetails: {
									deploymentState: card.devStatus.properties?.state,
									deploymentEnvironment: card.devStatus.properties?.environment?.type,
								},
							}
						: {};
				devStatusMap[String(card.id)] = {
					activity,
					count: card.devStatus.count,
					...deploymentDetails,
				};
			}
		});
	});

	return devStatusMap;
}

export function transformRestResponse(response: RestDevStatusResponse): DevStatusMap {
	const devStatusMap: DevStatusMap = {};

	Object.entries(response.devOpsActivityForIssues).forEach(([issueIdStr, activity]) => {
		const issueId = Number(issueIdStr);
		const activityTag = transformActivity(activity.devOpsInfoActivityState);

		if (activityTag == null) {
			return;
		}

		const deploymentDetails =
			activity.properties?.state && activity.properties?.environment?.type
				? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					({
						deploymentDetails: {
							deploymentState: activity.properties.state,
							deploymentEnvironment: activity.properties.environment.type,
						},
					} as DeploymentDetails)
				: {};

		devStatusMap[issueId] = {
			count: activity.count,
			activity: activityTag,
			...deploymentDetails,
		};
	});

	return devStatusMap;
}

export function devStatusService(
	ctx: ServiceContext,
	{ issueIds }: { issueIds: IssueId[] },
): ObservableType<DevStatusMap> {
	if (ctx.isCMPBoard) {
		return fetchDevStatusFromRest({
			boardId: ctx.boardId,
			issueIds,
		}).map((response) => transformRestResponse(response));
	}

	return fetchDevStatusFromSwag(ctx.contextPath, ctx.boardId).map((resp) =>
		transformSwagResponse(resp),
	);
}
