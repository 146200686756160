import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	label: {
		id: 'software-filters.filters.status-category.label',
		defaultMessage: 'Status category',
		description:
			'The label of a dropdown menu button used to choose status category to filter issues by.',
	},
});
