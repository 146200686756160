import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	missingIssueTypeTitle: {
		id: 'software.main.flags.missing-issue-type.missing-issue-type-title',
		defaultMessage: "Issue types in this board and project don't match",
		description:
			'Title of flag shown when board has a card of issue type that does not exist in project settings',
	},
	missingIssueTypeDescription: {
		id: 'software.main.flags.missing-issue-type.missing-issue-type-description',
		defaultMessage:
			"The <b>{missingIssueTypeName}</b> issue type is used on this board, but isn't configured for this project.",
		description:
			'Flag content for when board has a card of issue type that does not exist in project settings',
	},
	defaultMissingIssueTypeDescription: {
		id: 'software.main.flags.missing-issue-type.default-missing-issue-type-description',
		defaultMessage:
			"The issue type <b>{missingIssueTypeName}</b> is used on this board, but isn't configured for this project.",
		description:
			'Flag content for when board has a card of issue type that does not exist in project settings',
	},
	adminIssueTypeActionLink: {
		id: 'software.main.flags.missing-issue-type.admin-issue-type-action-link',
		defaultMessage: 'Manage issue types',
		description: 'Flag action link going to Project Issue Type Settings shown to Admin user',
	},
	missingIssueTypeTitleIssueTermRefresh: {
		id: 'software.main.flags.missing-issue-type.missing-issue-type-title-issue-term-refresh',
		defaultMessage: "Work types in this board and project don't match",
		description:
			'Title of flag shown when board has a card of issue type that does not exist in project settings',
	},
	missingIssueTypeDescriptionIssueTermRefresh: {
		id: 'software.main.flags.missing-issue-type.missing-issue-type-description-issue-term-refresh',
		defaultMessage:
			"The <b>{missingIssueTypeName}</b> work type is used on this board, but isn't configured for this project.",
		description:
			'Flag content for when board has a card of issue type that does not exist in project settings',
	},
	defaultMissingIssueTypeDescriptionIssueTermRefresh: {
		id: 'software.main.flags.missing-issue-type.default-missing-issue-type-description-issue-term-refresh',
		defaultMessage:
			"The work type <b>{missingIssueTypeName}</b> is used on this board, but isn't configured for this project.",
		description:
			'Flag content for when board has a card of issue type that does not exist in project settings',
	},
	adminIssueTypeActionLinkIssueTermRefresh: {
		id: 'software.main.flags.missing-issue-type.admin-issue-type-action-link-issue-term-refresh',
		defaultMessage: 'Manage work types',
		description: 'Flag action link going to Project Issue Type Settings shown to Admin user',
	},
});
