import React, { useMemo, Fragment } from 'react';
import {
	getMalformedMessage,
	logMalformedMessageError,
	MalformedMessageError,
} from '../services/error/index.tsx';
import { getTags, isAnyTag, findTag, isCloseTagDelimiter } from '../services/tags/index.tsx';
import type { FormattedI18nMessageProps } from './types.tsx';

/**
 * @deprecated use import { FormattedMessage } from '@atlassian/jira-intl'; instead
 *
 * See here for documentation and examples: https://formatjs.io/docs/react-intl/components
 *
 * This component is responsible for error NotFoundError: Failed to execute 'insertBefore' on 'Node'
 * so if you're seeing it in Sentry and you are using this component, please migrate.
 * See here for details and how to reproduce: https://hello.atlassian.net/wiki/spaces/MOCA/pages/4228224965/MCRTB2-952+NotFoundError+Failed+to+execute+insertBefore+on+Node
 */
export const FormattedI18nMessage = (props: FormattedI18nMessageProps) => {
	const { message, packageName, componentsMapping } = props;

	const messageParts = useMemo(() => {
		const { tags, openTags, closeTags } = getTags(componentsMapping);

		try {
			const parts = [];
			let token = '';
			let TagComponent;
			let closeTagLookup;

			for (let i = 0; i < message.length; i += 1) {
				const letter = message[i];
				token += letter;

				if (isCloseTagDelimiter(letter)) {
					const firstTagFound = findTag(token, { tags: openTags });
					if (firstTagFound !== null && firstTagFound !== undefined) {
						// if we have a tag already means it is a malformed message since it is 2 subsequent open tags
						if (TagComponent) {
							throw new MalformedMessageError();
						}

						const text = token.replace(firstTagFound, '');
						parts.push(text);

						// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
						const tagInfo = tags[firstTagFound];
						TagComponent = tagInfo.component;
						closeTagLookup = tagInfo.close;

						token = '';
					}
				}

				if (
					closeTagLookup !== null &&
					closeTagLookup !== undefined &&
					isCloseTagDelimiter(letter) &&
					isAnyTag(token, { tags: [closeTagLookup] })
				) {
					// this mean that we do not have an open tag for this closing tag
					if (!TagComponent) {
						throw new MalformedMessageError();
					}

					const text = token.replace(closeTagLookup, '');
					parts.push(<TagComponent>{text}</TagComponent>);

					TagComponent = undefined;
					token = '';
					closeTagLookup = undefined;
				}

				if (isCloseTagDelimiter(letter) && isAnyTag(token, { tags: closeTags })) {
					// if we do not have an open tag mean we are closing a tag that is not open
					if (!TagComponent) {
						throw new MalformedMessageError();
					}
				}
			}

			// if we reached the end and we have an open tag, mean this message is malformed
			if (TagComponent || closeTagLookup) {
				throw new MalformedMessageError();
			}

			// flush anything we keep in the current token
			if (token.length > 0) {
				parts.push(token);
			}

			return parts;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			if (e instanceof MalformedMessageError) {
				logMalformedMessageError({ message, packageName });
				return [getMalformedMessage({ message, closeTags, openTags })];
			}
			throw e;
		}
	}, [message, packageName, componentsMapping]);

	return (
		<>
			{messageParts.map((item, index) => (
				<Fragment key={index}>{item}</Fragment>
			))}
		</>
	);
};
