export const renameBoardSuccess = (eventPrefix: string) => () => ({
	name: `${eventPrefix}.rename-board.success`,
	data: {},
});

export const renameBoardFailure = (eventPrefix: string) => () => ({
	name: `${eventPrefix}.rename-board.failure`,
	data: {},
});

export const renameBoardRequest = (eventPrefix: string) => () => ({
	name: `${eventPrefix}.rename-board.request`,
	data: {},
});
