import { entityArrayToMap } from '../../../../services/software/software-data-transformer.tsx';
import { WORK_DATA_SET } from '../../../actions/work/index.tsx';
import type { Action } from '../../../types.tsx';
import type { StatusesState } from './types.tsx';

export const statusesReducer = (state: StatusesState = {}, action: Action): StatusesState => {
	if (action.type === WORK_DATA_SET) {
		const {
			payload: { statuses },
		} = action;
		return entityArrayToMap(statuses);
	}
	return state;
};
