import { useEffect } from 'react';
import { createHook, createStore } from '@atlassian/react-sweet-state';
import type { BoardToolSection, BoardToolWebItem, SprintActionsType } from '../common/types.tsx';
import { useConnectPluginSections } from '../common/ui/tool-sections/index.tsx';
import type { FetchCMPSprintActionsParams } from '../services/load-extension-data/index.tsx';
import type { BoardMenuItemLocation } from '../ui/board-connect-menu-items/types.tsx';
import { findSection } from '../ui/board-connect-menu-items/utils.tsx';
import * as actions from './actions.tsx';
import type { State } from './types.tsx';

type ToolSectionProps = {
	boardId: string;
	projectKey: string;
	mode?: 'work' | 'plan';
};

type HasToolSectionsProps = ToolSectionProps & {
	itemLocations?: BoardMenuItemLocation[];
};

export type Actions = typeof actions;

export const Store = createStore<State, Actions>({
	name: '@atlassian/jira-software-connect-toolbar',
	initialState: { toolSections: null, key: null, isLoading: false, sprintActions: null },
	actions,
});

export const useToolSections = createHook(Store, {
	selector: (state): (BoardToolSection | BoardToolWebItem)[] | null => state.toolSections,
});

export function useFetchedToolSections({ boardId, projectKey, mode = 'work' }: ToolSectionProps) {
	const [toolSections, { fetchExtensionData }] = useToolSections();
	useEffect(() => {
		fetchExtensionData({ boardId, projectKey, mode });
	}, [boardId, fetchExtensionData, mode, projectKey]);
	return toolSections;
}

export function useHasToolSections({
	boardId,
	projectKey,
	mode = 'work',
	itemLocations = [],
}: HasToolSectionsProps) {
	const toolSections = useFetchedToolSections({ boardId, projectKey, mode });

	const relevantSections = useConnectPluginSections(toolSections ?? []);
	const hasRelevantSections = relevantSections ? relevantSections.length > 0 : false;

	const hasTargetSections = itemLocations.some(
		(location) =>
			((toolSections &&
				findSection(toolSections, location)?.subItems?.filter((item) => item.id != null).length) ??
				0) > 0,
	);

	return hasTargetSections || hasRelevantSections;
}

export const useSprintActions = createHook(Store, {
	selector: (state): SprintActionsType => state.sprintActions,
});

export const useFetchedSprintActions = ({ sprintId }: FetchCMPSprintActionsParams) => {
	const [sprintActions, { fetchSprintActionsData }] = useSprintActions();
	useEffect(() => {
		fetchSprintActionsData({ sprintId });
	}, [fetchSprintActionsData, sprintId]);
	return sprintActions;
};
