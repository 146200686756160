import type { BoardType as SharedBoardType } from '@atlassian/jira-shared-types/src/rest/jira/board.tsx';
import type { BoardType } from '../../../model/board/board-types.tsx';
import { CMP_KANBAN_BOARD_TYPE, CMP_SCRUM_BOARD_TYPE } from '../../constants.tsx';

export const getBoardTypeForREST = (boardType: BoardType): SharedBoardType => {
	switch (boardType) {
		case CMP_KANBAN_BOARD_TYPE:
			return 'kanban';
		case CMP_SCRUM_BOARD_TYPE:
			return 'scrum';
		default:
			return 'simple';
	}
};
