// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentProps } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { LazySuspense, lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import { RELEASES_PACKAGE_NAME } from '../common/constants.tsx';
import type ReleaseVersionModalType from './index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const ReleaseVersionModal = lazyAfterPaint<typeof ReleaseVersionModalType>(
	() => import(/* webpackChunkName: "async-jira-version-modals-release-version-modal" */ './index'),
	{ ssr: false },
);
const AsyncReleaseVersionModal = (
	props: JSX.LibraryManagedAttributes<
		typeof ReleaseVersionModalType,
		ComponentProps<typeof ReleaseVersionModalType>
	>,
) => (
	<JSErrorBoundary
		id="async-jira-version-modals-release-version-modal"
		packageName={RELEASES_PACKAGE_NAME}
		teamName="fusion-solaris"
		fallback="flag"
	>
		<LazySuspense fallback={null}>
			<ReleaseVersionModal {...props} />
		</LazySuspense>
	</JSErrorBoundary>
);

export default AsyncReleaseVersionModal;
