import React from 'react';
import { styled } from '@compiled/react';
import CheckIcon from '@atlaskit/icon/core/check-mark';
import CheckIconOld from '@atlaskit/icon/glyph/check';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages.tsx';

const DoneColumnTick = () => {
	const intl = useIntl();
	return (
		<Container
			data-test-id="platform-board-kit.common.ui.column-header.editable-title.done-column-tick.done-column-tick"
			data-testid="platform-board-kit.common.ui.column-header.editable-title.done-column-tick.done-column-tick"
		>
			<Tooltip
				content={intl.formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.doneTickTooltipIssueTermRefresh
						: messages.doneTickTooltip,
				)}
			>
				<CheckIcon
					label=""
					color={token('color.icon.success', colors.G400)}
					LEGACY_fallbackIcon={CheckIconOld}
					LEGACY_primaryColor={token('color.icon.success', colors.G400)}
					LEGACY_size="small"
				/>
			</Tooltip>
		</Container>
	);
};

export default DoneColumnTick;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	lineHeight: 1,
	paddingLeft: token('space.050', '4px'),
});
