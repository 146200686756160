import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	menuButtonTooltip: {
		id: 'automation-menu.button.menu-button-tooltip',
		defaultMessage: 'Automation',
		description:
			'Clicking the button associated with this tooltip will display a dropdown menu containing links to the Automation configuration page',
	},
	automationsMenuButtonIcon: {
		id: 'automation-menu.button.automations-menu-button-icon',
		defaultMessage: 'automations-menu-button-icon',
		description: 'Label for automations mennu button icon',
	},
});
