/* eslint-disable @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */
/** @jsx jsx */
import React, { useCallback, useState } from 'react';
import { styled, jsx } from '@compiled/react';
import { IconButton } from '@atlaskit/button/new';
import UnlinkIcon from '@atlaskit/icon/core/migration/link-broken--unlink';
import Lozenge from '@atlaskit/lozenge';
import { Text, Flex, Inline, xcss, Box, Anchor } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { toAppearance } from '@atlassian/jira-business-approvals-common/src/utils.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { IssueType } from '@atlassian/jira-issue-line-card-issue-type/src/index.tsx';
import { CardDueDate } from '@atlassian/jira-platform-card/src/common/ui/due-date/index.tsx';
import type { AnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import {
	ISSUE_DUE_DATE_LOZENGE_TESTID,
	ISSUE_LINK_TESTID,
	ISSUE_STATUS_LOZENGE_TESTID,
	LINKED_ISSUE_CARD_CONTENT_TESTID,
	UNLINK_ISSUE_BUTTON_TESTID,
} from '../../common/constants.tsx';
import type { Props } from './types.tsx';

const unlinkButtonSelector = 'issue-links-details-content-unlink-button';

export const IssueLinksDetailsContent = ({
	issueLink,
	onIssueClicked,
	onUnlinkIssue,
	unlinkIssueButtonTooltipText,
	showBlockedTime,
	canUserLinkIssue,
}: Props) => {
	const onIssueClickedCallback = useCallback(
		(_: React.MouseEvent<HTMLElement, MouseEvent>, analyticsEvent: AnalyticsEvent) => {
			onIssueClicked?.(analyticsEvent);
		},
		[onIssueClicked],
	);

	const [isIssueFocused, setIsIssueFocused] = useState(false);

	return (
		<IssueDetailsRow key={issueLink.key}>
			<Inline
				alignBlock="center"
				space="space.050"
				xcss={componentStyles}
				testId={LINKED_ISSUE_CARD_CONTENT_TESTID}
			>
				<IssueType
					issueTypeName={issueLink.issueTypeName}
					issueTypeIconUrl={issueLink.issueTypeIconUrl}
					isDraggable={false}
					isActionVisible={false}
					restrictedReorderingMessage={issueLink.issueTypeName}
				/>
				<Box xcss={issueContainerStyles}>
					<Anchor
						xcss={anchorStyles}
						// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
						href={`${window.location.protocol}//${window.location.hostname}/browse/${issueLink.key}`}
						target="_blank"
						testId={ISSUE_LINK_TESTID}
						onClick={onIssueClickedCallback}
						onFocus={() => setIsIssueFocused(true)}
						onBlur={() => setIsIssueFocused(false)}
					>
						<Inline space="space.050" alignBlock="center">
							<Tooltip content={issueLink.key}>
								{(tooltipProps) => {
									return fg('dependency_visualisation_program_board_fe_and_be') ? (
										<IssueKey {...tooltipProps}>{issueLink.key}</IssueKey>
									) : (
										<IssueKeyOld {...tooltipProps}>{issueLink.key}</IssueKeyOld>
									);
								}}
							</Tooltip>
							<Tooltip content={issueLink.text}>
								{(tooltipProps) => <IssueText {...tooltipProps}>{issueLink.text}</IssueText>}
							</Tooltip>
						</Inline>
					</Anchor>
				</Box>
				{issueLink.dueDate ? (
					<Flex
						testId={ISSUE_DUE_DATE_LOZENGE_TESTID}
						justifyContent="end"
						gap={issueLink.offtrackLabel ? 'space.100' : undefined}
						xcss={dueDateStyles}
						alignItems={issueLink.offtrackLabel ? 'center' : undefined}
					>
						{issueLink.offtrackLabel && (
							<Text color="color.text.danger"> {issueLink.offtrackLabel} </Text>
						)}
						<CardDueDate dueDate={issueLink.dueDate} />
					</Flex>
				) : null}
				<Flex
					testId={ISSUE_STATUS_LOZENGE_TESTID}
					justifyContent="end"
					gap={issueLink.offtrackLabel ? 'space.100' : undefined}
					alignItems={issueLink.offtrackLabel ? 'center' : undefined}
					xcss={lozengeWrapperStyles}
				>
					{!issueLink.dueDate && issueLink.offtrackLabel && (
						<Text color="color.text.danger"> {issueLink.offtrackLabel}</Text>
					)}
					<Tooltip content={issueLink.statusName}>
						{(tooltipProps) => (
							<Lozenge appearance={toAppearance(issueLink.statusCategoryId)}>
								<span {...tooltipProps}>{issueLink.statusName}</span>
							</Lozenge>
						)}
					</Tooltip>
				</Flex>
				{showBlockedTime && <BlockedTimeText>{issueLink.blockedTime}</BlockedTimeText>}
				{canUserLinkIssue && (
					<Box
						xcss={[
							unlinkButtonContainerStyles,
							isIssueFocused && unlinkButtonContainerForceFocusStyles,
						]}
					>
						<Tooltip content={unlinkIssueButtonTooltipText}>
							<IconButton
								data-styled-selector={unlinkButtonSelector}
								testId={UNLINK_ISSUE_BUTTON_TESTID}
								icon={UnlinkIcon}
								label={unlinkIssueButtonTooltipText}
								onClick={(event) => onUnlinkIssue(issueLink, event)}
								appearance="subtle"
								spacing="compact"
							/>
						</Tooltip>
					</Box>
				)}
			</Inline>
		</IssueDetailsRow>
	);
};

const dueDateStyles = xcss({
	flexShrink: 0,
});

const lozengeWrapperStyles = xcss({
	flexShrink: 0,
	flexGrow: 1,
});

const componentStyles = xcss({
	color: 'color.text',
	height: 'size.300',
	paddingRight: 'space.050',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const BlockedTimeText = styled.p({
	marginTop: 0,
	font: token('font.body.small'),
	width: 70,
	flexShrink: 0,
});

const issueContainerStyles = xcss({
	width: '100%',
	minWidth: '0%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const IssueText = styled.p({
	color: token('color.text', colors.N200),
	fontWeight: token('font.weight.regular'),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	textAlign: 'left',
	marginTop: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueKey = styled.p({
	font: token('font.body.UNSAFE_small', fontFallback.body.UNSAFE_small),
	fontWeight: token('font.weight.medium'),
	textDecoration: 'none',
	whiteSpace: 'nowrap',
	width: 72,
	flexShrink: 0,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	textAlign: 'left',
	direction: 'rtl',
	color: token('color.link', colors.B400),
	paddingTop: token('space.025', '2px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueKeyOld = styled.p({
	font: token('font.body.UNSAFE_small', fontFallback.body.UNSAFE_small),
	fontWeight: token('font.weight.medium'),
	textDecoration: 'none',
	whiteSpace: 'nowrap',
	width: 56,
	flexShrink: 0,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	textAlign: 'left',
	direction: 'rtl',
	color: token('color.link', colors.B400),
	paddingTop: token('space.025', '2px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @typescript-eslint/consistent-type-assertions, @atlaskit/design-system/consistent-css-prop-usage
const unlinkButtonContainerStyles = xcss({
	button: {
		opacity: 0,

		':focus': {
			opacity: 1,
		},
	},
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @typescript-eslint/consistent-type-assertions, @atlaskit/design-system/consistent-css-prop-usage
const unlinkButtonContainerForceFocusStyles = xcss({
	button: {
		opacity: 1,
	},
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueDetailsRow = styled.div({
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		[`[data-styled-selector="${unlinkButtonSelector}"]`]: {
			opacity: 1,
		},
	},
});

const anchorStyles = xcss({
	textDecoration: 'none',

	':hover': {
		textDecoration: 'underline',
	},
});
