import type { Person } from '@atlassian/jira-filters/src/common/types.tsx';
import type { CollabParticipant } from '@atlassian/jira-real-time-presence/src/types.tsx';
import { PRESENCE_OFFLINE, PRESENCE_ONLINE, UNASSIGNED_USER_ID } from '../../common/constants.tsx';

export const getManageCustomFiltersUrl = (
	projectKey: string,
	boardId: string,
	isCompanyManaged: boolean,
	isUserBoard: boolean,
	isJSMBoard?: boolean,
	// For testing purposes

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	pathname = window.location.pathname,
): string => {
	if (isJSMBoard) {
		return `/jira/servicedesk/projects/${projectKey}/boards/${boardId}?config=quickFilters`;
	}

	if (isCompanyManaged) {
		if (isUserBoard) {
			return `${pathname}?config=quickFilters`;
		}

		return `/jira/software/c/projects/${projectKey}/boards/${boardId}${
			pathname.endsWith('backlog') ? '/backlog' : ''
		}?config=quickFilters`;
	}

	return `/jira/software/projects/${projectKey}/settings/boards/${boardId}/custom-filters`;
};

export const getAssigneesWithPresence = (
	allAssignees: Person[],
	participants: CollabParticipant[],
): Person[] =>
	allAssignees
		.map((assignee) => {
			if (assignee.id === UNASSIGNED_USER_ID) return assignee;
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return {
				...assignee,
				presence: participants.some((participant) => participant.clientId === assignee.id)
					? PRESENCE_ONLINE
					: PRESENCE_OFFLINE,
			} as Person;
		})
		.sort((a, b) => {
			// sort by presence & keep current user as the first
			if (a.id === allAssignees[0].id) return -1;
			if (b.id === allAssignees[0].id) return 1;
			if (a.presence === PRESENCE_ONLINE) return -1;
			if (b.presence === PRESENCE_ONLINE) return 1;
			return 0;
		});
