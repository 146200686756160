import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';

export const SET_SUMMARY = 'state.actions.issue.summary.SET_SUMMARY' as const;

export type SetSummaryAction = {
	type: typeof SET_SUMMARY;
	payload: {
		issueId: IssueId;
		summary: string;
		hasScenarioChanges?: boolean;
	};
};

export const setSummary = (
	issueId: IssueId,
	summary: string,
	hasScenarioChanges?: boolean,
): SetSummaryAction => ({
	type: SET_SUMMARY,
	payload: {
		issueId,
		summary,
		hasScenarioChanges,
	},
});

export type Action = SetSummaryAction;
