import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { getManageCustomFiltersUrl } from '../../../../common/urls/index.tsx';
import {
	projectKeySelector,
	rapidViewIdSelector,
} from '../../../../state/selectors/software/software-selectors.tsx';
import type { State } from '../../../../state/types.tsx';
import ManageCustomFiltersItem, { type StateProps } from './view.tsx';

const mapStateToProps = (state: State): StateProps => ({
	url: getManageCustomFiltersUrl(projectKeySelector(state), rapidViewIdSelector(state)),
});

export default connect(mapStateToProps, {})(ManageCustomFiltersItem);
