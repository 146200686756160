import { setIn } from 'icepick';
import { expVal } from '@atlassian/jira-feature-experiments';
import { SET_JQL } from '../../../actions/jql/index.tsx';
import type { Action } from '../../../types.tsx';
import type { JQLState } from './types.tsx';

const initialState = {
	jql: '',
} as const;

export const jqlReducer = (state: JQLState = initialState, action: Action): JQLState => {
	if (!expVal('filter_refinement_in_tmp_board', 'isEnabled', false)) {
		return state;
	}

	if (action.type === SET_JQL) {
		const { jql } = action.payload;

		return setIn(state, ['jql'], jql);
	}

	return state;
};
