export const TIMESTAMP_DISPLAY_SETTING =
	'jira.user.issue.details.timestamp-display-setting' as const;

export const CHILD_ISSUES_LIMIT_BEST_PRACTICE_FLAG_DISMISSED =
	'jira.user.issue.child-issues-limit.best-practice-flag.dismissed' as const;

export const IssueViewFetchOperations = {
	EARLY_FETCH_GRAPHQL: 'earlyFetchIssueDataQuery',
	INITIAL_GRAPHQL: 'issueDataQuery',
	EARLY_FETCH_GIRA: 'earlyFetchIssueViewInteractiveQuery',
	INITIAL_GIRA: 'issueViewInteractiveQuery',
	EARLY_FETCH_AGG: 'earlyFetchMainIssueAggQuery',
	INITIAL_AGG: 'mainIssueAggQuery',
	EARLY_FETCH_NON_CRITICAL_GIRA: 'earlyFetchIssueViewNonCriticalGiraQuery',
	NON_CRITICAL_GIRA: 'issueViewNonCriticalGiraQuery',
	VIEW_SCREEN_ID: 'viewScreenIdQuery',
	FIELD_CONFIGURATION: 'fieldConfigurationQuery',
	FIELD_DESCRIPTION: 'fieldDescriptionQuery',
	ATTACHMENT: 'attachmentsQuery',
} as const;
export type IssueViewFetchOperations =
	(typeof IssueViewFetchOperations)[keyof typeof IssueViewFetchOperations];
