import type { StoreApi } from './types.tsx';

export const actions = {
	onViewSettingsSpotlightInit:
		() =>
		({ setState }: StoreApi) => {
			setState({ hasViewedViewSettingsSpotlight: false });
		},

	onViewSettingsSpotlightStart:
		() =>
		({ setState }: StoreApi) => {
			setState({ activeViewSettingsSpotlight: 0 });
		},

	onViewSettingsSpotlightNext:
		() =>
		({ setState }: StoreApi) => {
			setState({
				activeViewSettingsSpotlight: 1,
			});
		},

	onViewSettingsSpotlightEnd:
		() =>
		({ setState }: StoreApi) => {
			setState({
				hasViewedViewSettingsSpotlight: true,
				activeViewSettingsSpotlight: null,
			});
		},

	setOpenViewSettings:
		(openViewSettingsCallback: () => void) =>
		({ getState, setState }: StoreApi) => {
			if (!getState().openViewSettings) {
				setState({
					openViewSettings: openViewSettingsCallback,
				});
			}
		},

	setViewSettingsTogglePulses:
		(viewSettingsTogglePulses: boolean) =>
		({ setState }: StoreApi) => {
			setState({ viewSettingsTogglePulses });
		},
} as const;

export type Actions = typeof actions;
