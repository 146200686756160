import { useCallback } from 'react';
import { useAnalyticsEvents, type UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';

type Attributes = {
	[key: string]: string | number | boolean;
};

export type CreateAnalyticsEventFn = (data: {
	action: string;
	actionSubject: string;
}) => UIAnalyticsEvent;

type ShortcutAnalyticsPayload = {
	id: string;
	key: string;
	attributes?: Attributes;
};

export type FireAnalyticsFn = (arg1: ShortcutAnalyticsPayload) => void;

const fireShortcutAnalytics = (
	createAnalyticsEvent: CreateAnalyticsEventFn,
	{ id, key, attributes }: ShortcutAnalyticsPayload,
	shortcutSource: string,
) => {
	const attrs: Attributes = {
		...attributes,
		key,
		shortcutSource,
		nextGenProject: true,
	};

	fireUIAnalytics(
		createAnalyticsEvent({
			action: 'pressed',
			actionSubject: 'keyboardShortcut',
		}),
		id,
		attrs,
	);
};

const useShortcutAnalytics = (shortcutSource: string): FireAnalyticsFn => {
	// Will fix on FF cleanup
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const fireAnalytics = useCallback(
		(payload: ShortcutAnalyticsPayload) => {
			fireShortcutAnalytics(createAnalyticsEvent, payload, shortcutSource);
		},
		[createAnalyticsEvent, shortcutSource],
	);
	return fireAnalytics;
};

export { fireShortcutAnalytics, useShortcutAnalytics };
