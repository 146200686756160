import type { Action } from '../../../actions/index.tsx';
import { SET_ISSUE_LINKS_PERMISSIONS } from '../../../actions/issue-links-permissions/index.tsx';
import type { IssueLinksPermissionsState } from './types.tsx';

const initialState: IssueLinksPermissionsState = { canUserLinkIssue: false, issueLinkTypes: [] };

export const issueLinksPermissionsReducer = (
	state: IssueLinksPermissionsState = initialState,
	action: Action,
): IssueLinksPermissionsState => {
	if (action.type === SET_ISSUE_LINKS_PERMISSIONS) {
		const { payload } = action;
		return payload;
	}

	return state;
};
