/**
 * @generated SignedSource<<d66617b87c0114dec14f52268752a6b1>>
 * @relayHash fa277d121f8f09e75035802d7ede6889
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID a079984b25df71d288de249187cdd59463b205124f1a0a13756db6b3207ef56b

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type viewBoardCustomFiltersQuery$variables = {
  boardId: string;
};
export type viewBoardCustomFiltersQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"customFilter_softwareFilters_CustomFilterRelay">;
};
export type viewBoardCustomFiltersQuery = {
  response: viewBoardCustomFiltersQuery$data;
  variables: viewBoardCustomFiltersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "boardId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "boardId",
    "variableName": "boardId"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "viewBoardCustomFiltersQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "customFilter_softwareFilters_CustomFilterRelay"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "viewBoardCustomFiltersQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "BoardScope",
        "kind": "LinkedField",
        "name": "boardScope",
        "plural": false,
        "selections": [
          {
            "concreteType": "CustomFilter",
            "kind": "LinkedField",
            "name": "customFilters",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "name": "name"
              }
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "id": "a079984b25df71d288de249187cdd59463b205124f1a0a13756db6b3207ef56b",
    "metadata": {},
    "name": "viewBoardCustomFiltersQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "059c408527e2b921d6b2e3d9305090e3";

import { PreloadableQueryRegistry } from 'relay-runtime';
PreloadableQueryRegistry.set(node.params.id, node);

export default node;
