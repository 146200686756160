import type { IncrementPlanningSettings } from '../types/constant.tsx';
import type { IncrementPlanningSettingsState } from '../types/state.tsx';

export const QUERY_PARAM_EPICS = 'epics' as const;
export const QUERY_PARAM_VERSIONS = 'versions' as const;
export const QUERY_PARAM_UNSCHEDULED_WORK = 'unscheduledWork' as const;
export const QUERY_PARAM_SHOW_OFFTRACK_DEPENDENCY_LINES = 'showOfftrackDependencyLines' as const;
export const QUERY_PARAM_ISSUE_IDS_TO_SHOW_DEPENDENCIES = 'issueIdsToShowDependencies' as const;
export type IncrementPlanningSettingsQuery = {
	[IncrementPlanningSettings.SHOW_UNSCHEDULED_COLUMN]:
		| IncrementPlanningSettingsState['SHOW_UNSCHEDULED_COLUMN']
		| undefined;
	[IncrementPlanningSettings.SHOW_OFFTRACK_DEPENDENCY_LINES]:
		| IncrementPlanningSettingsState['SHOW_OFFTRACK_DEPENDENCY_LINES']
		| undefined;
	[IncrementPlanningSettings.ISSUE_IDS_TO_SHOW_DEPENDENCIES]:
		| IncrementPlanningSettingsState['ISSUE_IDS_TO_SHOW_DEPENDENCIES']
		| undefined;
};
