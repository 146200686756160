import noop from 'lodash/noop';
import memoizeOne from 'memoize-one';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { RealtimeEvent } from '@atlassian/jira-realtime/src/common/types/events.tsx';
import Realtime from '@atlassian/jira-realtime/src/main.tsx';
import { APP_ID } from '../../model/constants.tsx';
import {
	REALTIME_EVENTS,
	REALTIME_EVENTS_WITH_BSI,
	BOARD_SCOPE_ISSUES_UPDATED_EVENT,
} from '../../model/realtime/constants.tsx';
import { dispatchRealtimeEvent } from '../../state/actions/realtime/index.tsx';
import type { State } from '../../state/reducers/types.tsx';
import { isCustomFiltersActive } from '../../state/selectors/filter/custom-filter-selectors.tsx';
import { realtimeChannelSelector } from '../../state/selectors/realtime/realtime-selectors.tsx';
import {
	cloudIdSelector,
	currentUserAccountIdSelector,
	getIsCMPBoard,
	projectIdSelector,
	rapidViewIdSelector,
} from '../../state/selectors/software/software-selectors.tsx';

type StateProps = {
	isCustomFiltersActive: boolean;
	cloudId: string;
	projectId: number;
	currentUserId: string;
	boardId: string;
	channels: string[];
	isCMPBoard: boolean;
};

const mapStateToProps = (state: State) => ({
	cloudId: cloudIdSelector(state),
	projectId: projectIdSelector(state),
	boardId: rapidViewIdSelector(state),
	channels: realtimeChannelSelector(state),
	currentUserId: currentUserAccountIdSelector(state),
	// If custom filters is active on this board, we actually need to listen to the granular
	// issue create and issue update events since BSI updated event does not give us enough
	// context
	isCustomFiltersActive: isCustomFiltersActive(state),
	isCMPBoard: getIsCMPBoard(state),
});

const mapDispatchToProps = {
	createRealtimeEventAction: dispatchRealtimeEvent,
} as const;

type DispatchProps = typeof mapDispatchToProps;
const onReceiveFactory = memoizeOne(
	(createRealtimeEventAction: DispatchProps['createRealtimeEventAction']) =>
		(event: RealtimeEvent) => {
			if (event) {
				createRealtimeEventAction(event);
			}
		},
);

export const mergeProps = (stateProps: StateProps, dispatchProps: typeof mapDispatchToProps) => {
	let events = REALTIME_EVENTS; // issue events

	// If the FF is enabled for listening to BSI updated, we also need to determine if we need to
	// still listen to some of the old events specifically for custom filters
	if (!stateProps.isCMPBoard) {
		events = stateProps.isCustomFiltersActive
			? REALTIME_EVENTS_WITH_BSI // BSI + issue create + issue updated
			: BOARD_SCOPE_ISSUES_UPDATED_EVENT; // just BSI
	}

	return {
		appId: APP_ID,
		channels: stateProps.channels,
		// The board scope issues updated event is designed to replace all of the individual events and is produced by
		// barbel whenever it sees a SPRINT or issue specific change
		events,
		onJoin: noop,
		onReceive: onReceiveFactory(dispatchProps.createRealtimeEventAction),
		onLeave: noop,
	};
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Realtime);
