import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/timer';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/switchMap';
import difference from 'lodash/difference';
import toNumber from 'lodash/toNumber';
import { Observable } from 'rxjs/Observable';
import { buffer } from 'rxjs/operators/buffer';
import { debounce } from 'rxjs/operators/debounce';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import {
	ISSUE_CREATED_EVENT,
	ISSUE_UPDATED_EVENT,
} from '@atlassian/jira-realtime/src/common/constants/events.tsx';
import type { IssueEventPayload } from '@atlassian/jira-realtime/src/common/types/payloads.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import { fetchFilteredCardIds } from '../../../services/filtered-cards/index.tsx';
import { makeServiceContext } from '../../../services/service-context.tsx';
import {
	refilterSuccess,
	refilterFailure,
} from '../../../state/actions/card/filtered-cards/index.tsx';
import { issueCreateFilteredV2 } from '../../../state/actions/flags/index.tsx';
import {
	REALTIME_DISPATCH_EVENT,
	type RealtimeEventAction,
} from '../../../state/actions/realtime/index.tsx';
import {
	getActiveCustomFilterIds,
	isCustomFiltersActive,
} from '../../../state/selectors/filter/custom-filter-selectors.tsx';
import { getIssueKeysFromIssueIds } from '../../../state/selectors/issue/issue-selectors.tsx';
import { currentUserAccountIdSelector } from '../../../state/selectors/software/software-selectors.tsx';
import { getInvisibleIssueIdsFromClientSideFiltersOnly } from '../../../state/selectors/work/work-selectors.tsx';
import type { ActionsObservable, MiddlewareAPI, State } from '../../../state/types.tsx';
import { getCustomFiltersDebounceTime } from '../utils.tsx';

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
const isActiveTab = () => !document.hidden;

const isCurrentUser = (state: State, action: RealtimeEventAction) =>
	currentUserAccountIdSelector(state) === action.payload.event.payload.atlassianId;

// The realtime provider returns board scope issue events as well as the issue
// created and updated events when the FF is enabled. The BSI updated event
// will be used for refreshing the board but the issue create/updated events
// are here ONLY for custom filter support
const isIssueEvent = (action: RealtimeEventAction) =>
	action.payload.event.type === ISSUE_CREATED_EVENT ||
	action.payload.event.type === ISSUE_UPDATED_EVENT;

export const refilterRequestEpic = (action$: ActionsObservable, store: MiddlewareAPI) => {
	const refilterAction$ = action$
		.ofType(REALTIME_DISPATCH_EVENT)
		.filter(isActiveTab)
		.filter(isIssueEvent)
		.filter(() => isCustomFiltersActive(store.getState()))
		.filter((action: RealtimeEventAction) => isCurrentUser(store.getState(), action));
	let debouncedActions: RealtimeEventAction[] = [];

	const newRefilterAction$ = refilterAction$.pipe(
		buffer(
			refilterAction$.pipe(
				debounce((valueRaw) => {
					// Saving debounced actions to be check if create flag should be shown
					debouncedActions.push(valueRaw);
					return Observable.timer(getCustomFiltersDebounceTime());
				}),
			),
		),
	);

	return newRefilterAction$.switchMap((actions) => {
		const state = store.getState();
		const customFilterIds = getActiveCustomFilterIds(state);

		const isCreatedIssue = (cardId: IssueId) =>
			debouncedActions.some(
				(action) =>
					'issueId' in action.payload.event.payload &&
					action.payload.event.payload.issueId === cardId &&
					action.payload.event.type === ISSUE_CREATED_EVENT,
			);

		const issueIds = [
			// remove duplicates via Set
			...new Set(
				actions.map((action) => {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					const payload = action.payload.event.payload as IssueEventPayload;
					return payload.issueId;
				}),
			),
		];

		const ctx = makeServiceContext(state);
		return fetchFilteredCardIds(ctx, { issueIds, customFilterIds })
			.mergeMap(({ cardIds }) => {
				const filteredCardIds = cardIds?.map((cardId) => toNumber(cardId)); // convert str to num/IssueKey
				const excludedCardIds = difference(issueIds, filteredCardIds); // diff them against the query response
				const invisibleIssueIdsSelector = getInvisibleIssueIdsFromClientSideFiltersOnly;

				const invisibleCardIds = [
					...invisibleIssueIdsSelector(state)(filteredCardIds), // hidden due to other client-side filters
					...excludedCardIds, // hidden due to custom filters
				];

				if (invisibleCardIds.length) {
					// we only want to show the flag for created issues
					const invisibleCreatedCardIds = invisibleCardIds.filter(isCreatedIssue);

					const invisibleCreatedCardKeys = getIssueKeysFromIssueIds(state)(invisibleCreatedCardIds);

					// if we were unable to get card keys, the issue isn't present on the board at all.
					// as such, we don't want to show the flag here, as we'll already be showing a flag prior to this (due to unmapped status or not in a current sprint).
					if (invisibleCreatedCardKeys.length) {
						debouncedActions = [];
						return [
							issueCreateFilteredV2(invisibleCreatedCardKeys),
							refilterSuccess(
								excludedCardIds.map((cardId) => cardId.toString()),
								cardIds,
							),
						];
					}
				}
				debouncedActions = [];

				return [
					refilterSuccess(
						excludedCardIds.map((cardId) => cardId.toString()),
						cardIds,
					),
				];
			})
			.catch((error) => {
				log.safeErrorWithoutCustomerData(
					'board.filtered-cards.refilter.failure',
					'Fail to re-filter cards with custom filters',
					error,
				);
				debouncedActions = [];
				return Observable.of(refilterFailure(error));
			});
	});
};
