import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noUnreleasedVersions: {
		id: 'software-filters.filters.version.no-unreleased-versions',
		defaultMessage: 'No unreleased versions in this project',
		description:
			'This message is shown in the versions quick filter when there are no unreleased versions to select from',
	},
	noMatchingVersions: {
		id: 'software-filters.filters.version.no-matching-versions',
		defaultMessage: 'No matching versions',
		description:
			'This message is shown in the versions quick filter when there are no matching versions',
	},
	label: {
		id: 'software-filters.filters.version.label',
		defaultMessage: 'Version',
		description: 'This is the label of a dropdown menu to filter Jira issues based on versions.',
	},
	toggleLabel: {
		id: 'software-filters.filters.version.toggle-label',
		defaultMessage: 'Show version panel',
		description: 'The label of toggle to show and hide the version panel',
	},
});
