import type { ExternalAction } from '@atlassian/jira-issue-view-store/src/actions/external-actions.tsx';
import type { IssueKey, IssueId } from '@atlassian/jira-shared-types/src/general.tsx';

export const OPEN_ISSUE_MODAL = 'state.actions.issue.modal.OPEN_ISSUE_MODAL' as const;

export type OpenIssueModalAction = {
	type: typeof OPEN_ISSUE_MODAL;
	payload: {
		issueKey: IssueKey;
		externalAction?: ExternalAction;
	};
};

export const openIssueModal = (
	issueKey: IssueKey,
	externalAction?: ExternalAction,
): OpenIssueModalAction => ({
	type: OPEN_ISSUE_MODAL,
	payload: {
		issueKey,
		externalAction,
	},
});

export const OPEN_PLAN_ISSUE_MODAL = 'state.actions.issue.modal.OPEN_PLAN_ISSUE_MODAL' as const;

export type OpenPlanIssueModalAction = {
	type: typeof OPEN_PLAN_ISSUE_MODAL;
	payload: {
		issueId: IssueId;
	};
};

export const openPlanIssueModal = (issueId: IssueId): OpenPlanIssueModalAction => ({
	type: OPEN_PLAN_ISSUE_MODAL,
	payload: {
		issueId,
	},
});

export const CLOSE_PLAN_ISSUE_MODAL = 'state.actions.issue.modal.CLOSE_PLAN_ISSUE_MODAL' as const;

export type ClosePlanIssueModalAction = {
	type: typeof CLOSE_PLAN_ISSUE_MODAL;
};

export const closePlanIssueModal = (): ClosePlanIssueModalAction => ({
	type: CLOSE_PLAN_ISSUE_MODAL,
});

export const OPEN_ISSUE_MODAL_DEBOUNCED =
	'state.actions.issue.modal.OPEN_ISSUE_MODAL_DEBOUNCED' as const;

export type OpenIssueModalDebouncedAction = {
	type: typeof OPEN_ISSUE_MODAL_DEBOUNCED;
	payload: {
		issueKey: IssueKey;
		externalAction?: ExternalAction;
	};
};

export const openIssueModalDebounced = (
	issueKey: IssueKey,
	externalAction?: ExternalAction,
): OpenIssueModalDebouncedAction => ({
	type: OPEN_ISSUE_MODAL_DEBOUNCED,
	payload: {
		issueKey,
		externalAction,
	},
});

export const CLOSE_ISSUE_MODAL = 'state.actions.issue.modal.CLOSE_ISSUE_MODAL' as const;

export type CloseIssueModalAction = {
	type: typeof CLOSE_ISSUE_MODAL;
	payload: IssueKey | null | undefined;
};

export const closeIssueModal = (issuekey?: IssueKey | null): CloseIssueModalAction => ({
	type: CLOSE_ISSUE_MODAL,
	payload: issuekey,
});

export const RESET_EXTERNAL_ACTION = 'state.actions.issue.modal.RESET_EXTERNAL_ACTION' as const;

export type ResetExternalAction = {
	type: typeof RESET_EXTERNAL_ACTION;
};

export const resetExternalAction = (): ResetExternalAction => ({
	type: RESET_EXTERNAL_ACTION,
});

export type Action =
	| OpenIssueModalAction
	| OpenIssueModalDebouncedAction
	| CloseIssueModalAction
	| OpenPlanIssueModalAction
	| ClosePlanIssueModalAction
	| ResetExternalAction;
