import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	groupCardsByOld: {
		id: 'software.main.header.controls-bar.swimlane-switch.group-cards-by-old',
		defaultMessage: 'Group by',
		description: 'Group by accessibility label',
	},
	groupCardsBy: {
		id: 'software.main.header.controls-bar.swimlane-switch.group-cards-by',
		defaultMessage: 'Group',
		description: 'Group by accessibility label',
	},
	groupCardsByAccessability: {
		id: 'software.main.header.controls-bar.swimlane-switch.group-cards-by-accessability',
		defaultMessage: 'Group by {label}',
		description: 'Group by accessibility label',
	},
});
