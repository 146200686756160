import React from 'react';
import { ColumnEdgeContextProvider } from '../column-edge-provider/index.tsx';
import { DraggableColumn } from './main.tsx';
import type { OwnProps } from './types.tsx';

const DraggableColumnMain = (props: OwnProps) => (
	<ColumnEdgeContextProvider>
		<DraggableColumn {...props} />
	</ColumnEdgeContextProvider>
);

export default DraggableColumnMain;
