import {
	createStore,
	createContainer,
	createHook,
	createActionsHook,
	createStateHook,
} from '@atlassian/react-sweet-state';
import { actions } from './actions/index.tsx';
import type { IssueLinkedStatsApi, State } from './types.tsx';

export const initialState: State = {
	openedIssueKey: '',
};

export type Actions = typeof actions;

export const store = createStore<State, Actions>({
	initialState,
	actions,
	name: 'issue-links-stats-store',
});

export const IssueLinkedStatsContainer = createContainer<State, Actions>(store, {
	onInit:
		() =>
		({ setState }: IssueLinkedStatsApi) => {
			setState(initialState);
		},
});

const getIsIssueLinkDetailsPopupOpen = (
	state: State,
	{ openedIssueKey }: { openedIssueKey: string },
) => state.openedIssueKey === openedIssueKey;

export const useIsIssueLinkDetailsPopupOpen = createStateHook(store, {
	selector: getIsIssueLinkDetailsPopupOpen,
});

export const useIssueLinkedStatsStore = createHook(store);

export const useIssueLinkedStatsActions = createActionsHook(store);
