import 'rxjs/add/observable/throw';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import get from 'lodash/get';
import { type Observable as ObservableType, Observable } from 'rxjs/Observable';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { CardTransitionEntities } from '../../../model/card-transition/card-transition-types.tsx';
import type { ColumnGQLData, Column } from '../../../model/column/column-types.tsx';
import columnRename from '../../../rest/column-rename-graphql/index.tsx';
import {
	transformColumn,
	getCardTransitions,
} from '../../board-scope-graphql/transformer/index.tsx';

export type ColumnRenameResponse = {
	column: Column;
	cardTransitions: CardTransitionEntities | null;
};

export const transform = (
	projectKey: string,
	columnRenameData: ColumnGQLData,
): ColumnRenameResponse => ({
	column: transformColumn(columnRenameData.column),
	cardTransitions: getCardTransitions(projectKey, [columnRenameData.column]),
});

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	contextPath: string,
	boardId: string,
	columnId: number,
	columnName: string,
	projectKey: string,
): ObservableType<ColumnRenameResponse> =>
	columnRename(contextPath, boardId, columnId, columnName)
		.flatMap((columnRenameData: ColumnGQLData) =>
			Observable.of(transform(projectKey, columnRenameData)),
		)
		.catch((error) => {
			log.safeErrorWithoutCustomerData(
				'board.column.rename.graphql.failure',
				'Failed to rename column via GraphQl',
				{ message: get(error, ['message'], '') || get(error, [0, 'message'], '') },
			);
			return Observable.throw(error);
		});
