import type { ApplicationKey } from '@atlassian/jira-shared-types/src/application-key.tsx';
import type { ApplicationEdition } from '@atlassian/jira-shared-types/src/edition.tsx';

// Used to further type `onOpen` in `UpflowIframe` that will now
// send the event payload when running `onOpen`
// upon receiving a `SPA_READY` event type.
// Currently only the engaged-billing iframe makes use of that
// with displaysNextAt
export type EventPayload = {
	type: string;
	displaysNextAt: Date;
};

export type Props = {
	targetEdition: ApplicationEdition;
	product: ApplicationKey;
	flow:
		| 'learn-more'
		| 'workflow-edit-permission-learn-more'
		| 'upgrade'
		| 'request-learn-more'
		| 'request-upgrade'
		| 'persistent-upgrade';
	touchpointId: string;
	experienceTrackingEnabled?: boolean;
	subRoute?: string;
	onClose: () => void;
	onOpen?: (event: EventPayload) => void;
};

export type SpaHostClientInitializerProps = Props & {
	ccpEntitlementId?: string;
	commerceBackend?: 'HAMS' | 'CCP';
};

export type UpFlowApplicationEdition = 'free' | 'standard' | 'premium';
export const FREE_EDITION = 'free';
export const STANDARD_EDITION = 'standard';
export const PREMIUM_EDITION = 'premium';
