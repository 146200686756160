export const EXPERIENCES = {
	IMPORT_PART_2: 'projectCreateImporterImportPart2',
	IMPORT_PART_JSW: 'projectCreateImporterImportPart2JSW',
} as const;

export type EXPERIENCES = (typeof EXPERIENCES)[keyof typeof EXPERIENCES];

export const X2C_SCREENS = {
	ImportProgressBar: 'projectCreateImportProgressBar',
} as const;
export type X2C_SCREENS = (typeof X2C_SCREENS)[keyof typeof X2C_SCREENS];
