import React from 'react';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';

export const CardCoverSkeleton = () => (
	<Box paddingBlockStart="space.100" paddingBlockEnd="space.100" xcss={skeletonStyles}>
		<Flex justifyContent="center" alignItems="center" xcss={skeletonStyles}>
			<Spinner size="medium" />
		</Flex>
	</Box>
);

const skeletonStyles = xcss({
	width: '366px',
	height: '300px',
});
