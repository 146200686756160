import type { StoreApi, OpenArchiveIssueModalProps } from './types.tsx';

export const ArchiveIssueModalActions = {
	openArchiveIssueModal:
		({
			issueKey,
			issueId,
			onIssueArchiveSuccess,
			onCloseIssueViewModal,
			triggerPointKey,
		}: OpenArchiveIssueModalProps) =>
		({ setState }: StoreApi) => {
			setState({
				isArchiveIssueModalOpen: true,
				issueKey,
				issueId,
				onIssueArchiveSuccess,
				onCloseIssueViewModal,
				triggerPointKey,
			});
		},
	closeArchiveIssueModal:
		() =>
		({ setState }: StoreApi) => {
			setState({
				isArchiveIssueModalOpen: false,
				issueKey: null,
				issueId: null,
				onIssueArchiveSuccess: null,
				onCloseIssueViewModal: null,
				triggerPointKey: null,
			});
		},
};

export type Actions = typeof ArchiveIssueModalActions;
