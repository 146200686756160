import type { BoardToolSection, BoardToolSectionSubItem } from '../../common/types.tsx';
import type { BoardMenuItemLocation } from './types.tsx';

/**
 * DFS look-up for section, recursive
 */
export function findSection(
	toolSections: BoardToolSectionSubItem[],
	location: BoardMenuItemLocation,
): BoardToolSection | null {
	for (let i = 0; i < toolSections.length; i += 1) {
		const item = toolSections[i];
		if (item.type === 'section' && item.id === location) {
			return item;
		}
		if (item.type === 'section' && item.subItems != null) {
			const candidate = findSection(item.subItems, location);
			if (candidate != null) {
				return candidate;
			}
		}
	}
	return null;
}
