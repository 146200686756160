import { useState, useCallback } from 'react';

/** convenience boolean hook for controlling @atlaskit/popup
 * https://atlassian.design/components/popup/
 */
export const usePopupController = (defaultState = false) => {
	// TODO one day useBoolean https://hello.atlassian.net/wiki/spaces/JFP/blog/2024/09/01/4131392637/PSA+Write+less+code+-+useBoolean+hook
	const [isOpen, setIsOpen] = useState(defaultState);

	const togglePopup = useCallback(() => {
		setIsOpen((pendingIsOpen) => !pendingIsOpen);
	}, [setIsOpen]);

	const closePopup = useCallback(() => {
		setIsOpen(false);
	}, [setIsOpen]);

	return [isOpen, togglePopup, closePopup] as const;
};
