import React from 'react';
import Icon from '@atlaskit/icon';
import type { IconPropsWithOnClick } from '../types.tsx';

const glyph = () => (
	<svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#a)" fill="#006644">
			<path d="m7 19c1.1046 0 2-0.8954 2-2s-0.89543-2-2-2-2 0.8954-2 2 0.89543 2 2 2z" />
			<path
				d="m10.292 9.4664c0.5541 0.38447 0.9515 1.1146 0.6559 1.7208l-3.0487 6.2512-1.7976-0.8768 3.6792-7.5433c0.15274 0.16787 0.32422 0.31835 0.51122 0.44812zm2.3852-6.3867 1.7976 0.87674-0.418 0.85921c-0.4863-0.45793-1.1254-0.75531-1.8327-0.80745l0.4531-0.9285z"
				clipRule="evenodd"
				fillRule="evenodd"
			/>
			<path d="m12 2c2.4213 0 4.4406 1.7211 4.9013 4.0066 0.1091 0.54134-0.3474 0.99368-0.8997 0.99356-0.5524-1.1e-4 -0.9853-0.46025-1.166-0.98224-0.4068-1.1745-1.5227-2.0179-2.8356-2.0179-1.6569 0-3 1.3432-3 3 0 0.99194 0.48143 1.8714 1.2234 2.4176l-1.0041 1.7385c-1.3384-0.8972-2.2193-2.4238-2.2193-4.1561 0-2.7614 2.2386-5 5-5z" />
			<path d="m10.403 5.7963c-0.66479 0.88215-0.48855 2.1362 0.3936 2.8009 0.8821 0.66474 2.1361 0.4885 2.8009-0.39365 0.6647-0.88214 0.4885-2.1362-0.3936-2.8009-0.8822-0.66474-2.1362-0.4885-2.8009 0.39364z" />
			<path
				d="m16.036 14.163c-0.6405 0.2111-1.4628 0.0891-1.769-0.5117l-3.1576-6.1969 1.782-0.90798 3.8102 7.478c-0.226 0.021-0.4494 0.0674-0.6656 0.1386zm3.6652 5.7485-1.782 0.908-0.4346-0.8509c0.6583-0.1128 1.2804-0.4442 1.7478-0.9777l0.4688 0.9206z"
				clipRule="evenodd"
				fillRule="evenodd"
			/>
			<path d="m20.97 20.02c-1.4572 1.9338-4.047 2.5107-6.1496 1.503-0.4979-0.2386-0.5843-0.8754-0.2519-1.3163 0.3325-0.441 0.9605-0.51 1.486-0.3402 1.1829 0.3821 2.5281-0.0015 3.3183-1.0501 0.9971-1.3233 0.7327-3.2043-0.5905-4.2014-0.7922-0.5969-1.7843-0.7418-2.667-0.4779l-0.7842-1.8481c1.522-0.5289 3.2714-0.3138 4.6548 0.7287 2.2054 1.6619 2.646 4.7969 0.9841 7.0023z" />
			<path d="m12 9c1.1046 0 2-0.89543 2-2s-0.8954-2-2-2-2 0.89543-2 2 0.8954 2 2 2z" />
			<path d="m18.79 16.121c-0.4929-0.9885-1.6937-1.3903-2.6823-0.8975-0.9885 0.4929-1.3903 1.6938-0.8974 2.6823 0.4928 0.9885 1.6937 1.3903 2.6822 0.8975 0.9885-0.4929 1.3904-1.6938 0.8975-2.6823z" />
			<path
				d="m9.9753 17.429c0.09691-0.6673 0.57301-1.3489 1.2473-1.3548l6.9548-0.0608 0.0174 1.9999-8.3924 0.0732c0.08208-0.2116 0.14022-0.4322 0.17291-0.6575zm-6.7799 0.7152-0.01745-1.9999 0.95543-0.0093c-0.19286 0.6395-0.17382 1.3441 0.09512 2.0004l-1.0331 0.0088z"
				clipRule="evenodd"
				fillRule="evenodd"
			/>
			<path d="m2.5311 19.232c-1.0804-2.167-0.44111-4.7421 1.3x988-6.1742 0.43571-0.3391 1.0442-0.1322 1.2905 0.3619 0.24632 0.4943 0.02783 1.0871-0.3586 1.4817-0.86977 0.8881-1.1267 2.2632-0.54083 3.4382 0.73929 1.4828 2.5406 2.0855 4.0234 1.3462 0.88773-0.4426 1.46-1.2659 1.6177-2.1736l2.0038 0.1229c-0.2058 1.5981-1.1788 3.0676-2.7291 3.8406-2.4713 1.2321-5.4735 0.2276-6.7057-2.2437z" />
		</g>
		<defs>
			<clipPath id="a">
				<path d="M0 0H24V24H0V0Z" fill="#fff" />
			</clipPath>
		</defs>
	</svg>
);

export const WebhookIcon = (props: IconPropsWithOnClick) => <Icon glyph={glyph} {...props} />;
