import type { Person } from '../../../model/people/people-types.tsx';

export const PERSON_SET = 'state.actions.people.PERSON_SET' as const;

export type SetPersonAction = {
	type: typeof PERSON_SET;
	payload: Person;
};

export const setPerson = (person: Person): SetPersonAction => ({
	type: PERSON_SET,
	payload: person,
});

export type Action = SetPersonAction;
