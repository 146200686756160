import isNil from 'lodash/isNil';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import { createSelector } from '@atlassian/react-sweet-state';
import type { CardId } from '../../../model/card/card-types.tsx';
import {
	getFilteredIssueIdsByColumn,
	platformIssueSelector,
	platformIssueIdsBySwimlaneSelector,
} from '../work/work-selectors.tsx';
import { getCardSelection } from './card-selectors.tsx';

/**
 * Given an issue clicked with shift, returns the list of IDs for a range
 * selection or null if the range selection is invalid
 */
export const getCardShiftSelectionIds = createSelector(
	[
		getCardSelection,
		platformIssueSelector,
		getFilteredIssueIdsByColumn,
		platformIssueIdsBySwimlaneSelector,
	],
	(cardSelection, getPlatformIssue, filteredIssuesByColumn, filteredIssuesBySwimlane) =>
		(issueId: IssueId): IssueId[] | null => {
			if (!cardSelection.length) {
				return null;
			}

			const lastCardId = cardSelection[cardSelection.length - 1];
			const lastCard = getPlatformIssue(lastCardId);
			const thisCard = getPlatformIssue(issueId);
			if (
				isNil(lastCard) ||
				isNil(thisCard) ||
				lastCard.columnId !== thisCard.columnId ||
				lastCard.swimlaneId !== thisCard.swimlaneId
			) {
				return null;
			}

			if (isNil(thisCard.swimlaneId)) {
				// no swimlane
				return rangeSelectWithinColumn(
					filteredIssuesByColumn[thisCard.columnId],
					lastCardId,
					issueId,
				);
			}
			// has swimlane
			return rangeSelectWithinColumn(
				filteredIssuesBySwimlane[thisCard.swimlaneId][thisCard.columnId],
				lastCardId,
				issueId,
			);
		},
);

const rangeSelectWithinColumn = (
	orderedIssues: IssueId[],
	lastCardId: CardId,
	issueId: IssueId,
): IssueId[] => {
	const startIndex = orderedIssues.indexOf(lastCardId);
	const endIndex = orderedIssues.indexOf(issueId);
	return orderedIssues.slice(Math.min(startIndex, endIndex), Math.max(startIndex, endIndex) + 1);
};
