import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noIssuesSelected: {
		id: 'automation-platform.no-issue-selected-empty-state.no-issues-selected',
		defaultMessage:
			'To automate one or more issues, select them by holding <ctrl>Ctrl</ctrl> + <click>click</click>',
		description:
			'Message that is shown when there are no issues selected on the board, the parameter is which key you should hold depending on what OS is running',
	},
});
