import React from 'react';
import { Section } from '@atlaskit/menu';
import { NoRuleForSelectedIssuesEmptyState } from '@atlassian/jira-automation-platform/src/ui/no-rule-for-issues-empty-state/index.tsx';
import {
	fireOperationalAnalyticsDeferred,
	MountEvent,
} from '@atlassian/jira-product-analytics-bridge';
import {
	useBaseAutomationUrl,
	useCanManageAutomations,
} from '../../../../../../controllers/menu-context/index.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => {
	const [canManageAutomations] = useCanManageAutomations();
	const [baseAutomationUrl] = useBaseAutomationUrl();

	return (
		<Section>
			<MountEvent
				onMount={(event) => fireOperationalAnalyticsDeferred(event, 'ruleEmptyState mounted')}
			/>
			<>
				<NoRuleForSelectedIssuesEmptyState
					baseAutomationUrl={baseAutomationUrl}
					canManageAutomations={canManageAutomations}
				/>
			</>
		</Section>
	);
};
