{
  "__schema": {
    "types": [
      {
        "kind": "UNION",
        "name": "Admin",
        "possibleTypes": [
          {
            "name": "JiraGroup"
          },
          {
            "name": "JiraUser"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "BoardFeatureView",
        "possibleTypes": [
          {
            "name": "BasicBoardFeatureView"
          },
          {
            "name": "EstimationBoardFeatureView"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "BoardLocation",
        "possibleTypes": [
          {
            "name": "Project"
          },
          {
            "name": "UserLocation"
          }
        ]
      }
    ]
  }
}