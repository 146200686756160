import { createSessionExpirableStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/session-storage/index.tsx';

const RETURN_TO_PROJECT_URL = 'returnToProjectUrl';

const sessionStorage = createSessionExpirableStorageProvider('navigation');

export const setReturnToProjectUrl = () => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	sessionStorage.set(RETURN_TO_PROJECT_URL, window.location.pathname + window.location.search);
};

export const getReturnToProjectUrl = (): string =>
	String(sessionStorage.get(RETURN_TO_PROJECT_URL) || '');
