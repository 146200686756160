import { freeze } from 'icepick';
import type { CardGQL } from '@atlassian/jira-software-board-fetch-scope-critical/src/types.tsx';
import type { Column } from '../../model/column/column-types.tsx';
import type { IssueMediaCollection } from '../../model/issue/issue-media-types.tsx';
import type { Issue } from '../../model/issue/issue-types.tsx';
import type { EstimationStatisticFieldId } from '../../model/work/work-types.tsx';
import { calculateEstimateValue } from '../utils/estimation/index.tsx';

export const cardRefreshMediaTransformer = (
	cardId: string,
	card: CardGQL | null,
): IssueMediaCollection => {
	const collection: IssueMediaCollection = {};

	if (card === null || !card.coverMedia || card.id !== cardId) {
		collection[cardId] = { isHiddenByUser: true };

		return collection;
	}

	const { endpointUrl, clientId, token, attachmentId, attachmentMediaApiId } = card.coverMedia;

	collection[String(cardId)] = {
		isHiddenByUser: false,
		endpointUrl,
		clientId,
		token,
		attachmentId,
		attachmentMediaApiId: String(attachmentMediaApiId),
	};

	return collection;
};

export const cardRefreshIssueTransformer = (
	projectId: string,
	column: Column,
	estimateFieldId: EstimationStatisticFieldId,
	card: CardGQL,
): Issue => {
	const {
		id,
		issue: {
			key,
			summary,
			labels,
			status,
			type: { id: typeId, name: typeName, iconUrl: typeUrl },
			assignee,
		},
		done: isDone,
		flagged: isFlagged,
		parentId,
		estimate,
		priority,
		dueDate,
		childIssuesMetadata,
	} = card;

	const sprintId = card.issue.activeSprint?.id ?? '';

	const assigneeAccountId = assignee && { assigneeAccountId: assignee.accountId };
	const columnId = column && { columnId: column.id };

	return freeze({
		id: Number(id),
		key,
		summary,
		statusId: Number(status.id),
		...columnId,
		projectId: Number(projectId),
		typeId: Number(typeId),
		typeName,
		typeUrl,
		...assigneeAccountId,
		// devStatus TODO https://jdog.jira-dev.com/browse/ABS-257
		isFlagged,
		labels: [...labels],
		isDone,
		parentId: Number(parentId) || null,
		estimate: calculateEstimateValue(estimate, estimateFieldId),
		priority: priority ? { ...priority } : null,
		dueDate: dueDate || null,
		numCompleteChildren: childIssuesMetadata ? childIssuesMetadata.complete : 0,
		numTotalChildren: childIssuesMetadata ? childIssuesMetadata.total : 0,
		sprintId,
	});
};
