import { useMemo, useRef } from 'react';

const isShallowDifferent = (arrayA: unknown[], arrayB: unknown[]) =>
	arrayA.length !== arrayB.length ||
	arrayA.some((currentElement, index) => currentElement !== arrayB[index]);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useMetricsMemo = <T extends Record<any, any>>(
	f: (arg1: undefined) => T,
	deps: unknown[],
): T => {
	const metricsTrackerRef = useRef<unknown>();
	const fetchDependenciesRef = useRef(deps);

	useMemo(() => {
		const hasEffectsChanged = isShallowDifferent(fetchDependenciesRef.current, deps);
		if (!metricsTrackerRef.current || hasEffectsChanged) {
			if (hasEffectsChanged) {
				fetchDependenciesRef.current = deps;
			}

			// @ts-expect-error - TS2554 - Expected 1 arguments, but got 0.
			metricsTrackerRef.current = f();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps);

	// @ts-expect-error - TS2322 - Type 'unknown' is not assignable to type 'T'.
	return metricsTrackerRef.current;
};
