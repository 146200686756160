import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	importingInProgressTitle: {
		id: 'project-create-import-progress.common.utils.use-flags.importing-in-progress-title',
		defaultMessage: 'We’re importing your data',
		description: 'Heading for the import in progress flag',
	},
	importingInProgressDescription: {
		id: 'project-create-import-progress.common.utils.use-flags.importing-in-progress-description',
		defaultMessage:
			"You won't see any imported data yet, but feel free to work on other things while we get this done. Return to this page to check the progress of your import.",
		description:
			'Description for the import in progress flag to inform user they can still work on other things while import is happening',
	},
	importSuccessDescription: {
		id: 'project-create-import-progress.common.utils.use-flags.import-success-description',
		defaultMessage:
			'Refresh the page to see the {count, plural, one {# issue} other {# issues}} in your new project.',
		description: 'Description for the import successful flag',
	},
	importSuccessFlagTitle: {
		id: 'project-create-import-progress.common.utils.use-flags.import-success-flag-title',
		defaultMessage: '{projectName} is ready!',
		description: 'Heading for the import succeeded flag',
	},
	importPartialSuccessTitle: {
		id: 'project-create-import-progress.common.utils.use-flags.import-partial-success-title',
		defaultMessage: 'We couldn’t import all your data',
		description: 'Heading for the import partially succeeded flag',
	},
	importAdvancedErrorTitle: {
		id: 'project-create-import-progress.common.utils.use-flags.import-advanced-error-title',
		defaultMessage: "We can't seem to import all your data",
		description: 'Heading for the import advanced error flag',
	},
	importAdvancedErrorDescription: {
		id: 'project-create-import-progress.common.utils.use-flags.import-advanced-error-description',
		defaultMessage:
			'See the error log to understand why {failed} of your {total} {total, plural, one {issue} other {issues}} failed.',
		description: 'Description for the import advanced error flag',
	},
	importPartialSuccessDescription: {
		id: 'project-create-import-progress.common.utils.use-flags.import-partial-success-description',
		defaultMessage:
			'Try again in a few minutes. If you continue experiencing problems with your import, contact support.',
		description: 'Description for the import partially succeeded flag',
	},
	contactSupport: {
		id: 'project-create-import-progress.common.utils.use-flags.contact-support',
		defaultMessage: 'Contact Support',
		description: 'Text for contact support call to action on the error flag',
	},
	downloadLogs: {
		id: 'project-create-import-progress.common.utils.use-flags.download-logs',
		defaultMessage: 'Download logs',
		description: 'Text for download logs action on the error flag',
	},
	giveFeedback: {
		id: 'project-create-import-progress.common.utils.use-flags.give-feedback',
		defaultMessage: 'Give feedback',
		description: 'Text for give feedback call to action on the flag',
	},
	refreshPage: {
		id: 'project-create-import-progress.common.utils.use-flags.refresh-page',
		defaultMessage: 'Refresh page',
		description: 'Button to refresh the page',
	},
	importSuccessDescriptionIssueTermRefresh: {
		id: 'project-create-import-progress.common.utils.use-flags.import-success-description-issue-term-refresh',
		defaultMessage:
			'Refresh the page to see the {count, plural, one {# work item} other {# work items}} in your new project.',
		description: 'Description for the import successful flag',
	},
	importAdvancedErrorDescriptionIssueTermRefresh: {
		id: 'project-create-import-progress.common.utils.use-flags.import-advanced-error-description-issue-term-refresh',
		defaultMessage:
			'See the error log to understand why {failed} of your {total} {total, plural, one {work item} other {work items}} failed.',
		description: 'Description for the import advanced error flag',
	},
});
