import React, { memo } from 'react';
import { PriorityChipRead } from '@atlassian/jira-issue-field-priority-chip/src/ui/read/index.tsx';
import type { Priority } from '../../../model/issue/issue-types.tsx';

export const PriorityFieldStatic = ({ name, iconUrl }: Priority) => {
	return (
		<div data-testid="software-board.common.fields.priority-field-static.wrapper">
			<PriorityChipRead name={name} iconUrl={iconUrl} />
		</div>
	);
};
export default memo<Priority>(PriorityFieldStatic);
