import React, { useState, useMemo } from 'react';
import type { State as StoreState } from '../../services/store/index.tsx';
import type { Action, Store } from '../../services/types.tsx';
import { createDispatcher, type DispatcherProps } from './create-dispatcher/index.tsx';

type Props<TState, TAction extends Action> = {
	store?: Store<TState>;
} & DispatcherProps<TState, TAction>;

export const Dispatcher = <TAction extends Action, TState extends StoreState>(
	props: Props<TState, TAction>,
) => {
	const { store, ...dispatcherProps } = props;
	const [lastStore, setLastStore] = useState(store);

	if (store !== lastStore) setLastStore(store);
	const DispatcherComponent: React.ComponentType<Props<TState, TAction>> = useMemo(
		() => createDispatcher(store),
		[store],
	);

	return <DispatcherComponent {...dispatcherProps} />;
};
