import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import isNil from 'lodash/isNil';
import { type Observable as ObservableType, Observable } from 'rxjs/Observable';
import { fg } from '@atlassian/jira-feature-gating';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import type { CardTransitionInfo } from '../../../model/card-transition/card-transition-types.tsx';
import type { Issue } from '../../../model/issue/issue-types.tsx';
import type {
	BoardCardCreate_boardCardCreate_newCards as NewCard,
	BoardCardCreate,
	BoardCardCreateVariables,
} from '../../../rest/card/create/__generated_apollo__/BoardCardCreate';
import rest from '../../../rest/card/create/index.tsx';
import {
	transitionIDForCardCreateSelector,
	transitionIDForCardCreateSelectorNew,
} from '../../../state/selectors/card-create/card-create-selectors.tsx';
import { getIsCustomBoardWithIcc } from '../../../state/selectors/inline-create/inline-create-selectors.tsx';
import { getIssueTypeById } from '../../../state/selectors/issue/issue-selectors.tsx';
import {
	contextPathSelector,
	rapidViewIdSelector,
} from '../../../state/selectors/software/software-selectors.tsx';
import type { State } from '../../../state/types.tsx';

export const transformer = (
	state: State,
	response: BoardCardCreate,
	optimisticIssues: Issue[],
	transitionInfo: CardTransitionInfo | null,
): Issue[] => {
	if (isNil(response.boardCardCreate)) {
		return [];
	}
	return (
		response?.boardCardCreate?.newCards
			?.filter((card) => card != null)
			?.map((card, index): Issue => {
				const {
					id,
					issue: { key },
				} = // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					card as NewCard;
				const {
					summary,
					statusId,
					columnId,
					projectId,
					typeId,
					isDone,
					parentId,
					estimate,
					priority,
					dueDate,
					numCompleteChildren,
					numTotalChildren,
					assigneeAccountId,
					labels,
					sprintId,
					fixVersions,
				} = optimisticIssues[index];

				const { name: typeName, iconUrl: typeUrl } = getIssueTypeById(state, typeId);
				return {
					id: Number(id),
					key: String(key),
					summary,
					statusId: transitionInfo ? transitionInfo.statusId : statusId,
					columnId,
					projectId,
					typeId,
					typeName,
					typeUrl,
					assigneeAccountId,
					labels,
					isDone,
					parentId,
					estimate,
					priority,
					dueDate,
					numCompleteChildren,
					numTotalChildren,
					sprintId,
					fixVersions,
				};
			}) ?? []
	);
};

export const convertToNewCards = (
	optimisticIssues: Issue[],
): BoardCardCreateVariables['newCards'] =>
	optimisticIssues.map((issue) => {
		const { assigneeAccountId, typeId, labels, parentId, summary } = issue;

		return {
			assigneeId: assigneeAccountId != null ? String(assigneeAccountId) : null,
			fixVersions: [],
			issueTypeId: typeId != null ? String(typeId) : '',
			labels,
			parentId: parentId != null ? String(parentId) : null,
			summary,
		};
	});

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	state: State,
	rankBeforeCardId: IssueId | null = null,
	optimisticIssues: Issue[],
	transitionInfo: CardTransitionInfo | null,
	swimlaneId?: string | null,
): ObservableType<Issue[]> => {
	const transitionInfoForNewIssues =
		transitionInfo ||
		(fg('use_backend_tmp_icc_config_')
			? transitionIDForCardCreateSelectorNew(state)(
					optimisticIssues[0].columnId,
					optimisticIssues[0].typeId,
				)
			: transitionIDForCardCreateSelector(state)(
					optimisticIssues[0].columnId,
					optimisticIssues[0].typeId,
				));

	const { sprintId = '' } = optimisticIssues?.[0] ?? {};

	return rest(contextPathSelector(state), {
		boardId: rapidViewIdSelector(state),
		cardTransitionId: transitionInfoForNewIssues?.transitionId || null,
		rankBeforeCardId,
		newCards: convertToNewCards(optimisticIssues),
		...(sprintId ? { destinationId: sprintId } : {}),
		requireJqlContext: getIsCustomBoardWithIcc(state),
		...(swimlaneId ? { swimlaneId } : {}),
	}).flatMap((result: BoardCardCreate) =>
		Observable.of(transformer(state, result, optimisticIssues, transitionInfoForNewIssues)),
	);
};
