import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import { REFRESH_SOURCE_FAILURE } from '../../model/constants.tsx';
import { boardRename } from '../../rest/board-rename-graphql/index.tsx';
import {
	RENAME_BOARD_REQUEST,
	renameBoardFailure,
	type RenameBoardRequestAction,
	renameBoardSuccess,
} from '../../state/actions/board/rename/index.tsx';
import { workRefreshData } from '../../state/actions/work/index.tsx';
import {
	contextPathSelector,
	rapidViewIdSelector,
} from '../../state/selectors/software/software-selectors.tsx';
import type { Action, ActionsObservable, MiddlewareAPI } from '../../state/types.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable, store: MiddlewareAPI) =>
	action$.ofType(RENAME_BOARD_REQUEST).switchMap((action: RenameBoardRequestAction) => {
		const state = store.getState();
		const {
			meta: {
				optimistic: { id: txId },
			},
			payload,
		} = action;

		const contextPath = contextPathSelector(state);
		const rapidViewId = rapidViewIdSelector(state);

		return boardRename(contextPath, rapidViewId, payload)
			.flatMap(() => Observable.of(renameBoardSuccess(txId)))
			.catch(() =>
				Observable.of(
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					renameBoardFailure(txId) as Action,
					workRefreshData(REFRESH_SOURCE_FAILURE),
				),
			);
	});
