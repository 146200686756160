import { createHook, createStore } from '@atlassian/react-sweet-state';
import type { FeatureStatus } from '../../common/types.tsx';
import { actions } from './actions.tsx';
import type { State } from './types.tsx';

const initialState: State = {
	features: [],
	isLoading: false,
	enablingFeatures: [],
	isFetchError: false,
	sidebarFeatureToggled: null,
};

const store = createStore<State, typeof actions>({
	name: 'feature-toggle',
	initialState,
	actions,
});

export const useFeature = createHook(store);

export const useEnablingFeature = createHook<State, typeof actions, boolean, { feature: string }>(
	store,
	{
		selector: (state, { feature }) => state.enablingFeatures.some((f) => f === feature),
	},
);

/**
 * @deprecated do not use - to be removed after feature toggle productionisation.
 */
export const useFeatureStatus = createHook<
	State,
	typeof actions,
	FeatureStatus | undefined,
	{ feature: string }
>(store, {
	selector: (state, { feature }) => state.features.find((f) => f.feature === feature)?.state,
});

export const useSidebarFeatureToggled = createHook<
	State,
	typeof actions,
	State['sidebarFeatureToggled'],
	// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	void
>(store, {
	selector: (state) => state.sidebarFeatureToggled,
});
