import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { GraphqlError } from '../../types.tsx';

type SwagErrorType = {
	message: string;
	graphQLErrors: GraphqlError[];
	networkError: FetchError | TypeError | null;
};

const generateErrorMessage = (err: SwagErrorType) => {
	let message = '';
	// If we have GraphQL errors present, add that to the error message.
	if (Array.isArray(err.graphQLErrors) && err.graphQLErrors.length !== 0) {
		err.graphQLErrors.forEach((graphQLError: GraphqlError) => {
			const errorMessage = graphQLError ? graphQLError.message : 'Error message not found.';
			message += `GraphQL error: ${errorMessage}\n`;
		});
	}

	if (err.networkError) {
		message += `Network error: ${err.networkError.message}\n`;
	}

	// strip newline from the end of the message
	message = message.replace(/\n$/, '');
	return message;
};

type Props = {
	graphQLErrors?: GraphqlError[];
	networkError?: FetchError | TypeError;
	errorMessage?: string;
	traceId?: string;
};

export class SwagError extends Error {
	graphQLErrors: GraphqlError[] = [];

	networkError: FetchError | TypeError | null = null;

	// SWAG trace id, extracted from "atl-traceid" response-header
	// Helps to find relevant error in SWAG logs
	traceId = '';

	constructor({ graphQLErrors, networkError, errorMessage, traceId = '' }: Props) {
		super(errorMessage);
		this.graphQLErrors = graphQLErrors || [];
		this.networkError = networkError || null;

		if (!errorMessage) {
			this.message = generateErrorMessage(this);
		} else {
			this.message = errorMessage;
		}

		this.name = this.constructor.name;
		this.traceId = traceId;
		this.cause = networkError || graphQLErrors?.[0];

		if (typeof Error.captureStackTrace === 'function') {
			Error.captureStackTrace(this, this.constructor);
		} else {
			this.stack = new Error(this.message).stack;
		}
	}

	skipSentry(): boolean {
		return this.networkError != null || this.graphQLErrors.length > 0;
	}
}

export const isJson = (value: string): boolean => {
	try {
		JSON.parse(value);
	} catch (e) {
		return false;
	}
	return true;
};

export const isFetchError = (error: unknown): error is FetchError =>
	error !== null && typeof error === 'object' && error instanceof FetchError;

export const isSwagError = (error: unknown): error is SwagError => {
	if (error !== null && typeof error === 'object' && error instanceof SwagError) {
		return 'graphQLErrors' in error || 'networkError' in error;
	}
	return false;
};
