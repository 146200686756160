import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';

export const FETCH_CARD_COVERS = 'state.actions.card-covers.FETCH_CARD_COVERS' as const;

type Meta = {
	analyticsEvent?: AnalyticsEvent;
};

export type FetchCardCoversAction = {
	type: typeof FETCH_CARD_COVERS;
	payload: IssueId[];
	meta?: Meta;
};

export const fetchCardCovers = (payload: IssueId[], meta?: Meta): FetchCardCoversAction => ({
	type: FETCH_CARD_COVERS,
	payload,
	meta,
});

export const FETCH_CARD_COVERS_FAILURE =
	'state.actions.card-covers.FETCH_CARD_COVERS_FAILURE' as const;

export type FetchCardCoversFailureAction = {
	type: typeof FETCH_CARD_COVERS_FAILURE;
	meta?: Meta;
};

export const fetchCardCoversFailure = (meta?: Meta): FetchCardCoversFailureAction => ({
	type: FETCH_CARD_COVERS_FAILURE,
	meta,
});

export type Action = FetchCardCoversAction | FetchCardCoversFailureAction;
