import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import { PACKAGE_NAME, TEAM_NAME } from '../../common/constants/index.tsx';
import { useIsTargetedForRaisingAwarenessOfImporter } from '../../controllers/is-targeted-for-raising-awareness-of-importer/index.tsx';
import type { ImportWorkButtonInternal as ImportWorkButtonInternalType } from './index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyImportWorkButtonInternal = lazy<typeof ImportWorkButtonInternalType>(
	() =>
		import(/* webpackChunkName: "async-onboarding-invites-import-work-button" */ './index').then(
			({ ImportWorkButtonInternal }) => ImportWorkButtonInternal,
		),
	{ ssr: false },
);

const ImportWorkButtonWithTargeting = () => {
	const isTargeted = useIsTargetedForRaisingAwarenessOfImporter();

	return isTargeted ? <LazyImportWorkButtonInternal /> : null;
};

export const ImportWorkButton = () => (
	<JSErrorBoundary
		id="importWorkButton"
		packageName={PACKAGE_NAME}
		teamName={TEAM_NAME}
		fallback="unmount"
	>
		<ImportWorkButtonWithTargeting />
	</JSErrorBoundary>
);
