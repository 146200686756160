import { ensureState } from 'redux-optimistic-ui';
import { createSelector } from 'reselect';
import { di } from 'react-magnetic-di';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import {
	SINGLE_CUSTOM_FIELD_TYPE_KEY,
	MULTI_CUSTOM_FIELD_TYPE_KEY,
	SYSTEM_LABEL_FIELD_ID,
	BOARD_SCOPE_FILTER_NONE,
	BOARD_SCOPE_FILTER_LABEL,
	BOARD_SCOPE_FILTER_SINGLE_SELECT,
	BOARD_SCOPE_FILTER_MULTI_SELECT,
	IP_BOARD_STORY_POINTS_PLANNING_UNIT,
} from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/constants.tsx';
import type { BoardScopeFilterType } from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/types.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import type { FieldEntry } from '@atlassian/jira-software-board-uif-types/src/index.tsx';
import {
	AGILITY_BOARD_TYPE,
	CMP_KANBAN_BOARD_TYPE,
	CMP_SCRUM_BOARD_TYPE,
	IP_BOARD_TIME_ESTIMATE_FIELD_ID,
	IP_BOARD_STORY_POINTS_FIELD_ID,
} from '../../../common/constants.tsx';
import type { BoardType } from '../../../model/board/board-types.tsx';
import type { ColumnId } from '../../../model/column/column-types.tsx';
import type {
	IssueProjectPermissionGroup,
	ProjectId,
	CardType,
} from '../../../model/software/software-types.tsx';
import type { VersionData } from '../../../model/version/version-types.tsx';
import type { ConfigState } from '../../reducers/entities/board/config/types.tsx';
import type { BoardState } from '../../reducers/entities/board/types.tsx';
import type { CardTransitionsState } from '../../reducers/entities/card-transitions/types.tsx';
import type { IncrementConfigState } from '../../reducers/entities/increment-config/types.tsx';
import type { IssueChildrenState } from '../../reducers/entities/issue-children/types.tsx';
import type { IssueLinkTypesState } from '../../reducers/entities/issue-link-types/types.tsx';
import type { IssueProjectsState } from '../../reducers/entities/issue-projects/types.tsx';
import type { IssueTransitionsState } from '../../reducers/entities/issue-transitions/types.tsx';
import type { IssueTypesByProjectState } from '../../reducers/entities/issue-types-by-project/types.tsx';
import type { IssueTypesState } from '../../reducers/entities/issue-types/types.tsx';
import type { IssuesState } from '../../reducers/entities/issues/types.tsx';
import type { RequestTypesState } from '../../reducers/entities/request-types/types.tsx';
import type { StatusesState } from '../../reducers/entities/statuses/types.tsx';
import type { TeamState } from '../../reducers/entities/teams/types.tsx';
import type { EntitiesState } from '../../reducers/entities/types.tsx';
import type { State } from '../../reducers/types.tsx';

export const getUi = (state: State) => ensureState(state.ui);

export const getEntities = (state: State): EntitiesState => ensureState(state.entities);
export const getIssueTypes = (state: State): IssueTypesState => getEntities(state).issueTypes;
export const getIssueLinkTypes = (state: State): IssueLinkTypesState =>
	getEntities(state).issueLinkTypes;
export const getIssueLinkTypeById = createSelector(
	[getIssueLinkTypes],
	(issueLinkTypes) => (linkTypeId: number | string) =>
		issueLinkTypes.find((type) => `${type.id}` === `${linkTypeId}`),
);

export const getProjectIssueTypes = (state: State): IssueTypesState =>
	getEntities(state).projectIssueTypes;
export const getIssueTypesByProject = (state: State): IssueTypesByProjectState =>
	getEntities(state).issueTypesByProject;
export const getIssueTypeInIPboard = createSelector(
	[getIssueTypesByProject],
	(issueTypesByProject) =>
		(projectId: number, issueTypeId: string): CardType | undefined => {
			const issueTypeArray = (issueTypesByProject[projectId] || []).filter(
				(t) => t.id === issueTypeId,
			);
			return issueTypeArray.length > 0 ? issueTypeArray[0] : undefined;
		},
);
export const getIssues = (state: State): IssuesState => getEntities(state).issues;

// not meant to export this function as it is the same as /selectors/issue-children/index.tsx@getIssueChildren
// it is used by getCanRankByIssue only
const getIssueChildren = (state: State): IssueChildrenState => getEntities(state).issueChildren;

export const getRequestTypes = (state: State): RequestTypesState => getEntities(state).requestTypes;

export const getStatuses = (state: State): StatusesState => getEntities(state).statuses;

export const getMissingParents = (state: State) => getEntities(state).missingParents;

export const getCardTransitions = (state: State): CardTransitionsState =>
	getEntities(state).cardTransitions;

export const getIssueTransitions = (state: State): IssueTransitionsState =>
	getEntities(state).issueTransitions;

export const getIssueProjects = (state: State): IssueProjectsState =>
	getEntities(state).issueProjects;

export const getTeams = (state: State): TeamState => getEntities(state).teams;

export const getIssueCount = createSelector([getIssues], (issues) => Object.keys(issues).length);

export const getIssueProjectsPermissionGroup = createSelector(
	[getIssueProjects],
	(projects): IssueProjectPermissionGroup => {
		const projectsWithCreatePermission: ProjectId[] = [];
		const projectsWithDeletePermission: ProjectId[] = [];

		Object.values(projects).forEach((project) => {
			const { id, permissions } = project;
			permissions?.CREATE_ISSUES && projectsWithCreatePermission.push(id);
			permissions?.DELETE_ISSUES && projectsWithDeletePermission.push(id);
		});

		return { projectsWithCreatePermission, projectsWithDeletePermission };
	},
);

export const getIssueProjectIds = createSelector([getIssueProjects], (projects) =>
	Object.keys(projects).map(Number),
);

export const hasMultipleProjects = (state: State): boolean =>
	Object.keys(getIssueProjects(state)).length > 1;

export const getVersionData = (state: State): VersionData => getEntities(state).versionData;

export const getBoardEntity = (state: State): BoardState => getEntities(state).board;
export const getOrderedColumnIds = (state: State): ColumnId[] => getBoardEntity(state).columns;
export const getOrderedIssueIds = (state: State): IssueId[] => getBoardEntity(state).issues;
export const getOrderedIssueChildrenIds = (state: State): IssueId[] =>
	getBoardEntity(state).issueChildren;
export const getIssueParentOrderIds = (state: State): IssueId[] =>
	getBoardEntity(state).issueParentOrder;
export const getBoardName = (state: State): string => getBoardEntity(state).config.name;

export const getAppConfiguration = (state: State) => state.configuration;

export const getLocale = (state: State) => getAppConfiguration(state).locale;

export const getBoardConfig = (state: State): ConfigState => getBoardEntity(state).config;
export const isBoardRankable = (state: State) => getBoardConfig(state).rankConfig.boardIsRankable;

export const getIsIncrementPlanningBoard = (state: State) =>
	getAppConfiguration(state).isIncrementPlanningBoard;

export const getIsIncrementPlanningBoardEmbed = () => {
	di(window);
	return (
		globalThis.window &&
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window?.location.pathname.includes('plans') &&
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window?.location.pathname.includes('scenarios') &&
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window?.location.pathname.includes('/embed')
	);
};

export const getCanInviteOthersToProject = (state: State) =>
	getAppConfiguration(state).canInviteOthersToProject;

export const getIncrementConfig = (state: State): IncrementConfigState =>
	getEntities(state).incrementConfig;

export const getPlanningUnit = createSelector(
	[getIncrementConfig],
	(incrementConfig) => incrementConfig?.planningUnit,
);

export const getIncrementPlanningEstimateFieldId = createSelector(
	[getPlanningUnit],
	(planningUnit) =>
		planningUnit === IP_BOARD_STORY_POINTS_PLANNING_UNIT
			? IP_BOARD_STORY_POINTS_FIELD_ID
			: IP_BOARD_TIME_ESTIMATE_FIELD_ID,
);

export const getScenarioColor = createSelector(
	[getIncrementConfig],
	(incrementConfig) => incrementConfig?.scenarioColor,
);

export const getBoardScopeFilter = createSelector(
	[getIncrementConfig],
	(incrementConfig) => incrementConfig?.boardScopeFilter,
);

export const getBoardScopeFilterType = createSelector(
	[getIncrementConfig],
	(incrementConfig: IncrementConfigState): BoardScopeFilterType => {
		const boardScopeFilter = incrementConfig?.boardScopeFilter;
		if (boardScopeFilter === undefined) {
			return BOARD_SCOPE_FILTER_NONE;
		}
		if (boardScopeFilter.filterFieldId === SYSTEM_LABEL_FIELD_ID) {
			return BOARD_SCOPE_FILTER_LABEL;
		}
		if (boardScopeFilter.filterFieldTypeKey === SINGLE_CUSTOM_FIELD_TYPE_KEY) {
			return BOARD_SCOPE_FILTER_SINGLE_SELECT;
		}
		if (boardScopeFilter.filterFieldTypeKey === MULTI_CUSTOM_FIELD_TYPE_KEY) {
			return BOARD_SCOPE_FILTER_MULTI_SELECT;
		}
		return BOARD_SCOPE_FILTER_NONE;
	},
);

export const getPlanId = (state: State) => getAppConfiguration(state).planId;

export const getScenarioId = (state: State) => getAppConfiguration(state).scenarioId;

export const getIsCMPBoard = (state: State) => getAppConfiguration(state)?.isCMPBoard === true;

export const getIsJSWBoard = (state: State) =>
	getAppConfiguration(state)?.projectType === SOFTWARE_PROJECT;

export const getCanEditBoard = (state: State) => getBoardEntity(state).permissions.editBoardConfig;

// CMP -> SCRUM/KANBAN, TMP -> undefined
export const getBoardType = (state: State) => getAppConfiguration(state)?.boardType;

export const getBoardTypeForAnalytics = (state: State): BoardType => {
	if (getIsCMPBoard(state)) {
		const cmpBoardType = getAppConfiguration(state)?.boardType?.toLowerCase();
		if (cmpBoardType === 'scrum') {
			return CMP_SCRUM_BOARD_TYPE;
		}
		if (cmpBoardType === 'kanban') {
			return CMP_KANBAN_BOARD_TYPE;
		}
	}
	return AGILITY_BOARD_TYPE;
};

export const getHierarchyNomenclature = (state: State) => state.configuration.hierarchyNomenclature;

export const contextPathSelector = createSelector(
	[getAppConfiguration],
	(configuration) => configuration.contextPath,
);

export const rapidViewIdSelector = createSelector(
	[getAppConfiguration],
	(configuration) => configuration.rapidViewId,
);

export const currentUserAccountIdSelector = createSelector(
	[getAppConfiguration],
	(configuration) => configuration.userAccountId,
);

export const projectKeySelector = createSelector(
	[getAppConfiguration],
	(configuration) => configuration.projectKey,
);

export const projectIdSelector = createSelector(
	[getAppConfiguration],
	(configuration) => configuration.projectId,
);

export const projectNameSelector = createSelector(
	[getAppConfiguration],
	(configuration) => configuration.projectName,
);

export const cloudIdSelector = createSelector(
	[getAppConfiguration],
	(configuration) => configuration.cloudId,
);

export const activationIdSelector = createSelector(
	[getAppConfiguration],
	(configuration) => configuration.activationId,
);

export const sessionIdSelector = createSelector(
	[getAppConfiguration],
	(configuration) => configuration.sessionId,
);

export const projectTypeSelector = createSelector(
	[getAppConfiguration],
	(configuration) => configuration.projectType,
);

// This function is used for CMP only as the TMP check over limit error
// at @atlassian/jira-spa-apps-next-gen-board which is before the board loaded.
export const getIssueLimitExceeded = createSelector(
	[getIsCMPBoard, getAppConfiguration],
	(isCMPBoard, configuration) => isCMPBoard && Boolean(configuration.issueLimitExceeded),
);

export const getSavedFilterId = createSelector(
	[getAppConfiguration],
	(configuration) => configuration.savedFilterId,
);

export const getCanRankByIssue = createSelector(
	[isBoardRankable, getIssueProjects, getIssues, getIssueChildren, getIsCMPBoard],
	(isRankable, issueProjects, allIssues, issueChildren, isCMPBoard) =>
		(issueId: IssueId): boolean => {
			// TMP is always rankable
			if (!isCMPBoard) {
				return true;
			}

			if (!isRankable) {
				return false;
			}

			// find the issue from all issues and issue children
			const issue = allIssues[issueId] || issueChildren[issueId];
			if (!issue) {
				return false;
			}

			const project = issueProjects[issue.projectId];
			return Boolean(project?.canRank);
		},
);

export const getIsDaysInColumnEnabled = createSelector([getIssues], (allIssues) =>
	Object.values(allIssues).some((issue) => Boolean(issue.daysInColumn)),
);

export const getIsEstimateEnabled = createSelector([getIssues], (allIssues) =>
	Object.values(allIssues).some((issue) => Boolean(issue?.estimate?.value)),
);

export const getIsPriorityEnabled = createSelector([getIssues], (allIssues) =>
	Object.values(allIssues).some((issue) => Boolean(issue?.priority)),
);

export const getIsDevelopmentEnabled = createSelector([getIssues], (allIssues) =>
	Object.values(allIssues).some((issue) => Boolean(issue?.devStatus)),
);

const hasDueDateSet = (allIssues: IssuesState) =>
	Object.values(allIssues).some((issue) => Boolean(issue.dueDate));

// this determines whether to show the view settings due date toggle.
// copying logic for CMP JSW boards from view/board-container/board/card-container/card/view.tsx.
export const getIsDueDateEnabled = createSelector(
	[getIssues, getIsCMPBoard, getIsJSWBoard, getIsIncrementPlanningBoard],
	(allIssues, isCMPBoard, isJSWBoard, isIncrementPlanningBoard) => {
		if (fg('jsw_cmp_show_due_date_by_default')) {
			return (
				(!isCMPBoard && hasDueDateSet(allIssues)) ||
				(isCMPBoard &&
					isJSWBoard &&
					!isIncrementPlanningBoard &&
					hasDueDateSet(allIssues) &&
					expVal('jsw_cmp_show_due_date_by_default_experiment', 'isDueDateFeatureEnabled', false))
			);
		}
		return !isCMPBoard && Object.values(allIssues).some((issue) => Boolean(issue.dueDate));
	},
);

export const getIsIssueLabelsEnabled = createSelector(
	[getIssues, getIsCMPBoard],
	(allIssues, isCMPBoard) =>
		!isCMPBoard && Object.values(allIssues).some((issue) => Boolean(issue.labels?.length > 0)),
);
export const getCardExtraFields = createSelector([getIssues], (allIssues) => {
	const extraFieldsMap = new Map<string, FieldEntry>();

	Object.values(allIssues).forEach((issue) => {
		issue.extraFields?.forEach((field) => extraFieldsMap.set(field.id, field));
	});

	return Array.from(extraFieldsMap.values());
});

export const getFirstNonEpicIssueInFirstColumn = createSelector(
	[getIssues, getOrderedColumnIds, getOrderedIssueIds],
	(allIssues, orderedColumnIds, orderedIssueIds) => {
		const issueVals = Object.keys(allIssues);
		if (
			!orderedColumnIds ||
			orderedColumnIds.length === 0 ||
			!allIssues ||
			issueVals.length === 0
		) {
			return undefined;
		}

		const firstColumnId = orderedColumnIds[0];
		const firstIssueId = orderedIssueIds.find(
			(issueId) => allIssues[issueId].columnId === firstColumnId,
		);
		if (firstIssueId) {
			return allIssues[firstIssueId].key;
		}

		return undefined;
	},
);
