import parameters from '@atlassian/jira-relay/src/__generated__/srcAsyncIssueLinksDetailsContentQuery$parameters';
import type { srcAsyncIssueLinksDetailsContentQuery$variables } from '@atlassian/jira-relay/src/__generated__/srcAsyncIssueLinksDetailsContentQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const issueLinksDetailsContentEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-issue-links-details-content-entrypoint" */ './src'),
	),
	getPreloadProps: ({
		cloudId,
		issueKey,
		issueCount,
		withDueDate,
		fields,
	}: srcAsyncIssueLinksDetailsContentQuery$variables) => ({
		queries: {
			issueLinksDetailsPopup: {
				// policy can be updated to store-or-network once we have issue linking mutation through relay
				options: { fetchPolicy: 'store-and-network' as const },
				parameters,
				variables: {
					cloudId,
					issueKey,
					issueCount,
					withDueDate,
					fields,
				},
			},
		},
	}),
});
