import React from 'react';
import {
	ENTRYPOINT_ID_JIRA_SOFTWARE_PROJECT,
	EntryKeys,
} from '@atlassian/jira-feedback-collector/src/constants.tsx';
import { FeedbackCollectorButton } from '@atlassian/jira-feedback-collector/src/ui/button/index.tsx';
import type { ButtonAppearance } from '@atlassian/jira-feedback-collector/src/ui/button/types.tsx';

export const LinkIssueFeedbackCollector = ({
	feedbackCollectorLocation,
	appearance,
}: {
	feedbackCollectorLocation: string;
	appearance?: ButtonAppearance;
}) => {
	const additionalFields = [
		{
			id: EntryKeys.FEEDBACK_COLLECTOR_LOCATION,
			value: feedbackCollectorLocation,
		},
	];

	return (
		<FeedbackCollectorButton
			entrypointId={ENTRYPOINT_ID_JIRA_SOFTWARE_PROJECT}
			feedbackGroupId={feedbackCollectorLocation}
			additionalFields={additionalFields}
			buttonAppearance={appearance}
		/>
	);
};
