import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	delete: {
		id: 'software.main.board-container.board.column.header.menu.item-delete.delete',
		defaultMessage: 'Delete',
		description: 'Menu item text for the Delete column option in the column menu on Agility boards',
	},
	lastColumn: {
		id: 'software.main.board-container.board.column.header.menu.item-delete.last-column',
		defaultMessage: "The last column can't be deleted",
		description: '',
	},
});
