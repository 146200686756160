export const ITEM_LINE_HEIGHT = 32;
export const MAX_VIEWPORT_ITEMS_IN_SEARCH = 5;
export const DEBOUNCE_DELAY = 300;
export const DEFAULT_MENU_WIDTH = 200;
export const MENU_MAX_HEIGHT = 500;

export const CONTEXT_MENU_INNER_CONTAINER_TESTID =
	'software-context-menu.ui.context-menu-inner.context-menu-inner-container';

export const CONTEXT_MENU_SUBMENU_TESTID =
	'software-context-menu.ui.context-menu-inner.context-menu-node.context-menu-item.context-menu-submenu';

export const CONTEXT_MENU_ITEM_CHEVRON_TESTID =
	'software-context-menu.ui.menu-renderer.context-menu-inner.context-menu-node.context-menu-item.chevron-icon' as const;
export const CONTEXT_MENU_ITEM_TESTID =
	'software-context-menu.ui.context-menu-inner.context-menu-node.context-menu-item.context-menu-item';

export const CONTEXT_MENU_ITEM_OVERFLOW_VISIBLE_CONTAINER_TESTID =
	'software-context-menu.ui.menu-renderer.context-menu-inner.context-menu-node.context-menu-item.overflow-visible-popup-container' as const;

export const CONTEXT_MENU_ITEM_TOOLTIP_TESTID =
	'software-context-menu.ui.menu-renderer.context-menu-inner.context-menu-node.context-menu-item.tooltip' as const;
export const CONTEXT_MENU_ITEM_LABEL_TESTID =
	'software-context-menu.ui.menu-renderer.context-menu-inner.context-menu-node.context-menu-item.context-menu-label' as const;

export const CONTEXT_MENU_SEARCH_TESTID =
	'software-context-menu.ui.context-menu-inner.context-menu-node.context-menu-search';
export const CONTEXT_MENU_SEARCH_CONTENT_TESTID =
	'software-context-menu.ui.context-menu-inner.context-menu-node.context-menu-search.content';
export const CONTEXT_MENU_SEARCH_LOADING_TESTID =
	'software-context-menu.ui.context-menu-inner.context-menu-node.context-menu-search.loading';
export const CONTEXT_MENU_SEARCH_NO_RESULTS_TESTID =
	'software-context-menu.ui.context-menu-inner.context-menu-node.context-menu-search.no-results';

export const CONTEXT_MENU_APPEARANCE = 'context' as const;
export const MEATBALL_MENU_APPEARANCE = 'meatball' as const;
