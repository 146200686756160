import { freeze, merge } from 'icepick';
import { entityArrayToMap } from '../../../../services/software/software-data-transformer.tsx';
import { CARD_DATA_SET, type CardDataSetAction } from '../../../actions/card/index.tsx';
import { PERSON_SET } from '../../../actions/people/index.tsx';
import { WORK_DATA_SET, WORK_DATA_CRITICAL_SET } from '../../../actions/work/index.tsx';
import type { Action } from '../../../types.tsx';
import type { PeopleState } from './types.tsx';

export const peopleReducer = (state: PeopleState = freeze({}), action: Action): PeopleState => {
	if (action.type === WORK_DATA_SET || action.type === WORK_DATA_CRITICAL_SET) {
		return merge(state, entityArrayToMap(action.payload.people));
	}

	if (action.type === PERSON_SET) {
		return merge(state, { [action.payload.id]: action.payload });
	}

	if (action.type === CARD_DATA_SET) {
		const {
			payload: { person },
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		} = action as CardDataSetAction;

		if (person) {
			return merge(state, { [person.id]: person });
		}
	}

	return state;
};
