import { createSelector } from 'reselect';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { getBoardCardCount } from '../../../services/performance/board-data-collector.tsx';
import { getIsCMPBoard } from '../../../state/selectors/software/software-selectors.tsx';
import type { State } from '../../../state/types.tsx';
import IssueWrapper, { type OwnProps, type StateProps } from './view.tsx';

const getBoardCardCountMemoized = () => createSelector(getBoardCardCount, (res) => res);

const mapStateToPropsFactory = () => {
	const getBoardCardCountInstance = getBoardCardCountMemoized();
	return (state: State): StateProps => ({
		boardCardCount: getBoardCardCountInstance(state),
		isCMPBoard: getIsCMPBoard(state),
	});
};

const ConnectedIssueWrapper = connect<StateProps, {}, OwnProps, State>(
	mapStateToPropsFactory,
	{},
)(IssueWrapper);

export default ConnectedIssueWrapper;
