import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';

export const FILTERED_CARD_SUCCESS = 'state.actions.card.filtered-cards.FILTERED_CARD_SUCCESS';
export type FilteredCardSuccessAction = {
	type: typeof FILTERED_CARD_SUCCESS;
	payload: string[] | null;
	meta: {
		numCustomFiltersApplied: number;
		numIssueIds: number;
		analyticsEvent?: UIAnalyticsEvent;
	};
};

export const filteredCardsSuccess = (
	cardIds: string[] | null,
	numCustomFiltersApplied = 0,
	numIssueIds = 0,
	analyticsEvent?: UIAnalyticsEvent,
): FilteredCardSuccessAction => ({
	type: FILTERED_CARD_SUCCESS,
	payload: cardIds,
	meta: {
		analyticsEvent,
		numIssueIds,
		numCustomFiltersApplied,
	},
});

export const FILTERED_CARD_CLEAR = 'state.actions.card.filtered-cards.FILTERED_CARD_CLEAR';
export type FilteredCardClearAction = {
	type: typeof FILTERED_CARD_CLEAR;
};

export const clearFilteredCards = (): FilteredCardClearAction => ({
	type: FILTERED_CARD_CLEAR,
});

export const REFILTER_SUCCESS = 'state.actions.card.filtered-cards.REFILTER_SUCCESS';
export type RefilterSuccessAction = {
	type: typeof REFILTER_SUCCESS;
	payload: {
		excludedCardIds: string[];
		filteredCardIds: string[];
	};
};

export const refilterSuccess = (
	excludedCardIds: string[],
	filteredCardIds: string[],
): RefilterSuccessAction => ({
	type: REFILTER_SUCCESS,
	payload: { excludedCardIds, filteredCardIds },
});

export const REFILTER_FAILURE = 'state.actions.card.filtered-cards.REFILTER_FAILURE';
export type RefilterFailureAction = {
	type: typeof REFILTER_FAILURE;
	payload: {
		error?: Error;
	};
};

export const refilterFailure = (error?: Error): RefilterFailureAction => ({
	type: REFILTER_FAILURE,
	payload: { error },
});

export const FILTERED_CARD_FAILURE = 'state.actions.card.filtered-cards.FILTERED_CARD_FAILURE';
export type FilteredCardFailureAction = {
	type: typeof FILTERED_CARD_FAILURE;
	payload: {
		error?: Error;
	};
	meta: {
		analyticsEvent?: UIAnalyticsEvent;
	};
};

export const filteredCardsFailed = (
	error?: Error,
	analyticsEvent?: UIAnalyticsEvent,
): FilteredCardFailureAction => ({
	type: FILTERED_CARD_FAILURE,
	payload: {
		error,
	},
	meta: { analyticsEvent },
});

export type Action =
	| FilteredCardSuccessAction
	| FilteredCardClearAction
	| RefilterSuccessAction
	| RefilterFailureAction
	| FilteredCardFailureAction;
