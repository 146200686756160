import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	spotlightHeading: {
		id: 'software-onboarding-nudges--next.sample-project-data.spotlight-heading',
		defaultMessage: 'Let’s start with your tasks',
		description:
			'Spotlight heading that will show up to users enrolled in sample data crawl phase experiment. This Nudge guides the user to checkout out the pre-populated Jira tasks.',
	},
	spotlightContent: {
		id: 'software-onboarding-nudges--next.sample-project-data.spotlight-content',
		defaultMessage:
			'We’ve created some starter tasks for you. Make them your own, or create a blank task to start tracking your work in Jira.',
		description:
			'Spotlight content that will show up to users enrolled in sample data crawl phase experiment. This Nudge guides the user to checkout out the pre-populated Jira tasks.',
	},
	spotlightDismissAction: {
		id: 'software-onboarding-nudges--next.sample-project-data.spotlight-dismiss-action',
		defaultMessage: 'Dismiss',
		description: 'Spotlight content for action that will dismiss the nudge',
	},
	spotlightCtaAction: {
		id: 'software-onboarding-nudges--next.sample-project-data.spotlight-cta-action',
		defaultMessage: 'View task',
		description: 'Spotlight content for action that will open up the pre-populated task',
	},
});
