import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	header: {
		id: 'software-board-size-limit-error-empty-state.header',
		defaultMessage: 'Your board has too many issues',
		description:
			'Message shown when a next-gen board/backlog would fail to load due to having 5000+ issues',
	},
	goToSearch: {
		id: 'software-board-size-limit-error-empty-state.go-to-search',
		defaultMessage: 'See issues in search',
		description: 'Go to search when board size is 5000+',
	},
	description: {
		id: 'software-board-size-limit-error-empty-state.description',
		defaultMessage:
			'Boards can only display up to 5,000 issues at a time. Move or delete issues to continue using your board.',
		description: 'Description of the 5000+ issues board size limit error.',
	},
	descriptionCmp: {
		id: 'software-board-size-limit-error-empty-state.description-cmp',
		defaultMessage:
			"Boards can only display up to 5,000 issues at a time. Try editing your board's filter query to include fewer issues, or create an additional board.",
		description:
			'Description of the 5000+ issues board size limit error on a company managed board.',
	},
	editBoardFilter: {
		id: 'software-board-size-limit-error-empty-state.edit-board-filter',
		defaultMessage: 'Edit board filter',
		description: 'Go to board filter page',
	},
	headerIssueTermRefresh: {
		id: 'software-board-size-limit-error-empty-state.header-issue-term-refresh',
		defaultMessage: 'Your board has too many work items',
		description:
			'Message shown when a next-gen board/backlog would fail to load due to having 5000+ issues',
	},
	goToSearchIssueTermRefresh: {
		id: 'software-board-size-limit-error-empty-state.go-to-search-issue-term-refresh',
		defaultMessage: 'View everything in search',
		description: 'Go to search when board size is 5000+',
	},
	descriptionIssueTermRefresh: {
		id: 'software-board-size-limit-error-empty-state.description-issue-term-refresh',
		defaultMessage:
			'Boards can only display up to 5,000 work items at a time. Move or delete work to continue using your board.',
		description: 'Description of the 5000+ issues board size limit error.',
	},
	descriptionCmpIssueTermRefresh: {
		id: 'software-board-size-limit-error-empty-state.description-cmp-issue-term-refresh',
		defaultMessage:
			"Boards can only display up to 5,000 work items at a time. Try editing your board's filter query to include fewer work items, or create an additional board.",
		description:
			'Description of the 5000+ issues board size limit error on a company managed board.',
	},
});
export default messages;
