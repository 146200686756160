import forbidden from './forbidden/index.tsx';
import genericError from './generic-error/index.tsx';
import serverError from './server-error/index.tsx';
import unauthorized from './unauthorized/index.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	forbidden,
	genericError,
	serverError,
	unauthorized,
};
