import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { useFirstActivationDateMs } from '@atlassian/jira-tenant-context-controller/src/components/first-activation-date-ms/index.tsx';

export const useShouldApplyFeatureToggle = () => {
	const { data: project } = useProjectContext();
	const firstActivationDateMs = useFirstActivationDateMs() || 0;

	const isJSW = project?.projectType === SOFTWARE_PROJECT;
	const isTMP = !!project?.simplified;
	const isProjectAdmin = !!project?.isProjectAdmin;
	const isNewInstance = new Date(firstActivationDateMs) >= new Date('2023-09-25T15:00:00');

	return isJSW && isTMP && isProjectAdmin && isNewInstance;
};
