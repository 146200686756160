import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	presenceToolTipOnline: {
		id: 'software-filters.common.presence-tooltip.presence-tool-tip-online',
		defaultMessage: '{name} is online',
		description: 'The tooltip text for the avatar when the user is online.',
	},
	presenceToolTipOffline: {
		id: 'software-filters.common.presence-tooltip.presence-tool-tip-offline',
		defaultMessage: '{name} is offline',
		description: 'The tooltip text for the avatar when the user is offline.',
	},
});
