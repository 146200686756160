import { freeze, merge } from 'icepick';
import { entityArrayToMap } from '../../../../services/software/software-data-transformer.tsx';
import { STATUS_CATEGORY_LOAD_SUCCESS } from '../../../actions/status-category/index.tsx';
import type { Action } from '../../../types.tsx';
import type { StatusCategoriesState } from './types.tsx';

export const statusCategoriesReducer = (
	state: StatusCategoriesState = freeze({}),
	action: Action,
): StatusCategoriesState => {
	if (action.type === STATUS_CATEGORY_LOAD_SUCCESS) {
		const { statusCategories } = action.payload;
		return merge(state, entityArrayToMap(statusCategories));
	}

	return state;
};
