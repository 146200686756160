import React, { memo, useCallback, useEffect, useMemo } from 'react';
import noop from 'lodash/noop';
import { useIntl } from '@atlassian/jira-intl';
import {
	type AnalyticsAttributes,
	fireTrackAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import type {
	SprintFilter as SprintFilterType,
	SprintId,
	CheckboxSelectEntry,
} from '../../../common/types.tsx';
import ListFilter from '../../../common/ui/list/index.tsx';
import messages from './messages.tsx';

type Props = {
	sprints: SprintFilterType[];
	selectedSprints?: SprintId[];
	onChange: (sprintIds: SprintId[], attributes?: AnalyticsAttributes) => void;
	isNested?: boolean;
};

const SprintFilter = ({
	sprints = [],
	selectedSprints = [],
	onChange = noop,
	isNested = false,
}: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		fireTrackAnalytics(createAnalyticsEvent({}), 'sprintFilter rendered', {
			numberOfSprints: sprints.length,
		});
	}, [createAnalyticsEvent, sprints]);

	const values = useMemo(
		(): CheckboxSelectEntry[] =>
			sprints.map((sprint) => ({
				label: sprint.name,
				value: sprint.id.toString(),
			})),
		[sprints],
	);

	const onChangeMapValues = useCallback(
		(entries: CheckboxSelectEntry[]) => {
			const analyticAttributes = { isNested };
			onChange(
				entries.map((entry) => entry.value),
				analyticAttributes,
			);
		},
		[onChange, isNested],
	);

	return (
		<ListFilter
			label={formatMessage(messages.label)}
			values={values}
			selectedValues={selectedSprints}
			onChange={onChangeMapValues}
			isNested={isNested}
		/>
	);
};

const SprintFilterComponent = memo<Props>(SprintFilter);

SprintFilterComponent.displayName = 'SprintFilter';

export default SprintFilterComponent;
