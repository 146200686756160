/**
 * @generated SignedSource<<af679ec527d1981dcdaff0e5cc594ad9>>
 * @relayHash 0014097d1ce5fa1efc5b91a05152d101
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 45a6c6bb5a98d9a9c3dbcd9f289262fb7d192b97d36ffaa9a2819f875e9e95f4

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { uiDevOpsBoardQuery } from './uiDevOpsBoardQuery.graphql';

const node: PreloadableConcreteRequest<uiDevOpsBoardQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "45a6c6bb5a98d9a9c3dbcd9f289262fb7d192b97d36ffaa9a2819f875e9e95f4",
    "metadata": {},
    "name": "uiDevOpsBoardQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
