/**
 * All API calls in the confluence macro need to be wrapped in a proxy if the Jira and Confluence have different base urls.
 * Note this should be used for api calls only, not for links.
 */
export const proxyContextSafeUrl = (url: string) =>
	`${
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.integrationApiUrl ||
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		(window.AJS && window.AJS.contextPath && window.AJS.contextPath()) ||
		''
	}${url}`;
