import { type DependencyList, useEffect, useRef } from 'react';

export type UseValueChangeEffectArgs<V> = {
	listener: V;
	callback: (current: V, previous: V) => void;
};

/**
 * Listens for changes to a given value, and triggers a callback when a change is detected
 *
 * @param options.listener the value you wish to listen for
 * @param options.callback the function that is called when the value changes, will receive the new and previous value as arguments
 * @param deps an array of dependencies that your callback is reliant upon.
 * @returns void
 */
export const useValueChangeEffect = <V,>(
	{ listener, callback }: UseValueChangeEffectArgs<V>,
	deps: DependencyList = [],
): void => {
	const previous = useRef<V>(listener);
	useEffect(() => {
		if (listener !== previous.current) {
			callback(listener, previous.current);
		}

		previous.current = listener;

		/* We do not memoize the callback as this will change every render
		 * But it is important to pass in all dependencies used in the callback */

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listener, ...deps]);
};
