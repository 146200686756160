@container card-content-container (width < 224px) {
	.customCardFieldList.customCardFieldList {
		font-size: 12px;
	}

	img {
		max-width: 100%;
	}

	.avatarContainer.avatarContainer {
		height: 24px;
		width: 24px;
	}

	.summary.summary {
		font-size: 14px;
		/* stylelint-disable-next-line value-no-vendor-prefix */
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;

		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
	}

	.cardColorInner.cardColorInner {
		width: 2px;
	}

	.footer.footer {
		grid-template-columns: minmax(0, 1fr) auto;
		grid-template-rows: 1fr 1fr;
		grid-template-areas:
			'tertiary tertiary'
			'primary secondary';
	}

	.content.content {
		padding: 8px;
	}

	/* Everything below here is for the lite card */
	.footerChildSection.footerChildSection {
		gap: 4px;
	}

	.cmpAvatar.cmpAvatar {
		height: 24px;
		width: 24px;
	}

	.iconsPlaceHolder.iconsPlaceHolder {
		width: 100%;
		height: 24px;
		/* keep the value the same as the min-height at src/packages/platform/ui/card/src/ui/card/card-content/styled.tsx:FooterTertiarySection */
		display: block;
	}
}
