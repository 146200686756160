import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	boardWithUnmappedColumnFlagTitle: {
		id: 'spa-apps.next-gen-board.board.board-with-router-cmp.board-with-unmapped-column-flag-title',
		defaultMessage: "We couldn't load your board",
		description: 'Title for error flag that is shown if board has columns with unmapped status',
	},
	boardWithUnmappedColumnFlagDescriptionForUser: {
		id: 'spa-apps.next-gen-board.board.board-with-router-cmp.board-with-unmapped-column-flag-description-for-user',
		defaultMessage:
			"The board doesn't have any statuses mapped to its columns. Ask your admin to map statuses to columns in board settings.",
		description:
			'Content of the flag that is shown to an end user (non-admin) if board has columns with unmapped status',
	},
	boardWithUnmappedColumnFlagDescriptionForAdmin: {
		id: 'spa-apps.next-gen-board.board.board-with-router-cmp.board-with-unmapped-column-flag-description-for-admin',
		defaultMessage:
			"The board doesn't have any statuses mapped to its columns. Ensure that at least 1 status is mapped to a column.",
		description:
			'Content of the flag that is shown to an admin if board has columns with unmapped status',
	},
	navigateToBoardSettingsCta: {
		id: 'spa-apps.next-gen-board.board.board-with-router-cmp.navigate-to-board-settings-cta',
		defaultMessage: 'Board settings',
		description:
			'Action button for error flag that is shown if board has columns with unmapped status',
	},
});
