import React from 'react';
import CustomIcon, {
	SVG,
	type CustomIconProps,
} from '../../../../../common/ui/custom-icon/index.tsx';

/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
const TransitionGlyph: React.FunctionComponent = () => (
	<SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24px" width="24px">
		<g fill="#000" fillRule="nonzero">
			<path d="M5 2a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0 2a1 1 0 1 1 0 2 1 1 0 0 1 0-2ZM19 16a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0 2a1 1 0 1 1 0 2 1 1 0 0 1 0-2Z" />
			<path d="M10 4a3 3 0 0 1 2.995 2.824L13 7v10a1 1 0 0 0 .883.993L14 18h3v2h-3a3 3 0 0 1-2.995-2.824L11 17V7a1 1 0 0 0-.883-.993L10 6H7V4h3Z" />
		</g>
	</SVG>
);
/* eslint-enable @atlaskit/design-system/ensure-design-token-usage */

const TransitionIcon: React.FunctionComponent<CustomIconProps> = (props) => (
	<CustomIcon {...props} glyph={() => <TransitionGlyph />} />
);

export default TransitionIcon;
