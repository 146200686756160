import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import type { GoalState } from '@atlassian/jira-shared-types/src/goal.tsx';
import {
	mapStateToBgColor,
	mapStateToColor,
	extractStatusLabelWithoutScore,
} from '../../../common/utils.tsx';
import { Score } from './score/index.tsx';

export const GoalTracker = ({ value, label, score }: GoalState) => (
	<Tracker backgroundColor={mapStateToBgColor(value)} color={mapStateToColor(value)}>
		{extractStatusLabelWithoutScore(label)}
		{score !== undefined && score !== null && <Score score={score} />}
	</Tracker>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const Tracker = styled.div<{ backgroundColor: string; color: string }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ backgroundColor }) => backgroundColor,
	borderRadius: '3px',
	padding: token('space.050', '4px'),
	font: token('font.body.small'),
	fontWeight: token('font.weight.bold'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: ({ color }) => color,
	textTransform: 'uppercase',
	textWrap: 'nowrap',
	display: 'block',
	height: '16px',
	lineHeight: '16px',
});
