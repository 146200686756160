import React from 'react';
import { styled } from '@compiled/react';
import Lozenge from '@atlaskit/lozenge';

import ToolTip from '@atlaskit/tooltip';

import { borderRadius, gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import type { Color, ColorSchema } from '@atlassian/jira-issue-epic-color/src/common/types.tsx';
import { transformColorToTheme } from '@atlassian/jira-issue-epic-color/src/common/utils.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { PARENT_SWITCHER_LOZENGE_TEST_ID } from '../../constants.tsx';
import { getLozengeThemeByColor } from '../../utils.tsx';
import type { EpicLozengeProps } from './types.tsx';

export const EpicLozenge = ({ color, summary, maxWidth, isCompactView }: EpicLozengeProps) => {
	const paletteColorValues: Record<Color, string> = transformColorToTheme(
		(colorSchema: ColorSchema) => colorSchema.primary,
	);
	const selectedColor = (color && paletteColorValues[color]) || '';

	return isCompactView ? (
		<ToolTip content={summary}>
			{selectedColor && (
				<ColorIndicator
					data-component-selector="parent-switcher-epic-lozenge-wrapper"
					color={selectedColor}
				/>
			)}
		</ToolTip>
	) : (
		<LozengeWrapper
			data-component-selector="parent-switcher-epic-lozenge-wrapper"
			data-testid={PARENT_SWITCHER_LOZENGE_TEST_ID}
		>
			<ToolTip content={summary} hideTooltipOnClick>
				<Lozenge
					// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					style={getLozengeThemeByColor(color)}
					maxWidth={maxWidth}
					testId="issue-field-parent-switcher.common.ui.epic-lozenge.lozenge"
				>
					{summary}
				</Lozenge>
			</ToolTip>
		</LozengeWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LozengeWrapperOld = styled.div({
	display: 'inline-flex',
	alignItems: 'center',
	maxWidth: '100%',
	lineHeight: 1,

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div': {
		maxWidth: '100%',

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& > span': {
			/* This is the lozenge element. It's styled as an inline-flex in atlaskit. So, it's not perfectly vertically centerable without this */

			verticalAlign: 'top', // not perfectly vertically centered without this
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LozengeWrapperNew = styled.div({
	display: 'inline-flex',
	alignItems: 'center',
	maxWidth: '100%',
	lineHeight: 1,

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div': {
		maxWidth: '100%',

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& > span': {
			/* This is the lozenge element. It's styled as an inline-flex in atlaskit. So, it's not perfectly vertically centerable without this */

			verticalAlign: 'top', // not perfectly vertically centered without this
			border: 'none',
		},
	},
});

const LozengeWrapper = componentWithCondition(
	isVisualRefreshEnabled,
	LozengeWrapperNew,
	LozengeWrapperOld,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ColorIndicator = styled.div({
	flexShrink: '0',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 2}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 2}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius}px`,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	background: (props: any) => props.color,
	margin: 0,
});
