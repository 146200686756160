import React from 'react';
import { useFormState } from '@atlaskit/form';
import { Text } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { formatDateWithIntl } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/format.tsx';
import messages from './messages.tsx';

const CalculatedBoardEndDate = () => {
	const intl = useIntl();
	const formState = useFormState({
		values: true,
		pristine: true,
		dirty: true,
	});

	const columnDuration = formState?.values?.iterationLength?.value;
	const numberOfColumns = formState?.values?.iterationsCount?.value;
	const start = formState?.values?.start;

	const getEndDate = () => {
		if (!columnDuration || !numberOfColumns || !start) {
			return null;
		}
		const startDate = new Date(start);
		const programDurationMS = columnDuration * numberOfColumns * 7 * 24 * 60 * 60 * 1000;
		return formatDateWithIntl(intl, startDate.getTime() + programDurationMS);
	};

	return (
		columnDuration &&
		numberOfColumns &&
		start && (
			<Text size="small" as="p">
				{intl.formatMessage(messages.estimatedEndDate)}{' '}
				<Text weight="semibold" size="small">
					{getEndDate()}
				</Text>
			</Text>
		)
	);
};

export default CalculatedBoardEndDate;
