import React, { memo, useCallback, useMemo } from 'react';
import noop from 'lodash/noop';
import { useIntl } from '@atlassian/jira-intl';
import type { AnalyticsAttributes } from '@atlassian/jira-product-analytics-bridge';
import { type ComponentId, toStringArray } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Component, CheckboxSelectEntry } from '../../../common/types.tsx';
import ListFilter from '../../../common/ui/list/index.tsx';
import messages from './messages.tsx';

type Props = {
	components: Component[];
	selectedComponents?: ComponentId[];
	onChange: (arg1: string[], attributes?: AnalyticsAttributes) => void;
	isNested?: boolean;
};

const ComponentFilter = ({
	components = [],
	selectedComponents = [],
	onChange = noop,
	isNested = false,
}: Props) => {
	const { formatMessage } = useIntl();

	const values = useMemo(
		(): CheckboxSelectEntry[] =>
			components.map((component) => ({
				label: component.name,
				// Casting to string normalises the id that may potentially be a number.
				value: String(component.id),
			})),
		[components],
	);

	const onChangeCallback = useCallback(
		(entries: CheckboxSelectEntry[]) => {
			const analyticAttributes = { isNested };
			onChange(
				entries.map(({ value }) => value),
				analyticAttributes,
			);
		},
		[onChange, isNested],
	);

	return useMemo(
		() => (
			<ListFilter
				label={formatMessage(messages.label)}
				values={values}
				selectedValues={toStringArray(selectedComponents)}
				onChange={onChangeCallback}
				isNested={isNested}
			/>
		),
		[formatMessage, onChangeCallback, selectedComponents, values, isNested],
	);
};

export default memo<Props>(ComponentFilter);
