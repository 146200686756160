import React, { Component, type ReactNode, type SyntheticEvent } from 'react';
import { styled as styled2 } from '@compiled/react';
import noop from 'lodash/noop';
import Button from '@atlaskit/button';
import AddIcon from '@atlaskit/icon/core/add';
import AddIconOld from '@atlaskit/icon/glyph/add';
import { token } from '@atlaskit/tokens';
import StatefulToolTip from '@atlaskit/tooltip';
import withFireUiAnalytics from '@atlassian/jira-analytics-web-react/src/components/with-fire-ui-analytics.tsx';
import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types.tsx';
import withEngagement from '@atlassian/jira-engagement/src/ui/with-engagement/index.tsx';
import { FormattedMessage } from '@atlassian/jira-intl';
import { zIndex } from '../../../../common/constants/styles/index.tsx';
import type { RenderCustomButton } from '../../../../common/types.tsx';
import { isStickySupported } from '../../../../common/utils/is-sticky-supported/index.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledStickyPlaceholder = styled2.div({
	position: 'sticky',
	top: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.stickyHeaders,
});

interface Props {
	isDisabled: boolean;
	renderCustomButton?: RenderCustomButton;
	onClick: (analyticsEvent: AnalyticsEvent) => void;
}

// eslint-disable-next-line jira/react/no-class-components
export default class ColumnCreateButton extends Component<Props> {
	static defaultProps = {
		isDisabled: false,
		onClick: noop,
	};

	onClick = (event: SyntheticEvent, analyticsEvent: AnalyticsEvent) => {
		this.props.onClick(analyticsEvent);
	};

	renderButton = () => (
		<FormattedMessage id="platform-board.column.add-button-tooltip" defaultMessage="Create column">
			{(formattedMessage: ReactNode[]) => {
				const message: string =
					typeof formattedMessage[0] === 'string' ? formattedMessage[0] : 'Create column';

				return (
					<StatefulToolTip position="left" content={message}>
						{this.props.renderCustomButton ? (
							this.props.renderCustomButton({
								onClick: this.onClick,
								testId: 'platform-board-kit.ui.column.column-create.button.styled-button',
								isDisabled: this.props.isDisabled,
								ariaLabel: message,
							})
						) : (
							<Button
								onClick={this.onClick}
								testId="platform-board-kit.ui.column.column-create.button.styled-button"
								isDisabled={this.props.isDisabled}
								aria-label={message}
								iconBefore={
									<AddIcon
										label=""
										spacing="spacious"
										LEGACY_fallbackIcon={AddIconOld}
										LEGACY_size="medium"
										color={token('color.icon')}
									/>
								}
							/>
						)}
					</StatefulToolTip>
				);
			}}
		</FormattedMessage>
	);

	render = () => {
		if (!isStickySupported) {
			return this.renderButton();
		}

		return <StyledStickyPlaceholder>{this.renderButton()}</StyledStickyPlaceholder>;
	};
}

const ColumnCreateButtonWithAnalytics = withFireUiAnalytics({
	onClick: () => ({
		name: 'addColumn',
	}),
})(ColumnCreateButton);

export const ColumnCreateButtonWithEngagement = withEngagement<Props>(
	ColumnCreateButtonWithAnalytics,
);
