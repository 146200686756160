import type { State } from '../types/index.tsx';

export const INITIAL_STATE: State = {
	issueId: '',
	issueKey: undefined,
	locale: 'en_US',
	// @ts-expect-error - TS2322 - Type '""' is not assignable to type 'PanelDataTypes'.
	dataTypeSelected: '',
	legacyData: undefined,
	isDevPanelDetailsDialogShown: false,
	legacyDevPanelDetailsDialogShownCounter: 0,
};

export const DEV_DETAILS_PLUGIN_RESOURCE =
	'wrc!com.atlassian.jira.plugins.jira-development-integration-plugin:devsummarycf-resources';

export const DEV_DETAILS_WRM_RESOURCE =
	'jira-development-status/releasereport/dev-status-dialog-bridge';

export const DEV_DETAILS_WRM_UTIL = 'jira-development-status/util/WRM';
