import React from 'react';
import { metrics } from '@atlassian/browser-metrics';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { Attributes } from '@atlassian/jira-product-analytics-bridge';
import {
	type ApplicationEdition,
	FREE_EDITION as AE_FREE,
	STANDARD_EDITION as AE_STANDARD,
} from '@atlassian/jira-shared-types/src/edition.tsx';
import { isLoaderError } from '@atlassian/react-loosely-lazy';
import { JIRA_UPFLOW_IFRAME } from './constants.tsx';
import {
	type UpFlowApplicationEdition,
	FREE_EDITION,
	STANDARD_EDITION,
	PREMIUM_EDITION,
} from './types.tsx';
import { CommerceApiErrorFlag } from './ui/commerce-api-error-flag/index.tsx';

type JiraUpFlowIframeAnalyticsAttributes = {
	subject: string;
	message: string;
};

export const fireErrorAnalytic = (
	attributes: JiraUpFlowIframeAnalyticsAttributes,
	error?: Error,
) => {
	fireErrorAnalytics({
		meta: {
			// We use the same ID for all errors in the package for easier
			// management in data portal, and use attributes to identify an error instead.
			id: JIRA_UPFLOW_IFRAME,
			packageName: JIRA_UPFLOW_IFRAME,
			teamName: 'luna-growth',
		},
		attributes: {
			...attributes,
		},
		error,
	});
};

export const applicationEditionToUpFlowEdition = (
	applicationEdition: ApplicationEdition,
): UpFlowApplicationEdition => {
	if (applicationEdition === AE_FREE) {
		return FREE_EDITION;
	}
	if (applicationEdition === AE_STANDARD) {
		return STANDARD_EDITION;
	}
	return PREMIUM_EDITION;
};

export const jiraUpFlowIframeUpgradePlanInteraction = metrics.interaction({
	key: `${JIRA_UPFLOW_IFRAME}.upgrade.plan`,
});

export const addChunkLoadErrorAnalyticsAttribute = (error: Error): Attributes => ({
	chunkLoadError: isLoaderError(error),
});

export const handleError = (subject: string, onClose: () => void, apiError?: Error) => {
	fireErrorAnalytic({ subject, message: apiError?.message || '' }, apiError);
	jiraUpFlowIframeUpgradePlanInteraction.cancel();
	onClose();
	return <CommerceApiErrorFlag />;
};
