import type { GIC_LIFECYCLE_EVENTS, GIC_LIFECYCLE_EVENTS_PAYLOADS } from './constants.tsx';
import { PubSubHub } from './pub-sub-hub/index.tsx';
import type { PubSub } from './pub-sub-hub/types.tsx';

const GICLifeCycleEventsService: PubSub<GIC_LIFECYCLE_EVENTS, GIC_LIFECYCLE_EVENTS_PAYLOADS> =
	new PubSubHub<GIC_LIFECYCLE_EVENTS, GIC_LIFECYCLE_EVENTS_PAYLOADS>();

export const useGICLifeCycleEvents = () => ({
	...GICLifeCycleEventsService,
});
