import React from 'react';
import Icon, { type CustomGlyphProps, type IconProps } from '@atlaskit/icon';

const DesignIconSVG = (props: CustomGlyphProps) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		data-testid={props['data-testid']}
		aria-label={props['aria-label']}
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		className={props.className}
	>
		<path
			fill="currentcolor"
			d="M9.864 10.736c.77-.746 1.893-.536 2.72.265.826.8 1.043 1.888.273 2.634-1.816 1.76-5.482 2.002-4.765 1.207 1.587-1.76.578-2.949 1.772-4.106M19.968 4.03c.402.378-3.1 4.732-4.427 5.98-.659.62-1.012.826-1.276 1.016-.115.082-.15-.027-.177-.076a3.7 3.7 0 0 0-.63-.801c-.236-.222-.607-.445-.867-.572-.053-.025-.169-.058-.08-.166.201-.249.421-.58 1.08-1.2 1.327-1.247 5.974-4.56 6.377-4.181M18.38 19.946a1 1 0 0 1-.332.054.95.95 0 0 1-.913-.628c-.343-.957-1.14-1.642-2.257-1.933-2.182-.568-3.22-.264-5.053.995l-.16.11a9 9 0 0 1-1.049.644c-1.477.755-2.632.825-3.482.419-.586-.28-.989-.794-1.1-1.407-.254-1.403.93-2.647 2.602-3.156a.906.906 0 0 1 1.155.601.92.92 0 0 1-.62 1.186c-.33.1-.717.311-.978.533-.195.165-.33.355-.306.434.042.138.088.185.16.214.155.063.445.029.818-.096q.247-.083.524-.21c.196-.088.348-.165.58-.287.076-.04.177-.11.445-.303 1.09-.784 1.802-1.198 2.845-1.52 1.562-.482 3.18-.39 4.802.272 1.399.57 2.399 1.589 2.883 2.94.174.483-.07.97-.564 1.138"
		/>
	</svg>
);

const DesignIcon = (props: IconProps) => <Icon {...props} glyph={DesignIconSVG} />;
export default DesignIcon;
