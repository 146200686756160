import React, {
	type RefObject,
	type ElementType,
	type ComponentPropsWithoutRef,
	Component,
	type ComponentType,
	createRef,
} from 'react';
import { styled as styled2 } from '@compiled/react';
import noop from 'lodash/noop';
import { Flex, xcss } from '@atlaskit/primitives';
import { AnalyticsSubject } from '@atlassian/jira-analytics-web-react/src/components/decorators.tsx';
import { getNormalisedPerformanceFFs } from '@atlassian/jira-common-long-task-metrics/src/common/util/collectors.tsx';
import { getLongTasksMetrics } from '@atlassian/jira-common-long-task-metrics/src/index.tsx';
import { filterReporter } from '@atlassian/jira-common-long-task-metrics/src/reporters/software-filters.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { JiraBottomRightCornerOutlet } from '@atlassian/jira-layout-controller/src/ui/bottom-right-corner/outlet/index.tsx';
import { BoardProvider } from '@atlassian/jira-platform-board-kit/src/ui/board-provider/index.tsx';
import { AsyncLazyScrollInterceptorWrapper as ScrollInterceptor } from '@atlassian/jira-scroll-interceptor/src/ui/scroll-interceptor-wrapper/async.tsx';
import { ScrollStateContextProvider } from '@atlassian/jira-software-fast-virtual/src/services/use-scroll-state/index.tsx';
import { LongTasksScrollObserver } from '@atlassian/jira-software-long-tasks-scroll-observer/src/ui/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { ANALYTICS_SOURCE_BOARD, ANALYTICS_SOURCE_PROGRAM_BOARD } from '../../common/constants.tsx';
import { NextBestTaskPanel } from '../board-panels/next-best-task-panel/index.tsx';
import { UnscheduledWorkPanel } from '../board-panels/unscheduled-work-panel/index.tsx';
import BacklogFeatureDialog from './board/backlog-feature-dialog/index.tsx';
import type BacklogIssueMoveDialogDI from './board/backlog-issue-move-dialog/index.tsx';
import { useBoardDragDropContext } from './board/board-drag-drop-context/use-board-drag-drop-context/index.tsx';
import type ColumnConfigurationButtonDI from './board/column-configuration/index.tsx';
import type ColumnCreateDI from './board/column-create/index.tsx';
import ColumnsContainer from './board/columns-container/index.tsx';
import type BoardDI from './board/index.tsx';
import type KeyboardShortcutsDI from './board/keyboard-shortcuts/index.tsx';
import type BoardMinimapDI from './board/minimap/index.tsx';
import SprintAssociationModal from './board/sprint-association-modal/index.tsx';
import SwimlanesContainer from './board/swimlanes-container/view.tsx';
import { UnassignedFilterCTABoardAnalytics } from './board/unassigned-filter-cta-experiment/unassigned-filter-cta-board-analytics/index.tsx';
import type ScrollObserverDI from './scroll-observer/index.tsx';
import type SubmitApdexDI from './submit-apdex/index.tsx';

type DIType<CT extends ElementType> = ComponentType<ComponentPropsWithoutRef<CT>>;

export interface OwnProps {
	isCacheHit: boolean;
	onDragEnd?: () => void;
}

export interface StateProps {
	isColumnCreateAllowed: boolean;
	isInlineColumnEditEnabled: boolean;
	isNonCriticalWorksetLoaded: boolean;
	hasSwimlanes: boolean;
	hasMinimap: boolean;
	Board: DIType<typeof BoardDI>;
	BacklogIssueMoveDialog: DIType<typeof BacklogIssueMoveDialogDI>;
	KeyboardShortcuts: DIType<typeof KeyboardShortcutsDI>;
	ColumnCreate: DIType<typeof ColumnCreateDI>;
	ColumnConfigurationButton: DIType<typeof ColumnConfigurationButtonDI>;
	BoardMinimap: DIType<typeof BoardMinimapDI>;
	SubmitApdex: DIType<typeof SubmitApdexDI>;
	ScrollObserver: DIType<typeof ScrollObserverDI>;
	boardSessionId: string;
	onBoardNonCriticalViewed: () => void;
	isFlexibleColumns?: boolean;
	shouldRenderSwimlane: boolean;
	isSprintsEnabled: boolean;
	preventInlineEditing?: boolean;
	onPreventInlineEditing: (shouldPreventInlineEditing: boolean) => void;
	isColumnConfigurationButtonEnabled: boolean;
	isIncrementPlanningBoard: boolean;
	isUnscheduledColumnVisible: boolean;
}

export type Props = OwnProps & StateProps;

export interface State {
	scrollingElementRef: RefObject<HTMLElement>;
	boardNonCriticalViewEventFired: boolean;
}

// eslint-disable-next-line jira/react/no-class-components
class SoftwareBoard extends Component<Props, State> {
	static defaultProps = {
		hasMinimap: true,
		onBoardNonCriticalViewed: noop,
	};

	constructor(props: Props) {
		super(props);
		this.state = {
			scrollingElementRef: createRef<HTMLElement>(),
			boardNonCriticalViewEventFired: false,
		};

		expVal('jira_aa_test', 'cohort', 'not-enrolled');
		expVal('jira_aa_test_with_targeting', 'cohort', 'not-enrolled');
	}

	componentDidMount() {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.addEventListener('mousedown', this.handleMouseEvents, true);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.addEventListener('mouseup', this.handleMouseEvents, true);
	}

	componentDidUpdate() {
		getLongTasksMetrics('filter').stop(filterReporter, {
			boardSessionId: this.props.boardSessionId,
		});

		if (!this.state.boardNonCriticalViewEventFired && this.props.isNonCriticalWorksetLoaded) {
			this.props.onBoardNonCriticalViewed();
			this.setState({ boardNonCriticalViewEventFired: true });
		}
	}

	componentWillUnmount() {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.removeEventListener('mousedown', this.handleMouseEvents);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.removeEventListener('mouseup', this.handleMouseEvents);
	}

	handleMouseEvents = (event: MouseEvent) => {
		if (event.metaKey || event.altKey || event.ctrlKey) {
			this.props.onPreventInlineEditing(true);
		} else if (this.props.preventInlineEditing) {
			this.props.onPreventInlineEditing?.(false);
		}
	};

	render() {
		const {
			isCacheHit,
			isColumnCreateAllowed,
			isInlineColumnEditEnabled,
			isFlexibleColumns,
			hasSwimlanes,
			hasMinimap,
			Board,
			BacklogIssueMoveDialog,
			KeyboardShortcuts,
			ColumnCreate,
			ColumnConfigurationButton,
			BoardMinimap,
			SubmitApdex,
			ScrollObserver,
			shouldRenderSwimlane,
			isColumnConfigurationButtonEnabled,
			isUnscheduledColumnVisible,
			isIncrementPlanningBoard,
		} = this.props;

		const isBoardWithVisibleUnscheduledWorkPanel =
			isIncrementPlanningBoard && isUnscheduledColumnVisible;

		const ipScrollObserverAttributes = fg('dependency_visualisation_program_board_fe_and_be')
			? { isDependencyVisualisationEnabled: true }
			: { isDependencyVisualisationEnabled: false };

		const boardChildren = (
			<>
				<Board
					scrollRef={this.state.scrollingElementRef}
					isBoardWithVisibleUnscheduledWorkPanel={isBoardWithVisibleUnscheduledWorkPanel}
				>
					<KeyboardShortcuts
						// @ts-expect-error TS2322 - hasSwimlanes possibly is dead-code
						hasSwimlanes={hasSwimlanes}
					/>
					<BacklogFeatureDialog />
					<BacklogIssueMoveDialog />
					<NextBestTaskPanel />
					{this.props.isIncrementPlanningBoard && <SprintAssociationModal />}
					<BoardContainer
						isFlexible={isFlexibleColumns}
						{...(isBoardWithVisibleUnscheduledWorkPanel && {
							'data-testid': 'software-board.board-container.scheduled-work-board',
						})}
					>
						<ScrollStateContextProvider scrollRef={this.state.scrollingElementRef}>
							{shouldRenderSwimlane ? (
								<SwimlanesContainer />
							) : (
								<ColumnsContainer
									swimlaneId={null}
									isLastSwimlane
									isUnscheduledWorkColumn={false}
								/>
							)}
						</ScrollStateContextProvider>
					</BoardContainer>

					{isColumnCreateAllowed && isInlineColumnEditEnabled && <ColumnCreate />}
					{isColumnCreateAllowed &&
						!isInlineColumnEditEnabled &&
						isColumnConfigurationButtonEnabled && <ColumnConfigurationButton />}

					{fg('scrollinterceptor_conditional_render') ? (
						this.state.scrollingElementRef.current && (
							<ScrollInterceptor scrollEl={this.state.scrollingElementRef.current} />
						)
					) : (
						<ScrollInterceptor scrollEl={this.state.scrollingElementRef.current} />
					)}

					{
						// LongTasksScrollObserver for software board
						!isIncrementPlanningBoard && fg('jsw_refactor_long-tasks_monitoring') && (
							<LongTasksScrollObserver
								appName={ANALYTICS_SOURCE_BOARD}
								scrollEl={this.state.scrollingElementRef.current}
								attributes={getNormalisedPerformanceFFs()}
							/>
						)
					}
					{
						// LongTasksScrollObserver for program board
						isIncrementPlanningBoard && fg('enable_scroll_observer_in_program_board') && (
							<LongTasksScrollObserver
								appName={ANALYTICS_SOURCE_PROGRAM_BOARD}
								scrollEl={this.state.scrollingElementRef.current}
								attributes={getNormalisedPerformanceFFs()}
								logAttributes={ipScrollObserverAttributes}
							/>
						)
					}
				</Board>
				{hasMinimap && (
					<JiraBottomRightCornerOutlet orderFromRight={2} id="software-board-minimp">
						<BoardMinimap />
					</JiraBottomRightCornerOutlet>
				)}
				<SubmitApdex isCacheHit={isCacheHit} />
				<ScrollObserver />
				{fg('jira-tako-unassigned-filter-cta-killswitch') && <UnassignedFilterCTABoardAnalytics />}
			</>
		);

		const boardWithUnscheduledColumn = (
			<Flex xcss={BoardWithUnscheduledColumnWrapperStyles}>
				{isUnscheduledColumnVisible && <UnscheduledWorkPanel />}
				{boardChildren}
			</Flex>
		);

		return (
			<UFOSegment name="board-container">
				<BoardWrapper data-onboarding-observer-id="board-wrapper">
					<BoardProvider>
						{isIncrementPlanningBoard && isUnscheduledColumnVisible
							? boardWithUnscheduledColumn
							: boardChildren}
					</BoardProvider>
				</BoardWrapper>
			</UFOSegment>
		);
	}
}

const SoftwareBoardWithDragDropContext = (props: Props) => {
	useBoardDragDropContext({ onDragEnd: props.onDragEnd });
	return <SoftwareBoard {...props} />;
};
export default AnalyticsSubject('board')(SoftwareBoardWithDragDropContext);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const BoardWrapper = styled2.div({
	width: '100%',
	height: '100%',
	minWidth: 0,
	position: 'relative',
});

const BoardWithUnscheduledColumnWrapperStyles = xcss({
	position: 'relative',
	width: '100%',
	height: '100%',
	paddingLeft: 'space.500',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const BoardContainer = styled2.div<{ isFlexible: boolean | undefined }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	flex: ({ isFlexible }) => (isFlexible ? '1' : '0 1 auto'),
});
