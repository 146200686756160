import { type Observable as ObservableType, Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import type { BoardGQLExtension } from '@atlassian/jira-software-board-fetch-scope-critical/src/types.tsx';
import { query$ } from '@atlassian/jira-software-swag/src/services/query/index.tsx';
import { loadExtensionAnalyticsOptions } from '../../common/graphql/metrics/index.tsx';

const softwareBoardExtensionOperationName = 'SoftwareBoardExtension';

// move to non-critical
export const getExtensionGraphQlQuery = (boardId: string) =>
	`query ${softwareBoardExtensionOperationName} {
        boardScope(boardId:${boardId}) {
            board {
                id
                extension {
                    operations {
                        icon {
                           url
                        }
                        name
                        url
                        tooltip
                        styleClass
                    } 
                }
            }
        }
    }`;

type Response = {
	boardScope: {
		board: BoardGQLExtension;
	};
};

export function fetchExtensionData(
	contextPath: string,
	boardId: string,
): ObservableType<BoardGQLExtension> {
	return query$<Response>(
		contextPath,
		softwareBoardExtensionOperationName,
		getExtensionGraphQlQuery(boardId),
		loadExtensionAnalyticsOptions,
	).flatMap((data: Response) => Observable.of(data.boardScope.board));
}
