import React, { useRef } from 'react';
import type { CardProps } from '@atlassian/jira-platform-board-kit/src/ui/card/types.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import { useBoardSelector } from '../../../../../state/index.tsx';
import { isActiveCardSelector } from '../../../../../state/selectors/work/work-selectors.tsx';
import CardCover from '../card/cover/index.tsx';
import { NewCard } from '../card/index.tsx';

export type Props = {
	id: IssueId;
	cardIndex: number;
	userCanViewMenu: boolean;
	onIssueChange: () => void;
	isDraggable: boolean;
	columnId: number;
	swimlaneId: string | null | undefined;
	CardPreview: CardProps['CardPreview'];
};

const CardWithRich = (props: Props) => {
	const hasRenderedRichCard: {
		current: boolean;
	} = useRef(false);

	const {
		id,
		cardIndex,
		onIssueChange,
		userCanViewMenu,
		isDraggable,
		columnId,
		swimlaneId,
		CardPreview,
	} = props;
	const isActiveCard = useBoardSelector((state) => isActiveCardSelector(state, { issueId: id }));

	// @ts-expect-error([Part of upgrade 4.9-5.4]) - Operator '<=' cannot be applied to types 'IssueId' and 'number'.
	const isLoading = id <= 0;
	const shouldRenderRichCardRequirements = isActiveCard && !isLoading;

	if (shouldRenderRichCardRequirements && !hasRenderedRichCard.current) {
		hasRenderedRichCard.current = true;
	}

	return (
		<NewCard
			key={id}
			id={id}
			isLoading={isLoading}
			draggableIndex={cardIndex}
			userCanViewMenu={userCanViewMenu}
			Cover={CardCover}
			onIssueChange={onIssueChange}
			isDraggable={isDraggable}
			shouldRenderRichCard={shouldRenderRichCardRequirements || hasRenderedRichCard.current}
			isActive={isActiveCard}
			columnId={columnId}
			swimlaneId={swimlaneId}
			CardPreview={CardPreview}
		/>
	);
};

export default CardWithRich;
