type UseRowCountConfiguration = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	placeholder: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	issues: any[];
};

export const useRowCount = ({ placeholder, issues }: UseRowCountConfiguration): number => {
	const numberOfRows = issues.length + 1;

	// If we aren't provided with any rows, we want to render the placeholder. Just put the count as 1.
	if (numberOfRows === 0 && placeholder) {
		return 1;
	}

	return numberOfRows;
};
