import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import type { IssueKey } from '../../../../model/issue/issue-types.tsx';
import type { SwimlaneId } from '../../../../model/swimlane/swimlane-types.tsx';

export const CARD_AUTO_SCROLL = 'state.actions.card.CARD_AUTO_SCROLL';

export type CardAutoScrollAction = {
	type: typeof CARD_AUTO_SCROLL;
	payload: {
		issueId: IssueId | null;
	};
};

export const cardAutoScroll = (issueId: IssueId | null): CardAutoScrollAction => ({
	type: CARD_AUTO_SCROLL,
	payload: {
		issueId,
	},
});

export const CARD_AUTO_SCROLL_REQUEST_ON_LOAD =
	'state.actions.card.CARD_AUTO_SCROLL_REQUEST_ON_LOAD';

export type CardAutoScrollRequestOnLoadAction = {
	type: typeof CARD_AUTO_SCROLL_REQUEST_ON_LOAD;
	payload: {
		issueKey: IssueKey;
	};
};

export const cardAutoScrollRequestOnLoad = (
	issueKey: IssueKey,
): CardAutoScrollRequestOnLoadAction => ({
	type: CARD_AUTO_SCROLL_REQUEST_ON_LOAD,
	payload: {
		issueKey,
	},
});

export const CARD_AUTO_SCROLL_REQUEST_ON_FILTER =
	'state.actions.card.CARD_AUTO_SCROLL_REQUEST_ON_FILTER';

export type CardAutoScrollRequestOnFilterAction = {
	type: typeof CARD_AUTO_SCROLL_REQUEST_ON_FILTER;
};

export const cardAutoScrollRequestOnFilter = (): CardAutoScrollRequestOnFilterAction => ({
	type: CARD_AUTO_SCROLL_REQUEST_ON_FILTER,
});

export const CARD_AUTO_SCROLL_UNSCHEDULED_COLUMN_ICC =
	'state.actions.card.CARD_AUTO_SCROLL_UNSCHEDULED_COLUMN_ICC';

export type CardAutoScrollUnscheduledColumnIccAction = {
	type: typeof CARD_AUTO_SCROLL_UNSCHEDULED_COLUMN_ICC;
	payload: {
		swimlaneId: SwimlaneId | null;
		isImmediate?: boolean;
	};
};

export const cardAutoScrollUnscheduledColumnIcc = (
	swimlaneId: SwimlaneId | null,
	isImmediate?: boolean,
): CardAutoScrollUnscheduledColumnIccAction => ({
	type: CARD_AUTO_SCROLL_UNSCHEDULED_COLUMN_ICC,
	payload: {
		swimlaneId,
		isImmediate,
	},
});

export type Action =
	| CardAutoScrollAction
	| CardAutoScrollRequestOnLoadAction
	| CardAutoScrollRequestOnFilterAction
	| CardAutoScrollUnscheduledColumnIccAction;
