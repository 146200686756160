import { CARD_DELETE } from '../../../actions/card/index.tsx';
import {
	ISSUE_DELETE_MODAL_OPEN,
	ISSUE_DELETE_MODAL_CLOSE,
} from '../../../actions/issue/delete-modal/index.tsx';
import { ISSUE_DELETE_REQUEST } from '../../../actions/issue/delete/index.tsx';
import type { Action } from '../../../types.tsx';
import type { IssueDeleteModalState } from './types.tsx';

export const issueDeleteModalReducer = (
	state: IssueDeleteModalState = { isOpen: false },
	action: Action,
): IssueDeleteModalState => {
	if (action.type === ISSUE_DELETE_MODAL_OPEN) {
		const { issueId } = action.payload;
		return { isOpen: true, issueId };
	}

	if (action.type === ISSUE_DELETE_MODAL_CLOSE) {
		return { isOpen: false };
	}

	if (action.type === ISSUE_DELETE_REQUEST || action.type === CARD_DELETE) {
		return { isOpen: false };
	}

	return state;
};
