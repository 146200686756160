/* eslint-disable jira/restricted/styled-components-migration */
import React, { type ReactNode } from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import noop from 'lodash/noop';
import { DEPENDENCIES_OVERLAY_CLASS_NAME } from './constants.tsx';
import DependencyLine from './line/index.tsx';
import type { OverlayDependencyItem, SelectedDependency, OnDependencyClick } from './types.tsx';

type Props = {
	dependencies: OverlayDependencyItem[];
	selectedDependency: SelectedDependency | undefined;
	onDependencyClick: OnDependencyClick;
};

const SvgOverlay = ({ dependencies, selectedDependency, onDependencyClick }: Props) => {
	const selectedDependencyLine =
		selectedDependency &&
		dependencies.find(
			(dependency) =>
				dependency.from.id === selectedDependency.fromId &&
				dependency.to.id === selectedDependency.toId &&
				dependency.from.indexOnScope === selectedDependency.fromIndex &&
				dependency.to.indexOnScope === selectedDependency.toIndex,
		);

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<SvgContainer className={DEPENDENCIES_OVERLAY_CLASS_NAME}>
			{dependencies.map((dependency) => {
				if (dependency !== selectedDependencyLine) {
					return (
						<DependencyLine
							key={`${dependency.from.id}-${dependency.to.id}-${dependency.from.y}-${dependency.to.y}`}
							{...dependency}
							onClick={onDependencyClick}
							isSelected={false}
						/>
					);
				}

				return null;
			})}
			{selectedDependencyLine && (
				<DependencyLine {...selectedDependencyLine} onClick={onDependencyClick} isSelected />
			)}
		</SvgContainer>
	);
};

SvgOverlay.defaultProps = {
	selectedDependency: undefined,
	onDependencyClick: noop,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
	dependencies: [] as any[],
};

export default SvgOverlay;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SvgContainer = styled.svg<{
	children?: ReactNode;
	className?: string;
}>({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	transition: 'opacity 0.2s ease-in-out',
	overflow: 'visible',
	pointerEvents: 'none',
});
