import type { JiraPlanDataRestResponse } from '@atlassian/jira-software-backlog-uif-types/src/index.tsx';
import { extractStatusCode } from '@atlassian/jira-software-sla-tracker/src/services/status-code/index.tsx';
import type {
	UIFBoardCachedDataResult,
	UIFBacklogCachedDataResult,
} from '@atlassian/jira-software-uif-early-script/src/index.tsx';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { type RouteResource, useResource } from '@atlassian/react-resource-router';

export const useShouldShowAdminInaccessibleBoard = (
	resource: RouteResource<
		UIFBoardCachedDataResult | UIFBacklogCachedDataResult | JiraPlanDataRestResponse | null
	>,
) => {
	const isAdmin = useIsAdmin();
	const data = useResource(resource);
	const statusCode = data.error ? extractStatusCode(data.error) : null;

	return isAdmin && statusCode === 404;
};
