import React, { type MutableRefObject, Component } from 'react';
import type { PreloadedState } from 'redux';
import type { PreloadedQuery } from 'react-relay';
import uuid from 'uuid';
import { SpotlightManager } from '@atlaskit/onboarding';
/* eslint jira/import/gql-multi-client: off */
import {
	ApolloClientProvider,
	getClient,
} from '@atlassian/jira-apollo-multiple-clients/src/main.tsx';
import swagClient from '@atlassian/jira-apollo-swag/src/index.tsx';
import AppBase from '@atlassian/jira-app-base/src/index.tsx';
import bindUrlToState from '@atlassian/jira-common-bind-url-to-state/src/index.tsx';
import type { ProjectType } from '@atlassian/jira-common-constants/src/index.tsx';
import {
	SERVICE_DESK_PROJECT,
	SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import type { Locale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
import { setMark } from '@atlassian/jira-common-performance/src/marks.tsx';
import { DISPOSE_ACTION } from '@atlassian/jira-common-redux-disposable/src/index.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { expVal } from '@atlassian/jira-feature-experiments';
import { getCachedFilters } from '@atlassian/jira-filters-storage/src/index.tsx';
import { FlagsDispatcher } from '@atlassian/jira-flags';
import MemoryObserver from '@atlassian/jira-memory-metrics/src/ui/index.tsx';
import type { TriggerPointKeyType } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/types.tsx';
import Ipcontext from '@atlassian/jira-portfolio-3-plan-increment-common/src/services/context/index.tsx';
import {
	jswCreateReduxStoreStartMark,
	jswCreateReduxStoreEndMark,
	jswRenderStartMark,
} from '@atlassian/jira-providers-spa-apdex-analytics/src/marks.tsx';
import type { viewBoardCustomFiltersQuery } from '@atlassian/jira-relay/src/__generated__/viewBoardCustomFiltersQuery.graphql';
import type {
	LicensedProducts,
	ProductCrossSellConfig,
	SiteAdminStatus,
} from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import type { BoardScopeGQL } from '@atlassian/jira-software-board-fetch-scope-critical/src/types.tsx';
import { BOARD } from '@atlassian/jira-software-resource-invalidator/src/common/types.tsx';
import { AsyncResourcesCacheWrapper } from '@atlassian/jira-software-resource-invalidator/src/ui/resources-cache-wrapper/async.tsx';
import type { UIFBoardCachedDataResult } from '@atlassian/jira-software-uif-early-script/src/index.tsx';
import type { ReactRouterCompatibleHistory } from '@atlassian/jira-spa-router-adapters/src/common/types.tsx';
import type { boardFilterRefinementQuery } from '@atlassian/jira-relay/src/__generated__/boardFilterRefinementQuery.graphql.ts';
import { isTailoredViewExperimentEnabled } from './feature-flags.tsx';
import { flagsMapper } from './flags/index.tsx';
import type { GlobalIssueCreateProps } from './model/check-global-issue-create/check-global-issue-create-types.tsx';
import {
	REFRESH_SOURCE_GLOBAL_ISSUE_CREATE,
	APP_ID,
	TEAM_NAME,
	PACKAGE_NAME,
} from './model/constants.tsx';
import type { CustomRequestHandlers } from './model/issue/issue-increment-planning-types.tsx';
import type { ActivationId } from './model/software/software-types.tsx';
import filterUrlBindings from './services/filter/filter-url-bindings.tsx';
import { jqlUrlBindings } from './services/jql/jql-url-bindings.tsx';
import { getCollapsedSwimlanes } from './services/software/software-storage.tsx';
import softwareUrlBindings from './services/software/software-url-bindings.tsx';
import { softwareAppLoaded } from './state/actions/software/index.tsx';
import { beforeDispose, workRefreshData } from './state/actions/work/index.tsx';
import createStore, {
	type BoardStore,
	BoardReduxProvider,
	createStoreWithCache,
	unmountCachedStore,
} from './state/index.tsx';
import {
	rapidViewIdSelector,
	projectIdSelector,
	getIsCMPBoard,
} from './state/selectors/software/software-selectors.tsx';
import type { State } from './state/types.tsx';
import { AllFieldsSync } from './view/all-fields-sync/index.tsx';
import BoardAPI from './view/board-api/index.tsx';
import type { BoardRefAPI } from './view/board-api/types.tsx';
import { FiltersStorage } from './view/filters-storage/index.tsx';
import RootView from './view/index.tsx';
import SelectedIssuesSync from './view/selected-issues-sync/index.tsx';
import { SwimlaneModeFromUrl } from './view/swimlane-mode/index.tsx';
import SyncWithRouting from './view/sync-with-routing/index.tsx';

type Props = {
	isCacheHit: boolean;
	isServer: boolean;
	cloudId: string;
	locale: Locale;
	contextPath: string;
	rapidViewId: number;
	projectKey: string;
	projectId: number;
	projectType: ProjectType;
	userAccountId: string;
	xsrfToken: string;
	activationId: ActivationId;
	prefetchedData: BoardScopeGQL | null;
	cmpBoardData: Promise<UIFBoardCachedDataResult | null> | null;
	// eslint-disable-next-line jira/react/handler-naming
	bindToOnJiraGlobalIssueCreateClose: (
		arg1: (
			event: Event,
			issues: GlobalIssueCreateProps[],
			triggerSource: TriggerPointKeyType,
		) => void,
	) => void;
	siteAdminStatus: SiteAdminStatus;
	productCrossSellConfig: ProductCrossSellConfig;
	licensedProducts: LicensedProducts;
	history: ReactRouterCompatibleHistory;
	customFiltersQueryRef: PreloadedQuery<viewBoardCustomFiltersQuery> | null | undefined;
	boardFilterRefinementQueryRef?: PreloadedQuery<boardFilterRefinementQuery> | null;
	onUnmount: () => void;
	isCMPBoard: boolean;
	isUserBoard: boolean;
	/**
	 * Disables redux store caches, used for integration testing.
	 */
	disableStoreCaches?: boolean;
	/**
	 * ARJ increment planning board
	 */
	isIncrementPlanningBoard: boolean;
	planId?: string;
	scenarioId?: string;
	customRequestHandlers?: CustomRequestHandlers;
	boardApiRef?: MutableRefObject<BoardRefAPI | null>;
};

// eslint-disable-next-line jira/react/no-class-components
export default class SoftwareApp extends Component<Props> {
	static defaultProps = {
		// Replace with lodash/noop
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		onUnmount: () => {},
		isCMPBoard: false,
		isUserBoard: false,
		isIncrementPlanningBoard: false,
		projectType: SOFTWARE_PROJECT,
	};

	constructor(props: Props) {
		super(props);

		const {
			contextPath,
			rapidViewId,
			projectKey,
			projectId,
			projectType,
			cloudId,
			activationId,
			userAccountId,
			locale,
			bindToOnJiraGlobalIssueCreateClose,
			licensedProducts,
			siteAdminStatus,
			productCrossSellConfig,
			isServer,
			history,
			isCMPBoard,
			isUserBoard,
			disableStoreCaches,
			/**
			 * ARJ increment planning board
			 */
			isIncrementPlanningBoard,
			planId,
			scenarioId,
			customRequestHandlers,
		} = props;

		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const initialState: PreloadedState<State> = {
			configuration: {
				contextPath,
				rapidViewId: String(rapidViewId),
				projectKey,
				projectId,
				projectType,
				cloudId,
				activationId,
				userAccountId,
				locale,
				licensedProducts,
				siteAdminStatus,
				productCrossSellConfig,
				sessionId: uuid.v4(),
				isCMPBoard,
				isUserBoard,
				/**
				 * ARJ increment planning board
				 */
				isIncrementPlanningBoard,
				planId,
				scenarioId,
			},
			ui: {
				swimlane: {
					collapsed: getCollapsedSwimlanes(rapidViewId),
				},
				...(isCMPBoard &&
					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					!window.location.search && {
						workFilters: { values: getCachedFilters('board', rapidViewId) ?? {} },
					}),
			},
		} as PreloadedState<State>;

		setMark(jswCreateReduxStoreStartMark);

		this.store = isCMPBoard
			? createStoreWithCache(String(rapidViewId), initialState, {
					isCMPBoard,
					isServer,
					projectType,
					disableStoreCaches: disableStoreCaches ?? false,
					swagClient: getClient(contextPath, swagClient, this.props.xsrfToken),
				})
			: createStore(initialState, {
					isCMPBoard,
					isServer,
					projectType,
					swagClient: getClient(contextPath, swagClient, this.props.xsrfToken),
					// noop in this position
					disableStoreCaches: false,
					customRequestHandlers,
				});

		setMark(jswCreateReduxStoreEndMark);

		// In increment planning board, there are URL params set by other packages, eg. software/view-settings
		const keepNonManagedParams = isIncrementPlanningBoard || isTailoredViewExperimentEnabled();

		this.unsubscribeUrlBindings = bindUrlToState(
			this.store,
			[
				...filterUrlBindings(isCMPBoard),
				...softwareUrlBindings(isIncrementPlanningBoard),
				...(expVal('filter_refinement_in_tmp_board', 'isEnabled', false)
					? jqlUrlBindings({
							isIncrementPlanningBoard,
							isCMPBoard,
							isJSMBoard: projectType === SERVICE_DESK_PROJECT,
							isJSWBoard: projectType === SOFTWARE_PROJECT,
						})
					: []),
			],
			keepNonManagedParams,
			history,
		);

		// let's update store before it's attached to virtual DOM
		this.store.dispatch(softwareAppLoaded(props.prefetchedData, props.cmpBoardData));
		bindToOnJiraGlobalIssueCreateClose(
			(event: Event, issues: GlobalIssueCreateProps[], triggerSource: TriggerPointKeyType) => {
				this.store.dispatch(
					workRefreshData(REFRESH_SOURCE_GLOBAL_ISSUE_CREATE, issues, triggerSource),
				);
			},
		);
	}

	componentDidMount() {
		this.hasRendered = true;
	}

	componentWillUnmount() {
		if (!fg('jira-boards-in-strict-mode')) {
			this.unsubscribeUrlBindings();

			if (this.store) {
				if (getIsCMPBoard(this.store.getState())) this.store.dispatch(beforeDispose);
				this.store.dispatch(DISPOSE_ACTION);
			}

			this.props.onUnmount();
			unmountCachedStore();
		}
	}

	unsubscribeUrlBindings: () => void;

	store: BoardStore;

	hasRendered = false;

	render() {
		if (!this.hasRendered) setMark(jswRenderStartMark);

		return (
			<ApolloClientProvider client={swagClient}>
				<AppBase
					id={APP_ID}
					store={this.store}
					teamName={TEAM_NAME}
					packageName={PACKAGE_NAME}
					fallback="page"
				>
					<BoardReduxProvider store={this.store}>
						<Ipcontext.Provider
							value={{
								scenarioId: this.props.scenarioId,
								planId: this.props.planId,
							}}
						>
							<SpotlightManager>
								<RootView
									isCacheHit={this.props.isCacheHit}
									rapidViewId={rapidViewIdSelector(this.store.getState())}
									xsrfToken={this.props.xsrfToken}
									hasMinimap={!this.props.isServer}
									hasModals={!this.props.isServer}
									hasFlags={!this.props.isServer}
									customFiltersQueryRef={this.props.customFiltersQueryRef}
									boardFilterRefinementQueryRef={this.props.boardFilterRefinementQueryRef}
									projectId={projectIdSelector(this.store.getState())}
									isIncrementPlanningBoard={this.props.isIncrementPlanningBoard}
								/>

								{/* standard tangerine-classic flags implementation */}
								<FlagsDispatcher mapper={flagsMapper} />
								<ErrorBoundary id="async-resources-cache-wrapper" packageName="software-board">
									<AsyncResourcesCacheWrapper origin={BOARD} />
								</ErrorBoundary>
								<SyncWithRouting />
								<MemoryObserver />
								<AllFieldsSync />
								{fg('nav4_route_aware_automation_menu') && <SelectedIssuesSync />}
								<FiltersStorage />
								{isTailoredViewExperimentEnabled() && <SwimlaneModeFromUrl />}
								{this.props.isIncrementPlanningBoard && this.props.boardApiRef !== undefined && (
									<BoardAPI boardApiRef={this.props.boardApiRef} />
								)}
							</SpotlightManager>
						</Ipcontext.Provider>
					</BoardReduxProvider>
				</AppBase>
			</ApolloClientProvider>
		);
	}
}
