import {
	type SetIssuesNotOnBoardAction,
	SET_ISSUES_NOT_ON_BOARD,
} from '../../../../actions/issue/index.tsx';
import type { Action } from '../../../../types.tsx';
import type { IssuesNotOnBoardState } from './types.tsx';

export const issuesNotOnBoardReducer = (
	state: IssuesNotOnBoardState = {},
	action: Action,
): IssuesNotOnBoardState => {
	if (action.type === SET_ISSUES_NOT_ON_BOARD) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const { payload } = action as SetIssuesNotOnBoardAction;
		return payload;
	}
	return state;
};
