import React, { useRef } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import {
	unscheduledColumnWidth,
	scrollbarWidth,
} from '@atlassian/jira-platform-board-kit/src/common/constants/styles/index.tsx';
import { AsyncLazyScrollInterceptorWrapper as ScrollInterceptor } from '@atlassian/jira-scroll-interceptor/src/ui/scroll-interceptor-wrapper/async.tsx';
import { ScrollStateContextProvider } from '@atlassian/jira-software-fast-virtual/src/services/use-scroll-state/index.tsx';
import Board from '../../board-container/board/index.tsx';
import UnscheduledWorkSwimlanesContainer from '../../board-container/board/unscheduled-work-swimlanes-container/index.tsx';
import ScrollObserver from '../../board-container/scroll-observer/index.tsx';

export const UnscheduledWorkPanel = () => {
	const scrollingElementRef = useRef<HTMLElement | null>(null);
	return (
		<Box
			xcss={UnscheduledWorkContainerStyles}
			testId="software-board.board-panels.unscheduled-work-panel"
		>
			<Board scrollRef={scrollingElementRef} isUnscheduledWorkColumnPanel>
				<ScrollStateContextProvider scrollRef={scrollingElementRef}>
					<UnscheduledWorkSwimlanesContainer />
				</ScrollStateContextProvider>
				{fg('scrollinterceptor_conditional_render') ? (
					scrollingElementRef.current && (
						<ScrollInterceptor scrollEl={scrollingElementRef.current} />
					)
				) : (
					<ScrollInterceptor scrollEl={scrollingElementRef.current} />
				)}
				<ScrollObserver />
			</Board>
		</Box>
	);
};

const UnscheduledWorkContainerStyles = xcss({
	// Calculate inner content width plus padding on each side and scrollbar width
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `calc(${unscheduledColumnWidth} + ${scrollbarWidth} + (${token('space.050', '4px')} * 2))`,
	height: '100%',
	overflowY: 'auto',
	overflowX: 'hidden',
	display: 'flex',
	backgroundColor: 'elevation.surface',
	zIndex: 'card',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	border: `1px solid ${token('color.border', colors.N40)}`,
	borderRadius: 'border.radius.200',
	position: 'relative',
	flexShrink: 0,
});
