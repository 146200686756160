import { differenceInDays } from 'date-fns';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { useFirstActivationDateMs } from '@atlassian/jira-tenant-context-controller/src/components/first-activation-date-ms/index.tsx';

export const useRealtimePresenceExperiment = () => {
	const firstActivationDateMs = useFirstActivationDateMs();
	const [expConfig] = UNSAFE_noExposureExp('jsw_board_real-time_collaboration_through_presence');

	return {
		experimentCohort:
			!firstActivationDateMs || differenceInDays(new Date(), firstActivationDateMs) < 30
				? 'not-enrolled'
				: expConfig.get('cohort', 'not-enrolled'),
	};
};
