import React, { useEffect, useMemo, useRef } from 'react';
import { useFormState, type FieldProps } from '@atlaskit/form';
import Select, { type ValueType } from '@atlaskit/select';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import {
	DEFAULT_ITERATIONS_COUNT,
	DEFAULT_ITERATION_LENGTH,
	ITERATIONS_COUNT_OPTIONS,
} from '../../../common/constants.tsx';
import type { NumberOption } from '../../../common/types.tsx';
import { iterationLengthCountOptionsMap } from './constants.tsx';

function isObjKey(key: PropertyKey): key is keyof typeof iterationLengthCountOptionsMap {
	return key in iterationLengthCountOptionsMap;
}

const IterationsCountField = (fieldProps: FieldProps<ValueType<NumberOption>>) => {
	const formState = useFormState();
	const selectedDuration = Number(
		formState?.values?.iterationLength?.value ?? DEFAULT_ITERATION_LENGTH,
	);
	const prevDuration = useRef(selectedDuration);

	const optionsForSelectedDuration = useMemo(() => {
		const optionsSettings =
			isObjKey(selectedDuration) && iterationLengthCountOptionsMap[selectedDuration];

		const options = optionsSettings
			? ITERATIONS_COUNT_OPTIONS.filter(
					(o) => o.value >= optionsSettings.min && o.value <= optionsSettings.max,
				)
			: [];
		return options;
	}, [selectedDuration]);

	useEffect(() => {
		if (prevDuration.current !== selectedDuration) {
			const defaultValue = isObjKey(selectedDuration)
				? iterationLengthCountOptionsMap[selectedDuration].default
				: DEFAULT_ITERATIONS_COUNT;
			const defaultOption = ITERATIONS_COUNT_OPTIONS.find((o) => o.value === defaultValue);
			if (defaultOption) {
				fieldProps.onChange(defaultOption);
				prevDuration.current = selectedDuration;
			}
		}
	}, [selectedDuration, fieldProps, optionsForSelectedDuration]);

	return (
		<Select
			{...fieldProps}
			options={optionsForSelectedDuration}
			styles={{
				menuPortal: (base) => ({
					...base,

					zIndex: layers.modal,
				}),
			}}
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			menuPortalTarget={document.body}
		/>
	);
};

export default IterationsCountField;
