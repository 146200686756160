import React, { memo } from 'react';
import { getParts } from '../common/utils/index.tsx';
import HighlightText from './main.tsx';

export type Props = {
	text: string;
	highlight: string[] | null;
};

const HighlightTextWithParser = memo<Props>(({ text, highlight }: Props) => {
	const parts = getParts(highlight, text);
	return <HighlightText parts={parts} />;
});

export default HighlightTextWithParser;
