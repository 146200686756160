import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'portfolio-3-plan-increment-common.ui.error-screens.custom-field.trashed.title',
		defaultMessage: 'The custom field "{fieldName}" has been deleted from your site.',
		description: 'Title for the custom field not found error screen',
	},
	description: {
		id: 'portfolio-3-plan-increment-common.ui.error-screens.custom-field.trashed.description',
		defaultMessage:
			'<Paragraph>This board was configured using the "{fieldName}" custom field, which has been deleted from your site by an administrator.</Paragraph>' +
			'<Paragraph>Custom fields can be restored for up to 60 days after they were moved to the trash. Contact your site administrator to see if this is possible in order to restore your board. If the custom field was deleted more than 60 days ago, you’ll need to make a new board.</Paragraph>',
		description: 'Description for custom field not found error screen',
	},
	imgAlt: {
		id: 'portfolio-3-plan-increment-common.ui.error-screens.custom-field.trashed.img-alt',
		defaultMessage: 'Drawing of a magnifying glass with a cross mark',
		description: 'Alt text for the image illustration',
	},
});
