import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	startStandupButton: {
		id: 'standups.control-buttons.start-standup-button.start-standup-button',
		defaultMessage: 'Start stand-up',
		description: 'Text on button for users to start a stand-up happening at the moment',
	},
	disabledButtonTooltip: {
		id: 'standups.control-buttons.start-standup-button.disabled-button-tooltip',
		defaultMessage: 'A sprint needs to be started in the backlog before you can begin stand-up',
		description: 'Tooltip message for disabled start stand-up button',
	},
});
