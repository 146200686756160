export const EstimationUnit = {
	STORY_POINTS: 'STORY_POINTS',
	ISSUE_COUNT: 'ISSUE_COUNT',
	TIME_TRACKING: 'TIME_TRACKING',
	OTHER: 'OTHER',
} as const;

export type EstimationUnits = keyof typeof EstimationUnit;

export const OTHER_ESTIMATION_UNIT = 'OTHER';
