/**
 * @generated SignedSource<<cca65563319c2a83f4401e48a49a5cd4>>
 * @relayHash b5e0b37aec54800457a5fd3db7082421
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ea97cb51235400c363559b2d6f24507688902e7f5efb636e827ccc6c74dd445d

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type boardFilterRefinementQuery$variables = {
  cloudId: string;
};
export type boardFilterRefinementQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ui_filterRefinement_FilterRefinement">;
};
export type boardFilterRefinementQuery = {
  response: boardFilterRefinementQuery$data;
  variables: boardFilterRefinementQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v3 = [
  {
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      {
        "kind": "ScalarField",
        "name": "accountId"
      },
      {
        "kind": "ScalarField",
        "name": "name"
      },
      {
        "kind": "ScalarField",
        "name": "picture"
      },
      {
        "kind": "ScalarField",
        "name": "id"
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "boardFilterRefinementQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "ui_filterRefinement_FilterRefinement"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "boardFilterRefinementQuery",
    "selections": [
      {
        "concreteType": "AuthenticationContext",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": (v3/*: any*/)
      },
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraJqlBuilder",
            "kind": "LinkedField",
            "name": "jqlBuilder",
            "plural": false,
            "selections": [
              {
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 100
                  },
                  {
                    "kind": "Literal",
                    "name": "jqlTerm",
                    "value": "assignee"
                  }
                ],
                "concreteType": "JiraJqlFieldValueConnection",
                "kind": "LinkedField",
                "name": "fieldValues",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraJqlFieldValueEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": (v3/*: any*/),
                            "type": "JiraJqlUserFieldValue"
                          }
                        ]
                      }
                    ]
                  }
                ],
                "storageKey": "fieldValues(first:100,jqlTerm:\"assignee\")"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "ea97cb51235400c363559b2d6f24507688902e7f5efb636e827ccc6c74dd445d",
    "metadata": {},
    "name": "boardFilterRefinementQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "84372a689b751b748f2b71543904611f";

import { PreloadableQueryRegistry } from 'relay-runtime';
PreloadableQueryRegistry.set(node.params.id, node);

export default node;
