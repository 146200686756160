import { useCallback } from 'react';
import { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';

export function useFireInvalidFieldConfigError() {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const fireInvalidFieldConfigError = useCallback(
		(error: Error) => {
			if (error instanceof ValidationError) {
				const removedEstimateError = error?.errors?.find((err) =>
					err?.error?.includes('It is not on the appropriate screen, or unknown.'),
				);
				if (removedEstimateError) {
					fireTrackAnalytics(createAnalyticsEvent({}), 'estimateField updateFailed', {
						field: removedEstimateError?.field,
						error: removedEstimateError?.error,
						isIncorrectFieldConfig: true,
					});
				}
			}
		},
		[createAnalyticsEvent],
	);
	return { fireInvalidFieldConfigError };
}
