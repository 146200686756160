// eslint-disable-next-line jira/restricted/react-component-props
import React, { useEffect, type ComponentProps, type ComponentType, type ReactNode } from 'react';
import flow from 'lodash/flow';
import noop from 'lodash/noop';
import { useAnalyticsEvents, type UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { useIsOneScreenOnboardingEnabled } from '@atlassian/jira-onboarding-core/src/controllers/is-one-screen-onboarding-enabled/index.tsx';
import { useIsDismissed } from '@atlassian/jira-onboarding-quickstart-core/src/services/index.tsx';
import PlatformColumnCreate from '@atlassian/jira-platform-board-kit/src/ui/column/column-create/index.tsx';
import {
	fireUIAnalytics,
	AnalyticsEventToProps,
	fireOperationalAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { useRegisterNudgeTarget } from '@atlassian/jira-software-onboarding-nudges--next/src/controllers/register-nudge-target/index.tsx';
import { BoardCreateColumnButtonWrapperNudgeAsync } from '@atlassian/jira-software-onboarding-nudges--next/src/ui/board-create-column/async.tsx';
import { ConditionalNudgeWrapper } from '@atlassian/jira-software-onboarding-nudges--next/src/ui/conditional-nudge-wrapper/index.tsx';
import { BoardCreateColumnKanbanTourNudgeAsync } from '@atlassian/jira-software-onboarding-nudges--next/src/ui/kanban-onboarding-tour/board-create-column/async.tsx';
import { WORKFLOW_NUDGE_ID } from '@atlassian/jira-spa-apps-onboarding-get-started/src/common/constants.tsx';
import { useOnboardingGetStartedSingleNudge } from '@atlassian/jira-spa-apps-onboarding-get-started/src/controllers/single-nudge-controller/index.tsx';
import { WorkflowNudgeAsync } from '@atlassian/jira-spa-apps-onboarding-get-started/src/view/nudges/workflow-nudge/async.tsx';
import { columnCreateRequest } from '../../../../state/actions/column/create/index.tsx';
import { useBoardSelector } from '../../../../state/index.tsx';
import type { State } from '../../../../state/reducers/types.tsx';
import { makeValidateColumnSelector } from '../../../../state/selectors/column/column-selectors.tsx';
import { getIsCMPBoard } from '../../../../state/selectors/software/software-selectors.tsx';
import { isSprintsEnabled } from '../../../../state/selectors/sprint/sprint-selectors.tsx';
import { isAnyColumnUpdating } from '../../../../state/selectors/work/work-selectors.tsx';
import type { Dispatch } from '../../../../state/types.tsx';

type OwnProps = {
	isCMPBoard: boolean;
};

type Props = {
	isKanbanBoard: boolean;
} & ComponentProps<typeof PlatformColumnCreate>;

const connector = connect(
	(state: State) => ({
		// analytics
		externalId: 'software.board.column.add.title',
		onEditStart: noop,
		onEditCancel: noop,
		validate: makeValidateColumnSelector(state),
		isDisabled: isAnyColumnUpdating(state),
		isKanbanBoard: !isSprintsEnabled(state),
	}),
	(dispatch: Dispatch, ownProps: OwnProps) => ({
		onSubmit: (temporaryColumnId: number, name: string, analyticsEvent: UIAnalyticsEvent) => {
			const action = columnCreateRequest(
				temporaryColumnId,
				name,
				analyticsEvent,
				ownProps.isCMPBoard,
			);
			dispatch(action);
		},
		onEditStart: (analyticsEvent: UIAnalyticsEvent) => fireUIAnalytics(analyticsEvent),
		onEditCancel: (columnId: number, type: string, analyticsEvent: UIAnalyticsEvent) =>
			fireUIAnalytics(analyticsEvent, { type, columnId }),
	}),
);

const WorkflowNudge = ({ children }: { children: ReactNode }) => {
	const { ref: columnsNudgeRef } = useRegisterNudgeTarget<HTMLDivElement>(WORKFLOW_NUDGE_ID, true);
	return (
		<JSErrorBoundary id="get-started-workflow-nudge" teamName="Jlove-makkuro">
			<Box ref={columnsNudgeRef} xcss={nudgeStyles}>
				{children}
			</Box>
			<WorkflowNudgeAsync />
		</JSErrorBoundary>
	);
};

// Wraps ColumnCreate with a NudgeToolTip
export const withQuickstartNudge =
	<P extends Props>(WrappedComponent: ComponentType<P>): ComponentType<P> =>
	(ownProps: P) => {
		const [isOnboardingDismissed] = useIsDismissed();

		const { data } = useProjectContext();

		const isEligibleForOneScreenOnboarding = useIsOneScreenOnboardingEnabled();

		const [{ nudgeId }] = useOnboardingGetStartedSingleNudge();
		if (
			isEligibleForOneScreenOnboarding &&
			ownProps.isKanbanBoard &&
			data?.simplified &&
			isOnboardingDismissed &&
			nudgeId === WORKFLOW_NUDGE_ID
		) {
			return (
				<WorkflowNudge>
					<WrappedComponent {...ownProps} />
				</WorkflowNudge>
			);
		}

		const AsyncNudgeComponent = ownProps.isKanbanBoard
			? BoardCreateColumnKanbanTourNudgeAsync
			: BoardCreateColumnButtonWrapperNudgeAsync;

		return (
			<ConditionalNudgeWrapper
				conditionsToApplyWrapper={!isOnboardingDismissed}
				Wrapper={AsyncNudgeComponent}
			>
				<Inline grow="hug">
					<WrappedComponent {...ownProps} />
				</Inline>
			</ConditionalNudgeWrapper>
		);
	};

const ColumnCreate = flow(
	withQuickstartNudge,
	AnalyticsEventToProps('columnCreate', {
		onEditStart: 'editStarted',
		onEditCancel: 'editCanceled',
	}),
	connector,
)(PlatformColumnCreate);

const ColumnCreateWrapper = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isCMPBoard = useBoardSelector(getIsCMPBoard);
	useEffect(() => {
		if (isCMPBoard) {
			const analyticsEvent = createAnalyticsEvent({});
			fireOperationalAnalytics(analyticsEvent, 'columnCreateButton mounted');
		}
	}, [isCMPBoard, createAnalyticsEvent]);

	return <ColumnCreate isCMPBoard={isCMPBoard} />;
};

export default ColumnCreateWrapper;

const nudgeStyles = xcss({
	alignSelf: 'start',
});
