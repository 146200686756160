import { createSelector } from 'reselect';
import type { IssueLinksPermissionsState } from '../../reducers/entities/issue-links-permissions/types.tsx';
import type { State } from '../../reducers/types.tsx';
import { getEntities } from '../software/software-selectors.tsx';

export const getIssueLinksPermissionsState = (state: State): IssueLinksPermissionsState =>
	getEntities(state).issueLinksPermissions;

export const getIssueLinksPermissions = createSelector(
	[getIssueLinksPermissionsState],
	(issueLinksPermissions) => issueLinksPermissions,
);
