import type { Observable as ObservableType } from 'rxjs/Observable';
import type { IssuesLinksPermissionsResponse } from '../../model/issue-links-permissions/issue-links-permissions-types.tsx';
import { getIssueLinksPermissions } from '../../rest/issue-links-permissions/index.tsx';
import type { IssuesLinksPermissionsProps } from '../../rest/issue-links-permissions/types.tsx';
/**
 * Get all issues links stats on board cards using the REST API.
 */
export const fetchIssueLinksPermissionsService = (
	props: IssuesLinksPermissionsProps,
): ObservableType<IssuesLinksPermissionsResponse> => getIssueLinksPermissions(props);
