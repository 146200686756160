import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	cloneFailedTitle: {
		id: 'clone-issue.services.clone-failed-title',
		defaultMessage: "We couldn't clone {issueKey}",
		description: 'The title for an error flag the issue fails to clone',
	},
	cloneStartFailed: {
		id: 'clone-issue.services.clone-start-failed',
		defaultMessage: 'Check your connection and then try again.',
		description: 'The description for an error when an issue clone fails to start.',
	},
	cloneFailedWorkflow: {
		id: 'clone-issue.services.clone-failed-workflow',
		defaultMessage: '{failedReason}',
		description:
			'The description for an error when an issue clone fails to start because of a workflow constraint. (Already translated)',
	},
	tryAgain: {
		id: 'clone-issue.services.try-again',
		defaultMessage: 'Try again',
		description:
			'The button that will attempt to re-clone the issue after it failed the previous time.',
	},
	cloneStartTitle: {
		id: 'clone-issue.services.clone-start-title',
		defaultMessage: 'Cloning {issueKey}',
		description: 'The title for the flag saying an issue clone is in progress',
	},
	cloneStartDescription: {
		id: 'clone-issue.services.clone-start-description',
		defaultMessage:
			"When cloning is complete, the cloned issue will be linked to {issueKey} and you'll receive another pop-up here just like this one.",
		description: 'Description for the flag saying an issue clone is in progress.',
	},
	cloneSuccessTitle: {
		id: 'clone-issue.services.clone-success-title',
		defaultMessage: 'Cloning complete',
		description: 'Title for the flag saying an issue clone was successful completed.',
	},
	cloneSuccessLink: {
		id: 'clone-issue.services.clone-success-link',
		defaultMessage: 'Open cloned issue',
		description: 'The button/text that will open the newly cloned issue in a new tab.',
	},
	cloneSuccessDescription: {
		id: 'clone-issue.services.clone-success-description',
		defaultMessage:
			'You can find the cloned issue {clonedIssueKey} linked to {oldIssueKey} or by clicking the link below.',
		description: 'Description for the flag saying an issue clone was successful.',
	},
	cloneSuccessButLostResultDescription: {
		id: 'clone-issue.services.clone-success-but-lost-result-description',
		defaultMessage:
			'You can find the cloned issue linked to {oldIssueKey} or by opening it from Your work.',
		description:
			"Description for the flag saying an issue clone was successful, but we lost the link to it so they'll have to navigate to it manually.",
	},
	goToYourWork: {
		id: 'clone-issue.services.go-to-your-work',
		defaultMessage: "Go to 'Your work'",
		description: 'Text for a link taking the user to the "Your work" page.',
	},
	cloneStartDescriptionIssueTermRefresh: {
		id: 'clone-issue.services.clone-start-description-issue-term-refresh',
		defaultMessage:
			"When cloning is complete, the cloned issue will be linked to {issueKey} and you'll receive another pop-up here just like this one.",
		description: 'Description for the flag saying an issue clone is in progress.',
	},
	cloneSuccessLinkIssueTermRefresh: {
		id: 'clone-issue.services.clone-success-link-issue-term-refresh',
		defaultMessage: 'Open cloned issue',
		description: 'The button/text that will open the newly cloned issue in a new tab.',
	},
	cloneSuccessDescriptionIssueTermRefresh: {
		id: 'clone-issue.services.clone-success-description-issue-term-refresh',
		defaultMessage:
			'You can find the cloned issue {clonedIssueKey} linked to {oldIssueKey} or by clicking the link below.',
		description: 'Description for the flag saying an issue clone was successful.',
	},
	cloneSuccessButLostResultDescriptionIssueTermRefresh: {
		id: 'clone-issue.services.clone-success-but-lost-result-description-issue-term-refresh',
		defaultMessage:
			'You can find the cloned issue linked to {oldIssueKey} or by opening it from Your work.',
		description:
			"Description for the flag saying an issue clone was successful, but we lost the link to it so they'll have to navigate to it manually.",
	},
});
