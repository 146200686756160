import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';

export const SET_CHILD_ISSUES_METADATA =
	'state.actions.issue.child-issues-metadata.SET_CHILD_ISSUES_METADATA' as const;

type IssueWithChildIssuesMetadata = {
	id: IssueId;
	numTotalChildren: number;
	numCompleteChildren: number;
};

export type SetChildIssuesMetadataAction = {
	type: typeof SET_CHILD_ISSUES_METADATA;
	payload: IssueWithChildIssuesMetadata[];
};

export const setChildIssuesMetadata = (
	issues: IssueWithChildIssuesMetadata[],
): SetChildIssuesMetadataAction => ({
	type: SET_CHILD_ISSUES_METADATA,
	payload: issues,
});
