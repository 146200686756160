import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { workIssuesSelector } from '../../state/selectors/work/work-selectors.tsx';
import type { State } from '../../state/types.tsx';
import GlobalSearchTracker, { type StateProps } from './view.tsx';

export default connect(
	(state: State): StateProps => ({
		numIssues: workIssuesSelector(state).length,
	}),
	{},
)(GlobalSearchTracker);
