import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import Badge from '@atlaskit/badge';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { FormattedMessage } from '@atlassian/jira-intl';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import TransitiveNumber from '@atlassian/react-transitive-number';

export type Props = {
	isEditing: boolean;
	isCMPBoard: boolean;
	totalCount: number;
	showTotalCount: boolean;
	visibleCount: number;
};

const ColumnCardCount = ({
	isCMPBoard,
	isEditing,
	totalCount,
	showTotalCount,
	visibleCount,
}: Props) => {
	if (isEditing || totalCount === 0) {
		return null;
	}

	if (showTotalCount) {
		if (isCMPBoard) {
			return (
				<Count>
					<BadgeWrapper>
						<FormattedMessage
							id="platform-board.column-header-count-uif"
							defaultMessage="{visibleCountNumber}/{totalCountNumber}"
							values={{
								visibleCountNumber: <TransitiveNumber>{visibleCount}</TransitiveNumber>,
								totalCountNumber: <TransitiveNumber>{totalCount}</TransitiveNumber>,
							}}
						/>
					</BadgeWrapper>
				</Count>
			);
		}
		return (
			<Count>
				<BadgeWrapper>
					<FormattedMessage
						id="platform-board.column-header-count"
						defaultMessage="{visibleCountNumber} of {totalCountNumber}"
						values={{
							visibleCountNumber: <TransitiveNumber>{visibleCount}</TransitiveNumber>,
							totalCountNumber: <TransitiveNumber>{totalCount}</TransitiveNumber>,
						}}
					/>
				</BadgeWrapper>
			</Count>
		);
	}

	return (
		<Count>
			<BadgeWrapper>
				<TransitiveNumber>{visibleCount}</TransitiveNumber>
			</BadgeWrapper>
		</Count>
	);
};

export default ColumnCardCount;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Count = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N300),
	minWidth: 0,
	flexGrow: 0,
	flexShrink: 0,
	marginRight: token('space.050', '4px'),
});

const BadgeWrapper = ({ children }: { children: ReactNode }) =>
	isVisualRefreshEnabled() ? <Badge>{children} </Badge> : <>{children}</>;
