import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createRule: {
		id: 'automation-platform.automation-recommendation.create-rule',
		defaultMessage: 'Create rule',
		description:
			'Describes where the user will be navigated to by clicking this button, the rules page',
	},
	viewTemplates: {
		id: 'automation-platform.automation-recommendation.view-templates',
		defaultMessage: 'See more templates',
		description: 'Explains what the automation recommendation will do once configured',
	},
});
