import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';

export const LEARN_MORE = 'learn-more' as const;
export const WORKFLOW_EDIT_PERMISSION_LEARN_MORE = 'workflow-edit-permission-learn-more' as const;
export const UPGRADE = 'upgrade' as const;
export type UpflowFlow =
	| typeof LEARN_MORE
	| typeof UPGRADE
	| typeof WORKFLOW_EDIT_PERMISSION_LEARN_MORE;

type ActionIDMapperType = {
	[name: string]: string;
};

export const mapActionIdFromType: ActionIDMapperType = {
	[LEARN_MORE]: 'freeLearnMore',
	[WORKFLOW_EDIT_PERMISSION_LEARN_MORE]: 'workflowEditLearnMore',
	[UPGRADE]: 'freeTryNow',
};

export type MessageMapperType = {
	[name: string]: MessageDescriptor;
};
