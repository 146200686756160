import React, { Fragment, useCallback, type MouseEvent } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import { AVATAR_SIZES, type SizeType } from '@atlaskit/avatar';
import InviteTeamIcon from '@atlaskit/icon/glyph/invite-team';
import { media } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { type IntlShape, injectIntl } from '@atlassian/jira-intl';
import { useIsDismissed } from '@atlassian/jira-onboarding-quickstart-core/src/services/index.tsx';
import { useGlobalRefStoreActions } from '@atlassian/jira-refs-store/src/index.tsx';
import { AssigneeFilterAddPeopleButtonWrapperNudgeAsync } from '@atlassian/jira-software-onboarding-nudges--next/src/ui/assignee-filter-add-people/async.tsx';
import { FILTER_PREFIX } from '../../../../../common/ui/assignee/constants.tsx';
import { ActionAvatar, ActionAvatarSpacer } from '../../../../../common/ui/assignee/index.tsx';
import { onBlur } from '../../../../../common/ui/assignee/utils.tsx';
import messages from './messages.tsx';
import { AddPeopleTooltip } from './responsive-tooltip/index.tsx';

const ADD_PEOPLE_BUTTON_TARGET = 'addPeopleButtonTarget';

type Props = {
	intl: IntlShape;
	onClick: () => void;
	peopleButtonRef?: React.Ref<HTMLButtonElement>;
};

const AddUsersButton = (props: Props) => {
	const {
		intl: { formatMessage },
		onClick: onClickProp,
		peopleButtonRef,
	} = props;

	const onClick = useCallback(
		(event: MouseEvent<HTMLElement>) => {
			onBlur(event);
			onClickProp();
		},
		[onClickProp],
	);

	const [, { register }] = useGlobalRefStoreActions();

	const onRef = useCallback(
		(ref: HTMLDivElement | null) => {
			register(ADD_PEOPLE_BUTTON_TARGET, ref);
		},
		[register],
	);

	const [isDismissed] = useIsDismissed();
	const NudgeTooltip = !isDismissed ? AssigneeFilterAddPeopleButtonWrapperNudgeAsync : Fragment;

	return (
		<AddButtonWrapper ref={onRef}>
			<NudgeTooltip>
				<AddPeopleTooltip content={formatMessage(messages.addPeople)}>
					<ActionAvatarWrapper
						ref={peopleButtonRef}
						data-test-id="filters.ui.filters.assignee.stateless.add-button"
						data-testid="filters.ui.filters.assignee.stateless.add-button"
						data-pollinator-filter-bar-add-people
						id={`${FILTER_PREFIX}-add-person`}
						aria-label={formatMessage(messages.addPeople)}
						onClick={onClick}
						aria-describedby={FILTER_PREFIX}
					>
						<AddButtonAvatarSpacer>
							<InviteTeamIcon size="medium" label={formatMessage(messages.addPeople)} />
						</AddButtonAvatarSpacer>
					</ActionAvatarWrapper>
				</AddPeopleTooltip>
			</NudgeTooltip>
		</AddButtonWrapper>
	);
};

AddUsersButton.defaultProps = {
	onClick: noop,
};

export default injectIntl(AddUsersButton);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AddButtonWrapper = styled.div({
	marginLeft: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ActionAvatarWrapper = styled(ActionAvatar)<{ size?: SizeType }>({
	borderRadius: '50%',
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AddButtonAvatarSpacer = styled(ActionAvatarSpacer)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${AVATAR_SIZES.medium}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minWidth: `${AVATAR_SIZES.medium}px`,
	width: 'auto',
	padding: `0 ${token('space.050', '4px')}`,
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.neutral', colors.N20),
	[media.below.lg]: {
		backgroundColor: 'transparent',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const visibleForTesting = {
	ActionAvatarWrapper,
};
