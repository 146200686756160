import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import {
	ISSUE_LINKS_REMOVE_REQUEST,
	issueLinksRemoveSuccess,
	issueLinksRemoveFailed,
} from '../../../state/actions/issue/issue-link/index.tsx';
import type { ActionsObservable, MiddlewareAPI, BoardDependencies } from '../../../state/types.tsx';

export function issueUnlinkEpic(
	action$: ActionsObservable,
	store: MiddlewareAPI,
	{ customRequestHandlers }: BoardDependencies,
) {
	return action$.ofType(ISSUE_LINKS_REMOVE_REQUEST).mergeMap((action) => {
		const { issueId, issueLink } = action.payload;

		const success = () =>
			Observable.of(
				issueLinksRemoveSuccess({
					issueLinksToRemove: { [issueId]: issueLink.id },
				}),
			);

		const error = (err?: Error) => {
			log.safeErrorWithoutCustomerData(
				'issue.issue-unlink-epic.failure',
				'Failed to unlink IP board issue',
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				err as Error,
			);
			return Observable.of(issueLinksRemoveFailed());
		};

		if (!customRequestHandlers || !customRequestHandlers.removeIssueLink) {
			return error(new Error('no request handler found for removing dependency'));
		}

		return Observable.from(
			customRequestHandlers.removeIssueLink({
				itemKey: issueLink.id,
				type: issueLink.linkTypeId,
				sourceItemKey: `${issueLink.sourceId}`,
				targetItemKey: `${issueLink.destinationId}`,
			}),
		)
			.flatMap(() => success())
			.catch((err) => error(err));
	});
}
